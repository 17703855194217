li.SettingForSidestyle {
    background: rgba(129, 193, 75, 0.2);
    cursor: pointer;
    color: #81C14B !important;
    border-left: 5px solid #81C14B;
}
.SettingForSidestyle1 {
    background-color: white ;
  /* Add a smooth transition for better user experience */
}


.SettingForSidestyle1.active {
    background: rgba(129, 193, 75, 0.2);
    cursor: pointer;
    color: #81C14B !important;
    border-left: 5px solid #81C14B;
  }

  .SettingForSidestyle1 li.active {
    background-color: #e0e0e0; /* You can change the background color as needed */
}

.SettingForSidestyle1:hover {
    background-color: rgba(129, 193, 75, 0.2) ; /* Change to your desired hover color */
    cursor: pointer; /* Change the cursor on hover */
   
    color: #81C14B !important;
    border-left: 5px solid #81C14B;
}


.clgForstyle {
    color: #4D4D4D;
    line-height: 17px;
}

.redefineClass {
    border-bottom: revert !important;
    padding: revert !important;
}



.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: transparent;
    border-left: none;
}

.SessionForBar {
    padding: 10px 20px;
    background: #F6F6F6;
    border-radius: 5px;
    color: #4D4D4D;
    margin-top: 10px;
}


.inputgrouppass{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 97%;
}

/*===================== start_Privacy_setting_css ========================*/
.SettingPrivacy_Main_section {
    margin-bottom: 207px;
    padding: 20px;
}

.SettingPrivacy_Card_Body {
    padding: 20px !important;
    background: #F6F6F6 !important;
    border-radius: 5px !important;
    margin-top: 20px;
}

.SettingPrivacy_Card_Body h3 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.SettingPrivacy_Content_Section {
    padding-bottom: 10px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 20px !important;
}

.SettingPrivacy_Content_Section:last-child {
    padding-bottom: 0 !important;
    border-bottom: none !important;
    margin-bottom: 0 !important;
}

.SettingPrivacy_SubContent_Section {
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
}

.SettingPrivacy_SubContent_Section h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}

.SettingPrivacy_SubContent_Section p {
    color: #808080;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Media_content_section {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.Media_content_section h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
}

.Media_content_section .High-Quality {
    object-fit: cover;
    border-radius: 5px;
    width: 100%;
    height: 160px;
    border: 3px solid #FFFFFF;
    box-shadow: 0px 0px 0px 3px #81C14B;
    border-radius: 5px;
}

.Media_content_section .Medium-Quality {
    object-fit: cover;
    filter: blur(0.25px);
    border-radius: 5px;
    width: 100%;
    height: 160px;
}

.Media_content_section .Low-Quality {
    object-fit: cover;
    filter: blur(0.5px);
    border-radius: 5px;
    width: 100%;
    height: 160px;
}

.ReportProblem_label h3 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-top: 12px;
}

.Report_Input_section {
    background: #F6F6F6;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-top: 8px;
}

.Report_text_Validation {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.Report_Desclimer_section h3 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.Report_Desclimer_section span {
    color: #81C14B;
    font-weight: 600;
    font-size: 16px;
}

/*===================== end_Privacy_setting_css ========================*/

/*===================== start_language_settings_css =====================*/
.SettingLanguage_Card_Body {
    padding: 20px !important;
    background: #F6F6F6 !important;
    border-radius: 5px !important;
    margin-top: 20px;
}

.SettingLanguage_Card_Body p {
    color: #808080;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.SettingLanguage_Card_Body h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4D4D4D;
}

button.Language_DropSelect_section.btn.btn-outline-primary {
    border: 1px solid #81C14B;
    border-radius: 4px;
}

.language_menu.dropdown-menu.show {
    padding: 0px !important;
    margin: 0px !important;
    margin-top: 2px !important;
    font-size: 1rem !important;
    color: #212529 !important;
    text-align: left !important;
    list-style: none !important;
    background-color: #ffffff !important;
    background-clip: padding-box !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.25rem !important;
    max-height: 268px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

/*===================== end_language_settings_css =====================*/
/*===================== start_Deleted_setting_css =====================*/
.Delete_Account_ContentSection h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin-top: 10px;
}

.Delete_Account_ContentSection span {
    color: #FF9922;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;

}

.Delete_Account_Content_Section_blk h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    margin-top: 10px;
}

.Delete_Account_Content_Section_blk span {
    color: #FF9922;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
}

.SettingAccount_Content_Section {
    /* padding-bottom: 10px !important; */
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 20px !important;
}

.SettingAccount_SubContent_Section {
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
}

.SettingAccount_SubContent_Section h4 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
}

.SettingAccount_DeleteContent_Section {
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
}

.SettingAccount_DeleteContent_Section {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 16px;
}

.Delete_Account_profileSection {
    display: flex !important;
    align-items: center !important;

}

.Delete_Account_profileSection img {
    object-fit: cover;
    height: 71px;

}

.Delete_Account_profileSection h3 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
}

.Delete_Account_profileSection p {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

/*============= new ============*/

.Delete_Account_DetailSection h3 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.Delete_Account_DetailSection span {
    color: #0066FF;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.Delete_Account_DetailSection h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
}

.delete_Account_inputSection h3 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 20px;
}

.setting_Group_blk_section {
    border-bottom: 1px solid #ddd;
}

.setting_Group_blk_section:last-child {
    border-bottom: none;
}

.Settings_card-title {
    padding: 20px;
}

.Settings_card-title h3 {
    text-transform: capitalize;
    color: #51595e;
    font-weight: 700;
}

.Settings_Icon_Interest {
    background: #81C14B;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Settings_Fillter_ConnectionTab_section.dropdown-menu.show {
    position: absolute !important;
    will-change: transform !important;
    top: 0px !important;
    left: 0px !important;
    transform: translate3d(-54px, 39px, 0px) !important;
    /* margin: 0px; */
    padding: 0 !important;
    margin-top: 2px !important;
    display: block;
    width: 150% !important;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

button.Settings_Hover_item.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

button.Settings_Hover_item.dropdown-item:hover,
button.Settings_Hover_item.dropdown-item:focus {
    background: #E6F3DB !important;
    color: #333333;
}

.Setteing_connection_Heading_section h3 {
    color: #4D4D4D;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.Setting_Deleted_btn {
    width: 18%;
}

.btn-ReportProblem {
    background: #F6F6F6;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.Legal_Policies_Blk_section {
    padding-bottom: 15px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 15px !important;
}

.Legal_Policies_Blk_section:last-child {
    padding-bottom: 0 !important;
    border-bottom: none !important;
    margin-bottom: 0 !important;
}

.Legal_Policies_Content_section {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
}

a.Legal_Policies_Link {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
}

.Legal_Policies_Heading h3 {
    color: #333333;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

button.Language_droup_Item.dropdown-item:hover {
    color: #000000;
    background-color: #E6F3DB !important;
}

.My_Connection_MoreBtn_blk.btn-group {
    margin-right: -23px !important;
}

/*================ Start_MediaQueries.css ==================*/
@media (max-width: 540px) {
    .Media_content_section .High-Quality {
        border-radius: 5px;
        width: 100%;
        height: 100px;
        border: 3px solid #FFFFFF;
        box-shadow: 0px 0px 0px 3px #81C14B;
        border-radius: 5px;
    }

    .Media_content_section .Medium-Quality {
        object-fit: cover;
        filter: blur(0.25px);
        border-radius: 5px;
        width: 100%;
        height: 100px;
    }

    .Media_content_section .Low-Quality {
        object-fit: cover;
        filter: blur(0.5px);
        border-radius: 5px;
        width: 100%;
        height: 100px;
    }

    .Media_content_section h4 {
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: #4D4D4D;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        margin-top: 10px;
    }

    .btn-ReportProblem {
        margin-left: 16px;
    }

    button.ReportSumit_btn.btn.btn-primary {
        width: 100%;
        margin-top: 10px;
    }

    .Delete_Account_DetailSection h3 {
        color: #4D4D4D;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
    }

    .Delete_Account_DetailSection span {
        color: #0066FF;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
    }

    .Delete_Account_DetailSection h4 {
        color: #4D4D4D;
        font-weight: 500;
        font-size: 14px;
    }

    a.AccountProfile-login.active {
        text-decoration: none !important;
        color: #81c14b !important;
        font-weight: 500;
        font-size: 12px;
    }

    .Delete_Account_InputSection {
        display: flex !important;
        justify-content: center !important;
        width: 100% !important;
    }

    .Delete_Account_InputBlock {
        width: 70% !important;
    }

    .Setting_Deleted_btn {
        width: 30%;
    }
}

.Report_Problem_submit {
    display: flex;
    justify-content: end;
}

.Delete_Account_InputSection {
    display: flex;
    justify-content: center;
    width: 100%;
}

.Delete_Account_InputBlock {
    width: 50%;
}

/*================ End_MediaQueries.css ==================*/



@media (min-width:280px) and (max-width: 575px) {
    .hide-setting-options{
        display: none !important;
    }
    
}

@media (min-width :576px){
    .hide-setting-options1{
   display: none;
}

.setposi{
    
}
}

.settfont{
    color: #4D4D4D;
font-family: Poppins;
font-size: 18px !important;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 27px */
}


.setposi{
    position: relative;
    top: 2px;
    right: -17px;
}


.backarbut{
    position: absolute;
    top: -41px;
    left: 4px;
}
.backarbut1{
    position: absolute;
    top: -40px;
    left: 7px;
}

.Categories_LeftPannel_slider1{
    height: max-content;
    border-radius: 5px;
    position: sticky;
    top: 100px;
    /* margin-bottom: 30px; */
    overflow-y: hidden;
}

.blockmodalset{
    position: relative;
    overflow: hidden;
    margin-left: -4px;
    margin-right: -4px; 
}

.butsec{
    position: relative;
    right: 6px;
}