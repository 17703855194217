.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.react-datetime-picker__wrapper {
  border: none !important;
}

.fr-btn .confirm-btn:hover {
  color: #fff !important;
}

.phone-code-list:hover {
  cursor: pointer;
  border: 1px solid rgba(27, 139, 27, 0.445) !important;
}

.active-phone-code {
  border: 1px solid rgba(27, 139, 27, 0.445) !important;
}

.thought-input::placeholder {
  color: #fff !important;
  opacity: 1;
}

.static-margin {
  margin-left: 10px;
}

/* OVERRIDE PROFILE RADIUS */
.conversation-panel .friend-section .friend-list .friend-box .user-img img {
  border-radius: 25% !important;
}

.react-datetime-picker__calendar {
  z-index: 2 !important;
}

.without-input-fill:disabled {
  background: #acdad6;
  border-color: #acdad6;
}

.without-input-fill {
  background: #81c14b;
  border-color: #81c14b;
}

.button-anchor {
  color: #81c14b !important;
  border: none;
  background: none;
  font-weight: 500;
}

.checkbox_animated2 input[type="checkbox"] + label:after {
  content: "\2714";
  border: 0.1em solid rgba(100, 117, 137, 0.45);
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  padding-left: 0;
  padding-bottom: 0;
  margin-right: 10px;
  vertical-align: bottom;
  color: transparent;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 1;
}

.checkbox_animated2 input[type="checkbox"]:checked + label:after {
  background-color: #81c14b;
  border-color: #81c14b;
  color: #fff;
}

.checkbox_animated2 input[type="checkbox"] {
  display: none;
}

.checkbox_animated2 input[type="checkbox"] + label:active:after {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.checkbox_animated2 input[type="checkbox"]:checked:disabled _ label:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  background-color: rgba(3, 137, 201, 0.2);
  border-color: rgba(3, 137, 201, 0.2);
}

.checkbox_animated2 input[type="checkbox"]:disabled _ label:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  border-color: #aaa;
}

.checkbox_animated2 input[type="checkbox"] + label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.media-img-vid-addmore {
  position: absolute;
  top: 20px;
  margin-left: 6rem;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  background-color: #fff;
  display: inline-block;
  z-index: 1;
}

.media-img-vid-edit {
  position: absolute;
  top: 20px;
  margin-left: 5px;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  background-color: #fff;
  display: inline-block;
  z-index: 1;
}

.media-img-close-icon .iw-20 {
  border: 1px solid #e0e0e0;
  stroke: #e0e0e0;
  border-radius: 50%;
  background: white;
}

.media-img-close-icon {
  position: absolute;
  right: 20px;
  top: 5px;
  display: inline-block;
  z-index: 1;
}

.input-minus {
  position: absolute;
  right: 9%;
  top: 50.4%;
  background: #ffffff;
  border: 1px solid #81c14b;
  border-radius: 3px;
  width: 4.5%;
}
/* .input-minuss {
  position: absolute;
  right: 9%;
  top: 50.4%;
  background: #ffffff;
  border: 2px solid #81c14b;
  width: 27px;
  height: 3px;
} */
@media (max-width: 360px) {
  /* .event-card-text {
    margin-left: -11px !important;
    font-size: 10px !important;
  } */
  .matual-friend-section {
    font-size: 10px !important;
    margin-left: -9px !important;
  }
  /* .BizBTN_sections {
    width: 78px !important;
    font-size: 10px !important;
  } */
  .input-Events_search-blks {
    margin-right: 92px !important;
  }
  .create-event-text {
    margin-top: -124px !important;
    margin-right: -6px !important;
    font-size: 8px !important;
  }

  /* .Events_Cards_Top_section{
    position: absolute!important;
    top: -16.5rem!important;
    height: 120px!important;
  } */
  .hidden-text {
    display: none !important;
  }
  /* .upcomming-btn {
    width: 140px !important;
    margin-left: -6px !important;
  }
  .filter-btn {
    margin-top: -82px!important;
    margin-right: 2px!important;
    width: 175px;
  } */
}
@media (min-width: 360px) and (max-width: 500px) {
  .Events_Cards_Top_section {
    height: 44px;
    position: absolute;
    bottom: 62px;
    width: 142px !important;
    left: -29px;
  }
  .form-text-profile {
    font-size: 10px !important;
  }
  .text-profile {
    position: absolute;
    top: 2px;
    left: 4px;
  }
  .Events_Cards_Top_sections {
    width: 150px !important;
    height: 102px;
    bottom: 2px;
  }
  .event-card-texts {
    font-size: 12px !important;
  }
  .hidden-text {
    display: none !important;
  }
}
@media (min-width: 600px) {
  .Group_Top_Cover_Buttom_LeftSection {
    position: absolute !important;
  }
}
@media (min-width: 640px) and (max-width: 360px) {
  .Events_Cards_Top_section {
    display: none !important;
  }

  .form-text-profile {
    font-size: 9px !important;
  }
  .text-profile {
    position: absolute;
    top: 2px;
    left: 35px;
  }
  .Events_Cards_Top_sections {
    width: 150px !important;
    height: 102px;
    bottom: 2px;
  }
  .event-card-texts {
    font-size: 12px !important;
  }
  .web-text {
    display: none !important;
  }
}
@media only screen and (min-width: 600px) {
  .event-card-text {
    /* margin-left: -11px !important;
    font-size: 10px !important; */
  }
  .Events_Cards_Top_section {
    /* position: absolute;
    left: 17rem;
    top: -6.5rem;
    width: 200px !important;
    height: 150px; */
    /* bottom: 72px; */
    /* left: -15px;
    width: 217px !important;
    bottom: 2.7rem; */
  }
}
@media (max-width: 320px) {
  /* .BizBTN_sections {
    width: 66px !important;
    font-size: 8px !important;
  } */
}
@media (max-width: 353px) {
  /* .BizBTN_sections {
    margin-left: 12px;
  } */
  .group-search-assign-filter {
    /* margin-right: 70px; */
  }
  .mobile-text-edit {
    position: absolute;
    right: -19rem;
  }
  .Events_Cards_Top_sections {
    position: absolute;
    left: -1rem;
    height: 36px;
    bottom: 70px;
  }
  .profile-date {
    position: absolute;
    top: -4px;
    left: 131px;
  }
}

@media (min-width: 712px) and (max-width: 1138px) {
  .Events_Cards_Top_section {
    position: absolute;
    bottom: -5rem;
  }
}
@media (min-width: 712px) {
  .canvas1-text {
    margin-bottom: 8rem;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  /* .Events_Cards_Top_section {
    position: absolute !important;
    bottom: -6rem !important;
  } */
}
@media (min-width: 1920px) and (max-width: 1080px) {
  .Events_Cards_Top_section {
    margin-left: 2rem !important;
    width: 372px !important;
    margin-top: 5rem !important;
  }
}
@media (min-width: 800px) and (max-width: 1280px) {
  .Events_Cards_Top_section {
    position: absolute;
    bottom: -10rem;
  }
  /* .canvas1-text{
    margin-bottom: 18rem;
  } */
}
@media (min-width: 800px) {
  .canvas1-text {
    /* margin-bottom: 36%; */
  }
}
@media (min-width: 820px) {
  .canvas1-text {
    /* margin-bottom: 26%; */
  }
}
@media (max-width: 353px) {
  .Events_Cards_Top_section {
    height: 44px;
    position: absolute;
    bottom: 62px;
    width: 131px !important;
    left: -2rem;
  }
  .form-text-profile {
    font-size: 9px !important;
  }
  .text-profile {
    position: absolute;
    top: 2px;
    left: 4px;
  }
}

@media (min-width: 1366px) {
  .Events_srch_block {
    /* width: 300px !important; */
    /* margin-right: -81px; */
  }
}
@media (min-width: 600px) {
  .cal-text {
    position: absolute;
    top: -38px;
  }
}
@media (min-width: 375px) {
  .cal-text {
    position: absolute;
    top: -68px;
  }
  .rem-mob-view-texts {
    display: none;
  }
}
@media (min-width: 540px) {
  .cal-text {
    margin-top: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .group-search-assign-filter .Events_srch_block {
    width: 154px !important;
    margin-top: -7px;
    padding-left: 22px;
  }
  .Events_Cards_Top_section {
    position: absolute;
    top: -100px;
    height: 153px;
  }
  .event-categories-text {
    margin-left: 19px;
    text-wrap: wrap !important;
  }
}
