.Notifications {
  height: 100px;
  width: 900px;
  margin-left: 100px;
}

.Filterc_Notifications {
  margin-left: 822px;
}

.Header_Notification {
  width: 127px;
  height: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-left: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.Button_interted {
  margin-top: -20px;
}

.Filter_Notifications {
  margin-right: 20px;
}

.Droupdown_seemore {
  margin-right: 20px;
}

.Today {
  color: #81c14b;
  margin-left: 6px;
  font-weight: 500;
  font-size: 13.34px;
}

.Header_Droupdown {
  margin-left: 530px;
  box-sizing: border-box;
  width: 71px;
  height: 28px;
}

.Button {
  margin-left: 10px;
  margin-top: 6px;
}

.Vertical {
  color: #808080;
  size: 10px;
  height: 20px;
  width: 20px;
  margin-top: 10px;
  margin-left: 67px;
}

.main:hover {
  text-decoration: none;
  outline: none;
  background-color: #f6f6f6;
}

.PopoverActionSubHeading {
  display: flex;
  justify-content: space-between;
  margin-left: -7px;
}

.PopoverActionSubHeading span {
  color: #808080;
  cursor: pointer;
}

.Filterb {
  margin-left: 78px;
  margin-top: 1px;
  color: #81c14b;
}

.content {
  max-height: 8rem;
  overflow: hidden;
  position: relative;
}

.Modar2 {
  width: 600px;
}

.Filterc {
  margin-left: 822px;
  color: #81c14b;
}

/* .Vertical-dropmenu{
    margin-left: 34px;
  } */
.Size {
  margin-left: 2px;
  margin-top: 1px;
  size: 12px;
}

.CardInfoBackGround {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(129, 193, 75, 0.1);
  border-radius: 5px;
  width: 800px;
  height: 768px;
}

.Sell-center {
  padding-left: 194px;
  padding-right: 12px;
}

.margin {
  margin-left: 12px;
}

.notification_seemore {
  width: 127px;
  height: 30px;
  margin-left: 1%;
  /* font-family: 'Poppins'; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #4d4d4d;
}

.CardHeader_Seemore {
  background-color: #f6f6f6;
  border-bottom: 8px;
  margin-top: 0%;
}

.cardheaderforseemore {
  background-color: transparent !important;
  border: none;
}

.cardbody_Seemore {
  margin-top: -4%;
}

.Notification_width {
  width: 10%;
}

.Filter_Notification {
  margin-left: 5px;
}

.thatNotification_Filterbtn {
  margin-left: 2% !important;
}

.Thatdroupdown_notification {
  top: -18px !important;
  border: none;
  padding: 0;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
  min-width: 16rem;
  z-index: 2;
}

.thatdropdownnotification.show {
  display: block;
  width: revert;
  top: -6px !important;
  margin-left: -173px;
}

.Thatcustom-dropdown .dropdown-menu {
  top: 20px !important;
  border: none;
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  min-width: 16rem;
  z-index: 2;
  margin-left: -130px !important;
}

.notification-btn-ThatNotificationdropdown {
  position: relative;
  min-width: 13rem !important;
  height: 130px !important;
  padding: 8px !important;
}

.ThatHeader {
  background-color: #e5f0e0 !important;
  margin-top: -8px;
}

button.My_Connection_MoreBtn_blk\.btn-group.btn.btn-flat-secondary {
  margin-right: -22px !important;
}

.thatdropmenu {
  margin-top: 5%;
}

/* .scrollbarThatFixed {
  width: 3px;
  height: 4px;
  overflow: auto;
  color: red;
} */

.scrollForNotification {
  overflow-y: scroll;
  max-height: calc(90vh - 90px);
  overflow-x: hidden;
}

.scrollForNotification::-webkit-scrollbar-thumb {
  background-color: #8abe46;
  /* set the color of the scrollbar thumb */
}

.CardThisHover:hover {
  background-color: #e6f3db !important;
}

.headerThishightwidth {
  font-size: small;
  margin-top: 21px;
  margin-right: 2px !important;
}

.notificationThisfontsizedropdownmenu {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 102px !important;
  transform: translate3d(-199px, -6px, 0px) !important;
  min-width: 242px !important;
  font-size: small;
}

.notificationThisfontsizeseemore {
  /* position: absolute;
  will-change: transform;
  top: 0px; */
  left: -42px !important;
  /* transform: translate3d(-197px, 11px, 0px) !important; */
  transform: translate3d(-140px, 16px, 0px) !important;
  /* min-width: 242px !important; */
  font-size: small;
}

.seemorethisoption {
  font-size: small;
  margin-top: 21px;
  transform: translate3d(0px, 40px, 0px);
}

.no-fade-modal {
  transition: none;
}

.notificationThisfontsizeseemore.perfect-size {
  width: fit-content;
}

/*================== deepak_Notifocation_css  ==========================*/
.Notification_Main_blk_section {
  /* background: #FFFFFF; */
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4); */
  border-radius: 5px;
}

/* .thatNotificationdropdown.mt-2.Notification_Showing_section.dropdown-menu::after {
  content: "";
  position: absolute;
  top: -10px !important;
  left: 41% !important;
  width: 0 !important;
  height: 0 !important;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #ffffff;
} */

.Notification_top_section {
  margin-top: -9px;
  padding: 10px !important;
  border-radius: 5px 5px 0 0;
  background: #e5f0e0;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Notification_top_section h3 {
  color: black;
  font-weight: 600;
  font-size: 18px;
}

.Notification_More_OptionMenu {
  /* background: rgba(0, 0, 0, 0.1);
  border-radius: 20px; */
  cursor: pointer;
  height: 25px;
  width: 25px;
}
.Notification_TopDown_blk {
  padding: 10px !important;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
}

.Notification_TopDown_blk h3 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
}

.Notification_TopDown_blk p {
  color: #81c14b;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.Notification_BottomDown_blk {
  padding: 10px !important;
  background: #ffffff;
}

.Notification_profile-blk {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background: #f6f6f6;
}

.Notification_profile-blk_Nxt {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.Notification_profile-blk_Nxt p {
  color: #808080;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.Notification_profile-blk_Nxt h4 {
  font-weight: 600;
  font-size: 14px;
  color: #4d4d4d;
  margin: 0 0 4px;
}

.Notification_profile-blk h4 {
  font-weight: 600;
  font-size: 15px;
  color: #4d4d4d;
  margin: 0 0 4px;
}

.Notification_profile-blk p {
  color: #808080;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.DropDown_Notification_item.dropdown-menu.show {
  transform: translate3d(23px, 21px, 0px) !important;
  min-width: fit-content !important;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%) !important;
  border-radius: 7px !important;
}

button.DropDown_Notification_items.dropdown-item:hover {
  color: #4d4d4d !important;
  background-color: #e6f3db !important;
}

button.DropDown_Notification_items.dropdown-item {
  color: #4d4d4d;
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 500;
  font-size: 16px;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

button.ml-2.Notification_More_OptionMenu.btn.btn-secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1) !important;
  border-radius: 20px !important;
  padding: 0 !important;
  border: none !important;
}

.DropDown_Notification_item_moreMenu.dropdown-menu.show {
  transform: translate3d(-29px, 15px, 0px) !important;
  min-width: fit-content !important;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%) !important;
  border-radius: 7px !important;
}
.DropDown_Notification_item_moreMenus.dropdown-menu.show {
  transform: translate3d(-29px, 15px, 0px) !important;
  min-width: fit-content !important;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%) !important;
  border-radius: 7px !important;
}

button.Notification_More_OptionMenuProfile.btn.btn-flat-primary {
  padding: 0 !important;
  border: none !important;
}

.Notification_aCCEPECT_section {
  display: block;
  /* background: #f6f6f6; */
  color: white;
  font-weight: 500;
  font-size: 14px;
  width: 50%;
  border-radius: 5px;
  padding: 6px 12px;
  outline: none;
  background-color: #81c14b;
  border: none;
  /* padding: 10px 20px; */
}

.Notification_Fill_section {
  display: block;
  font-weight: 500;
  font-size: 14px;
  width: 50%;
  border-radius: 5px;
  padding: 6px 12px;
  border: none;
  outline: none;
  color: #4d4d4d;
  /* padding: 10px 20px; */
}

.Notification_Main_scroll_box {
  /* max-height: 440px !important; */
  height: 45rem !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.Notification_Action_Blk {
  color: #808080;
  font-size: 15px;
  color: #808080;
  font-weight: 500;
}

button.NotificationTop_Filter.btn.btn-outline-primary {
  background: rgba(255, 255, 255, 0.5) !important;
  border: 1px solid #81c14b !important;
  border-radius: 5px !important;
  padding: 5px 10px !important;
  gap: 10px !important;
}

button.NotificationTop_Filter.btn.btn-outline-primary:hover {
  background-color: #81c14b !important;
  color: #ffffff !important;
}

.CardThisHover_Crad_Box_Section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DropDown_Notification_item.dropdown-menu_Block {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  unicode-range: U+00-7F;
}

.Update_Report_Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 10px;
  border-radius: 1px solid red;
  border: 2px solid green;
  unicode-range: U+100-17F;
}

.Report_Updation_Modal_Section_Blk {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border-radius: 1px solid red;
}

.Button_interted_Block_Sectikon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  text-decoration: double;
  text-align: justify;
  padding: 0;
  margin: 0;
  border-radius: 1px solid green;
  gap: 10px;
  position: absolute;
  left: 5px;
  top: 50px;
  bottom: 10px;
  right: 30px;
  max-width: 100%;
}

.Button_interted_Block_Sectikon_Block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
}
.date {
  position: absolute;
  top: 4.2rem;
  right: 50px;
}
/* =====================add 360 css===================== */
@media screen and (max-width: 360px) {
  .date {
    right: unset;
    left: 8%;
  }
}
@media (min-width: 360px) {
  .Notification_More_OptionMenu {
    margin-left: -1rem !important;
  }

  .filterNotification {
    position: absolute !important;
    will-change: transform !important;
    top: 7px !important;
    left: -7rem !important;
  }
  .DropDown_Notification_item_moreMenu {
    margin-left: -11rem !important;
    margin-top: 0.1rem !important;
  }
  .DropDown_Notification_item_moreMenus {
    margin-left: -10rem !important;
    margin-top: -1.9rem !important;
  }
}
/* ========================1920=============== */
/* @media (min-width: 1920px) {
  .DropDown_Notification_item_moreMenus {
    margin-left: -10rem !important;
    margin-top: -2.9rem !important;
  }
}

@media (max-width: 768px) and (min-height: 1024px) {
  .date {
    position: absolute !important;
    top: -5.5rem !important;
    left: -9rem !important;
    transform: translate3d(327px, 158px, 0px);
  }
}
@media (max-width: 1366px) and (min-height: 768px) {
  .date {
    position: absolute !important;
    top: -5.5rem !important;
    left: -6rem !important;
    transform: translate3d(327px, 158px, 0px);
  }
}
@media (max-width: 1920px) and (min-height: 1080px) {
  .date {
    position: absolute !important;
    top: -5.5rem !important;
    left: 2rem !important;
    transform: translate3d(327px, 158px, 0px);
  }
}

@media (max-width: 1024px) and (min-height: 1366px) {
  .date {
    position: absolute !important;
    top: -5.5rem !important;
    left: -16rem !important;
    transform: translate3d(327px, 158px, 0px);
  }
}
@media (max-width: 2560px) and (min-height: 1440px) {
  .date {
    position: absolute !important;
    top: -5.5rem !important;
    left: 22rem !important;
    transform: translate3d(327px, 158px, 0px);
  }
}
@media (max-width: 849px) {
  .date {
    position: absolute !important;
    top: -5.5rem !important;
    
    transform: translate3d(327px, 158px, 0px);
  }
} */
