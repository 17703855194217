.Search_NavItem {
  /* margin: 5px; */
  background-color: #e6f3db;
  border-radius: 5px;
  width: 211px;
}

.MarketplaceThisWidth {
  width: 159px !important;
}

.RecommendationThisWidth {
  width: 164px !important;
}

.peopletabstext {
  color: #313636;
  font-weight: 500;
  font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
}

.People_designation {
  /* margin-left: 3px; */
  color: #828282;
  font-size: 12px;
  margin-top: 2%;
}

.People_Atlanta {
  color: #828282;
  font-size: 16px;
  /* margin-left: 3px; */
  margin-top: 2%;
}

.People_Sociomate {
  font-size: 12px !important;
  margin-top: 8px !important;
  margin-left: 3%;
  margin: 5px 4px 0px !important;
}

.Article_flitericon {
  color: #81c14b;
  margin: 7%;
  margin-top: 12px;
}

.peopleroundedborder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 107px;
  height: 41px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #81c14b;
  border-radius: 5px;
  margin-left: 20%;
}

.BizPage_text_center {
  font-style: normal;
  font-weight: 500;
  font-size: 18px !important;
  color: #4d4d4d !important;
  margin: 1px 0 4px !important;
  text-align: center;
}

.Bizpagetabs_Sociomate_Center {
  font-style: normal;
  font-weight: 600;
  font-size: 13px !important;
  color: #4d4d4d !important;
  width: 29px;
  height: 33px;
  margin-top: 1%;
}

.dropdownThisitem {
  font-size: 12px !important;
}

.Bizpagetabsfortextcenter {
  font-style: normal;
  font-weight: 400;
  margin: 0px 0 0px !important;
  font-size: 14px !important;
  color: #808080 !important;
  text-align: center;
  font-size: 12px !important;
}

.People_Filter {
  color: #81c14b;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  width: 40px;
  height: 21px;
  margin-top: 3%;
}

.ShotzThisicon {
  top: 2px;
  right: 3px;
}

.GroupTabsThisPage {
  width: 255px;
  padding: 4px;
}

.This-gallery-cont img {
  width: 40px !important;
}

.gallery-img-sublink-this-bizpage {
  position: absolute;
  right: -10px !important;
  top: 4px !important;
  z-index: 2 !important;
}

.BizpageThisRowSize {
  width: 255px !important;
  padding: 4px;
}

.FollowingThisButtonColor {
  background: rgba(129, 193, 75, 0.2);
  border: none;
}

.People_Cancel_button {
  font-family: "Poppins";
  font-style: normal;
  height: 40px;
  font-size: 17px;
  background: rgba(129, 193, 75, 0.2);
  border-radius: 5px;
  border: none;
  color: rgba(0, 0, 0, 0.5);
}

.BizPage_Follow {
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  width: 111px;
  height: 32px;
  color: #808080;
}

.BizPage_Invite {
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  width: 96px;
  height: 32px;
  color: #808080;
}

.Group_Requested {
  background: rgba(129, 193, 75, 0.1);
  border: 1px solid #d1d7cd;
  border-radius: 5px;
  color: #81c14b;
  width: 125px;
  height: 38px;
}

.textThisSocioMate {
  color: #81c14b;
  font-weight: 500;
  font-size: 12px;
}

.ButtonThisHeaderSearchbar {
  background: rgba(129, 193, 75, 0.2) !important;
  border-radius: 5px !important;
  color: #81c14b !important;
  border: none;
  /* font-weight: 500 !important;
  font-size: 12px !important; */
  font-weight: 500;
}

.SearchThisFollow {
  background: rgba(129, 193, 75, 0.1);
  border: 1px solid #d1d7cd;
  border-radius: 5px;
  color: #81c14b;
  width: 96px;
  height: 36px;
  margin-left: 70%;
}

.GroupTabs_Join_Button {
  color: #ffffff;
  background-color: #81c14b;
  width: 200px;
  height: 38px;
  text-align: center;
  border: none;
  border-radius: 5px;
}

/* .Music {
  color: white;
  margin-right: 30px;
} */
.songs {
  color: white;
}

/* .top-gallery-cont, */
.Music-gallery-cont {
  position: absolute;
  bottom: 80px;
  display: flex;
  color: #ffffff;
  margin-bottom: -42px;
}

/* .border_size{
    border: 1px;
    width: 250px;
  } */
.card_size {
  width: 200px;
}

.Shotzs {
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 20.83%,
      rgba(0, 0, 0, 0) 77.14%,
      rgba(0, 0, 0, 0.5) 100%),
    url;
  border: 1.5px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-sizing: border-box;
}

.Icon {
  margin-right: 50px;
}

.rounded-top {
  height: 230px;
  width: 362px;
  border: 1px;
  border-radius: top;
}

.controlledButtonThisDropdown .btn {
  padding: 3px 6px !important;
  text-transform: capitalize;
  font-size: calc(11px + (12 - 11) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
}

.shotztabssongs {
  /* font-family: 'Poppins'; */
  font-size: 11px;
  font-weight: 600;
  /* width: 154px; */
  height: 40px;
  margin-left: 3%;
  color: #ffffff;
  /* margin-left: 6%; */
}

.Badge {
  background: #dedede;
  border-radius: 20px;
  font-size: 10px;
  color: #4d4d4d;
}

.Badge_size {
  font-size: 20px;
}

.rounded_border {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 2px;
  margin-top: 15px;
  border-radius: 8px;
}

.toptitle1 {
  margin-left: 6px;
  /* font-family: "Poppins";
  font-style: normal; */
  font-weight: 500;
  font-size: 16px;
}

.biz-adminprofile-blk img {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.biz-adminprofile-blk {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translate(-50%);
}

.posts {
  color: #81c14b;
  margin-top: 5px;
}

.Shotzs_img {
  height: 50px;
  width: 60px;
  margin-left: 50px;
  border-radius: 3px;
  margin: 2px;
}

/* .Shotzsss {
  margin-left: 10px;
} */

/* .marzin {
  margin-left: 13px;
}

.larzin {
  margin-left: 13px;
} */

/* .toptitleclass {
  margin-left: 71px;
} */

.Shotzsss span {
  position: absolute;
  color: #fdfbfb;
  margin: 20px;
}

.NumFormatterThisNum {
  font-size: 1px;
}

/* .row posts {
  --bs-gutter-x: -1.5rem;
} */

.play {
  width: 67px !important;
}

.NumThiscount {
  margin-top: 26%;
}

.fontThiscolor {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 13px;
}

.RecommendationThisbutton {
  /* background-color: #e9e9e9; */
  position: absolute;
  width: 100%;
  bottom: 9%;
  border-radius: 0 0 10px 10px;
}

.RecommendationThisbuttonSeoundcard {
  /* background-color: #e9e9e9; */
  position: absolute;
  width: 100%;
  bottom: 17%;
  border-radius: 0 0 10px 10px;
}

.TagTrendingTitleThisbuttonSeoundcard {
  /* background-color: #e9e9e9; */
  position: absolute;
  width: 100%;
  bottom: 0%;
  border-radius: 0 0 10px 10px;
}

.Readmore {
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  margin-left: 8px;
  color: #4d4d4d;
}

.TextcolorThisReadMore {
  color: #0066ff;
  font-weight: 700;
  font-size: 11px;
}

.PollThisNumber {
  color: #81c14b;
  font-weight: 600;
  font-size: 18px;
}

.PollThisPosted {
  margin: 6px;
  color: #808080;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}

.posteds {
  margin: 5px;
  color: #4d4d4d;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
}

.Celebration {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 16px;
  color: #231f1f;
}

.Musiccelebration {
  color: #81c14b;
  /* font-style: normal; */
  font-weight: 400;
  font-size: 12px;
}

.paragaraf {
  color: #808080;
  font-size: 12px;
  margin-top: 17px;
}

/* .Dropdown_Menu{
    margin-right: 20px;
  } */
.FrameImg {
  height: 133px;
  text-align: center;
  margin-left: 4px;
}

.Confucious {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.thoughtoftheday {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #d35cd6;
}

.podcastImg {
  height: 200px;
}

.PodcastIcon {
  position: absolute;
}

.podcastLeft {
  position: absolute;
  top: 5px;
  left: 9px;
}

.UncontrolledButtonDropdowntopright {
  position: absolute;
  top: -2px;
  right: -11px;
  font-size: 18px;
}

.textThisColorLearnmore {
  color: #1066ad;
}

.podcasRight {
  position: absolute;
  top: 142px;
  left: 0px;
  background: #f9a321;
  border-radius: 0px 30px 30px 0px;
  width: 66px;
  height: 20px;
  align-items: center;
  padding: 1px;
  padding: 0px 4px 0px 6px;
}

.podcastftBottom {
  position: absolute;
  bottom: 8px;
  left: 9px;
  color: white;
}

.podcastRightBottom {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.micnumber {
  color: #ffffff;
  margin-top: 3px;
}

.paidimg {
  margin-top: 160px;
  position: absolute;
  display: flex;
}

.Pappapoddentext {
  color: white;
  margin-top: 15px;
  margin-left: 8px;
}

.shotztabssongstext {
  margin-right: 3%;
}

.paid {
  position: absolute;
  margin-top: 101px;
  margin-left: 130px;
}

.Miccolor {
  color: white;
}

.numformattercolor {
  background: rgba(255, 255, 255, 0.9);
}

.RecommendationThisTextColor {
  background: rgb(0 0 0 / 33%);
}

.TagTrendingThisTextColor {
  background-color: #e9e9e9;
  border-radius: 0 0 9px 9px;
}

.ThreatTabs_img {
  height: 259px;
  width: 410px;
}

.divcolor {
  background-color: #fffbe5;
  height: 55px;
  width: 298px;
}

.trendingtitle {
  color: #287af4;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin: 3px;
}

.Recommend {
  height: 42px;
  width: 303px;
  position: absolute;
  margin-top: 207px;
}

.recommendation {
  font-weight: 400;
  font-size: 13px;
  color: #808080;
}

.User_Recommend {
  height: 48px;
  width: 303px;
}

/* .btn-primary {
  color: #ffffff;
  background-color: #81c14b;
  border-color: #81c14b;
  height: 37px;
} */
/* .group-slide-box .group-img {
  width: 100%;
  height: 197px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
} */
/* .time {
  color: #81c14b;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
} */
.moreview {
  position: absolute;
  margin-bottom: 10px;
  margin-left: 205px;
  color: #30302f;
  box-shadow: #f6f6f6;
}

.FilterThisButtonDropdown {
  margin: -118% !important;
  /* margin-right: 34% !important; */
  margin-top: -6% !important;
  width: 200%;
}

.LocationThisIcon {
  position: absolute;
  top: 56px;
  right: 15px;
}

.Naba_Keleba {
  color: #0e0d0d;
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-top: 10px;
}

.accordion-button::after {
  /* background: #f6f6f6; */
  /* box-shadow: 0px 0px 9.05714px rgba(0, 0, 0, 0.25); */
  border-radius: 5px;
  position: absolute;
  right: 22px;
  /* top: 28px; */
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.market_place {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #4d4d4d;
  margin-top: 5px;
}

.Marketplace_Moreview {
  position: absolute;
  color: #ffffff;
  margin-left: 200px;
  margin-top: 5px;
}

.Marketplace_Eye_view {
  position: absolute;
  margin-top: 4px;
  margin-left: 6px;
  color: #ffffff;
}

.Marketplace_Count {
  margin-left: 5px;
  margin-top: 2px;
}

.Marketplace_tabs_text {
  /* margin-left: 5px; */
  color: #16c31e;
}

.Marketplace_text {
  color: #808080;
  font-weight: 300;
  font-size: 12px;
}

.TagTrenThisText {
  font-weight: 400;
  font-size: 15px;
  color: #808080;
}

.EventTabs_text_date {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  margin-top: 10px;
  padding-left: 2%;
  color: #81c14b;
}

.EventTabs_MoreHorizontal {
  border-radius: 5px;
  margin-right: 8px;
}

.Event_Tabs_pic {
  position: absolute;
  margin-left: 108px;
  margin-top: 7px;
}

.MapThisBessemer {
  padding-left: 2%;
  padding-top: 3%;
}

.Event_Text {
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
  text-align: start;
  padding-left: 2%;
}

.GoingThis {
  padding-left: 2%;
  padding-top: 2%;
}

.GoingThisInterested {
  padding-top: 2%;
}

.EventTabs_Bessemer {
  color: #808080;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-left: 1%;
}

.Event_Tabs_usersicon {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #808080;
  margin-left: 1px;
}

.Article_Going_Button {
  background: #f0f0f0;
  border-radius: 5px;
  border: none;
  color: #808080;
}

.Article_Button_Interested {
  background: #81c14b;
  border-radius: 5px;
  border: none;
  height: 42px;
  width: 104px;
  margin-bottom: 6px;
  color: #ffffff;
}

.ArticleTabs_date_month {
  color: #808080;
  margin-left: 6px;
  margin-top: 13px;
}

.Article_Sports {
  margin-right: 5px;
  color: #808080;
  margin-top: 13px;
}

.ArticleTabs_Liverpool {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
  margin-left: 2%;
}

.Article_Liverpool_Champions {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #808080;
  margin: 6px;
  text-align: justify;
  text-align: start;
}

.Artical_tabs {
  position: absolute;
  color: #4d4d4d;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  display: flex;
  margin-left: 94px;
  text-align: center;
  margin-top: 8px;
  height: 34px;
}

.Article_like_thumble {
  color: #4d4d4d;
}

.TheatTabs_avatar {
  margin-bottom: 10px;
}

.headerpagefor_SocioMate {
  margin-left: 107px;
  color: #81c14b;
  font-weight: 500;
  font-size: 12px;
}

.headerpageThisFollowing {
  margin-left: 110px;
  color: #81c14b;
  font-weight: 500;
  font-size: 12px;
}

.header_for_button {
  border: none;
  margin-left: 53px;
  color: #81c14b;
  background: rgba(129, 193, 75, 0.2);
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 28px;
}

.headertextcolor {
  color: #81c14b;
  font-weight: 500;
}

.header_popup {
  /* margin: 10px; */
  /* background-color: red; */
  position: absolute;
}

/* .custom-dropdown .dropdown-menu {
  top: 3px;
} */
.inputgroupfor {
  background: #f6f6f6;
  border-radius: 5px;
  color: #f6f6f6;
}

.form-controler {
  /* width: 100% !important; */
  height: 39px;
  border-radius: 5px;
  color: #f6f6f6;
  padding-left: 30px;
  border: none;
  background: #f6f6f6;
}

.peoplesearchbar {
  width: 467px;
  margin-top: 15px;
}

.This {
  height: 249px;
  width: 303px;
}

.AlltabThisGroup.bizpagelink-blk {
  margin: 0 0 0px !important;
}

.headerforxicon {
  position: absolute;
  right: 23px;
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  color: #ffffff;
}

.Ariticalright {
  position: absolute;
  top: 6px;
  right: 5px;
  color: white;
}

.Articalimgright {
  position: absolute;
  top: 6px;
  right: 25px;
  color: white;
}

.MessageCircleright {
  position: absolute;
  right: 50px;
  top: 1px;
  color: white;
}

.TopRight_Section {
  position: absolute;
  top: 8px;
  right: 1px;
  display: flex;
  flex-direction: row;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  align-items: center;
  padding: 5px 10px;
  gap: 15px;
}

.TopRight_Section p {
  font-weight: 400;
  font-size: 12px;
  color: #4d4d4d;
}

.page-center_slider {
  overflow-x: scroll;
  overflow-y: hidden !important;
  flex-wrap: nowrap !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #ffffff;
  background-color: #81c14b;
}

.Peopletabforseeallcolor {
  color: #81c14b;
}

.drops.custom-dropdown.dropdown-menu {
  top: -1px !important;
  border: none;
  padding: 0;
  -webkit-box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
  min-width: 16rem;
  z-index: 2;
}

.SerachThisButtonleft {
  position: absolute;
  top: 13px;
  left: 21px;
}

/* .dropdown-menus {
  position: absolute;
  will-change: transform;
  top: -125px;
  left: -145px;
  transform: translate3d(0px, 46px, 0px);
  min-width: 100px !important;
} */
.marketplaceright {
  position: absolute;
  top: -5px;
  right: -11px;
  color: white;
}

.Marketplacecenter {
  width: 239px;
  height: 27px;
  font-size: 17px;
  color: #4d4d4d;
  margin-top: 5px;
}

.EventforTabsright1 {
  background: #ff9922;
  width: 115px;
  height: 27px;
  position: absolute;
  color: #ffffff;
  right: 0px;
  top: 1%;
  border-radius: 20px 0px 0px 20px;
}

.EventforTabsright2 {
  background: #ff9922;
  width: 82px;
  height: 27px;
  position: absolute;
  color: #ffffff;
  right: 0px;
  top: 1%;
  border-radius: 20px 0px 0px 20px;
}

.EventforTabsright3 {
  background: #ff9922;
  width: 157px;
  height: 27px;
  position: absolute;
  color: #ffffff;
  right: 0px;
  top: 1%;
  border-radius: 20px 0px 0px 20px;
}

.EventforTabsright4 {
  background: #ff9922;
  width: 126px;
  height: 27px;
  position: absolute;
  color: #ffffff;
  right: 0px;
  top: 1%;
  border-radius: 20px 0px 0px 20px;
}

.InterestedThisColor {
  background: #f0f0f0;
  color: #ef8100;
  border: none;
}

.btnThisheight {
  height: 40px;
  width: 124px;
}

.peopleoftext {
  color: #313636;
  font-weight: 500;
}

.peoplebuttonright {
  right: 14px;
}

/* .AllThisTextalign {
  padding-right: 2%;
} */

.peopletabstext {
  font-style: normal;
  font-weight: 500;
  color: #4d4d4d;
  font-size: medium;
}

.MarketplaceThis {
  font-weight: 500;
  font-size: 17px;
  color: #4d4d4d;
}

.Shotztabstext {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
}

.dropdownitem.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #f9fbf7 !important;
}

.bizpagefordropdownmenu {
  position: absolute;
  will-change: transform;
  top: -34px !important;
  /* left: -59px !important; */
  transform: translate3d(-182px, 68px, 10px) !important;
  width: fit-content;
  font-size: 12px !important;
}

.UncontrolledButtonDropdowntoprightSearchBar {
  position: absolute;
  top: 3px;
  right: 9px;
  font-size: 18px;
}

.grouptittlecenter {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #4d4d4d;
  margin-top: 6%;
  text-align: center;
}

.grouptabsforpublic {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4d4d4d;
  margin-top: 3%;
  margin-left: 12%;
}

.grouptabsformembers {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4d4d4d;
  margin-top: 2%;
}

.groupThisbizpage {
  position: relative;
  background-color: #287af4 !important;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

/* .ThisBizpageAvatar {
text-align: center;
} */
.ThoughtThisbackgroundcolor {
  background-color: #871515;
  border-radius: 10px;
  margin: 6px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12);
}

.ThoughtThisbackgroundcolor1 {
  background-color: #ffffff;
  border-radius: 10px;
  margin: 6px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12);
}

.ThoughtThisbackgroundcolor2 {
  background-color: #00a3ff;
  border-radius: 10px;
  margin: 6px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12);
}

.ThisThoughtTabs {
  text-align: center;
  color: #f9fbf7;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 31px;
  padding: 12%;
  height: 166px;
}

.ThisThoughtTabs1 {
  text-align: center;
  color: #871515;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 31px;
  padding: 12%;
  height: 166px;
}

.ThisThoughtTabs2 {
  text-align: center;
  color: #ffe6b7;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 31px;
  padding: 12%;
  height: 166px;
}

.ThoughttabsthisLeftquotationIcon {
  position: absolute;
  margin-top: 11%;
  margin-left: 2%;
  font-size: 24px !important;
}

.ThoughttabsthisRightquotationIcon {
  position: absolute;
  /* margin-bottom: 6%; */
  margin-top: 20%;
  margin-left: 90%;
  font-size: 24px !important;
}

.mqText {
  font-size: 24px !important;
}

@media only screen and (max-width: 600px) {
  .ThoughttabsthisLeftquotationIcon {
    font-size: 14px;
  }

  .ThoughttabsthisRightquotationIcon {
    font-size: 14px;
    margin-left: 88%;
  }

  .mqText {
    font-size: 12px !important;
  }
}

.ThreatThistabs1 {
  background-color: #ffd600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 184px;
  padding: 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 2%;
}

.ThreatThistabs2 {
  background: #ff822e;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 184px;
  padding: 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ThreatThistabs3 {
  background: #ff1010;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 201px;
  padding: 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.Alartthispagetext1 {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #222222;
  padding: 14px;
}

.Alartthispagetext2 {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
  padding: 14px;
}

.Alartthispagetext3 {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
  padding: 14px;
}

.TagThisTrendingTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #4d4d4d;
}

.TagThisCountPost {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #808080;
}

.TagThisTitle {
  font-weight: 500;
  font-size: 15px;
  color: #4d4d4d;
}

.TagThisAmmotivation {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #287af4;
  margin: 6px;
}

.TagThistrendingtext {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #808080;
}

.TagThisButton {
  background-color: #e9e9e9;
  position: absolute;
  width: 100%;
  bottom: 0%;
  border-radius: 0 0 10px 10px;
}

.TagThisButtonPlay {
  position: absolute;
  top: 48%;
  left: 38%;
  text-align: center;
}

.buttonThis.btn {
  padding: 17px 20px !important;
}

.ButtonThisTagTrending.btn {
  padding: 10px 13px !important;
  text-transform: capitalize;
  font-size: calc(11px + (12 - 11) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
}

.ThreatThisText1 {
  position: absolute;
  top: 94px;
  right: 8px;
  background: #ff822e;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.ThreatThisText2 {
  position: absolute;
  top: 94px;
  right: 8px;
  background: #ffd600;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.ThreatThisText3 {
  position: absolute;
  top: 76px;
  right: 8px;
  background: #ff822e;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.ThreatThisMild1 {
  font-weight: 600;
  color: #ffffff;
  width: 43px;
  text-align: -webkit-center;
}

.NumThisFormatter {
  position: absolute;
  left: -6px;
  top: 65%;
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  /* background-color: rgba(255, 255, 255, 0.456); */
}

.ThreatThisMild2 {
  font-weight: 600;
  color: #ffffff;
  width: 93px;
  text-align: -webkit-center;
}

/* .MsgThisIcon {
  color: white;
} */

.ImgThisTransparent {
  width: 100%;
  height: auto;
}

.ThreatThisMild3 {
  font-weight: 600;
  color: #ffffff;
  width: 76px;
  text-align: -webkit-center;
}

.AllTagsThis.row {
  --bs-gutter-x: -0.5rem !important;
}

.GroupThisBlank {
  text-align: center;
}

.BadgeThisColor {
  background: rgb(229, 229, 229);
  border-radius: 17px;
}

.DropdownThisFilter {
  font-weight: 400;
  font-size: 16px;
  color: #4d4d4d;
}

.TextThiscolorWithin {
  font-weight: 500;
  font-size: 13px;
  color: #4d4d4d;
}

.MarketplaceThislist {
  font-weight: 500;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.TextThisHighestColor {
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
  padding: 7%;
}

.ThreattabsthisLeftquotationIcon {
  position: absolute;
  top: 122px;
  left: 17px;
  font-size: 18px;
}

.ThreatttabsthisRightquotationIcon {
  position: absolute;
  bottom: 107px;
  right: 25px;
  font-size: 18px;
}

.css-187mznn-MuiSlider-root {
  border-radius: 12px;
  box-sizing: content-box;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  color: #81c14b !important;
  -webkit-tap-highlight-color: transparent;
  height: 4px;
  width: 100%;
  padding: 13px 0;
}

/* @media (min-width: 375px) and (max-width: 600px) {
  .TagThisButton {
    background-color: #e9e9e9;
    position: absolute;
    width: 301px;
    bottom: 1%;
    border-radius: 0 0 10px 10px;
  }
} */