.borderforImgpostPreview {
    border-top: 0px double #E6E3E1;
    border-left: 0px double #E6E3E1;
    border-right: 0px double #E6E3E1;
}

.rowflexforImgpostPreviews {
    border-top: 0px double #E6E3E1;
    border-left: 0px double #E6E3E1;
    border-bottom: 0px double #E6E3E1;
    padding: 0 0.9rem 0 0.9rem;
}

.rowflexforImgpostPreview {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
}

.throughtInputBox {
    background-color: transparent;
    /* margin: 55px; */
    margin-top: 55px;
    margin-bottom: 55px;
    text-align: center;
    border: none;
    font-size: 24px;
    font-weight: 500;
    width: 100%;
}
.throughtInputBox:focus {
    /* border: transparent; */
    outline-color: transparent !important;
}

.LeftquotationIconpostion {
    position: absolute;
}

.RightquotationIconpostion {
    position: absolute;
    bottom: 1%;
    left: 97%;
}

.react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon, .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
    stroke: #81C14B;
    color: #81C14B;
}

/* .gr-1 {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(#ffffff));
    background-image: linear-gradient(to top, #ffffff 0%, #ffffff 100%);
} */
.thought-input{
    border: 1px solid rgb(217 211 211) !important;
    width: 100% !important;
    height: 125px !important;
}
.thought-input::placeholder {
 color: grey !important;
 font-size: 16px !important;
 font-weight: 600 !important;
}
.button-anchorThought{
    margin-right: 24px !important;
    margin-top: 10px;
    color: #bbbbbb !important;
    /* background-color:  rgb(73, 73, 73) !important; */
    border: 1px solid #bbbbbb !important;
    border-radius: 50px !important;
    padding: 0px 4px 0px 4px !important;
    font-size: 12px !important;
}
.thought-danger{
    color:#FF007A;
    font-weight: 600;
    margin-bottom: 4px !important;
    margin-left: -24px !important;
    padding-bottom: 8px !important;
}
.create-post .create-bg .thoughBgPost {
    display: none;
    width: 100%;
    height: 150px;
    border-radius: 5px;
    position: relative;
}
/* .postBtn{
    color: white !important;
    background-color: #ACDAD6 !important;
    font-size: 14px !important;
} */

.hashTagHead{
    font-size: 14;
    font-weight: 500;
    color: #4d4d4d;
    margin-top: 20px;
    margin-bottom: 30px !important;
}

.hashTagPlaceholder ::placeholder{
    color: #000000 !important;
    font-size:medium;
}
.detectLocation{
    color:#81C14B
}
.locationImg{
    height: 16px !important;
    margin-top: -8px !important;
}
.rightQuotes{
    margin-top: -44px !important;
}
.colorIcons{
    padding-top: 4px;
    margin-left: -6px !important;
    height: 22px;
}
.thtFlexContainer{
    display: flex; 
}

