.form-control:disabled,
.form-control[readonly] {
  background-color: white;
  opacity: 1;
}

.hideborders {
  /* border: none; */
  display: none;
}
.hidebordertext {
  border: none;
}
.hide-text {
  margin-left: -17px;
}
.dfdsfdf {
  border: none;
}
.dropdownText {
  margin: 0 auto;
  z-index: 10000;
  width: 300px;
  position: relative;
}
.dropdownText,
.dropdownText * {
  z-index: 10;
  width: auto;
}
.start-text {
  margin-top: -25px;
  margin-left: 28%;
  color: gray;
}
.start-texts {
  position: absolute;
  /* top: 129px; */
  color: gray;
  margin-top: 4px;
  left: 38%;
}
.dropdownText .dropdownText-btn {
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #777;
  font-weight: 500;
}
.text-field{
  margin-left: -14px;
}
.input-minus-text{
  box-shadow: #51595e;
}
.dropdowncon-content {
  /* position: absolute; */
  /* top: 110%; */
  left: 0;
  width: 100%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
}
.dropdowncon-content .item {
  padding: 10px;
  cursor: pointer;
}
.dropdowncon-content .item:hover {
  background: rgb(229, 240, 224);
}

/* button {
  z-index: -1;
  display: block;
  width: 300px;
  margin: 0 auto;
} */

.css-1g9qunv-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent !important;
}

.badge-warning {
  color: #808080;
  background-color: rgba(255, 193, 7, 0.5) !important;
}

.badge-danger {
  color: #fff;
  background-color: rgba(220, 53, 69, 0.5);
  border: 1px solid rgba(220, 53, 69, 0.5) !important;
}

.WQNxq {
  overflow-x: auto !important;
}

.WQNxq::-webkit-scrollbar {
  height: 5px;
  /* for Chrome, Safari, and Opera */
}

.fuLPYh {
  overflow-x: auto !important;
}

.fuLPYh::-webkit-scrollbar {
  height: 5px;
  /* for Chrome, Safari, and Opera */
}

.AnalyticsForAnime {
  animation-duration: 2s;
  /* background: whitesmoke; */
  animation-iteration-count: 1, 0;
  transform-origin: bottom;
  transition: 0.4s ease-in-out;
}

.AnalyticsForAnime:hover {
  transform: translateY(-7px);
  /* box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25); */
}

.FundsForstyle {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.FundsForstyle h2 {
  margin: 0;
  color: #51595e;
  font-size: calc(27px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.UserEngagemendtStyle {
  text-align: center;
  display: flex;
}

.UserEngagemendtStyle div {
  padding: 10px 20px;
  text-transform: capitalize;
  font-size: calc(11px + (12 - 11) * ((100vw - 320px) / (1920 - 320)));
  /* font-family: 'Montserrat'; */
  width: 100%;
  margin: 1%;
  border-radius: 3px;
  font-weight: 400;
}

.divactive {
  background: #81c14b;
  color: #ffffff;
}

.divInctive {
  background: #f6f6f6;
  color: #4d4d4d;
  border: 1px solid #cfc9c971;
}

.UserEngagemendtStyleCard {
  overflow-y: scroll;
  max-height: 350px;
  overflow-x: hidden;
}

.UserEngagemendtStyleCard::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.UserEngagemendtStyleCard div {
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #cfc9c971;
}

/* .btn {
  font-family: 'Montserrat';
} */

/* .FontForSame {
  font-family: 'Montserrat';
} */

.fontForSubCardheading > h2 {
  /* font-family: 'Montserrat'; */
  position: relative;
  width: 430px;
  font-weight: 500;
  color: #4d4d4d;
}

.fontForSubCardheading > h3 {
  /* font-family: 'Montserrat'; */
  font-weight: 500;
  color: #4d4d4d;
}

.fontForSubCardheading > h4 {
  /* font-family: 'Montserrat'; */
  font-weight: 500;
  color: #4d4d4d;
}

.fontForSubchildCardheading > h5 {
  /* font-family: 'Montserrat'; */
  font-weight: 400;
  color: #4d4d4d;
}

.fontForSubchildCardheading > h6 {
  /* font-family: 'Montserrat'; */
  font-weight: 400;
  color: #808080;
  line-height: 24px;
}

/* table */

.fontForSubTablheading > h4 {
  /* font-family: 'Montserrat'; */
  font-weight: 400;
  color: #4d4d4d;
}

.fontForSubTablheading > h5 {
  /* font-family: 'Montserrat'; */
  font-weight: 400;
  color: #4d4d4d;
}

.OnMouseDataShow {
  opacity: 0;
}

.rdt_TableRow:hover .OnMouseDataShow {
  opacity: 1;
}

.react-daterange-picker__wrapper {
  border: none !important;
  border-radius: 3px;
}

.react-calendar__tile--active {
  background: #81c14b !important;
  color: white;
}

.react-calendar__tile--now {
  background: #81c14b33 !important;
}

.react-daterange-picker__button:enabled:hover
  .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus
  .react-daterange-picker__button__icon {
  stroke: #81c14b !important;
}

.react-daterange-picker,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  box-sizing: border-box;
  background: #e6f3db !important;
  border-radius: 4px !important;
  padding: 7px !important;
}

.DynamicFilterForStyle {
  background: #e6f3db;
  padding-inline-start: 0px;
  padding-inline-end: 10px;
  border-radius: 5px;
}

.underLineForLink:hover .underlinetext {
  /* text-decoration: underline;
  text-decoration-color: blue; */
  border-bottom: 1px solid blue;
}

.AddInfoBorder {
  border-radius: 6px 5px 5px 6px;
  -webkit-border-radius: 6px 0px 0px 6px;
  -moz-border-radius: 6px 0px 0px 6px;
  border-left: 6px solid #ff6550;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.css-igs3ac {
  text-align: left;
  position: absolute;
  inset: 0 !important;
  margin: 0px;
  padding: 0 !important;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 0 !important;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.css-1bp1ao6.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: none !important;
  border-width: 0 !important;
}

.css-gjbq6i-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom: 2px solid #81c14b !important;
}

.boderForactive {
  border-bottom: 2px solid #81c14b;
}

.ads-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.color-grey {
  color: #c4c4c4;
}

.ad-title {
  margin-left: 1rem;
}

.fontWeightBold h4 {
  font-weight: 600;
  /* font-family: 'Montserrat'; */
  padding-bottom: 10px;
}

.fontWeightBold h5 {
  font-weight: 400;
  /* font-family: 'Montserrat'; */
  padding: 6px 0px;
}

.unset {
  position: unset;
  background-color: #f9fbf7 !important;
  position: unset;
  box-shadow: unset !important;
}

.nav-flex {
  display: flex !important;
  align-items: center;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cus-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: -6px;
}

.com {
  background: white;
  padding: 1rem;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin-right: 8px;
}

.max-width {
  width: 70px !important;
  max-height: 70px;
  border-radius: 10px;
}

.img-con {
  display: flex;
}

.linkForstyLe {
  color: #81c14b;
  border: 1px solid #81c14b;
  font-weight: 500;
}

.linkForstyLe:hover {
  color: #ffffff;
  font-weight: 500;
}

.scrollForAdmanagerFilter {
  overflow-y: scroll;
  max-height: calc(100vh - 50px);
  overflow-x: hidden;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 27px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: #81c14b;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:focus + .slider {
  box-shadow: 0 0 1px #81c14b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #c4c4c4;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  border: 1px solid #81c14b;
}

.slider.round:before {
  border-radius: 50%;
}

.adserch-menu {
  background: transparent !important;
  margin: 0 !important;
}

.adserch-btn {
  background: transparent !important;
  color: #81c14b !important;
  font-size: medium !important;
}

.sidebar-bg {
  /* background: white !important;
  min-width: 10% !important; */
  box-shadow: rgba(17, 17, 26, 0.1) 1px 0px 0px;
}

.sidebar-padding {
  padding: 0.5rem;
}

.table-link {
  display: flex;
  justify-content: space-around;
}

.table-link > span > a {
  color: rgb(0, 40, 116);
}

.ad-tr-bg {
  background: #e5f0e0;
}

.ad-bg-com-2 {
  background: #f6f6f6;
  padding: 1rem;
}

.profile-menu-new ul li {
  margin: 20px 0 0px 15px !important;
}

.profile-menu-new ul li a.active {
  background: #e5f0e0;
}

.profile-menu-new ul li a.active h6 {
  color: #81c14b;
  stroke: #81c14b;
}

.table tbody tr {
  background: white;
}

.com-btn {
  display: flex;
  float: right;
  margin-top: 1rem;
}

.ts-padding {
  padding: 1rem 8rem !important;
}

.ts-img-pad {
  padding: 1rem;
}

.success {
  padding: 0.5rem;
  background: #e5f0e0;
  color: #81c14b;
  border-radius: 20px;
}

.failed {
  padding: 0.5rem;
  background: rgba(221, 121, 121, 0.12);
  color: #dd7979;
  border-radius: 20px;
}

.max-width-ts {
  max-width: 18% !important;
}

.margin-add-fud {
  margin: 1rem;
}

.add-width {
  max-width: 800px;
}

.ad-model-header {
  background-color: #f6f6f6;
}

.ad-model-title {
  color: black;
  font-weight: 800;
  margin-bottom: 0.5rem;
}

.add-footer {
  justify-content: center !important;
}

.add-btn {
  width: 90% !important;
}

.margin-add-fud-bg:hover {
  background-color: #81c14b;
  border-radius: 5px;
  border: 1px solid #81c14b;
  color: #ffffff;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  cursor: pointer;
}

.model-height {
  max-height: 500px !important;
}

.profile-menu-new ul li a {
  border: 1px solid #e5f0e0;
  background: white;
  padding: 0.5rem 2rem !important;
}

.ad-search-bar {
  width: 180%;
}

.ad-filters {
  display: contents;
}

.ad-manager-filter {
  padding: 1rem;
  background: #dedede;
  border-radius: 10px;
}

.table-link > span > a {
  color: #0d6efd;
  text-decoration: underline;
}

.dropedownForFilter.show {
  display: block;
  width: revert;
}

input[type="checkbox"] {
  accent-color: #81c14b;
}

.customcheckboxForFilter
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: #81c14b;
  color: #eff0ef !important;
}

.formToolForAnime {
  animation-duration: 2s;
  background: whitesmoke;
  animation-iteration-count: 1, 0;
  transform-origin: bottom;
  transition: 0.4s ease-in-out;
}

.formToolForAnime:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}

.formToolForPreview {
  animation-duration: 2s;
  animation-iteration-count: 1, 0;
  transform-origin: bottom;
  transition: 0.4s ease-in-out;
}

.formToolForAnime svg {
  cursor: pointer;
}

.bounce:hover {
  animation-name: bounce;
  animation-timing-function: ease-in-out;
}

@keyframes bounce {
  0% {
    transform: translateY(50px);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  }

  50% {
    transform: translateY(0);
  }

  /* 100% {
    transform: translateY(0);
  } */
}

/* -------------------------------- */

.navs-tab-list {
  background: #ffffff !important;
  border-radius: 6px 6px 0px 0px !important;
  /* font-family: 'Montserrat' !important; */
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  white-space: nowrap;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px 30px; */
  text-align: center !important;
  color: #5e5873 !important;
}

.navs-tab-lists {
  color: #4d4d4d !important;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
}

.navs-tab-lists.active {
  color: #81c14b !important;
  background-color: #81c14b33 !important;
  border: 2px solid #81c14b !important;
}

.btnForRefresh {
  background-color: #e5f0e0;
  border-radius: 5px;
  color: #4d4d4d;
}

.colorForBadgePrimary.badge {
  color: #81c14b;
  background-color: rgba(129, 193, 75, 0.12);
  border-radius: 17px;
}

.colorForBadgedanger.badge {
  color: #dd7979;
  background-color: rgba(221, 121, 121, 0.12);
  border-radius: 17px;
}

.ButtondisableForbutton:disabled {
  color: #ffffffa2;
  background-color: #4d4d4d93;
  border-color: whitesmoke;
}

.widthFordropemenu {
  will-change: transform;
  top: 0px;
  left: -50% !important;
  transform: translate3d(-46px, 38px, 0px);
  width: max-content !important;
}

.rowForrevert.row {
  --bs-gutter-x: revert !important;
}

.sizingForTextsmall {
  font-size: 0.6rem;
}

.sizingForTextvsmall {
  font-size: 0.5rem;
}

.sizingForTextheading {
  font-size: 0.6rem;
}

.postionFormic {
  position: absolute;
  top: 2.8rem;
  left: 3.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ffffff;
}

.postionFortag {
  position: absolute;
  background-color: #f9a321;
  width: 14%;
  text-align: center;
  border-radius: 30px 0px 0px 30px;
  top: 4.5rem;
  right: 0.3rem;
}

.customeForprogressbar {
  height: 0.28rem !important;
  /* position: relative; */
  width: 100% !important;
  /* left: -0.5rem; */
}
.rowForreverts {
  background-color: rgb(249, 251, 247);
}
.customeForprogressbar .progress-bar {
  background-color: #28a745 !important;
  height: 0.5rem;
}

.colorForbottom {
  background-color: rgba(0, 0, 0, 0.493);
  position: absolute;
  top: 85%;
  width: 100%;
  left: 0%;
}

.colorStoriesForbottom {
  background-color: rgba(0, 0, 0, 0.493);
  position: absolute;
  top: 95%;
  width: 100%;
  left: 0%;
}

.colorStoriesForbottomsmall {
  background-color: rgba(0, 0, 0, 0.493);
  position: absolute;
  top: 75%;
  width: 100%;
  left: 0%;
}

.whyAdForPosition {
  position: relative;
  top: 85%;
}

.whyAdForShotzPosition {
  position: relative;
  top: 98%;
}

.whyAdForShotzPositionsmall {
  position: relative;
  top: 88%;
}

.hbotQl {
  white-space: revert !important;
}

.PaymentmodalForStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background: #f6f6f6;
  border-bottom: #f6f6f6 1px solid;
}

.amount label {
  color: #4d4d4d;
  font-weight: 500;
}

.ConfirmatioForstyle {
  margin-top: 3%;
}

.ConfirmatioForstyle h5 {
  color: #4d4d4d;
  padding: 2% 0%;
  margin-top: 4%;
}

.ConfirmatioForstyle h3 {
  padding: 2% 0%;
}

.modalForPayHead {
  color: #4d4d4d;
  font-weight: 500;
}

.payForIcon img {
  width: 15%;
  aspect-ratio: 3/2;
  object-fit: contain;
  margin-right: 5%;
  /* mix-blend-mode: color-burn; */
}

.onMouseHovePayment:hover {
  background-color: #e5f0e0;
}

.currencyicon {
  position: absolute;
  left: 19px;
  bottom: 26px;
}

.Amounticon {
  position: absolute;
  left: 1.99%;
  right: 10.01%;
  top: 46%;
  bottom: 20%;
}

.ScrollForTab {
  overflow-y: auto;
  height: 500px;
}

.marginForheader {
  margin-right: 5% !important;
  /* display: ; */
}

.postitionForcount {
  position: absolute;
  right: 15px;
  top: 1px;
  border-radius: 100%;
  background-color: red;
  color: white;
  padding: 1px 5px;
  font-size: x-small;
}

.postionForOnlinestatus {
  position: absolute;
  right: 155px;
  top: 15px;
}

.dashboardForstyle {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 2;
  /* width: min(100% - 10px, 1840px); */
  /* margin-inline: auto; */
}

.paddingForform {
  padding: 0rem 25rem;
}

.rotate-text {
  writing-mode: vertical-rl;
  /* "rl" stands for right-to-left */
  text-align: center;
  /* to center the heading within the div */
  transform: rotate(180deg);
  position: absolute;
  top: 30%;
}

.mobile-frame {
  position: relative;
  width: 320px;
  height: 588px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.mobileAd-card {
  position: sticky;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  padding: 20px;
  overflow: auto;
}

.monitor-frame {
  position: relative;
  width: 600px;
  height: 550px;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
}

.monitorAd-card {
  position: absolute;
  top: 90px;
  left: 20px;
  right: 20px;
  /* bottom: 20px; */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  overflow: auto;
}

.mobile-frame-carousel {
  position: relative;
  width: 320px;
  height: 480px;
  border: 2px solid #ddd;
  border-radius: 16px;
  overflow: hidden;
}

.mobile-frame-video {
  position: relative;
  width: 320px;
  height: 480px;
  border: 2px solid #ddd;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.video-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 48px;
  color: #fff;
  opacity: 0.8;
  cursor: pointer;
}

.play-button:hover {
  opacity: 1;
}

.mobile-frame-full-video {
  position: relative;
  width: 320px;
  height: 480px;
  border: 2px solid #ddd;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-container-full-screen {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-thumbnail-full-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.story-bar {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.3);
}

.story-progress {
  height: 100%;
  width: 50%;
  background-color: #81c14b;
  animation: story-progress 10s linear infinite;
}

@keyframes story-progress {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

/* MEDIA */

@media (max-width: 575px) {
  .paddingForform {
    padding: 0rem;
  }

  .sidebar-bg {
    display: none;
  }

  .sizingForTextsmall {
    font-size: 0.5rem;
  }

  .sizingForTextvsmall {
    font-size: 0.4rem;
  }

  .sizingForTextheading {
    font-size: 0.5rem;
  }

  .postionFormic {
    position: absolute;
    top: 2.7rem;
    left: 3.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ffffff;
  }

  .adForCol-1.col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 4.33333%;
  }

  .adForCol-2.col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 95.66667%;
  }
}

@media (max-width: 375px) {
  .postionFormic {
    position: absolute;
    top: 2.6rem;
    left: 3.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ffffff;
  }

  .postionFortag {
    position: absolute;
    background-color: #f9a321;
    width: 20%;
    text-align: center;
    border-radius: 30px 0px 0px 30px;
    top: 2.8rem;
    right: 0.3rem;
    font-size: 0.3rem;
  }

  .ad-buttons-blk {
    display: flex;
    justify-content: center;
  }

  .customeForprogressbar {
    height: 0.25rem !important;
    position: relative;
    width: 18rem !important;
    left: -10px;
  }

  .responsiveForTag.ad-count-btn {
    width: 50px !important;
  }

  .adForCol-1.col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 4.33333%;
  }

  .adForCol-2.col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 95.66667%;
  }
}
/* -------------------------mobile view css------------------------ */
/* @media (max-width: 350px) {
  .header-section-One {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden; 
  }
} */
/* navtext-item */
/* Adjust the breakpoint value as needed */

@media (max-width: 768px) {
  .fixed-header {
    /* Keep the header fixed and adjust its width */
    position: fixed;
    width: 100%;
    max-width: 100%; /* Use full width for screens <= 768px */
    left: 0;
  }
}
@media (max-width: 768px) {
  .navtext-item {
    margin-left: 22px;
  }
}

/* Adjust the layout for mobile view */
/* Adjust the layout for mobile view */
/* @media (max-width: 768px) {
  .nav-item {
    width: 100%;
  }
} */

@media (max-width: 768px) {
  .nav-item {
    display: grid;
    /* grid-template-columns: repeat(2, 1fr);  */
    gap: 0.2rem; /* Add gap between items */
  }
  .nav-link {
    line-height: 1.2;
    white-space: nowrap;
  }
}
@media (max-width: 768px) {
  .nav-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Display two items per row */
    gap: 0.1rem; /* Add gap between items */
  }
  .nav-link {
    line-height: 1.2;
    white-space: nowrap;
  }
}
.nav-link {
  font-size: 14px; /* Adjust as needed */
  line-height: 1.5; /* Adjust as needed */
}

/* ===================list and grid css=================== */
@media (max-width: 768px) {
  .nav-flex {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .nav-flex > div {
    margin-bottom: 1rem;
  }
}
@media (max-width: 752px) {
  .small-main-text {
    margin-left: 113px !important;
    margin-top: -124px !important;
  }
}

/* =====================mobile css========================= */
/* Adjustments for mobile view */
@media (max-width: 767px) {
  .main-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }

  .filter-section {
    margin-bottom: 15px;
    margin-left: -0.1rem;
  }

  .filter-content {
    flex-direction: column;
  }

  .toggle-filter {
    flex-direction: column;
    align-items: flex-start;
    margin-top: -2rem;
  }

  .search-input {
    margin-top: -2rem;
    /* margin-left: -0.5rem; */
  }
  /* Add this CSS class to your stylesheet */
  /* .custom-checkbox {
  
      color:  white;
    } */

  .date-and-export {
    flex-direction: column;
    align-items: flex-start;
    margin-top: -1rem;
    margin-right: -2.9rem;
  }
}
@media (max-width: 912px) {
  .runningdrop {
    margin-left: -2rem !important;
  }
}
@media (max-width: 912px) {
  .runningdroplist {
    margin-left: 6rem !important;
  }
}
@media (min-width: 1200px) {
  .posttext-pre {
    margin-left: 3px !important;
  }
}

@media (min-width: 1366px) {
  .AdDurationForStyle {
    margin-top: -41px !important;
  }
}
@media (min-width: 318px) and (max-width: 900px) {
  .radio-text {
    margin-top: -29px !important;
  }
}
@media (min-width: 280px) and (max-width: 1366px) {
  .start-text {
    margin-top: -25px;
    margin-left: 44%;
    color: red;
  }
}
@media (min-width: 280px) and (max-width: 1366px) {
  .start-texts {
    margin-top: 4px;
    margin-left: 24%;
    color: red;
  }
}
@media (max-width: 1080px) {
  .lead-form {
    position: absolute !important;
    left: 32rem !important;
  }
}
