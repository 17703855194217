.styleForTextFooter {
    color: #A6A6A6;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background: #F6F6F6;
    border-radius: 15px;
}

.styleForTextFooter h5 {
    color: #A6A6A6;
    font-weight: 500;
}

.styleForTabDesigne {
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    padding: 10px;
    border-radius: 0px 4px 0px 0px;
}

.styleForTabDesigneMob {
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    padding: 12px 4px;
    border-radius: 0px 4px 0px 0px;
}

.userSelectiontab:hover {
    background: #F6F6F6;
    border-radius: 5px;
}


.matual-emtion-blk {
    display: grid;
    grid-template-columns: auto auto auto 25px;
    grid-gap: 0px;

}

.matual-emtion-blk li {
    margin-left: -10px;

}

.BookmarkForPosition {
    position: absolute;
    top: -20px;
    right: -5px;
    z-index: 2;
}

.opacityForactiveScreen {
    position: relative;
    -webkit-box-shadow: 10px 10px 200px 5000px rgba(0, 0, 0, 0.61);
    -moz-box-shadow: 10px 10px 200px 5000px rgba(0, 0, 0, 0.61);
    box-shadow: 10px 10px 200px 5000px rgba(0, 0, 0, 0.61);
    border-radius: 7px;
    z-index: 3;
    transition: 0.4s ease-in-out;
    height: auto;
}

.postCardForopacity {
    background: transparent;
    border-radius: 5px;
}

.scrollForHastag {
    overflow: scroll;
    overflow-x: hidden;
    height: 150px;
}


.positionforAddonText {
    color: #808080;
    background: whitesmoke;
    border-radius: 5px;
    padding: 0.25rem;
    position: absolute;
    left: 10px;
    top: 10px;
}

.scrollForMedia {
    overflow: scroll;
    overflow-x: hidden;
    height: 500px;
    padding: 0.5rem;
}

.paddingForPostdiscardcrossdisaapered {
    padding-top: 0.4rem;
    padding-bottom: 0.8rem;
    position: absolute;
    top: -40px;
}

.paddingForPostdiscardAppered {
    padding-top: 1.5rem;
    padding-bottom: 1.0rem;
}

.fixedforModal {
    height: 100vh;
    overflow: hidden;
}

.pointerForfreez {
    cursor: none;
    pointer-events: none;
}

.pointerForworked {
    cursor: auto !important;
    pointer-events: all !important;
}

.PostiotionForCrossIcon {
    position: absolute;
    right: 10px;
    top: 10px;
}

.GridViewForAllImage {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.imgResposiveForUpload img {
    object-fit: fill;
    width: 100%;
    height: 250px;
    border-radius: 5px 5px 0 0;
}

.imgResposiveForUpload small {
    color: #999999;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}


.AccordionForSrtyle .accordion-button {
    padding: 1rem .5rem;
    color: #4D4D4D;
    font-weight: 600;
    font-size: 16px;
}


@media (max-width: 767px) {
    .hidden-mobile {
        display: none;
    }

    .content-center-slider.content-center {
        max-height: revert;
        overflow-y: revert;
        overflow-x: hidden;
    }

    .toggle-sec .toggle-btn .knobs span {
        left: 6px;
        top: 4px;
        /* background-color: #81c14b !important; */
        z-index: 1;
    }

}

.Social_Feed_ShadowBox {
    box-shadow: none;
}