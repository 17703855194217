@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.react-datetime-picker__wrapper {
  border: none !important;
}

.fr-btn .confirm-btn:hover {
  color: #fff !important;
}

.phone-code-list:hover {
  cursor: pointer;
  border: 1px solid rgba(27, 139, 27, 0.445) !important;
}

.active-phone-code {
  border: 1px solid rgba(27, 139, 27, 0.445) !important;
}

.thought-input::-webkit-input-placeholder {
  color: #fff !important;
  opacity: 1;
}

.thought-input::placeholder {
  color: #fff !important;
  opacity: 1;
}

.static-margin {
  margin-left: 10px;
}

/* OVERRIDE PROFILE RADIUS */
.conversation-panel .friend-section .friend-list .friend-box .user-img img {
  border-radius: 25% !important;
}

.react-datetime-picker__calendar {
  z-index: 2 !important;
}

.without-input-fill:disabled {
  background: #acdad6;
  border-color: #acdad6;
}

.without-input-fill {
  background: #81c14b;
  border-color: #81c14b;
}

.button-anchor {
  color: #81c14b !important;
  border: none;
  background: none;
  font-weight: 500;
}

.checkbox_animated2 input[type="checkbox"] + label:after {
  content: "\2714";
  border: 0.1em solid rgba(100, 117, 137, 0.45);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  padding-left: 0;
  padding-bottom: 0;
  margin-right: 10px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
  line-height: 1;
}

.checkbox_animated2 input[type="checkbox"]:checked + label:after {
  background-color: #81c14b;
  border-color: #81c14b;
  color: #fff;
}

.checkbox_animated2 input[type="checkbox"] {
  display: none;
}

.checkbox_animated2 input[type="checkbox"] + label:active:after {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.checkbox_animated2 input[type="checkbox"]:checked:disabled _ label:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  background-color: rgba(3, 137, 201, 0.2);
  border-color: rgba(3, 137, 201, 0.2);
}

.checkbox_animated2 input[type="checkbox"]:disabled _ label:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  border-color: #aaa;
}

.checkbox_animated2 input[type="checkbox"] + label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.media-img-vid-addmore {
  position: absolute;
  top: 20px;
  margin-left: 6rem;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  background-color: #fff;
  display: inline-block;
  z-index: 1;
}

.media-img-vid-edit {
  position: absolute;
  top: 20px;
  margin-left: 5px;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  background-color: #fff;
  display: inline-block;
  z-index: 1;
}

.media-img-close-icon .iw-20 {
  border: 1px solid #e0e0e0;
  stroke: #e0e0e0;
  border-radius: 50%;
  background: white;
}

.media-img-close-icon {
  position: absolute;
  right: 20px;
  top: 5px;
  display: inline-block;
  z-index: 1;
}

.input-minus {
  position: absolute;
  right: 9%;
  top: 50.4%;
  background: #ffffff;
  border: 1px solid #81c14b;
  border-radius: 3px;
  width: 4.5%;
}
/* .input-minuss {
  position: absolute;
  right: 9%;
  top: 50.4%;
  background: #ffffff;
  border: 2px solid #81c14b;
  width: 27px;
  height: 3px;
} */
@media (max-width: 360px) {
  /* .event-card-text {
    margin-left: -11px !important;
    font-size: 10px !important;
  } */
  .matual-friend-section {
    font-size: 10px !important;
    margin-left: -9px !important;
  }
  /* .BizBTN_sections {
    width: 78px !important;
    font-size: 10px !important;
  } */
  .input-Events_search-blks {
    margin-right: 92px !important;
  }
  .create-event-text {
    margin-top: -124px !important;
    margin-right: -6px !important;
    font-size: 8px !important;
  }

  /* .Events_Cards_Top_section{
    position: absolute!important;
    top: -16.5rem!important;
    height: 120px!important;
  } */
  .hidden-text {
    display: none !important;
  }
  /* .upcomming-btn {
    width: 140px !important;
    margin-left: -6px !important;
  }
  .filter-btn {
    margin-top: -82px!important;
    margin-right: 2px!important;
    width: 175px;
  } */
}
@media (min-width: 360px) and (max-width: 500px) {
  .Events_Cards_Top_section {
    height: 44px;
    position: absolute;
    bottom: 62px;
    width: 142px !important;
    left: -29px;
  }
  .form-text-profile {
    font-size: 10px !important;
  }
  .text-profile {
    position: absolute;
    top: 2px;
    left: 4px;
  }
  .Events_Cards_Top_sections {
    width: 150px !important;
    height: 102px;
    bottom: 2px;
  }
  .event-card-texts {
    font-size: 12px !important;
  }
  .hidden-text {
    display: none !important;
  }
}
@media (min-width: 600px) {
  .Group_Top_Cover_Buttom_LeftSection {
    position: absolute !important;
  }
}
@media (min-width: 640px) and (max-width: 360px) {
  .Events_Cards_Top_section {
    display: none !important;
  }

  .form-text-profile {
    font-size: 9px !important;
  }
  .text-profile {
    position: absolute;
    top: 2px;
    left: 35px;
  }
  .Events_Cards_Top_sections {
    width: 150px !important;
    height: 102px;
    bottom: 2px;
  }
  .event-card-texts {
    font-size: 12px !important;
  }
  .web-text {
    display: none !important;
  }
}
@media only screen and (min-width: 600px) {
  .event-card-text {
    /* margin-left: -11px !important;
    font-size: 10px !important; */
  }
  .Events_Cards_Top_section {
    /* position: absolute;
    left: 17rem;
    top: -6.5rem;
    width: 200px !important;
    height: 150px; */
    /* bottom: 72px; */
    /* left: -15px;
    width: 217px !important;
    bottom: 2.7rem; */
  }
}
@media (max-width: 320px) {
  /* .BizBTN_sections {
    width: 66px !important;
    font-size: 8px !important;
  } */
}
@media (max-width: 353px) {
  /* .BizBTN_sections {
    margin-left: 12px;
  } */
  .group-search-assign-filter {
    /* margin-right: 70px; */
  }
  .mobile-text-edit {
    position: absolute;
    right: -19rem;
  }
  .Events_Cards_Top_sections {
    position: absolute;
    left: -1rem;
    height: 36px;
    bottom: 70px;
  }
  .profile-date {
    position: absolute;
    top: -4px;
    left: 131px;
  }
}

@media (min-width: 712px) and (max-width: 1138px) {
  .Events_Cards_Top_section {
    position: absolute;
    bottom: -5rem;
  }
}
@media (min-width: 712px) {
  .canvas1-text {
    margin-bottom: 8rem;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  /* .Events_Cards_Top_section {
    position: absolute !important;
    bottom: -6rem !important;
  } */
}
@media (min-width: 1920px) and (max-width: 1080px) {
  .Events_Cards_Top_section {
    margin-left: 2rem !important;
    width: 372px !important;
    margin-top: 5rem !important;
  }
}
@media (min-width: 800px) and (max-width: 1280px) {
  .Events_Cards_Top_section {
    position: absolute;
    bottom: -10rem;
  }
  /* .canvas1-text{
    margin-bottom: 18rem;
  } */
}
@media (min-width: 800px) {
  .canvas1-text {
    /* margin-bottom: 36%; */
  }
}
@media (min-width: 820px) {
  .canvas1-text {
    /* margin-bottom: 26%; */
  }
}
@media (max-width: 353px) {
  .Events_Cards_Top_section {
    height: 44px;
    position: absolute;
    bottom: 62px;
    width: 131px !important;
    left: -2rem;
  }
  .form-text-profile {
    font-size: 9px !important;
  }
  .text-profile {
    position: absolute;
    top: 2px;
    left: 4px;
  }
}

@media (min-width: 1366px) {
  .Events_srch_block {
    /* width: 300px !important; */
    /* margin-right: -81px; */
  }
}
@media (min-width: 600px) {
  .cal-text {
    position: absolute;
    top: -38px;
  }
}
@media (min-width: 375px) {
  .cal-text {
    position: absolute;
    top: -68px;
  }
  .rem-mob-view-texts {
    display: none;
  }
}
@media (min-width: 540px) {
  .cal-text {
    margin-top: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .group-search-assign-filter .Events_srch_block {
    width: 154px !important;
    margin-top: -7px;
    padding-left: 22px;
  }
  .Events_Cards_Top_section {
    position: absolute;
    top: -100px;
    height: 153px;
  }
  .event-categories-text {
    margin-left: 19px;
    text-wrap: wrap !important;
  }
}

.all-hashtags {
    display: flex;
    flex-direction: row;
}

.hashtag-icob>img {
    width: 20px;
}

.css-1xr9krm.Mui-selected {
    background-color: #81C14B;
}

.hashtag-stat h4 {
    font-size: 1rem;
    line-height: 18px;
    color: #4D4D4D;
    font-weight: 500;
    cursor: pointer;
    margin: 0 0 4px;
}

.hashtag-stat h4:hover {
    text-decoration: underline !important;
    color: #81C14B !important;
}

.hashtag-stat h5 {
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
    color: #808080;
    font-weight: 400;
    line-height: 18px;
}

.more-tags-inn {
    align-items: center;
    text-transform: capitalize;
    margin-top: 20px;
    color: #81C14B;
    font-weight: 500;
    background-color: rgba(237, 247, 251, 0.25);
    padding: 8px 15px;
    border-radius: 5px;
}

.group-custom-block-hashtag {
    background: white;
    padding: 1rem;
}

.heading-podcast-blk-new {
    flex-direction: column;
    align-items: unset !important;
}

.group-btn-new {
    width: 100% !important;
}

.heading-podcast-blk-new p {
    color: #4D4D4D;
}

.Socialgallery img {
    /* border-radius: 5px; */
    /* width: 100%; */

}

img.img-fluid.Grallery-frstImg {
    border-radius: 5px 0 0 0 !important;
}

img.img-fluid.Grallery-ScndImg {
    border-radius: 0 5px 0 0 !important;
}

img.img-fluid.Grallery-ThirdImg {
    border-radius: 0 0 0 5px !important;
}

img.img-fluid.Grallery-FourthImg {
    border-radius: 0 0 5px 0 !important;
}

.SocialgallerySubChild {
    /* margin-top: 1px; */
    position: relative;
}

.SocialgallerySubChild img {
    opacity: 0.6;
}

.SocialgallerySubChildCount {
    /* color: yellowgreen; */
    position: absolute;
    top: 50%;
    left: 50%;
    /* width: 100%; */
    text-align: center;
    font-size: 18px;
    color: black;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.content-center-slider.content-center {
    max-height: 2200px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.content-center-slider.content-center::-webkit-scrollbar {
    display: none;
}

.headerFormain {
    position: relative;
    color: #4D4D4D;
}

.headerFormain .activeForIcon {
    position: absolute;
    top: -2px;
    right: 130px;
    font-size: 18px;
}

.form-check-input:checked {
    background-color: #81C14B;
    border-color: #81C14B;
}

.card {
    border: none;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
}


.shareForButton {
    background: rgba(126, 178, 98, 0.1);
    border-radius: 5px;
    padding: 8px 8px;
    color: #81C14B;
}


.SeeAllEventButton>h5 {
    color: #fff;
    cursor: pointer;
    font-weight: 600;
}

.SeeAllEventButton>h5:hover {
    text-decoration: underline;
    font-weight: 600;
}


.CommonHeadingSeg>h2 {
    color: #4D4D4D;
    font-weight: 500;
    line-height: 21px;
    /* font-family: 'Montserrat'; */
}

.CommonHeadingSeg>h5 {
    color: #808080;
    font-weight: 400;
    line-height: 21px;
    /* font-family: 'Montserrat'; */
}

.profileForCount>h3 {
    color: #000000 !important;
    line-height: 18px;
    font-weight: 500;
    /* font-family: 'Montserrat'; */
}

.profileForCount>h5 {
    color: #808080 !important;
    line-height: 18px;
    font-weight: 400;
    /* font-family: 'Montserrat'; */
}


.theBirthdayCardTitle {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.theBirthdayCardTitle>* {
    flex: 1 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: clamp(16px, 2vw, 24px);
}

.Events_Img_CardBlk {
    width: 100% !important;
    object-fit: cover !important;
    border-radius: 5px 5px 0px 0px !important;
}

.profileCard_box {
    background: #FFFFFF !important;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1) !important;
    border-radius: 5px !important;
}

.Group_suggestion_card {
    padding: 15px;
    background: #FFFFFF;
    border-radius: 5px;
}

.Crad_Blk_section {
    border-radius: 5px;
    position: relative;
    text-align: center;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.Img_Blk_section {
    position: relative;
    margin: 0 0 30px;
    display: block;
}

.Crad_Blk_section img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
}

.adminprofile-blk {
    position: absolute;
    bottom: -23px;
    left: 33px;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
}

.Card_Content_blk {
    position: absolute;
    bottom: -23px;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    text-align: start;
}

.adminprofile-blk img {
    background: #fff;
    -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
            filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
}

.Card_Content_blk h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 6px;
}

button.Biz_PageMore_btn.btn.btn-outline-primary {
    padding: 0;
    width: 20px;
    /* border-radius: 100%; */
    height: 20px;
    border: 0;
}

button.Biz_PageMore_btn.btn.btn-outline-primary:hover {
    background: none;
}

button.Biz_PageMore_btn.btn.btn-outline-primary {
    background-color: #FFFFFF !important;
    background: none !important;
}

.Events_buttom_section {
    align-items: center;
    position: absolute;
    padding: 15px;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100% !important;
}

.Events_buttom_section h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #FFFFFF;
}

:root {
    --updateStoryGradient: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))
}

.Events_bottom_rght_blk {
    position: absolute;
    bottom: 8px;
    right: 8px;
}

.Icon_Blk_section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    width: 34px;
    height: 34px;
}

.event-box .event-content:after {
    position: absolute !important;
    top: 0 !important;
}

.Group_Join_btn_blk {
    padding: 6px 12px;
}

.Suggestion_Grp_Img {
    width: 100%;
    object-fit: cover;
    height: 130px;
    border-radius: 5px 5px 0 0;
}

.Grpupmember_Main_blk span {
    /* margin: 3px 4px; */
    font-size: 13px;
    display: inline-block;
    color: #4d4d4d;
    /* font-weight: 600; */
}

.groupBottom-custom-block {
    margin: 0 0 10px !important;
}

.Events_button_section {
    font-size: 18px;
    /* Default font size */
}

@media screen and (max-width: 768px) {
    .Events_button_section {
        font-size: 16px;
        /* Font size for mobile devices */
    }
}

@media screen and (max-width: 480px) {
    .Events_button_section {
        font-size: 14px;
        /* Font size for smaller mobile devices */
    }
}

.eventTextByText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.seeall-btn a:hover {
    color: #81C14B !important;
    text-decoration: underline !important;
}

.time-hours-blk a:hover {
    text-decoration: underline !important;
    color: #81C14B !important;
}

.FollowRequest_Accept_btn {
    padding: 6px 24px !important;
}

.FollowRequest_Reject_btn {
    padding: 6px 24px !important;
    border: 1px solid #c4c4c4 !important;
}

.GroupSSliderCustomizes>.slick-prev:before {
    content: "\2039" !important;
    color: white !important;
    background: rgba(85, 85, 85, 0.5);
    border-radius: 0px 10px 10px 0px;
    padding: 5px 2px;
    padding-bottom: 16px !important;

    /* chevron left icon */
}

.GroupSSliderCustomizes>.slick-next:before {
    content: "\203A" !important;
    color: white !important;
    background: rgba(126, 178, 98, 0.8);
    padding: 5px 2px;
    padding-bottom: 16px !important;
    background: rgba(85, 85, 85, 0.5);
    border-radius: 10px 0px 0px 00px;
}

.GroupSSliderCustomizes>.slick-prev {
    left: -2px !important;
}

.GroupSSliderCustomizes>.slick-next {
    right: -2px !important;
}

.Biz_Img_Box img {
    border-radius: 5px;
}

.LikeBizUser h4 {
    margin-bottom: 0 !important;
}
.Notifications {
  height: 100px;
  width: 900px;
  margin-left: 100px;
}

.Filterc_Notifications {
  margin-left: 822px;
}

.Header_Notification {
  width: 127px;
  height: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-left: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.Button_interted {
  margin-top: -20px;
}

.Filter_Notifications {
  margin-right: 20px;
}

.Droupdown_seemore {
  margin-right: 20px;
}

.Today {
  color: #81c14b;
  margin-left: 6px;
  font-weight: 500;
  font-size: 13.34px;
}

.Header_Droupdown {
  margin-left: 530px;
  box-sizing: border-box;
  width: 71px;
  height: 28px;
}

.Button {
  margin-left: 10px;
  margin-top: 6px;
}

.Vertical {
  color: #808080;
  size: 10px;
  height: 20px;
  width: 20px;
  margin-top: 10px;
  margin-left: 67px;
}

.main:hover {
  text-decoration: none;
  outline: none;
  background-color: #f6f6f6;
}

.PopoverActionSubHeading {
  display: flex;
  justify-content: space-between;
  margin-left: -7px;
}

.PopoverActionSubHeading span {
  color: #808080;
  cursor: pointer;
}

.Filterb {
  margin-left: 78px;
  margin-top: 1px;
  color: #81c14b;
}

.content {
  max-height: 8rem;
  overflow: hidden;
  position: relative;
}

.Modar2 {
  width: 600px;
}

.Filterc {
  margin-left: 822px;
  color: #81c14b;
}

/* .Vertical-dropmenu{
    margin-left: 34px;
  } */
.Size {
  margin-left: 2px;
  margin-top: 1px;
  size: 12px;
}

.CardInfoBackGround {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(129, 193, 75, 0.1);
  border-radius: 5px;
  width: 800px;
  height: 768px;
}

.Sell-center {
  padding-left: 194px;
  padding-right: 12px;
}

.margin {
  margin-left: 12px;
}

.notification_seemore {
  width: 127px;
  height: 30px;
  margin-left: 1%;
  /* font-family: 'Poppins'; */
  /* font-style: normal; */
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #4d4d4d;
}

.CardHeader_Seemore {
  background-color: #f6f6f6;
  border-bottom: 8px;
  margin-top: 0%;
}

.cardheaderforseemore {
  background-color: transparent !important;
  border: none;
}

.cardbody_Seemore {
  margin-top: -4%;
}

.Notification_width {
  width: 10%;
}

.Filter_Notification {
  margin-left: 5px;
}

.thatNotification_Filterbtn {
  margin-left: 2% !important;
}

.Thatdroupdown_notification {
  top: -18px !important;
  border: none;
  padding: 0;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
  min-width: 16rem;
  z-index: 2;
}

.thatdropdownnotification.show {
  display: block;
  width: revert;
  top: -6px !important;
  margin-left: -173px;
}

.Thatcustom-dropdown .dropdown-menu {
  top: 20px !important;
  border: none;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  min-width: 16rem;
  z-index: 2;
  margin-left: -130px !important;
}

.notification-btn-ThatNotificationdropdown {
  position: relative;
  min-width: 13rem !important;
  height: 130px !important;
  padding: 8px !important;
}

.ThatHeader {
  background-color: #e5f0e0 !important;
  margin-top: -8px;
}

button.My_Connection_MoreBtn_blk\.btn-group.btn.btn-flat-secondary {
  margin-right: -22px !important;
}

.thatdropmenu {
  margin-top: 5%;
}

/* .scrollbarThatFixed {
  width: 3px;
  height: 4px;
  overflow: auto;
  color: red;
} */

.scrollForNotification {
  overflow-y: scroll;
  max-height: calc(90vh - 90px);
  overflow-x: hidden;
}

.scrollForNotification::-webkit-scrollbar-thumb {
  background-color: #8abe46;
  /* set the color of the scrollbar thumb */
}

.CardThisHover:hover {
  background-color: #e6f3db !important;
}

.headerThishightwidth {
  font-size: small;
  margin-top: 21px;
  margin-right: 2px !important;
}

.notificationThisfontsizedropdownmenu {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 102px !important;
  -webkit-transform: translate3d(-199px, -6px, 0px) !important;
          transform: translate3d(-199px, -6px, 0px) !important;
  min-width: 242px !important;
  font-size: small;
}

.notificationThisfontsizeseemore {
  /* position: absolute;
  will-change: transform;
  top: 0px; */
  left: -42px !important;
  /* transform: translate3d(-197px, 11px, 0px) !important; */
  -webkit-transform: translate3d(-140px, 16px, 0px) !important;
          transform: translate3d(-140px, 16px, 0px) !important;
  /* min-width: 242px !important; */
  font-size: small;
}

.seemorethisoption {
  font-size: small;
  margin-top: 21px;
  -webkit-transform: translate3d(0px, 40px, 0px);
          transform: translate3d(0px, 40px, 0px);
}

.no-fade-modal {
  transition: none;
}

.notificationThisfontsizeseemore.perfect-size {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

/*================== deepak_Notifocation_css  ==========================*/
.Notification_Main_blk_section {
  /* background: #FFFFFF; */
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4); */
  border-radius: 5px;
}

/* .thatNotificationdropdown.mt-2.Notification_Showing_section.dropdown-menu::after {
  content: "";
  position: absolute;
  top: -10px !important;
  left: 41% !important;
  width: 0 !important;
  height: 0 !important;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #ffffff;
} */

.Notification_top_section {
  margin-top: -9px;
  padding: 10px !important;
  border-radius: 5px 5px 0 0;
  background: #e5f0e0;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Notification_top_section h3 {
  color: black;
  font-weight: 600;
  font-size: 18px;
}

.Notification_More_OptionMenu {
  /* background: rgba(0, 0, 0, 0.1);
  border-radius: 20px; */
  cursor: pointer;
  height: 25px;
  width: 25px;
}
.Notification_TopDown_blk {
  padding: 10px !important;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
}

.Notification_TopDown_blk h3 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
}

.Notification_TopDown_blk p {
  color: #81c14b;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.Notification_BottomDown_blk {
  padding: 10px !important;
  background: #ffffff;
}

.Notification_profile-blk {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background: #f6f6f6;
}

.Notification_profile-blk_Nxt {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.Notification_profile-blk_Nxt p {
  color: #808080;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.Notification_profile-blk_Nxt h4 {
  font-weight: 600;
  font-size: 14px;
  color: #4d4d4d;
  margin: 0 0 4px;
}

.Notification_profile-blk h4 {
  font-weight: 600;
  font-size: 15px;
  color: #4d4d4d;
  margin: 0 0 4px;
}

.Notification_profile-blk p {
  color: #808080;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.DropDown_Notification_item.dropdown-menu.show {
  -webkit-transform: translate3d(23px, 21px, 0px) !important;
          transform: translate3d(23px, 21px, 0px) !important;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%) !important;
  border-radius: 7px !important;
}

button.DropDown_Notification_items.dropdown-item:hover {
  color: #4d4d4d !important;
  background-color: #e6f3db !important;
}

button.DropDown_Notification_items.dropdown-item {
  color: #4d4d4d;
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 500;
  font-size: 16px;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

button.ml-2.Notification_More_OptionMenu.btn.btn-secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1) !important;
  border-radius: 20px !important;
  padding: 0 !important;
  border: none !important;
}

.DropDown_Notification_item_moreMenu.dropdown-menu.show {
  -webkit-transform: translate3d(-29px, 15px, 0px) !important;
          transform: translate3d(-29px, 15px, 0px) !important;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%) !important;
  border-radius: 7px !important;
}
.DropDown_Notification_item_moreMenus.dropdown-menu.show {
  -webkit-transform: translate3d(-29px, 15px, 0px) !important;
          transform: translate3d(-29px, 15px, 0px) !important;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%) !important;
  border-radius: 7px !important;
}

button.Notification_More_OptionMenuProfile.btn.btn-flat-primary {
  padding: 0 !important;
  border: none !important;
}

.Notification_aCCEPECT_section {
  display: block;
  /* background: #f6f6f6; */
  color: white;
  font-weight: 500;
  font-size: 14px;
  width: 50%;
  border-radius: 5px;
  padding: 6px 12px;
  outline: none;
  background-color: #81c14b;
  border: none;
  /* padding: 10px 20px; */
}

.Notification_Fill_section {
  display: block;
  font-weight: 500;
  font-size: 14px;
  width: 50%;
  border-radius: 5px;
  padding: 6px 12px;
  border: none;
  outline: none;
  color: #4d4d4d;
  /* padding: 10px 20px; */
}

.Notification_Main_scroll_box {
  /* max-height: 440px !important; */
  height: 45rem !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.Notification_Action_Blk {
  color: #808080;
  font-size: 15px;
  color: #808080;
  font-weight: 500;
}

button.NotificationTop_Filter.btn.btn-outline-primary {
  background: rgba(255, 255, 255, 0.5) !important;
  border: 1px solid #81c14b !important;
  border-radius: 5px !important;
  padding: 5px 10px !important;
  grid-gap: 10px !important;
  gap: 10px !important;
}

button.NotificationTop_Filter.btn.btn-outline-primary:hover {
  background-color: #81c14b !important;
  color: #ffffff !important;
}

.CardThisHover_Crad_Box_Section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DropDown_Notification_item.dropdown-menu_Block {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  unicode-range: U+00-7F;
}

.Update_Report_Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 1px solid red;
  border: 2px solid green;
  unicode-range: U+100-17F;
}

.Report_Updation_Modal_Section_Blk {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border-radius: 1px solid red;
}

.Button_interted_Block_Sectikon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  -webkit-text-decoration: double;
          text-decoration: double;
  text-align: justify;
  padding: 0;
  margin: 0;
  border-radius: 1px solid green;
  grid-gap: 10px;
  gap: 10px;
  position: absolute;
  left: 5px;
  top: 50px;
  bottom: 10px;
  right: 30px;
  max-width: 100%;
}

.Button_interted_Block_Sectikon_Block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
}
.date {
  position: absolute;
  top: 4.2rem;
  right: 50px;
}
/* =====================add 360 css===================== */
@media screen and (max-width: 360px) {
  .date {
    right: unset;
    left: 8%;
  }
}
@media (min-width: 360px) {
  .Notification_More_OptionMenu {
    margin-left: -1rem !important;
  }

  .filterNotification {
    position: absolute !important;
    will-change: transform !important;
    top: 7px !important;
    left: -7rem !important;
  }
  .DropDown_Notification_item_moreMenu {
    margin-left: -11rem !important;
    margin-top: 0.1rem !important;
  }
  .DropDown_Notification_item_moreMenus {
    margin-left: -10rem !important;
    margin-top: -1.9rem !important;
  }
}
/* ========================1920=============== */
/* @media (min-width: 1920px) {
  .DropDown_Notification_item_moreMenus {
    margin-left: -10rem !important;
    margin-top: -2.9rem !important;
  }
}

@media (max-width: 768px) and (min-height: 1024px) {
  .date {
    position: absolute !important;
    top: -5.5rem !important;
    left: -9rem !important;
    transform: translate3d(327px, 158px, 0px);
  }
}
@media (max-width: 1366px) and (min-height: 768px) {
  .date {
    position: absolute !important;
    top: -5.5rem !important;
    left: -6rem !important;
    transform: translate3d(327px, 158px, 0px);
  }
}
@media (max-width: 1920px) and (min-height: 1080px) {
  .date {
    position: absolute !important;
    top: -5.5rem !important;
    left: 2rem !important;
    transform: translate3d(327px, 158px, 0px);
  }
}

@media (max-width: 1024px) and (min-height: 1366px) {
  .date {
    position: absolute !important;
    top: -5.5rem !important;
    left: -16rem !important;
    transform: translate3d(327px, 158px, 0px);
  }
}
@media (max-width: 2560px) and (min-height: 1440px) {
  .date {
    position: absolute !important;
    top: -5.5rem !important;
    left: 22rem !important;
    transform: translate3d(327px, 158px, 0px);
  }
}
@media (max-width: 849px) {
  .date {
    position: absolute !important;
    top: -5.5rem !important;
    
    transform: translate3d(327px, 158px, 0px);
  }
} */

.story-preview-page {
  width: 400px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    199.09deg,
    #81c14b 0%,
    #155b46 69.23%,
    #083737 100%
  );
  border-radius: 10px;
  font-size: 15px;
  text-decoration: underline;
  align-self: center;
  align-self: center;
  font-style: italic;
  color: #ffffff;
}

.story_sidebar {
  /* width: 24%; */
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(129, 193, 75, 0.2);
  /* position: fixed; */
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* overflow: auto; */
  scrollbar-width: none;
  -ms-overflow-style: none;

} 

.story_side ::-webkit-scrollbar{
  display: none;
}

/* .StoryPostButtonForUniversal {
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
} */

.story_content {
  /* margin-left: 24%; */
  /* width: 75%; */
  align-items: center !important;
  justify-content: center !important;
  align-content: center !important;
  height: 100% !important;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(6px) !important;
          backdrop-filter: blur(6px) !important;
}

.story_inner_content {
  background: #ffffff;
  /* margin: 15px; */
  /* padding: 10px; */
  border-radius: 10px;
  align-self: center;
}

.change-background-scroll {
  height: calc(100vh / 3);
  max-height: calc(100vh / 3.5);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  /* gap: 4px; */
}

.change-background-scroll_filter {
  height: calc(100vh / 3);
  max-height: calc(100vh / 3.5);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 18px;
  grid-gap: 12px;
  gap: 12px;
}

.change-background-scroll > div {
  flex: 1 1 auto;
  aspect-ratio: 4/4;
  width: calc(100% / 7);
  max-width: calc(100% / 7);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 !important;
  /* margin: 0px 0px !important; */
}

.change_background_scroll_filter > div {
  flex: 1 1 auto;
  aspect-ratio: 4/4;
  width: calc(100% / 4);
  max-width: calc(100% / 4);
  /* padding: 0 !important; */
  /* margin: 0px 0px !important; */
}

.BackgroundFilterImg {
  transition: all 0.1s ease-in-out;
  aspect-ratio: 4/4 !important;
}

.BackgroundFilterImg:hover {
  box-shadow: 10px 10px 30px -5px rgba(0, 0, 0, 0.4);
}

.storyTextRotationBtn {
  /* padding: 5px !important; */
  border-radius: 100% !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  height: 20px;
  margin-bottom: -10px;
  margin-right: -10px;
  z-index: 3;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .change-background-scroll > div {
    width: calc(100% / 4);
    max-width: calc(100% / 4);
  }

  .change_background_scroll_filter > div {
    flex: 1 1 auto;
    aspect-ratio: 4/4;
    width: calc(100% / 3);
    max-width: calc(100% / 3);
    /* padding: 0 !important; */
    /* margin: 0px 0px !important; */
  }
}

@media (max-width: 900px) {
  .change-background-scroll > div {
    width: calc(100% / 4);
    max-width: calc(100% / 3);
  }

  .change_background_scroll_filter > div {
    flex: 1 1 auto;
    aspect-ratio: 4/4;
    width: calc(100% / 4);
    max-width: calc(100% / 4);
    /* padding: 0 !important; */
    /* margin: 0px 0px !important; */
  }
}

.scroll-selecter {
  height: 200px;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

.story_sidebar a {
  display: block;
  padding: 16px;
  text-decoration: none;
}

.story_sidebar a.active {
  background-color: #04aa6d;
  color: white;
}

.story_sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

.backgroun_style {
  background-size: cover !important;
  border: 3px solid #ffffff !important;
  border-radius: 5px !important;
}

.backgroun_style:hover {
  background-size: cover !important;
  border: 3px solid #81c14b !important;
  border-radius: 5px !important;
}

.story-switch-tab {
  display: flex;
  justify-content: end;
  flex-direction: row;
  inline-size: -webkit-max-content;
  inline-size: max-content;
}

.fab_btn_success {
  color: #ffffff !important;
  stroke: #ffffff !important;
  fill: #ffffff !important;
  background-color: #81c14b !important;
}

.Create_story_container {
  position: relative;
}

/*--------------- View Story Modal css ----------------- */
.modal-container .modal-content {
  background: transparent !important;
  border: none;
}

.story_profress_bar progress {
  height: 5px !important;
}

.progress-custome-color {
  height: 10px !important;
  background-color: #81c14b !important;
  border-radius: 5px !important;
}

.text-area-box {
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 5px;
  line-break: auto;
  white-space: pre-wrap;
}

.img-top-head {
  position: absolute;
  top: 10px;
  color: #ffffff;
  border-radius: 15px !important;
  display: flex;
  justify-content: space-between;
}

.Story_Creation_Preview_section {
  /* background: linear-gradient(199.09deg, #81C14B 0%, #155B46 69.23%, #083737 100%); */
  border-radius: 10px !important;
  align-items: center;
  line-break: auto !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden !important;
  color: #ffffff !important;
  aspect-ratio: 9/16;
  height: calc(100vh / 1.2);
  /* height: 750px;
  width: 500px; */
}

.border_style {
  padding: 5px !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%2381C14BFF' stroke-width='3' stroke-dasharray='50%2c 14' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  border-radius: 5px;
}

.border_style_disable {
  padding: 5px !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%234D4D4DFF' stroke-width='3' stroke-dasharray='40%2c 14' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  border-radius: 5px;
}

.circle-border-style {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%2381C14BFF' stroke-width='3' stroke-dasharray='50%25%2c 13%25' stroke-dashoffset='86' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 100px;
}

.img-view-container {
  border-radius: 10px !important;
  position: relative !important;
  background-color: #c4c4c4 !important;
  align-content: center !important;
  flex-direction: column !important;
  justify-content: center !important;
  display: flex !important;
  /* height: 780px !important; */
  background-size: cover !important;
  /* min-height: 600px; */
}

.img-edit-container {
  border-radius: 5px !important;
  display: flex;
  position: relative !important;
  background-color: #c4c4c4 !important;
  justify-content: center;
  align-content: center !important;
  align-items: center;
  height: 750px;
  overflow: hidden;
  max-height: 100%;
  max-width: 100%;
}

/* 
.img-edit-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 33%;
  border-right: 1px solid white;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  Set the background color of the mask
  z-index: 1;
  Set the z-index to be higher than the image to position it on top of the image
}

.img-edit-container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 33%;
  border-left: 1px solid white;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  Set the background color of the mask
  z-index: 1;
  Set the z-index to be higher than the image to position it on top of the image
} */

.img-text-style {
  position: absolute;
  text-align: center;
  font-size: 18px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.35) !important;
  border-radius: 5px;
  padding: 10px !important;
  background-color: transparent;
  line-break: anywhere !important;
}

.text-container {
  position: absolute;
  top: 0;
  width: 93%;
  margin-left: 14px;

  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.text-Contianer-Mobile {
  position: absolute;
  top: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  height: -webkit-fit-content;

  height: -moz-fit-content;

  height: fit-content;
  text-align: center;
  font-size: 18px;
  vertical-align: middle;
  z-index: 1;
}

.ParenMobiletextContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TextMobileView {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  z-index: 3;
}

.img-text-container {
  position: absolute;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  overflow: hidden !important;
  font-size: 30px;
}

.text-area-box-img {
  background-color: transparent !important;
  outline: none !important;
  max-height: 98% !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  max-width: 100% !important;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.35) !important;
  border-radius: 5px;
  padding: 10px !important;
  /* word-wrap: calc(); */
  overflow-wrap: break-word;
  font-weight: 500;

  /* float: left; */
  /* overflow: hidden; */
}

.text-area-box-pre {
  background-color: transparent !important;
  outline: none !important;
  font-size: 30px;
  max-height: 100% !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  max-width: 100% !important;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.35) !important;
  border-radius: 5px;
  padding: 10px !important;
  /* word-wrap: calc(); */
  overflow-wrap: break-word !important;
  font-weight: 500;
  overflow: hidden;
}

.input-color-container {
  position: relative;
  overflow: hidden;
  width: 35px;
  height: 35px;
  border: solid 2px #ddd;
  border-radius: 35px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.input-color {
  position: absolute;
  right: -8px;
  top: -8px;
  width: 56px;
  height: 56px;
  border: none;
}

/* .StoryMobileColorPicker {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;

} */

.StoryMobileColorPicker {
  -webkit-appearance: none;
          appearance: none;
  /* hide the default appearance */
  width: 30px;
  /* set the width of the input */
  height: 30px;
  /* set the height of the input */
  border-radius: 50%;
  /* make the input circular */
  border: none;
  /* remove the border */
  outline: none;
  /* remove the default focus outline */
  cursor: pointer;
  /* change the cursor to a pointer on hover */
}

/* set the background color to the selected color */
.StoryMobileColorPicker::-webkit-color-swatch-wrapper {
  padding: 0;
}

.StoryMobileColorPicker::-webkit-color-swatch {
  border-radius: 50%;
}

.StoryMobileColorPicker::-moz-color-swatch {
  border-radius: 50%;
}

/*---------------- Upload Image ----------------*/

.upload-field-customized {
  width: 50px;
  position: relative;
  cursor: pointer;
}

.upload-field-customized input[type="file"] {
  position: absolute;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  left: 0px;
  top: 0px;
  z-index: 10;
}

.story-time-progress {
  position: absolute !important;
  height: 10px;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  z-index: 1;
  background-color: transparent !important;
  top: 0 !important;
}

.added-story {
  border-radius: 10px;
  background-size: cover !important;
  object-fit: fill !important;
}

.added-story-container {
  position: relative;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.progress {
  display: -ms-flexbox;
  height: 10px;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 5px;
}

.add-icon-story {
  background-size: cover !important;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.upload-field-customized span {
  text-align: center;
  width: 100%;
}

.upload-field-customized span img {
  margin-top: 10px;
  opacity: 0.2;
}

.story-gif-align {
  position: relative;
}

.gif-align {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 18px;
}

.bg-img-story {
  width: 100%;
  height: auto;
}

.story-bg-transparent {
  background-color: transparent !important;
  border-radius: 20px !important;
}

.progress-bar {
  border-radius: revert !important;
}

.gif-align {
  position: absolute;
  align-items: center;
  z-index: 1;
}

.StoryAspectRatioAdjustment {
  aspect-ratio: 9/16 !important;
  height: calc(100vh / 1.2) !important;
}

.MinHeightOfImage {
  min-height: calc(100vh / 1.2) !important;
  max-height: calc(100vh / 1.2) !important;
}

.StorylikeAndShare {
  position: absolute;
  bottom: 3%;
  left: 5%;
}

.IconsStory {
  /* transition: all 0.3s ease-in-out; */
  position: absolute;
  bottom: 3%;
  right: 5%;
  display: flex;
  width: calc(100vw / 25);
  height: calc(100vh / 34);
  width: 50%;
  /* overflow: hidden; */
  justify-content: flex-end;
  align-items: centers;
  cursor: pointer;
  grid-gap: 0.2rem;
  gap: 0.2rem;
}

.IconsStory:nth-child(n) {
  flex: 1 1 auto;
  /* transition: 0.3s ease-in-out; */
  width: calc(100% / 6);
  /* transform: translate(0); */
  /* animation: slideEmoji 1.2s ease-in-out; */
}

.IconsStory > img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  z-index: 3;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
}

/* css for adjustmentbox */
.adjustment-container {
  margin-top: 20px;
}

.adjustment-container .adjustment-title {
  cursor: pointer;
}

.adjustment-title {
  position: absolute;
  right: 20px;
  left: 20px;
  height: 35px;
  border-radius: 5px;
  background-color: #f5f3f3;
}
.adjustment-title p {
  position: relative;
  top: 30%;
  left: 10px;
}
.reset{
  position: relative;
  top: 6%;
  left: 71%;
}


.adjustment-title img {
  position: absolute;
  top: 12px;
  right: 10px;
}

.adjustment-container .card {
  margin-top: 55px;
  padding: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 0px #00000040;
  height: 300px; /* Set a fixed height for the scrollable box */
  overflow: auto;
  position: relative;
  top: 50px;
  right: 7px;
  left: 3px;
}

.adjustment-container .card-body {
  display: flex;
  flex-direction: column;
}

.adjustment-container .card-body > div {
  margin-bottom: 10px;
}

.adjustment-container label {
  display: block;
  margin-bottom: 5px;
}

.adjustment-container input[type="range"] {
  width: 85%;
}

/* Slider styles */
.adjustment-container input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  background-color: #4d4d4d;
  outline: none;
  border-radius: 5px;
}

.adjustment-container input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 2px solid #88ee88;
  color: rgb(144, 238, 144);
  border-radius: 50%;
  cursor: pointer;
}

.adjustment-container input[type="range"]::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 2px solid lightgreen;
  color: rgb(144, 238, 144);
  border-radius: 50%;
  cursor: pointer;
}

/* mouse hover on story slider */

/* Add hover effect to the Story_Main_Card_blk */
/* .Story_Main_Card_blk:hover {
  background-color: green;
  border-top: 7px solid rgb(81, 180, 81);
   */
/* Add any other styles you want for the hover effect */
/* } */

/* Add hover effect to the StoryUnRead_Main_Card_blk */
/* .StoryUnRead_Main_Card_blk:hover {
  background-color: green; */
/* Add any other styles you want for the hover effect */
/* border-top: 7px solid rgb(81, 180, 81);
} */

/* Add hover effect to the CreateStory_Main_Card_blk */
/* .CreateStory_Main_Card_blk:hover {
  background-color: green; */
/* Add any other styles you want for the hover effect */
/* border-top: 7px solid rgb(81, 180, 81);
} */

/* --------------------- screen resize media Query ------------------- */

@media only screen and (max-width: 1450px) and (min-width: 400px) {
  .Story_Creation_Preview_section {
    /* background: linear-gradient(199.09deg, #81C14B 0%, #155B46 69.23%, #083737 100%); */
    border-radius: 10px !important;
    align-items: center;
    line-break: auto !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    color: #ffffff;
    overflow: hidden !important;
    /* height: 430px;
    width: 350px; */
  }

  .img-view-container {
    border-radius: 10px !important;
    position: relative !important;
    background-color: #c4c4c4 !important;
    align-content: center !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
    background-size: cover !important;
    /* height: 430px; */
  }

  .img-edit-container {
    border-radius: 5px !important;
    position: relative !important;
    background-color: #c4c4c4 !important;
    justify-content: center;
    align-content: center !important;
    align-items: center;
    overflow: hidden;
    height: auto;
  }
}

@media screen and (max-width: 1000px) and (min-width: 785px) {
  /* .story_sidebar {
    width: 30%;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(129, 193, 75, 0.2);
    position: fixed;
    height: 100%;
    overflow: auto;
  } */

  .story_content {
    /* margin-left: 30%; */
    align-items: center !important;
    justify-content: center !important;
    align-content: center !important;
    height: 100% !important;
    border-radius: 5px;
    -webkit-backdrop-filter: blur(6px) !important;
            backdrop-filter: blur(6px) !important;
  }
}

@media screen and (max-width: 600px) {
  .story_sidebar {
    /* width: 100%; */
    height: auto;
    float: left;
    position: relative;
  }

  .story_content {
    width: 100% !important;
    height: 400px;
    align-items: center !important;
    justify-content: center !important;
    align-content: center !important;
    height: auto !important;
    border-radius: 5px;
    margin-left: 0;
  }

  .Story_Creation_Preview_section {
    height: auto;
    overflow: hidden !important;
    width: 300px;
  }

  .img-view-container {
    height: auto;
    width: 300px;
  }

  .img-edit-container {
    height: auto;
    width: 300px;
    overflow: hidden;
  }

  .MinHeightOfImage {
    /* min-height: calc(100vh /1.1) !important; */
    max-height: calc(100vh / 1.1) !important;
  }

  .StoryAspectRatioAdjustment {
    aspect-ratio: 9/16 !important;
    height: calc(100vh / 1.1) !important;
  }

  .SwitchOnMobileView {
    position: absolute;
    top: calc(100vh / 13);
    right: calc(100vw / 11);
    z-index: 4;
  }
}

@media (min-width: 1400px) {
  .imageMaxWidth {
    max-width: 34vw !important;
  }
}

@media (min-width: 1700px) {
  .imageMaxWidth {
    max-width: 28vw !important;
  }
}

.Mx_h_100 {
  max-height: 100%;
}

.font500 {
  font-weight: 500 !important;
}

.font400 {
  font-weight: 400 !important;
}

.FullStoryPriview {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
}

.FullStoryPriview:nth-child(1) {
  width: calc(100vw / 4);
}

.FullStoryPriview:nth-child(2) {
  width: calc(100vw / 3);
}

/* report Button  */

.StoryThreeDotButtonMenu {
  position: absolute;
  will-change: transform;
  top: -34px !important;
  /* left: -59px !important; */
  -webkit-transform: translate3d(-128px, 68px, 10px) !important;
          transform: translate3d(-128px, 68px, 10px) !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px !important;
}

.storyEditBtnDesignViewPage {
  padding: 0.5rem 0.7rem !important;
  border-radius: 50%;
  background-color: white !important;
  /* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); */
  color: #4d4d4d;
}

.storyEditBtnDesignViewPage:hover {
  background-color: #81c14b !important;
  color: #fff !important;
}

.storyEditBtnDesignViewPageactive {
  padding: 0.5rem 0.7rem !important;
  border-radius: 50%;
  /* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); */
  background-color: #81c14b !important;
  color: #fff !important;
}

/* slick slider modify  */

.StoryBtnSlickSlider > .slick-prev {
  left: -100px !important;
}

.StoryBtnSlickSlider > .slick-next {
  right: -100px !important;
}

.zIndex2 {
  z-index: 2 !important;
}

/* 

/* Story Privew box New Design  */

.StoryPriviewbox {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  /* height: 100vh; */
}

.us-none {
  -webkit-user-select: none;
          user-select: none;
}

.StoryPreviewContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.StoryPreviewBox {
  background-repeat: no-repeat !important;
  aspect-ratio: 9 / 16;
  /* width: calc(100vw / 4); */
  border-radius: 10px;
  /* background-color: #04AA6D; */
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
  /* height: calc(100vh / 1.2); */
}

.StoryPreviewBox > img {
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}

.StoryImageUploadPreview {
  /* background-repeat: no-repeat !important; */
  aspect-ratio: 9 / 16;
  /* width: calc(100vw / 4); */
  border-radius: 10px;
  /* background-color: #04AA6D; */
  /* height: 100%; */
  /* background-image: url("https://wallpapershome.com/images/pages/ico_h/24116.jpg"); */
  /* background-size: cover !important; */
  /* background-position: center !important; */
  /* height: calc(100vh / 1.2); */
}

.StoryImageEditContainer {
  height: 80vh;
  object-fit: contain;
}

.StoryImageUploadPreview {
  z-index: 1;
  border: 1px solid white;
}

.ImageBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  /* adjust the color of the backdrop as desired */
  z-index: 1;
  /* set the z-index to -1 to place the backdrop behind the image and overlay */
}

.Frame {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid white;
  aspect-ratio: 9/16;
  /* height: 100%; */
  width: 100%;
  object-fit: contain;
  z-index: 1;
  /* box-shadow: 0px 0px 3000px rgba(0, 0, 0, 0.75); */
}

/* Mobile View Story Creationn  */

.StoryCreationCrossBtn {
  position: absolute;
  /* left: calc(100% / 20); */
  left: 0;
  top: calc(100% / 26);
}

/* .FilterAndTextBtn {
  position: absolute;
  right: 8%;
  top: calc(100% / 25);
} */

.StoryMobileBottomOptions {
  position: absolute;
  bottom: calc(100% / 26);
  left: 0;
}

.FiltersMobileView {
  position: absolute;
  bottom: calc(100% / 8);
  left: 0;
  display: flex;
  align-items: center;
  overflow-y: scroll;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.StoryMobiletextFieldUpperFilter {
  position: absolute;
  bottom: calc(100% / 4);
  left: 0;
  display: flex;
  align-items: center;
  overflow-y: scroll;
  transition: 0.3s ease-in-out;
  width: 100%;
}

.FiltersMobileView::-webkit-scrollbar {
  display: none;
}

.FiltersMobileView > img {
  width: calc(100% / 10);
  aspect-ratio: 4 / 4;
}

.MobileStoryView {
  display: none;
  /* background-size: contain !important; */
  background-repeat: no-repeat !important;
  background-position: center !important;
}

@media (max-width: 567px) {
  .FiltersMobileView > img {
    width: calc(100% / 5);
    aspect-ratio: 4 / 4;
  }
}

@media (max-width: 1024px) {
  .DeskTopViewStoryCreation {
    display: none;
  }

  .MobileStoryView {
    display: flex;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
}

/* story view Modal  */

.StoryViewHoverColor:hover {
  background-color: #e6f3db;
  border-radius: 5px;
}

.StoryAspectRatioFix {
  aspect-ratio: 9/16 !important;
  max-height: calc(100vh / 1.2) !important;
}

.StoryImagePreviewImage {
  aspect-ratio: 9/16 !important;
  height: calc(100vh / 1.7);
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.img-preview {
  overflow: hidden;
}

/* new story view modal  */

.storyViewPageMainContainer {
  display: flex;
}

.StorySidePannel {
  width: calc(100vw / 3);
}

.StoryViewPannel {
  width: calc(100vw / 2);
}

.StorySidebarCircleIcon {
  padding: 0.5rem 0.7rem;
  border-radius: 50%;
  border: 1px solid #4d4d4d;
  background-color: white;
}

.StoryProgressbar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

.StoryProgressbar > div {
  width: calc(100% / 5.1);
}

.StoryMainDesign {
  width: calc(100% / 2);
}

.mainStoryImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.StoryTextContainerAdjustment {
  /* position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%); */
  -webkit-transform: translateY(-70%);
          transform: translateY(-70%);
}

:root {
  --storyGradient: linear-gradient(
    to top,
    rgba(129, 193, 75, 0.5),
    rgba(129, 193, 75, 0.5)
  );
  --updateStoryGradient: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
}

.StoryMainSliderComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .ptofileImage{
  box-shadow: 0px 4px 4px 0px #00000040;

} */

.CreateStory_Main_Card_blk {
  border-radius: 9px;
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  min-width: 50%;
  height: 185px;
  object-fit: cover;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.Story_Main_Card_blk {
  border-radius: 9px;
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  height: 185px;
  object-fit: cover;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.unRead_story_section {
  border: 2px solid rgba(129, 193, 75, 0.4);
  border-radius: 5px;
}

.StoryUnRead_Main_Card_blk {
  border: 2px solid #81c14b;
  border-radius: 9px;
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  height: 185px;
  object-fit: cover;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.Create_Story {
  position: absolute;
  top: 33%;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.Live_pills {
  padding: 3px 8px;
  position: absolute;
  top: 10px;
  left: 8px;
  background: #fd1b1b;
  border-radius: 5px;
  color: #e6f4f3;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
}

.Live_Right_blk {
  position: absolute;
  color: #e6f4f3;
  font-weight: 500;
  font-size: 10px;
  top: 12px;
  right: 8px;
}

.story-buttomcontent {
  position: absolute;
  bottom: 8px;
  left: 8px;
  text-align: start;
}

.story-buttomcontent h3 {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
}

.story-buttomcontent h4 {
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  margin-top: 5px;
}

.story_buttom_rght_blk {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.story_TopLeft_blk {
  position: absolute;
  width: 100%;
  top: 0px;
}

.story_Progress_Bar {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px 5px 0px 0px;
  height: 4px;
}

.progress-bar {
  /* background: #81c14b; */
  background-color: #106931;
}

.Add_Story_text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  /* margin-top: 8px; */
  padding-top: 10px;
}

.StoryViewImageSlider {
  width: 100%;
  min-height: 80vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}

/* 
.StroyImagePrivewSlider {
  aspect-ratio: 9/16;
  min-height: calc(100vh / 1.2);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;

  background-size: contain;
  background-color: #4d4d4d;
  background-position: center;

} */

.StroyImagePrivewSlider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  background-size: contain;
  background-color: #4d4d4d;
  background-position: center;
}

@media (max-width: 767px) {
  .StroyImagePrivewSlider {
    aspect-ratio: 9/16 !important;
    min-height: auto;
    min-width: calc(100vw * (9 / 16));
    max-height: calc(100vh * (16 / 9));
    /* min-height: calc(100vw * 9/16); */
  }

  .StoryViewImageSlider {
    width: 100%;
    min-height: 100vh;
    /* max-height: 100vh; */
    /* background-color: red; */
  }
}

@media (max-width: 520px) {
  .StroyImagePrivewSlider {
    aspect-ratio: 9/16 !important;
    min-height: auto;
    min-width: calc(100vw * (14 / 16));
    max-height: calc(100vh * (16 / 9));
    /* min-height: calc(100vw * 9/16); */
  }

  .StoryViewImageSlider {
    width: 100%;
    min-height: 100vh;
    /* max-height: 100vh; */
    /* background-color: red; */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .StroyImagePrivewSlider {
    aspect-ratio: 9 /16 !important;
    min-width: 100%;
  }
}

@media (min-width: 1025px) {
  .StroyImagePrivewSlider {
    aspect-ratio: 9 /16 !important;
    min-height: calc(100vh / 1.2);
  }
}

/* When screen size descrease, when it reached 1024px after that header will not visible */

.StoryPreviewImageSrc {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
}

/* .reply-form {
 position: absolute;
 bottom: 0;
 left: 0;
 margin-bottom: -80px;
} */

.storyReactionBtn {
  width: 2rem;
}

@media (max-width: 767px) {
  .RemoveInMobileStory {
    display: none;
  }

  /* 
  .SwitchOnMobileView {
    display: none;
  } */

  .storyReactionBtn {
    width: 1.5rem !important;
  }

  .StoryViewImageSlider {
    align-items: flex-start;
    padding-top: 15px;
  }
}

.ReplyInputbox {
  width: 100%;
  z-index: 9999;
}

/* reply box media query  */

/* New story Veiw design */

.NewSoryViewDesign {
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.StoryWholeContainer_ {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-color: transparent;
  display: flex;
  flex-wrap: nowrap;
  
  align-items: center;
  justify-content: center;
  /* display: grid; */
  /* grid-template-areas: "sidebar sidebar main main main main main main main main"; */
}

.StorySideBar_ {
  /* width: clamp(450px, 24vw, 26vw); */
  overflow-y: auto;
  height: 100vh;
  background-color: #ffffff;
  /* grid-area: sidebar; */
}

.StoryUserDesign_ {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.StoryViewArea_ {
  /* width: 100%; */
  background-color: transparent;
  height: 100vh;
  /* max-width: calc(100vw - clamp(450px, 24vw, 26vw)); */
  /* grid-area: main; */
}

.StorySideBarHead_ {
  /* width: 100%; */
  height: clamp(40px, 50px, 60px);
  border-bottom: 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.StoryDesignUserSelfData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
}

.StoryUserData_ {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 10px 0px;
}

.StoryUsetTextData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 0.6rem;
  gap: 0.6rem;
}

.StoryDataUserImage {
  width: clamp(30px, 10vw, 45px);
  height: clamp(30px, 10vw, 45px);
  border-radius: 5px;
  padding: 4px;
  color: #ccc;
  box-sizing: content-box;
}

.StoryImageEditbuttons_ {
  padding: 6px 8px;
  border-radius: 50%;
  
}

.Story_ActiveEdit_Btn {
  padding: 6px 8px;
  border-radius: 50%;
}

.StoryImageEditbuttons_AddText {
  padding: 8px 8px;
  border-radius: 50%;
  border: 1px solid #4d4d4d;
  
}

.EditStoryBtnImage {
  width: 16px;
  height: 16px;
}

.EditStoryBtnImage:hover {
  fill: white !important;
}

.StoryProgressbar {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  height: 80px;
  align-items: center;
}

.story_Progress_Bar_main {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 5px 5px 5px 5px;
  height: 7px;
}

.story_Progress_Bar_main > .progress-bar {
  background: white !important;
}

/* .Story_Preview_ {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
} */

.previewedImage_,
.ImagePreviewStory_ {
  border-radius: 10px;
}

.ImagePreviewStory_ .previewedImage_ {
  min-width: 100%;
  object-fit: contain;
  object-position: center;
}

.ImagePreviewContainer {
  min-width: 100%;
  overflow: hidden;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

/* .ImagePreviewContainer>div {
  width: 100%;
  object-fit: contain;

} */

.ImagePreviewContianerSub {
  max-width: 100%;
  position: relative;
}

.ImagePreviewStory_ {
  max-width: 100%;
  max-height: 83vh !important;
  object-fit: cover !important;
}

.OnStoryUserImg_ {
  width: clamp(40px, 40px, 65px);
  height: clamp(40px, 40px, 65px);
  border-radius: 100%;
  border: 2px solid white;
}

.StoryUserInfo_ {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), transparent);
  border-radius: 10px 10px 0px 0px;
}

.OnStoryUserInfo {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}

.OnStoryUserInfo > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.OnStorySettings {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.OnStorySettings_ {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.StoryReplybox_ {
  height: 2rem;
}

.StoryReactionbtn {
  height: 2rem;
  width: 2rem;
}

.StoryReplyEmojiIcons {
  width: 1.5vw;
}

.StoryReplySendBtn {
  width: 2rem;
  height: 1.9rem;
  position: relative;
  background: #4d4d4d;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .StorySliderCustomize>.slick-prev:before,
.StorySliderCustomize>.slick-next:before {
  font-family: "Font Awesome 5 Free";
  font-size: 30px;
  line-height: 1;
  opacity: .75;
  color: #fff;
} */

.StorySliderCustomize > .slick-prev:before {
  content: "\2039" !important;
  color: white !important;
  /* chevron left icon */
}

.StorySliderCustomize > .slick-next:before {
  content: "\203A" !important;
  color: white !important;

  /* chevron right icon */
}

.StorySliderCustomize > .slick-prev {
  left: -50px !important;
  -webkit-transform: scale(2.2);
          transform: scale(2.2);
}

.StorySliderCustomize > .slick-next {
  right: -50px !important;
  -webkit-transform: scale(2.2);
          transform: scale(2.2);
}

.green-button {
  /* background-color: #81c14b; */
}
.StoryBottonLikeViewAdjust_ {
  /* position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%); */
  -webkit-transform: translateY(-60%);
          transform: translateY(-60%);
}

.StoryCrossButton_ {
  position: absolute;
  right: 0;
  z-index: 2;
}

.StoryCrossButton_Mobile {
  display: none;
}

@media (min-width: 280px) and (max-width: 800px){
  .StoryReplyEmojiIcons {
    width: 3.5vw;
  }
  
} 

@media (max-width: 960px) {
  .StoryCrossButton_Mobile {
    display: block;
    position: absolute;
    right: 0;
    top: 2px;
    z-index: 2;
  }

  .StoryCrossButton_ {
    display: none;
  }
}

/* unversal  */
.storyBoderColor {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.storyBoderColor4D {
  border-color: #4d4d4d !important;
}

.Story4D_600 {
  color: #4d4d4d !important;
  font-weight: 500 !important;
}

.Story4D_500 {
  font-weight: 400 !important;
  color: #4d4d4d !important;
}

.Story81_600 {
  font-weight: 600 !important;
  color: #81c14b;
}

.Fw_600 {
  font-weight: 600 !important;
}

.Fw_500 {
  font-weight: 400 !important;
}

.Fw_400 {
  font-weight: 400 !important;
}

.Hover81:hover {
  background-color: #81c14b !important;
  color: white;
}

.HoverE6:hover {
  background-color: #e6f3db !important;
}

.BorderRadius5px {
  border-radius: 5px;
}
/* profile image on story page */
.profileImage {
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  height: 60px;
  width: 60px;
 

}

.StatusBorderDashedProfile {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%2381C14BFF' stroke-width='5' stroke-dasharray='10%25%2c15%25' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  border-radius: 5px;
}

.StatusBorderDashed {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%2381C14BFF' stroke-width='5' stroke-dasharray='50%25%2c15%25' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  border-radius: 5px;
}

.StatusBorderDashedGray {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23C4C4C4FF' stroke-width='5' stroke-dasharray='30%25%2c15%25' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  border-radius: 5px;
}

.UserActiveStory {
  background-color: #e6f3db !important;
}

.StorySliderCustomizes > .slick-prev:before {
  content: "\2039" !important;
  color: white !important;
  background: rgba(85, 85, 85, 0.5);
  padding: 5px 2px;
  border-radius: 0px 5px 5px 0px;
  padding-bottom: 16px !important;

  /* chevron left icon */
}

.StorySliderCustomizes > .slick-next:before {
  content: "\203A" !important;
  color: white !important;
  background: rgba(85, 85, 85, 0.5);
  padding: 5px 2px;
  border-radius: 5px 0px 0px 5px;
  padding-bottom: 16px !important;
}

.StorySliderCustomizes > .slick-prev {
  left: -4px !important;
}

.StorySliderCustomizes > .slick-next {
  right: -4px !important;
}

.GroupSSliderCustomizes > .slick-prev:before {
  content: "\2039" !important;
  color: white !important;
  background: rgba(85, 85, 85, 0.5);
  border-radius: 0px 10px 10px 0px;
  padding: 5px 2px;
  padding-bottom: 16px !important;

  /* chevron left icon */
}

.GroupSSliderCustomizes > .slick-next:before {
  content: "\203A" !important;
  color: white !important;
  background: rgba(126, 178, 98, 0.8);
  padding: 5px 2px;
  padding-bottom: 16px !important;
  background: rgba(85, 85, 85, 0.5);
  border-radius: 10px 0px 0px 10px;
}

.GroupSSliderCustomizes > .slick-prev {
  left: -10px !important;
}

.GroupSSliderCustomizes > .slick-next {
  right: -10px !important;
}

.BirthdaySliderCustomizes > .slick-prev:before {
  content: "\2039" !important;
  color: white !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0px 10px 10px 0px;
  padding: 5px 2px;
  padding-bottom: 16px !important;

  /* chevron left icon */
}

.BirthdaySliderCustomizes > .slick-next:before {
  content: "\203A" !important;
  color: white !important;
  background: rgba(126, 178, 98, 0.8);
  padding: 5px 2px;
  padding-bottom: 16px !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px 0px 0px 10px;
}

.BirthdaySliderCustomizes > .slick-prev {
  left: -4px !important;
}

.BirthdaySliderCustomizes > .slick-next {
  right: -4px !important;
}

.StoryCreationTextAreaMain {
  max-height: 10rem;
  outline-color: #81c14b;
  color: #4d4d4d;
  font-weight: 400;
  border-color: #c4c4c4;
  border-width: 0.5px;
}

.StoryCreationTextAreaMain::-webkit-input-placeholder {
  color: #c4c4c4;
  font-weight: 400;
}

.StoryCreationTextAreaMain::placeholder {
  color: #c4c4c4;
  font-weight: 400;
}

/* image edit container flow   */

/* image select and crop section  */

.ImageEditCropSectionHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.CropTheStoryImage_Text {
  justify-self: center;
}

.StoryImageEditContainer_ {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.StoryImageEditContainer_Sub {
  height: 85vh;
  width: 90%;
  background-color: #c4c4c4;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ImageEditContainer_main {
  max-width: 100%;
  /* width: 100%; */
  max-height: 100%;
  min-height: 100%;
  display: grid;
  place-items: center;
  border-radius: 10px;
  position: absolute;
  top: 3px;
  bottom: 30px;
}



.StoryImageUploadIconContainer {
  max-width: 100%;
  /* width: 100%; */
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ImageEditContainer_mainAfterCrop {
  max-width: 100%;
  /* width: 100%; */
  max-height: 80%;
  min-height: 80%;
  display: grid;
  object-fit: cover;
  place-items: center;
  border-radius: 10px;
}

.OnEditImagePrivew_AfterCrop {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  min-height: 100%;
  height: auto;
  object-fit: cover;
  /* border-radius: 10px; */
  overflow: hidden;
  /* position: absolute;
  top:10px;
  bottom:10px; */
}

.OnEditImagePrivew_ {
  max-height: 100%;
  max-width: 90%;
  width: auto;
  min-height: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  overflow: hidden;
}

.DropDownLanguageBox {
  position: absolute;
  top: 2.4rem;
  left: 0.2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 20rem;
  padding: 6px;
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 10;
  align-items: flex-start;
}

.DropDownLanguageBox > div {
  padding: 5px 10px;
}

button.StoryFontDropDownToggle.btn.btn-secondary {
  border: none !important;
  background: white !important;
  padding: 0 !important;
}

.StoryFontDropDownMenu {
  width: 16rem !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
}

.AddTextWindowStoryEdit {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.InputTextStory_ {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 22px;
  resize: none;
  overflow: hidden;
  min-height: 40px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-width: 92%;
  outline: none;
  
}

.InputTextStory_::-webkit-input-placeholder {
  color: white;
  font-size: 22px;
}

.InputTextStory_::placeholder {
  color: white;
  font-size: 22px;
}

.CroppedImageGradientStory::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
}

/* text customizer styline  */

.TheGeneratedtext {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px;
  position: relative;
  border: 1px solid transparent;
}

.TheGeneratedtext:hover {
  border: 1px dashed gray;
}

.TextCrossBtn_story {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.cursor-p {
  cursor: pointer;
}

.TextRotationBtn_story {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translate(50%, 50%);
          transform: translate(50%, 50%);
}

.TextResizableBtn_Story {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translate(50%, -50%) rotate(-30deg);
          transform: translate(50%, -50%) rotate(-30deg);
}

.TextRotationBoxSide {
  position: absolute;
  padding: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.StoryFontDropDownMenu.dropdown-menu.show {
  z-index: 3 !important;
}

.StoryImageAfterEdit {
  width: auto;
  max-height: 100%;
  height: 85vh;
}

/* .addedtext{
  font-size: 30px;
} */

.selectedTextBorder {
  border: 1px solid white;
}

.StoryCrossBtn {
  position: absolute;
  left: 0;
  top: 0;
 
 
 
}

.StoryTextCustomize_ {
  position: absolute;
  left: 0;
  right: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 4px;
}

/* when screen size descrease below the 1025px then header will bw disappear */
/* @media screen and (max-width: 1025px){
  header{
    visibility: hidden;
  }
} */

/* mobile screen story module */

 /* .StoryCreationCrossBtn {
  position: absolute;
  top: 60px;
 
}

.StoryMobileBottomOptions {
  position: absolute;
  bottom: 65px;
  
}  */

@media (max-width: 1025px) {
  .header{
    display: none;
  }
}

.addrealtion{
  color: #81C14B;
  color: var(--button-color, #81C14B);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 28.5px */
cursor: pointer;
}

.shotz-top-heading {
  /* font-family: 'Montserrat', sans-serif !important; */
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 1.2rem !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

.modal-container-for-shortz {
  background-color: rgba(255, 0, 0, 0.859);
  display: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.shotz-search-style {
  padding: 8px 10px 8px 15px !important;
  font-size: 15px;
  color: #666666;
  /* font-family: 'Montserrat', sans-serif !important; */
  font-style: normal !important;
  font-weight: 500 !important;
  width: 350px;
  background: #eeeeee;
  border-radius: 5px;
  border: "none";
  display: flex !important;
  align-items: center !important;
  outline: none;
}

.shotz-text {
  /* font-family: 'Montserrat', sans-serif !important; */
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1rem !important;
  color: #ffffff !important;
}

.close-icon {
  position: absolute;
  right: -75px;
  color: #ffffff !important;
  cursor: pointer;
}

.shotz-menu-hover-style:hover {
  background: #e5f0e0 !important;
  color: #4d4d4d !important;
  /* font-family: 'Montserrat', sans-serif !important; */
  font-style: normal !important;
  cursor: pointer !important;
  font-weight: 500 !important;
}

.selected-shotz-style {
  border: 2px solid #81c14b;
  border-radius: 5px !important;
}

.videoWrapper {
  width: 100% !important;
  max-height: 100vh !important;
  min-height: 100vh !important;
  object-fit: cover !important;
  background-size: cover !important;
}

.Media_header {
  background: rgba(246, 237, 237, 0.5);
}

.light-green-button {
  background: #ffe5e5;
  border-radius: 6px;
  color: #caffcecc;
  border: none !important;
  outline: none !important;
}

.user-media .user-img img {
  width: 55px;
  height: 55px;
  border-radius: 10px;
  object-fit: cover;
}

.shotzs-img {
  background-size: cover !important;
  border: none !important;
  outline: none !important;
  height: 100% !important;
}

.shotz-box-selected {
  background: #ffffff;
  border: 3px solid #81c14b !important;
  padding: 2px !important;
  border-radius: 5px;
}

.shotz-box {
  border: 3px solid #ffffff !important;
  padding: 2px !important;
  border-radius: 5px;
}

.img-gradient {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.54) 0%,
    rgba(0, 0, 0, 0) 35.42%,
    rgba(0, 0, 0, 0) 61.98%,
    rgba(0, 0, 0, 0.72) 100%,
    rgba(0, 0, 0, 0.72) 100%
  ) !important;
}

.comment-bttn-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  margin-bottom: 30px !;
  background-color: red;
  color: white;
  text-align: center;
}

.grid-toggle-customize-css {
  background-color: #dfe3dd !important;
  color: #4d4d4d !important;
}

.img-container {
  position: relative !important;
}

.shotz_center {
  top: 30%;
  /* width: 100%; */
  height: 30px !important;
  width: 30px !important;
  position: absolute !important;
  display: flex;
  right: 30%;
  justify-content: center;
  align-items: center;
}

.archive-btn {
  stroke: #ffffff !important;
  fill: #ffffff !important;
  color: #ffffff !important;
}

.lable_style {
  font-size: 13px !important;
}

.img-shotz-container {
  position: relative;
  text-align: center;
  color: white;
}

.img-text-centered {
  position: absolute;
  text-align: center;
  left: 100px;
  top: 75px;
}

.align-right-ab {
  position: absolute !important;
  right: 8px !important;
  top: 15px;
  color: #4d4d4d;
}

.center-shotz-cont {
  position: absolute;
  left: 50%;
  top: 30%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.center-shotz-cont img {
  width: 70px;
}

.shotz-border {
  border-bottom: 30px solid rgba(35, 24, 24, 0.822);
}

.shotz-conatiner-vertical-Scroll {
  scroll-behavior: smooth !important;
  height: 100vh !important;
  overflow-y: scroll !important;
  scroll-snap-type: y mandatory !important;
}

.explore-btn:hover svg {
  stroke: #ffffff !important;
  fill: #ffffff !important;
  color: #ffffff !important;
}

.scroll-share-com {
  overflow: scroll;
  scroll-behavior: smooth;
  height: 350px;
}

/* shotz video css */
.stotz-video-style {
  height: 920px !important;
  object-fit: fill !important;
}

.carousel-container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  scroll-behavior: smooth;
  object-fit: cover !important;
}

/* shotz card hover css */

.shotz-card-hover {
  display: grid;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
}

.shotz-card-hover > * {
  grid-area: 1/1;
  border-radius: 5px;
  transition: 0.4s;
}

.shotz-card-hover figcaption {
  display: grid;
  align-items: end;
  /* font-family: sans-serif; */
  font-size: 2.3rem;
  font-weight: bold;
  color: #0000;
  border-radius: 5px;
  background: #0009;
  background: var(--c, #0009);
  -webkit-clip-path: inset(0 100% 0 0);
          clip-path: inset(0 100% 0 0);
  -webkit-clip-path: inset(0 var(--_i, 100%) 0 0);
          clip-path: inset(0 var(--_i, 100%) 0 0);
  --webkit-mask: linear-gradient(#000 0 0), linear-gradient(#000 0 0);
  --webkit-mask-composite: xor;
  --webkit-mask-clip: text, padding-box;
}

video::-webkit-media-controls-start-playback-button {
  display: none;
}

.shotz-card-hover:hover figcaption {
  --_i: 0%;
}

.shotz-card-hover:hover img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.bottom_alignMent-Footer {
  position: absolute;
  bottom: 10px;
  /* width: 370px; */
}

.scrollSuggestion {
  height: 300px;
  scroll-behavior: smooth;
  overflow-y: auto;
}

/* nav css */

.nav-flex-container {
  display: flex !important;
  align-items: center;
  justify-content: space-between !important;
  flex-wrap: wrap;
}

.sub-nav-flex {
  display: flex !important;
  align-items: center;
  flex-wrap: wrap;
}

/* shotz share modal css */

.shotz-share-modal {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 5px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.shotz-share-modal img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border: 2px solid #ffffff;
  border-radius: 50%;
  margin-right: 10px;
}

.shotz-share-modal {
  display: block;
}

.top-shotz-cont {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  left: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}

/* video css */

@supports not (-webkit-mask-clip: text) {
  .shotz-card-hover figcaption {
    --webkit-mask: none;
    color: #fff;
  }
}

.PositionThiscenter {
  position: absolute;
  top: 18px;
  left: 26px;
  font-size: 18px;
}

.PositionThistopright {
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 18px;
}

.VideoFitforcover {
  height: calc(100vh - 56px);
  object-fit: cover;
}

.ghcvewcwevc {
  -webkit-transform: translate(1px, 20px) !important;
          transform: translate(1px, 20px) !important;
}

.VideoFitzfocus.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #ffffff;
  background-color: #e74e2500 !important;
  border-color: #565e64 !important;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.VideoFitzfocus.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #ffffff;
  background-color: #e74e2500 !important;
  border-color: #565e64 !important;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.obj-container.dropdown-menu.show {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.myModalTransparent .modal-content {
  background: transparent;
  border: none;
}

.comment-col {
  transition: all 0.3s;
  transition-timing-function: ease-out;
}

.comment-col-close {
  transition: all 0.3s;
  transition-timing-function: ease-in;
}

.simple-linear.open {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), transparent);
  z-index: 2;
  border-radius: 10px;
  transition: all 0.5s;
  transition-timing-function: ease-out;
}

.simple-linear.close {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), transparent);
  z-index: 2;
  border-radius: 1rem 1rem 0 0;
  transition: all 0.5s;
  transition-timing-function: ease-out;
}

.bottom-linear.open {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);
  z-index: 2;
  border-radius: 0 0 0 1rem;
  transition: all 0.5s;
  transition-timing-function: ease-out;
}

.bottom-linear.close {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);
  z-index: 2;
  border-radius: 0 0 1rem 1rem;
  transition: all 0.5s;
  transition-timing-function: ease-out;
}

.gradient-overlay {
  position: relative;
  display: inline-block;
}

.gradient-overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 19.79%,
      rgba(0, 0, 0, 0) 85.42%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    linear-gradient(360deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 49.78%);
}

.scrollForShareShotz {
  overflow-y: scroll;
  max-height: calc(100vh - 300px);
}

.ShotzRightSlider {
  vertical-align: bottom;
  position: absolute;
  bottom: 0px;
  right: -80px;
  z-index: 100;
}

.hide-on-web {
  display: none;
}

.modal.fade.show {
  -webkit-backdrop-filter: brightness(1.1);
          backdrop-filter: brightness(1.1); /* Adjust the brightness value as needed */
}

.bottom-gallery-cont.padEdit {
  padding: 100px 20px 20px 20px;
  margin-bottom: 6px;
}

.my-card {
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.my-card.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.my-card.closed {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.video-container {
  /* height: 400px;
  overflow-y: scroll;
  scroll-snap-type: y mandatory; */
  scroll-snap-type: y mandatory;
  flex-basis: 50%;
  scroll-snap-stop: always;
  overflow-y: scroll;
}

.video-item {
  /* scroll-snap-align: start;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; */

  scroll-snap-align: start;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.comment-closer {
  cursor: pointer;
  color: #fff !important;
  padding: 30px 3px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px 0 0 10px;
  position: absolute;
  right: 12px;
  top: 45%;
}

.cmnt-close-btn {
  position: relative;
}

.cmnt-close-btn ::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

button.padding-for-shotz.btn.btn-primary {
  padding: 7px 14px !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.scroll-container {
  overflow-y: scroll; /* Enable vertical scrolling */
  overflow-x: hidden;
  white-space: nowrap;
  scroll-snap-type: y mandatory; /* Enable snapping to each child element */
  scroll-behavior: smooth; /* Smooth scrolling behavior */
}
.scroll-container::-webkit-scrollbar-track {
  background-color: #ffff0000;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #ffff0000;
}
.scroll-container .modal-fade {
}
.scroll-item {
  scroll-snap-align: start; /* Snap each item to the top */
}
.vcontainer {
  overflow: auto;
  padding-right: 100px;
  padding-left: 15px;
}

@media (max-width: 992px) {
  .hide-on-web {
    display: block;
  }
  .close-icons {
    position: absolute !important;
    right: 4px !important;
    z-index: 5 !important;
    top: 6px !important;
  }
}

@media (max-width: 768px) {
  .ShotzRightSlider {
    vertical-align: bottom;
    position: absolute;
    bottom: 0px;
    right: 1rem;
  }
  .vcontainer {
    padding-right: 12px;
    padding-left: 12px;
  }
  .close-icons {
    position: absolute !important;
    right: 4px !important;
    z-index: 5 !important;
    top: 6px !important;
  }
}

.SortsUpDownButton {
  position: fixed;
  right: -75px;
  z-index: 100;
  height: 93vh;
  top: 0;
}

/* .SortsUpDownButton {
  position: fixed;
  right: -75px;
  z-index: 100;
  height: 94vh;
  top: 0;
} */

.animateClose {
  -webkit-animation: closeComment 0.3s ease !important;
          animation: closeComment 0.3s ease !important;
  width: 0px !important;
}

@-webkit-keyframes closeComment {
  from {
    width: 100%;
  }

  to {
    width: 0px;
  }
}

@keyframes closeComment {
  from {
    width: 100%;
  }

  to {
    width: 0px;
  }
}
.shutter-container {
  overflow: hidden;
  height: auto;
  max-height: 0;
  transition: max-height 0.3s ease-in-out; /* Initial duration */
}

.shutter-container.slow {
  transition-duration: 2.5s; /* Slower duration when 'slow' class is added */
}

.shutter-content {
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}
.comment-closer {
  display: block;
  opacity: 1;
  transition: opacity 0.5s ease-in-out, -webkit-transform 0s ease-in-out;
  transition: opacity 0.5s ease-in-out, transform 0s ease-in-out;
  transition: opacity 0.5s ease-in-out, transform 0s ease-in-out, -webkit-transform 0s ease-in-out; /* 0s transition for transform */
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.comment-closer.close {
  display: block;
  opacity: 1;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out; /* Apply the transition only for close */
}


/* For mobile devices (e.g., screens with a maximum width of 767px) */
@media (max-width: 767px) {
  .manage-text {
    /* height: 30px; */
    width: 140px;
  }
  .manage-texts {
    /* height: 30px; */
    width: 137px;
  }
  /* margin-left: -7px; */
}

.Archive-text-btn{
   margin-left: -5px;
}

@media (min-width: 1024px)  {
  .close-icons {
    position: absolute !important;
    right: -14rem !important;
    z-index: 5 !important;
    top: 6px !important;
  }
 
}
/* Define the custom margin-top for screens with a resolution of 1024x1366 */
@media screen and (max-width: 1200px) {
  .select-text-buttons {
    margin-top: 12px;
  }
}


.styleForTextFooter {
    color: #A6A6A6;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background: #F6F6F6;
    border-radius: 15px;
}

.styleForTextFooter h5 {
    color: #A6A6A6;
    font-weight: 500;
}

.styleForTabDesigne {
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    padding: 10px;
    border-radius: 0px 4px 0px 0px;
}

.styleForTabDesigneMob {
    border-right: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    padding: 12px 4px;
    border-radius: 0px 4px 0px 0px;
}

.userSelectiontab:hover {
    background: #F6F6F6;
    border-radius: 5px;
}


.matual-emtion-blk {
    display: grid;
    grid-template-columns: auto auto auto 25px;
    grid-gap: 0px;

}

.matual-emtion-blk li {
    margin-left: -10px;

}

.BookmarkForPosition {
    position: absolute;
    top: -20px;
    right: -5px;
    z-index: 2;
}

.opacityForactiveScreen {
    position: relative;
    box-shadow: 10px 10px 200px 5000px rgba(0, 0, 0, 0.61);
    border-radius: 7px;
    z-index: 3;
    transition: 0.4s ease-in-out;
    height: auto;
}

.postCardForopacity {
    background: transparent;
    border-radius: 5px;
}

.scrollForHastag {
    overflow: scroll;
    overflow-x: hidden;
    height: 150px;
}


.positionforAddonText {
    color: #808080;
    background: whitesmoke;
    border-radius: 5px;
    padding: 0.25rem;
    position: absolute;
    left: 10px;
    top: 10px;
}

.scrollForMedia {
    overflow: scroll;
    overflow-x: hidden;
    height: 500px;
    padding: 0.5rem;
}

.paddingForPostdiscardcrossdisaapered {
    padding-top: 0.4rem;
    padding-bottom: 0.8rem;
    position: absolute;
    top: -40px;
}

.paddingForPostdiscardAppered {
    padding-top: 1.5rem;
    padding-bottom: 1.0rem;
}

.fixedforModal {
    height: 100vh;
    overflow: hidden;
}

.pointerForfreez {
    cursor: none;
    pointer-events: none;
}

.pointerForworked {
    cursor: auto !important;
    pointer-events: all !important;
}

.PostiotionForCrossIcon {
    position: absolute;
    right: 10px;
    top: 10px;
}

.GridViewForAllImage {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.imgResposiveForUpload img {
    object-fit: fill;
    width: 100%;
    height: 250px;
    border-radius: 5px 5px 0 0;
}

.imgResposiveForUpload small {
    color: #999999;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}


.AccordionForSrtyle .accordion-button {
    padding: 1rem .5rem;
    color: #4D4D4D;
    font-weight: 600;
    font-size: 16px;
}


@media (max-width: 767px) {
    .hidden-mobile {
        display: none;
    }

    .content-center-slider.content-center {
        max-height: revert;
        overflow-y: revert;
        overflow-x: hidden;
    }

    .toggle-sec .toggle-btn .knobs span {
        left: 6px;
        top: 4px;
        /* background-color: #81c14b !important; */
        z-index: 1;
    }

}

.Social_Feed_ShadowBox {
    box-shadow: none;
}
.positionForbgColorRecco {
    background: #235363af;
    position: absolute;
    bottom: 53px;
    padding: 7px;
}
/* .positionForbgColorReccos {
    background: #235363af;
    position: absolute;
    bottom: 4.7rem;
    padding: 7px;
    left: 0px;
} */

.progress-bar-warning {
    background-color: #E5F0E0;
}

.positionForheadOne {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    width: -webkit-fill-available;
    top: 0;
    padding: 2%;
    color: whitesmoke;
}

.positionForheadTwo {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    top: 163px;
    padding: 2%;
    color: whitesmoke;
    width: -webkit-fill-available;
}

/* .positionForheadThree {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    padding: 2%;
    bottom: 114px;
    width: 100% !important;
    padding: 7px;
    color: whitesmoke;
} */

.imgForShadow {
    opacity: 0.5;
}

.mediaForshade {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%);
    font-size: 18px;
    color: black;
    padding: 4px 8px;
    text-align: center;
}

.mediaForVshade {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    text-align: center;
}

.mediaForVBshade {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%);
    text-align: center;
}


.inputForMedia input {
    display: none;
    content: Add;
}

label.labelForMedia {
    margin-bottom: 0;
    cursor: pointer;
    color: #808080;
}

label.labelForMediaM {
    margin-bottom: 0;
    cursor: pointer;
    color: #808080;
    position: absolute;
    margin: 2%;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px 0px;
}

.imgForgrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.imgForstyle {
    margin: 0.5rem 0.5rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px 0px;
}

.pd-banaer-img {
    background-size: contain;
    background-repeat: no-repeat;
}

.sizeForpro.progress {
    height: 0.4rem;
    background-color: #ACDAD6;
    color: #368C4E;
    margin-right: 15px;
    
}

.sizeForpro.progress-bar {
    background-color: #368C4E;
}

.progressFortimeLine .progress-bar {
    background-color: #368C4E;
    border-radius: 35px !important;
    color: black !important;
    display: flex !important;
    justify-content: space-between;
    flex-direction: row !important;
}

.positionForMainPodacastIcon {
    margin: 0;
    position: absolute;
    top: 30%;
    width: 100%;
    text-align: center;
}

.positionForWings {
    height: 140px;
    position: absolute;
    top: 28%;
    width: 100%;
    text-align: center;
    -webkit-animation: beat .50s infinite alternate;
            animation: beat .50s infinite alternate;
    -webkit-transform-origin: center;
            transform-origin: center;
}

@-webkit-keyframes beat {
    to {
        -webkit-transform: scale(1.4);
                transform: scale(1.4);
    }
}

@keyframes beat {
    to {
        -webkit-transform: scale(1.4);
                transform: scale(1.4);
    }
}

.colorForEverything {
    color: #808080;
}

.positionForSerachSmile {
    position: absolute;
    bottom: 7px;
    right: 15px;
}

.backgroundForallIcons {
    width: 30px;
    height: 30px;
    background: #DDDDDD;
    border-radius: 20px;
}

.backgroundGForallIcons {
    width: 30px;
    height: 30px;
    background: #81C14B;
    border-radius: 20px;
}

.cardAnimate {
    display: none;
    transition: opacity 1s ease-out;
    opacity: 0;
}

.postCardForSize {
    width: 100%;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
}

.Danimated {
    background-repeat: no-repeat;
    background-position: left top;
    /* padding-top: 95px; */
    margin-bottom: 60px;
    -webkit-animation-duration: 8s;
    animation-duration: 10s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeOuts {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOuts {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fadeOuts {
    -webkit-animation-name: fadeOuts;
    animation-name: fadeOuts;
}

.removed-item {
    -webkit-animation: removed-item-animation 1s cubic-bezier(0.55, -0.04, 0.91, 0.94) forwards;
            animation: removed-item-animation 1s cubic-bezier(0.55, -0.04, 0.91, 0.94) forwards;
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
}

@-webkit-keyframes removed-item-animation {
    0% {
        opacity: 1;
        -webkit-transform: rotateZ(0);
                transform: rotateZ(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(600px) rotateZ(90deg);
                transform: translateY(600px) rotateZ(90deg);
        /* display: none !important; */
    }

    101% {
        display: none;
    }
}

@keyframes removed-item-animation {
    0% {
        opacity: 1;
        -webkit-transform: rotateZ(0);
                transform: rotateZ(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(600px) rotateZ(90deg);
                transform: translateY(600px) rotateZ(90deg);
        /* display: none !important; */
    }

    101% {
        display: none;
    }
}

.onhovForPostselection {
    display: flex;
    align-items: center;
    border: 1px solid #F6F6F6;
    font-size: 12px;
    color: #808080;
}

.onhovForPostselection a {

    color: #808080;
}

.onhovForPostselection:hover {
    background-color: #F6F6F6;
}

.positionForCrossbutton {
    position: absolute;
    right: 1rem;
    margin: 2%;
    color: #b0cf66;
}

.inputFortext {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding-top: 14px;
    color: rgba(77, 77, 77, 0.5);
    background: #F6F6F6;
    resize: none;
}

.inputForback.form-control:focus {
    color: #212529;
    background-color: #F6F6F6;
    z-index: 1;
    border-color: #81C14B;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.positionForplaceholder::-webkit-input-placeholder {
    position: absolute;
    /* top: 45%; */
    left: 35%;
    color: #6c757d;
    font-size: 16px;
}

.positionForplaceholder::placeholder {
    position: absolute;
    /* top: 45%; */
    left: 35%;
    color: #6c757d;
    font-size: 16px;
}

.positionForArticleplaceholder::-webkit-input-placeholder {
    position: absolute;
    top: 45%;
    left: 25%;
    color: #4F4F4F;
    font-size: 16px;
}

.positionForArticleplaceholder::placeholder {
    position: absolute;
    top: 45%;
    left: 25%;
    color: #4F4F4F;
    font-size: 16px;
}

.positionForReccplaceholder::-webkit-input-placeholder {
    color: #81C14B;
    font-size: 12px;
    margin-left: 15%;
}

.positionForReccplaceholder::placeholder {
    color: #81C14B;
    font-size: 12px;
    margin-left: 15%;
}

.shadowForcircle {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 15px -3px, rgba(0, 0, 0, 0.25) 0px 4px 6px -2px;
    border-radius: 50%;
}

.btn-post {
    color: #ffffff;
    background-color: #ACDAD6;
    border-color: #ACDAD6;
}

.btn-post:hover {
    color: #ffffff;
    background-color: #81C14B;
    border-color: #81C14B;
}

.public-DraftStyleDefault-ltr {
    direction: ltr;
    text-align: left;
    /* border: 1px solid #F1F1F1; */
}

.rdw-left-aligned-block>div {
    display: revert !important;
}

.Podcast-section {
    position: relative;

}

.Podcast-section .PodcastForBanner {
    object-fit: cover;
    /* width: 100%; */
    height: 300px;

}

.positionForheadTwo {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    top: 217px;
    padding: 9px;
    color: #FFFFFF;
}

.positionForheadThree {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    top: 219px;
    /* bottom: 115px; */
    padding: 9px;
    color: #FFFFFF;
}


.ql-editor {
    box-sizing: border-box;
    line-height: 1.42;
    height: 100%;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
    /* margin-top: 397px; */
    border-top: 1px solid #ccc;
    min-height: 157px;
}

.positionForHead {
    position: absolute;
    top: 58px;
    width: 100%;
}

.positionForArticleSplaceholder {
    height: 52px;
    /* border: ; */
    padding-top: 2%;
    padding-bottom: 0;
    overflow: auto;
    box-shadow: none;
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
    font-size: 42px;
    line-height: 40px;
    margin: 3.2rem 0;
    min-height: 4.8rem;
    overflow-y: hidden !important;
    resize: none;
    max-width: -webkit-fill-available;
    margin: 47px 32px 4px 0;
}

.header-text-style {
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    color: #808080;
}

.positionForArticleSplaceholder::-webkit-input-placeholder {
    color: var(--color-text);
    font-size: 42px;
    line-height: 48px;
}

.positionForArticleSplaceholder::placeholder {
    color: var(--color-text);
    font-size: 42px;
    line-height: 48px;
}

.kitopenFor {
    padding: 0.25rem;
    -webkit-animation-name: spin;
            animation-name: spin;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
}

.positionForArticleSplaceholderS {
    height: 52px;
    padding-top: 2%;
    padding-bottom: 0;
    overflow: auto;
    box-shadow: none;
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
    font-size: 42px;
    line-height: 40px;
    margin: 3.2rem 0;
    min-height: 4.8rem;
    overflow-y: hidden !important;
    resize: none;
    /* max-width: -webkit-fill-available; */
    margin: 47px 32px 4px 0;
}

.positionForArticleSplaceholderS::-webkit-input-placeholder {
    color: var(--color-text);
    font-size: 42px;
    line-height: 48px;
}

.positionForArticleSplaceholderS::placeholder {
    color: var(--color-text);
    font-size: 42px;
    line-height: 48px;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
    }
}

.HovForkit {
    border: 1px solid #dee2e6;
    padding: 0.25rem;
}

.HovForkit:hover {
    background-color: #dee2e6;
}

.animForkit {
    display: block;
    /* animation-delay: 1.5s; */
    -webkit-animation-name: moveInleft;
            animation-name: moveInleft;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
}

.positionForImgremove {
    position: absolute;
    top: 135px;
    right: 45px;
}

.positionForImgremoveS {
    position: absolute;
    top: 195px;
    right: 40px;
}

.kitForinputbox {
    display: inline-block;
    background-color: transparent;
    color: white;
    cursor: pointer;
    width: 100%;
}


@-webkit-keyframes moveInleft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-15px);
                transform: translateX(-15px);
    }

    /* 80% {
        transform: translateX(10px);
    } */

    100% {
        opacity: 1;
        -webkit-transform: translate(0);
                transform: translate(0);
    }
}


@keyframes moveInleft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-15px);
                transform: translateX(-15px);
    }

    /* 80% {
        transform: translateX(10px);
    } */

    100% {
        opacity: 1;
        -webkit-transform: translate(0);
                transform: translate(0);
    }
}

.rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    min-height: 500px;
}

.StickerForslider {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 200px;
}

.StickerForslider::-webkit-scrollbar {
    display: none;
}

.reactionFortab {
    position: absolute;
    border-radius: 30px 30px 30px 30px;
    left: 25px;
    bottom: 116px;
}

.reactionFortab button {
    margin: 1px;
    padding: revert;
}

.mediaForGridContainer {
    display: flex;
}

.mediaForGridContainers {
    display: flex;
}

.mediaForGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mediaForGridContainer img {
    border: 1px solid white;
}

.mediaForGridContainers .borderForgrid {
    border: 1px solid white;
}

@media (max-width: 420px) {
    .positionForheadTwo {
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        top: 211px;
        padding: 2%;
        color: whitesmoke;
    }

    .positionForbgColorRecco {
        background: #235363af;
        position: absolute;
        bottom: 70px;
        padding: 7px;
    }

    .positionForheadThree {
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        top: 207px;
        /* bottom: 115px; */
        padding: 9px;
        color: #FFFFFF;
    }

    .mediaForshade {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 165%);
                transform: translate(-50%, 165%);
        font-size: 16px;
        font-weight: bold;
        color: black;
        padding: 4px 8px;
        text-align: center;
    }

    .sizeForText {
        font-size: 10px;
    }

    .positionForplaceholder::-webkit-input-placeholder {
        position: absolute;
        top: 45%;
        left: 20%;
        color: white;
        font-size: 16px;
    }

    .positionForplaceholder::placeholder {
        position: absolute;
        top: 45%;
        left: 20%;
        color: white;
        font-size: 16px;
    }

    .mediaForVshade img {
        width: 40px;
    }

    .mediaForVshade {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 70%);
                transform: translate(-50%, 70%);
        text-align: center;
    }

}



@media (max-width: 360px) {
    .positionForheadTwo {
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        top: 200px;
        padding: 2%;
        color: whitesmoke;
    }

    .positionForheadThree {
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        top: 213px;
        padding: 2%;
        color: whitesmoke;
        width: -webkit-fill-available;
    }

    .positionForplaceholder::-webkit-input-placeholder {
        position: absolute;
        top: 45%;
        left: 10%;
        color: white;
        font-size: 16px;
    }

    .positionForplaceholder::placeholder {
        position: absolute;
        top: 45%;
        left: 10%;
        color: white;
        font-size: 16px;
    }

    .positionForWings {
        height: 140px;
        position: absolute;
        top: 21%;
        width: 100%;
        text-align: center;
        -webkit-animation: beat .50s infinite alternate;
                animation: beat .50s infinite alternate;
        -webkit-transform-origin: center;
                transform-origin: center;
    }

    .mediaForshade {
        position: absolute;
        top: 10px;
        left: 50%;
        -webkit-transform: translate(-50%, 175%);
                transform: translate(-50%, 175%);
        font-size: 12px;
        font-weight: bold;
        color: black;
        padding: 4px 8px;
        text-align: center;
    }

    .mediaForVshade img {
        width: 35px;
    }

    .mediaForVshade {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 90%);
                transform: translate(-50%, 90%);
        text-align: center;
    }
}
/*start_ Catalogues_card_section */
.CataloguesCard {
  /* width: 274px;
    height: 250px;
    background: rgba(129, 193, 75, 0.1);
    border: 1px solid #81C14B;
    border-radius: 5px;
    justify-content: center; */
  border: 1px solid #ddd;
  border-radius: 4px;
  /* padding: 4px; */
  display: block;
  position: relative;
  height: 250px;
  background: rgba(129, 193, 75, 0.1);
}

.cataloguesBodyCard {
  text-align: center;
  margin-top: 25%;
}

.CataloguesCards {
  box-sizing: border-box;
  position: absolute;
  border-radius: 5px;
  position: absolute;
}

.data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  grid-gap: 20px;
  gap: 20px;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.catalogueItem {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}

/* end_ Catalogues_card_section */
/* start_Reviews_Tab */
.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  grid-gap: 554px;
  gap: 554px;
  width: 764px;
  height: 50px;
}

.Rowdata {
  justify-content: space-between;
  display: flex;
}

.replydata {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
}

.reviewsData {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
}

.ProfileName {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #4d4d4d;
}

.inputbox {
  box-sizing: border-box;
  background: rgba(246, 246, 246, 0.09);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 713px;
  height: 90px;
}

/* end_Reviews_Tab */

/* start_ReactSlick_css */
.group {
  /* width: 170px;
    height: 160px;
    left: 170px;
    top: 0px;
    border-radius: 5px; */
}

.ReviewsHeading h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
}

.ReviewImgSection img {
  width: 369px;
  height: 84px;
  border-radius: 12px;
}

.ReviewPost {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
}

.ReviewTitle h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: #4d4d4d;
}

.spaninfo {
  font-size: small;
  font-weight: 400;
  font-style: normal;
  color: #000000;
  font-size: 12px;
}

/*end_ ReactSlick_css */
/* start_CreateBiz#_css */
.CardTextHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #4d4d4d;
}

/* end_CreateBiz#_css */
/* start_BizPageSetting_css */
.BizPageSettingTab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(129, 193, 75, 0.2);
  height: 50px;
  align-items: center;
  padding: 20px 10px;
}

.nave-items-bizPage {
  align-items: center;
  /* background: rgba(129, 193, 75, 0.2); */
  /* border-radius: 5px !important; */
  color: #4d4d4d !important;
  font-weight: 300;
  /* padding: 10px 5px; */
}

/* .nave-items-bizPage:hover {
  align-items: center;
 
  color: #FFFFFF !important;


} */

.BizPageSettingTab :hover {
  background: rgba(129, 193, 75, 0.2);
}

.nave-items-bizPage span {
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  color: #4d4d4d;
}

.custom-card-head,
.biz-carddetail-blk h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 18px;
}

.PinPage {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.PinPage p {
  font-weight: 500;
  font-size: 16px;
}

.PinPage h5 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

.PinPage small {
  color: #808080;
  font-weight: 400;
  font-size: 16px;
}

.DelBizData h5 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

.DelBizData small {
  color: #808080;
  font-weight: 400;
  font-size: 16px;
}

.DelBizData button {
  margin-top: 10px;
}

.DelBizData button p {
  color: #ff6b00;
}

.biz-main-Setting-blk {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.biz-PeopleFollow-blk {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.bizPage_pageRole-blk:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.biz-PeopleFollow-blk:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.biz-PeopleFollow-blk-blk:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.biz-PeopleFollow-blk h4 {
  font-weight: 600;
  font-size: 16px;
  color: #4d4d4d;
  margin: 0 0 8px;
}

.biz-PeopleFollow-blk p {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.biz-BlockedFollow-blk p {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.biz-PeopleFollow-blk h5 {
  font-weight: 500;
  font-size: 14px;
  color: #4d4d4d;
  margin: 0 0 8px;
}

.biz-main-Setting-blk h4 {
  font-weight: 500;
  font-size: 18px;
  color: #4d4d4d;
  margin: 0 0 10px;
}

.biz-main-Setting-blk p {
  font-weight: 400;
  font-size: 15px;
  color: #4d4d4d;
  line-height: 1.6;
}

.Biz_Notification_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Biz_Notification_section h5 {
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
}

li.Biz_setting {
  background: rgba(129, 193, 75, 0.2);
  cursor: pointer;
  border-right: 5px solid #81c14b;
}

li.left_setting:hover {
  background: rgba(129, 193, 75, 0.2);
  cursor: pointer;
  border-right: 5px solid #81c14b;
}

.biz_Filter_btn {
  cursor: pointer;
  padding: 10px 25px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Biz_business_stng.w-50.show {
  -webkit-transform: translate(537px, 66px) !important;
          transform: translate(537px, 66px) !important;
  padding: 0px !important;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Biz_business_stng.w-25.show {
  -webkit-transform: translate(661px, 67px) !important;
          transform: translate(661px, 67px) !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

/* end_BizPageSetting_css */

/* ----------------------start_About_biz_Page------------------------ */
.Biz_Location_btn {
  position: absolute;
  top: 8px;
  right: 10px;
  background: #ffffff;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
}

.bizabout-location {
  position: relative;
  object-fit: cover;
}

.BizAbout_bottom_section {
  display: flex;
  align-items: start;
  margin-top: 10px;
}

.Biz_Icon {
  padding: 7px;
  background: rgba(126, 178, 98, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BizAbout_Icon {
  padding: 7px;
  background: rgba(126, 178, 98, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.BizAbout_bottom_section .BizAbout_Detail {
  width: calc(100% - 45px);
}

.BizAbout_bottom_section .BizAbout_Detail h5 {
  color: #4d4d4d;
  -webkit-font-feature-settings: lining-nums tabular-nums;
          font-feature-settings: lining-nums tabular-nums;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BizAbout_bottom_section .BizAbout_Detail h6 {
  color: #4d4d4d;
  /* text-transform: capitalize; */
  line-height: 1.4;
  font-size: 16px;
  font-weight: 500;
}

.BizAbout_blk_section {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}

.BizAbout_edit_Icon {
  background: #f6f6f6;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.BizAbout_blk_section .BizDetails {
  width: calc(100% - 45px);
}

.BizAbout_blk_section .BizDetails h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  color: #4d4d4d;
}

.BizAbout_blk_section .BizDetails h6 {
  color: #4d4d4d;
  /* text-transform: capitalize; */
  line-height: 1.4;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 500;
}

.bizAbout_cont_section ul li {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.bizAbout_cont_section ul li iframe {
  width: 100%;
  height: 150px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}

.bizAbout_Map_Modal iframe {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  margin-top: 10px;
}

a.BizLink {
  color: #0066ff;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
}

a.OverView_BizLink {
  color: #0066ff;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.BizAbout_Modal_section {
  display: flex;
  justify-content: space-between;
  background: #f6f6f6;
  border-radius: 10px 10px 0px 0px;
}

.BizAbout_Heading {
  border-bottom: 1px solid #f6f6f6;
  border-color: rgba(0, 0, 0, 0.08);
  width: 100%;
}

.BizAbout_Modal_section h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.BizAbout_Modal_section p {
  color: #81c14b;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  line-height: 24px;
}

.BizAbout_input_box {
  position: relative;
}

.BizAbout_inputForm {
  padding-right: 20%;
}

.AboutBiz_section p {
  color: #81c14b;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.BizAbout_Map_heading {
  background: #f6f6f6;
  display: flex;
  justify-content: center;
}

.BizAbout_Map_heading h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #4d4d4d;
}

.BizAbout_Map_section {
  display: flex;
  justify-content: space-between;
}

.BizAbout_Map_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.BizAbout_Map_section p {
  color: #ff5b58;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.BizAbout_Map_subSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BizAbout_Map_subSection h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #4d4d4d;
}

.BizAbout_Map_subSection span {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #81c14b;
  cursor: pointer;
}

.RemoveBiz_section {
  text-align: center;
}

.RemoveBiz_section h5 {
  color: #808080;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.RemoveBiz_section h6 {
  color: #808080;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.Biz_Catalogue_Section {
  background: rgba(129, 193, 75, 0.1);
  border: 1px solid #81c14b;
  border-radius: 5px;
  height: 274px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Biz_Catalogue_Section h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.Biz_Catalogue_Section h4 {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.Biz_catalogue_media_section {
  border-radius: 4px;
  display: block;
  position: relative;
}

.Biz_catalogue_Allmedia_section {
  display: block;
  position: relative;
  border: 5px solid #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
}

.Biz_catalogue_img {
  width: 100%;
  height: 274px;
  object-fit: cover;
  border-radius: 5px;
}

.Catalogue_Top_section {
  position: absolute;
  right: 0px;
  top: 8px;
  cursor: pointer;
}

.Catalogue_LeftTop_section {
  position: absolute;
  left: 8px;
  top: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Catalogue_LeftTop_section span {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}

.Catalogue_bottom_section {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(5px) !important;
          backdrop-filter: blur(5px) !important;
  width: 100%;
  /* padding: 20px; */
  height: 77px;
  left: 0;
}

.Catalogue_bottom_section span {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

element.style {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  -webkit-transform: translate(0px, 27px);
          transform: translate(0px, 27px);
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Biz_Drop_Option.show {
  -webkit-transform: translate(-167px, 17px) !important;
          transform: translate(-167px, 17px) !important;
  padding: 0px;
}

.Catalogues_heading_section {
  display: flex;
  justify-content: space-between;
}

.Catalogues_heading_section h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #4d4d4d;
}

.Catalogue_buttom_Count {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 41.15%,
    rgba(0, 0, 0, 0.5) 100%
  );
  padding: 17px 10px 10px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Catalogue_buttom_Count span {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

.Biz_Icon_btn {
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
}

.Biz_modal_heading {
  display: flex;
  justify-content: center;
}

.Biz_modal_heading h3 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.biz_label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
}

/* ----------------------end_About_biz_Page------------------------ */
/*-----------------------start_Biz_Review_page---------------------*/

.BizReview_section_box p {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
}

.BizReview_section_Subbox {
  display: flex;
  justify-content: space-between;
}

.BizReview_section_Subbox p {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
}

.BizReview_Detail h3 {
  color: #4d4d4d;
  -webkit-font-feature-settings: lining-nums tabular-nums;
          font-feature-settings: lining-nums tabular-nums;
  font-variant-numeric: lining-nums tabular-nums;

  /* /H3 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* @media (max-width: 540px) {
  .BizReview_Detail h3 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 14px;
  }
} */

.BizReview_Detail_MoreQuestionSection {
  color: #81c14b;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

/* .BizReview_Detail b {
  font-weight: 700;
  font-size: 16px;
  color: #4D4D4D;
} */

.BizReview_Detail_Question b {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 18px */
}

.BizReview_Detail_Question h4 {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.BizReview_Center_Section {
  margin-left: 42px;
}

/* .BizReview_Center_Section p {
  color: #222222;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
} */

.BizReview_Center_Section span {
  /* color: #81C14B; */
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  cursor: pointer;
}

.Reply_Biz_img {
  display: block;
  position: relative;
}

.Biz_ReplyImg_section {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.Biz_Progress_Bar {
  background: #efefef;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  height: 18px;
}

a.BizReview_Link {
  color: #0066ff;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
}

a.BizReviewTab_Link {
  color: #0066ff;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  cursor: pointer;
}

/*-----------------------end_Biz_Review_page---------------------*/

/*-----------------------start_Biz_OverView_page---------------------*/
.Overview_Biz_img {
  display: block;
  position: relative;
}

.Biz_OverviewImg_section {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 5px;
}

.OverView_Photo_block {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
}

.Lates_reviews_section {
  background: rgba(0, 102, 255, 0.1);
  border-radius: 5px;
  padding: 10px;
}

.Review_section p {
  color: #000000;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
}

.Review_img_box {
  object-fit: cover;
  border-radius: 12px;
  height: 80px;
  width: 80px;
}

/*-----------------------end_Biz_OverView_page---------------------*/
/*---------------------- start_Biz_Creation_page ------------------*/
.Biz_suggested_section {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
  border-radius: 5px;
  padding: 20px;
}

.BizCreation_card_head {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.BizCreation_card_head h4 small {
  padding-left: 5px;
  color: #808080;
}

.card-headInfo {
  display: flex;
  align-items: flex-start;
}

.image_section {
  border-radius: 5px;
  width: 35px;
  height: 35px;
  background: rgba(126, 178, 98, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.FormGroupInfo {
  display: flex;
  justify-content: space-between;
}

.FormGroupInfo h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.FormGroupInfo p {
  color: #81c14b;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

/*---------------------- sta_Biz_Creation_page ------------------*/

.Member_Tab_heading_section {
  /* border-bottom: solid 1px #F0F0F0; */
  padding: 10px;
}

a.Member_link.nav-link.active {
  color: #81c14b;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  border-bottom: solid 1px #81c14b;
}

a.Member_link.nav-link {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.5);
}

.Biz-members-block {
  display: block;
}

.Biz-members-block {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 15px;
  margin: 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Biz-members-block img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border: 2px solid #ffffff;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 50%;
  margin-right: 10px;
}

.BizMember_bottom_count {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.BizMember_bottom_count span {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.Ask_Review_heading {
  background: #f6f6f6;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
}

.Ask_Review_heading h2 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.css-zun73v.Mui-checked,
.css-zun73v.MuiCheckbox-indeterminate {
  color: #81c14b !important;
}

.BizDelete_Heading {
  display: flex;
  justify-content: center;
  background: #f6f6f6;
}

.BizDelete_Heading h2 {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.BizUnblock_Heading {
  background: #f6f6f6;
  /* background: #fd7e14; */
  display: flex;
  justify-content: center;
}

.BizUnblock_Heading h2 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.BizSetting_Unblock_Heading h2 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.BizSetting_Unblock_Heading p {
  color: #999999;
  font-weight: 500;
  font-size: 14px;
}

.Grpup_resend {
  text-decoration: underline;
  color: #2f80ed;
  cursor: pointer;
}

.biz-Follow-blk h4 {
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
  margin: 0 0 8px;
}
/* .profile-menu ul li a:hover svg{
  stroke: #81C14B;
} */

/*============= start_Owned_Biz_Page_css =======================*/
.BizPage_Card_section {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.2);
  position: relative;
  text-align: center;
  overflow: hidden;
}

.BizPage_Card_section1 {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.2);
  position: relative;
  text-align: center;
  overflow: hidden;
}

.BizPage_Card_section
  .BizPage_option_menu
  .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal {
  -webkit-transform: translate(-198px, 24px) !important;
          transform: translate(-198px, 24px) !important;
  width: 233px;
}

.BizPage_Card_section1
  .BizPage_option_menu
  .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal {
  -webkit-transform: translate(-198px, 24px) !important;
          transform: translate(-198px, 24px) !important;
  width: 233px;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.CataLogues_option.show {
  -webkit-transform: translate(-207px, 23px) !important;
          transform: translate(-207px, 23px) !important;
  padding: 0px;
  width: 213px;
}

.drop-menu-gal ul li a.Biz-OPTION_menu {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
}

.BizPage_option_menu {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  cursor: pointer;
}

.BizPage_Card_section .group-img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.BizPage_Card_section1 .group-img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.BizPage-buttons-blk {
  display: flex;
  align-items: center;
  justify-content: center;
}

.BizBTN_section {
  display: block;
  /* background: #f6f6f6; */
  border: 1px solid #c4c4c4;
  /* color: #808080; */
  font-weight: 500;
  font-size: 14px;
  width: 50%;
  border-radius: 5px;
  padding: 8px 5px;
  /* padding: 10px 20px; */
  margin: 5px;
}

.BizBtn_Fill_section {
  display: block;

  font-weight: 500;
  font-size: 14px;
  width: 50%;
  border-radius: 5px;
  padding: 6px 12px;
  /* padding: 10px 20px; */
  margin: 5px;
}

/*============= end_Owned_Biz_Page_css =======================*/

.BizCreation_CanCel_modal {
  background: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
}

/* .BizCreation_CanCel_modal h3 {
  
  color: #4d4d4d;
  color: #4D4D4D;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
width: 136px;
height: 30px;
position: relative;
left: 158px;

} */

.BizCreation_Content_section p {
  color: #808080;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.BizCreation_Btn_section {
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  width: 34%;
  color: #000000;
  position: relative;
  right: -27px;
}

/* { in create bizpage when tou go in left side form and go on additional info and go and setup open model mdedia query
 } */

@media (max-width: 540px) {
  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 30%;
  }

  /* .BizCreation_CanCel_modal h3 {
  
    color: #4d4d4d;
    color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 166px;
  
  } */
}

/* @media (max-width: 440px) {

  .BizCreation_CanCel_modal h3 {
  
    color: #4d4d4d;
    color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 105px;
  
  }
} */
/* @media (max-width: 400px) {

  .BizCreation_CanCel_modal h3 {
  
    color: #4d4d4d;
    color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 85px;
  
  }
} */
/* @media (max-width: 350px){
  .sugeested{
    width: 102%;
  }
} */
/* @media (max-width: 350px) {

  .BizCreation_CanCel_modal h3 {
  
    color: #4d4d4d;
    color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 85px;
  
  }
} */
@media (max-width: 412px) {
  .sucessmodal {
    color: #4d4d4d;
    font-family: Poppins;
    font-size: 23px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .foll1 {
    position: relative;
    right: 1% !important;
  }

  .sugeested {
    position: relative;
    right: 1% !important;
  }
}

/* 
@media (min-width: 540px) {

  .BizCreation_CanCel_modal h3 {
  
    color: #4d4d4d;
    color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 169px;
  
  }
} */
/* {end here} */
.BizCreation_Btn_Fillsection {
  background: #acdad6;
  border: 1px solid #acdad6;
  position: relative;
  left: -33px;
  width: 110px;
  padding-left: 8px;
}

.Biz_Creation_heading_section {
  background: #ffffff;
  margin: 0px 0 30px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
  border-radius: 5px;
}

.Biz_Creation_heading_section h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 20px;
}

.Biz_Creation_Rightsection {
  padding: 12px;
}

.Biz_Creation_Rightsection h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
}

.BizInput_heading_section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.BizInput_heading_section b {
  font-weight: 600;
  font-size: 16px;
  color: #4d4d4d;
  cursor: pointer;
}

.Biz_form_input_field {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

.Biz_form_input_field1 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

.Biz_form_tym {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* margin-left: 10px; */
}

.Biz_Form_more_location {
  cursor: pointer;
  color: #c4c4c4;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.BizForm_action_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BizForm_action_section b {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
}

.BizForm_action_section p {
  cursor: pointer;
  color: #81c14b;
  font-weight: 500;
  font-size: 14px;
}

.BizForm_toggle_section {
  cursor: pointer;
  color: #81c14b;
  font-weight: 500;
  font-size: 14px;
}

.Biz_Creation_Main_blk {
  padding-bottom: 15px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  margin-bottom: 19px;
  margin-top: 19px;
}

.Biz_Creation_Main_blk:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.Biz_Creation_setUp_section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.Biz_Creation_setUp_section h5 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 15px;
  margin: 0 0 8px;
}

.Biz_Creation_setUp_section small {
  font-weight: 400;
  font-size: 14px;
  color: #808080;
}

.Biz_toggle_action_switch {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.Biz_Creation_heading_Rightsection {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.Biz_Creation_heading_Rightsection h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.Biz_Creation_heading_Rightsection p {
  color: #4d4d4d !important;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
}

.Biz_Creation_Summery_section h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #4d4d4d;
}

.Biz_Creation_Summery_section p {
  color: #b5b5b5;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.bizCreation-user-pro-blk {
  text-align: center;
  margin-top: -90px;
}

.bizCreation-user-pro-blk img {
  border: 3px solid #ffffff;
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 50%;
}

.bizCreation-user-pro-blk h5 {
  font-size: 16px;
  color: #262626;
  margin: 15px 0 0;
  font-weight: 600;
}

.bizCreation-user-pro-blk p {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0 0;
  text-decoration: underline;
}

.bizCreation-user-pro-blk p svg {
  margin-right: 3px;
}

button.Creation_preview_btn.btn.btn-primary {
  background: #81c14b !important;
  color: #fff !important;
  border-radius: 5px !important;
  border: 1px solid #81c14b;
}

button.Creation_preview_Cancelbtn {
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}

.Biz_Rating_SECTION {
  background: #f6f6f6;
  display: flex;
  justify-content: center;
}

.Biz_Assign_section_heder {
  background: #f6f6f6;
}

.Biz_Assign_section_heder h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.Biz_Rating_SECTION h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.ASK_Question_heading h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.Biz_Review_detail_section {
  display: flex;
  align-items: flex-start;
}
.Biz_Review_detail_section1 {
  display: flex;
  align-items: flex-start;
}

.Biz_Review_detail_section h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
}
.Biz_Review_detail_section1 h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
}

.BizPost_Offering_section {
  display: flex;
  align-items: center;
}

.BizPost_Offering_section h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  text-transform: capitalize;
}

.Biz_Review_detail_section p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  color: #808080;
  align-items: center;
}
.Biz_Review_detail_section1 p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* display: flex; */
  color: #808080;
  align-items: center;
}

.Biz_Review_detail_section b {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
}

.Biz_Rating_sab_section h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.Biz_Post_sOfferingLabel_section h3 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.Biz_Messagesetting_section h3 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.Biz_Rating_status h6 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}

.about-profile .card_Biz_Review_title {
  padding: 15px;
}

.card_Biz_Review_title {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
}

.card_Biz_Review_title h3,
.card_Biz_Review_title .h3 {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.BizPage_leftSide_Heading {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.BizPage_leftSide_Heading h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
}

.Biz_Ask_Question_Limit {
  cursor: pointer;
  color: #808080;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

/* ========================= start_Biz_Review_tab_section =============================*/
.Biz_ReviewTab_heading_section h3 {
  color: #333;
  color: var(--Gray-1, #333);
  -webkit-font-feature-settings: lining-nums tabular-nums;
          font-feature-settings: lining-nums tabular-nums;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Biz_ReviewPage_content_section p {
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
}

.CataLog_Upload_img_section {
  position: relative;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.CataLog_Upload_img_section img {
  object-fit: cover;
  border-radius: 5px;
  width: 100%;
  height: 250px;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  color: #c4c4c4 !important;
}

.CataLog_Upload_Icon_section {
  position: absolute;
  top: 8px;
  right: 16px;
  cursor: pointer;
}

.WataDo_limit_section {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.customAbout-card-head {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.customAbout-card-head h4 small {
  padding-left: 5px;
  color: #808080;
}

.customAbout-card-head h3 {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.bizAbout-Main-blk {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.bizAbout-Main-blk h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
  margin: 0 0 10px;
  -webkit-font-feature-settings: lining-nums tabular-nums;
          font-feature-settings: lining-nums tabular-nums;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
}

.bizAbout-Main-blk p {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
}

.BizAddition_heading_section h3 {
  color: #4d4d4d;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.bizAdditional-Main-blk {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.bizAdditional-Main-blk h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
  margin: 0 0 10px;
}

.bizAdditional-Main-blk p {
  font-weight: 500;
  font-size: 14px;
  color: #808080;
}

.service-Aboutlist-blk span {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  display: inline-block;
  padding: 8px;
  margin: 0 10px 10px 0;
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
}

.service-Amenitieslist-blk span {
  background: #f6f6f6;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: inline-block;
  padding: 8px;
  margin: 0 10px 10px 0;
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.gallery-page-section > .Aboutcard-title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6f3db;
  padding: 20px;
}

.Aboutcard-title {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
}

.thedetector {
  color: #262626;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Aboutcard-title h4,
.Aboutcard-title .h4 {
  color: #4d4d4d;
  -webkit-font-feature-settings: lining-nums tabular-nums;
          font-feature-settings: lining-nums tabular-nums;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Aboutcard-title {
  margin-bottom: 0.5rem;
}

.Biz_Aditional_SECTION {
  background: #f6f6f6;
  display: flex;
  justify-content: center;
}

.Biz_Aditional_SECTION h3 {
  color: #4d4d4d;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.Heading_Points_section {
  color: #81c14b;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.AboutBiz_Email_section h4 {
  color: #333333;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.AboutBiz_Email_section h5 {
  color: #333333;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.AboutBiz_Email_section span {
  font-weight: 400;
  font-size: 18px;
  color: #c4c4c4;
  line-height: 27px;
  text-decoration: underline;
  cursor: pointer;
}

.AboutBiz_EmailOTP_section h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.BizAbout_location_addmore {
  color: #81c14b !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  cursor: pointer !important;
}

/* ========================= end_Biz_Review_tab_section =============================*/

.biz-main-SettingTab-blk {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.biz-main-SettingTab-blk:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.biz-main-SettingTab-blk h4 {
  font-weight: 600;
  font-size: 16px;
  color: #4d4d4d;
  margin: 0 0 10px;
}

.biz-main-SettingTab-blk p {
  font-weight: 400;
  font-size: 14px;
  color: #808080;
  line-height: 1.6;
}

.BizSetting_msg_toggle {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
}

.BizGeneral_settingDetel_modal h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
}

.BizGeneral_settingDetel_OtpSection span {
  font-weight: 500;
  font-size: 16px;
  color: #c4c4c4;
  line-height: 25px;
}

.BizSeeting_BlockBtn {
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.2);
  color: rgba(255, 0, 0, 0.6);
}

.BizSetting_DropDown_item.dropdown-menu.show {
  top: 4px !important;
  width: 220px !important;
  position: absolute !important;
  left: -129px !important;
  background: #ffffff !important;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%) !important;
  border-radius: 7px !important;
}

.Bizmember_section_blk {
  color: #c4c4c4;
}

.Bizmember_section_blk span {
  margin: 3px 4px;
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  color: #4d4d4d;
}

.Bizfriend-section h6 {
  font-size: calc(11px + (13 - 11) * ((100vw - 320px) / (1920 - 320)));
  color: #4d4d4d;
  margin-left: 6px;
  font-weight: 500;
  margin-top: 5px;
}

.bizpage-block .people-likes.Bizfriend-section {
  padding: 0 15px 15px;
}

.group-content .people-likes.Bizfriend-section {
  justify-content: center;
  margin: 5px 0;
}

.people-likes.Bizfriend-section {
  display: flex;
  align-items: center;
}

.TopBanner_profile_Picture img {
  height: 480px;
  width: 100%;
}

.TopBanner_profile_CoverPicture img {
  height: 555px;
  width: 100%;
}

.Feather_Icon_Profile {
  position: absolute;
  top: -27px;
  right: -2px;
  cursor: pointer;
}

.BizCover-top-block {
  min-height: 350px;
}

.BizCover-top-block {
  min-height: 350px;
}

@media (max-width: 575px) {
  .BizCover-top-block {
    min-height: 170px;
    justify-content: center;
  }

  .heading-title-blk h3 {
    text-align: center;
    font-size: 20px;
    margin: 0 0 5px;
  }

  .gd-content-area-top-blk {
    text-align: center;
  }

  .group-content-area-top-blk {
    text-align: center;
  }

  .gd-content-area-top-blk .people-likes.matual-friend-sec {
    justify-content: center;
  }

  .group-content-area-top-blk .people-likes.matual-friend-sec {
    justify-content: center;
  }

  .biz-details-top .BizCover-top-block {
    justify-content: flex-end;
  }
}

.Bizgd-top-btns {
  position: absolute;
  top: 325px;
  right: 9px;
  z-index: 2;
}

@media (max-width: 991.98px) {
  .biz-details-top .Bizgd-top-btns .grey-btn {
    font-size: 0;
    padding: 8px;
  }

  .biz-details-top .Bizgd-top-btns .grey-btn svg {
    margin: 0px;
  }

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    position: relative;
    left: -33px;
    width: 110px;
    padding-left: 8px;
  }

  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 34%;
    color: #000000;
    position: relative;
    right: -27px;
  }
}

.Biz_followlist-blk {
  display: flex;
  justify-content: flex-end;
}

.Biz_followlist-blk li {
  margin-left: 30px;
}

.Biz_followlist-blk li {
  margin-left: 30px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
}

.Biz_followlist-blk li span {
  display: block;
  line-height: 1;
  font-size: 12px;
  font-weight: 500;
  color: #808080;
}

.head-title-biz .Biz_followlist-blk li {
  margin-left: 7%;
}

.head-title-biz .Biz_followlist-blk li {
  margin: 0 2%;
}

@media (max-width: 991.98px) {
  .head-title-biz .Biz_followlist-blk li {
    margin: 0 2%;
  }

  .head-title-biz .Biz_followlist-blk {
    justify-content: center;
  }

  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 21%;
    color: #000000;
  }
}

.Biz_top_groupmember-blk span {
  margin: 3px 4px !important;
  font-size: 16px !important;
  display: inline-block !important;
  color: #4d4d4d !important;
  font-weight: 500 !important;
}

.BizAbout_profileEdit_Icon {
  position: absolute;
  top: 76px;
  right: 127px;
}

.biz-user-pro-blk {
  position: relative;
}

.CreatePost_Offer_section {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.CreatePost_Offer_section img {
  object-fit: cover;
  height: 250px;
  width: 100%;
  border-radius: 5px;
}

.Upload_Creation_img_section {
  position: relative;
}

.UploadImg_Position_section {
  position: absolute;
  top: 8px;
  right: 16px;
  cursor: pointer;
}

.BizPost_sOffering_OptionalSection h3 {
  color: #4d4d4d;
  font-weight: 700;
  font-size: 14px;
}

.Create_offering_post_btn {
  padding: 10px 15px;
}

.Upload_img_section h3 {
  color: #4d4d4d !important;
  font-weight: 700 !important;
  font-size: 15px !important;
}

.Upload_img_section h4 {
  color: #808080;
  font-weight: 500;
  font-size: 15px;
}

.BizReview_Profile img {
  object-fit: cover;
  border: 1px solid #ffffff;
  -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
}

.bizPage_pageRole-blk {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.bizPage_pageRole-blk:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.bizPage_pageRole-blk h4 {
  font-weight: 600;
  font-size: 16px;
  color: #4d4d4d;
  margin: 0 0 4px;
}

.bizPage_pageRole-blk h5 {
  color: #81c14b;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  margin: 0 0 4px;
}

.bizPage_pageRole-blk p {
  color: #4f4f4f;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
}

.bizPage_pageRole-Modalblk {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.bizPage_pageRole-Modalblk:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.bizPage_pageRole-Modalblk h4 {
  font-weight: 600;
  font-size: 16px;
  color: #4d4d4d;
  margin: 0 0 4px;
}

.bizPage_pageRole-Modalblk p {
  color: #808080;
  font-weight: 500;
  font-size: 12.68px;
  line-height: 19px;
}

.BizReview_Center_SubSection {
  margin-left: 67px;
}

.BizReview_Center_SubSection p {
  color: #222222;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.black12 {
  color: #000000;
}

@media (max-width: 540px) {
  .BizCreationbtn-solidUpload {
    border-radius: 5px;
    border: 1.00227px solid #c4c4c4;
    color: #ffffff;
    -webkit-transition: all 0.5s ease;
    /* transition: all 0.5s ease; */
    margin-left: 16px;
  }
}

.BizCreationbtn-solidUpload {
  border-radius: 5px;
  border: 1.00227px solid #c4c4c4;
  color: #ffffff;
  -webkit-transition: all 0.5s ease;
  /* transition: all 0.5s ease; */
}

img.AvatarGroupImg.bg-img {
  background: #ffffff;
  height: 26px;
  width: 26px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
}

.BizDetailTymList {
  display: grid;
  grid-template-areas: "Day time ";
}

.BizTymListDay {
  grid-area: "Day";
  /* text-transform: capitalize; */
  line-height: 1.4;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 500;
}

.TymBiz {
  grid-area: "time ";
  color: red;
  /* text-transform: capitalize; */
  line-height: 1.4;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 500;
}

.BizCatalogue_Main_Listblk {
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}

.BizCatalogue_Internal_blk {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.bizCat_Img_section img {
  height: 125px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

@media (max-width: 540px) {
  .bizCat_Img_section img {
    height: 125px;
    width: calc(100% - 45px);
    object-fit: cover;
    border-radius: 5px;
  }
}

@media (max-width: 912px) {
  .CardTextHeading {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #4d4d4d;
  }
}

@media (min-width: 280px) and (max-width: 571px) {
  .BizPage_Card_section {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 10%);
    position: relative;
    text-align: center;
    overflow: hidden;
    margin-right: 11px;
    margin-left: 11px;
  }
}

@media (min-width: 280px) and (max-width: 571px) {
  .BizPage_Card_section1 {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 10%);
    position: relative;
    text-align: center;
    overflow: hidden;
    margin-right: 22px;
    margin-left: 12px;
  }
}
@media (min-width: 280px) and (max-width: 575px) {
  .BizPage_Card_section112 {
    position: relative;

    margin-right: 13px;
    margin-left: 12px;
  }
}

@media (min-width: 280px) and (max-width: 420px) {
  .Biz-members-block {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    padding: 15px;
    margin: 0 0 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .profile-menu .right-menu {
    margin-left: auto;
    position: relative;
  }

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    width: 105px;
    padding-left: 10px;
    position: relative;
    left: -62px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .ownedd {
    position: relative;
    right: 7%;
  }
  .foll1 {
    position: relative;
    right: 7%;
  }
  .sugeested {
    position: relative;
    right: 7% !important;
  }
}

@media (max-width: 1080px) {
  .profile-menu .right-menu {
    margin-left: auto;
    position: relative;
  }

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    width: 105px;
    padding-left: 6px;
    position: relative;
    left: -8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 100px;
    color: #000000;
    position: relative;
    right: -4px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

@media (min-width: 1080px) {
  .BizCreation_Btn_Fillsection {
    width: 110px;
    padding-left: 9px;
    position: relative;
    left: -3px;
    border-radius: 5px;
    background: #acdad6;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 110px;

    color: #000000;
    position: relative;
    right: -2px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    background: #f6f6f6;
  }

  .pinsvgs {
    position: absolute !important;
  }
}

.profile-menu .iw-14 :hover circle {
  color: #000000;
}

@media (max-width: 540px) {
  .Biz_form_input_field {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    width: 97%;
    margin-left: 7px;
  }

  .countryfield {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    height: calc(2.25rem + 2px);
    display: block;
    width: 97%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
  }

  .BizInput_heading_section b {
    font-weight: 600;
    font-size: 16px;
    color: #4d4d4d;
    cursor: pointer;
    margin-right: -1px;
    margin-left: 9px;
  }

  .h3,
  .h3 {
    font-family: var(--bs-poppins-font);
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin: 0;
    margin-left: 8px;
  }

  .sugess {
    left: 3px;
  }
  .foll1 {
    left: 3px;
  }
}

@media (max-width: 576px) {
  .profile-cover .btn-group {
    top: 15px;
    right: 15px;
    position: relative;
    position: absolute;
    margin-left: 24px;
  }

  .addadress {
    width: 94%;
    margin-left: 7px;
  }

  .BizForm_action_section p {
    cursor: pointer;
    color: #81c14b;
    font-weight: 500;
    font-size: 14px;
    margin-right: 7px;
  }

  .input-hints {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    color: #c4c4c4;
    text-align: right;
    margin: 8px 11px 0px;
  }

  .h3,
  .h3 {
    font-family: var(--bs-poppins-font);
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin: 0;
    margin-left: 8px;
  }

  .Biz_Form_more_location {
    cursor: pointer;
    color: #c4c4c4;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    margin-right: 18px;
  }

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    width: 105px;
    padding-left: 10px;
    position: relative;
    left: -40px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

@media (max-width: 340px) {
  .Biz_form_input_field {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    width: 94%;
    margin-left: 7px;
  }

  .countryfield {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    height: calc(2.25rem + 2px);
    display: block;
    width: 94%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
  }

  /* .BizCreation_CanCel_modal h3 {
  
    color: #4d4d4d;
    color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 65px;
  
  } */
  .BizInput_heading_section b {
    font-weight: 600;
    font-size: 16px;
    color: #4d4d4d;
    cursor: pointer;
    margin-right: -1px;
    margin-left: 9px;
  }

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    width: 105px;
    padding-left: 10px;
    position: relative;
    left: -45px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 100px;
    color: #000000;
    position: relative;
    right: -2px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

/* @media (max-width: 340px) {

      
  
  .BizCreation_CanCel_modal h3 {
  
    color: #4d4d4d;
    color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 65px;
  
  }
} */
@media (max-width: 320px) {
  .ownedd {
    position: relative;
    /* right: 13px !important; */
    left: -10px !important;
  }

  .ownpage {
    margin-left: -4px;
    margin-right: -1px;
  }

  .navbiz {
    left: 5px;
  }
  .sugeested {
    position: relative;
    left: -15px !important;
  }

  .widt {
    width: 125px;
    margin-left: -7px;
    margin-right: -20px !important;
  }
}

@media (max-width: 480px) {
  .profile-cover .btn-group {
    top: 15px;
    right: 15px;
    position: relative;
    position: absolute;
    margin-left: 24px;
  }

  .Biz_form_input_field {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    width: 94%;
    margin-left: 7px;
  }

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    width: 105px;
    padding-left: 10px;
    position: relative;
    left: -24px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 100px;
    color: #000000;
    position: relative;
    right: -2px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .sugeested {
    left: 2px;
  }
  .foll1 {
    left: 2px;
  }
}

@media (max-width: 280px) {
  .profile-cover .btn-group {
    top: 15px;
    right: 15px;
    position: relative;
    position: absolute;
    margin-left: 24px;
  }

  .Biz_form_input_field {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    width: 94%;
    margin-left: 7px;
  }

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    width: 105px;
    padding-left: 10px;
    position: relative;
    left: -7px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 100px;
    color: #000000;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .sentex {
    padding-left: 10px !important;
  }

  .owned {
    position: relative;
    right: 5px;
  }

  .allbiz {
    position: relative;
    right: 7px;
  }
}

.slideronoff {
  position: relative;
  top: 22px;
  right: -5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 27px;
  top: 3px;
}

.pinsvg1 {
  position: relative;
}

.droup2 {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal {
  -webkit-transform: translate(74px, 41px) !important;
          transform: translate(74px, 41px) !important;
  padding: 0px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  opacity: 0.9;
}

@media (max-width: 945px) {
  .bizset {
    position: relative;
    margin: auto;
    top: -26px;
  }
}
@media (max-width: 375px) {
  .ownedd {
    left: 2px;
  }
}

@media (max-width: 317px) {
  .creationimg {
    position: relative;
    left: -9px;
  }
}

@media (max-width: 414px) {
  .sentex {
    padding-left: 18px;
  }
  .ownedd {
    left: 2px;
  }
}

@media (max-width: 360px) {
  .sugeested {
    position: relative;
    left: -5px;
  }

  .sentex {
    padding-left: 15px;
  }
  .foll1 {
    position: relative;
    right: 1%;
  }

  /* .BizCreation_CanCel_modal h3 {
    color: #4d4d4d;
    color: #4D4D4D;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 136px;
    height: 30px;
    position: relative;
    left: 89px;
} */

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    width: 105px;
    padding-left: 10px;
    position: relative;
    left: -14px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

@media (max-width: 393px) {
  .sucessmodal {
    color: #4d4d4d;
    font-family: Poppins;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  /* .BizCreation_CanCel_modal h3 {
  color: #4d4d4d;
  color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 89px ;
} */
}

@media (max-width: 1145px) {
  .createdbiz {
    position: relative;
    top: -24px;
    width: 218px;
  }
}

@media (max-width: 997px) {
  .createdbiz {
    position: relative;
    top: -24px;
    width: 182px;
  }
}
@media (max-width: 820px) {
  .sugeested {
    position: relative;
    right: 5px;
  }
}

@media (max-width: 600px) {
  .sugess {
    /* margin-left: -7px; */
  }

  .foll1 {
    position: relative;
    right: 4%;
  }
  .widt {
    margin-left: -7px;
    position: relative;
    right: 2px;
  }
}

@media (max-width: 1146px) {
  .cretbiz {
    width: 163px;
  }
}

.sugestborder {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.2);
}

.inviteimg {
  position: relative;
  left: 138px;
}

.BizCreation_CanCel_modal button span {
  position: absolute !important;
  top: -2rem;
  color: #ddd;
  display: none;
}

.invitebutton {
  display: block;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
  background: #81c14b;
  width: 120px;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
}

@media (max-width: 831px) {
  .widt {
    width: 145px;
    margin-right: -11px;
  }
}

@media (max-width: 779px) {
  .widt {
    width: 145px;
    margin-right: -14px;
  }
}

@media (max-width: 360px) {
  .widt {
    width: 125px;
  }
}

@media (max-width: 360px) {
  .widt {
    width: 125px;
    margin-left: -7px;
  }
}

@media (max-width: 789px) {
  .foll {
    width: 131px;
    margin-right: -6px;
    margin-left: -6px;
  }
}

@media (min-width: 360px) and (max-width: 540px) {
  .foll {
    width: 107px;
    margin-left: -3px;
    margin-right: -4px;
  }

  .widt {
    width: 125px;
    margin-right: -14px;
  }
}

@media (min-width: 280px) and (max-width: 359px) {
  .foll {
    width: 106px;
    margin-left: -3px;
    margin-right: -4px;
  }

  .foll1 {
    position: relative;
    left: -10px;
  }
}

@media (max-width: 280px) {
  .foll {
    width: 106px;
    margin-left: -7px;
    margin-right: -7px;
  }

  .widt {
    margin-left: -7px;
    margin-right: -17px;
  }

  /* .BizCreation_CanCel_modal h3 {
  color: #4d4d4d;
  color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 47px ;
} */
}

.dropshow1 {
}

.Modal_False_blk1 {
  position: absolute;
  top: -33px;
  right: -1px;
  cursor: pointer;
}
.Modal_False_blk12 {
  position: absolute;
  top: -29px;
  right: -1px;
  cursor: pointer;
}

.sucessmodal {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.py-12 {
  padding-top: 0.38rem !important;
  padding-bottom: 0.25rem !important;
}

.slider1:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: #c4c4c4;
  transition: 0.4s;
}

@media (min-width: 540px) {
  .countryfield {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    height: calc(2.25rem + 2px);
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
  }
}

.xcircle {
  cursor: pointer;
  position: absolute;
  right: 7px;
}

.cardinbiz {
  padding-right: 0px;
  margin-right: -2px;
}

.centered-heading {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.invitetext {
  margin-left: 13px !important;
}

.my-31 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

@media (min-width: 329px) and (max-width: 390px) {
  .sucessmodal {
    color: #4d4d4d;
    font-family: Poppins;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@media (min-width: 280px) and (max-width: 312px) {
  .setupmargain {
    margin-top: 2rem;
  }
}

@media (min-width: 280px) and (max-width: 328px) {
  .sucessmodal {
    color: #4d4d4d;
    font-family: Poppins;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@media (min-width: 280px) and (max-width: 767px) {
  .invitrinput {
    width: 60%;
  }

  .filterinvite {
    bottom: 41px;
  }

  .inviteallsocio {
    position: absolute;
    top: -33px;
  }

  .invitesocio {
    margin-top: -35px;
  }
}

@media (min-width: 280px) and (max-width: 390px) {
  .invitebutton {
    display: block;
    margin: 0 auto;
    text-align: center;
    border-radius: 5px;
    background: #81c14b;
    width: 85px;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
  }

  .modalmargin {
    margin-top: 2rem;
  }
}

.image-container1 {
  position: relative;
  display: inline-block;
}

.BizAbout_profileEdit_Icon1 {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 280px) and (max-width: 576px) {
  .bizhead {
    position: relative;
    left: 13px;
  }

  .seeallbiz {
    position: relative;
    right: 12px;
    top: 4px;
  }
}

.inviteheading {
  width: 139px;
}

@media (min-width: 280px) and (max-width: 360px) {
  .imginvite {
    width: 60px !important;
    height: 60px !important;
    object-fit: cover;
    border: 2px solid #ffffff;

    border-radius: 50%;
    margin-right: 10px;
  }
}

@media (min-width: 280px) and (max-width: 412px) {
  .modalmargin {
    margin-top: 2rem;
  }
}

@media (max-width: 280px) {
  .bizpageheading {
    font-size: 17px !important;
  }
}
@media (max-width: 600px) {
  .ownedd {
    position: relative;
    right: 6px;
  }
}
@media (max-width: 353px) {
  .bizset {
    top: -40px !important;
  }
}
@media (min-width: 280px) and (max-width: 576px) {
  .bizdetailprofile {
    position: relative;
    box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);

    overflow: hidden;
    margin-right: 11px;
    margin-left: 11px;
  }
}

@media (min-width: 355px) and (max-width: 548px) {
  .bizset {
    top: -20px;
  }
}
@media (min-width: 280px) and (max-width: 352px) {
  .bizset {
    top: -63px !important;
  }
}

.viewphoto {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.flex-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
  right: 9px;
  top: -26px;
}

.cretedbizpage {
  text-transform: capitalize;
  color: #647589;
  font-weight: normal;
  font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
  transition: all 0.5s ease;
}

.svgcreted {
  background: rgba(129, 193, 75, 0.2);
  border-radius: 5px;
  padding: 5px 16px;
}

@media (min-width: 992px) {
  .svgcreted {
    display: none;
  }
}
@media (min-width: 767px) {
  .setnavwindow {
    display: none;
  }
}
/* @media (min-width: 992px) {
  .entmagvifo {
    position: relative;
    top: -15px;
  }
} */

@media (max-width: 768px) {
  .img-fluid1 {
    max-width: 100%;
  }
}

.bizphotodroup {
  width: -webkit-max-content !important;
  width: max-content !important;
}

.catalogbutton {
  display: flex;
  height: -webkit-max-content;
  height: max-content;
}

.bizmaptop {
  font-size: 16px;
  display: flex;

  justify-content: center;
  margin: 3px 0 0;
  font-weight: 500;
  color: #81c14b;
  text-decoration: underline;
}

.bizmappara {
  display: flex;
  justify-content: center;
}

.bizenq {
  color: #333;
  color: var(--Gray-1, #333);
  -webkit-font-feature-settings: lining-nums tabular-nums;
          font-feature-settings: lining-nums tabular-nums;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.lscbizdetail {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.matual-friend-sec h6 {
  font-size: calc(11px + (13 - 11) * ((100vw - 320px) / (1920 - 320)));
  color: #647589;
  margin-left: 6px;
  font-weight: 500;
  margin-top: 0px !important;
}

.padavtar {
  margin: 5px 4px 10px !important;
}

.newcat {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6f3db;
  padding: 20px;
  justify-content: space-between;
}

@media (min-width: 280px) and (max-width: 442px) {
  .newcat {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e6f3db;
    padding: 20px;
    justify-content: space-between;
    flex-direction: column;
  }

  .newcatlog {
    color: #81c14b !important;
    -webkit-font-feature-settings: lining-nums tabular-nums;
            font-feature-settings: lining-nums tabular-nums;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: Poppins;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin-left: 1px !important;
    position: relative;
    top: 8px;
  }
}

.newcatlog {
  color: #81c14b !important;
  -webkit-font-feature-settings: lining-nums tabular-nums;
          font-feature-settings: lining-nums tabular-nums;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin-left: 1px !important;
}

@media (min-width: 280px) and (max-width: 430px) {
  .repfound {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

@media (min-width: 280px) and (max-width: 580px) {
  .catnone {
    display: none !important;
  }

  .bizpagepicmodal {
    max-width: 96% !important;
  }
  .bizpagepicmodal1 {
    max-width: 96% !important;
  }
}
@media (min-width: 280px) and (max-width: 768px) {
  .Review_section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .ml-311 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 1025px) {
  .Review_section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .ml-311 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 860px) {
  .repfound {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
@media (min-width: 581px) and (max-width: 1215px) {
  .bizpagepicmodal {
    max-width: 84% !important;
  }
}
@media (min-width: 581px) and (max-width: 1000px) {
  .bizpagepicmodal1 {
    max-width: 60% !important;
  }
}
@media (min-width: 280px) and (max-width: 767px) {
  .seetingnav {
    display: none;
  }
}

.bizpagepicmodal {
  max-width: 40%;
}
.bizpagepicmodal1 {
  max-width: 40%;
}

@media (min-width: 280px) and (max-width: 767px) {
  .hide-setting-options21 {
    display: none !important;
  }
}

.biz-Follow-blk {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

@media (min-width: 280px) and (max-width: 434px) {
  .biz-Follow-blk1 {
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }

  .biz-Follow-blk h4 {
    font-weight: 500;
    font-size: 16px;
    color: #4d4d4d;
    margin: 0 0 6px !important;
  }
  .biz-Follow-blk1 h4 {
    font-weight: 500;
    font-size: 16px;
    color: #4d4d4d;
    margin: 0 0 6px !important;
  }
  .admintesxt {
    position: relative;
    top: -14px;
    left: 68px;
  }

  .blockmar {
    margin: -9px;
  }
  .remmob {
    position: relative;
    top: 7px;
    left: 67px;
  }

  .blockhead {
    display: flex;
    /* align-content: stretch; */
    /* flex-wrap: wrap; */
    flex-direction: column;
    /* justify-content: space-around; */
  }
}

@media (min-width: 768px) {
  .hide-setting-options12 {
    display: none;
  }
}
@media (min-width: 432px) {
  .BizReview_section_box1 {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 435px) {
  .biz-Follow-blk {
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 435px) {
  .biz-Follow-blk1 {
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .blockhead {
    display: flex;
    /* align-content: stretch; */
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: space-around;
  }
}

.BizReview_section_box {
  display: flex;
  justify-content: space-between;
}

.askqu {
  display: flex;
  justify-content: space-between;
}

.checkbox {
  position: relative;
  top: -7px;
}

.modal-scroll-container {
  max-height: 566px; /* Set your desired max height */
  overflow-y: auto;
}

.selected-tab1 {
  background: rgba(129, 193, 75, 0.2);
  cursor: pointer;
  border-right: 5px solid #81c14b;
} /* Change this to your desired color */

@media (min-width: 280px) and (max-width: 650px) {
  .aboutside {
    display: none;
  }
}
@media (min-width: 280px) and (max-width: 767px) {
  .modal-scroll-container {
    max-height: 500px; /* Set your desired max height */
    overflow-y: auto;
  }
}

.sndcolor {
  border-radius: 50px;
  background: #81c14b;
}


/* .sendicon1 {

  position: absolute;
  right: 42px;
  margin-top: 6px;
  cursor: pointer;
} */

.sendiconbiz{
  position: absolute;
  right: 44px;
  margin-top: 6px;
  cursor: pointer;
}

@media (min-width: 280px) and (max-width: 575px) {
  .modalbiz {
    margin-top: 30px;
  }
}
@media (min-width: 280px) and (max-width: 768px) {
  .bizratingrev {
    display: flex;
    align-items: center;
  }
}
@media (min-width: 280px) and (max-width: 420px) {
  .followbut {
    position: relative;
    left: 80px;
  }
}
@media (min-width: 280px) and (max-width: 650px) {
  .qamda {
    display: none;
  }
}
@media (min-width: 280px) and (max-width: 1300px) {
  .bizavtar {
    display: none;
  }
}
@media (min-width: 280px) and (max-width: 576px) {
  .headbiz {
    position: relative;
    left: 9px;
  }
}
@media (min-width: 280px) and (max-width: 340px) {
  .Biz_Review_detail_section1 h4 {
    color: #4d4d4d;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    text-transform: capitalize;
  }
}
@media (min-width: 280px) and (max-width: 372px) {
  .bizeditrat {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
@media (min-width: 280px) and (max-width: 500px) {
  .revimap1 {
    display: none;
  }
}
@media (min-width:280px) and (max-width: 540px) {
  .upload2{
    display: none;
 
  }
}

.bizeditrat {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 651px) {
  .aboutside1 {
    display: none;
  }
}

@media (min-width: 651px) {
  .plus1 {
    display: none;
  }
}
@media (min-width: 826px) {
  .bizratingrev {
    display: flex;
    align-items: center;
  }
}

.invitemodal {
  margin-top: 1.5rem;
  padding-top: 1rem;
}

.profile-menu a.active {
  background: #81c14b;
  color: white;
}

.addpc {
  border-radius: 5px;
  border: 1px solid rgba(129, 193, 75, 0.7);
  background: rgba(129, 193, 75, 0.1);
  padding: 4px;
}



.profile-menu1 {
  /* background-color: #ffffff; */
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.02);
  /* box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.02); */
  padding: 0 5px;
  display: flex;
  align-items: center;
}


.profile-menu1 a.active {
  background: #81c14b;
  color: white;
}
.setupmargain{
  padding-top: 15px;
}


.Assign_Sub_Section1{
  position: relative;
  margin-left: 16px;
  margin-top: 18px;
  /* margin-right: 23px; */
  padding-right: 26px;
}

.custom-select-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-select {
  width: 100%;
  padding: 8px 30px 8px 10px; /* Adjust padding to make space for the arrow */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff; /* Default background color */
  color: #333; /* Default text color */
  -webkit-appearance: none;
          appearance: none; /* Remove default arrow */
  font-size: 16px;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s; /* Smooth transition */
}

.custom-select.selected {
  background-color: #81c14b; /* Green background when an option is selected */
  color: #fff; /* White text color for contrast */
}

.custom-select:focus {
  border-color: #81c14b; /* Border color on focus */
  outline: none; /* Remove default focus outline */
}

.custom-select-container::after {
  content: '▼'; /* Unicode character for arrow */
  font-size: 12px;
  color: #333; /* Default arrow color */
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust position as needed */
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  pointer-events: none; /* Make sure arrow doesn’t block click events */
}

.custom-select.selected + .custom-select-container::after {
  color: #fff; /* Change arrow color when an option is selected */
}



/* for sports background */
.profile-slide-box{
    border-radius: 5px;
background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.24) 100%); 
}

.hidden-file .choose-file input {
    opacity: 0;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
}

.btn-grp-new {
    position: unset !important;
    right: unset !important;
    bottom: unset !important;
}

.profile-cover-new {
    height: unset !important;
}

.Event-box {
    background: #FFFFFF;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: relative;
    /* width: 299px; */
    /* height: 368px; */

}

.Event-box .Event-img-sublink {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
}


.Event-box .event-img {
    object-fit: cover;
    height: 151px;
    width: 100%;
    border-radius: 5px 5px 0px 0px;
}

.Event-FirstSection {
    padding: 5px;
}

.Event-SecndSection {
    display: flex;
    justify-content: space-between;
}

.Event-SecndSection small {
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #81C14B;

}

.stng_btn {
    background: #F6F6F6;
    border-radius: 80px;
    width: 24px;
    height: 24px;
    cursor: pointer;


}

.Event-SecndSection img {
    position: absolute;
    left: 91%;
    bottom: 51%;
}

.Event-bottom-section p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #808080;

    padding-top: 10px;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.events_Option.show {
    width: auto;
    -webkit-transform: translate(49px, 10px) !important;
            transform: translate(49px, 10px) !important;
    padding: 0px !important;
}

.Event-bottom-section img {
    width: 12px;
    height: 12px;
}

.Events-bottom-paragraph {
    display: flex;

}

.Events-bottom-paragraph p {
    color: #808080;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
}

.Event_Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 41px;
    grid-gap: 10px;
    gap: 10px;
    background: #F0F0F0;
    border-radius: 5px;
    margin-top: 31px;
    cursor: pointer;
}

.Event_Button p {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 14px;
}

.Events_SendBtn {
    background: #F0F0F0;
}

.Going_Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 41px;
    grid-gap: 10px;
    gap: 10px;
    background: #81C14B;
    border-radius: 5px;
    margin-top: 31px;
    cursor: pointer;

}

.Invitation_Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 41px;
    grid-gap: 10px;
    gap: 10px;
    background: #C4C4C4;
    border-radius: 5px;
    margin-top: 31px;
    cursor: pointer;
}

.Invitation_Button p {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
}

.Going_Button p {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;

}

.Event-FirstSection h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #4D4D4D;
}

.InvitationInst_Button {
    background: #C4C4C4;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 41px;
    width: 112px;
}

.InvitationInst_Button p {
    font-weight: 600;
    font-size: 13px;
    color: #FFFFFF;
}

.InterestedButton {
    background: #C4C4C4;
    border-radius: 5px;
    height: 41px;
    width: 110px;
    align-items: center;

}

.InterestedButton span {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 12px;

}

.Interest_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    background: #C4C4C4;
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    width: 135px;
    height: 41px;

}

.MoreDropdownOption {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    background: rgba(129, 193, 75, 0.1);
    border-radius: 5px;
    height: 37px;
}

.MoreDropdownOption h6 {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 5px;
}

.DropDownMoreOption {
    background: #E5F0E0 !important;
    border-radius: 5px;
    border: none;
}

.DropDownMoreOption:hover {
    background: #81C14B !important;
    border-radius: 5px;
    border: none;
    color: #FFFFFF !important;
}

.DropDownMoreOption:active {
    background: #81C14B !important;
    border-radius: 5px;
    border: none;
    color: #FFFFFF !important;
}

.DropDownMoreOption h6 {
    background: #E5F0E0 !important;
    border-radius: 5px;
}

.profile-menu ul li.active svg,
.profile-menu ul li:hover svg {
    stroke: #FFFFFF !important;
    transition: all 0.5s ease;
}

.ancher-text {
    color: revert !important;
    border-radius: 0px !important;
    background-color: #ffffff !important;
}

.menu-item-text {
    color: #000000 !important;
    border-radius: 0px !important;
    background-color: #ffffff !important;
}

.menu-item-text:hover {
    background-color: #81C14B !important;
    border-radius: 0px !important;
    color: #FFFFFF !important;
}

.menu-item-text:active {
    background-color: #81C14B !important;
    border-radius: 0px !important;
}


.group-card-box {
    background: #FFFFFF;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: relative;


}

.group-card-box .Group-Img-sublink {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
}



.group-card-box .group-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
}

.Group-Contect_Text {
    padding: 12px;

}

.headingContectText {
    display: flex;
    justify-content: center;
}

.headingContectText h4 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 16px
}

.GroupButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #F6F6F6;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    width: 152px;
    height: 41px;
    margin-top: 15px;
    margin-left: 38px;
    cursor: pointer;
}

.Join_btn button {
    background: #F6F6F6;
}

.BookMark_Card_Box {
    background: #F6F6F6;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 29px;
    height: 28px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

.BookMark_Card_Section {
    border-radius: 5px 5px 5px 5px;
}

.BookMark_Card_Section img {
    border-radius: 5px 5px 0px 0px;
    height: 230px
}

.BookMark-card-Subdata {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    bottom: 19%;
    height: 77px;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    padding: 10px;
}

.BookMark-card-Subdata p {
    color: #81C14B;
}

.BookMark-card-Subdata span {
    color: #C4C4C4;
    font-weight: 400;
    font-size: 12px;
}

.BookMark-card-Subdata h4 {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
}

.BookMark_Button_section p {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 16px;
}

.BookMarkText_Card_Section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.BookMarkText_Card_box p {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;

}

.TextCenter_section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    background: linear-gradient(0deg, #81C14B, #81C14B);
    border-radius: 5px 5px 0px 0px;
    padding: 10px;

}

.MarketPlace_Card_Section {
    border-radius: 5px;
    /* width: 300px; */

}

.MarketPlace_Card_Section .MarketPlaceImg {
    object-fit: cover;
    border-radius: 5px 5px 0px 0px;

}

.MarketPlace_Card_LeftPosition {
    position: absolute;
    top: 8px;
    left: 16px;
    display: flex;
}

.MarketPlace_Card_LeftPosition p {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
}

.MarketPlace_Card_RightPosition {
    position: absolute;
    top: 8px;
    right: 16px;
}

/* .MarketPlace_Card_BottomLeftPosition {
    position: absolute;
    bottom: 123px;
    left: 16px;
} */

.BottomLeftPosition {
    background: #00A855;
    border-radius: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;

}

.BottomLeftPosition p {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;
}

.PenddingLeftPosition {
    background: #FF9900;
    border-radius: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
}

.PenddingLeftPosition p {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;
}

.SoludLeftPosition {
    background: #808080;
    border-radius: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
}

.SoludLeftPosition p {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;
}

.market-slide-box .MarketPlace_Card_BottomRightPosition {
    position: absolute;
    top: 116px;
    right: 10px;

}

/* .MarketPlace_Bottom_Section { */
    /* margin-top: 5px; */

/* } */

.MarketPlace_Bottom_Section h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 16px;
}

.MarketPlace_Bottom_Section h3 {
    font-weight: 600;
    font-size: 16px;

}

.MarketPlace_Bottom_Section .MarketPlace_Sub_Section {
    display: flex;
    justify-content: space-between;
    padding-top: 13px;
}

.MarketPlace_Bottom_Section .MarketPlace_Sub_Section p {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 16px;
}

.MarketPlace_Sub_Section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MarketPlace_Sub_Section h5 {
    color: #16C31E;
    font-weight: 400;
    font-size: 14px;
}

.MarketPlace_Sub_Section p {
    color: #4D4D4D;
    font-size: 16px;
    font-weight: 600;
}

.MarketPlace_Bottom_Section .MarketPlace_Sub_Section h5 {
    color: #16C31E;
    font-weight: 400;
    font-size: 14px;
}

.MarketPlace_End_section p {
    color: #808080;
    font-size: 14px;
    font-weight: 400;
}

/* .MarketPlace_Bottom_Section .MarketPlace_End_section {
    /* padding-top: 6px; */


.OwnedBizPage_section {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.OwnedBizPage_section .createBizImg {
    object-fit: cover;
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    height: 100px;

}

.OwnedBizPage_section .MenuBar_BizPage {
    position: absolute;
    right: 22px;
    top: 8px;
}

.Biz-Profile img {
    border: 2px solid #FFFFFF;
    -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
            filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
}

.Biz-Profile {
    position: absolute;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    bottom: 224px;
    left: 50%;
}

.Biz-content {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.Biz-content p {
    color: #808080;
    font-weight: 400;
    font-size: 14px;
}

.BizGroupContent {
    display: flex;
    justify-content: space-between;
}


.BizEmailcontnet {
    text-align: center;
}

.ButtonClass p {
    color: #808080;
    font-weight: 500;
    font-size: 14px;
}

.Followedbtn p {
    color: #81C14B;
    font-weight: 500;
    font-size: 14px;
}

/* start_Atrical-section */
.Artical_Section {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.Artical_Section .ArticalImg {
    object-fit: cover;
    border-radius: 5px 5px 0px 0px;
    width: 100%;
}

.Artical_Top_Right {
    position: absolute;
    top: 8px;
    right: 16px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
    display: flex;
    flex-direction: row;

}

.Artical_Heading_Section h4 {
    font-weight: 500;
    font-size: 18px;
    color: #4D4D4D;
}

.Artical_subHeading_Section p {
    color: #808080;
    font-weight: 400;
    font-size: 14px;
}

.Artical_Main_section .Artical_Title_Section span {
    color: #808080;
    font-weight: 400;
    font-size: 14px;
}

/* Atrical-section */
/* start_Recommendation_section */
.Recommendation_section {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.Recommendation_section .RecommendationImg {
    border-radius: 5px 5px 0px 0px;
    object-fit: cover;
    width: 100%;
}

.Recommendation_Body_Section {
    background: #235363;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
}

.Recommendation_Body_Section p {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
}

.Recommendation_SubHeading p {
    color: #4D4D4D;
    font-size: 17px;
    line-height: 24px;
}

.Recommendation_Location p {
    color: #808080;
    font-weight: 400;
    font-size: 14px;
}

.Recommendation_Bottom_section .Recommendation_btn h4 {
    color: #999999;
    font-weight: 500;
    font-size: 15px;
}

.Recommendation_Bottom_section .Recommendation_btn .RecommendationImg {
    width: 20px;
    height: 20px;
    color: #999999;
}

.Recommendation_RightPosition {
    position: absolute;
    top: 8px;
    right: 16px;
    background: rgba(246, 246, 246, 0.7);
    border-radius: 80px;
}

.Text_section {
    background: #FFFFFF;
    border: 1px solid #81C14B !important;
}

.Mordreate_Text_Section {
    background: #FFE8D3;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.Severe_Text_Section {
    background: rgba(255, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.Mild_Text_Section {
    background: rgba(255, 230, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.TextLeft_Top {
    position: absolute;
    top: 8px;
    left: 16px;
}

.TextLeft_Top p {
    color: #333333;
    font-weight: 600;
    font-size: 20px;
}

.Mordreate_TextRight_Top {
    position: absolute;
    top: 8px;
    right: 16px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}

.Mlid_TextRight_Top {
    position: absolute;
    top: 8px;
    right: 16px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 20px;

}

.Severe_TextRight_Top {
    position: absolute;
    top: 8px;
    right: 16px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 20px;

}

.Text_TextRight_Top {
    position: absolute;
    top: 8px;
    right: 16px;
    background: #F6F6F6;
    border-radius: 20px;

}

.Text_Center_part p {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 14px;

    margin-left: 16px;
    margin-right: 16px;
    margin-top: 8px;

}

.Text_part p {
    color: #333333;
    font-weight: 400;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    font-size: 14px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 8px;


}


.Mordreate_SubText_part p {
    color: #FF8B02;
    font-weight: 400;
    font-size: 12px;
    margin-left: 16px;
    margin-top: 16px;
}

.Mild_SubText_part p {
    color: #FFB802;
    font-weight: 400;
    font-size: 12px;
    margin-left: 16px;
    margin-top: 16px;
}

.Severe_SubText_part p {
    color: #FF0202;
    font-weight: 400;
    font-size: 12px;
    margin-left: 16px;
    margin-top: 16px;
}

.Poll_RowFirtSide_section {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(129, 193, 75, 0.1);
    border-radius: 5px;
}

.PollMoreMenu {
    background: #EFEFEF;
    border-radius: 40px;
    padding: 10px;
    cursor: pointer;
}

.Poll_Progress_Bar {
    background: #D4E7E0;
    border-radius: 35px;
    height: 35px;

}

.Poll_Progress_Bar .Progress_Events {
    background: #FFD600;
    border-radius: 35px;
    width: 174px;

}

.Poll_Progress_Bar .Progress_Events p {
    color: #48985C;
    font-weight: 500;
    font-size: 16px;
    left: 22px;
    top: 7px;
    position: relative;
}

.Poll_Progress_Bar .Progress_Rating p {
    color: #48985C;
    font-weight: 500;
    font-size: 14px;
    top: 8px;
    right: 22px;
    position: relative;
}

.Progress_Bar_Poll {
    background: #D4E7E0;
    border-radius: 35px;
    height: 35px;
    display: flex !important;
    justify-content: space-between !important;
}

.Progress_Bar_Poll .progress-bar {
    background-color: #FFD600;
    border-radius: 35px;
}

.Progress_Bar_Poll .progress-bar label {
    color: #48985C;
    left: 22px;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    top: 4px;
}

.Progress_Bar {
    background: #D4E7E0;
    border-radius: 35px;
    height: 35px;
}

.Progress_Rating {
    position: absolute;
    right: 45px;
    color: #48985C;
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;

}

/*------------------------- start_Running_Poll------------------------ */
.Completed_Bar_Poll {
    background: #D4E7E0;
    border-radius: 35px;
    height: 35px;
    display: flex !important;
    justify-content: space-between !important;
}

.Completed_Bar_Poll .progress-bar {
    background-color: #C4C4C4;
    border-radius: 35px;
}

.Completed_Bar_Poll .progress-bar label {
    color: #48985C;
    left: 22px;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    top: 4px;
}

.Completed_Analytics {
    display: flex;
    justify-content: space-between;
}

.Completed_Analytics h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #4D4D4D;
}

.Completed_Analytics p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #808080;
}

/*------------------------- end_Running_Poll------------------------ */
/*------------------------- start_Schedule_Poll------------------------ */
.Schedule_Bar_Poll {
    background: #D4E7E0;
    border-radius: 35px;
    height: 35px;
    display: flex !important;
    justify-content: space-between !important;
}

.Schedule_Bar_Poll .progress-bar {
    background-color: #D4E7E0;
    border-radius: 35px;
}

.Schedule_Bar_Poll .progress-bar label {
    color: #48985C;
    left: 22px;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    top: 4px;
}

.Scheduled_Analytics {
    display: flex;
    justify-content: space-between;
}

.Scheduled_Analytics h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #4D4D4D;
}

.Scheduled_Analytics p {
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #808080;
}

/*------------------------- end_Schedule_Poll------------------------ */
.Poll_Btn_section {
    background: #F6F6F6;
    height: 48px;
}

.Poll_Btn_section .Btn_section .flex_btn p {
    background: #DDDDDD;
    border-radius: 20px;
    width: 25px;
    height: 25px;
}

.Poll_Btn_section .Btn_section .btn_flex p {
    background: #DDDDDD;
    border-radius: 20px;
    width: 25px;
    height: 25px;

}

.Poll_Btn_section .Btn_section .btn_flex img {
    position: absolute;
    padding: 5px;
}

.Poll_Btn_section .Btn_section .flex_btn img {
    position: absolute;
    padding-left: 6px;
    padding-top: 5px;
}

.Poll_Analytics {
    display: flex;
    justify-content: space-between;
}

.Poll_Analytics h4 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 18px;
}

.Poll_Analytics p {
    color: #81C14B;
    font-weight: 500;
    font-size: 18px;
}

.Poll_Analytics .Poll_AnalyticsData {
    background: #F6F6F6;
    border-radius: 5px;
    height: 100px;
    width: 224px;
}

.Poll_Analytics .Poll_AnalyticsData h4 {
    color: #16C31E;
    font-weight: 500;
    font-size: 21px;
}

.Analytics_Poll {
    background: #F6F6F6;
    border-radius: 5px;
}

.Analytics_Poll h4 {
    color: #16C31E;
    font-weight: 500;
    font-size: 21px;
}

.Analytics_Poll .Analytics_Flex_box p {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 16px;
}

.Analytics_Poll .Analytics_Flex_box span {
    color: #0066FF;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
}

.Bottom_AllType_Tab_Section p {
    color: #81C14B;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;

}

.Bottom_AllType_Tab_Section span {
    font-weight: 500;
    font-size: 18px;
    color: #4D4D4D;
    cursor: pointer;
}

.Poll_Analaytics:active {
    color: #81C14B;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

a.Poll_link.nav-link {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

a.Poll_link.nav-link.active {
    color: #81C14B;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    border-bottom: solid 1px #81C14B;
}

.Gender_Progress_Bar {
    background: #EFF7F9;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}

.Gender_Progress_Bar .progress-bar {
    background-color: #3C803D !important;
    border-radius: 20px;
}

.PollGenderPreivew h4 {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 16px;
}

.PollGenderPreivew h5 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 16px;
}

.ViewModal_Heading_Section h4 {
    font-weight: 500;
    font-size: 18px;
    color: #81C14B;

}

/* start user profile */
/* end user profile */
.UserProfile_cover {
    height: 160px;
    border-radius: 5px 5px 0 0;
    box-shadow: none;
}

.UserProfile_cove .img-fluid.bg-img {
    height: 160px;
}

::-webkit-scrollbar{
    width: 3px;
}::-webkit-scrollbar-track{
    background-color: #e2fae1;
}::-webkit-scrollbar-thumb{
    background-color: #8abe46;
}

.DropDown_item.dropdown-menu.show {
    top: 4px !important;
    width: auto !important; /* Change width to auto to adjust based on content */
    min-width: 220px !important; /* Set a minimum width to ensure dropdown is not too small */
    max-width: 350px !important; /* Set a maximum width to prevent it from being too wide */
    position: absolute !important;
    left: 11px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
    border-radius: 7px !important;
    max-height: 210px;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; 
}

.PodCast_DropDown_item.dropdown-menu.show {
    top: 4px !important;
    width: 220px !important;
    position: absolute !important;
    left: -184px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
    border-radius: 7px !important;
}

.DropDown_Share_item.dropdown-menu.show {
    top: 4px !important;
    width: 220px !important;
    position: absolute !important;
    left: -126px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
    border-radius: 7px !important;
}

.DropDown_Group_item.dropdown-menu.show {
    top: 4px !important;
    width: 220px !important;
    position: absolute !important;
    background: #FFFFFF !important;
    left: -9px !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
    border-radius: 7px !important;
}

.MenuBar_BizPage .dropdown-menu .dropdown-menu-right .custom-dropdowns .drop-menu-gal {
    -webkit-transform: translate(-267px, 150px) !important;
            transform: translate(-267px, 150px) !important;
}

.MenuBar_BizPage .dropdown-menu.show {
    display: block;
    width: 200px !important;
}

.Profile_Info h4 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 16px;
}

.Profile_Info .Post_Count h4 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 14px;


}

.Profile_Info .Post_Count span {
    font-weight: 500;
    font-size: 14px;
    color: #808080;
}

.Profile_Info .Post_Count .Follower_count h4 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 14px;
}

.Profile_Info .Post_Count .Follower_count span {
    font-weight: 500;
    font-size: 14px;
    color: #808080;

}

.Like_Profile_user {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 2px;
    margin-left: 2px;
    margin-right: -1px;
}

.Btn_Typ .View_Btn {
    background: #EFEFEF;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
}

.Btn_Typ .View_Btn span {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 14px;
}

.ViewTab_Section a {
    color: #81C14B !important;
}

.ViewTab_Section .viewTab_NavItem {
    padding: 10px !important;
}

.ViewTab_Section .viewTab_NavItem span {
    font-weight: 400;
    font-size: 18px;
}

/* .Like_search {} */

.Like_search .Srch_btn {
    position: absolute;
    left: 22px;
    top: 10px;
    width: 16px;
    height: 16px;
    color: #4D4D4D;
}

.Like_search .input-controler {
    background: #F6F6F6;
    border-radius: 5px;
    border: 1px solid #F6F6F6;
    height: 40px;

}

.Like_Heading_Section h3 {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 16px;
}

.Heading_Invite_Section h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 20px;
}

.Heading_Invite_Section span {
    color: #81C14B;
    font-weight: 500;
    font-size: 16px;
}

.SubHeading_Invite_Section h4 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 16px;
    padding-top: 10px;
}

.SubHeading_Invite_Section p {
    color: #81C14B;
    font-weight: 400;
    font-size: 16px;
    padding-top: 10px;
}

.Invite_Filter_btn {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 20px;
    justify-content: center;
}

.Uncontrolled_btn :hover {
    /* background-color: #81C14B !important; */
    color: #81C14B !important;
}

/* .Invite_Filter_btn :hover {
    color: #81C14B;
    background-color: #00A855 !important;
} */

.Invite_Filter_btn p {
    color: #4B4B4B;
    font-weight: 400;
    font-size: 14px;
}

.Btn_Typ .CheckBox_btn {
    color: #81C14B !important;
}

.modalBody {
    overflow-y: auto;
    scroll-behavior: smooth;
    max-height: 10% !important;
}

.Join_Card_Section {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.Join_Card_Section img {
    border-radius: 5px 5px 0px 0px;
    height: 150px
}

.Join_Card_Position_Section {
    position: absolute;
    top: 8px;
    right: 22px;
    /* border-radius: 80px; */
    /* background: rgba(246, 246, 246, 0.24); */
    /* width: 24px;
    height: 24px; */
}

/* STAR_pODcAST_ */
.PodCast_section {
    border-radius: 5px;
}

.TopPodcast_Section img {
    border-radius: 5px 5px 5px 5px;

}

.TopPodcat_Left_right {
    position: absolute;
    top: 13px;
    right: 1px;
    background: #F9A321;
    border-radius: 30px 0px 0px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 4px 0px 7px;
}

.TopPodcat_Left_right h4 {
    color: #000000;
    font-size: 14px;
    font-weight: 500;

}

.TopPodcat_Left_Side {
    position: absolute;
    top: 8px;
    left: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.TopPodcat_Left_Side p {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
}

.TopPodcat_bottom_left {
    position: absolute;
    bottom: 8px;
    left: 12px;
}

.TopPodcat_bottom_left h4 {
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.TopPodcast_bottom_right {
    position: absolute;
    bottom: 0px;
    right: 0px;

}

.PodCast_Title h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    font-size: 16px;


}

.PodCast_Title p {
    color: #81C14B;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}

.Title_Podcast h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    font-size: 16px;
}

.Musics_Card {
    background: #FFFFFF;
    border-radius: 5px;
    width: 260px;
    height: 305px;
    box-sizing: border-box;
    left: 44px;
    top: 37px;
}

.Musics_Card img {
    border-radius: 5px 5px 5px 5px;
}



.Media_name h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    font-size: 16px;
}

.Media_name h5 {
    color: #979797;
    font-weight: 400;
    font-size: 14px;
}

.Media_name p {
    color: #979797;
    font-weight: 400;
    font-size: 14px;
}

.Delete_Media_modal_section {
    background: #ff0000;
    /* margin-left: 0; */
    /* margin-right: 1rem; */
}

.Delete_Media_modal_section h4 {
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
}

.modalHeading_Section h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 20px;
}

.modalHeading_Section p {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 14px;
}

.Shotz_Cancle_btn {
    background: #EFEFEF;
    border-color: #C4C4C4;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    width: 125px;
    height: 41px;

}

.Shotz_Cancle_btn p {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 14px;
}

.Shotz_HideOk_btn {
    background: #81C14B;
    border-radius: 5px;
    width: 125px;
    height: 41px;
}

.Shotz_HideOk_btn p {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.media_option.show {
    -webkit-transform: translate(90px, 41px) !important;
            transform: translate(90px, 41px) !important;
    padding: 0px !important;
    width: auto !important;
}

/* END_pODcAST_ */
/* end_Recommendation_section */
/* .Event-box .Event-FirstSection .Event-SecndSection .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal {
    transform: translate(-136px, 24px) !important;
} */
/* .dropdown-menu .dropdown-menu-right .custom-dropdown .drop-menu-gal{
    transform: translate(-136px, 24px) !important;

} */


/* end_my_events_css */

.profile-search-blk {
    position: relative !important;
    width: 300px;


}

.profile-search-blk .search-svg {
    width: 17px;
    height: 17px;
    position: absolute;
    top: 12px;
    left: 10px;
    color: #4D4D4D;
}

.profile-search-blk .form-control {
    background: #F6F6F6;
    border-radius: 5px;
    padding: 8px 10px 8px 35px;
}

.Media_Preview .modal-content {
    background: transparent !important;

}

.media-body h4 {
    font-family: "Montserrat", sans-serif;
    color: #51595e;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 5px;
}

.PaginationCounts .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #81C14B !important;
    color: #FFFFFF !important;
}

.Profile-slide-box.card {
    margin: -4px -4px 25px !important;
}

.market-slide-box.card {
    margin: 0px 0px 25px !important;
}

.market-slide-box {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
    border-radius: 5px;
    /* border: 1px solid #C4C4C4; */
    position: relative;
}

.Profile-slide-box {
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
    border-radius: 5px;
    /* border: 1px solid #C4C4C4; */
    position: relative;
}

.Profile-slide-box .gallery-img-sublink {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
}

.market-slide-box .gallery-img-sublink {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
}

.market-slide-box .MarketPlace_Card_LeftPosition {
    position: absolute;
    top: 8px;
    left: 16px;
    display: flex;

}

.market-slide-box .MarketPlace_Card_BottomLeftPosition {
    position: absolute;
    top: 120px;
    left: 10px;

}


.Profile-slide-box .gallery-img-sublink .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal {
    -webkit-transform: translate(-136px, 24px) !important;
            transform: translate(-136px, 24px) !important;
}

.market-slide-box .gallery-img-sublink .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal {
    -webkit-transform: translate(-136px, 24px) !important;
            transform: translate(-136px, 24px) !important;
}

.Profile-slide-box .group-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
}

.market-slide-box .group-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
}

.MarketPlace-content {
    padding: 10px;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.recommendation.show {
    -webkit-transform: translate(-169px, 41px) !important;
            transform: translate(-169px, 41px) !important;
    padding: 0px;
    width: 177px !important;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Text.show {
    -webkit-transform: translate(-169px, 0px) !important;
            transform: translate(-169px, 0px) !important;
    padding: 0px;
}

/*Start_ABout_section_css */
.profile-about .about-content li .icon {
    background: rgba(126, 178, 98, 0.05);
    /* background: #0066FF; */
    border-radius: 5px;
    width: 35px;
    height: 35px;
    /* justify-content: center;
    margin-right: 15px;
    align-items: center;
    -webkit-box-pack: center; */
    position: relative;

}

.Icon_section {
    position: absolute;
    left: 7px;
    top: 6px;
}

.profile-about .about-content li .Profession_detail h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 16px;
}

.profile-about .about-content li .Education_details {
    width: calc(100% - 45px);
}

.profile-about .about-content li .Education_details h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 16px;
}

.profile-about .about-content li .Education_details h6 {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
}

.profile-about .about-content li .Education_details small {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 12px;
    text-transform: capitalize;
}

.Profession_Modal_section {
    display: flex !important;
    justify-content: space-between !important;
    padding: 1rem !important;
}

/* .taps-style {
    border-bottom: 3px solid #F6F6F6 !important;
} */

.taps-hover-style:hover {
    border-bottom: 3px solid #81C14B !important;
    border-width: 300px;
    align-content: center;
}

.taps-hover-style:active {
    border-bottom: 3px solid #81C14B !important;
    border-width: 300px;
    align-content: center;
}


.Profession_Modal_section h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

}

.Profession_Modal_section p {
    color: #81C14B;
    /* font-weight: 600; */
    font-size: 16px;
    cursor: pointer;
    line-height: 24px;

}

.Interest_Modal_section span {
    color: #81C14B;
    font-weight: 400;
    font-size: 16px;
}

.Interest_Modal_section h4 {
    color: #333333;
    font-weight: 400;
    font-size: 18px;
}


/* .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #81C14B !important;
} */
span.MuiRadio-root.MuiRadio-colorPrimary.MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.PrivateSwitchBase-root.Mui-checked.Radio_btn_check.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    color: #81C14B !important;
    margin-right: -10px !important;
}

/* .css-1a5icme.Mui-checked .Radio_btn_check {
    color: #81C14B !important;
    margin-right: -10px !important;
} */

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    margin-right: -10px !important;

}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #81C14B !important;
}

.Profession_Modal_Option {
    display: flex;
    justify-content: space-between;
    align-items: center;


}



.Profession_Modal_Option h4 {
    font-weight: 400;
    font-size: 16px;
    color: #4D4D4D;

}

/* .about-content.About_section {
    padding: 20px !important;
} */

.about-content.Modal_About_section {
    padding: 0px !important;

}

.card-title.About_section {
    padding: 15px !important;
}

.Basic_Info_modal {
    background: rgba(126, 178, 98, 0.2);
    border-radius: 5px;
    width: 35px;
    height: 35px;
    position: relative;
}

.hobbies_Info_modal {
    background: #81C14B;
    border-radius: 4px;
    width: 35px;
    height: 35px;
    position: relative;
}

.hobbies_Info_modal p {
    position: absolute;
    left: 7px;
    top: 7px;
}

.Info_Modal_img {
    position: absolute;
    left: 7px;
    top: 8px;

}

.Basic_Info h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 14px;
}

.Basic_Info h6 {
    color: #647589;
    /* text-transform: capitalize; */
    line-height: 1.4;
    font-size: 14px;
    margin-top: 5px;

}

.Basic_Info p {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 12px;
}

/* .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #81C14B !important;
} */
span.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.Mui-checked.Check_Input_section.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    color: #81C14B !important;
}

.span.MuiSlider-colorPrimary.MuiSlider-sizeMedium.MuiSlider-root.slider_section_Musics.css-exkjcx {
    color: #81C14B !important;
}

label.Bio_text {
    color: #4D4D4D !important;
    font-weight: 500 !important;
    font-size: 15px;
}

.form_control_section p {
    color: #81C14B;
    /* font-weight: 400;
    font-size: 16px; */
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.form_control_section_input_box {
    position: relative;
}

.input_box_section {
    position: absolute;
    right: 8px;
    top: 12px;
    font-weight: 400;
    font-size: 14px;
    color: #C4C4C4;

}

.Map_box_section {
    position: absolute;
    right: 8px;
    top: 13px;
}

.Add_box_section {
    position: absolute;
    right: 8px;
    top: 11px;
}

.PlusCircle_border {
    position: absolute;
    right: 12px;
    top: 11px;
    border: 1px solid rgba(87, 17, 17, 0.1);
}

.Input_cross_section {
    position: absolute;
    right: 8px;
    top: 12px;
    cursor: pointer;
}

.Verfiy_input_section {
    position: absolute;
    right: 35px;
    top: 9px;
    cursor: pointer;
}

.inputForm {
    padding-right: 20%;


}

.Eduction_inputForm {
    padding-right: 11%;
    border-right: 1px solid rgba(24, 97, 9, 0.1);

}

.Input_Verify_section {
    background: rgba(196, 196, 196, 0.1);
    border-radius: 8px;
    width: 345px;
    height: 273px;
    margin-left: 70px;
}

.Otp_section {
    width: 39px;
    height: 39px;
    border-radius: 5px;
}

.input_receive_section h5 {
    color: #5F5F5F;
    font-weight: 400;
    font-size: 13px;
}

.input_receive_section h4 {
    color: #000000;
    font-weight: 400;
    font-size: 14px;
}

.hobbies-blk li {
    display: inline-block;
    padding: 10px 20px;
    background: rgba(126, 178, 98, 0.2);
    border: 0.5px solid rgba(129, 193, 75, 0.2);
    border-radius: 4px;
    font-size: 12px;
    color: #4D4D4D;
    font-weight: 500;
    margin: 0 15px 15px 0;
    display: inline-flex;
    align-items: center;
}

.hobbies-blk li p {
    margin-right: 5px;
}

.Interestes-blk li {
    display: inline-block;
    padding: 10px 20px;
    background: #81C14B;
    border: 0.5px solid rgba(129, 193, 75, 0.2);
    border-radius: 5px;
    font-size: 12px;
    color: #FFFFFF;
    font-weight: 500;
    margin: 0 15px 15px 0;
    display: inline-flex;
    align-items: center;
}

.Interestes-blk li p {
    margin-right: 5px;

}

.sport_Img_section {
    color: #000000;
    background-color: #0066FF;
}

.Profession_Modal_Option img {
    object-fit: cover;
    border-radius: 4px;

}

.Input_Add_Profession {
    width: 80%;
}

.Recently__Card_Media {
    background: #FFFFFF;
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
}

.Recently__Card_buttom_section {
    text-align: center;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.center_btn_action.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    background-color: #81C14B !important;
}

span.MuiSlider-colorPrimary.MuiSlider-sizeMedium.MuiSlider-root.slider_section_Musics.css-187mznn-MuiSlider-root {

    color: #81C14B !important;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.PodCast_play_list.w-50.show {
    -webkit-transform: translate(185px, 512px) !important;
            transform: translate(185px, 512px) !important;
    padding: 0px;
}

.Recently_Player_List {
    display: flex;
    align-items: center;
    position: relative;
}

.Play_Btn_Section {
    position: absolute;
    left: 4%;
    top: 13%;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.25);

}

.Recently_Player_List .video_image {
    object-fit: cover;
    border-radius: 5px;
}

.Recently_Player_List_botton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    position: absolute;
    width: 150px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 5px;
    cursor: pointer;
    color: #81C14B;
    font-weight: 400;
    font-size: 14px;
}

.PodCast_Media_section {
    display: flex;
    position: relative;
}

.PodCast_Media_section .Podcast_media {
    object-fit: cover;
    border-radius: 5px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) !important;
}

img.img-fluid.Podcast_media {
    border-radius: 5px;
    object-fit: cover;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) !important;

}

.PodCast_Media_section {
    position: relative;
}

.Media_Mic {
    position: absolute;
    top: 52px;
    left: 48px;
}

.Media_Paid {
    position: absolute;
    bottom: 8px;
    left: 88px;
}

.Media_Paid img {
    border-radius: 5px 5px 5px 5px;
}

img.img-fluid.paid_media {
    border-radius: 5px;
}

.Podcast_Icon_section {
    position: relative;
    display: flex;
    width: 25px;
    height: 25px;
    background: #DDDDDD;
    border-radius: 20px;
}

.Icon_section {
    position: absolute;
    left: 8px;
    top: 8px;
}

.PodCast_button_section {
    background: #DDDDDD;
    border-radius: 20px;
    position: absolute;
    width: 25px;
    height: 25px;
}

.Podcast_Media_Card_Box {
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    position: relative;

}

.Podcast_Btn_section {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    width: 105px;
    height: 40px;
    color: #979797;
    font-weight: 400;
    font-size: 14px;

}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.podcast_option.show {
    -webkit-transform: translate(455px, 27px) !important;
            transform: translate(455px, 27px) !important;
    padding: 0px;
    width: auto !important;

}


.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.podcastDetails_Option.show {
    -webkit-transform: translate(512px, 41px) !important;
            transform: translate(512px, 41px) !important;
    padding: 0px;
    width: auto !important;
}

.Podcast_action_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #81C14B;
    border-radius: 5px;
    color: #81C14B;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
}

.Podcast_Explore_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px;
    cursor: pointer;
    width: 90px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #81C14B;
    border-radius: 5px;
    color: #81C14B;
    font-weight: 400;
    font-size: 14px;

}

.analitics-custom-blk {
    background: #F6F6F6;
    box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 10%);
    border-radius: 5px;
    padding: 15px;
    margin: 0 0 25px;

}

.podcast-analitics-blk li {
    display: flex;
    justify-content: space-between;
}

.podcast-analitics-blk li {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.podcast-analitics-blk li b {
    font-weight: 600;
    color: #2F81FC;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}

.Podcast_Details_box {
    border: 1px solid rgba(196, 196, 196, 0.5);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 23px 10px 10px;
}

img.img-fluid.Podcast_Details_media {
    object-fit: cover;
    border-radius: 5px;
}

.Podcast_Details_Mic {
    position: absolute;
    top: 42px;
    left: 54px;
}

.episode-img-blk {
    position: relative;
}

.afsdfdxbbrt {
    position: absolute;
    top: 8px;
    left: 8px;
    cursor: pointer;
}

.Follower-memberslist-block {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    padding: 5px;
    /* margin: -15px -9px 25px; */
    margin: 0 -9px 7px;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.Follower-memberslist-block img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border: 2px solid #FFFFFF;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    border-radius: 50%;
    margin-right: 10px;
}

.Like_Profile_user img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    margin-right: 10px;
}

.Follower-memberslist-block {
    display: block;
}

.Remove_Modal_section p {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

}

.Choose_Modal_section {
    display: flex;
    justify-content: space-around;
}

.Choose_Modal_section h4 {
    cursor: pointer;
}

img.img-fluid.Choose_Img.card-img {
    cursor: pointer !important;
}

.Select_Modal_Header {
    margin-left: 168px;

}

button.Option_going_btn.dropdown-item:hover {
    color: #FFFFFF !important;
    background-color: #81C14B;
}

.Going_Menu.dropdown-menu.show {
    display: block;
    width: 137%;
    z-index: 99;
    color: #808080;
}

.Completed_Poll {
    display: flex;
    align-items: center;
}

.Completed_Poll h5 {
    font-weight: 600;
    font-size: 20px;
    color: #808080;
    line-height: 30px;
}

.Completed_Poll span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    opacity: 0.6;
}

.Poll-about-cont ul li {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.Running_POll {
    display: flex;
    align-items: center;
}

.Running_POll h5 {
    font-weight: 600;
    font-size: 20px;
    color: #81C14B;
    line-height: 30px;
}

.Running_POll span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    opacity: 0.6;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Poll_option.show {
    width: auto;
    -webkit-transform: translate(409px, 39px) !important;
            transform: translate(409px, 39px) !important;
    padding: 0px !important;
}

.AboutModal_Main_section {
    border-bottom: 1px solid #F6F6F6;
    width: 100%;

}

.Modal_scroll_box {
    max-height: 450px !important;
    overflow-y: scroll !important;
}

/* #Scroll-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#Scroll-1::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

#Scroll-1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    
    background: rgba(129, 193, 75);

} */

span.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.Mui-checked.Check_Input_section.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    margin-right: -10px;
    color: #81C14B;
}

.Contact_Icon {
    background: rgba(126, 178, 98, 0.05);
    border-radius: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #81C14B !important;
    margin-right: -10px !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #81C14B !important;
    margin-right: -10px !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    margin-right: -10px !important;

}

.Media_Modal_send_Icon {
    background: #81C14B;
    border-radius: 50px;
    padding: 5px 3px 5px 7px;
    position: absolute;
    top: 7px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.Reply_Comment_section {
    padding: 34px;
    margin-top: -55px;

}

.my-0.py-0.rounded.shadow-lg.bg-body.rounded.dropdown-menu.show {
    width: 250% !important;
    display: block;
    border: none;
    border-radius: 5px;
}

.Media_filter_btn {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    border-radius: 5px;
    padding: 9px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4B4B4B;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.media_Filter.show {
    -webkit-transform: translate(765px, 68px) !important;
            transform: translate(765px, 68px) !important;
    padding: 0px !important;
    /* width: 25% !important; */
    width: auto;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Artical_Filter.show {
    -webkit-transform: translate(879px, 68px) !important;
            transform: translate(879px, 68px) !important;
    padding: 0px !important;
    /* width: 25% !important; */
    width: auto;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.shotz_Filter.show {
    -webkit-transform: translate(1072px, 67px) !important;
            transform: translate(1072px, 67px) !important;
    padding: 0px !important;
    /* width: 25% !important; */
    width: auto;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Biz_Filter {
    -webkit-transform: translate(1232px, 638px) !important;
            transform: translate(1232px, 638px) !important;
    padding: 0px !important;
    /* width: 25% !important; */
    width: auto;
}

.Slider_Filter {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    stroke-width: 3px;
}


@media only screen and (min-width: 2200px) {
    .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Biz_Filter.show {
        -webkit-transform: translate(1528px, 638px) !important;
                transform: translate(1528px, 638px) !important;
        padding: 0px !important;
        /* width: 25% !important; */
        width: auto;
    }
}

.dropdown-menu.dropdown-menu-right.custom-dropdowns.drop-menu-gal.BizCard_option.show {
    width: auto;
    padding: 0px !important;
    -webkit-transform: translate(-185px, 21px) !important;
            transform: translate(-185px, 21px) !important;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.InviteModal_filter.show {
    -webkit-transform: translate(-96px, 44px) !important;
            transform: translate(-96px, 44px) !important;
    padding: 0% !important;
}

button.Option_going_btn.dropdown-item {
    color: #808080 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
}

li.nav-item {
    margin-bottom: 1px !important;
}

.PodCast_Icon_Section {
    background: #DDDDDD;
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
    display: flex;


}

.Span_text {
    color: #808080;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.Podcast_media_blk {
    width: 120px;
    height: 120px;
    object-fit: cover;
    position: relative;
    border-radius: 5px;

}

.Profile_tab_more {
    background: rgba(129, 193, 75, 0.2);
    border-radius: 5px;
    padding: 8px 10px;
    grid-gap: 5px;
    gap: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

a.Podcast_link_heading {
    color: rgba(0, 0, 0, 0.8) !important;


}

a.Podcast_link_heading h3 {
    font-weight: 500 !important;
    font-size: 20px !important;
}

.Choose_Photo_section {
    border-radius: 8px 11px 8px 8px;
    position: relative;
}

.Choose_check_top_right {
    position: absolute;
    top: 8px;
    right: 16px;
}

/*End_ABout_section_css */

/* Add this CSS class to center the pagination */
.center-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Adjust the margin-top to control the spacing */
  }
 
  /* Define the border style for the hover effect */
  /* .post-card {
    transition: background-color 0.3s ease;
  } */
/*   
  .post-card:hover {
    border: 1px solid  lightgreen;
  } */

  .about-profile-box-blk{
    border-radius: 5px;
background: #FFF;
box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.10);
  }

  /* //for cross button */


  

.connection-profile-new {
    width: 60px !important;
    height: 60px !important;
}

.media-new {
    justify-content: center;
    align-items: center;
}

.media-body-new {
    justify-content: space-around;
    flex-direction: column;
}

.popover__message {
    text-align: center;
}

.conn-btn {
    border: none;
}

.mp-head-blk-new {
    align-items: center;
    padding-bottom: 2rem;
}

.mp-head-blk-new>h2 {
    font-size: 1.8rem;
    font-weight: 600;
}

.mp-releted-pro-blk-new {
    position: unset !important;
    align-items: center;
}

.gallery-img-sublink-new>.dropbtn-gal>svg {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    background: none;
    border: none;
}

.gallery-img-sublink-new {
    background: none;
    border: none;
}

/* card hover css start */
.connection-card {
    background: #FFFFFF !important;
    border: 1px solid #CCCCCC !important;
    border-radius: 10px !important;
    color: #666666 !important;
}

.popover___wrapper {
    position: relative;
    /* margin-top: 1rem; */
    display: inline-block;
}

.popover____content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50px;
    top: -300px;
    -webkit-transform: translate(0, 10px);
            transform: translate(0, 10px);
    /* background-color: #FFFFFF; */
    /* padding: 1.5rem; */
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: 300px;
}

.popover____content:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -20px;
    width: 300px;
    /* border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #FFFFFF transparent; */
    transition-duration: 0.3s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}

.popover___wrapper:hover .popover____content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(0, -20px);
            transform: translate(0, -20px);
    transition: all 0.8s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}


/* card hover css end */

.name-style {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1.2em !important;
    color: #222222 !important;
}

.user-name-style {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    color: #999999 !important;
}

.contries-scroll {
    max-height: 500px;
    scroll-behavior: smooth;
    /* overflow-y: scroll; */
}
/* =======================add css scroll==================== */
.contries-scroll {
    overflow: hidden;
}
.contries-scroll:hover {
    overflow-y: scroll;
}

.menu-hover-style:hover {
    background: #E5F0E0 !important;
    color: #4D4D4D !important;
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
}

.css-bf9wz-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    background-color: #81c14b !important;
}

.connections_PeopleThisGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr)) !important;
}

.css-13zgp8s.Mui-selected {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    color: rgb(255, 255, 255) !important;
    background-color: #81c14b !important;
}

.css-1a5icme.Mui-checked {
    color: #81c14b !important;
}

.connection-search {
    padding: 3px 10px 3px 15px !important;
    font-size: 1.1em;
    color: #666666;
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    background: #EEEEEE;
    border-radius: 5px;
    border: 'none';
    display: flex !important;
    align-items: center !important;
    outline: none;

}

.css-13zgp8s.Mui-selected:hover {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    background-color: #81c14b !important;
}

.professional-style {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1em !important;
    color: #AAAAAA !important;
}

.green-text-style {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 1em !important;
    color: #81C14B !important;
}

.css-55hfh7-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #81C14B !important;
}

.conention-tab-text {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
}

.dark-heading-style {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1.8em !important;
    color: #4D4D4D !important;
}

.view-more-style {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    color: #999999 !important;
}

.connection-sub-heading {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1.1rem !important;
    color: #666666 !important;
}

.connection-orange-text {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    color: #F1AB59 !important;
}

.connection-red-text {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    color: #F95252 !important;
}

.gray-text-dark {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1em !important;
    color: #666666 !important;
}

.Connection-dropdown {
    width: 250px !important;
    justify-content: center;
    align-items: flex-start;
    background: #FFFFFF;
    box-shadow: 0px 24px 40px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    color: #666666;
}

.connection-dropdown-menu {
    justify-content: center;
    align-items: flex-start;
    background: #FFFFFF;
    box-shadow: 0px 24px 40px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    color: #666666;
}

.connection-cutomize-dropdown-toggle {
    margin: 0px !important;
    padding: 0px !important;
    border: none !important;
    background-color: transparent !important;
    display: inline-flex !important;
    display: -webkit-inline-box !important;
}

.connection-blue-text {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12 !important;
    color: #2F80ED !important;
}

.connection-search-style {
    padding: 3px 10px 3px 15px !important;
    font-size: 1.1em;
    color: #666666;
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    width: 300px;
    background: #EEEEEE;
    border-radius: 5px;
    border: 'none';
    display: flex !important;
    align-items: center !important;
    outline: none;
}

.coonection-input-style {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
}

.list-grid-toggle {
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    grid-gap: 10px;
    gap: 10px;
}

.grid-toggle-customize-css {
    background-color: #FFFFFF !important;
    color: #81C14B !important;
}

.green-text-dark {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #81C14B;
}


.bg-light-dropdown-toggle {
    padding: 1px !important;
    background: transparent !important;
    border: none !important;
    outline: none !important;
    color: #4D4D4D !important;
}

.light-red-button {
    background: #FFE5E5;
    border-radius: 6px;
    color: #FF0000CC;
    border: none !important;
    outline: none !important;
}

.light-orrange-button {
    background: rgba(241, 171, 89, 0.1);
    border-radius: 6px;
    color: #F1AB59;
    border: none !important;
    outline: none !important;
}

.light-secondary-button {
    justify-content: center;
    align-items: center;
    background: #C4C4C4;
    border-radius: 6px;
    color: #FFFFFF;
    outline: none !important;
    border: none !important;
}

.light-green-button {
    background: rgba(129, 193, 75, 0.2);
    border-radius: 5px;
    border: none !important;
    color: #4d4d4d;
    outline: none;
}

.report-option-text {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #808080;
}

.modal-scroll {
    min-height: 450px;
    height: 450px;
    overflow-y: scroll;
    margin-bottom: 10px;
}

.modal_scrollconnections .modal-scroll {
    max-height: 350px;
    /* height: 350px; */
    overflow-y: scroll;
    margin-bottom: 10px;
    padding: 0px 5px;
}

.modal-scroll::-webkit-scrollbar {
    display: none;
}

.box-shadow-style {
    display: flex;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 0 20px;
    transition: box-shadow 1s !important;
}

.box-selected {
    display: flex;
    border-radius: 6px;
    border: 1px solid #81C14B !important;
    margin: 0 0 20px;
    background: rgba(129, 193, 75, 0.1);
    border-radius: 6px;
    position: relative;
    z-index: 1;
}

.box-selected .option-disable {
    position: relative;
    z-index: -1;
}

.box-shadow-style:hover {
    border: 1px solid #81C14B !important;
    background: rgba(129, 193, 75, 0.1) !important;
    border-radius: 6px;
}

.connection_bg {
    background-color: #FFFFFF !important;
}

.btn-transparent {
    display: flex;
    align-items: center;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal-new {
    -webkit-transform: translate(530px, 35px) !important;
            transform: translate(530px, 35px) !important;
    width: unset !important;
}

.green-text {
    color: #81C14B !important;
    font-weight: 500 !important;
    font-size: 1em !important;
}

.btn-connection-save-btn {
    background: #EEFBE4;
    border-radius: 5px;
}

.mp-releted-pro-blk-new-new {
    height: 64px;
    font-weight: 600;
}

.conn-menu-dropdown {
    -webkit-transform: translate(600px, 45px) !important;
            transform: translate(600px, 45px) !important;
}

.conn-menu-dropdown ul {
    display: flex;
    flex-direction: column;
}

.conn-menu-dropdown ul li a {
    background: unset;
    margin: 0;
    padding: 5px 15px;
}

.dropdown-content-new {
    visibility: hidden;
    opacity: 0;
    display: none;
    position: absolute;
    background: white;
    width: 200px;
}

.dropdown-content-new>a {
    background: unset !important;
}

.dropdown:hover .dropdown-content-new {
    visibility: visible;
    opacity: 1;
    z-index: 99;
    display: block;
}

.dropdown:hover .dropdown-content-new>a {
    color: black;
}

.cus-cur-pointer {
    cursor: pointer;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal-new-follower {
    -webkit-transform: translate(270px, 35px) !important;
            transform: translate(270px, 35px) !important;
    width: unset !important;
}

.sm-size-align-start {
    display: flex;
    margin-right: 19px !important;
    justify-content: end !important;
}

.rem-web-view {
    display: none !important;
}

/* nav css */

.nav-flex-container {
    display: flex !important;
    align-items: center;
    justify-content: space-between !important;
    flex-wrap: wrap;
    margin-left: 6px;
}
.nav-flex-containers {
    display: flex !important;
    align-items: center;
    justify-content: space-between !important;
    flex-wrap: wrap;
    margin-left: 1px;
}
.sub-nav-flex {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
}

.country-drop-menu {
    width: 400px !important;
}

.remove-margin {
    margin: 0rem 0.5rem;
}

/* .remove-btn-padding{
    padding: 0rem 0rem;
    margin: 0 0.5rem;
} */

/* Connection Card Hover css */


/* ----------------- Screen resize media Query ----------------- */

@media screen and (max-width: 992px) {
    .size-sm-mt-4 {
        margin-top: 10px !important;
        align-items: start !important;
    }

    .rem-mob-view {
        display: none !important;
    }

    .flex-mob-view {
        justify-content: space-between !important;
    }

    .rem-web-view {
        display: flex !important;
    }

    .sub-nav-flex {
        padding: 0.5rem 0rem;
    }
}

@media screen and (max-width: 912px) {
    .remove-margin {
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .add-margin {
        margin: 0rem 0.5rem;
    }
}

@media screen and (max-width: 576px) {
    .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal-new {
        -webkit-transform: translate(220px, 54px) !important;
                transform: translate(220px, 54px) !important
    }

    .connections_PeopleThisGrid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)) !important;
    }

    .connection-profile-new {
        width: 25px !important;
        height: 25px !important;
    }

    .rem-mob-view {
        display: none !important;
    }

    .size-xs-mt-4 {
        margin-top: 10px !important;
        align-items: start !important;
    }

    .sm-size-align-start {
        display: flex !important;
        justify-content: start !important;
        margin-left: 19px !important;
    }

    .rem-web-view {
        display: flex !important;
    }

    .country-drop-menu {
        width: 300px !important;
    }

}

@media screen and (min-width:439px) {
    .remove-btn-padding {
        padding: 0rem 0rem;
        margin-left: 0.5rem;
    }
}

@media screen and (max-width: 354px) {
    .add-btn-padding {
        margin-top: 0.5rem;
    }
}

@media screen and (max-width : 320px) {
    .rem-mob-view-small{
        display: none !important;
    }

    .margin-small{
        margin-top: 5px;
    }
}

.borderforImgpostPreview {
    border-top: 0px double #E6E3E1;
    border-left: 0px double #E6E3E1;
    border-right: 0px double #E6E3E1;
}

.rowflexforImgpostPreviews {
    border-top: 0px double #E6E3E1;
    border-left: 0px double #E6E3E1;
    border-bottom: 0px double #E6E3E1;
    padding: 0 0.9rem 0 0.9rem;
}

.rowflexforImgpostPreview {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
}

.throughtInputBox {
    background-color: transparent;
    /* margin: 55px; */
    margin-top: 55px;
    margin-bottom: 55px;
    text-align: center;
    border: none;
    font-size: 24px;
    font-weight: 500;
    width: 100%;
}
.throughtInputBox:focus {
    /* border: transparent; */
    outline-color: transparent !important;
}

.LeftquotationIconpostion {
    position: absolute;
}

.RightquotationIconpostion {
    position: absolute;
    bottom: 1%;
    left: 97%;
}

.react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon, .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
    stroke: #81C14B;
    color: #81C14B;
}

/* .gr-1 {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(#ffffff));
    background-image: linear-gradient(to top, #ffffff 0%, #ffffff 100%);
} */
.thought-input{
    border: 1px solid rgb(217 211 211) !important;
    width: 100% !important;
    height: 125px !important;
}
.thought-input::-webkit-input-placeholder {
 color: grey !important;
 font-size: 16px !important;
 font-weight: 600 !important;
}
.thought-input::placeholder {
 color: grey !important;
 font-size: 16px !important;
 font-weight: 600 !important;
}
.button-anchorThought{
    margin-right: 24px !important;
    margin-top: 10px;
    color: #bbbbbb !important;
    /* background-color:  rgb(73, 73, 73) !important; */
    border: 1px solid #bbbbbb !important;
    border-radius: 50px !important;
    padding: 0px 4px 0px 4px !important;
    font-size: 12px !important;
}
.thought-danger{
    color:#FF007A;
    font-weight: 600;
    margin-bottom: 4px !important;
    margin-left: -24px !important;
    padding-bottom: 8px !important;
}
.create-post .create-bg .thoughBgPost {
    display: none;
    width: 100%;
    height: 150px;
    border-radius: 5px;
    position: relative;
}
/* .postBtn{
    color: white !important;
    background-color: #ACDAD6 !important;
    font-size: 14px !important;
} */

.hashTagHead{
    font-size: 14;
    font-weight: 500;
    color: #4d4d4d;
    margin-top: 20px;
    margin-bottom: 30px !important;
}

.hashTagPlaceholder ::-webkit-input-placeholder{
    color: #000000 !important;
    font-size:medium;
}

.hashTagPlaceholder ::placeholder{
    color: #000000 !important;
    font-size:medium;
}
.detectLocation{
    color:#81C14B
}
.locationImg{
    height: 16px !important;
    margin-top: -8px !important;
}
.rightQuotes{
    margin-top: -44px !important;
}
.colorIcons{
    padding-top: 4px;
    margin-left: -6px !important;
    height: 22px;
}
.thtFlexContainer{
    display: flex; 
}


.hashTagPlaceholder {
    border: 1px solid rgb(148, 148, 148) !important;
    border-radius: 4px !important;
}

.hashTagHead {
    font-weight: 600;
}

.emojiSearch {
    width: 100% !important;
    border: 1px solid rgb(206, 206, 206);
    padding: 4px;
    margin-top: 6px !important;
    margin-bottom: 22px !important;
    border-radius: 4px !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    /* color: #03ca0d !important; */
    color: #81C14B !important;
    /* font-weight: 600; */
    font-weight: 400;
    font-size: 16px;
    /* background-color: #ffffff;
    /* border-color: #dee2e6 #dee2e6 #ffffff; * comment this line deepak and i am inform rashid sir and sourav sir  */
}

/* .locationHeads{
    display: flex;
} */


.locationHeadsRight {
    color: #81c14b;
    font-size: 12px !important;
    font-weight: 600;
}

.remove {
    color: #81c14b;
    font-size: 12px !important;
    font-weight: 600;
}

.locationImg {
    margin-top: 0px !important;
    color: #03ca0d;
    font-size: 12px !important;
    font-weight: 600;
}

.locationDetails {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.locationPosition {
    position: relative !important;
    top: 0% !important;
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
    left: 13px !important;
}

.toggle-sec .toggle-btn .knobs span {
    left: 4px;
    /* background-color: #81c14b !important; */
    z-index: 1;
}

.toggle-sec .toggle-btn .checkbox:checked+.knobs span {
    left: 42px;
    /* background-color: #bfbebe !important; */
    color: black !important;
}
.marginForSlider{
    margin-top: 0px !important;
}
.slider1{
    margin-top: 14px !important;
}
.marginForRange{
    color: rgb(77, 77, 77);
    font-weight: 500;
    font-size: 13px;
    margin-top: -22px !important;
}

  
 


.RightquotationIconAlert {
    position: absolute;
    left: 95%;
    bottom: 12%;
}

@media (max-width: 575px) {
    .RightquotationIconAlert {
        position: absolute;
        left: 87%;
        bottom: 12%;
    }

    .LeftIconforthrought {
        position: absolute;
        left: 5px;
        top: 9px;
    }

    .RightIconforthrought {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
}

.flexicon {
    position: absolute;
    left: 90%;
}

.progress-bar-warning {
    font-size: .857rem;
    background-color: #E5F0FF;
    color: black;
    border-radius: 35px;
}

.progressColor {
    background-color: #FFCC00;
}

.progress-bar {
    /* background-color: #FFCC00 !important; */
    border-radius: 35px !important;
    color: black !important;
    display: flex !important;
    justify-content: space-between;
    flex-direction: row !important;
}

.poll-radio-blk {
    padding: 1px 20px !important;
    background: #E5F0E0;
    border-radius: 35px;
    margin-top: 10px;
    display: flex;
}

.poll-radio-blk label {
    margin: 10px !important;
}

.radio_animated {
    position: relative;
    margin: 5px 1rem 0 0;
    cursor: pointer;
}

.radio_animated:before {
    transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75), -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    content: "";
    position: absolute;
    top: 5px;
    left: 0.125rem;
    z-index: 1;
    width: 0.55rem;
    height: 0.55rem;
    background: #81C14B;
    border-radius: 50%;
}

.radio_animated:after {
    content: "";
    position: absolute;
    top: 3px;
    /* left: 0.875rem; */
    margin-top: 9px;
    width: 1.05rem;
    height: 1.05rem;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}

.radio_animated:checked:before {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    /* margin-left: 16px; */
    margin-top: 4px;
}

.radio_animated:checked:after {
    border: 1px solid #81C14B;
}

.somecheccount {
    position: absolute;
    right: 22px;

}

.progress-bar-warning .input-radio {
    background-color: red;
}


#popover-basic.fade.show.popover.bs-popover-top {
    inset: auto auto -80px -24px !important;
    border: 1px solid #d2d3d0 !important;
    position: relative;
    overflow: hidden;
}

.popOverHeaderMainPost {
    background-color: #f8faf6;
    height: auto;

}

.main-post-mb-0 {
    margin-bottom: -2px !important;
    margin-top: -2px !important;
    color: rgb(99, 99, 99) !important;
}

.alert-border {
    position: absolute;
    display: block;
    top: -50%;
    left: -50%;
    z-index: -1;
    display: block;
    height: 200%;
    width: 200%;
    /* transform: rotate(-20deg); */
    overflow: hidden;
    background: linear-gradient(to right, #f7f9f5 20%, #f7f9f5 40%, #e0f1cf 50%, #d4e4c5 55%, #f7f9f5 70%, #f7f9f5 100%);
    background-size: 200% auto;
    -webkit-animation: shine 3s linear infinite;
            animation: shine 3s linear infinite;
}

div.post-drop-down {
    width: 204px !important;
    font-size: 12px;
    -webkit-transform: translate(-180px, 27px) !important;
            transform: translate(-180px, 27px) !important;
}

.ih-16 {
    height: 14px !important;
}

.setting-dropdown ul {
    padding: 0px !important;
}

.share-via-alert {
    margin-left: -110px
}

/*Begin shimmer code*/

@-webkit-keyframes shine {
    to {
        background-position: 200% center;
    }
}

@keyframes shine {
    to {
        background-position: 200% center;
    }
}
@media (min-width: 280px) and (max-width:715px){
    .Mobile-text{
        font-size: 12px !important;
        margin-left: 14px
    }
    .Mobile-text-btn{
        color: white;
        font-size: 13px;
        font-weight: 600;
        position: relative;
        right: -6px!important;
    }
    .Mobile-text-btn-section{
        position: relative;
        right: 0px!important;
    }
}



.swal-overlay {
    background-color: rgba(0,0,0,0.0) !important;
    box-shadow: 10px 20px 36 #888888 !important;
    
}

.swal-overlay--show-modal .swal-modal {
    box-shadow: 5px 10px 40px 12px #dce2dc !important;
    border: 1px solid #caccca !important;
    /* padding-bottom: 24px !important; */
}
/* .swal-title {
    color: rgba(0,0,0,.65);
    font-weight: 600;
    text-transform: none;
    position: relative;
    display: block;
    padding: 13px 16px !important;
    font-size: 27px;
    line-height: normal;
    text-align: center;
    margin-bottom: 0;
} */
.Events_TimeLine_Section {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.2);
  border-radius: 5px;
}
.bizpagefordropdownmenus {
  -webkit-transform: translate3d(-126px, 27px, 10px) !important;
          transform: translate3d(-126px, 27px, 10px) !important;
  font-size: 12px;
}
.create-Grp-section {
  text-align: center;
  margin: 20px 0 0;
  text-align: right;
}
.send-btn-text p {
  color: #4d4d4d;
  font-size: 15px;
  font-weight: 400;
}
.UncontrolledButtonDropdowntext {
  z-index: 0 !important;
}
.Event_Remember_section h4 {
  color: #63bf4c;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
}
/* .slick-slide .slick-active .slick-current{
width: 100%;
} */
.Event_Remember_section p {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.Group_Top_Cover_Buttom_LeftSection {
  position: absolute;
  /* bottom: 70px; */
  top: 7rem;
  left: 26px;
  font-size: 18px;
}

.Events_Remember_Heading h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 20px;
}

.Events_Remember_Heading p {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.Events_Top_section {
  position: relative;
  /* margin: -12px !important; */
}

.Events_scheduling_Card {
  position: absolute;
  bottom: 33px;
  left: 22px;
  background: rgba(22, 195, 30, 0.2);
}

.Group_Top_Cover_Buttom_CenterSection {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
}

.event-cover {
  height: 350px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.2);
  position: relative;
}

.event-cover .event-box {
  position: absolute;
  left: 40px;
  top: 50%;
  /* -webkit-transform: translateY(-50%); */
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  /* padding: 15px; */
  width: 300px;
  height: 200px;
}

.event-box .event-content {
  text-align: center;
}

.event-box {
  /* background: rgba(22, 195, 30, 0.2); */
  border-radius: 5px;
}

.event-conten p {
  color: #ffffff;
  font-weight: 500;
  font-size: 21px;
}

.event_Card_section {
  background: rgba(22, 195, 30, 0.2) !important;
  border-radius: 5px !important;
  width: 300px;
  /* height: 200px; */
  box-sizing: border-box;
}
@media (max-width: 500px) {
  .event_Card_section {
    background: rgba(
      22,
      195,
      30,
      0
    ) !important; /* Set transparent background for smaller screens */
  }
}
/* @media (min-width: 1280px) and (max-height: 800px) {
  .event_Card_section {
    background: rgba(
      22,
      195,
      30,
      0
    ) !important; 
  }
} */

.Events_Card_Top_section h4 {
  color: #ffffff;
  font-weight: 500;
  font-size: 21px;
}

.Events_Card_Top_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event_Location_section h4 {
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.Event_Bottom_section p {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.Boday_section {
  /* display: flex;
    justify-self: stretch; */
}

.events-slide-box {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
  border-radius: 5px;
  /* border: 1px solid #C4C4C4; */
  position: relative;
}

.events-slide-box .event_Tag_section {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 2;
}

.event_Tag_section {
  background: #ff9922;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0px 0px 20px;
  padding: 5px 15px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

.event_Body_Box {
  position: relative;
}

.event_Body_Box p {
  background: #f6f6f6;
  border-radius: 80px;
  width: 30px;
  height: 30px;
  position: absolute;
  padding: 5px;
  top: 8px;
  right: 9px;
  cursor: pointer;
}

.event_Body_Box span {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.event_Body_Box small {
  color: #81c14b;
  font-weight: 400;
  font-size: 12px;
}

.event_Body_Box h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

.fdsgfs span {
  cursor: pointer;
}

/* .heading-podcast-blk p {
    color: #0066FF;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
} */

.events-custom-block {
  margin: 0 -5px 25px !important;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Events-dropdown.show {
  -webkit-transform: translate(-161px, 23px) !important;
          transform: translate(-161px, 23px) !important;
  padding: 0px !important;
}

.EventsDetail_tab_Section {
  margin: 1px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
  border-radius: 5px;
}

/* start_events_Top_banner_section  */
.Events_modal_header_section {
  background: #f6f6f6;
}

.Events_modal_header_section h2 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.Event_location_search-svg {
  position: absolute;
  left: 8px;
  top: 11px;
}

.Event_inputForm {
  padding-left: 27px;
  padding-right: 20px;
}

/* end_events_Top_banner_section  */

/* Start_events_Upcoming_section */
.Filter_Modal_section {
  display: flex !important;
  justify-content: space-between !important;
}

.Filter_Modal_section h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #4d4d4d;
}

.Filter_Modal_section p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #81c14b;
}

.Events_Main_section {
  border-bottom: 1px solid #f6f6f6;
  width: 100%;
}

.Events_Filter_Modal_Option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Events_Filter_Modal_Option h4 {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.Events_srch_block {
  /* width: 600px; */
  /* max-width: 100%; */
}

.EventsCustom-card-head {
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* .Events_srch_block .input-Events_search-blk {
  width: 200px;
} */

.input-Events_search-blk {
  position: relative;
}

.input-Events_search-blk .search-svg {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 12px;
  left: 10px;
  color: #4d4d4d;
}

.Events_Invite_btn {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.search-block-podcast .input-Events_search-blk {
  margin: 3px !important;
}

.search-block-podcast .input-Events_search-blk {
  margin: 0 15px 0 0;
}

.Events_srch_block {
  /* width: 600px;
    max-width: 100%; */
}

.Events_srch_block .input-Events_search-blk {
  margin: 1px 15px 0 0;
}

.input-Events_search-blk {
  position: relative;
  margin-bottom: 15px;
}

.input-Events_search-blk .search-svg {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 12px;
  left: 10px;
  color: #4d4d4d;
  cursor: pointer;
}

.input-Events_search-blk .form-control {
  background: #f6f6f6;
  border-radius: 5px;
  padding: 8px 10px 8px 35px;
}

.group-search-assign-filter .Events_srch_block {
  width: auto;
}

/* end_events_Upcoming_section */

/* start_ Hosted_tab_section */
.MyEvents_box_section {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  /* overflow: hidden; */
  position: relative;
}

.MyEvents_box_section .MyEvent_tAG {
  position: absolute;
  right: 0px;
  top: 10px;
  /* z-index: 2; */
}

.MyEvents_box_section .MyEvent-img {
  object-fit: cover;
  height: 180px;
  width: 100%;
  border-radius: 5px 5px 0px 0px;
}

.MyEvents_box_slider_section {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 10%);
  /* overflow: hidden; */
  position: relative;
}

.MyEvents_box_slider_section .MyEvent_tAG {
  position: absolute;
  right: 0px;
  top: 10px;
  /* z-index: 2; */
}

.MyEvents_box_slider_section .MyEvent-img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border-radius: 5px 5px 0px 0px;
}

.MyEvent_BodySection {
  padding: 1rem 1rem;
  flex: 1 1 auto;
  -webkit-box-flex: 1;
  /* padding: 8px; */
}

.MyEvent_BodySection h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.MyEvent_First_BodySection {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.MyEvent_First_BodySection small {
  color: #81c14b;
  font-weight: 600;
  font-size: 12px;
}

.MyEvents_more_btn {
  background: #f6f6f6;
  border-radius: 80px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.MyEvent-bottom-Bodysection p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #808080;
  padding-top: 10px;
}

.MyEvents_Btn_Types {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 41px;
  grid-gap: 10px;
  gap: 10px;
  background: #81c14b;
  border-radius: 5px;
  cursor: pointer;
}

.MyEvents_Btn_Types p {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}

.MyEvents_Invitaion_Btn_types {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 41px;
  grid-gap: 10px;
  gap: 10px;
  background: #c4c4c4;
  border-radius: 5px;
  cursor: pointer;
}

/* .MyEvents_Invitaion_Btn_types p {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
} */
.border-select {
  border: 1px solid gray;
}

a.my_events_Navlink.nav-link.active {
  color: #ffffff !important;
  background-color: #81c14b !important;
  padding: 10px !important;
}

a.my_events_Navlink.nav-link {
  padding: 10px !important;
}

.My_eventsMember_section h4 {
  line-height: 30px;
}

.My-Member_Header_section {
  background: #f6f6f6;
}

.My-Member_Header_section h2 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 20px;
}

.MyEvents-memberslist-block {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 5px;
  margin: 0 -20px 7px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.MyEvents-memberslist-block img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border: 2px solid #ffffff;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  border-radius: 50%;
  margin-right: 10px;
}

.MyEvents-memberslist-block {
  display: block;
}

/* End_ Hosted_tab_section */

/*-------start_ EventsCategories ----------*/
.EventsCategories_calender {
  background: rgba(129, 193, 75, 0.1);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.Trending_Heading_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
}

.EventsCategories_All_events h5 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

li.leftSection:hover {
  cursor: pointer;
  /* color: #81C14B !important; */
}

li.Events_categories_leftSection {
  background: rgba(129, 193, 75, 0.2);
  cursor: pointer;
  color: #81c14b !important;
  border-right: 5px solid #81c14b;
}

.Categories_Left_block {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
  border-radius: 5px;
}

.Categories_LeftScroll_block {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
  border-radius: 5px;
  height: 810px;
  overflow-y: scroll !important;
}
.Categories_LeftScroll_blocks {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
  border-radius: 5px;
  height: 324px;

  overflow-y: scroll !important;
}
.Total_count_Events_Categories h5 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
}

.Categories_LeftPannel_slider {
  height: -webkit-max-content;
  height: max-content;
  border-radius: 5px;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  margin-bottom: 30px;
  overflow-y: hidden;
}
.Categories_LeftPannel_sliders {
  margin-left: 6px !important;
}

#Categories-Scroll-1::-webkit-scrollbar {
  width: 5px;
}

#Categories-Scroll-1::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#Categories-Scroll-1::-webkit-scrollbar-thumb {
  background: rgba(129, 193, 75, 0.2);
}

#Categories-Scroll-1::-webkit-scrollbar-thumb:hover {
  background: #81c14b;
}

.Categories_subHeading_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Categories_subHeading_section h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
}

.Categories_subHeading_section p {
  color: #0066ff;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

.ForWard_Img_categories {
  position: relative;
}

.right_imge {
  position: absolute;
  left: 51px;
  bottom: 51px;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.MyeventsCategories_Option.show {
  width: auto;
  /* -webkit-transform: translate(49px, 10px) !important; */
  /* transform: translate(49px, 40px) !important; */
  -webkit-transform: translate(117px, 202px) !important;
          transform: translate(117px, 202px) !important;
  padding: 0 !important;
}

.Categories_iNTERESTED {
  text-transform: capitalize;
  font-size: calc(11px + (12 - 11) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
}

.events-cover.profile-cover-new {
  border-radius: 5px !important;
  position: relative !important;
  box-shadow: none !important;
}

.btn-EventUpload {
  color: #ffffff;
  transition: all 0.5s ease;
}

.css-bf9wz-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  background-color: #81c14b !important;
}

.css-13zgp8s.Mui-selected {
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  color: rgb(255, 255, 255) !important;
  background-color: #81c14b !important;
}

.Events_list_heading p {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
}

/*-------end_ EventsCategories ----------*/

/*-------------start_Events_listView_section --------------*/
.Events_ListView_section {
  background: #ffffff;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.events_Invitaion_Card_section {
  background: #f6f6f6;
  border-radius: 5px;
  padding: 10px 15px;
}

.Invitation_Img_section {
  border-radius: 5px;
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.Invitaion_btn_types {
}

.Events_PeopleThisGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.Invitaion_Card_section {
  display: flex;
  align-items: flex-start;
}

.Invitaion_Card_section h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}

.Invitaion_Card_section p {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.Biz-heading_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Biz-heading_section h2 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

/*-------------end_Events_listView_section --------------*/

@media (min-width: 360px) and (max-height: 740px) {
  .avt-img-section {
    position: absolute;
    left: -1px !important;
    width: 28px !important;
    top: -7px !important;
  }
  .mobile-text-edit {
    position: absolute;
    right: -19rem;
  }
  .Events_Cards_Top_sections {
    position: absolute;
    left: 10rem;
    height: 42px;
    bottom: 62px;
  }
  .profile-date {
    position: absolute;
    top: 2px;
    left: 37px;
  }
  .rem-mob-view-texts {
    display: none;
  }
  .EventsDetail_tab_Sections {
    height: 62px;
  }
  .btn-add-text {
    margin-top: -21px;
  }
}
@media (min-width: 600px) and (max-width: 712px) {
  .Events_srch_block {
    position: relative;
    right: 30px;
  }
  .EventsDetail_tab_Sections {
    height: 58px;
  }
}
@media (min-width: 280px) and (max-height: 500px) {
  .web-texts {
    display: none;
  }
  .web-text {
    position: relative;
    font-size: 10px;
    margin-top: 32px;
    text-wrap: nowrap;
    margin-left: 5px;
  }
  .map-web-text {
    position: absolute;
    bottom: -1px;
    margin-left: -5px;
    width: 15px;
  }
  .date-second {
    font-size: 9px;
  }
}

@media (max-width: 412px) {
  .web-texts {
    display: none;
  }
  .web-text {
    position: relative;
    font-size: 10px;
    margin-top: 32px;
    text-wrap: nowrap;
    margin-left: 5px;
  }
  .map-web-text {
    position: absolute;
    bottom: -1px;
    margin-left: -5px;
    width: 15px;
  }

  .date-second {
    font-size: 9px;
  }
  /* .BizBTN_sections {
    font-size: 10px;
  } */
}

@media (max-width: 430px) {
  /* .BizBTN_sections {
    font-size: 10px;
  }
  .star-img {
    width: 13px;
  } */
}
@media (max-width: 480px) {
  .web-texts {
    display: none;
  }
  .web-text {
    position: relative;
    font-size: 10px;
    margin-top: 29px;
    text-wrap: nowrap;
    margin-left: 5px;
  }
  .map-web-text {
    position: absolute;
    bottom: -1px;
    margin-left: -5px;
    width: 15px;
  }
  .date-second {
    font-size: 9px;
  }
  .EventsDetail_tab_Sections {
    height: 58px;
  }
}
@media only screen and (max-width: 384px) {
  .date-second {
    font-size: 9px;
  }
}

@media only screen and (max-width: 384px) {
  .date-second {
    font-size: 9px;
  }
}
@media (min-width: 800px) {
  .vghfthftgh {
    /* width: 150px; */
  }
}
@media (min-width: 375px) and (max-height: 667px) {
  /* .BizBTN_sections {
    padding-left: 0px;
  } */
  .avt-img-section {
    position: absolute;
    left: -15px !important;
    width: 28px !important;
    top: -7px !important;
  }
  .avt-img-sections {
    position: absolute !important;
    left: 5px !important;
    width: 28px !important;
    top: -7px !important;
  }
}
@media (min-width: 375px) and (max-height: 812px) {
  /* .BizBTN_sections {
    padding-left: 0px;
  } */
  .avt-img-section {
    position: absolute;
    left: -9px !important;
    width: 28px !important;
    top: -7px !important;
  }
  .avt-img-sections {
    position: absolute !important;
    left: 5px !important;
    width: 28px !important;
    top: -7px !important;
  }
}
@media (min-width: 430px) and (max-height: 932px) {
  .input-Events_search-blks {
    width: 175px;
    position: absolute;
    left: 59%;
    margin-top: -74px;
  }
}

/* @media (max-width: 400px) {
  .event-card-text {
    margin-left: -11px !important;
    font-size: 10px !important;
  }
} */
@media (min-width: 384px) {
  .cal-text {
    position: absolute;
    top: -5rem;
  }
}

@media (min-width: 600px) {
  .cal-text {
    margin-top: 39px;
  }
  /* .mobile-text-edit {
    position: absolute;
    right: -19rem;
  }
  .Events_Cards_Top_sections {
    position: absolute;
    left: 20rem;
    height: 42px;
    bottom: 62px;
  }
  .profile-date{
    position: absolute;
    top: 2px;
    left: 37px;
  } */
}
@media (max-width: 390px) {
  .BizPage-buttons-blk {
    font-size: 10px !important;
  }
  /* .star-img {
    width: 13px;
  }

  .BizBTN_sections {
    font-size: 10px;
  } */
}
@media (min-width: 768px) and (max-height: 1024px) {
  .left_bar {
    /* width: 150px; */
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: -18px;
  }
  /* .interest-text {
    display: none !important;
  } */
}
@media (max-width: 1024px) {
  .not-interested-button {
    max-width: 83px;
  }
  .interest-text {
    margin-left: -13px;
  }
}

@media (min-width: 820px) and (max-height: 1180px) {
  .left_bar {
    /* width: 150px; */
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: -18px;
  }
  /* .interest-text {
    display: none !important;
  } */
}
@media (min-width: 1024px) and (max-height: 1024px) {
  .left_bar {
    /* width: 150px; */
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: -18px;
  }
  /* .interest-text {
    display: none !important;
  } */
  .interest-texts {
    display: none !important;
  }
  /* .star-img {
    width: 13px;
  } */
}
@media (min-width: 1000px) and (max-height: 1920px) {
  .event-hidden {
    display: none !important;
  }
}
@media (min-width: 640px) and (max-height: 360px) {
  .search-blk {
    margin-right: 55px !important;
  }
  .event_Card_section {
    position: absolute;
    top: -101px;
    height: 153px;
  }
  .input-Events_search-blk {
    width: 156px;
  }
  .Event_Bottom_section {
    display: none;
  }
  .event_Card_section {
    background: rgba(22, 195, 30, 0) !important;
  }
}

@media (max-width: 820px) {
  .rem-mob-view-text {
    display: none;
  }
  .create-btn-text {
    margin-top: -2rem;
  }
}
@media (max-width: 320px) {
  .group-search-assign-filter {
    margin-right: 19px;
    /* margin-top: -5rem; */
  }
  .EventsDetail_tab_Sections {
    height: 48px;
  }
  .Events_srch_block .input-Events_search-blk {
    margin: 0px 0px 0 0;
  }
  .avt-img-section {
    position: absolute;
    left: -1px !important;
    width: 28px !important;
    top: -7px !important;
  }
}

@media (min-width: 1366px) and (max-height: 768px) {
  .input-card-event-text {
    /* margin-left: 7%; */
  }
}

/* @media (min-width: 800px) {
  .event_drop {
    display: none;
  }
} */

@media (max-width: 712px) {
  .input-card-event-text {
    position: absolute;
    left: -25px;
    margin-top: -7px;
  }
}

@media (max-width: 800px) {
  .mobile-slider {
    width: 700px;
  }
  .nav-item {
    display: grid;
    /* grid-template-columns: repeat(2, 1fr); */
    grid-gap: 0.2rem;
    grid-gap: 0.2rem;
    gap: 0.2rem;
  }
}
@media (max-width: 820px) {
  .mobile-slider {
    width: 700px;
  }
  .nav-item {
    display: grid;
    /* grid-template-columns: repeat(2, 1fr); */
    grid-gap: 0.2rem;
    grid-gap: 0.2rem;
    gap: 0.2rem;
  }
}
@media (max-width: 950px) {
  .mobile-slider {
    width: 700px;
  }
  .nav-item {
    display: grid;
    /* grid-template-columns: repeat(2, 1fr); */
    grid-gap: 0.2rem;
    grid-gap: 0.2rem;
    gap: 0.2rem;
  }
}

@media (max-width: 600px) {
  .event_Card_section {
    position: absolute;
    top: -101px;
    height: 153px;
  }
  .input-card-event-text {
    position: absolute;
    left: -25px;
    margin-top: -7px;
  }
  /* .input-search-text {
    width: 172px;
  } */
}
/* Common styles for both groups */
.event-date-time-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Responsive styles for smaller screens */
@media (min-width: 320px) and (max-width: 640px) {
  .event-date-time-container {
    flex-direction: column !important;
    align-items: stretch !important;
  }
  .two-text {
    display: flex;
    justify-content: center;
    margin-top: -16px;
  }
  .event-date-time-group {
    margin-bottom: 10px; /* Adjust spacing between groups */
  }
}
/* CSS code */
@media (max-width: 560px) {
  .hide-on-search {
    display: none !important;
  }
}
@media (max-width: 320px) {
  .star-img {
    margin-left: -4px;
  }
  .btn-add-text {
    margin-top: -33px;
    margin-left: -9px;
  }
  .MyEvents_box_section {
    margin-right: -5px !important;
  }
}
@media (max-width: 400px) {
  .btn-add-text {
    margin-top: -26px!important;
  }
}

.new-create-grp-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grp-create-btn {
  width: 100% !important;
}

.grp-create-p {
  text-align: center;
}

.new-create-grp-img {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.new-create-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grp-create-bg {
  width: 350px;
  margin: auto;
}

.grp-privacy {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.grp-privacy-h {
  font-weight: 600;
}

.grp-privacy-p {
  color: #647589;
}

.biz-user-pro-blk-new h5 {
  font-size: 16px;
  color: #262626;
  margin: 15px 0 0;
  font-weight: 600;
}

.grp-preview {
  border-bottom: 1px solid #0000001f;
  padding: 0.5rem 0rem;
}

.GropMenu_Tab_Section {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.2);
  border-radius: 5px;
}

.GroupDetail_tab_Section {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
  border-radius: 5px;
}

.Member_Super_Admin_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 15.787px;
}

.Member_Super_Admin_section .Super_profile {
  border-radius: 4.93342px;
  border: 0.986685px solid #f0f0f0;
  display: flex;
  align-items: center;
}

.profile_super_Member h5 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 15.787px;
}

.profile_super_Member p {
  color: #808080;
  font-weight: 400;
  font-size: 13.8136px;
}

.Super_profile h5 {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  left: 120.54px;
}

.Admin_Profile_section {
  background: #ffffff;
  border: 0.986685px solid #f0f0f0;
  border-radius: 4.93342px;
}

.Admin_Profile_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 15.787px;
}

.Admin_Profile_section p {
  color: #808080;
  font-weight: 400;
  font-size: 13.8136px;
}

.Admin_Profile_section small {
  color: #808080;
  font-weight: 400;
  font-size: 13.8136px;
}

.Admin_Profile_section .Admin_Profile_Detail {
  position: absolute;
  left: 100.54px;
}

.Activity_Profile_section {
  background: #ffffff;
  border: 0.986685px solid #f0f0f0;
  border-radius: 4.93342px;
  box-sizing: border-box;
}

.Activity_Profile_section .Activity_Profile_Detail {
  position: absolute;
  left: 82.54px;
}

.admin_Title_Name h4 {
  font-weight: 500;
  font-size: 15.787px;
  color: #4d4d4d;
}

.About_Section {
  background: #ffffff;
  box-shadow: 0px 0px 17.8888px rgba(126, 178, 98, 0.1);
  border-radius: 8.94442px;
}

.About-card-block {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
  border-radius: 5px;
  margin-bottom: 20px;
}

.About_Img-Section {
  background: #f6f6f6;
  border-radius: 40px;
  padding: 10px;
}

.About_edit_section {
  background: #f6f6f6;
  border-radius: 40px;
  padding: 10px;
}

.About_Main_section {
  height: 76px;
}

.About_Main_section .about_sub_section {
  position: absolute;
  left: 90px;
}

.about_sub_section h5 {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 20px;
}

.about_sub_section p {
  color: #b5b5b5;
  font-size: 18px;
  font-weight: 400;
}

.custom-card-head h4 {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 32px;
}

.About_Gallery_Section {
  background: #ffffff;
  box-shadow: 0px 0px 7.38296px rgba(126, 178, 98, 0.1);
  border-radius: 3.69148px;
  margin-bottom: 20px;
}

.Img_Card {
  width: 100%;
}

.imghight {
  height: 180px;
}

.top_Grllery_Section {
  margin-top: 36px;
}

.Suggestion_box_Section {
  border-radius: 5.12796px;
}

.Suggestion_box_Section img {
  border-radius: 5px 5px 0px 0px;
}

.join_btn {
  background: #81c14b;
  border-radius: 5.12796px;
  width: 266.65px;
  height: 42.51px;
}

.join_btn p {
  color: #ffffff;
  font-weight: 500;
  font-size: 14.3583px;
}

.Suggestion_Heading h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18.4607px;
}

.Suggestion_Heading span {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 14.3583px;
}

.Suggestion_Heading p {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.Trending_Heading_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
}

.Trending_Heading_section p {
  color: #81c14b;
  font-weight: 500;
  font-size: 12.9724px;
  cursor: pointer;
}

.trending_section .Tranding_data {
  position: absolute;
  left: 90px;
}

.trending_section {
  height: 70px;
}

.Tranding_data h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
}

.Tranding_data p {
  color: #808080;
  font-weight: 400;
  font-size: 16px;
}

.biz-about-cont span {
  color: #81c14b;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 5px;
}

.Setting_Data_Section {
  box-shadow: 0px 0px 10px rgba(129, 193, 75, 0.2);
  border-radius: 5px;
}

.setting_section ul li {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.setting_section span {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 18px;
}

.setting-card-head {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.setting-card-head h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 18px;
}

.setting-card-head p {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
}

.Setting_Section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.Setting_Section p {
  color: #81c14b;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.Setting_Section h5 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

.Setting_Section small {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

/* input box  */
.GroupInfo_Input {
  border-radius: 5px;
  width: 300px;
  height: 41px;
}

.Create_GroupInfo_Input {
  border-radius: 5px;
  /* width: 100%; */
}

.GroupInfo_Input.form-control:focus {
  border: 1px solid #81c14b !important;
}

.Create_GroupInfo_Input.form-control:focus {
  border: 1px solid #81c14b !important;
}

input[type="Group"] {
  /* width: 300px; */
  box-sizing: border-box;
  transition: 0.5s;
  /* height: 41px; */
  border-radius: 5px;
}

input[type="Report"] {
  width: 100%;
  transition: 0.5s;
  height: 41px;
  border: none;
  background: #f6f6f6;
  border-radius: 3.23343px;
}

input[type="file"] {
  width: 300px;
  box-sizing: border-box;
  transition: 0.5s;
  height: 41px;
  border-radius: 5px;
}

input[type="textarea"] {
  border-radius: 5px;
  box-sizing: border-box;
  transition: 0.5s;
  height: 141px;
}

input[type="Group"]:focus {
  border: 1px solid #81c14b;
}

input[type="Creategroup"]:focus {
  border: 1px solid #81c14b;
}

.UploadBtn {
  color: #808080;
}

::-webkit-input-placeholder {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 14px;
}

::placeholder {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 14px;
}

.Check_Label_Section p {
  color: #808080;
  font-weight: 400;
  font-size: 12px;
  padding-top: 5px;
}

.Check_Label_Section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

.Private_Label_Section p {
  color: #808080;
  font-weight: 400;
  font-size: 12px;
  padding-top: 5px;
}

.Private_Label_Section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

.GroupName_Label_Section p {
  color: #808080;
  font-weight: 400;
  font-size: 12px;
}

.GroupName_Label_Section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
}

.public_Check.form-check-input:checked {
  background-color: #81c14b !important;
  border-color: #81c14b !important;
}

.Private_Check.form-check-input:checked {
  background-color: #81c14b !important;
  border-color: #81c14b !important;
}

.Check.form-check-input:checked {
  background-color: #81c14b !important;
  border-color: #81c14b !important;
}

.Assign_Check.form-check-input:checked {
  background-color: #81c14b !important;
  border-color: #81c14b !important;
}

.public_Check.form-check-input {
  position: absolute !important;
  margin-top: 1px !important;
  margin-left: 1px !important;
}

.Check_Label_Section {
  margin-left: 40px;
}

.Private_Check.form-check-input {
  position: absolute !important;
  margin-top: 1px !important;
  margin-left: -19px !important;
}

.Check.form-check-input {
  position: absolute !important;
  margin-top: -3px !important;
  margin-left: -25px !important;
}

.Assign_Check.form-check-input {
  position: absolute !important;
  margin-top: 17px !important;
  margin-left: 4px !important;
}

.Assign_Sub_Section {
  position: relative;
  margin-left: 43px;
  margin-top: 18px;
}

.Private_Label_Section {
  margin-left: 21px;
}

.Group_Cancle_btn {
  background: #efefef;
  border-color: #c4c4c4;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  width: 130px;
  height: 45px;
}

.Group_Cancle_btn p {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.Group_Update_btn {
  background: #81c14b;
  border-radius: 5px;
  width: 130px;
  height: 45px;
}

.Group_Update_btn p {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

.validation {
  display: flex;
  justify-content: flex-end;
  color: #808080;
  font-weight: 400;
  font-size: 12px;
}

.Forgot_Section {
  display: flex;
  justify-content: flex-end;
  color: #81c14b;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.Descriptionvalidation {
  display: flex;
  justify-content: flex-end;
  color: #808080 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}

.Blocked_Member_Heading h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 20px;
}

.Blocked_Main_Section {
  background: #ffffff;
  border-radius: 5px;
}

.border_section {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.Unblock_btn {
  background: #ff822e;
  border: 1px solid #ff822e;
  border-radius: 5px;
  padding: 0px 0px;
  width: 86px;
  height: 37px;
}

.Unblock_btn p {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

/* @media (max-width:375px) {
    .Unblock_btn {
        background: #FF822E;
        border: 1px solid #FF822E;
        border-radius: 5px;
        width: 50px;
        padding: 0px !important;
        margin: 0px !important;
    }

    .Unblock_btn p {
        color: #FFFFFF;
        font-weight: 400;
        font-size: 10px;
    }
} */

.PaginationCount
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #81c14b !important;
  color: #ffffff !important;
}

@media (max-width: 375px) {
  .PaginationCount
    .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #81c14b !important;
    padding: 0px !important;
    margin: 0px !important;
  }
}

.modal_Heading_BlockUser {
  background: #f6f6f6;
  height: 70px;
}

.modal_Heading_BlockUser h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 24px;
}

.UnBlock_Cancle_btn {
  width: 125px;
  background: #efefef;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 41px;
}

.UnBlock_Cancle_btn p {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
}

.UnBlock_ok_btn {
  background: #81c14b;
  border-radius: 5px;
  width: 125px;
  height: 41px;
}

.UnBlock_ok_btn P {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.BlockProfile_section h6 {
  color: #808080;
  font-weight: 500;
  font-size: 15px;
}

.BlockProfile_section p {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.BlockSubProfile_section p {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.Join_Accept_btn {
  background: #81c14b;
  border-radius: 5.49479px;
  height: 44px;
  width: 90px;
}

.Join_Accept_btn p {
  color: #ffffff !important;
  font-weight: 400 !important;
  font-size: 15.3854px !important;
}

.Join_Decline_btn {
  background: #f6f6f6;
  border-radius: 5.49479px;
  height: 44px;
  width: 90px;
}

.Join_Decline_btn p {
  color: #808080;
  font-weight: 400;
  font-size: 15.3854px;
}

.Join_Confirm_btn {
}

.General_Cancel_btn {
  background: #efefef;
  width: 125px;
  height: 41px;
}

.General_Cancel_btn p {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
}

@media (max-width: 375px) {
  .Join_Accept_btn {
    background: #81c14b;
    border-radius: 5.49479px;
    width: 55px;
    padding: 0px !important;
    margin: 0px !important;
  }

  .Join_Accept_btn p {
    color: #ffffff !important;
    font-weight: 400;
    font-size: 11px !important;
  }
}

@media (max-width: 375px) {
  .Join_Decline_btn {
    width: 55px;
    background: #f6f6f6;
    border-radius: 5.49479px;
    padding: 0px !important;
    margin: 0px !important;
    margin-left: 9px !important;
  }

  .Join_Decline_btn p {
    color: #808080;
    font-weight: 400;
    font-size: 11px;
  }
}

.Join_Profile_section {
  background: #ffffff;
  border: 0.986685px solid #f0f0f0;
  border-radius: 4.93342px;
}

.Join_Profile_section .Join_Profile_Detail {
  position: absolute !important;
  left: 86px !important;
}

.Join_Profile_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 17.5833px;
}

.Join_Profile_section p {
  color: #808080;
  font-weight: 400;
  font-size: 13.8136px;
}

@media (max-width: 375px) {
  .Join_Profile_section h4 {
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13.5833px;
  }
}

@media (max-width: 375px) {
  .Join_Profile_section p {
    color: #808080;
    font-weight: 400;
    font-size: 10.8136px;
  }
}

.Join_Profile_section .Join_Profile_Detail {
  position: absolute;
  left: 100.54px;
}

@media (max-width: 375px) {
  .PostMedia_Gallery_section {
    width: 100% !important;
  }
}

.Block_UnblockAll_btn {
  background: #81c14b;
  border-radius: 5px;
  width: 117px;
  height: 41px;
}

.Block_UnblockAll_btn p {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

/* start_Member_section */
/* .group-main-block {
    display: block;
} */

/* .group-main-block {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    padding: 15px;
    margin: 0 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
} */

/* .group-main-block .gropImg {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border: 2px solid #FFFFFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 50%;
    margin-right: 10px;
} */

.Admin_Remove_btn {
  background: #ffffff;
  border-radius: 4.93342px;
  border: 0.986685px solid #ff822e;
  width: 86px;
  height: 36px;
  display: inline-block;
  padding: 0px 0px;
}

.Admin_Decline_btn {
  width: 86px;
  height: 36px;
  display: inline-block;
  background: #f6f6f6;
  padding: 0px 0px;
}

.Admin_Decline_btn p {
  color: #808080;
  font-weight: 400;
  font-size: 15.3854px;
}

.Admin_Remove_btn p {
  color: #ff822e;
  font-weight: 400;
  font-size: 13.8136px;
}

.Admin_Modify_btn {
  display: inline-block;
  background: #81c14b;
  border-radius: 4.93342px;
  width: 86px;
  height: 36px;
  padding: 0px;
}

.Admin_Modify_btn p {
  color: #ffffff;
  font-weight: 400;
  font-size: 13.8136px;
}

.Admin-btns-blk {
  margin: 12px 0 0;
}

.Block-btns-blk {
  margin: 12px 0 0;
}

@media (max-width: 375px) {
  .Block-btns-blk {
    display: flex !important;
    justify-content: center;
  }
}

@media (max-width: 375px) {
  .Admin-btns-blk {
    display: flex !important;
    justify-content: flex-end !important;
  }
}

@media (max-width: 375px) {
  .Group_Btns_section {
    display: flex;
    justify-content: center;
  }
}

.People_Cancel_btn {
  background: #f6f6f6;
  width: 107px;
  height: 44px;
}

.People_Cancel_btn p {
  color: #808080;
  font-weight: 400;
  font-size: 16px;
}

.People_Assign_btn {
  background: #81c14b;
  border-radius: 5px;
  width: 107px;
  height: 44px;
}

.People_Assign_btn p {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}

.Assign_Sub_Section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14.4914px;
}

.Assign_Sub_Section p {
  color: #808080;
  font-weight: 400;
  font-size: 12.68px;
}

.Accordin_Body_Section {
  color: #808080;
  font-weight: 400;
  font-size: 12.68px;
}

.Success_Heading_Section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 23.3504px;
}

.Success_Ok_btn {
  background: #81c14b;
  border-radius: 3.89639px;
  width: 92px;
  height: 44px;
}

.Success_Ok_btn p {
  font-weight: 400;
  font-size: 16.6882px;
  color: #ffffff;
}

.CreateGroup_Btn {
  border: 1px solid #81c14b;
  border-radius: 5px;
  background: #ffffff;
}

.CreateGroup_Btn p {
  color: #81c14b;
  font-weight: 400;
  font-size: 14px;
}

.Btn_CreateGroup {
  height: 37px;
  padding: 0px;
  margin: 0px;
  width: 137px;
}

.Delete_Modal_Heading_section {
  background: rgba(255, 0, 0, 0.5);
  display: flex;
  justify-content: center;
}

.Delete_Modal_Heading_section h4 {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
}

/* end_Member_section */

/* Joined_group_Section */
.Join_Actifiy_btn {
  background: #81c14b;
  border-radius: 5px;
  width: 110px;
  height: 41px;
}

.Unfollow_Actifiy_btn {
  width: 110px;
  height: 41px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}

/* Joined_group_Section */

.Grop_Form_Label p {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 16px;
}

.CreateGroup_btn {
  background: #acdad6;
  border-radius: 5.01137px;
  width: 130px;
  height: 45px;
}

.CreateGroup_btn p {
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
}

.CancleGroupForm_btn {
  background: #f6f6f6;
  border: 1.00227px solid #c4c4c4;
  border-radius: 5.01137px;
  width: 130px;
  height: 45px;
}

.CancleGroupForm_btn p {
  color: #808080;
  font-weight: 400;
  font-size: 15px;
}

.Scandal-clr-btn {
  background: #acdad6;

  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
  border: 1.00227px solid #acdad6;
}

.Group_Memver_p p {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 16px;
}

.Group_Title_Section h4 {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 20.9179px;
}

.Group_Title_Section p {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 16px;
}

.Icon_Box_Section {
  background: #f6f6f6;
  box-shadow: 0px 0px 10.5338px rgba(126, 178, 98, 0.1);
  border-radius: 5.26688px;
  width: 31.69px;
  height: 31.69px;
}

.Create_Group_section h4 {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 18px;
}

.Create_Group_section p {
  color: #b5b5b5;
  font-weight: 400;
  font-size: 16px;
}

.Create_Group_Heading_Section {
  background: rgba(255, 0, 0, 0.5);
}

.Create_Group_Body_Section h4 {
  color: #808080;
  font-weight: 500;
  font-size: 16px;
}

.Creation_Cnfm_btn {
  background: #81c14b;
  border-radius: 4.71043px;
  width: 117px;
  height: 38px;
  color: #ffffff;
  font-weight: 500;
  font-size: 13.1892px;
  padding: 0px 0px;
}

.Creation_Cncle_btn {
  background: #efefef;
  border: 0.942085px solid #c4c4c4;
  border-radius: 4.71043px;
  width: 117px;
  height: 38px;
  color: #4d4d4d;
  font-weight: 500;
  font-size: 13.1892px;
  padding: 0px 0px;
}

.Button_section {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.Invitation_Heading_Section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 21.2161px;
}

.Invitaion_User_Profile h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

.Invitaion_User_Profile p {
  color: #808080;
  font-weight: 400;
  font-size: 15px;
}

.Invitaion_List_Heading h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 17px;
}

.Invite_SocioMate_Heading h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
}

.Invitation_Send_btn {
  background: #81c14b;
  border-radius: 3.7274px;
  width: 96.91px;
  height: 35.91px;
  padding: 0px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}

.Creation_Heading_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 15.213px;
}

.Creation_Heading_section p {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
}

.Invite_person_btn {
  background: #c4c4c4;
  border-radius: 2.36513px;
  width: 70.18px;
  height: 25.22px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  padding: 0px;
}

.Invite_person_btn.btn-secondary:hover {
  color: #ffffff !important;
  background-color: #c4c4c4 !important;
  border-color: #c4c4c4 !important;
}

.Group_suggested_Box {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
  border-radius: 5px;
}

.Group_suggested_Box .group-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.Group_suggested_Box .Group_circle {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  cursor: pointer;
}

.SuggestedGroup_Heading {
  text-align: center;
}

.SuggestedGroup_Heading h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
}

.Suggest_Join_Button {
  background: #81c14b;
  width: 260px;
  height: 41px;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  padding: 0px;
}

.Invite_Join_Button {
  width: 125px;
  height: 41px;
  background: #81c14b;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  padding: 0px;
}

.Invite_Decline_Button {
  width: 125px;
  height: 41px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  color: #808080;
  font-size: 14px;
  line-height: 21px;
  padding: 0px;
}

.Invite_Join_Button .btn-secondary:hover {
  color: #ffffff !important;
  background-color: #81c14b !important;
  border-color: #81c14b !important;
}

.Suggest_Join_Button .btn-secondary:hover {
  color: #ffffff !important;
  background-color: #81c14b !important;
  border-color: #81c14b !important;
}

.Suggestmember-blk {
  color: #4d4d4d;
}

.Suggestmember-blk span {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
}

.Suggestmember-blk-text p {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.heading-podcast-blk h3 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 20px;
}

.heading-podcast-blk p {
  color: #808080;
  font-weight: 400;
  font-size: 16px;
}

.group-slide-box .gallery_menu_sublink {
  position: absolute;
  top: 120px;
  right: 10px;
  cursor: pointer;
  z-index: 2;
}

.group-slide-box
  .gallery_menu_sublink
  .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal {
  -webkit-transform: translate(-136px, 24px) !important;
          transform: translate(-136px, 24px) !important;
}

.Report_modal_Heading_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 20.1318px;
}

.modal_Header {
  background: #ffffff;
}

.Modal_Body_Section p {
  color: rgba(77, 77, 77, 0.5);
  font-weight: 500;
  font-size: 15px;
  text-align: start;
}

.Report_Section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 15.4697px;
}

.Report_Input_section {
  border-bottom: 0.646685px solid #ebebeb;
  width: 100%;
}

.Report_summit_btn {
  background: #81c14b;
  border-radius: 3.23343px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14.4697px;
  padding: 0px;
  width: 84.07px;
  height: 34.93px;
}

.Report_cancel_btn {
  background: #f6f6f6;
  border: 0.63px solid #c4c4c4;
  border-radius: 3.23343px;
  width: 84.07px;
  height: 34.93px;
  color: #808080;
  font-weight: 500;
  font-size: 14.4697px;
  padding: 0px;
}

.Group_Top_Cover_Section {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
  border-radius: 5px;
  margin-left: 4px;
  margin-right: 5px;
}

.detail-buttons-blk {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-buttons-blk p {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  font-size: 20px;
}

.Group_Detail_Invite_btn {
  background: #81c14b;
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
  width: 99px;
  height: 41px;
}

.Group_Join_btn {
  background: #81c14b;
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 41px;
}

.Group_Detail_Invite_btn .btn.btn-secondary {
  color: #81c14b !important;
  background-color: #81c14b !important;
}

.Group_Detail_paragraph_section p {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 14.1083px;
}

.Group-user-pro-blk-new h5 {
  color: #262626;
  font-weight: 500;
  font-size: 35px;
}

.Group_Avatar_section h6 {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 16px;
}

.Join_Request_Profile h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18.1614px;
}

.Join_Request_Profile p {
  color: #808080;
  font-weight: 400;
  font-size: 14.2697px;
}

.Join_Request_Tym h4 {
  color: #81c14b;
  font-weight: 400;
  font-size: 15.5669px;
}

.approved_Post_button {
  width: 370.03px;
  height: 40.13px;
}

.group-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Post_content_section p {
  color: #808080;
  font-weight: 400;
  font-size: 14.2697px;
}

.Post_hasTag_section span {
  color: #81c14b;
  font-weight: 400;
  font-size: 18.7241px;
}

.Post_hasTag_section p {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 16.2697px;
}

.post_heading_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 23.4051px;
}

.post_Request.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.w-25.show {
  -webkit-transform: translate(400px, 42px) !important;
          transform: translate(400px, 42px) !important;
  padding: 0px !important;
  width: 300px !important;
}

.Post_Card_header {
  padding: 0.75rem 1.25rem !important;
  margin-bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.profile-cover.profile-cover-new {
  border-radius: 5px !important;
  position: relative !important;
  box-shadow: none !important;
}

label.GroupName_Label_Section {
  margin: auto !important;
}

.Group_Dropdown_menu.dropdown-menu.show {
  -webkit-transform: translate3d(-65px, 45px, 0) !important;
          transform: translate3d(-65px, 45px, 0) !important;
  will-change: transform !important;
  top: 0px !important;
  left: 0px !important;
  position: absolute !important;
}

.About_Dropdown_menu.dropdown-menu.show {
  -webkit-transform: translate3d(-65px, 45px, 0) !important;
          transform: translate3d(-65px, 45px, 0) !important;
  will-change: transform !important;
  top: 0px !important;
  left: 0px !important;
  position: absolute !important;
  height: 250px;
}

a.Group_DrppDown_Item.dropdown-item {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 15.8136px;
}

a.Group_DrppDown_Item.dropdown-item:active {
  background-color: #81c14b !important;
  color: #ffffff !important;
}

.Group_Pending_Screen_Section {
  background: #ffffff;
  width: 100%;
  height: 350px;
  position: relative;
}

.abccdrwe {
  position: absolute;
  top: 25%;

  width: 100%;
  text-align: center;
  font-size: 18px;
}

.search-blk {
  position: relative !important;
  /* margin-bottom: 15px !important; */
  left: 0px !important;
}

.search-blk .search-svg {
  width: 17px !important;
  height: 17px !important;
  position: absolute !important;
  top: 12px !important;
  left: 10px !important;
  color: #4d4d4d !important;
}

.search-blk .form-control {
  background: #f6f6f6 !important;
  border-radius: 5px !important;
  padding: 8px 10px 8px 35px !important;
}

.ForgetInput {
  width: 45px;
  border: 1px solid #bdbdbd;
  border-radius: 6px;
}

.tglAntd .ant-switch-checked{
    background:#81c14b !important;
    border: '1px solid #81c14b !important'
 }


.upload-chip{
    margin-top: 5px !important;
}
/* .create-biz-btn .btn:hover {
    background-color: #28a745;
  } */

/* .biz-cration-preview .btn.btn-primary {
  background: #c4c4c4 !important;
  border: 1px solid #c4c4c4;
  color: #fff !important;
} */
 .Biz_form_input_field1{
  
  height: 102px;
}

.conta {


  flex-shrink: 0;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.10);
  margin-left: 9px;
  margin-right: 9px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.40);
}

@media (max-width: 480px) {

  .conta {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.40);
    margin-left: 9px;
    margin-right: 9px;
  }
}
@media (min-width: 280px) and (max-width: 370px){

  .closeinput {
    position: relative;
    left: 0px !important;
}


}
@media (min-width: 280px) and (max-width: 370px){

  .droup{
    left: 100px !important;
  }


}
@media (min-width: 280px) and (max-width: 529px){



.radies {
  
  right: 4px !important;
}
}

@media (max-width: 380px) {

  .conta {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.10);
    margin-left: 9px;
    margin-right: 9px;
    margin-top: 2rem;
  }
  .closeinput{
    position: relative;
    left:5px ;
  }
}

.Bizcrea {
  position: relative;
  left: 23px;
  top: 6px;
  font-size: 20px;
}

.pys-1{
  position: relative;
    left: 47px;
    
}

.gl{
  position: relative;
  top: 33px;
}
.ml-31{
  position: relative;
  top: -35px;
  left: 123px;
}
.headd{
  position: relative;
  left: 34px;
}
.loca{

}

.closeinput{
  position: relative;
 
}

@media (max-width: 1100px){
  .radies{
    position: relative;
    right: 28px;
   
  }
}

.settinges:hover{
  color: blue;
}
.downarrow{
  position: relative;
  right: -85%;
  top: -30px;
}
.downarrow:hover{
  cursor: pointer;
}

.logess{
  position: relative;
  top: -20px;
}

.savebizpage{
  background-color:#acdad6 !important;
  transition: background-color 0.3s, color 0.3s;
}

.BizCreation_Btn_Fillsection:hover {
  background-color: #81C14B !important; /* Change to the desired hover background color */
  color: white; /* Change to the desired hover text color */
}


.borderbiz{
  border-radius: 5px;
background: #FFF;
box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.20);
}

/* Change the color to black on hover */
.your-link-class:hover .your-svg-class {
  stroke: black;
}


@media (max-width: 758px){
  .gd-content-area-top-blk .people-likes.matual-friend-sec {
    margin: 5px -40px 12px;
}
}

@media (max-width: 800px){
.popov{
  width: 73px;
}
.popmember{
  position:relative ;
  right: 12px;
}
}


.settingsvg:hover{
  color: #81c14b;
}

.previewmember{
  padding: 15px 9px !important;
}

.bizsummary{
  padding: 0.55rem 1rem !important;
}

.settingicon ul li a:hover svg{
  stroke: #81c14b !important;
}

.assignimg{

border: 2px solid #FFF;
background:  lightgray 50% / cover no-repeat;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.weektext{
  color: #000;
  color: var(--415047, #000);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
}

.open-text {
  color: green;
}


.success-message {
    position: fixed;
    top: 10%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 10px;
    background-color: green;
    color: white;
    border-radius: 5px;
}

/* ================= Market Place header section ============== */

.MrktPlaceSearch {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #F6F6F6;
    border-radius: 5px;
    /* margin-left: 10px; */
    grid-gap: 8px;
    gap: 8px;
    padding: 13px 20px;
}

.MrktPlaceSearch>input {
    border: none;
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    color: "black";
    background-color: transparent;
}

.MrktPlaceSearch>input::-webkit-input-placeholder {
    color: rgba(77, 77, 77, 0.5);
    font-size: 12px;
    font-weight: 400;
}

.MrktPlaceSearch>input::placeholder {
    color: rgba(77, 77, 77, 0.5);
    font-size: 12px;
    font-weight: 400;
}

.MrktPlaceHeaderLocation {
    padding: 12px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EAECEC;
    border-radius: 5px;
    grid-gap: 10px;
    gap: 10px;
}

.MrktPlaceHeaderLocation>input {
    border: none;
    height: 100%;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    color: "black";
    background-color: transparent;
}

.MrktCated {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;

}

.Mp_Deskto_HeaderTabs {
    display: flex;
}

.MarketPlaceHeaderCate {
    height: 6rem;
    width: 100%;
    display: flex;
    align-items: center;
}

.MrktPlaceCateCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.MrktCated>h3 {
    color: #4D4D4D;
    font-weight: 500;
}

.MrktCated>h6 {
    color: #4D4D4D;
    font-weight: 400;
}

.Mp_LocationSearchInput {
    width: 15rem;
}

.Mp_FormHeader_SideTwo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.Mp_HeaderSearchAndLocation {
    display: flex;
}

.Mp_HeaderSearchAndLocation_Mobile {
    display: none;
}



/* ================= market Place header end ===================== */


/* ====================== left sort By ============================ */


.accordion-button:not(.collapsed) {
    color: revert;
    background-color: #e6f3db;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}

.accordion-button:not(.collapsed) {
    color: revert;
    background-color: #e6f3db;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}


.MrktPlaceRadio {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.MrktPlaceCheckbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.MrktPlaceRadio>input {
    width: 1rem;
    height: 1rem;
}

.MrktPlaceRadio>h5 {
    color: "#808080"
}

.MpUploadHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.MrktClearAllBtn {
    color: #808080;
    cursor: pointer;
    font-weight: 400;
}

/* ================ left sort by ends ============================ */

/* =================left filter ============================= */

.MarketFilterPadding {
    padding: 1rem 1.25rem;
}

.MarketFilterPaddingLeft {
    padding-left: 1.25rem;
}


.Mp_FilterAccordionItem_btn>button.accordion-button {
    padding: 0.25rem 0.25rem;
}

.MpFilterValueInput>input {
    font-weight: 500;
    color: "#4D4D4D";
}

.MpFilterValueInput_first {
    width: 60%;
    border: none;
    border-radius: 0;
}

.MpFilterValueInput_Two {
    width: 40%;
    border: none;
}

.MpFilterValueInput>input::-webkit-input-placeholder {
    font-weight: 500;
    color: "#4D4D4D";
}

.MpFilterValueInput>input::placeholder {
    font-weight: 500;
    color: "#4D4D4D";
}

.MpFilterValueInput_Two>option {
    width: 100%;
    border-bottom: 1px solid #808080;
}

.MpFilterValueInput_Price_First {
    width: 50%;
    border: none;
    border-radius: 0;
}

.MpFilterValueInput_Price_Doller {
    width: 10%;
}

.MpFilterValueInput_Price_two {
    width: 40%;
    border: none;
}




.Mp_FilterRangebtwn_value {
    font-weight: "500";
    font-size: 16px;
    color: #4d4d4d;
}

.Mp_FilterRangebtwn_value::-webkit-input-placeholder {
    font-weight: "500";
    color: #4d4d4d;
}

.Mp_FilterRangebtwn_value::placeholder {
    font-weight: "500";
    color: #4d4d4d;
}


.Mp_CategoryAccordion.accordion-body {
    padding-left: 1.25rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

/* .Mp_Filter_Tab {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

} */

/* .Mp_Filter_Tabs_Top {
    display: grid;
    column-gap: 1.5rem;
    grid-template-columns: repeat(4, minmax(auto, min-content));
} */

.Mp_Filter_Tabs_Top {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
}

.Mp_Filter_Tabs_Top>div {
    flex: 1 1 55px;
}

.Mp_Tab_navItems {
    padding: 15px 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.Mp_FilterAccordionItem_btn>.accordion-button::after {
    top: 12px !important;
    /* or any other color that suits your design */
}


/* ============================ left Filter ends ======================= */


/* ===================== market place center cards ====================== */

.MpCardsMainCardContainer {
    padding: .5rem;
}

.marketplaceCardSec.card {
    margin: 0px 0px 12px !important;

}
.marketplaceCardSec2 {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    cursor: pointer;
    padding: 0.5rem;
    box-sizing: content-box;
    height:400px;
    margin-bottom: 8px;
}

.marketplaceCardSec {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    cursor: pointer;
    padding: 0.5rem;
    box-sizing: content-box;
}

.marketplaceCardSec .Mp_Card_img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 5px 5px 5px 5px;
}


.marketplaceCardSec2 .Mp_Card_img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 5px 5px 5px 5px;
}
.MarketPlaceCard_RightEye {
    position: absolute;
    display: flex;
    bottom: 8px;
    left: 8px;
    background: rgba(0, 0, 0, 0.15);
    padding: 4px;
    border-radius: 5px;
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
}

.MarketPlaceCard_RightEye_Suggested {
    position: absolute;
    display: flex;
    top: 20px;
    right: 12px;
    background: rgba(0, 0, 0, 0.15);
    padding: 4px;
    border-radius: 5px;
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
}

.MarketPlaceCard_RightEye p {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;

}

.MarketPlaceCard_RightEye_Suggested p {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;

}

.Mp_ProductCard_Analytics {
    padding: 6px 10px;
    background-color: rgba(0, 0, 0, 0.26);
    color: white;
    border-radius: 5px;
}

.MarketPlace_MrktIconLeft {
    position: absolute;
    top: 20px;
    left: 16px;
    display: flex;
    background: rgba(0, 0, 0, 0.15);
    padding: 8px;
    border-radius: 5px;
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
}

.MarketPlace_MrktIconLeft_SingleProductPage {
    position: absolute;
    top: 15px;
    /* left: 25px; */
    right: 25px;
    display: flex;
    background: rgba(0, 0, 0, 0.15);
    padding: 8px;
    border-radius: 5px;
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
}

.MarketPlace_MrktIconLeft_SingleProductPage1 {
    position: absolute;
    top: 25px;
    left: 25px;
    /* right: 25px; */
    display: flex;
    background: rgba(0, 0, 0, 0.15);
    padding: 8px;
    border-radius: 30px;
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
}


/* .MarketPlaceCard_RightEye p {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;

} */

.MpThreeDotMenu {
    position: absolute;
    right: 10px;
    top: 20px;
}

.MpMobileThreeDotIcon {
    display: none;
}

.Mp_ThreeDotIcon.btn-group>.btn {
    padding-right: 0px !important;
    padding-top: 8px !important;
}

.MpThreeDotMenu_singleProduct {
    position: absolute;
    right: 10px;
    top: 6px;
}

.MpMobileThreeDotIcon {
    display: none;
}

.MpThreeDotMenu_singleProduct.btn-group>.btn {
    padding-right: 0px !important;
    padding-top: 8px !important;
}





.MarketPlace_SubCate_Price {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MarketPlace_SubCate_Price h5 {
    color: #81c14b;
    font-weight: 400;
    font-size: 14px;
}

.MarketPlace_SubCate_Price p {
    color: #81c14b;
    font-size: 16px;
    font-weight: 600;
}

.MpDesktopEye {
    display: block;
}

.MpMobileEye {
    display: none;
}


/* .Mp_Card_Call_Msg_Btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
} */

.Mp_Card_Call_Msg_Btn {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
            column-gap: 12px;
}

.Mp_Card_Call_Msg_Btn1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
            column-gap: 12px;
}

/* 
.Mp_Card_Call_Msg_Btn>button {
    flex: 1 1 100px;
} */

.MarketPlaceCardContent {
    padding: 10px;
}




/* / */

.Mp_Card_Bottom_Sec h4 {
    color: #4D4D4D;
    font-weight: 600;
}

.Mp_Card_Bottom_Sec h3 {
    font-weight: 600;

}

.Mp_Card_Bottom_Sec .MarketPlace_SubCate_Price {
    display: flex;
    justify-content: space-between;
    padding-top: 13px;
}

.Mp_Card_Bottom_Sec .MarketPlace_SubCate_Price p {
    color: #4D4D4D;
    font-weight: 600;
}


.Mp_Card_Bottom_Sec .MarketPlace_SubCate_Price h5 {
    color: #16C31E;
    font-weight: 400;
}



.Mp_MsgBtn_svg {
    margin-right: 8px;
    width: 15px;
    height: 15px;
}

.Mp_MsgBtn_svg:hover {
    stroke: white;
}

.Mp_GridDesign_Display_None {
    display: none;
}

.Mp_ListViewCard_DisplayMange {
    display: flex;
}

.MpnoneDisplay {
    display: none;
}

/* ==============market place center cards end ======================= */


/* ============== Market place single product ========================= */

.SetHeartActive {
    color: red;
    fill: red;
}




/* .Mp-SingleProductMoreImages {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5px;
    row-gap: 5px;
} */

.MP_GridImageSizeDecieder img {
    aspect-ratio: 4/4;
    object-fit: cover;
    object-position: top;
}


/* For Safari iOS */
@supports (-webkit-touch-callout: none) {
    .MP_GridImageSizeDecieder {
        height: 0;
        padding-bottom: 100%;
        position: relative;
    }

    .MP_GridImageSizeDecieder img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
}




.Mp_GridContainers {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Mp_Single_CardDetails>h5 {
    color: #81c14b;
    font-weight: 600;
    margin-top: 10px;
}

.Mp_Single_CardDetails_H5 {
    color: #81c14b;
    font-weight: 600;
    margin-top: 10px;
}

.Mp_Single_CardDetails>h1 {
    color: #4D4D4D;
    font-weight: 600;
    margin-top: 10px;
}

.Mp_SetOrder {
    display: flex;
    width: 100%;
}

.Mp_setOrderLeft {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Mp_setOrderRight {
    flex: 0.2 1;
}

.Mp_setOrderLeft>h5 {
    font-weight: 600;
    color: #4D4D4D;
}

.Mp_setOrderLeft_Image {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.Mp_setOrderLeft_Image>img {
    width: 6.25rem;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
}

.Mp_setOrderLeft_ImageText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    grid-gap: 1.25rem;
    gap: 1.25rem;
}

.Mp_setOrderLeft_ImageText1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    grid-gap: 0.35rem;
    gap: 0.35rem;
}

.Mp_setOrderLeft_ImageText>h6 {
    color: #878992;
    font-weight: bold;
}

.Mp_setOrderLeft_ImageText>.Mp_singleVarient {
    font-weight: 600;
    color: "#4D4D4D"
}

.Mp_setOrderRight_Icon {
    padding: 7px;

}

.Mp_SinglePage_CardHeading {
    font-weight: 600;
    color: #4D4D4D;
}

.Mp_AllItems_Items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 10px;
    row-gap: 10px;
}

.Mp_AllItems_Discription {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.Mp_AllItems_Discription>li {
    color: #878992;
    font-weight: 400;
}

.MpSellerDetails_View {
    color: #81c14b;
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
}

.Mp_TextColorGray {
    color: "#878992";
    font-weight: "400";
}

.Mp_ColorPrimaryBold {
    font-weight: 600;
    color: "#81c14b";
}



/* like share cmment and view   */

/* .MuiButtonBase-root{
    color: #81c14b !important;
} */
/* button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    color: #81c14b !important;
    text-transform: capitalize !important;
}


span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
    background-color: #81c14b !important;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    text-transform: capitalize !important;
} */

/* ======================== market place single page ========================== */

/* .css-1aquho2-MuiTabs-indicator {
    background-color: #81c14b !important;
} */



/* Market place sell modal */

.Mp_Modalheader {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 6px 0px;
}

.Mp_Modalheader>h3 {
    color: #4D4D4D;
    font-weight: 600;
}

/* .Mp_Modalheader>h5 {
    color: #ABACB3;
    font-weight: 400;
} */

.Mp_SellForm_Head>h4 {
    font-weight: 600;
    color: #4D4D4D;
}

.MpModalCategoryCard {
    max-height: 372px;
    overflow-y: auto;
}



/* =========== Form tabs ======== */
/* 
.MpFormTabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}

.MpFormTabs>div {
    flex: 1 1 100px;
    border-radius: 5px;
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
} */

.MpformElementHeading {
    color: #878992;
    font-weight: 500;
}


.MpFormTabs {
    width: 100%;
    display: flex;
    grid-gap: 20px;
    gap: 20px
}


.Mp_Form_Tabs_ {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: inline-block;
    padding: 8px 15px;
    margin: 0 10px 10px 0;
    color: #4d4d4d;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.Mp_Form_Tabs_:hover {
    background: rgba(129, 193, 75, 0.15);
    border: 1px solid #81C14B;
}


.Mp_Form_ImageUploadInputs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.Mp_Form_ImageUploadInputs>div {
    flex: 1 1 auto;
    max-width: 100px;
}




.MpFullDropDown {
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    max-height: -webkit-max-content;
    max-height: max-content;
}

.MpFormDropdownHeading {
    padding: 14px 25px;
}

.MpFullDropDown>div {
    position: absolute;
    left: 0;
    top: 44px;
    display: flex;
    padding: .5rem 0rem;
    padding-left: 2rem;
    grid-gap: 12px;
    gap: 12px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    z-index: 1000;
    background-color: white;
    box-shadow: 0px 20px 70px rgba(0, 0, 0, 0.1);
}

.MpFullDropDown>div>h5 {
    width: 100%;
    padding: .5rem 0rem;

}

.MpFormLocationInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    width: 100%;
    padding-right: 10px;
}


.Mp_LocationBoxOnInput {
    position: absolute;
    top: 46px;
    left: 0;
    width: 100%;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 20px 70px rgba(0, 0, 0, 0.1);
    background-color: white;
    z-index: 1;
}





.MpFormPriceInput>input {
    font-weight: 500;
    color: #4D4D4D;
}

.MpFormPriceInput>input::-webkit-input-placeholder {
    font-weight: 500;
    color: #4D4D4D;
}

.MpFormPriceInput>input::placeholder {
    font-weight: 500;
    color: #4D4D4D;
}


/* Discription box  */


.MpFormBottomEditable>span {
    font-size: 18px;
    object-fit: cover;
    object-position: center;
}

.MpChooseImage {
    border: 1px dashed #D1D0D3;
    border-radius: 20px;
    /* padding: 20px; */
    width: 100px;
    height: 100px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    object-fit: cover;
    object-position: center;
    justify-content: center;
}




/* form selller profile  */




.Mp_From_Seller {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Mp_From_Seller>img {
    width: 90px;
    height: 90px;
}


.Mp_Form_SellerCommunicaion>h5 {
    color: #878992;
    font-weight: 500;
}


.Mp_FormSeller_Btn {
    padding: 11px 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: white;

}

.MpSellerBtnActive {
    background: rgba(129, 193, 75, 0.15);
    border: 1px solid #81C14B;
}

.Mp_FormSeller_Btn:hover {
    background: rgba(129, 193, 75, 0.15);
    border: 1px solid #81C14B;
}

.Mp_FormPostNow_Btn {
    padding-left: 80px;
    padding-right: 80px;
}


.css-16r9ayz-MuiFormControlLabel-root {
    margin-bottom: 0px !important;
}








button.srch_btn_toggle.btn.btn-secondary {
    background: none !important;
    /* margin-top: -9px; */
    padding: 0;
}

.marketPlace_srch_dropMenu.dropdown-menu.show {
    display: block;
    width: 112%;
    will-change: transform;
    top: 0px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 10px 10px 84px rgba(0, 0, 0, 0.15);
    left: 0px;
    -webkit-transform: translate3d(-37px, 48px, 0px) !important;
            transform: translate3d(-37px, 48px, 0px) !important;
}


.Mp_MarketSvgICon {
    width: 12px;
    height: 12px;
    border-color: white;
}







/* ================ Universal class for marketplace Tabs ============= */
.Mp_TabsActive {
    background: rgba(129, 193, 75, 0.15);
    border: 1px solid #81C14B;
}

.Mp_TabsHover:hover {
    background: rgba(129, 193, 75, 0.15);
    border: 1px solid #81C14B;
}


/* color class  */

.Mp_Color_4D {
    color: #4D4D4D;
}

.Mp_FontWeight_6 {
    font-weight: 600;
}

.Mp_FontWeight_5 {
    font-weight: 500;
}

.Mp_Color4D_Font6 {
    font-weight: 600;
    color: #4D4D4D;
}

.Mp_Color4D_Font5 {
    font-weight: 500;
    color: #4D4D4D;
}

.Mp_Color80_Font4 {
    font-weight: 400;
    color: #4D4D4D;
}

.Mp_Color4D_Font6_Flex1 {
    font-weight: 600;
    color: #4D4D4D;
    flex: 1 1;
}

.Mp_Border0_Flex1 {
    border: none;
    flex: 1 1;
}

.Mp_Color81_Font5 {
    color: #81C14B;
    font-weight: 500;
}

.Mp_Color87_Font5 {
    color: #878992;
    font-weight: 500;
}

.Mp_Color16_Font6 {
    color: #161828;
    font-weight: 600;
}

.Mp_Color80_Font5 {
    color: #808080;
    font-weight: 600;
}


.zIndexImage {
    z-index: 1 !important;
}







/*  ================== Modal Location Design ================ */

.Mp_Loaction_Modal_Heading {
    display: flex !important;
    justify-content: space-between !important;

}

.Mp_Loaction_Modal_Heading h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

}

.Mp_Loaction_Modal_Heading p {
    color: #81C14B;
    /* font-weight: 600; */
    font-size: 16px;
    cursor: pointer;
    line-height: 24px;

}


.Mp_FormControlSec p {
    color: #81C14B;
    /* font-weight: 400;
    font-size: 16px; */
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.Mp_Form_Location_FormControl_Input {
    position: relative;
}


label.Mp_LocationSearchInputLabel {
    color: #4D4D4D !important;
    font-weight: 500 !important;
    font-size: 14;
}


.inputForm {
    padding-right: 20%;
    background-color: #F6F6F6;


}


.ImageCrossButton_Mp {
    border-radius: 100%;
    background-color: #FFFFFF;
    opacity: 0.7;
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
}

















/* arbnnb  */

.colorWhiteSlider {
    background-color: white !important;
}






/* market place modal  */
.MP_Modal_Adjustments {
    max-height: 400px;
}


ol.breadcrumb {
    background: transparent;
}





.PositionOfSmilyInReplyInput {
    position: absolute;
    right: 50px;
}





h3.MpEmojiBoxHeader.popover-header {
    background: #81c14b;
    color: white;
}



/* to responsive the cards */

.MpGridSystemAuto {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}



button.Mp_Card_Msgbtn.btn.btn-outline-success {
    padding: 4px 8px;
}

.Mp_Card_Msgbtn {
    width: 100%;
    padding: 10px;
}







/* Market place  three dot menu   */


.dropdown-menu.MpDropDownMenu.Market\@place_dropDown.show {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    -webkit-transform: translate(135px, 40px) !important;
            transform: translate(135px, 40px) !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

}


.dropdown-menu.MpDropDownMenu.Market\@place_dropDown.show {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    -webkit-transform: translate(135px, 40px) !important;
            transform: translate(135px, 40px) !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.MarketPlaceSingleProductMenu.show {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    -webkit-transform: translate(550px, 41px) !important;
            transform: translate(550px, 41px) !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}



.Mp_Product_Status {
    padding: 5px 15px;
    background-color: #80c14b4e;
    color: #81c14b;
    border-radius: 30px;
    display: inline-block;
}

.Mp_Product_Status2 {
    padding: 5px 15px;
    background-color: #0f100f61;
    color: #fff;
    border-radius: 30px;
    display: inline-block;
}





.MpDropdownOptionMenu.dropdown-menu.show {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    box-shadow: 2px 2px 8px rgb(0 0 0 / 10%) !important;
}


a.MpDropdownMenuItems.dropdown-item:hover {
    background: #e6f3db !important;
}

.MpDropdownMenuItems {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    font-size: 14px;
    border-bottom: 1px solid rgb(225, 225, 225);
}

.MpDropdownMenuItems:last-child {
    border-bottom: none;
}

.Mp_ThreeDotIcon {
    position: absolute;
    top: 10px;
    right: 10px;
}


.MpDropdownOptionMenu.dropdown-menu.show {
    -webkit-transform: translate3d(-190px, 28px, 0px) !important;
            transform: translate3d(-190px, 28px, 0px) !important;
}

.MpDropdownOptionMenu2.dropdown-menu.show {
    -webkit-transform: translate3d(-132px, 39px, 0px) !important;
            transform: translate3d(-132px, 39px, 0px) !important;
}




a.Mp_ViewTabs.nav-link.active {
    color: #81C14B;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    border-bottom: solid 1px #81C14B;
}

a.Mp_ViewTabs.nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 3rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: rgba(0, 0, 0, 0.5);
}


.mpCancelButton {
    border: 1px solid red;
    background-color: rgba(255, 0, 0, 0.19);
    color: red;
}






.MpDeleteButton {
    color: orange;
    border-color: orange;
}

.MpDeleteButton:hover {
    background: orange;
    color: white;
    border-color: orange;
}


button.MpDropdownMenuItems.dropdown-item:hover {
    color: #333;
    background: #e6f3db;
}


.Mptruncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


li.MpBreadCrumb_Items.breadcrumb-item {
    padding: 0 !important;
}

.MpSigleProductBookMark {
    position: absolute;
    right: 42px;
    top: 10px;
}
.MpSigleProductBookMark1 {
    position: absolute;
    right: 42px;
    top: 21px;
}








.Mp_Deskto_HeaderTabs {
    flex-wrap: wrap;
}



.Mp_Mobile_HeaderTabs {
    display: none;
}


.mpListViewImageWidth {
    min-width: 4vw;
    min-height: 4vh;
}


/* list View Card Design ================= */

.MpCardListView {
    /* max-height: 16vh; */
    display: grid;
    grid-template-columns: 11% 41% 15% 13% 18% 2%;
    grid-template-areas: "image name category price seller ThreeDotMenu";
    place-items: center;
    box-sizing: content-box;
    overflow-clip-margin: 10px;
}

.Mp_ListviewCardImage {
    grid-area: image;
    height:100px;
}

.Mp_ListViewCard_NameLoc {
    grid-area: name;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.MpCardListViewCategory {
    grid-area: category;
}

.MpCardListViewPrice {
    grid-area: price;
}

.MpCardListViewBtn_userdetail {
    grid-area: seller;
}

.MpCardListViewMenu {
    grid-area: ThreeDotMenu;
}

.MpListViewCardEye {
    position: absolute;
    bottom: 6px;
    right: 6px;
}



.MarketPlaceListView_MrktIconLeft {
    position: absolute;
    top: 8px;
    left: 6px;
    display: flex;
    background: rgba(0, 0, 0, 0.15);
    padding: 4px;
    border-radius: 5px;
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
}

.MpCardListViewButtons>div {
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid rgb(237, 237, 237);
}


.MarketPlaceFullCardContainer>button {
    padding: 2px 12px !important;
}

/* .MpHoverCard {
    display: flex;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 0 20px;
    transition: box-shadow 1s !important;

} */

button.Mp_ListGrid_Btn.ml-2.btn.btn-outline-success {
    padding: 9px 9px !important;
}


.MpHoverCard:hover {
    border: 1px solid #81C14B !important;
    background: rgba(129, 193, 75, 0.1) !important;
    border-radius: 6px;
}


.MpRevertRow.row {
    --bs-gutter-x: revert !important;
}











/* Modal category card ANimations ============================================= */

.Mp_CateList_d-none {
    -webkit-animation: slideGone 0.3s ease;
            animation: slideGone 0.3s ease;
    display: none;

}

.Mp_CateList_d-block {
    -webkit-animation: SlideCome 0.3s ease;
            animation: SlideCome 0.3s ease;

}






@-webkit-keyframes slideGone {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}






@keyframes slideGone {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@-webkit-keyframes SlideCome {
    from {
        opacity: 0;
    }

    to {

        opacity: 1;
    }
}

@keyframes SlideCome {
    from {
        opacity: 0;
    }

    to {

        opacity: 1;
    }
}


.MpFilterAndSortByMobile {
    display: none;
    position: relative;
}


/* vertical line */
.MpFilterAndSortByMobile::before {
    content: "";
    width: .5px;
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    background-color: #dee2e6;
}



/* ============================ sideBar Mobile Filter  ================== */
/* .MpFilterSortByMobile {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: white;
    overflow: auto;
    padding: 1rem;
    display: none;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 70vw;
    animation: SideBarfilterIn 0.3s ease-in-out;
} */





/* ========================== list View card media Query ====================== */

/* 
@media screen and (max-width:751px) {
    .MpCardListView {
        grid-template-columns: repeat(auto-fill, 1fr);
        grid-template-areas:
            "image image name name name ThreeDotMenu"
            "image image name name name .."
            "image image price price ..  .. "
            "image image .. .. seller seller "
            "image image category category seller seller";
        max-height: 35vh;
    }
}

@media screen and (max-width:425px) {
    .MpCardListView {
        grid-template-areas:
            "name name name price price ThreeDotMenu"
            "name name name price price .."
            "image image category category ..  .. "
            "image image category category seller seller "
            "image image category category seller seller";
    }
} */



ol.breadcrumb {
    z-index: 2 !important;
    position: relative !important;
}


.ManageOwnProductBtn {
    border: 1px solid orange;
    color: orange;
    background: #FFFFFF;
}

.ManageOwnProductBtn:hover {
    background-color: orange;
    border: 1px solid orange;
    color: white;
}

.marketradio {
    -webkit-appearance: radio !important;
    width: 18px !important;
    height: 24px;
}

.canvas3 li {

    padding: 3px 0;
    color: #E6F4F3;
}

.canvas3 {
    text-align: left;
    /* position: relative; */
    /* left: 36px; */
    margin-left: 8px;
}

.marketcn {
    position: relative;
    left: 4px;
}

@media (min-width:280px) and (max-width: 576px) {
    .markettop {

        position: relative;
        overflow: hidden;
        margin-right: 8px;
        margin-left: 8px;
    }
}

@media (min-width:280px) and (max-width: 336px) {
    .likempdiv {

        position: relative;
        right: 10px;

    }
}

@media (min-width:280px) and (max-width: 576px) {
    .mpshare {

        margin: 15px;
    }

    .cardmobile {

        display: none !important;
    }

    .comenshare {

        position: absolute;
        left: 98px;
    }

    .markettop1 {

        position: relative;

        margin-right: 8px;
        margin-left: 8px;
    }

    .Mp_FormPostNow_Btn {
        padding-left: 40px;
        padding-right: 40px;
    }

    .gapmp {
        border-radius: 10px;
        border: 1px solid #81C14B;
        background: #FFF;
    }

    .descpgap {
        margin-top: 26px !important;
    }

    .mpother{
        margin-top: 20px;
    }
}





.MpUploadHeading1 {
    display: flex;
    justify-content: flex-end;

}

@media (min-width:576px) {
    .cardmobileviewmp {

        display: none !important;
    }
}

.maploc {
    color: #808080;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.maplocc {
    color: #878992;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.maploccc {
    color: #212529;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.aboutmp {
    color: #161828;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.mpdescr {
    color: #4D4D4D;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.parmp {
    margin-top: -20px;
    margin-left: 13px;
    color: #878992;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
}

@media  (max-device-width: 480px) {
    .parmp {
      line-height: 1.2; /* Adjust line height specifically for iOS */
    }
  }

.mapicon{
    position: relative;
    top: -2px;
}

.totalpri{
    color: #000 !important;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.totalpri2{
    color: #81C14B;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
/*  ========================= media query ================ */




@media (max-width : 1070px) {
    .Mp_LocationSearchInput {
        width: 100%;
    }
}

@media (max-width:1500px) {
    .MpFilterValueInput_first {
        width: 55%;
    }

    .MpFilterValueInput_Two {
        width: 45%;
    }

    .MpFilterValueInput_Price_First {
        width: 45%;
    }

    .MpFilterValueInput_Price_two {
        width: 45%
    }


}

/* to manange the cards of the market place on all screen  */

@media (max-width:1851px) {
    .MpGridSystemAuto {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
    }
}

@media (max-width:1300px) {
    .MpGridSystemAuto {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
    }
}


/* Media Query for the responsiveness Of Header  */

@media screen and (max-width: 1154px) and (min-width:567px) {
    .Mp_Deskto_HeaderTabs {
        display: none;
    }

    .MrktCated {
        min-width: 164px;
    }

    .Mp_Mobile_HeaderTabs {
        display: flex;
    }
}

/* Media Query For the Responsiveness of Header on 800px */
@media screen and (max-width: 767px) {
    .MpFormTabs {
        flex-wrap: wrap;
        min-width: 100%;
    }

    .MpFormTabs>span {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Mp_MarketPlaceHeader_Location {
        width: 100%;
    }

    .Mp_LocationSearchInput {
        width: 100%;
    }

    .MrktPlaceHeaderLocation {
        width: 100%;
    }

    .Mp_FormHeader_SideTwo {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 10px;
    }

    .Mp_FormHeaderLocationBar.btn-group {
        width: 100% !important;
    }


}



/* Media Query For the  Header and SingleProduct Page design */
@media screen and (max-width: 576px) {
    .MpGrid_Image_BigScreen {
        display: none;
    }

    .Mp_SingleProductPage_Details {
        margin-top: 15px;
    }

    .Mp_SinglePageHeading_Name {
        font-size: 16px;
    }

    .MpFormTabs {
        flex-wrap: wrap;
        min-width: 100%;
    }

    .MpFormTabs>span {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Mp_Deskto_HeaderTabs {
        display: none;
    }

    .MrktCated {
        min-width: 164px;
    }

    .Mp_Mobile_HeaderTabs {
        display: flex;
    }

}

@media (max-width:992px) {
    .MpGrid_Image_BigScreen {
        display: none;
    }
}



/* media query for the card design on mobile  */

@media (max-width: 768px) {
    .MpGridSystemAuto {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    }

    .Mp_Card_Bottom_Sec>h3 {
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .MarketPlace_SubCate_Price>h5 {
        font-size: 9px;
    }

    .MarketPlace_SubCate_Price>p {
        font-size: 10px;
    }

    .Mp_Card_Call_Msg_Btn {
        display: none;
    }
    /* .Mp_Card_Call_Msg_Btn1 {
        display: none;
    } */

    .MarketPlace_End_section {
        display: none;
    }

    .MpMobileMarketSvgICon {
        width: 9px;
        height: 9px;
        border-color: white;
    }

    /* .Mp_ThreeDotIcon {
        display: none;
    } */

    .MarketPlace_MrktIconLeft {
        position: absolute;
        top: 14px;
        left: 12px;
        display: flex;
        background: rgba(0, 0, 0, 0.15);
        padding: 6px;
        border-radius: 5px;
        -webkit-backdrop-filter: blur(7px);
        backdrop-filter: blur(7px);
    }

    .MpMobileThreeDotIcon {
        display: block;
        position: absolute;
        top: -1px;
        right: 4px;
    }

    .MpMobileThreeDotIcon .btn-group>.btn {
        padding-right: 0 !important;
        padding-top: 8px !important;
    }

    button.MpDropdownMenuItems.dropdown-item {
        font-size: 10px;
    }

    .MpDropdownOptionMenu.dropdown-menu.show {
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1) !important;
    }

    .MpDropdownOptionMenu.dropdown-menu.show {
        -webkit-transform: translate3d(-124px, 28px, 0) !important;
                transform: translate3d(-124px, 28px, 0) !important;
    }

    .MpDropdownOptionMenu2.dropdown-menu.show {
        -webkit-transform: translate3d(-124px, 28px, 0) !important;
                transform: translate3d(-124px, 28px, 0) !important;
    }

    .MarketPlaceCard_RightEye {
        position: absolute;
        display: flex;
        bottom: 8px;
        left: 8px;
        background: rgba(0, 0, 0, 0.15);
        padding: 2px;
        border-radius: 5px;
        -webkit-backdrop-filter: blur(7px);
        backdrop-filter: blur(7px);
    }

    .MarketPlaceCard_RightEye p {
        color: #fff;
        font-weight: 500;
        font-size: 9px;
    }

    .MpDesktopEye {
        display: none;
    }

    .MpMobileEye {
        display: block;
    }

    .MpnoneDisplay {
        display: grid;
    }

    .Mp_ListViewCard_DisplayMange {
        display: none;
    }

    /* media query for the mobile view of Header */
    .Mp_Mobile_HeaderTabs {
        display: none;
    }

    button.MpMobileViewButton_Manage.btn.btn-outline-primary {
        padding: 2px 5px !important;
        display: none;
    }

    button.MpMobileViewButton_sell.btn.btn-outline-primary {
        padding: 5px 10px !important;
        /* border: none; */
        font-size: 12px !important;
    }

    .button.MpMobileViewButton_sell.btn.btn-outline-primary:hover {
        background-color: transparent !important;
        color: #81C14B !important;
    }

    .MrktPlaceSearch {
        padding: 13px 10px;
    }

    .marketPlace_srch_dropMenu.dropdown-menu.show {
        -webkit-transform: translate3d(-20px, 48px, 0px) !important;
                transform: translate3d(-20px, 48px, 0px) !important;
    }

    .MpCardsMainCardContainer {
        padding: .5rem;
    }

    .marketplaceCardSec {
        padding: 0rem;
    }

    .marketplaceCardSec {
        border: none !important;
    }

    .marketplaceCardSec2 {
        padding: 0rem;
        height: 350px;
    }

    .marketplaceCardSec2 {
        border: none !important;
    }




    /* list View and Grid view hide */
    .listViewAndGridViewHide {
        display: none;
    }
}



/* media query for the filter and sortby ==== */

@media screen and (max-width:1199px) {
    /* .MpFilterSortByMobile {
        display: block;
        width: 40%;
    } */

    .MpFilterSortBy_Desktop {
        display: none;
    }

    .MpFilterAndSortByMobile {
        display: flex;
    }
}




@media (max-width:992px) {
    .MarketPlace_MrktIconLeft_SingleProductPage {
        top: 25px;
    }
    .MarketPlace_MrktIconLeft_SingleProductPage1 {
        top: 25px;
    }

    .mpListViewImageWidth {
        min-width: 7vw;
        min-height: 7vh;
    }

    .MpCardListView {
        /* max-height: 16vh; */
        display: grid;
        grid-template-columns: 16% 36% 15% 13% 18% 2%;
        grid-template-areas: "image name category price seller ThreeDotMenu";
        place-items: center;
        box-sizing: content-box;
        overflow-clip-margin: 10px;
    }

}
.FilterThisButton {
  padding: 10px 15px;
  color: #808080;
  /* background: rgba(129, 193, 75, 0.2); */
  border-radius: 5px;
  display: block;
  transition: 0.5s;
}
.connection-input-style {
  white-space: pre-wrap;
}

.bottomleft {
  position: absolute;
  bottom: 0px;
  font-size: 18px;
  color: #ffffff;
  background: rgba(2, 2, 2, 0.135);
  width: 100%;
  display: flex;
  border-radius: 10px;
}

.bottomleft.rem-round {
  position: absolute;
  bottom: 0px;
  font-size: 18px;
  color: #ffffff;
  background: rgba(2, 2, 2, 0.135);
  width: 100%;
  display: flex;
  border-radius: 0 0 10px 10px;
}

.PeopleThisGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.TopTitleThis {
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
}

.topleft {
  position: absolute;
  top: 14px;
  font-size: 15px;
  left: 12px;
}

.roundedThisBorder {
  border-radius: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.RecommendationThisawesome {
  background: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  padding: 11px 6px;
}

.numformattercolorPostion {
  position: absolute;
  bottom: 0;
  /* left: 2px; */
  font-size: 18px;
  width: 100%;
}

.backGroundThisColor {
  background: rgba(255, 255, 255, 0.9);
}

.topright {
  position: absolute;
  top: 14px;
  right: 16px;
  font-size: 18px;
}

.CountLeft {
  position: absolute;
  top: 15px;
  font-size: 15px;
  left: 76px;
  display: flex;
  opacity: 1.4;
}

.EventThisbutton {
  position: absolute;
  width: 100%;
  bottom: 14%;
  border-radius: 0 0 10px 10px;
}

.EventThisbuttonTitle {
  position: absolute;
  width: 100%;
  bottom: 17%;
  border-radius: 0 0 10px 10px;
}

.CricleThisFill {
  position: absolute;
  left: 50px;
  top: 70px;
}

.TagThisTopVideo {
  position: absolute;
  top: 36%;
  left: 39%;
  text-align: center;
}

.NumThisPeople {
  position: absolute;
  left: -1px;
  top: 70%;
  font-weight: 500;
  font-size: small;
  width: 100%;
  text-align: center;
  font-weight: 500;
  color: #000000;
  /* background-color: rgba(255, 255, 255, 0.456); */
}

.CategoryThisText {
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
}

.PaidThisColor {
  position: absolute;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  width: 218px;
  height: 75px;
  right: -119px;
  bottom: 24px;
}

.EventThisTextFree {
  position: absolute;
  width: 100%;
  top: 30%;
  left: 10px;
  border-radius: 0 0 10px 10px;
}

.light-green-People {
  background: #81c14b;
  border-radius: 6px;
  color: #f8f9fa;
  border: none !important;
  outline: none !important;
}

.EventThisTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #4d4d4d;
}

.FreeThisText {
  border: 1px solid #ffffff;
  border-radius: 14px;
  color: #ffffff;
}

.Eventright {
  position: absolute;
  top: 8px;
  right: -2px;
  font-size: 16px;
}

.Eventleft {
  position: absolute;
  top: 1px;
  font-size: 18px;
  color: #ffffff;
  background: rgba(2, 2, 2, 0.135);
  width: 100%;
  display: flex;
}

.EventbodyThis {
  position: relative;
}

.CardbodyThis {
  position: relative;
}

.UncontrolledButtonDropdownthis {
  position: absolute;
  left: 269px;
  top: 30px;
}

.CountriesThisFontColor {
  font-weight: 400;
  font-size: 14px;
  color: #81c14b;
  padding-left: 1%;
}

.CounTriesTextColor {
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
  grid-gap: 10px;
  gap: 10px;
  margin: 1%;
}

.CountriyThisFilter {
  margin-right: 10rem;
}

.TrendingPeoplethistext {
  font-weight: 600;
  font-size: 13px;
  color: #4d4d4d;
}

.FollwerThisFontsize {
  font-weight: 400;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.FollwerThisFontsize {
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #81c14b !important;
}

.RecentThisText {
  color: rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  padding-top: 3%;
}

.backgroundColorThiswhite img {
  opacity: 0.5;
}

.PeopleThisGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  /* grid-gap: 1px; */
}

.padingThisblock {
  padding-left: 0px;
}

.TrendingPeoplethistexttwo {
  padding-left: 2%;
  padding-top: 1%;
  font-weight: 500 !important;
  font-size: 15px !important;
  color: #222222 !important;
}

.TrendingTagsBorders {
  background: rgba(129, 193, 75, 0.05);
  border-top: 2px solid rgba(129, 193, 75, 0.5);
  border-radius: 0px 0px 20px 20px;
  width: 55px;
  height: 59px;
}

.PeopleFollowersThis {
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 0.01em;
  font-weight: 600;
  font-size: 13px;
}

.PilesThistags {
  font-weight: 500;
  font-size: 12px;
  color: #81c14b;
  /* padding: 9px;
  padding-top: 12px; */
}

.MsgThisIcon .active svg,
.MsgThisIcon :hover svg {
  stroke: #f6f7f8;
}

.SeemoreThisTrendiingPeople {
  background: rgba(129, 193, 75, 0.05);
  border-radius: 10px;
  width: 232px;
  color: rgba(129, 193, 75, 0.7);
  height: 44px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  padding-top: 12px;
}

.GridButtonView {
  color: #ffffff;
  background-color: #81c14b;
}

.Thisiscaptiontext {
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 77%;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  color: #000000;
  height: 40px;
}

.TagThisButtonVideo {
  position: absolute;
  top: 42%;
  left: 38%;
  text-align: center;
}

.TagThisTopButtonVideo {
  position: absolute;
  top: 49%;
  left: 38%;
  text-align: center;
}

.ThisssscaptionVideo {
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 74%;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  color: #000000;
  height: 40px;
}

.ThisiscaptionVideo {
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 76%;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  color: #000000;
  height: 40px;
}

.TagTrendingTitleThisbuttonVideo {
  /* background-color: #e9e9e9; */
  position: absolute;
  width: 100%;
  bottom: 0%;
  border-radius: 0 0 10px 10px;
}

.TrendThisPlayButton {
  position: absolute;
  left: 46px;
  top: -5px;
}

.MicThisPosgition {
  position: absolute;
  left: 43px;
  top: -8.5px;
}

.TrendThisTopP {
  position: absolute;
  top: 6px;
}

.MediaThisVideo {
  position: absolute;
  top: 10%;
  left: 2%;
  text-align: center;
}

.Goliveleft {
  position: absolute;
  top: 41px;
  font-size: 8px;
  left: 30px;
}

.MicThisImg {
  position: absolute;
  top: 58px;
  left: 48px;
}

.BizPageCategory {
  font-weight: 500;
  font-size: 12px;
  color: #4d4d4d;
  letter-spacing: 0.01em;
}

.GridThreatThisText1 {
  position: absolute;
  top: 130px;
  right: 8px;
  background: #ff822e;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.divcolorThis {
  position: absolute;
  bottom: 8px;
  background: #ffffff;
  opacity: 0.5;
}

.GridThreatThisText2 {
  position: absolute;
  top: 130px;
  right: 8px;
  background: #ffd600;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.GridThreatThisText3 {
  position: absolute;
  top: 130px;
  right: 8px;
  background: #ff822e;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.EntertainmentThisText {
  background: rgba(19, 18, 18, 0.2);
  border: 1px solid #ffffff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 11px !important;
  text-align: center;
  width: 110px;
  height: 27.33px;
  padding-top: 5px;
}

button.btn.btn-primary.ColorThisButtonpadding.btn-xs {
  padding: revert;
  text-transform: capitalize;
  font-size: calc(11px + (12 - 11) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
}

.ColorThisButtonpadding svg :hover {
  color: white !important;
}

.xxyyzz:hover {
  color: #fff !important;
  background-color: #81c14b !important;
  border-color: #81c14b !important;
  stroke: #ba1c1c;
  fill: #ba1c1c;
}

.msg-btn {
  stroke: #ffffff !important;
  fill: #ffffff !important;
  color: #ffffff !important;
}

.MusicThisIcon {
  position: absolute;
  /* align-items: center; */
  text-align: center;
  left: 62px;
  top: 82px;
}

.msgThisiconfont {
  font-weight: 600;
}

.css-ryrseu-MuiRating-root {
  /* display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox; */
  display: inline-flex;
  position: relative;
  /* font-size: 1.5rem; */
  color: #faaf00;
  /* cursor: pointer; */
  text-align: left;
  -webkit-tap-highlight-color: transparent;
  /* pointer-events: auto !important; */
}

.RatingThisWidth {
  font-size: 2rem !important;
  padding-top: 7px;
}

.Video_Treanding_card {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
  padding-top: calc(var(--bs-gutter-x) / 2);
  padding-bottom: calc(var(--bs-gutter-x) / 2);
}

.Potrate_treanding_img {
  position: relative;
}

.BizpageThisimg {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px 5px 0px 0px;
}

.Potrate_treanding_img img {
  object-fit: cover;
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.Treanding_caption_section {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: rgb(13, 13, 13);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MicThisNum {
  display: flex;
  position: absolute;
  top: 7%;
  left: 5%;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  grid-gap: 3px;
  gap: 3px;
}

.TreandingThisGame {
  align-items: flex-start;
  padding: 12px 20px;
  background: rgba(0, 0, 0, 0.6);
  /* height: 65px; */
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.TreandingThisGames {
  align-items: flex-start;
  padding: 12px 20px;
  /* background: rgba(0, 0, 0, 0.6); */
  /* height: 65px; */
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.TreandingThisGamess {
  align-items: flex-start;
  padding: 6px 20px;
  background: rgba(0, 0, 0, 0.6);
  /* height: 65px; */
  border-radius: 10px 10px 0px 0px;
  color: #ffffff;
  position: absolute;
  top: 10px;
  width: 100%;
  height: 4.2rem;
}
.TreandingThisGamesss {
  align-items: flex-start;
  padding: 6px 20px;
  background: rgba(0, 0, 0, 0.6);
  /* height: 65px; */
  border-radius: 10px 10px 0px 0px;
  color: #ffffff;
  position: absolute;
  top: 6.5rem;
  width: 400px; /* You can adjust this value as needed */
  height: 4.3rem;
}

.TreandingThisEvent {
  align-items: flex-start;
  padding: 5px 20px;
  background: rgba(0, 0, 0, 0.6);
  /* height: 71.11px; */
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.TreandingRecommendations {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  height: 50.11px;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  padding: 5px;
  width: 100%;
  grid-gap: 1px;
  gap: 1px;
}

.short-recommendation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  height: 50px;
  color: #ffffff;
  margin-top: "5px";
  /* position: absolute; */
  /* bottom: 0; */
  padding: 5px;
  width: 100%;
  grid-gap: 1px;
  gap: 1px;
}

.ModalThisSize .modal-content {
  width: 71% !important;
}

.PaidThisImg {
  position: absolute;
  top: -52%;
  right: 0%;
}

.APPLYThisWidth {
  width: 24%;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 1px !important;
  pointer-events: none;
  border-style: solid;
  border-width: 0px !important;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.ChooseThisTrendingSong {
  background: #f7f7f7;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  font-weight: 600;
}

.Treanding_bottom_section {
  width: 100%;
  height: 64.56px;
  background: #f7f5f3;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* .diagonalthisgradient {
  background-color: #362f2f !important;
  width: 100% !important;
  height: 46% !important;
  border-radius: 5px !important;
} */

.diagonalthisgradient img {
  opacity: 0.2;
  /* background: radial-gradient(circle at center, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)); */
}

.Treanding_icon_section_forCpunt {
  background: rgba(0, 0, 0, 0.25);
  /* padding: 8px 11px; */
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: translate(5%);
          transform: translate(5%);
  margin-right: 6px;
  cursor: pointer;
}

.Treanding_icon_liked {
  background: #81c14b;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transform: translate(5%);
          transform: translate(5%);
  margin-right: 8px;
  cursor: pointer;
}

.Settings_tab_top_data {
  margin-right: 85px !important;
}

/* .polluThisserimg {
  transform: translateY(5px);
} */

.scrollbartopandtrend {
  height: 304px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.ArticleShowdo {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.TabthisMediaQurey {
  width: 100%;
}

.circularCorner {
  border-radius: 4px 4px 4px 4px;
}

.image-with-gradient {
  position: relative;
  display: inline-block;
}

.image-with-gradient:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 19.79%,
      rgba(0, 0, 0, 0) 85.42%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    linear-gradient(360deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 49.78%);
  border-radius: 4px;
}

.gallery-img-sublink-this-bizpage-shotz {
  position: absolute;
  right: -10px !important;
  z-index: 2 !important;
}

.bizpagefordropdownmenu.tops-dropdown {
  -webkit-transform: translate3d(-130px, 60px, 10px) !important;
          transform: translate3d(-130px, 60px, 10px) !important;
}

.on-hover-bg:hover {
  background-color: #80c14b35 !important;
  cursor: pointer;
  transition: 0.3s all;
}

.on-hover-bg-border:hover {
  background-color: #aaf07235 !important;
  border-color: #81c14b !important;
  cursor: pointer;
  transition: 0.5s all;
}

.tabs-top-trending {
  background-color: #e6f3db;
  border-radius: 5px;
  /* width: 211px; */
}

.slick-slide.slick-active.auto-wid {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.right-triangle {
  width: 0;
  height: 0;
  border-right: 50px solid transparent;
  border-left: 1px solid #81c14b;
  border-top: 50px solid #81c14b;
}

.rotated-text {
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
}

.trending-number-location {
  position: "absolute";
  top: "8px";
  left: "4px";
  z-index: "5";
  font-size: "12px";
}

.trending-triangle {
  position: "absolute";
  top: "0px";
  z-index: "4";
  left: "0";
}

.width-for-dropdown {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  z-index: 10;
}

.date-time-fs {
  font-size: calc(
    11px + (14 - 13) * ((100vw - 320px) / (1920 - 320))
  ) !important;
}

.BizPageCategory.match {
  font-weight: 600;
  font-size: 12px;
  color: #4d4d4d;
  letter-spacing: 0.01em;
}

.BizPageCategory.count {
  font-weight: 600;
  font-size: 13px;
  color: #4d4d4d;
  letter-spacing: 0.01em;
}

.poll-text {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: rgba(77, 77, 77, 1);
}

.user-profile:hover {
  text-decoration: underline;
  cursor: pointer;
}

.text-muted.light-for-date {
  color: rgba(0, 0, 0, 0.5) !important;
}

.bizpagefordropdownmenu.dropdownLocation {
  -webkit-transform: translate3d(-180px, 43px, 10px) !important;
          transform: translate3d(-180px, 43px, 10px) !important;
}

.bizpagefordropdownmenu.dropdownLocation.dropdown-for-user-specific {
  -webkit-transform: translate3d(-160px, 43px, 10px) !important;
          transform: translate3d(-160px, 43px, 10px) !important;
}

.video {
  object-fit: cover;
}

.marquee-wrapper {
  width: 100%;
  padding: 0px 20px;
}

.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  -webkit-animation: marquee 10s linear infinite;
          animation: marquee 10s linear infinite;
}

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes marquee {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

.for-scroll {
  max-height: "150px";
  overflow-y: "auto";
}

@media only screen and (max-width: 375px) {
  .TabthisMediaQurey {
    width: -webkit-max-content;
    width: max-content;
  }
}

@media screen and (max-width: 1200px) {
  .rem-beyond-web {
    display: none !important;
  }
}
.rounded-top-right {
  border-top-right-radius: 10px; /* Adjust the value to control the roundness */
}
/* ==========================text add css======================== */
.text {
  display: flex; /* Use Flexbox to control layout */
  font-size: 13px;
}

.gray-text {
  color: #4d4d4d;
}
.post {
  font-size: 13px;
}
/* ===============================375 and 667======================== */
@media (min-width: 375px) and (min-height: 667px) {
  .filterbtn {
    margin-top: -26px !important;
    padding-left: 10px !important;
  }
}
/* ===============================360 and 740======================== */
@media (min-width: 360px) and (min-height: 740px) {
  .filterbtn {
    margin-top: -26px !important;
    padding-left: 10px !important;
  }
}
@media (min-width: 360px) and (min-height: 740px) {
  .svgtext {
    margin-top: -2.7px !important;
  }
}

/* ===============================820 and 1180======================== */
@media (min-width: 820px) and (min-height: 1180px) {
  /* .filterbtn {
    margin-top: -5px !important;
    padding-left: 10px !important;
  } */
  .toptext {
    text-wrap: nowrap;
  }
  .ArticleShowdos {
    display: block !important;
    /* margin-top: -5.8rem !important; */
  }
}

/* ===============================970 and 564======================== */
@media screen and (min-width: 970px) and (min-height: 564px) {
  .topepisode {
    margin-left: 11rem !important;
  }
  .profiles {
    height: 5rem !important;
  }
}

/* ===============================412 and 892======================== */
@media (min-width: 412px) and (min-height: 892px) {
  /* .Recom {
    margin-left: -2rem !important;
  }
  .trand {
    margin-right: 9rem !important;
  } */
  .morehorizontal {
    display: flex !important;
    justify-content: end !important;
  }
  .toptexts {
    margin-left: -9rem !important;
  }
}
/* ===============================375 and 667======================== */
@media (min-width: 375px) and (min-height: 667px) {
  .toptexts {
    margin-left: -1rem !important;
  }
  .topepisode {
    margin-left: 10.5rem !important;
  }
  .country-section {
    top: 50px !important;
    z-index: 100 !important;
    left: -12rem !important;
  }
  .country-sections {
    top: 4rem !important;
    z-index: 100 !important;
    left: -16rem !important;
  }
}
/* ===============================360and 740======================== */
@media (min-width: 360px) and (min-height: 640px) {
  .country-section {
    top: 4rem !important;
    z-index: 100 !important;
    left: -14rem !important;
  }
}
@media (max-width: 375px) {
  .button-text {
    display: none;
  }
  .audio-img {
    font-size: 10px;
  }
  .filterbt {
    width: 20px !important;
    margin-top: -2rem !important;
  }
  .filtericon {
    margin-left: -8px !important;
  }
}
@media (max-width: 414px) {
  .button-text {
    display: none;
  }
  .filterbt {
    width: 20px !important;
    /* margin-top: -4rem !important; */
  }
  .filtericon {
    margin-left: -8px !important;
  }
  .textpoll {
    height: 8rem !important;
  }
  .topepisode {
    margin-left: 10.5rem !important;
  }
  .country-section {
    z-index: 100 !important;
    top: 6rem !important;
    left: -6rem !important;
  }
  .Countrynametext {
    margin-left: -6rem !important;
    margin-top: 2rem !important;
    /* position: fixed !important; */
  }
  .textdrop {
    margin-top: 2rem !important;
    /* position: fixed !important; */
    left: 1rem !important;
  }
}
@media (max-width: 414px) {
  .button-text {
    display: none;
  }
  .filterbt {
    width: 20px !important;
    margin-top: -3rem !important;
  }
  .filtericon {
    margin-left: -8px !important;
  }
  .textpoll {
    height: 8rem !important;
  }
  .topepisode {
    margin-left: 10.5rem !important;
  }
  .country-section {
    z-index: 100 !important;
    top: 6rem !important;
    left: -6rem !important;
  }
  /* .Countrynametexts {
    margin-left: -9rem !important;
    margin-top: 2rem !important;
    position: fixed !important;
  } */
  .textdrops {
    margin-top: 2rem !important;
    /* position: fixed !important; */
    left: 1rem !important;
  }
}
/* ===============================820and 1180======================== */
@media (min-width: 820px) and (min-height: 1180px) {
  /* .country-section {
    top: 4rem !important;
    z-index: 100 !important;
    left: -7rem !important;
  } */
  .textpoll {
    height: 8rem !important;
  }
  .users {
    text-wrap: wrap !important;
  }
}

/* ===============================768 and 1124======================== */
@media (min-width: 768px) and (min-height: 1024px) {
  .textpoll {
    height: 10rem !important;
  }
}

/* ===============================1280and 800======================== */
@media (min-width: 768px) and (min-height: 1024px) {
  .textpoll {
    height: 10rem !important;
  }
}
/* ===============================540 and 720======================== */
@media (min-width: 540px) and (min-height: 720px) {
  .textAstronomy {
    /* width: 100% !important; */
    /* padding-top: 2rem !important; */
  }
  /* .filterbt {
    margin-top: -3rem !important;
  } */
}
/* ===============================912 and 1368======================== */
@media (min-width: 912px) and (min-height: 1368px) {
  .msgbtn {
    margin-left: 4rem !important;
  }
}
/* ===============================768 and 1024======================== */
@media (min-width: 768px) and (min-height: 1024px) {
  .textAstronomy {
    height: auto !important;
    width: auto !important;
  }
  /* .podcastlist {
    margin-top: -103px !important;
  } */
  /* .light-for-date {
    display: none !important;
  } */
}
/* @media (min-width: 414px) and (min-height: 896px) {
  .btnmsgs {
    margin-left: 8.5rem !important;
    width: 16rem !important;
  }
} */
@media (min-width: 768px) and (min-height: 1024px) {
  .msgbtn {
    margin-left: 0.5rem !important;
  }
  .chevron {
    margin-left: -1.5rem !important;
  }
  /* .pollutext {
    height: 6.5rem !important;
  } */
}
/* ===============================1366 and 768======================== */
@media (min-width: 1366px) and (min-height: 768px) {
  .msgbtn {
    margin-left: 1.5rem !important;
  }
}
/* ===============================360 and 640======================== */
@media (min-width: 360px) and (min-height: 640px) {
  /* .country-sections {
    top: 4rem !important;
    z-index: 100 !important;
    left: -6rem !important;
  } */
  .msgbtn {
    margin-left: 2rem !important;
  }
}
/* ===============================1024 and 1366======================== */
@media (min-width: 1024px) and (min-height: 1366px) {
  /* .btnmsg {
    margin-top: 20px !important;
    margin-left: 0rem !important;
    width: 7rem !important;
  } */
  .moredown {
    margin-left: -3rem !important;
  }
  .paid-card {
    margin-top: -3px !important;
  }
}
/* Hide text for screens with a maximum width of 768px (adjust the width as needed) */
/* @media (max-width: 768px) {
  .ArticleShowdos {
    display: none;
  }
  .light-for-date {
    display: none;
  }
} */
/* @media (max-width: 768px) and (min-height: 1024px) {
  .ArticleShowdos {
    display: block;
  }
  .light-for-date {
    display: none;
  }
} */
/* @media (max-width: 540px) and (min-height: 720px) {
  .ArticleShowdos {
    display: block;
    margin-top: -6rem !important;
  }
  .light-for-date {
    display: none;
  }
} */
/* @media (max-width: 375px) and (min-height: 812px) {
  .ArticleShowdos {
    display: none;
  }
  .light-for-date {
    display: none;
  }
} */
/* @media (max-width: 768px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
} */
/* @media (max-width: 360px) and (min-height: 564px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
} */
/* @media (max-width: 414px) and (min-height: 896px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }

} */
/* @media (max-width: 390px) and (min-height: 844px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: none !important;
  }
} */
/* @media (max-width: 393px) and (min-height: 851px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: none !important;
  }
} */
/* =================================add media query 360 and 740================== */
/* @media (max-width: 360px) and (min-height: 740px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: none !important;
  }
} */
/* =================================add media query 1024 and 600================== */
/* @media (max-width: 1024px) and (min-height: 600px) {
  .morehorizontaldata {
  margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: block !important;
  }
  .light-for-date {
    text-wrap: nowrap !important;
  }
} */
/* =================================add media query 1024 and 600================== */
/* @media (max-width: 540px) and (min-height: 720px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: block !important;
  }
  .light-for-date {
    text-wrap: nowrap !important;
  }
} */
/* =================================add media query 412 and 914================== */
/* @media (max-width: 412px) and (min-height: 914px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: none !important;
  }
} */
/* =================================add media query 540 and 564================== */
/* @media (max-width: 540px) and (min-height: 564px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: none !important;
    margin-top: -5.8rem !important;
  }
  .textAstronomy {
    width: 100% !important;
  } */
/* .msgbtn {
    margin-left: 8.5rem !important;
    width: 16rem !important;
  } */
/* .light-for-date {
    display: none !important;
  }
  .usedtextin {
    margin-top: -5.8rem !important;
  }
} */
/* =================================add media query 375 and 667================== */
/* @media (max-width: 375px) and (min-height: 664px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: none !important;
    margin-top: -5.8rem !important;
  } */
/* .msgbtn {
    margin-left: 3.5rem !important;
     width: 16rem !important;
  }
} */

/* CSS stylesheet or equivalent */
/* @media (max-width: 768px) {
  .hide-on-small-screen {
    display: none;
  }
}
@media (max-width: 360px) {
  .hide-on-small-screens {
    display: none;
  }
} */

/* Default style for screens other than 820px */
.small-screen {
  display: none;
}
/* ===============280px and 653px ============== */
/* Media query for screens with a minimum width of 280px or greater */
@media screen and (min-width: 280px) and (max-width: 280px) {
  .media_card {
    width: 15rem;
  }
  .custom-text {
    font-size: 10px !important;
    color: #666;
  }
  .hide-on-small-screens {
    display: none;
  }
  .textpolls {
    text-wrap: nowrap !important;
  }
  .small-text {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 768px) {
  .textpolls {
    text-wrap: nowrap !important;
  }
  .hide-on-small-screens {
    display: none;
  }
  .small-text {
    display: none;
  }
}
@media (max-width: 360px) {
  .usertextlist {
    display: none !important;
    text-wrap: wrap !important;
  }
}
/* @media (min-width: 360px) {
  .usertextlist {
    display: block !important;
    text-wrap: wrap !important;
  }
} */
@media (max-width: 768px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .podcastlist {
    margin-top: -86px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 820px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .podcastlist {
    margin-top: -86px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 1200px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .podcastlist {
    margin-top: -86px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 1200px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .audio-text {
    /* margin-top: -22px;
    margin-right: 13rem; */
    position: absolute;
    margin-left: -36px;
  }
}
@media (max-width: 1200px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .btn-audio {
    margin-right: 6rem;
  }
}

@media (max-width: 1075px) and (max-width: 768px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .rem-mob-views {
    display: none !important;
  }
  .podcastlist {
    /* margin-top: -62px; */
    margin-left: 49%;
    display: flex !important;
    align-items: center;
  }
}

@media (max-width: 360px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .morehorizontaldata {
    margin-top: -62px;
    margin-left: 49%;
    display: flex !important;
    align-items: center;
  }
}
@media (max-width: 375px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .morehorizontaldata {
    margin-top: -65px !important;
    margin-left: 49%;
    display: flex !important;
    align-items: center;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .hide-podcast {
    display: none !important;
  }
}
/* @media (min-width: 375px) and (max-width: 812px) {

  .textpodcasts {
    margin-top: -80px !important;
  }
} */
@media (min-width: 768px) and (max-width: 1024px) {
  .small-text {
    display: none;
  }
}
/* @media (max-width:834) {
  .Treanding_bottom_sections {
   position: absolute;
   bottom: 0px!important;
  }
} */
/* Apply styles for screens with a width between 834px and 984px */
/* @media screen and (min-width: 834px) and (max-width: 984px) {
  .textprofile {
    font-size: 10px;
  }
} */
/* @media screen and (min-width: 768px) and (max-width: 968px) {
  .textprofile {
    font-size: 10px;
  }
} */
@media (min-width: 768px) and (max-width: 1200px) and (min-height: 768px) and (max-height: 1200px) {
  .hide-text {
    display: none !important;
  }
}

.Search_NavItem {
  /* margin: 5px; */
  background-color: #e6f3db;
  border-radius: 5px;
  width: 211px;
}

.MarketplaceThisWidth {
  width: 159px !important;
}

.RecommendationThisWidth {
  width: 164px !important;
}

.peopletabstext {
  color: #313636;
  font-weight: 500;
  font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
}

.People_designation {
  /* margin-left: 3px; */
  color: #828282;
  font-size: 12px;
  margin-top: 2%;
}

.People_Atlanta {
  color: #828282;
  font-size: 16px;
  /* margin-left: 3px; */
  margin-top: 2%;
}

.People_Sociomate {
  font-size: 12px !important;
  margin-top: 8px !important;
  margin-left: 3%;
  margin: 5px 4px 0px !important;
}

.Article_flitericon {
  color: #81c14b;
  margin: 7%;
  margin-top: 12px;
}

.peopleroundedborder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 107px;
  height: 41px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #81c14b;
  border-radius: 5px;
  margin-left: 20%;
}

.BizPage_text_center {
  font-style: normal;
  font-weight: 500;
  font-size: 18px !important;
  color: #4d4d4d !important;
  margin: 1px 0 4px !important;
  text-align: center;
}

.Bizpagetabs_Sociomate_Center {
  font-style: normal;
  font-weight: 600;
  font-size: 13px !important;
  color: #4d4d4d !important;
  width: 29px;
  height: 33px;
  margin-top: 1%;
}

.dropdownThisitem {
  font-size: 12px !important;
}

.Bizpagetabsfortextcenter {
  font-style: normal;
  font-weight: 400;
  margin: 0px 0 0px !important;
  font-size: 14px !important;
  color: #808080 !important;
  text-align: center;
  font-size: 12px !important;
}

.People_Filter {
  color: #81c14b;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  width: 40px;
  height: 21px;
  margin-top: 3%;
}

.ShotzThisicon {
  top: 2px;
  right: 3px;
}

.GroupTabsThisPage {
  width: 255px;
  padding: 4px;
}

.This-gallery-cont img {
  width: 40px !important;
}

.gallery-img-sublink-this-bizpage {
  position: absolute;
  right: -10px !important;
  top: 4px !important;
  z-index: 2 !important;
}

.BizpageThisRowSize {
  width: 255px !important;
  padding: 4px;
}

.FollowingThisButtonColor {
  background: rgba(129, 193, 75, 0.2);
  border: none;
}

.People_Cancel_button {
  font-family: "Poppins";
  font-style: normal;
  height: 40px;
  font-size: 17px;
  background: rgba(129, 193, 75, 0.2);
  border-radius: 5px;
  border: none;
  color: rgba(0, 0, 0, 0.5);
}

.BizPage_Follow {
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  width: 111px;
  height: 32px;
  color: #808080;
}

.BizPage_Invite {
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  width: 96px;
  height: 32px;
  color: #808080;
}

.Group_Requested {
  background: rgba(129, 193, 75, 0.1);
  border: 1px solid #d1d7cd;
  border-radius: 5px;
  color: #81c14b;
  width: 125px;
  height: 38px;
}

.textThisSocioMate {
  color: #81c14b;
  font-weight: 500;
  font-size: 12px;
}

.ButtonThisHeaderSearchbar {
  background: rgba(129, 193, 75, 0.2) !important;
  border-radius: 5px !important;
  color: #81c14b !important;
  border: none;
  /* font-weight: 500 !important;
  font-size: 12px !important; */
  font-weight: 500;
}

.SearchThisFollow {
  background: rgba(129, 193, 75, 0.1);
  border: 1px solid #d1d7cd;
  border-radius: 5px;
  color: #81c14b;
  width: 96px;
  height: 36px;
  margin-left: 70%;
}

.GroupTabs_Join_Button {
  color: #ffffff;
  background-color: #81c14b;
  width: 200px;
  height: 38px;
  text-align: center;
  border: none;
  border-radius: 5px;
}

/* .Music {
  color: white;
  margin-right: 30px;
} */
.songs {
  color: white;
}

/* .top-gallery-cont, */
.Music-gallery-cont {
  position: absolute;
  bottom: 80px;
  display: flex;
  color: #ffffff;
  margin-bottom: -42px;
}

/* .border_size{
    border: 1px;
    width: 250px;
  } */
.card_size {
  width: 200px;
}

.Shotzs {
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 20.83%,
      rgba(0, 0, 0, 0) 77.14%,
      rgba(0, 0, 0, 0.5) 100%),
    url;
  border: 1.5px solid rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-sizing: border-box;
}

.Icon {
  margin-right: 50px;
}

.rounded-top {
  height: 230px;
  width: 362px;
  border: 1px;
  border-radius: top;
}

.controlledButtonThisDropdown .btn {
  padding: 3px 6px !important;
  text-transform: capitalize;
  font-size: calc(11px + (12 - 11) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
}

.shotztabssongs {
  /* font-family: 'Poppins'; */
  font-size: 11px;
  font-weight: 600;
  /* width: 154px; */
  height: 40px;
  margin-left: 3%;
  color: #ffffff;
  /* margin-left: 6%; */
}

.Badge {
  background: #dedede;
  border-radius: 20px;
  font-size: 10px;
  color: #4d4d4d;
}

.Badge_size {
  font-size: 20px;
}

.rounded_border {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 2px;
  margin-top: 15px;
  border-radius: 8px;
}

.toptitle1 {
  margin-left: 6px;
  /* font-family: "Poppins";
  font-style: normal; */
  font-weight: 500;
  font-size: 16px;
}

.biz-adminprofile-blk img {
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.biz-adminprofile-blk {
  position: absolute;
  bottom: -40px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

.posts {
  color: #81c14b;
  margin-top: 5px;
}

.Shotzs_img {
  height: 50px;
  width: 60px;
  margin-left: 50px;
  border-radius: 3px;
  margin: 2px;
}

/* .Shotzsss {
  margin-left: 10px;
} */

/* .marzin {
  margin-left: 13px;
}

.larzin {
  margin-left: 13px;
} */

/* .toptitleclass {
  margin-left: 71px;
} */

.Shotzsss span {
  position: absolute;
  color: #fdfbfb;
  margin: 20px;
}

.NumFormatterThisNum {
  font-size: 1px;
}

/* .row posts {
  --bs-gutter-x: -1.5rem;
} */

.play {
  width: 67px !important;
}

.NumThiscount {
  margin-top: 26%;
}

.fontThiscolor {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 13px;
}

.RecommendationThisbutton {
  /* background-color: #e9e9e9; */
  position: absolute;
  width: 100%;
  bottom: 9%;
  border-radius: 0 0 10px 10px;
}

.RecommendationThisbuttonSeoundcard {
  /* background-color: #e9e9e9; */
  position: absolute;
  width: 100%;
  bottom: 17%;
  border-radius: 0 0 10px 10px;
}

.TagTrendingTitleThisbuttonSeoundcard {
  /* background-color: #e9e9e9; */
  position: absolute;
  width: 100%;
  bottom: 0%;
  border-radius: 0 0 10px 10px;
}

.Readmore {
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  margin-left: 8px;
  color: #4d4d4d;
}

.TextcolorThisReadMore {
  color: #0066ff;
  font-weight: 700;
  font-size: 11px;
}

.PollThisNumber {
  color: #81c14b;
  font-weight: 600;
  font-size: 18px;
}

.PollThisPosted {
  margin: 6px;
  color: #808080;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}

.posteds {
  margin: 5px;
  color: #4d4d4d;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
}

.Celebration {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 16px;
  color: #231f1f;
}

.Musiccelebration {
  color: #81c14b;
  /* font-style: normal; */
  font-weight: 400;
  font-size: 12px;
}

.paragaraf {
  color: #808080;
  font-size: 12px;
  margin-top: 17px;
}

/* .Dropdown_Menu{
    margin-right: 20px;
  } */
.FrameImg {
  height: 133px;
  text-align: center;
  margin-left: 4px;
}

.Confucious {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.thoughtoftheday {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #d35cd6;
}

.podcastImg {
  height: 200px;
}

.PodcastIcon {
  position: absolute;
}

.podcastLeft {
  position: absolute;
  top: 5px;
  left: 9px;
}

.UncontrolledButtonDropdowntopright {
  position: absolute;
  top: -2px;
  right: -11px;
  font-size: 18px;
}

.textThisColorLearnmore {
  color: #1066ad;
}

.podcasRight {
  position: absolute;
  top: 142px;
  left: 0px;
  background: #f9a321;
  border-radius: 0px 30px 30px 0px;
  width: 66px;
  height: 20px;
  align-items: center;
  padding: 1px;
  padding: 0px 4px 0px 6px;
}

.podcastftBottom {
  position: absolute;
  bottom: 8px;
  left: 9px;
  color: white;
}

.podcastRightBottom {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.micnumber {
  color: #ffffff;
  margin-top: 3px;
}

.paidimg {
  margin-top: 160px;
  position: absolute;
  display: flex;
}

.Pappapoddentext {
  color: white;
  margin-top: 15px;
  margin-left: 8px;
}

.shotztabssongstext {
  margin-right: 3%;
}

.paid {
  position: absolute;
  margin-top: 101px;
  margin-left: 130px;
}

.Miccolor {
  color: white;
}

.numformattercolor {
  background: rgba(255, 255, 255, 0.9);
}

.RecommendationThisTextColor {
  background: rgb(0 0 0 / 33%);
}

.TagTrendingThisTextColor {
  background-color: #e9e9e9;
  border-radius: 0 0 9px 9px;
}

.ThreatTabs_img {
  height: 259px;
  width: 410px;
}

.divcolor {
  background-color: #fffbe5;
  height: 55px;
  width: 298px;
}

.trendingtitle {
  color: #287af4;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin: 3px;
}

.Recommend {
  height: 42px;
  width: 303px;
  position: absolute;
  margin-top: 207px;
}

.recommendation {
  font-weight: 400;
  font-size: 13px;
  color: #808080;
}

.User_Recommend {
  height: 48px;
  width: 303px;
}

/* .btn-primary {
  color: #ffffff;
  background-color: #81c14b;
  border-color: #81c14b;
  height: 37px;
} */
/* .group-slide-box .group-img {
  width: 100%;
  height: 197px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
} */
/* .time {
  color: #81c14b;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
} */
.moreview {
  position: absolute;
  margin-bottom: 10px;
  margin-left: 205px;
  color: #30302f;
  box-shadow: #f6f6f6;
}

.FilterThisButtonDropdown {
  margin: -118% !important;
  /* margin-right: 34% !important; */
  margin-top: -6% !important;
  width: 200%;
}

.LocationThisIcon {
  position: absolute;
  top: 56px;
  right: 15px;
}

.Naba_Keleba {
  color: #0e0d0d;
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-top: 10px;
}

.accordion-button::after {
  /* background: #f6f6f6; */
  /* box-shadow: 0px 0px 9.05714px rgba(0, 0, 0, 0.25); */
  border-radius: 5px;
  position: absolute;
  right: 22px;
  /* top: 28px; */
  flex-shrink: 0;
}

.market_place {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #4d4d4d;
  margin-top: 5px;
}

.Marketplace_Moreview {
  position: absolute;
  color: #ffffff;
  margin-left: 200px;
  margin-top: 5px;
}

.Marketplace_Eye_view {
  position: absolute;
  margin-top: 4px;
  margin-left: 6px;
  color: #ffffff;
}

.Marketplace_Count {
  margin-left: 5px;
  margin-top: 2px;
}

.Marketplace_tabs_text {
  /* margin-left: 5px; */
  color: #16c31e;
}

.Marketplace_text {
  color: #808080;
  font-weight: 300;
  font-size: 12px;
}

.TagTrenThisText {
  font-weight: 400;
  font-size: 15px;
  color: #808080;
}

.EventTabs_text_date {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  margin-top: 10px;
  padding-left: 2%;
  color: #81c14b;
}

.EventTabs_MoreHorizontal {
  border-radius: 5px;
  margin-right: 8px;
}

.Event_Tabs_pic {
  position: absolute;
  margin-left: 108px;
  margin-top: 7px;
}

.MapThisBessemer {
  padding-left: 2%;
  padding-top: 3%;
}

.Event_Text {
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
  text-align: start;
  padding-left: 2%;
}

.GoingThis {
  padding-left: 2%;
  padding-top: 2%;
}

.GoingThisInterested {
  padding-top: 2%;
}

.EventTabs_Bessemer {
  color: #808080;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-left: 1%;
}

.Event_Tabs_usersicon {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #808080;
  margin-left: 1px;
}

.Article_Going_Button {
  background: #f0f0f0;
  border-radius: 5px;
  border: none;
  color: #808080;
}

.Article_Button_Interested {
  background: #81c14b;
  border-radius: 5px;
  border: none;
  height: 42px;
  width: 104px;
  margin-bottom: 6px;
  color: #ffffff;
}

.ArticleTabs_date_month {
  color: #808080;
  margin-left: 6px;
  margin-top: 13px;
}

.Article_Sports {
  margin-right: 5px;
  color: #808080;
  margin-top: 13px;
}

.ArticleTabs_Liverpool {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
  margin-left: 2%;
}

.Article_Liverpool_Champions {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #808080;
  margin: 6px;
  text-align: justify;
  text-align: start;
}

.Artical_tabs {
  position: absolute;
  color: #4d4d4d;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  display: flex;
  margin-left: 94px;
  text-align: center;
  margin-top: 8px;
  height: 34px;
}

.Article_like_thumble {
  color: #4d4d4d;
}

.TheatTabs_avatar {
  margin-bottom: 10px;
}

.headerpagefor_SocioMate {
  margin-left: 107px;
  color: #81c14b;
  font-weight: 500;
  font-size: 12px;
}

.headerpageThisFollowing {
  margin-left: 110px;
  color: #81c14b;
  font-weight: 500;
  font-size: 12px;
}

.header_for_button {
  border: none;
  margin-left: 53px;
  color: #81c14b;
  background: rgba(129, 193, 75, 0.2);
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 28px;
}

.headertextcolor {
  color: #81c14b;
  font-weight: 500;
}

.header_popup {
  /* margin: 10px; */
  /* background-color: red; */
  position: absolute;
}

/* .custom-dropdown .dropdown-menu {
  top: 3px;
} */
.inputgroupfor {
  background: #f6f6f6;
  border-radius: 5px;
  color: #f6f6f6;
}

.form-controler {
  /* width: 100% !important; */
  height: 39px;
  border-radius: 5px;
  color: #f6f6f6;
  padding-left: 30px;
  border: none;
  background: #f6f6f6;
}

.peoplesearchbar {
  width: 467px;
  margin-top: 15px;
}

.This {
  height: 249px;
  width: 303px;
}

.AlltabThisGroup.bizpagelink-blk {
  margin: 0 0 0px !important;
}

.headerforxicon {
  position: absolute;
  right: 23px;
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  color: #ffffff;
}

.Ariticalright {
  position: absolute;
  top: 6px;
  right: 5px;
  color: white;
}

.Articalimgright {
  position: absolute;
  top: 6px;
  right: 25px;
  color: white;
}

.MessageCircleright {
  position: absolute;
  right: 50px;
  top: 1px;
  color: white;
}

.TopRight_Section {
  position: absolute;
  top: 8px;
  right: 1px;
  display: flex;
  flex-direction: row;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  align-items: center;
  padding: 5px 10px;
  grid-gap: 15px;
  gap: 15px;
}

.TopRight_Section p {
  font-weight: 400;
  font-size: 12px;
  color: #4d4d4d;
}

.page-center_slider {
  overflow-x: scroll;
  overflow-y: hidden !important;
  flex-wrap: nowrap !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #ffffff;
  background-color: #81c14b;
}

.Peopletabforseeallcolor {
  color: #81c14b;
}

.drops.custom-dropdown.dropdown-menu {
  top: -1px !important;
  border: none;
  padding: 0;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
  min-width: 16rem;
  z-index: 2;
}

.SerachThisButtonleft {
  position: absolute;
  top: 13px;
  left: 21px;
}

/* .dropdown-menus {
  position: absolute;
  will-change: transform;
  top: -125px;
  left: -145px;
  transform: translate3d(0px, 46px, 0px);
  min-width: 100px !important;
} */
.marketplaceright {
  position: absolute;
  top: -5px;
  right: -11px;
  color: white;
}

.Marketplacecenter {
  width: 239px;
  height: 27px;
  font-size: 17px;
  color: #4d4d4d;
  margin-top: 5px;
}

.EventforTabsright1 {
  background: #ff9922;
  width: 115px;
  height: 27px;
  position: absolute;
  color: #ffffff;
  right: 0px;
  top: 1%;
  border-radius: 20px 0px 0px 20px;
}

.EventforTabsright2 {
  background: #ff9922;
  width: 82px;
  height: 27px;
  position: absolute;
  color: #ffffff;
  right: 0px;
  top: 1%;
  border-radius: 20px 0px 0px 20px;
}

.EventforTabsright3 {
  background: #ff9922;
  width: 157px;
  height: 27px;
  position: absolute;
  color: #ffffff;
  right: 0px;
  top: 1%;
  border-radius: 20px 0px 0px 20px;
}

.EventforTabsright4 {
  background: #ff9922;
  width: 126px;
  height: 27px;
  position: absolute;
  color: #ffffff;
  right: 0px;
  top: 1%;
  border-radius: 20px 0px 0px 20px;
}

.InterestedThisColor {
  background: #f0f0f0;
  color: #ef8100;
  border: none;
}

.btnThisheight {
  height: 40px;
  width: 124px;
}

.peopleoftext {
  color: #313636;
  font-weight: 500;
}

.peoplebuttonright {
  right: 14px;
}

/* .AllThisTextalign {
  padding-right: 2%;
} */

.peopletabstext {
  font-style: normal;
  font-weight: 500;
  color: #4d4d4d;
  font-size: medium;
}

.MarketplaceThis {
  font-weight: 500;
  font-size: 17px;
  color: #4d4d4d;
}

.Shotztabstext {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
}

.dropdownitem.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #f9fbf7 !important;
}

.bizpagefordropdownmenu {
  position: absolute;
  will-change: transform;
  top: -34px !important;
  /* left: -59px !important; */
  -webkit-transform: translate3d(-182px, 68px, 10px) !important;
          transform: translate3d(-182px, 68px, 10px) !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px !important;
}

.UncontrolledButtonDropdowntoprightSearchBar {
  position: absolute;
  top: 3px;
  right: 9px;
  font-size: 18px;
}

.grouptittlecenter {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #4d4d4d;
  margin-top: 6%;
  text-align: center;
}

.grouptabsforpublic {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4d4d4d;
  margin-top: 3%;
  margin-left: 12%;
}

.grouptabsformembers {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4d4d4d;
  margin-top: 2%;
}

.groupThisbizpage {
  position: relative;
  background-color: #287af4 !important;
  flex: 1 1 auto;
}

/* .ThisBizpageAvatar {
text-align: center;
} */
.ThoughtThisbackgroundcolor {
  background-color: #871515;
  border-radius: 10px;
  margin: 6px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12);
}

.ThoughtThisbackgroundcolor1 {
  background-color: #ffffff;
  border-radius: 10px;
  margin: 6px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12);
}

.ThoughtThisbackgroundcolor2 {
  background-color: #00a3ff;
  border-radius: 10px;
  margin: 6px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12);
}

.ThisThoughtTabs {
  text-align: center;
  color: #f9fbf7;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 31px;
  padding: 12%;
  height: 166px;
}

.ThisThoughtTabs1 {
  text-align: center;
  color: #871515;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 31px;
  padding: 12%;
  height: 166px;
}

.ThisThoughtTabs2 {
  text-align: center;
  color: #ffe6b7;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 31px;
  padding: 12%;
  height: 166px;
}

.ThoughttabsthisLeftquotationIcon {
  position: absolute;
  margin-top: 11%;
  margin-left: 2%;
  font-size: 24px !important;
}

.ThoughttabsthisRightquotationIcon {
  position: absolute;
  /* margin-bottom: 6%; */
  margin-top: 20%;
  margin-left: 90%;
  font-size: 24px !important;
}

.mqText {
  font-size: 24px !important;
}

@media only screen and (max-width: 600px) {
  .ThoughttabsthisLeftquotationIcon {
    font-size: 14px;
  }

  .ThoughttabsthisRightquotationIcon {
    font-size: 14px;
    margin-left: 88%;
  }

  .mqText {
    font-size: 12px !important;
  }
}

.ThreatThistabs1 {
  background-color: #ffd600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 184px;
  padding: 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 2%;
}

.ThreatThistabs2 {
  background: #ff822e;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 184px;
  padding: 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ThreatThistabs3 {
  background: #ff1010;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 201px;
  padding: 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.Alartthispagetext1 {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #222222;
  padding: 14px;
}

.Alartthispagetext2 {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
  padding: 14px;
}

.Alartthispagetext3 {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
  padding: 14px;
}

.TagThisTrendingTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #4d4d4d;
}

.TagThisCountPost {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #808080;
}

.TagThisTitle {
  font-weight: 500;
  font-size: 15px;
  color: #4d4d4d;
}

.TagThisAmmotivation {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #287af4;
  margin: 6px;
}

.TagThistrendingtext {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #808080;
}

.TagThisButton {
  background-color: #e9e9e9;
  position: absolute;
  width: 100%;
  bottom: 0%;
  border-radius: 0 0 10px 10px;
}

.TagThisButtonPlay {
  position: absolute;
  top: 48%;
  left: 38%;
  text-align: center;
}

.buttonThis.btn {
  padding: 17px 20px !important;
}

.ButtonThisTagTrending.btn {
  padding: 10px 13px !important;
  text-transform: capitalize;
  font-size: calc(11px + (12 - 11) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
}

.ThreatThisText1 {
  position: absolute;
  top: 94px;
  right: 8px;
  background: #ff822e;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.ThreatThisText2 {
  position: absolute;
  top: 94px;
  right: 8px;
  background: #ffd600;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.ThreatThisText3 {
  position: absolute;
  top: 76px;
  right: 8px;
  background: #ff822e;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.ThreatThisMild1 {
  font-weight: 600;
  color: #ffffff;
  width: 43px;
  text-align: -webkit-center;
}

.NumThisFormatter {
  position: absolute;
  left: -6px;
  top: 65%;
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  /* background-color: rgba(255, 255, 255, 0.456); */
}

.ThreatThisMild2 {
  font-weight: 600;
  color: #ffffff;
  width: 93px;
  text-align: -webkit-center;
}

/* .MsgThisIcon {
  color: white;
} */

.ImgThisTransparent {
  width: 100%;
  height: auto;
}

.ThreatThisMild3 {
  font-weight: 600;
  color: #ffffff;
  width: 76px;
  text-align: -webkit-center;
}

.AllTagsThis.row {
  --bs-gutter-x: -0.5rem !important;
}

.GroupThisBlank {
  text-align: center;
}

.BadgeThisColor {
  background: rgb(229, 229, 229);
  border-radius: 17px;
}

.DropdownThisFilter {
  font-weight: 400;
  font-size: 16px;
  color: #4d4d4d;
}

.TextThiscolorWithin {
  font-weight: 500;
  font-size: 13px;
  color: #4d4d4d;
}

.MarketplaceThislist {
  font-weight: 500;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.TextThisHighestColor {
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
  padding: 7%;
}

.ThreattabsthisLeftquotationIcon {
  position: absolute;
  top: 122px;
  left: 17px;
  font-size: 18px;
}

.ThreatttabsthisRightquotationIcon {
  position: absolute;
  bottom: 107px;
  right: 25px;
  font-size: 18px;
}

.css-187mznn-MuiSlider-root {
  border-radius: 12px;
  box-sizing: content-box;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  color: #81c14b !important;
  -webkit-tap-highlight-color: transparent;
  height: 4px;
  width: 100%;
  padding: 13px 0;
}

/* @media (min-width: 375px) and (max-width: 600px) {
  .TagThisButton {
    background-color: #e9e9e9;
    position: absolute;
    width: 301px;
    bottom: 1%;
    border-radius: 0 0 10px 10px;
  }
} */
.poll-wrapper {
  width: 100%;
}
.content-wrapper {
  width: 100%;
  position: absolute;
  bottom: 0px !important;
}
.bookmark-post-card-image {
  width: 100%;
  height: -webkit-min-content;
  height: min-content;
}
.bookmark-content {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.bookmark-contentt {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.bookmark-content-caption {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  top: 14rem;
  font-size: 13px;
  padding: 10px;
  height: 118px;
  width: 100%;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
}
.bookmark-content-captionn {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  top: 13.1rem;
  font-size: 13px;
  padding: 10px;
  height: 131px;
  width: 100%;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
}
.bookmark-content-captions {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  top: 17.9rem;
  font-size: 13px;
  padding: 10px;
  height: 3rem;
  width: 100%;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
}
.bookmark-content-captionss {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  top: 18.2rem;
  font-size: 13px;
  padding: 10px;
  height: 3rem;
  width: 100%;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
}
.bookmark-Recommendations-content-captionss {
  background: #235363af;
  position: absolute;
  top: 19.5rem;
  padding: 7px;
  left: 0px;
}
.text-server {
  margin: -1.5rem !important;
}
.text-servers {
  margin-bottom: -6rem !important;
}
.SingleMedia_blk .bookmark-content .bookmark-content-tag {
  position: absolute;
  height: 20px;
}
.SingleMedia_blk .bookmark-content img {
  position: absolute;
  height: 50px !important;
  width: 50px !important;
  top: 18.23rem;
}
.singletext {
  margin: -1rem;
  margin-left: 0.1rem;
}
/* .ThatCard-bookmark {
    height: 70px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
    border-radius: 5px;

}

@media only screen and (max-width: 375px) {
    .ThatCard-bookmark {
        font-size: 14px;
    }

    .ThatCard-bookmark {
        font-size: 14px;
        margin-left: 88%;
    }

    .mqText {
        font-size: 12px !important;
    }
}

.Thatbookmarktext {
    width: 160px;
    height: 30px;
    left: 21px;
    top: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #4D4D4D;
    margin-left: 20px;
}

.Thatfilter-iconbookmark {
    width: 46px;
    height: 41px;
    right: 20px;
    top: 16px;
    background: rgba(129, 193, 75, 0.2);
    border-radius: 5px;
}
.thatbookmark_headerImg{
    left: -25px;
}
.thatheaderImgbookmark{
    left: -15px;
}



.Thatbookmark-icon {
    margin-left: 20px;
}

.Thatimage-Cardbookmark {
    position: absolute;
    padding: none;
    width: 700px;
    margin-left: 15%;
    
}

.ThatCard-Headerbookmark {
    background: #FFFFFF;
}

.ThatCard-Footerbookmark {
    background: #FFFFFF;
}

.ThattextHeading-bookmark {
    font-weight: 500;
    font-size: 20px;
    color: #4D4D4D;
    width: 455px;
    height: 30px;
}

.Thattext1-bookmarkparaghaph {
    font-weight: 400;
    font-size: 16px;
    color: #4D4D4D;
    margin-top: 10px;

}

.thatbookmark-span {
    color: #81C14B;
    margin-top: 10px;
}

.Thattext2-bookmarkparaghaph {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    color: #808080;
}

.bookmarksearchbar {
    width: 300px;
    margin-right: 15px;
}

.Thatbookmark-time {
    color: #81C14B;
    width: 85px;
    height: 21px;
}

.thatbookmark-likebutton {
    width: 35px;
    border-radius: 20px;
}

.Thatposteds {
    margin: 5px;
    color: #4d4d4d;

}

.Thatposteds-Like {
    margin: 5px;
    color: #81C14B;

}



.thatbookmark-Commentbox {
    margin-top: 5%;
    box-sizing: border-box;
    height: 40px;
    background: #F9FBF7;
    border: 1px solid rgba(126, 178, 98, 0.1);
    border-radius: 5px;
}

.ThatTime-bookmark {
    height: 22px;
    left: 70px;
    top: 1182px;
    background: rgba(126, 178, 98, 0.1);
    border-radius: 5px;
    color: #81C14B;
}

.thatcommentboxicons {
    position: relative;
    top: -32px;
    right: -603px;
}

.Card2-Bookmark {
    width: 677px;
    left: 0px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(129, 193, 75, 0.2);
    border-radius: 5px;
}

.Thatbutton-Section {
    background: #F6F6F6;
    margin-left: -16px;
    width: 105%;
}

.thatbookmark-Icon {
    width: 34px;
    height: 34px;
    left: 646px;
    top: 873px;
    background: rgba(126, 178, 98, 0.1);
    border-radius: 5px;
    margin-left: 345px;
    padding: 5px;
}

.Thatbookmark-Images {
    width: 504px;
    height: 435px;
    left: 0px;
    top: 0px;
}

.Thatbookmark-Images2 {
    position: absolute;
    box-sizing: border-box;
    height: 147px;
    left: 504px;
    width: 171px;
    top: 81px;
    border: 1px solid #FFFFFF;
}

.Thatbookmark-Images3 {
    box-sizing: border-box;
    position: absolute;
    width: 169px;
    height: 149px;
    left: 505px;
    top: 225px;
    border: 1px solid #FFFFFF;
}

.Thatbookmark-Images4 {
    box-sizing: border-box;
    position: absolute;
    width: 171px;
    height: 151px;
    left: 504px;
    top: 370px;
    border: 1px solid #FFFFFF;
}

.Thatbookmark-Number {
    position: absolute;
    width: -57px;
    left: 580px;
    top: 431px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
}

.thatbookmark-wooden {
    width: 606px;
    height: 58px;
    left: 20px;
    font-size: 16px;
    line-height: 24px;
    color: #4D4D4D;
    margin-left: 18px;

}

.thatbookmark-woodensale {
    width: 672px;
    margin-left: -2%;
    height: 70px;
    background: #F6F6F6;
}

.thatbookmark-woodenicon {
    margin-left: 420px;
}

.Thatbookmark-play {
    position: absolute;
    left: 25%;
    right: 0%;
    top: 30%;
    bottom: 0%;
    opacity: 0.5;
}

.Thatbookmark-play2 {
    position: absolute;
    left: 83%;
    right: -34%;
    top: 16%;
    bottom: 22%;
    border-radius: 2px;

}

.Thatbookmark-play3 {
    position: absolute;
    left: 83%;
    right: -34%;
    top: 34%;
    bottom: 16%;
    border-radius: 2px;

}

.Thatbookmark-play4 {
    position: absolute;
    left: 82%;
    right: -34%;
    top: 52%;
    bottom: 22%;
    border-radius: 2px;
}

.Thatbookmark-Number2 {
    position: absolute;
    left: 572.12px;
    top: 470.5px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
}

.Thatbookmark-Audi {
    left: 7.27%;
    right: 84.39%;
    top: 30%;
    bottom: 28%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #4D4D4D;
    position: relative;
    margin-top: -35px;
}

.thatbookmark-AudiHeading {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    height: 50px;
    background: rgba(0, 102, 255, 0.1);
    border-radius: 35px;
}

.Thatbookmark-Checkbox {
    box-sizing: border-box;
    left: 3.03%;
    right: 94.55%;
    top: 34%;
    bottom: 34%;
    background: #FFFFFF;
    border-radius: 100%;
}

.ProgressThatColor .progress-bar {
    background: #e5f0ff !important;
    color: #0066FF1A;
}

.Thatbookmark-line {
    width: 106%;
    margin-left: -3%;
}

.BookmarkThatpadding {
    padding-right: 10%;
    background-color: #F9FBF7;
}

.thatcommenticon {
    margin-top: -40px;
}

.thatdropdown-menu {
    min-width: 15rem;
} */
.BookmarkThisMainCard {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
  border-radius: 5px;
}

.thatflitericon {
  background: rgba(129, 193, 75, 0.2);
  border-radius: 5px;
  padding: 9px;
  width: 46px;
  height: 38px;
  background: rgba(129, 193, 75, 0.2);
  border-radius: 5px;
}

.BookMarkThisDropDown {
  position: absolute;
  will-change: transform;
  top: 12px !important;
  /* left: -px !important; */
  -webkit-transform: translate3d(-159px, 46px, 0px) !important;
          transform: translate3d(-159px, 46px, 0px) !important;
  width: 218px !important;
  font-size: 12px !important;
}

.BookMarkThisFilter {
  position: absolute;
  will-change: transform;
  top: 12px !important;
  /* left: -px !important; */
  -webkit-transform: translate3d(-159px, 46px, 0px) !important;
          transform: translate3d(-159px, 46px, 0px) !important;
  width: 218px !important;
  /* font-size: 12px !important; */
}

.BookmarkSearchbIcon {
  position: absolute;
  /* left: 134px; */
  top: 127px;
  right: 422px;
}

.BookMarkSvgIcon {
  /* width: 15px; */
  height: 15px;
}

/* new css to customize the cards  */

.PeopleThisGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.Video_Treanding_card_overrided {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
  /* border-radius: 10px; */
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
  padding-top: calc(var(--bs-gutter-x) / 2);
  padding-bottom: calc(var(--bs-gutter-x) / 2);
}

/* classes to override the design  */

.BookmarkBorderRadius {
  border-radius: 5px !important;
}

.BookmarkBorderRadius_BothBottom {
  border-radius: 0px 0px 5px 5px !important;
}

.BookmarkBorderRadius_BothTop {
  border-radius: 5px 5px 0px 0px !important;
}

.PaddinNone {
  padding: 0 !important;
}

.BookmarkThisSerachSmile {
  position: absolute;
  bottom: 23px;
  right: 24px;
}

.BookmarkVideoLinearGradient {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}

.BookmarkRight8px {
  right: 8px !important;
}

.BookMarkCardShadow {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px !important;
}

.bookmarkMediaCount {
  position: absolute;
  top: 40%;
  /* transform: translateY(-500%); */
  width: 100%;
  margin: auto;
  font-size: 18px;
  color: black;
  text-align: center;
}

.textDisplayForBookmark {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
  /* height: 53px; */
  padding: 5px;
  width: 100% !important;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
}

.textArrangementForBookmark {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
}

.captionBoxForBookmark {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
  min-height: 53px;
  width: 100% !important;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
}
.captionBoxForBookmarks {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
  min-height: 53px;
  width: 100% !important;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
}
.captionTextForBookmark {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  /* margin-left: 20px !important; */
  padding: 5px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
}

.captionTextForBookmark {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  /* margin-left: 20px !important; */
  padding: 5px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
}

.text-nowrap {
  white-space: nowrap;
}

/* go liver */

.height100vh {
  min-height: 100vh;
  max-height: 100vh;
}

.GoLiveOverLapContents {
  display: grid;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  grid-template-areas:
    "header header header comment"
    "header header header comment"
    "footer footer footer comment"
    "footer footer footer comment";
  width: 100%;
}

.Zindex0 {
  z-index: 0 !important;
}

.goLiveHeader_is {
  grid-area: header;
  z-index: 1;
}

.goLiveFooter_is {
  grid-area: footer;
  z-index: 1;
}

.goLiveSidebar_is {
  grid-area: comment;
  background-color: #00000063;
  z-index: 1;
}

.goliveProfileImg {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.goliveHr {
  height: 1px;
  width: 100%;
  background: white;
}

.GridViewListView_Conatainer {
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

button.BookmarkFilterButton.btn.btn-primary {
  background: #e6f3db !important;
  color: #808080 !important;
  border-color: #e6f3db !important;
}

button.BookmarkFilterButton_Active.btn.btn-primary {
  background: #e6f3db !important;
  color: #808080 !important;
  border-color: #e6f3db !important;
}

.BookmarkFilterContentDropdown {
  max-height: 35vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.bookmarKDropDownMenu.dropdown-menu.show {
  display: block;
  width: 112%;
  will-change: transform;
  top: 0px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  left: 0px;
  -webkit-transform: translate3d(-218px, 48px, 0px) !important;
          transform: translate3d(-218px, 48px, 0px) !important;
}

.HoverE6_81:hover {
  background-color: #e6f3db !important;
  color: #81c14b !important;
}
/* Example media queries for responsive design */
/* Mobile */
@media (max-width: 768px) {
  .Card {
    max-width: 100%;
    margin: 0 auto;
  }

  /* Adjust the layout and font sizes for mobile */
  /* .d-flex {
    flex-direction: row;
    align-items: center;
  } */

  .UserProfile-Name {
    text-align: center;
  }

  /* Adjust image and text sizes for mobile */
  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  /* h4 {
    font-size: 16px;
  } */

  /* ... Add more styles as needed for mobile */
}

/* Tablet and Larger Screens */
@media (min-width: 768px) {
  .Card {
    max-width: 50%;
    margin: 0 auto;
  }

  /* Adjust the layout and font sizes for tablet and larger screens */
  /* ... Add styles as needed for larger screens */
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.blinking-form {
  -webkit-animation: blink 1s infinite;
          animation: blink 1s infinite;
}
.image-container {
  position: relative;
  width: 90px;
  margin-left: 10px;
  padding: 2px;
}
/*
.rounded-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
}

.rounded-circle:nth-child(1) {
  left: 0;
  top: 0;
}

.rounded-circle:nth-child(2) {
  left: 15px;
  top: 0px;
}

.rounded-circle:nth-child(3) {
  left: 30px;
  top: 0px;
} */
/* ============================text===================== */
.usdtext {
  text-wrap: nowrap;
  padding-top: 10px;
}
.alertText {
  font-size: 16px;
}
.smalls {
  margin-right: 22px;
}

@media (max-width: 768px) {
  /* Adjust card layout for smaller screens */
  .your-card-component {
    max-width: 500px;
  }

  /* Adjust font sizes and padding */
  .text-nowrap {
    font-size: 14px;
  }
  .UserProfile-Name {
    font-size: 16px;
  }
  /* ... Other styles ... */
}

@media (min-width: 768px) and (max-width: 1023px) {
  .your-card-component {
    max-width: 100%;
    margin: 0 auto;
  }
}

/* Desktop styles (1024px and above) */
@media (min-width: 1024px) {
  .your-card-component {
    max-width: 100%;
    margin: 0 auto;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 991px) {
  .your-card-component {
    max-width: 100%;
    margin: 0 auto;
  }
}

/* Example media query for mobile screens */
@media (max-width: 768px) {
  .your-card-component {
    /* Update styles for mobile view */
    /* For example, adjust padding, margins, font sizes, etc. */
    /* text-wrap: nowrap; */
    margin: 0px;
    padding: 0px;
  }
}

@media (max-width: 360px) and (max-height: 915) {
  .Allbtn {
    margin-right: -0.8rem !important;
  }
  .TextWork {
    margin-left: -0.8rem !important ;
  }
  .pera {
    margin-top: -1rem !important;
  }
  .totaltext {
    margin-left: 0.5rem;
  }
  .episodetext {
    bottom: 9.3rem !important;
  }
}
/* Media query for mobile screens */
@media (max-width: 768px) {
  .card {
    /* max-width: 500px;  */

    .cardtext {
      /* max-width: 550px; */
      text-wrap: wrap;
      margin: 0px;
      padding: 0px;
      flex-direction: column;
      /* align-items: flex-start; */
      width: 370px;
    }
  }

  /* Adjust font sizes and margins for mobile */
  .card-content h4 {
    font-size: 18px;
  }

  .card .card-text {
    font-size: 12px;
    bottom: 2.6rem;
  }

  .tags {
    font-size: 10px;
  }

  /* Reduce padding for overlay on mobile */
  .overlay {
    padding: 5px;
  }
}

/* ==================card media============ */
@media (max-width: 768px) {
  /* .cards {
    max-width: 500px;
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
     width: 370px; 
  } */

  .cards .card-text {
    font-size: 12px;
    bottom: 2.6rem;
  }
}
@media (max-width: 768px) {
  .textCard {
    /* max-width: 500px;  */
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
  }

  .InternationalText {
    /* padding: 6px; */
    margin-top: -10px;
  }
  .mobiletext {
    font-size: 10px;
  }
  .cards .card-text {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .textCards {
    max-width: 500px; /* Full width for mobile */
    margin: 0px;
    padding: 0px;
    bottom: 9.3rem !important;
    height: 120px;
  }
  @media (max-width: 1629px) {
    .textCards {
      max-width: 500px; /* Full width for mobile */
      margin: 0px;
      padding: 0px;
      bottom: 9.3rem !important;
      height: 120px;
    }
  }
  .pollcardx {
    margin: -7px;
  }
  /* .playicon {
    position: absolute !important;
    top: 9rem !important;
    left: 35% !important;
  } */
  .blinkicon {
    position: absolute !important;
    top: 7rem !important;
    left: 26% !important;
  }
  .searchbookmark {
    width: 9.3rem !important;
  }
  .mintext {
    display: flex;
    justify-content: start;
  }
  .mintexts {
    margin-top: 3rem !important;
  }
  .cards .card-text {
    font-size: 12px;
  }
}

.cardtexts {
  /* Add styles for your card here */
  /* Example styles for the card container */
  border: 1px solid #ccc;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 400px; /* Adjust the maximum width as needed */
}

.cardtexts img {
  /* Add styles for the image here */
  max-width: 100%; /* Ensure the image doesn't exceed its container */
}

.cardtexts .content {
  /* Add styles for the content section here */
  margin-top: 10px; /* Add some space between the image and content */
}

.cardtexts .read-more {
  /* Add styles for the "Read more" text here */
  margin-top: 10px; /* Adjust the margin-top as needed to control spacing */
  color: #007bff; /* Change the color to your desired color */
  cursor: pointer; /* Add a pointer cursor to indicate it's clickable */
}

.cardtexts .read-more:hover {
  text-decoration: underline; /* Underline the "Read more" text on hover if desired */
}
/* ======================================768 and 1024===================== */
@media (min-width: 768px) and (min-height: 1024px) {
  .usdtexts {
    margin-right: 0rem;
  }
  .textCard {
    color: white;
    bottom: 6.6rem !important;
  }
  .pollcardx {
    width: 440px !important;
    margin-left: 12px;
  }
  .textCards {
    /* max-width: 500px; */
    margin: 0px;
    padding: 0px;
    bottom: 7.3rem !important;
  }
}
/* =======================================834 and 1194======================== */
@media (min-width: 834px) and (min-height: 1194px) {
  .pollcardx {
    width: 530px !important;
    margin-left: -4px;
  }
}
/* ===============================1920 and 1180======================== */
@media (min-width: 1920px) and (min-height: 1180px) {
  .pollcardx {
    width: 620px !important;
    margin-left: -4px;
  }
  .textCards {
    max-width: 671px;
    margin: 0px;
    padding: 0px;
    bottom: 8.1rem !important;
  }
  .icontext {
    padding-left: 36rem !important;
  }
}
/* ===============================2560 and 1440======================== */
@media (min-width: 2560px) and (min-height: 1440px) {
  .pollcardx {
    width: 59rem !important;
    margin-left: -4px;
  }
  .textCards {
    max-width: 62rem;
    margin: 0px;
    padding: 0px;
    bottom: 8.1rem !important;
  }
  .icontext {
    padding-left: 19rem !important;
  }
  .textCard {
    bottom: 5.6rem !important;
  }
}
/* ===============================375 and 667======================== */
@media (min-width: 375px) and (min-height: 667px) {
  .pollcardx {
    width: 22.6rem !important;
    margin-left: -4px;
  }
  .totaltext {
    margin-left: 0.5rem;
  }
  .Intertext {
    margin-left: -4px;
  }
  .TextWork {
    padding-left: 0.25px !important;
  }
  .messagebtn {
    margin-left: 5rem !important;
  }
}
/* ===============================414 and 886======================== */
@media (min-width: 414px) and (min-height: 889px) {
  .pollcardx {
    width: 25.2rem !important;
    margin-left: -4px;
  }
}
/* ===============================390 and 844======================== */
@media (min-width: 390px) and (min-height: 844px) {
  .pollcardx {
    width: 23.7rem !important;
    margin-left: -4px;
  }
}
/* ===============================367 and 740======================== */
@media (min-width: 360px) and (min-height: 740px) {
  .totaltext {
    margin-left: 0.5rem;
  }
  .Intertext {
    margin-left: -4px;
  }
  .TextWork {
    padding-left: 0.25px !important;
  }
  .messagebtn {
    margin-left: 2rem !important;
  }
}
/* ===============================412 and 915======================== */
@media (min-width: 412px) and (min-height: 915px) {
  .pollcardx {
    width: 24.9rem !important;
    margin-left: -4px;
  }
}
/* ======================================820 and 1180===================== */
@media (min-width: 768px) and (min-height: 1024px) {
  .usdtexts {
    margin-right: 0rem;
  }
  .textCard {
    color: white;
    bottom: 6.6rem !important;
  }
  .pollcardx {
    width: 550px !important;
    margin-left: -15px;
  }
  .textCards {
    /* max-width: 36rem; */
    margin: 0px;
    padding: 0px;
    bottom: 7.6rem !important;
  }
  .messagebtn {
    margin-left: 19rem !important;
  }
  .icontext {
    padding-left: 13rem !important;
  }
}
/* ===============================768 and 1024======================== */
@media (min-width: 768px) and (min-height: 1024px) {
  .textCard {
    /* max-width: 34rem; */
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 6.3rem !important;
  }
  .blkimg {
    width: 100% !important;
  }
  .icontext {
    padding-left: 16rem !important;
  }
  .pollcardx {
    width: 500px !important;
    margin-left: 4px;
  }
}
@media (min-width: 912px) and (min-height: 1368px) {
  .textCard {
    /* max-width: 49rem; */
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 6.5rem !important;
  }
  .textCards {
    /* max-width: 42rem !important; */
    margin: 0px;
    padding: 0px;
    bottom: 7.6rem !important;
  }
  .blkimg {
    width: 100% !important;
  }
  .icontext {
    padding-left: 35rem !important;
  }
  .pollcardx {
    width: 40rem !important;
    margin-left: -11px;
  }
}
/* ==========================540 and 720================== */
@media (min-width: 540px) and (min-height: 720px) {
  .textCard {
    /* max-width: 42rem; */
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 6.3rem !important;
  }
  .textCards {
    /* max-width: 42rem !important; */
    margin: 0px;
    padding: 0px;
    bottom: 7.5rem !important;
  }
  .blkimg {
    width: 100% !important;
  }
  .icontext {
    padding-left: 18rem !important;
  }
  .pollcardx {
    width: 32rem !important;
    margin-left: -4px;
  }
  .blinkicon {
    position: absolute !important;
    top: 7rem !important;
    left: 34% !important;
  }
  .messagebtn {
    margin-left: 16rem !important;
  }
}
/* ==========================1124 and 600================== */
@media (min-width: 1124px) and (min-height: 600px) {
  .messagebtn {
    margin-left: 16rem !important;
  }
  .icontext {
    padding-left: 16rem !important;
  }
}
/* ==========================1280 and 800================== */
@media (min-width: 1280px) and (min-height: 800px) {
  .textCard {
    /* max-width: 42rem; */
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 7.9rem !important;
  }
  .textCards {
    /* max-width: 42rem !important; */
    margin: 0px;
    padding: 0px;
    /* bottom: 9.8rem !important; */
  }
  .blkimg {
    width: 100% !important;
  }
  .icontext {
    padding-left: 21rem !important;
  }
  .pollcardx {
    width: 30rem !important;
    margin-left: -16px;
  }
  .blinkicon {
    position: absolute !important;
    top: 7rem !important;
    left: 34% !important;
  }
  .messagebtn {
    margin-left: 16rem !important;
  }
}
/* ==========================1366 and 768================== */
@media (min-width: 1366px) and (min-height: 768px) {
  .textCard {
    /* max-width: 42rem; */
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 6.9rem !important;
  }
  .pollcardx {
    width: 33rem !important;
    margin-left: -16px;
  }
}
/* ==========================1920 and 1080================== */
@media (min-width: 1920px) and (min-height: 1080px) {
  .textCards {
    /* max-width: 42rem !important; */
    margin: 0px;
    padding: 0px;
    /* bottom: 8.1rem !important; */
  }
  .pollcardx {
    width: 41rem !important;
    margin-left: -16px;
  }
  .icontext {
    padding-left: 37rem !important;
  }
  .blinkicons {
    position: absolute !important;
  }
}
/* ==========================2560 and 1440================== */
@media (min-width: 2560px) and (min-height: 1440px) {
  .textCard {
    max-width: 62rem;
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 5.6rem !important;
  }
  .messagebtn {
    margin-left: 33rem !important;
  }
  .icontext {
    padding-left: 18rem !important;
  }
  .pollcardx {
    width: 61rem !important;
    margin-left: -16px;
  }
  .pollcards {
    width: 40rem !important;
  }
  .textCards {
    max-width: 62rem !important;
    margin: 0px;
    padding: 0px;
    bottom: 7.8rem !important;
  }
  .textCad {
    bottom: 3.5rem !important;
  }
  .textCads {
    bottom: 4.5rem !important;
  }
  .textCadss {
    bottom: 2.5rem !important;
  }
  .blinkicons {
    position: absolute !important;
    top: 50% !important;
    left: 52% !important;
  }
  .bookmark-Recommendations-content-captionss {
    background: rgba(35, 83, 99, 0.6862745098039216) !important;
    position: absolute !important;
    top: 18rem !important;
    padding: 7px !important;
    left: 0 !important;
  }
}
/* ==========================2560 and 1440================== */
@media (min-width: 1078px) and (min-height: 527px) {
  .icontext {
    padding-left: 24rem !important;
  }
}
/* ==========================375 and 667================== */
@media (min-width: 375px) and (min-height: 667px) {
  /* .North {
    margin-left: -10rem !important;
  }
  .Norths {
    margin-left: -10rem !important;
  } */
  .Options {
    margin-right: 8rem !important;
  }
}
@media (min-width: 820px) and (min-height: 1180px) {
  .textCard {
    max-width: 42rem;
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 6.5rem !important;
  }
  .icontext {
    padding-left: 13rem !important;
  }
}
@media (min-width: 912px) and (min-height: 1368px) {
  .pollcardx {
    width: 61rem !important;
    margin-left: -16px;
  }
  .icontext {
    padding-left: 34rem !important;
  }
}
@media (min-width: 1024px) and (min-height: 600px) {
  .icontext {
    padding-left: 27rem !important;
  }
}
@media (min-width: 1280px) and (min-height: 800px) {
  .icontext {
    padding-left: 23rem !important;
  }
}
@media (min-width: 1078px) and (min-height: 575px) {
  .icontext {
    margin-left: 11rem !important;
  }
}
@media (min-width: 375px) and (min-height: 667px) {
  .icontext {
    margin-left: 7rem !important;
  }
}
@media (min-width: 1366px) and (min-height: 768px) {
  .TextWork {
    margin-left: 0.1rem !important;
  }
}
@media (min-width: 1024px) and (min-height: 1366px) {
  /* .pollcardx {
    width: 45rem !important;
  } */
}
@media (min-width: 2560px) and (min-height: 1440px) {
  /* .pollcardx {
    width: 60rem !important;
  } */
}
@media (min-width: 1024px) and (min-height: 1366px) {
  .textCard {
    max-width: 62rem;
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 6.5rem !important;
  }
}
/* .textCards {
  bottom: 7.6rem !important;
} */
@media (min-width: 483px) and (min-height: 564px) {
  .content-wrappe {
    bottom: 6.5rem !important;
  }
}
@media (min-width: 360px) {
  .content-wrappe {
    bottom: 6.5rem !important;
  }
}
@media (min-width: 360px) and (min-height: 564px) {
  .episodetext {
    bottom: 7.6rem !important;
  }
  .textCard {
    bottom: 6.7rem !important;
  }
}
/* @media (min-width: 721px) and (min-height: 564px) {
  .episodetexts {
    bottom: 7.6rem !important;
  } */
/* .textCard {
    bottom: 6.2rem !important;
  } */
/* } */
@media (min-width: 540px) and (min-height: 720px) {
  .episodetexts {
    bottom: 7.3rem !important;
  }
  .textCard {
    bottom: 6.2rem !important;
  }
}
@media (min-width: 820px) and (min-height: 1180px) {
  .episodetexts {
    bottom: 7.8rem !important;
  }
  .textCard {
    bottom: 6.8rem !important;
  }
}
@media (min-width: 1075px) and (min-height: 564px) {
  .iconfafa {
    margin-left: 12rem !important;
  }
}
@media (min-width: 912px) and (min-height: 1368px) {
  .iconfafa {
    margin-left: 22rem !important;
  }
}
@media (min-width: 1920px) and (min-height: 1080px) {
  .iconfafa {
    margin-left: 22rem !important;
  }
}
/* @media screen and (max-width: 1024px) {
  .iconfafa {
    display: block !important;
  }
}
@media only screen and (max-width: 480px) {
  .iconfafa {
    display: none !important;
  }
} */
@media only screen and (max-width: 600px) {
  .iconfafa {
    margin-left: 8rem !important;
  }
}

@media only screen and (min-width: 768px) {
  .iconfafa {
    margin-left: 12rem !important;
  }
}

@media only screen and (min-width: 992px) {
  .iconfafa {
    margin-left: 29rem !important;
  }
}

@media only screen and (min-width: 1075px) {
  .iconfafa {
    margin-left: 19rem !important;
  }
}
/* @media only screen and (min-width: 360px) {
  .internationtext {
    margin-left: -5px;
  }
} */

.rowForrevert.row {
    --bs-gutter-x: revert !important;
}

.dropToggleWidth {
    width: 500px !important
}

.headerForpodcast {
    position: absolute;
    display: flex;
    justify-content: space-between;
    color: whitesmoke;
    width: 100%;
    z-index: 1;
}

.simple-linear {
    background-image: linear-gradient(180deg, black, transparent);
    z-index: 2;
}

.podcastForcard {
    border-radius: 5px !important;
    /* box-shadow: 1px 5px #88888842; */
    box-shadow: 0px 0px 10px #E0E2DE;
    margin: 2%;
}

.container-padding {
    padding: 0px 80px 0px 80px;
    display: flex;
    justify-content: center;
}

.scroll-for-podcast {
    overflow-y: scroll;
    /* height: 90vh; */
    height: calc(100% - 30px);
    margin: 10px 0px;
}

.moreoverFormargin {
    color: white;
}

.paidTagForpostition {
    position: absolute;
    /* bottom: 110px;
    left: 73%; */
    bottom: 0px;
    right: 0px;
}

.adForpodcast {
    position: absolute;
    z-index: 100;
}

.adbigTagForpodcast {
    position: absolute;
    display: flex;
    bottom: 0px;
    justify-content: space-between;
    align-items: center;
    margin-left: 2%;
    color: white;
    background-color: #5a6a64a4;
    width: 100%;
    padding: 4%;
    left: -2%;
}

.bottomright {
    position: absolute;
    bottom: 8px;
    right: 16px;
    font-size: 18px;
}

.bottomcenter {
    position: absolute;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 18px;
}

.createForHeadercolor {
    padding: 1%;
    background-color: rgba(129, 193, 75, 0.2);
}

.colorForSwitch .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: #81C14B !important;
}

.colorForSwitch .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: revert;
    border: 1px solid #81C14B;
}

.whocanListenForpodcast {
    padding-right: 31rem;
    background-color: '#F6F6F6';
}

.PodacstForInput.form-control:focus {
    border: 1px solid #81C14B !important;
    background-color: #F6F6F6;
}

.soundUploadForpodcast {
    border-radius: 5px;
    /* background-color: #f1f3f4; */
    padding: 1%;
}

.imgbackgroundForPostion {
    position: absolute;
    left: 6px;
}

.existingDrop.dropdown-menu.show {
    display: block;
    width: 150%;
    min-width: -webkit-max-content;
    min-width: max-content;
}

.existingDropQa.dropdown-menu.show {
    display: block;
    width: 150%;
    min-width: -webkit-max-content;
    min-width: max-content;
    left: -15px !important;
}

.imgbackgroundFor {
    position: relative;
    width: 60px;
    height: 60px;
    /* background-color: #D0B736; */
    -webkit-animation-name: bck;
            animation-name: bck;
    /* animation: backwards; */
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    border-radius: 100%;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    animation-direction: reverse;
    left: 2.5%;
    top: 27.7%
}

.padding-music-footer {
    padding: 10rem 20rem;
}

.padding-music-footer-slider {
    padding: 0rem 8rem;
}

.logo-size {
    width: 8rem;
}

.shadow-play {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 50% !important;
}

.button-hovered {
    background-color: #D3D3D3;
    border-radius: 50%;
}

.innerscroll {
    display: flex;
    justify-content: space-between;
    overflow-y: hidden;
    min-height: 85%;
    width: 100%;
    padding: 50px 45px;
}

.offcanvas-body.rem-scroll {
    overflow-y: hidden !important;
}

.pl {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    width: 50%;
}

.qu {
    position: relative;
    margin: 0;
    width: calc(100vw / 3);
    max-width: 550px;
    max-height: calc(75vh);
    overflow-y: auto !important;
}

.qu::-webkit-scrollbar {
    display: none;
}

.top {
    padding-left: 0;
    padding-bottom: 18px;
    margin-left: 20px;
    margin-bottom: 0;
    border-bottom: 0.4px solid rgba(142, 142, 147, .2);
}

.listScroll {
    overflow-y: auto;
    height: calc(100% - 30px);
    margin: 10px 0;
}

.table-head {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #6c7068;
    padding: 5px;
    z-index: 5;
    border-radius: 2px;
}

.img-container {
    position: relative;
}

.img-options {
    position: absolute;
    top: 8px;
    right: 4px;
    z-index: 2;
}

.scroll-canvas {
    overflow-y: hidden;
}

.margin-for-animation {
    margin: 7px 0;
}

.btn-size-reduce {
    padding: 6px 6px;
}

@-webkit-keyframes bck {
    0% {
        /* background-color: #f3cc0bea; */
        border: 4px solid #b6b091ea;
    }

    25% {
        /* background-color: #dbba17a9; */
        border: 7px solid #c5b770a9;
    }

    50% {
        border: 9px solid #dbba17a9;
    }

    75% {
        border: 11px solid #ad9b41a9;
    }

    100% {
        /* background-color: #ceb12065; */
        border: 14px solid #d8b81b65;
    }
}

@keyframes bck {
    0% {
        /* background-color: #f3cc0bea; */
        border: 4px solid #b6b091ea;
    }

    25% {
        /* background-color: #dbba17a9; */
        border: 7px solid #c5b770a9;
    }

    50% {
        border: 9px solid #dbba17a9;
    }

    75% {
        border: 11px solid #ad9b41a9;
    }

    100% {
        /* background-color: #ceb12065; */
        border: 14px solid #d8b81b65;
    }
}

.animeForHeader {
    -webkit-animation: headeranima 0.4s ease-in-out;
            animation: headeranima 0.4s ease-in-out;
}


@-webkit-keyframes headeranima {
    from {
        -webkit-transform: translateY(-200px);
                transform: translateY(-200px);
    }

    to {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}


@keyframes headeranima {
    from {
        -webkit-transform: translateY(-200px);
                transform: translateY(-200px);
    }

    to {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

.chevron-down {
    -webkit-animation-name: bounce;
            animation-name: bounce;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes bounce {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }

    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}


.podcastcenterForstyle {
    position: absolute;
    top: 40%;
    left: 38%;
    /* width: 100%; */
    text-align: center;
    /* font-size: 18px; */
}

#usercontainer {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
}

.usercircle {
    border-radius: 50%;
    background-color: #CEB42F;
    width: 100px;
    height: 100px;
    position: absolute;
    opacity: 0;
    -webkit-animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);
            animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);
}

@-webkit-keyframes scaleIn {
    from {
        -webkit-transform: scale(.5, .5);
                transform: scale(.5, .5);
        opacity: .5;
    }

    to {
        -webkit-transform: scale(2.5, 2.5);
                transform: scale(2.5, 2.5);
        opacity: 0;
    }
}

@keyframes scaleIn {
    from {
        -webkit-transform: scale(.5, .5);
                transform: scale(.5, .5);
        opacity: .5;
    }

    to {
        -webkit-transform: scale(2.5, 2.5);
                transform: scale(2.5, 2.5);
        opacity: 0;
    }
}

.useritem {
    z-index: 100;
    padding: 2px;
}

.useritem img {
    width: 35px;
}

.audioForlivepodcast {
    position: absolute;
    z-index: 1;
    color: white;
    width: -webkit-fill-available;
}

.audioForlivepodcastAudio {
    position: absolute;
    z-index: 1;
    color: white;
    width: -webkit-fill-available;
    bottom: 0px;
}

.Fullsizecontainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Fullsizecontainercard {
    position: relative;
    width: 100%;
    height: 100%;
}

.Fullsizecontainertop-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.Fullsizecontainerbottom-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.podcastMic {
    position: relative;
}

.podcastMicImg {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    text-align: center;
}

.podcastMicpositionForWings {
    height: 215px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-animation: beats 0.80s ease-in-out infinite;
            animation: beats 0.80s ease-in-out infinite;
}

@-webkit-keyframes beats {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1);
    }

    50% {
        -webkit-transform: translate(-50%, -50%) scale(1.5);
                transform: translate(-50%, -50%) scale(1.5);
    }

    100% {
        -webkit-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes beats {
    0% {
        -webkit-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1);
    }

    50% {
        -webkit-transform: translate(-50%, -50%) scale(1.5);
                transform: translate(-50%, -50%) scale(1.5);
    }

    100% {
        -webkit-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1);
    }
}

.seriesPlayForIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.seriesPlayForIconList {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.floatingButtonWrap {
    display: block;
    position: fixed;
    bottom: 80px;
    right: 160px;
    z-index: 1;
}

.floatingButtonWrapFilter {
    display: block;
    position: fixed;
    bottom: 120px;
    right: 1px;
    z-index: 1;
}

.flex-mob-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.padding-for-player {
    padding: 0rem 6rem;
}

.MusicList:hover {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    background-color: #80c14b35 !important;
    padding: 0.5rem !important;
    cursor: pointer;
    transition: 0.3s;
}

.list-heading {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.play-highlight:hover {
    background-color: #DDDDDD;
    border-radius: 50%;
    padding: 2px;
}

.caption {
    text-align: left;
}

.btn-outline-secondary.border-clr {
    border-color: #dedede;
}

.rem-bg {
    background-color: #ffffff !important;
}

@media (max-width: 375px) {
    .whocanListenForpodcast {
        padding-right: 10.5rem;
    }

    .imgbackgroundFor {
        position: absolute;
        width: 60px;
        height: 60px;
        /* background-color: #D0B736; */
        -webkit-animation-name: bck;
                animation-name: bck;
        /* animation: backwards; */
        -webkit-animation-duration: 5s;
                animation-duration: 5s;
        -webkit-animation-delay: 1s;
                animation-delay: 1s;
        border-radius: 100%;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        animation-direction: reverse;
        left: 3.2%;
        top: 26.7%
    }

    .positionLikeForMobPodcast {
        position: absolute;
        right: 33px;
        top: -195px;
    }
}

@media (max-width:1880px) {
    .timecontrols {
        width: calc(100% - 470px);
    }

    .padding-music-footer {
        padding: 10rem 15rem;
    }
}

@media (max-width:1440px) {
    .timecontrols {
        width: calc(100% - 364px);
    }

    .padding-for-player {
        padding: 0rem 1rem;
    }
}

@media (max-width: 1200px) {
    .rem-tab-hide {
        display: none;
    }
}

@media (max-width:1024px) {
    .timecontrols {
        width: calc(100% - 255px);
    }

    .margin-tab {
        margin-top: 5px;
    }

    .padding-music-footer {
        padding: 2rem 0rem;
    }

    .padding-for-player {
        padding: 0rem;
    }

}

@media (max-width: 992px) {
    .floatingButtonWrap {
        display: block;
        position: fixed;
        bottom: 50px;
        right: 150px;
        z-index: 1;
    }

    .floatingButtonWrapFilter {
        display: block;
        position: fixed;
        bottom: 55px;
        right: 125px;
        z-index: 1;
    }

    .container-padding {
        padding: 0px;
    }

    .padding-music-footer {
        padding: 0px;
    }

    .padding-music-footer-slider {
        padding: 0px;
    }

    .innerscroll {
        flex-direction: column;
        padding: 0px 20px;
    }

    .qu {
        position: relative;
        margin: 0;
        width: 100% !important;
        max-width: 100% !important;
        max-height: 500px !important;
        overflow-y: auto !important;
    }

    .qu::-webkit-scrollbar {
        display: none;
    }

    .scroll-canvas {
        overflow-y: auto;
    }

}

@media (max-width: 768px) {
    .floatingButtonWrap {
        display: block;
        position: fixed;
        bottom: 76px;
        right: 50px;
      
        z-index: 1;
    }

    .floatingButtonWrapFilter {
        display: block;
        position: fixed;
        bottom: 165px;
        right: 125px;
        /* right: 25px; */
        z-index: 1;
    }

    .timecontrols {
        width: 100%;
    }

    .logo-size {
        width: 100px;
    }
}

@media (max-width: 734px) {
    .whocanListenForpodcast {
        padding-right: 12.8rem;
    }
}

@media (max-width: 412px) {
    .whocanListenForpodcast {
        padding-right: 10.5rem;
    }
}

@media (max-width: 425px) {
    .whocanListenForpodcast {
        padding-right: 10.5rem;
    }

    .floatingButtonWrap {
        display: block;
        position: fixed;
        bottom: 84px;
        right: 10px;
        z-index: 1;
    }

    .floatingButtonWrapFilter {
        display: block;
        position: fixed;
        bottom: 165px;
        right: -14px;
        z-index: 1;
    }
}


@media (max-width: 375px) {
    .whocanListenForpodcast {
        padding-right: 10.5rem;
    }

    .floatingButtonWrap {
        display: block;
        position: fixed;
        bottom: 125px;
        right: 10px;
        z-index: 1;
    }

    .floatingButtonWrapFilter {
        display: block;
        position: fixed;
        bottom: 165px;
        right: -14px;
        z-index: 1;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .padding-music-footer {
        padding: 9rem 6rem;
    }
}







.MusicList {
    display: flex;
    align-items: center;
}

.MusicList>div {
    flex: 1 1 200px;
}

.categories-scroll {
    overflow-y: scroll;
    max-height: 200px;
    overflow-x: hidden;
}

.who-can-listen-scroll {
    overflow-y: scroll;
    max-height: 150px;
    overflow-x: hidden;
}

.scrollForSharePodcast {
    overflow-y: scroll;
    max-height: 250px;
}

.dropdown-padding-form {
    padding: 9px 20px !important;
}

.custom-toggle {
    color: #555555;
    font-weight: normal;
    /* font-size: 16px; */
    font-size: '14px';
}

.podcast-test-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 6px;
    height: 38px;
    color: #4d4d4d;
    font-weight: 500;
    transition: 0.2s;
}

.podcast-test-button:hover {
    background: #81C14B;
    color: #fff !important;
}

.podcast-setting-preview-blk {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.podcast-setting-preview-blk h4 {
    color: #ffffff;
}

.obj-container.dropdown-menu.show {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}

.pod-footer {
    display: grid;
    grid-template-columns: 40% 20% 40%;
}

.pod-footer>div {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.TimerAlignment {
    justify-self: flex-end;
}


.VolumeSliderCustomize {
    position: absolute;
    bottom: 2rem;
    left: 0;
    height: 10rem;
    border-radius: 30px;
    width: 2rem;
    padding: 10px 2px;
    background-color: whitesmoke;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

@media (min-width:280px) and (max-width: 768px)  {
    .podnav{
  
    display: contents;
    }
  }
@media (min-width:280px) and (max-width: 336px)  {
    .tooglespace{
  
        border-radius: 2px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        grid-gap: 10px;
        grid-gap: 0px;
        gap: 0px;
    }
  }
@media (min-width:280px) and (max-width: 576px)  {
    .podwholenav{
  
        box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
   
      
        overflow: hidden;
        margin-right: 11px;
        margin-left: 11px;
    }
  }
@media (min-width:280px) and (max-width: 311px)  {
    .podpad{
  
      padding: 0.5rem 0.35rem !important;
    }
  }
@media (min-width:280px) and (max-width: 350px)  {
    .newpod{
  
   margin-top: 28px !important;
    }
  }
@media (min-width:280px) and (max-width: 374px)  {
    .podcomment{
  
   display: none !important;
    }
  }
@media (max-width:280px)   {
    .threedot{
  
   right: 16px;
    }
  }

@media (min-width:280px) and (max-width: 334px)  {
    .mobwatch{
  
    position: relative;
    right: 10px;
    }
    .butmobwat{
        padding: 10px 17px !important;
    }
    .butmobwat1{
        padding: 10px 8px !important;
    }
  }
@media (min-width:280px) and (max-width: 1200px)  {
    .pod-nav{
  
      display: none;
    }
  }
@media (min-width:280px) and (max-width: 370px)  {
    .foterpodmob{
  
     width: 270px !important;
    }
  }
@media (min-width:280px) and (max-width: 350px)  {
    .sharemodalpod{
  
    position: relative;
    right: 14px;
    }
  }
@media (min-width:1201px) and (max-width: 1214px)  {
    .filcret{
  
        display: flex;
        position: relative;
        top: -36px;
    }
  }
@media (min-width:589px) and (max-width: 1200px)  {
    .mypodehead{
  
      
        position: relative;
      left: 2.5%;
    }
  }
@media (min-width:438px) and (max-width: 589px)  {
    .mypodehead{
  
      
        position: relative;
      left: 4.5%;
    }
  }
@media (min-width:361px) and (max-width: 577px)  {
    .filtericonn {
           
        top: 59px !important;
        left: -11px !important;

    }
  }
@media (min-width:280px) and (max-width: 360px)  {
    .filtericonn {
           
        top: 51px !important;
        left: -11px !important;

    }
  }
@media (min-width:577px) and (max-width: 767px)  {
    .filtericonn {
           
      left: 12px !important;
    }
  }
@media (min-width:280px) and (max-width: 437px)  {
    .mypodehead{
  
      
        position: relative;
      left: 8%;
    }
  
  }

  .MobForChat1 {
    position: absolute;
    z-index: 0;
    /* width: 100%; */
    /* max-height: 282px; */
    overflow-y: scroll;
    /* background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.3)); */
    webkit-mask-image: linear-gradient(356deg, white, transparent);
    /* padding-right: 1px; */
    margin-left: 0px;
  }

  @media (max-width: 992px){
.InputForcolor12 {
    background: rgba(249, 251, 247, 0.8);
    border: 1px solid rgba(126, 178, 98, 0.1);
    padding-right: 24%;
    position: absolute;
    bottom: 0;
    position: fixed;
    /* margin-left: -15px; */
}
  }

  .podheart{
    position: relative;
    top: -13px;
  }

  .podwebheart{
    position: relative;
    top: -8px;
  }

  .podsliveblink{
    border-radius: 20px;
    font-size: 16px;
  }

  .podjoined{
    margin-left: 10px;
  }

  .chtspod{
    left: 26px;
  }

  .golivesetting-toggle-box1{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    
  }
  .m-31{
    margin: 1rem 1rem 1rem 0rem !important;
  }

  .hetpod{
    position: relative;
    right: 5%;
  }

  .reportbu{
    padding: 10px 11px !important;
  }

  .pt-21{
    padding-top: 0.8rem !important;
  }

  .filtericonn{
    display: flex;
    /* flex-direction: row-reverse; */
    /* flex-wrap: wrap; */
    position: absolute;
    top: 97px;
    left: 95px;
  }

  .mr-31{
    margin-right: 2rem!important;
    top: -6px;
  }

  .addpodcast{
    position: absolute;
  }

  .foterpodmob{
    width: 350px;
  }
  .fotlike{
    width: -webkit-max-content;
    width: max-content;
  }


  .toppoddroup{
    padding: 0.25rem 0.5rem !important;
  }
.golivemainForbackground {
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.webcamForstyle {
    width: "100%";
    height: "100%";
    position: "absolute";
    left: "50%";
    margin-left: "-50%";
    object-fit: "cover";
    object-position: "center";
}

.mobheaderstyle {
    position: absolute;
    color: #FFFFFF;
    font-family: 'Montserrat';
    z-index: 1;
}

.scrollForGolive {
    overflow: auto;
    overflow-x: hidden;
    max-height: 70vh;
}

.ShareOnlineUser {
    position: absolute;
    top: -8px;
    left: -12px;
}

.HeaderactiveForExplorePage {
    top: 1px;
    position: absolute;
    left: 37px;
}

.HeaderactiveForGoLiveIcon {
    top: 3px;
    position: absolute;
    left: 51px;
}

.HeaderactiveForGoLiveIconMedia {
    top: -3px;
    position: absolute;
    left: 37px;
}

.onHoverForshow {
    opacity: 0%;
}

.onHoverForshow:hover {
    opacity: 0.9;
}

.postitionForgoLivedrop {
    max-width: -webkit-max-content;
    max-width: max-content;
    inset: 0px auto auto 305px !important;
}

.gl-setting-toggle-cont h5 {
    color: #808080;
}

.gl-setting-toggle-cont h4 {
    color: #4D4D4D;
}

.goliveFordrop.dropdown-menu {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.goLiveDropDownMenuItems>.dropdown-item {
    font-size: 0.7rem !important;
}

.postitionStopForButton {
    margin-right: 11%;
}

.wglpausecircleForposition {
    position: relative;
    right: 516px;
}

.CommentForColor {
    background: rgba(0, 0, 0, 0.062) !important;
    color: #FFFFFF;
}

.InputForcolor {
    background: rgba(249, 251, 247, 0.4);
    border: 1px solid rgba(126, 178, 98, 0.1);
    border-radius: 5px;
    padding-right: 5%;
}

.iconForcolor {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    transition: all 0.25s ease-in-out;
}

.commentInputForposition {
    position: absolute;
    right: 20px;
    bottom: 23px;
}
.smile{
    position: absolute;
    right: 20px;
}

.disableForButton .btn-primary:disabled,
.btn-primary.disabled {
    color: #81C14B;
    /* background-color: #0d6efd; */
    background: rgba(129, 193, 75, 0.2);
    border-color: #81C14B;
}

.positionTimerForMob {
    background-color: rgba(255, 255, 255, 0.411);
    font-size: x-large;
}

.goliveForheader {
    position: absolute;
    width: 100vw;
    top: 0px;
    color: #B8C6DB;
    font-weight: 500;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), transparent);
    -webkit-text-stroke-color: #FFFFFF;
}

.goliveForcampostition {
    position: absolute;
    width: 100vw;
    bottom: 0px;
    color: #B8C6DB;
    font-weight: 500;
    padding-top: 20px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
    -webkit-text-stroke-color: #FFFFFF;
}

.scrollForGoliveFilter {
    max-height: 200px; /* Adjust the max height as needed */
    overflow-y: auto;
  }
  .scrollForGoliveFilters {
    max-height: 385px;
    overflow-y: auto;
  }
  .scrollForGoliveFilters .dropdown-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .scrollForGoliveFilter .dropdown-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.MobForChat {
    position: absolute;
    z-index: 0;
    /* width: 100%; */
    max-height: 282px;
    overflow-y: scroll;
    /* background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.3)); */
    -webkit-mask-image: linear-gradient(356deg, white, transparent);
    padding-right: 1px;
    margin-left: -26px;
}

.gradient-blur {
    position: fixed;
    z-index: 5;
    inset: auto 0 0 0;
    height: 11%;
    pointer-events: none;
    bottom: calc(100% - 433px);
}

.gradient-blur>div,
.gradient-blur::before,
.gradient-blur::after {
    position: absolute;
    inset: 0;
}

.gradient-blur::before {
    content: "";
    z-index: 0;
    -webkit-backdrop-filter: blur(0px);
            backdrop-filter: blur(0px);
    -webkit-mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 12.5%,
            rgba(0, 0, 0, 1) 25%,
            rgba(0, 0, 0, 0) 37.5%);
            mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 12.5%,
            rgba(0, 0, 0, 1) 25%,
            rgba(0, 0, 0, 0) 37.5%);
}


.gradient-blur>div:nth-of-type(1) {
    z-index: 4;
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
    -webkit-mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 37.5%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 1) 62.5%,
            rgba(0, 0, 0, 0) 75%);
            mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 37.5%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 1) 62.5%,
            rgba(0, 0, 0, 0) 75%);
}

.gradient-blur>div:nth-of-type(2) {
    z-index: 4;
    -webkit-backdrop-filter: blur(0.9px);
            backdrop-filter: blur(0.9px);
    -webkit-mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 37.5%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 1) 62.5%,
            rgba(0, 0, 0, 0) 75%);
            mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 37.5%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 1) 62.5%,
            rgba(0, 0, 0, 0) 75%);
}

.gradient-blur>div:nth-of-type(3) {
    z-index: 4;
    -webkit-backdrop-filter: blur(0.5px);
            backdrop-filter: blur(0.5px);
    -webkit-mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 37.5%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 1) 62.5%,
            rgba(0, 0, 0, 0) 75%);
            mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 37.5%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 1) 62.5%,
            rgba(0, 0, 0, 0) 75%);
}

.gradient-blur::after {
    content: "";
    z-index: 8;
    /* backdrop-filter: blur(32px); */
    -webkit-mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 87.5%,
            rgba(0, 0, 0, 1) 100%);
            mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 87.5%,
            rgba(0, 0, 0, 1) 100%);
}


.EditedChanges {
    position: fixed !important;
    z-index: 0;
    width: 100%;
    /* max-height: 222px; */
    overflow-y: scroll;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.3));

}

.mobPinnedChat {
    position: fixed;
    bottom: 40px;
}


.MobForChat::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.chatForpopup {
    /* background: rgba(233, 226, 226, 0.4); */
    padding: 0px 15px 0px 15px;
}

.sizeForEyesvg {
    font-family: 'Montserrat';
}

.positionCategoryForMob {
    font-family: 'Montserrat';
}
.form-control-test-Fs14_Fw_400{
    height:46px;
}


.smile{
    position:absolute;
     right:20px;
}
.wglpausecircleForposition {
    position: relative;
    right: 63px;
    top: -26px;
}
.heart-explosion {
    position: relative;
    
    
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    cursor: pointer;
  }
 
  
  
  .heart-emoji {
    position: absolute;
    font-size: 24px;
    
    -webkit-animation: explode 1s ease-out infinite;
    
            animation: explode 1s ease-out infinite;
    -webkit-animation-delay: calc(0.1s * var(--index));
            animation-delay: calc(0.1s * var(--index));
    cursor: pointer;
  }
  .heart{
    position: absolute;
    right: 36%;
    bottom: 20px;
  }
  @-webkit-keyframes releaseHeart {
    0%, 100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
  }
  @keyframes releaseHeart {
    0%, 100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
  }

  
  @-webkit-keyframes explode {
    0% {
      -webkit-transform: translateY() scale(0);
              transform: translateY() scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(-100px) scale(1);
              transform: translateY(-100px) scale(1);
      opacity: 1;
    }
  }

  
  @keyframes explode {
    0% {
      -webkit-transform: translateY() scale(0);
              transform: translateY() scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(-100px) scale(1);
              transform: translateY(-100px) scale(1);
      opacity: 1;
    }
  }


@media (max-width:1280px) {

    .wglpausecircleForposition {
        position: relative;
        right: 63px;
        top: -26px;
    }
    

}

@media (max-width:1000px) {
    .golive-comment-block {
        display: none;
    }
}


@media (max-width: 768px) {
    .wglpausecircleForposition {
        position: absolute;
        right: 14px;
        top: -192px;
    }

    .postitionStopForButton.wglpausecircle {
        position: absolute;
        left: 50%;
        -webkit-transform: translate(-50%, 0%);
                transform: translate(-50%, 0%);
        bottom: 45px;
    }


    .commentInputForposition {
        position: absolute;
        right: 27px;
        bottom: 6px;
    }

    .positionTimerForMob {
        position: absolute;
        left: 9px;
        background-color: transparent;
        font-size: x-large;
        font-weight: 500;
        top: -480px;
    }

    .positionCategoryForMob {
        position: absolute;
        top: 84px;
        font-size: smaller;
    }

    .positionWatchingForMob {
        position: absolute;
        top: 100px;
        left: 1px;
    }

    .positionForEndbutton {
        position: absolute;
        top: -465px;
        right: 28px;
    }

}

@media (max-width: 567px) {

    .positionIconForMob {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .positionTimerForMob {
        position: absolute;
        top: -600px;
        right: 20px;
        background-color: transparent;
        font-size: x-large;
        font-weight: 500;
    }

    .positionCategoryForMob {
        position: absolute;
        top: 46px;
        font-size: smaller;
    }

    .positionWatchingForMob {
        position: absolute;
        top: 60px;
        left: 1px;
    }

    .positionMicForMob {
        position: absolute;
        right: 35px;
        top: -145px;
    }

    .positionSendForMob {
        position: absolute;
        right: 28px;
        top: -95px;
    }

    .positionMoreviewForMob {
        position: absolute;
        right: 33px;
        top: -50px;
    }

    .rotateIconMoreview {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        transition: all 0.25s ease-in-out;
    }

    .postitionStopForButton.wglpausecircle {
        position: absolute;
        left: 50%;
        -webkit-transform: translate(-50%, 0%);
                transform: translate(-50%, 0%);
        /* bottom: 50px; */

    }

    .inputboxForposition {
        display: block;
        position: relative;

    }

    .commentInputForposition {
        position: absolute;
        bottom: 5px;
    }

    .iconForcolor {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        transition: all 0.25s ease-in-out;
        margin-left: 10px;
    }

    .InputForcolor {
        background: rgba(249, 251, 247, 0.4);
        border: 1px solid rgba(126, 178, 98, 0.1);
        padding-right: 24%;
        position: absolute;
        bottom: 0;
    }

    .wglpausecircleForposition {
        position: absolute;
        right: 416px !important;
    }

    .postitionStopForButton.wglpausecircle {
        position: absolute;
        left: 50%;
        -webkit-transform: translate(-50%, 0%);
                transform: translate(-50%, 0%);
        bottom: 45px;
    }

}


@media (max-width: 425px) {
    .wglpausecircleForposition {
        position: relative;
        left: -15px;
        top: -192px;
    }

    .postitionStopForButton.wglpausecircle {
        position: absolute;
        left: 50%;
        -webkit-transform: translate(-50%, 0%);
                transform: translate(-50%, 0%);
        bottom: 45px;
        display: none;
    }

    .positionForEndbutton {
        position: absolute;
        top: -535px;
        right: 28px;
    }

    .positionTimerForMob {
        position: absolute;
        left: 9px;
        background-color: transparent;
        font-size: larger;
        font-weight: 500;
        top: -500px;
    }
    .postitionForleftsidebar>div {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        top: 60px;
    }

}


@media (min-width: 300px){
    
    .postitionForleftsidebar>div {
        /* display: flex;
        flex-direction: column; */
     

        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        top: 31px;

    }
   
}
@media (min-width: 600px){
    
    .postitionForleftsidebar>div {
        /* display: flex;
        flex-direction: column; */
     

        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        top: 94px;

    }
}
@media (min-width: 400px){
    
    .postitionForleftsidebar>div {
        /* display: flex;
        flex-direction: column; */
     

        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        top: 70px;

    }
}

@media (max-width: 992px) {
    .wglpausecircleForposition {
        position: relative;
        left: -16px;
    }

    .postitionForleftsidebar {
        position: fixed;
        bottom: 0px;
        /* transform: translate(309px, -137px); */
        -webkit-transform: translateY(calc(100% - 150%));
                transform: translateY(calc(100% - 150%));
        right: 10px;
        height: 30vh;
        z-index: 1;

    }

    /* { for thr hewart animation } */

    .heart-explosion {
        position: relative;
        
        
        display: flex;

        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        cursor: pointer;
      }
      .heart-explosion:hover{
        cursor: pointer;
      }
      
      .heart-emoji {
        position: absolute;
        font-size: 24px;
        -webkit-animation: explode 1s ease-out infinite;
                animation: explode 1s ease-out infinite;
        -webkit-animation-delay: calc(0.1s * var(--index));
                animation-delay: calc(0.1s * var(--index));
        cursor: pointer;
      }
      @-webkit-keyframes releaseHeart {
        0%, 100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        50% {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
        }
      }
      @keyframes releaseHeart {
        0%, 100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        50% {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
        }
      }
  
      
      @-webkit-keyframes explode {
        0% {
          -webkit-transform: translateY() scale(0);
                  transform: translateY() scale(0);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(-100px) scale(1);
                  transform: translateY(-100px) scale(1);
          opacity: 1;
        }
      }
  
      
      @keyframes explode {
        0% {
          -webkit-transform: translateY() scale(0);
                  transform: translateY() scale(0);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(-100px) scale(1);
                  transform: translateY(-100px) scale(1);
          opacity: 1;
        }
      }
/* 
    .postitionForleftsidebar>div {
       
     

        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        top: 45px;

    } */

    .timecontrols {
        padding: revert;
        bottom: 0;
    }

    /* .postitionForleftsidebar>div:nth-child(1) {
        width: calc(100% / 15%);
        margin-top: calc(100% / 5);
    }

    .postitionForleftsidebar>div:nth-child(3) {
        width: calc(100% / 15%);
        margin-top: calc(100% / 5);
    }

    .postitionForleftsidebar>div:nth-child(4) {
        width: calc(100% / 15%);
        margin-top: calc(100% / 5);
    }

    .postitionForleftsidebar>div:nth-child(5) {
        width: calc(100% / 15%);
        margin-top: calc(100% / 5);
    } */

    .postitionStopForButton.wglpausecircle {
        position: absolute;
        left: 50%;
        -webkit-transform: translate(-50%, 0%);
                transform: translate(-50%, 0%);
        bottom: 45px;
    }

    .postitionStopForButton {
        display: none;
    }

    .positionCategoryForMob {
        position: absolute;
        top: 45px;
        font-size: smaller;
    }

    .positionTimerForMob {
        position: fixed;
        left: 9px;
        background-color: transparent;
        font-size: large;
        font-weight: 500;
        top: 53px;
    }

    .positionForEndbutton {
        position: fixed;
        top: 10px;
        left: 14px;
        width: 60px;
    }

    .positionWatchingForMob {
        position: absolute;
        top: 85px;
        z-index: 1;
        left: -4px;
    }

    .positionIconForMob {
        position: absolute;
        top: 63px;
        left: 84px;
    }

    .sizeForEyesvg svg {
        width: 28px;
        height: 15px;
    }

    .InputForcolor {
        background: rgba(249, 251, 247, 0.8);
        border: 1px solid rgba(126, 178, 98, 0.1);
        padding-right: 24%;
        /* position: absolute; */
        bottom: 0;
        position: fixed;

        margin-left: -15px;
    }

    .InputForcolor1 {
        background: rgba(249, 251, 247, 0.40);
        border: 1px solid rgba(126, 178, 98, 0.1);
        padding-right: 24%;
        /* position: absolute; */
        bottom: 0;
        position: fixed;

        margin-left: -30px;
        opacity: 0.70;
    }
    .inputgolive {
        background: #c4c4c4;
        border: 1px solid rgba(126, 178, 98, 0.1);
        padding-right: -67%;
        /* position: absolute; */
        bottom: 0;
        position: fixed;
        margin-left: -16px;
    }

    .commentInputForposition {
        position: fixed;
        right: 14px;
        bottom: 7px;
    }
    .commentInputForposition1 {
        position: fixed;
        right: 20px;
        bottom: 7px;
    }

}



@media (max-width: 480px){
.show1 {
    left: -1px;
}
.dropshow{

}
}

@media (max-width: 360px){
.show1 {
    left: -1px;
}
.dropshow{
 left: -28px !important;
}
.checkbox{
    top: -8px;
   }

}







/* animation for the comment box  */

@-webkit-keyframes commentOpen {
    from {
        height: 100%;
    }

    to {
        height: 100vh;
    }
}

@keyframes commentOpen {
    from {
        height: 100%;
    }

    to {
        height: 100vh;
    }
}


.Fs14_Fw_400 {
    font-size: 14px !important;
    font-weight: 400 !important;
}


.goLiveRedBtn {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}
@media (max-width: 320px){
.ShareOnlineUser {
    position: absolute;
    top: 2px;
    left: -12px;
}
.playcircle{
    margin-right: -1px !important;
    padding-left: 3px;
}
.playci{
    margin-left: 5px;
}
.sendtext{
    padding-left: 13px;
}
.golivered{
    width: 90px;
    padding-left: 8px;
}
.textvideo{
    left: -16px ;
    padding-right: 2px;
}
.dropshow{
    left: -46px !important;
   }

   .drouparrow1 {
    position: absolute;
    right: 12px !important;
}
.priv{
    width: 175px;
   }
.golivehead{
    margin-top: 1.5rem;
}
}

@media (max-width: 331px){
    .readylive {
        width: 150px;
    }
}

@media (max-width: 375px){
   .checkbox{
    top:-8px;

   }
}
@media (max-width: 728px){
    .goliveheader{
        position: relative;
    left: 14px;
    width: 87%;

    }
}
@media (max-width: 768px){
    .goliveheader{
        position: relative;
        left: 2px;
    width: 99% !important;

    }
    .serchfiltergolive{
        margin-left: 6rem;
      
    }
}
@media (max-width: 466px){
    .goliveheader{
        position: relative;
        left: 9px;
        width: 96% !important;
    }
    .show1{
        left: 1px;
    }
  
}
@media (max-width: 412px){
    .goliveheader{
        position: relative;
        left: 9px;
        width: 94% !important;
    }
    .padbutton{
        padding: 8px 4px;
       }
    .priploi{
        left: -10px;
        width: 241px;
    }
    .dropshow{
        left: -13px !important;
       }
  .checkbox{
        top:-8px;
    
       }
}


@media (max-width: 766px){
    .goliovehead{
        
       position: absolute;
       top: 10px;
    }
}
@media (max-width: 390px){
    .goliovehead{
        
      width: 96% !important;
    }
    .dropshow{
        left: -20px !important;
       }
    .checkbox{
        top:-8px;
    
       }
}
@media (max-width: 384px){
    .dropshow{
        left: -23px !important;
       }
    
}
/* @media (max-width: 280px){
    .goliovehead{
        
      width: 96% !important;
    }
    
    .textpadd{
        padding-left: 2px;
    }
} */
@media (max-width: 766px){
    .serchfiltergolive{
        margin-left: 11rem;
      
    }
}
@media (max-width: 393px){
    .checkbox{
        top:-8px;
    
       }
}
@media (max-width: 342px){
    .serchfiltergolive{
        margin-left: 5rem;
      
    }
}
@media (max-width: 500px){
    .serchfiltergolive{
        margin-left: 4rem;
        margin-right: -5px;
      
    }
    .icon2{
        margin-left: -7px;
    }
}
@media (max-width: 314px){
    .serchfiltergolive{
        margin-left: 3.5rem;
        margin-right: -13px;
    }
   
}
/* @media (max-width: 625px){
    .serchfiltergolive{
        position: relative;
        left: 10%;
      
    }
}
@media (max-width: 625px){
    .serchfiltergolive{
        position: relative;
        left: 10%;
      
    }
} */


@media (max-width: 280px) {
    .srechgolive{
        margin-left: -4px;
    }
    .golivered{
        padding: 9px 8px;
    }
    .goliovehead{
        position: absolute;
        top: 13px;
        left: -1px !important;
    }
    .filter{
        margin-left: -5px !important;
        
    margin-right: -6px !important;
    }
    .serchfiltergolive {
        margin-left: 3.15rem;
        margin-right: -13px;
    }
    .livgif{
        height: 24px;
    }
    .textpadd{
        padding-left: 4px;
    }

    .show1{
        left: -58px !important;
    }
    .down2{
        margin-right: -14px;
        right: -4px;
       }
        
       .down{
       right: -17px;
       }
        
       .goliovehead{
        
        width: 96% !important;
      }
      
      .textpadd{
          padding-left: 2px;
      }
      .dropshow{
        left: -1px !important;
       }
       .priv{
        width: 175px;
       }
       .checkbox{
        top: -8px;
       }
    }
    

@media (max-width: 350px) {
    .goliveheader {
        position: relative;
        left: 2px;
        width: 98% !important;
        top: 7px;
        margin-top: 23px;
    }
    .padbutton{
        padding: 9px 8px;
    }
}
@media (max-width: 322px) {

    .seeall {
        width: 101px;
        position: relative;
        left: 32px;
    }
    .down2{
        margin-right: -14px;
        right: -4px;
       }
}
@media (max-width: 358px) {

   .padbutton{
    padding: 8px 4px;
   }
}

.goliovehead{
    position: absolute;
    top: 13px;
    left: 3px;
}



.custom-select {
    position: relative;
    display: inline-block;
  }
  
  .custom-select select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 10px;
    color: #4d4d4d;
  }
  
  .custom-select__arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    pointer-events: none; /* Ensure the arrow doesn't interfere with the select box */
    color: #6E6B7B; /* Color for the chevron-down icon */
    font-size: 16px; /* Adjust the font size as needed */
  }
  

  /* @media (max-width: 280px) {
    .golivered.padbutton {
      
      padding: 6px 8px; 
      font-size: 14px; 
    }

    .drouparrow1{
        position: absolute;
        right: 12px !important;
      }
      .textvideo{
        left: -14px;
        padding-right: 2px;
    }
   
  
  } */

  @media (max-width: 333px) {

    .searrch{
        padding: 10px 10px 10px 18px !important;
    }

  }
  @media (max-width: 542px) {

    .textvideo{
        left: -10px;
        padding-right: 2px;
    }
    .show1{
        left: -15px;
    }
    .show3{
        left: -10px;
    }
  
   
  }
  @media (max-width: 315px) {

    .filter{
        padding: 8.5px 5px !important;
    }
  }
  @media (max-width: 393px) {

    .golivebutton{
        position: absolute;
        right: 13px;
    
    }
    .priploi{
        left: -11px;
    width: 242px;
    }
    .dropshow{
        left: -32px !important;
       }
    
  }

  .drouparrow{
    position: absolute;
    right: 22px;
  }
  .drouparrow1{
    position: absolute;
    right: 22px;
  }



.golivebutton{
    position: absolute;
    right: 13px;

}

.smileemoji{
    position: absolute;
    right: 31px;
}






.blinkab {
    -webkit-animation: blinkAnimation 1s infinite;
            animation: blinkAnimation 1s infinite;
    background-color: red;
  }
  .podliveblinkes{
    border-radius: 20px;
    position: absolute;
    left: 94px;
    top: 56px;
    font-size: 19px;
  }
  .podliveblink{
    border-radius: 20px;
    position: absolute;
    left: 16px;
    top: 9px;
    font-size: 19px;
    
    font-size: 0.75rem !important;

  }
  
  @-webkit-keyframes blinkAnimation {
    0%, 50%, 100% {
      opacity: 1;
    }
    25%, 75% {
      opacity: 0;
    }
  }
  
  @keyframes blinkAnimation {
    0%, 50%, 100% {
      opacity: 1;
    }
    25%, 75% {
      opacity: 0;
    }
  }




  .heart-explosion1 {
    position: relative;
    
    
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    cursor: pointer;
  }
 
  
  
  .heart-emojii {
    position: absolute;
    font-size: 24px;
    
    -webkit-animation: explode 1s ease-out infinite;
    
            animation: explode 1s ease-out infinite;
    -webkit-animation-delay: calc(0.1s * var(--index));
            animation-delay: calc(0.1s * var(--index));
    cursor: pointer;
  }
  .heart{
    position: absolute;
    right: 17%;
    bottom: 20px;
  }
  @-webkit-keyframes releaseHeart {
    0%, 100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
  }
  @keyframes releaseHeart {
    0%, 100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
  }

  
  @-webkit-keyframes explode {
    0% {
      -webkit-transform: translateY() scale(0);
              transform: translateY() scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(-100px) scale(1);
              transform: translateY(-100px) scale(1);
      opacity: 1;
    }
  }

  
  @keyframes explode {
    0% {
      -webkit-transform: translateY() scale(0);
              transform: translateY() scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(-100px) scale(1);
              transform: translateY(-100px) scale(1);
      opacity: 1;
    }
  }

  .heart-animation-app1{
    position: relative;
    right: 30%;
  }

  @media (min-width: 576px) {

  .mobheaderstyles{
    padding: 20px;
    padding: .75rem !important;
  }
  .podliveblinkes{
    border-radius: 20px;
    position: absolute;
    left: 113px;
    top: 73px;
    font-size: 19px;
  }
}
  
  @media (max-width: 576px) {

  .mobheaderstyles{
    padding: 20px;
    padding: .75rem !important;
  }
  .podliveblinkes{
    border-radius: 20px;
    position: absolute;
    left: 113px;
    top: 73px;
    font-size: 19px;
  }
}
  

@media (max-width: 280px){

  .podliveblinkes{
    border-radius: 20px;
    position: absolute;
    left: 93px;
    top: 63px;
    font-size: 14px;
}
}
  
  @media (min-width: 280px) {

  .InputForcolor-createlive{

  }
  .mobheaderstyles{
    padding: 20px;
    padding: .75rem !important;
  }
  .podliveblinkes{
    border-radius: 20px;
    position: absolute;
    left: 97px;
    top: 65px;
    font-size: 14px;
  } 
  .podliveblinkess{
    border-radius: 20px;
    position: absolute;
    left: 97px;
    top: 65px;
    font-size: 14px !important;
  } 
  .podliveblink{
    border-radius: 20px;
    position: absolute;
    left: 16px;
    top: 9px;
    font-size: 19px;
    
    font-size: 0.75rem !important;
  }
  .comimgsss{
     
      padding-right: 0.5rem !important;
      padding-left: 1.0rem !important;
  
  }
}
.pxy-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

/* video */

/* .video-container {
  position: relative;
  width: 100%;
   /* 16:9 aspect ratio (adjust as needed) 
  overflow: hidden;
}

.video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video {
  width: 105%;
    height: 111%;
  object-fit: cover;
} */

/* @media (min-width: 1280px) {

  .video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; 
    overflow: hidden;
  }
  
  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
} */
/* @media (min-width: 280px) {
  .video-container {
    position: relative;
    width: 100%;
    
    overflow: hidden;
    object-fit: cover;
    object-position: center center;
  }
  
  .video-wrapper {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 230px;
  }
} */

.video-container1 {
  position: fixed;
  width: 100%;
  height: 100%;

  overflow: hidden;
}

.video {
  /* position: fixed; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 280px){
  .video-container1 {
    position: absolute;
    width: 100%;
    height: 100%;
  
    overflow: hidden;
  }
  .video {
    /* position: fixed; */
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .ShareOnlineUser {
    position: absolute;
    top: 2px;
    left: -12px;
  }
  
}
@media (min-width: 580px){
  .video-container1 {
    position: fixed;
    width: 100%;
    height: 100%;
  
    overflow: hidden;
  }
  .video {
    /* position: fixed; */
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  
}
@media (max-width: 280px){
  .whocan{
         position: relative;
         width: 140px;


}
.readylive{
  width: 150px;
}
}
@media (max-width: 280px){
  .whocan{
    position: relative;
    left: 0px;
    top: 2px;
    width: auto;
  }
}
@media (max-width: 1191px){
  .detailheart{
    position: absolute;
    right: 13% !important;
    top: 10px;
  }
  
}

.micsend{
  position: relative;
  left: 20px;
  
}

.reposect {
  position: relative; /* Create a positioning context */
}

.line {
  position: absolute; /* Position the line absolutely within the container */
  bottom: -10px;/* Align the line to the bottom of the container */
  left: 0; /* Align the line to the left edge of the container */
  width: 100%; /* Make the line span the entire width */
  height: 1px; /* Set the line height (thickness) */
  background: rgba(0, 0, 0, 0.10); /* Line color */
}



.eyees{
  top: -10px;
}

.goliveblink{
  top: 17px;
  left: 10px;
}

.detailheart{
  position: absolute;
  right: 10%;
  top: 10px;
}

@media (max-width: 1000px){
  .detailheart {
    position: absolute;
    right: 14%;
    top: 8px;
}
}

.custom-select {
   /* Remove the default dropdown arrow in some browsers */
  background-image: url('https://static.thenounproject.com/png/1123247-200.png'); /* Add your dropdown icon image path here */
 
  
  padding-right: 30px; /* Adjust this value to align the icon properly */
}

.canvas{
  text-align: left;
  /* position: relative; */
  /* left: 36px; */
  margin-left: 8px;
}

.canvas1{
  border-radius: 22px 22px 0px 0px;
background: #FFF;
box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.10);
height: auto;
}

.canvas li:hover {
  background-color: #E6F4F3;
  /* Add any other styling you want for the hover effect */
}

@media(max-width:280px){
.sendtext{
  padding-left: 10px;
}
}

.canbutton{
  position: relative;
  top: 16px;
}

.view{
 
  border-radius: 5px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 0px;
}
.view1{
 
  
    minWidth: '500px',
   
}

.eyesicon{

left: 54px;

}

.usernamees{
  margin: 0 0 1px 0px !important
}
.heartcount{
  position: relative;
  left: 24px;
    top: -6px;
}
.heart-button{
  position: relative;
  top: 19px;
}

.heartplacing{
  position: relative;
  right: 32%;
  top: -20%;
}

.py-11{
  padding-top: 9px;
  padding-bottom: 4px;
}
.py-112{
  padding-top: 9px;
  padding-bottom: 10px;
}

.sendicon {
  position: absolute;
  right: 2%;
  top: 32%;
}
@media (max-width: 417px) {
  .filter-text {
      display: none;
  }
}

.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 10px;
  color: #4d4d4d;
}

.custom-select__arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  pointer-events: none; /* Ensure the arrow doesn't interfere with the select box */
  color: #6E6B7B; /* Color for the chevron-down icon */
  font-size: 16px; /* Adjust the font size as needed */
}

.canvas li {
  border-bottom: 1px solid #ccc; /* Add a border at the bottom of each li */
  padding: 8px 0; /* Adjust padding as needed */
  color:#E6F4F3 ;
}

.canvas li:last-child {
  border-bottom: none; /* Remove border from the last li */
}

.my-4{
  margin-top: 0.65rem !important;
  margin-bottom: 1rem !important;
}


.hrtcnt{
  position: relative;
  top: 17px;
}

.reort{
  align-items: center;
}

.spacing{
  margin-left: 0.70rem!important;
}


.emoj{
  position: absolute;
    right: 26px;
    top: 3px;
}

.mobile{
  position: relative;
  left: 9px;
}

.mobileheart{
  position: absolute;
  top: -52px;
  left: 8px;
}
.scrolledContent {
  overflow-y: auto;
  margin-top: 80px;
}

.ad-left {
  background-color: white;
  box-shadow: 0px 0px 10px rgb(129 193 75 / 10%);
}

.borderForADSelection {
  box-shadow: 0px 0px 5px 2px rgba(129, 193, 75, 0.94) !important;
  background: #e5f0e0 !important;
}

.color-grey {
  color: #a3a5a5;
}

.desh-icon-main {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  width: 300px;
  /* margin: auto; */
  margin-top: 15px;
  align-items: center;
  /* height: 100vh; */
}

/* CREATE AD */
.brand-main-new {
  width: unset;
  height: unset;
  background: unset;
}

.step-select-new {
  position: absolute;
}

.ad-count-btn {
  width: 44px;
  height: 44px;
  background: #81c14b;
  border-radius: 30px;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.ad-count-step {
  font-weight: 600;
  font-size: 16px;
  color: #81c14b;
}

.ad-count-heading {
  font-style: normal;
  font-weight: 500;
  color: #4d4d4d;
  font-weight: bold;
}

.ad-count {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.step-ad-heading {
  box-shadow: 0px 0px 10px rgb(129 193 75 / 10%);
  background-color: white;
  padding: 1rem 0rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.ad-type {
  padding: 1.5rem;
  background-color: white;
  box-shadow: 0px 0px 10px rgb(129 193 75 / 10%);
  margin: 0.5rem;
  align-items: center;
}

.btn-cancel {
  padding: 12px 3rem;
  background: #e0e0e0;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  color: black;
  font-size: 14px;
}

.btn-next:hover {
  color: white;
}

.btn-next {
  padding: 12px 3rem;
  background: #81c14b;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  color: white;
  font-size: 14px;
}

.ad-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}

.step-section {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
}

.radio_animated_lg:checked:before {
  padding: 7px;
}

.radio_animated_lg:checked:after {
  padding: 10px;
}

.radio_animated_lg:after {
  padding: 10px;
}

.ch-150 {
  height: 150px !important;
}

/* TYPES OF TYPE */
.ad-types-type {
  flex-direction: column;
  box-shadow: unset;
}

.ad-types-type-dec {
  display: contents;
}

.ad-types-type {
  background: #f6f6f6;
  border-radius: 10px;
}

.dec-types {
  padding: 0 5rem;
  text-align: center;
  color: black;
}

.ad-types-of-type-map {
  padding: 1rem;
  background-color: white;
  border-radius: 5px;
}

/* SINGLE IMAGE AD */
.navigate {
  padding-bottom: 1rem;
}

.p-heading {
  font-weight: bold;
  font-size: 16px !important;
  line-height: 21px;
  color: #4d4d4d;
  margin-bottom: 0.5rem;
  margin-right: 0.3rem;
  font-family: "Poppins", sans-serif;
}

.upload-img {
  width: 400px;
  height: 200px;
  background: #f6f6f6;
  border: 1px dashed #c4c4c4;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}
.upload-imgs {
  width: 400px;
  height: 200px;
  background: #f6f6f6;
  border: 1px dashed #81c14b;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}
.p-max-car {
  margin-top: 0.5rem;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #808080;
}

.btn-toggle {
  margin: 0 1rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
}

.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
  outline: none;
}

.btn-toggle:before,
.btn-toggle:after {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}

.btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}

.btn-toggle.active {
  transition: background-color 0.25s;
}

.btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
  background: #81c14b;
}

.btn-toggle.active:before {
  opacity: 0.5;
}

.btn-toggle.active:after {
  opacity: 1;
}

.btn-size {
  padding: 12px 25px !important;
}

.navigate-right {
  display: block;
  text-align: end;
}

.navigate-color {
  color: #81c14b;
  font-family: "Poppins", sans-serif;
}

/* CAROSEL AD */
.carosel-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ad-btn-new {
  background: transparent;
  border: 1px solid #81c14b;
  display: inline;
  font-size: 13px;
  border-radius: 5px;
  padding: 10px 30px;
  margin: 5px;
}

.full-video-ad-main {
  background-color: #f6f6f6;
}

.full-video-ad {
  width: 140px;
  display: block;
  margin: auto;
}

/* save-audience */
.save-audience {
  flex-direction: column;
}

.a-price-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
}

.a-price-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.a-price {
  font-weight: bold;
  font-size: 16px !important;
  line-height: 21px;
  color: #81c14b;
  margin-bottom: 0.5rem;
  margin-right: 0.3rem;
}

.total-amount {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.total-amount p {
  margin: 0.5rem 0;
}

.total-amount h2 {
  font-family: "Poppins", sans-serif !important;
  font-size: 2.5rem;
  margin: 0;
  color: #51595e;
}

/* PREVIEW */
.preview-main {
  background-color: unset;
  margin: unset;
  padding: 1rem 0rem;
}

.preview-left-end {
  background-color: white;
  box-shadow: 0px 0px 10px rgb(129 193 75 / 10%);
  padding: 1rem;
  border-radius: 5px;
}

.post-panel-new {
  background-color: white;
  overflow: hidden;
}

.preview-grp-post > img {
  width: 90px;
  height: 100px;
  border-radius: 1rem 1rem rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.preview-event > img {
  width: 150px;
  height: 150px;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.head-less-preview {
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #ebebeb;
  /* width: 75%; */
  border-radius: 5px;
  cursor: pointer;
}

.tag {
  padding: 1rem;
  font-size: inherit;
  font-weight: 500;
}

.Why-this-ad p {
  text-decoration: underline;
  color: darkgray;
}

.transparent-btn {
  padding: 5px 20px !important;
}

.ad-heading-upsd-title-input p {
  font-style: normal;
  font-weight: 400;
  /* font-size: 12px; */
  line-height: 18px;
  font-weight: bold;
  color: #4d4d4d;
  margin: 1.3rem 0rem;
}

/* LEAD GENREGATION */
.lead-greem {
  color: #81c14b;
  font-size: 14px;
}

.lead-switch {
  flex-direction: row;
  justify-content: space-between;
}

.lead-desh {
  display: contents;
}

.lead-add {
  color: #81c14b;
  font-size: 14px;
  font-weight: 600;
}

.preview-img > img {
  width: 135px;
  height: 135px;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.preview-fake-img {
  width: 135px;
  height: 135px;
  background-color: #c4c4c4;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.preview-img-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.preview-detail-small {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.preview-small {
  font-size: smaller;
}

.preview-grp-img > img {
  width: 165px;
  height: 135px;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.preview-heading {
  margin: 0.5rem 0;
  font-weight: 600;
}

.preview-know {
  text-decoration: underline;
  font-weight: 600;
}

.preview-main-border {
  padding: 0.5rem;
  box-shadow: 0rem 0rem 0.5rem 0rem #0000004a;
  width: 180px;
  height: 205px;
  border-radius: 0.5rem;
}

.fake-heading {
  background-color: #c4c4c4;
  height: 10px;
  /* width: 135px; */
  margin: auto;
}

.fake-p {
  height: 10px;
  background-color: #c4c4c4;
  width: 65%;
}

.fake-know {
  height: 10px;
  background-color: #c4c4c4;
  width: 15%;
}

.fake-know-main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.preview-event-know-more {
  border-top: 1px solid #647589;
}

.preview-event-know-more > p {
  color: #647589;
  margin-bottom: 1rem;
}

.preview-more {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
}

/* SMALL PREVIEW SCREENS */
.small-bg {
  background-color: #ebebeb;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.small-p-heading {
  font-weight: bold;
  font-size: 10px !important;
  /* line-height: 21px; */
  color: #4d4d4d;
  /* margin-bottom: 0.5rem; */
  margin-right: 0.3rem;
}
.sponsored-text {
  font-size: 9px !important;
}
.small-tag {
  font-size: 9px;
  font-weight: 500;
  text-align: justify;
}

.small-recom-btn-cont-blk {
  display: none;
}

.small-Why-this-ad {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 1rem; */
}

.small-preview-img > img {
  width: 60px;
  height: 60px;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.small-preview-fake-img {
  width: 60px;
  height: 60px;
  background-color: #c4c4c4;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.small-preview-heading {
  font-size: 10px;
}

.small-preview-small {
  font-size: 6px;
}

.small-preview-main-border {
  padding: 0.5rem;
  box-shadow: 0rem 0rem 0.5rem 0rem #0000004a;
  width: 90px;
  height: 115px;
  border-radius: 0.5rem;
}

.small-preview-grp-img > img {
  width: 75px;
  height: 70px;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.small-preview-fake-img {
  /* width: 102px; */
  height: 60px;
  background-color: #c4c4c4;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.small-preview-grp-post {
  width: 40px;
  height: 40px;
  border-radius: 1rem 1rem rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.small-preview-event > img {
  width: 75px;
  height: 75px;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.smalls-preview-fake-img {
  width: 150px;
  height: 95px;
  background-color: #c4c4c4;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.smalls-preview-main-border {
  padding: 0.5rem;
  box-shadow: 0rem 0rem 0.5rem 0rem #0000004a;
  width: 218px;
  height: 160px;
  border-radius: 0.5rem;
}

.small-bg-main {
  cursor: pointer;
  height: 55vh;
  background: #c4c4c4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.5rem;
  border: 2px solid #81c14b;
}
.small-bg-mains {
  cursor: pointer;
  /* height: 55vh; */
  background: #c4c4c4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.5rem;
  border: 2px solid #81c14b;
}
.small-bg-main > .post-panel-new {
  border: unset;
}

.small-padding-fake {
  padding: 6px;
}

.small-preview-more {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 600;
  font-size: 10px;
  text-transform: capitalize;
}

.head-preview {
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #ebebeb;
  /* width: 50%; */
  border-radius: 5px;
  cursor: pointer;
}

.preview-heading-head {
  padding: 0.5rem 0;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0rem 0rem 1rem 0rem #00000014;
}

.preview-logo {
  margin-top: 1px;
  background-color: white;
  padding: 0.8rem;
}

.bg-white {
  background-color: white;
}

.full-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.post-details-new {
  padding: 8px;
}

.mp-overword {
  position: relative;
  margin-top: -30px;
  background: #212529ad !important;
  padding: 5px 10px;
  color: white;
}

.AdHov:hover {
  background-color: #e5f0e0;
}

.AdDurationForStyle {
  background: #f6f6f6;
  border-radius: 5px;
}

.AdDurationForStyle h4 {
  background: #f6f6f6;
  font-weight: 500;
  color: #4d4d4d;
  border-radius: 5px;
  padding: 12px;
}

.total-paid-ad {
  padding: 2rem 1rem;
  /* background: rgba(126, 178, 98, 0.2); */
  margin-bottom: 2rem;
}

.total-paid-ad-after-selected {
  padding: 2rem 1rem;
  background: rgba(126, 178, 98, 0.2);
  margin-bottom: 2rem;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main-section-spss {
  display: flex;
}

.sidebar-main-spss {
  position: absolute;
  width: 350px;
  height: 879px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(129, 193, 75, 0.1);
  overflow: hidden;
}

.desh-icon-main-spss {
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin: auto;
  margin-top: 15px;
  align-items: center;
}

.desh-icon-spss {
  display: flex;
}

.desh-icon-spss i:nth-child(1) {
  color: #6e6b7b;
  padding-right: 10px;
}

.desh-icon-spss p:nth-child(2) {
  color: #4d4d4d;
  /* font-family: 'Poppins'; */
  /* font-family: 'Poppins', sans-serif; */
  font-size: 12px;
  font-weight: 400;
}

.desh-second-spss i:nth-child(1) {
  color: #808080;
}

.create-add-main-spss {
  position: absolute;
  width: 330px;
  height: 40px;
  margin-left: 10px;
  margin-top: 10px;
  align-items: center;
}

.create-add-main-second-spss {
  position: absolute;
  width: 330px;
  height: 40px;
  background: #e5f0e0;
  top: 125px;
  left: 10px;
  border-left: 2px solid #81c14b;
  align-items: center;
}

.create-add-main-third-spss {
  position: absolute;
  width: 330px;
  height: 40px;
  top: 85px;
  left: 10px;
  align-items: center;
}

.create-add-spss {
  display: flex;
  justify-content: space-between;
  width: 298px;
  margin: auto;
  align-items: center;
  margin-top: 12px;
}

.create-add-one-spss {
  display: flex;
  margin-left: 15px;
}

.create-add-one-spss i:nth-child(1) {
  color: #4d4d4d;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.create-add-one-spss p:nth-child(2) {
  color: #81c14b;
  /* font-family: 'Poppins'; */
  font-weight: 400;
  font-size: 12px;
  color: #4d4d4d;
  font-weight: bold;
}

.create-add-second-spss i:nth-child(1) {
  color: #808080;
}

.step-select-main-spss {
  width: 100%;
  height: 91px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
}

.step-select-spss {
  display: flex;
}

.step-select-child-spss {
  display: flex;
  align-items: center;
  margin-top: 10px;
  overflow: hidden;
}

.step-select-one-spss {
  box-sizing: border-box;
  position: absolute;
  width: 145px;
  height: 64px;
  left: 350px;
  margin-top: 14px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.step-select-child-spss button:nth-child(1) {
  position: absolute;
  width: 44px;
  height: 44px;
  left: 22px;
  background: #81c14b;
  border-radius: 30px;
  border: none;
  margin-top: 40px;
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.step-select-child-spss p:nth-child(2) {
  position: absolute;
  left: 76px;
  /* font-family: 'Poppins'; */
  font-weight: 600;
  font-size: 16px;
  color: #81c14b;
  margin-top: 40px;
}

.step-select-second-spss p:nth-child(1) {
  position: absolute;
  left: 526px;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  color: #4d4d4d;
  margin-top: 20px;
  font-weight: bold;
}

.step-select-second-spss p:nth-child(2) {
  position: absolute;
  left: 525px;
  /* font-family: 'Poppins'; */
  font-weight: 400;
  font-size: 12px;
  color: #808080;
  margin-top: 50px;
}

.brand-image-main-spss {
  position: absolute;
  width: 874px;
  height: 541px;
  left: 415px;
  top: 110px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
  border-radius: 5px;
}

.brand-aware-text-spss p:nth-child(1) {
  position: absolute;
  left: 20px;
  top: 20px;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  color: #81c14b;
}

.ad-heading-spss p:nth-child(1) {
  position: absolute;
  left: 20px;
  top: 58px;
  /* font-family: 'Poppins'; */
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #4d4d4d;
}

.ad-heading-second-spss p:nth-child(1) {
  position: absolute;
  left: 20px;
  top: 172px;
  /* font-family: 'Poppins'; */
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #4d4d4d;
}

.ad-heading-third-spss p:nth-child(1) {
  position: absolute;
  left: 20px;
  top: 272px;
  /* font-family: 'Poppins'; */
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #4d4d4d;
}

.ad-sale-spss {
  box-sizing: border-box;
  position: absolute;
  width: 402px;
  height: 45px;
  left: 20px;
  top: 89px;
  background: #ffffff;
  /* border: 1px solid #DEDEDE; */
  border-radius: 6px;
}

.ad-sale-spss p:nth-child(1) {
  position: absolute;
  left: 35.09px;
  top: 12px;
  /* font-family: 'Poppins'; */
  font-size: 14px;
  color: #4d4d4d;
  font-weight: bold;
}

.max-char-spss {
  position: absolute;
  left: 308px;
  top: 135px;
  /* font-family: 'Poppins'; */
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #808080;
}

.ad-sale-second-spss {
  box-sizing: border-box;
  position: absolute;
  width: 402px;
  height: 45px;
  left: 20px;
  top: 200px;
  background: #ffffff;
  /* border: 1px solid #DEDEDE; */
  border-radius: 6px;
}

.ad-sale-second-spss-input-new {
  box-sizing: border-box;
  /* position: absolute; */
  width: 100%;
  height: 45px;
  left: 20px;
  background: #ffffff;
  /* border: 1px solid #DEDEDE; */
  border-radius: 6px;
}

.ad-sale-second-spss p:nth-child(1) {
  position: absolute;
  left: 35.09px;
  top: 12px;
  /* font-family: 'Poppins'; */
  font-size: 14px;
  color: #4d4d4d;
  font-weight: bold;
}

.max-char-second-spss {
  position: absolute;
  left: 308px;
  top: 245px;
  /* font-family: 'Poppins'; */
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #808080;
}

.ad-sale-third-spss {
  box-sizing: border-box;
  position: absolute;
  width: 833px;
  height: 150px;
  left: 20px;
  top: 300px;
  background: #ffffff;
  /* border: 1px solid #DEDEDE; */
  border-radius: 6px;
}

.ad-sale-third-spss p:nth-child(1) {
  position: absolute;
  left: 35.09px;
  top: 12px;
  /* font-family: 'Poppins'; */
  font-size: 14px;
  color: #4d4d4d;
  font-weight: bold;
}

.max-char-third-spss {
  position: absolute;
  left: 725px;
  top: 450px;
  /* font-family: 'Poppins'; */
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #808080;
}

.upload-main-spss p:nth-child(1) {
  position: absolute;
  left: 450px;
  top: 58px;
  /* font-family: 'Poppins'; */
  font-size: 14px;
  color: #4d4d4d;
  font-weight: bold;
}

.upload-main-spss button:nth-child(2) {
  position: absolute;
  width: 401px;
  height: 196px;
  left: 450px;
  top: 88px;
  background: #f6f6f6;
  border: 1px dashed #c4c4c4;
  border-radius: 5px;
  font-size: 14px;
  /* font-family: 'Poppins'; */
  font-weight: bold;
}

.two-bumain-spss {
  display: flex;
}

.two-bumain-spss button:nth-child(1) {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 30px;
  grid-gap: 5px;
  gap: 5px;
  position: absolute;
  width: 111px;
  height: 43px;
  left: 585px;
  top: 480px;
  background: #f6f6f6;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
}

.two-bumain-spsss button:nth-child(1) {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 30px;
  grid-gap: 5px;
  gap: 5px;
  position: absolute;
  width: 111px;
  height: 43px;
  left: 720px;
  top: 480px;
  background: #c4c4c4;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}

/* .brand-image-main-spyy{
  margin-top: 70px;
} */
.brand-image-main-spss {
  margin-top: 70px;
}

.total-budget {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.total-budget-time {
  justify-content: space-around;
  align-items: baseline;
  background: #f6f6f6;
  height: 100px;
}

.total-budget-time p {
  font-size: 1rem;
}

.budget-btn {
  background: #81c14b;
  color: white;
  border: none;
  border-radius: 5px;
  width: 150px;
  height: 43px;
}

.budget-btn-main {
  display: flex;
  justify-content: end;
}

.in-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.main-left {
  border-bottom: 1px solid rgb(180, 180, 180);
}

.transparent-btn {
  background: transparent;
  color: #81c14b;
}

.new-recom-btn-cont-blk {
  margin: -70px 0 10px !important;
  position: absolute;
  width: 100%;
  background-color: #212529ad !important;
}

.bg-bullet {
  color: #c4c4c4;
}

.Publish-btn {
  width: 100% !important;
}

.preview-data {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.borderLeftForDasboard {
  border-left: 5px solid #81c14b;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.15) inset;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 20px -20px; */
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

.paddingForStyle p {
  padding-bottom: 0.5rem;
}

/* MEDIA QUERY START HERE */
@media (max-width: 575px) {
  .ad-left {
    display: none;
  }

  .pad-zero {
    padding: 0%;
  }

  .ad-types-of-type-map {
    margin: 1rem 0;
  }

  .input-img {
    display: flex;
    flex-direction: column;
  }

  .upload-img {
    width: 237px;
  }

  .ad-types-of-type-map {
    padding: 1rem 0;
  }
}
.account-text {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 15px;
}
.account-texts {
  color: #81c14b;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}
.Account-text-section {
  border: 1px solid #f6f6f6;
  padding: 10px;
  background-color: #f5fff7;
  border-radius: 5px;
}
.Account-text-sections {
  border: 1px solid #f6f6f6;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
}
.trash-icon {
  position: relative;
  color: red;
  cursor: pointer;
}
.image-text {
  margin-top: -15px;
}
.term-text {
  color: #1066ad;
  font-size: 14px;
  font-weight: 600;
  margin-top: 12px;
  text-decoration: underline;
}
.view-text {
  color: #48985c;
  font-size: 14px;
  font-weight: 600;
}
.custom-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.custom-message p {
  color: #717171;
  font-size: 14px;
  font-weight: 400;
}
.header-section-request {
  padding: 47px;
}
.modal-cross-icon {
  position: relative;
}
.cross-icon-section {
  position: absolute;
  right: 11px;
}
/* .nav-pills .nav-link.active, */
.nav-pills .show > .nav-link {
  color: #81c14b;
  background-color: #ffffff;
}
.nav-pills .nav-link {
  background: none;
  border: transparent;
  border-radius: 0.25rem;
  color: #000000;
  font-size: 16px;
  font-weight: 300;
}
.css-13xfq8m-MuiTabPanel-root {
  padding: 21px !important;
}
.hover-text:hover {
  background-color: rgb(129 193 75 / 20%);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white;
  opacity: 1;
}

.hideborders {
  /* border: none; */
  display: none;
}
.hidebordertext {
  border: none;
}
.hide-text {
  margin-left: -17px;
}
.dfdsfdf {
  border: none;
}
.dropdownText {
  margin: 0 auto;
  z-index: 10000;
  width: 300px;
  position: relative;
}
.dropdownText,
.dropdownText * {
  z-index: 10;
  width: auto;
}
.start-text {
  margin-top: -25px;
  margin-left: 28%;
  color: gray;
}
.start-texts {
  position: absolute;
  /* top: 129px; */
  color: gray;
  margin-top: 4px;
  left: 38%;
}
.dropdownText .dropdownText-btn {
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #777;
  font-weight: 500;
}
.text-field{
  margin-left: -14px;
}
.input-minus-text{
  box-shadow: #51595e;
}
.dropdowncon-content {
  /* position: absolute; */
  /* top: 110%; */
  left: 0;
  width: 100%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
}
.dropdowncon-content .item {
  padding: 10px;
  cursor: pointer;
}
.dropdowncon-content .item:hover {
  background: rgb(229, 240, 224);
}

/* button {
  z-index: -1;
  display: block;
  width: 300px;
  margin: 0 auto;
} */

.css-1g9qunv-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent !important;
}

.badge-warning {
  color: #808080;
  background-color: rgba(255, 193, 7, 0.5) !important;
}

.badge-danger {
  color: #fff;
  background-color: rgba(220, 53, 69, 0.5);
  border: 1px solid rgba(220, 53, 69, 0.5) !important;
}

.WQNxq {
  overflow-x: auto !important;
}

.WQNxq::-webkit-scrollbar {
  height: 5px;
  /* for Chrome, Safari, and Opera */
}

.fuLPYh {
  overflow-x: auto !important;
}

.fuLPYh::-webkit-scrollbar {
  height: 5px;
  /* for Chrome, Safari, and Opera */
}

.AnalyticsForAnime {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  /* background: whitesmoke; */
  -webkit-animation-iteration-count: 1, 0;
          animation-iteration-count: 1, 0;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  transition: 0.4s ease-in-out;
}

.AnalyticsForAnime:hover {
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
  /* box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25); */
}

.FundsForstyle {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.FundsForstyle h2 {
  margin: 0;
  color: #51595e;
  font-size: calc(27px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.UserEngagemendtStyle {
  text-align: center;
  display: flex;
}

.UserEngagemendtStyle div {
  padding: 10px 20px;
  text-transform: capitalize;
  font-size: calc(11px + (12 - 11) * ((100vw - 320px) / (1920 - 320)));
  /* font-family: 'Montserrat'; */
  width: 100%;
  margin: 1%;
  border-radius: 3px;
  font-weight: 400;
}

.divactive {
  background: #81c14b;
  color: #ffffff;
}

.divInctive {
  background: #f6f6f6;
  color: #4d4d4d;
  border: 1px solid #cfc9c971;
}

.UserEngagemendtStyleCard {
  overflow-y: scroll;
  max-height: 350px;
  overflow-x: hidden;
}

.UserEngagemendtStyleCard::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.UserEngagemendtStyleCard div {
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #cfc9c971;
}

/* .btn {
  font-family: 'Montserrat';
} */

/* .FontForSame {
  font-family: 'Montserrat';
} */

.fontForSubCardheading > h2 {
  /* font-family: 'Montserrat'; */
  position: relative;
  width: 430px;
  font-weight: 500;
  color: #4d4d4d;
}

.fontForSubCardheading > h3 {
  /* font-family: 'Montserrat'; */
  font-weight: 500;
  color: #4d4d4d;
}

.fontForSubCardheading > h4 {
  /* font-family: 'Montserrat'; */
  font-weight: 500;
  color: #4d4d4d;
}

.fontForSubchildCardheading > h5 {
  /* font-family: 'Montserrat'; */
  font-weight: 400;
  color: #4d4d4d;
}

.fontForSubchildCardheading > h6 {
  /* font-family: 'Montserrat'; */
  font-weight: 400;
  color: #808080;
  line-height: 24px;
}

/* table */

.fontForSubTablheading > h4 {
  /* font-family: 'Montserrat'; */
  font-weight: 400;
  color: #4d4d4d;
}

.fontForSubTablheading > h5 {
  /* font-family: 'Montserrat'; */
  font-weight: 400;
  color: #4d4d4d;
}

.OnMouseDataShow {
  opacity: 0;
}

.rdt_TableRow:hover .OnMouseDataShow {
  opacity: 1;
}

.react-daterange-picker__wrapper {
  border: none !important;
  border-radius: 3px;
}

.react-calendar__tile--active {
  background: #81c14b !important;
  color: white;
}

.react-calendar__tile--now {
  background: #81c14b33 !important;
}

.react-daterange-picker__button:enabled:hover
  .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus
  .react-daterange-picker__button__icon {
  stroke: #81c14b !important;
}

.react-daterange-picker,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
  box-sizing: border-box;
  background: #e6f3db !important;
  border-radius: 4px !important;
  padding: 7px !important;
}

.DynamicFilterForStyle {
  background: #e6f3db;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  -webkit-padding-end: 10px;
          padding-inline-end: 10px;
  border-radius: 5px;
}

.underLineForLink:hover .underlinetext {
  /* text-decoration: underline;
  text-decoration-color: blue; */
  border-bottom: 1px solid blue;
}

.AddInfoBorder {
  border-radius: 6px 5px 5px 6px;
  -webkit-border-radius: 6px 0px 0px 6px;
  -moz-border-radius: 6px 0px 0px 6px;
  border-left: 6px solid #ff6550;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.css-igs3ac {
  text-align: left;
  position: absolute;
  inset: 0 !important;
  margin: 0px;
  padding: 0 !important;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 0 !important;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.css-1bp1ao6.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: none !important;
  border-width: 0 !important;
}

.css-gjbq6i-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom: 2px solid #81c14b !important;
}

.boderForactive {
  border-bottom: 2px solid #81c14b;
}

.ads-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.color-grey {
  color: #c4c4c4;
}

.ad-title {
  margin-left: 1rem;
}

.fontWeightBold h4 {
  font-weight: 600;
  /* font-family: 'Montserrat'; */
  padding-bottom: 10px;
}

.fontWeightBold h5 {
  font-weight: 400;
  /* font-family: 'Montserrat'; */
  padding: 6px 0px;
}

.unset {
  position: unset;
  background-color: #f9fbf7 !important;
  position: unset;
  box-shadow: unset !important;
}

.nav-flex {
  display: flex !important;
  align-items: center;
  -webkit-flex-wrap: wrap;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cus-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: -6px;
}

.com {
  background: white;
  padding: 1rem;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin-right: 8px;
}

.max-width {
  width: 70px !important;
  max-height: 70px;
  border-radius: 10px;
}

.img-con {
  display: flex;
}

.linkForstyLe {
  color: #81c14b;
  border: 1px solid #81c14b;
  font-weight: 500;
}

.linkForstyLe:hover {
  color: #ffffff;
  font-weight: 500;
}

.scrollForAdmanagerFilter {
  overflow-y: scroll;
  max-height: calc(100vh - 50px);
  overflow-x: hidden;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 27px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: #81c14b;
  transition: 0.4s;
}

input:focus + .slider {
  box-shadow: 0 0 1px #81c14b;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #c4c4c4;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  border: 1px solid #81c14b;
}

.slider.round:before {
  border-radius: 50%;
}

.adserch-menu {
  background: transparent !important;
  margin: 0 !important;
}

.adserch-btn {
  background: transparent !important;
  color: #81c14b !important;
  font-size: medium !important;
}

.sidebar-bg {
  /* background: white !important;
  min-width: 10% !important; */
  box-shadow: rgba(17, 17, 26, 0.1) 1px 0px 0px;
}

.sidebar-padding {
  padding: 0.5rem;
}

.table-link {
  display: flex;
  justify-content: space-around;
}

.table-link > span > a {
  color: rgb(0, 40, 116);
}

.ad-tr-bg {
  background: #e5f0e0;
}

.ad-bg-com-2 {
  background: #f6f6f6;
  padding: 1rem;
}

.profile-menu-new ul li {
  margin: 20px 0 0px 15px !important;
}

.profile-menu-new ul li a.active {
  background: #e5f0e0;
}

.profile-menu-new ul li a.active h6 {
  color: #81c14b;
  stroke: #81c14b;
}

.table tbody tr {
  background: white;
}

.com-btn {
  display: flex;
  float: right;
  margin-top: 1rem;
}

.ts-padding {
  padding: 1rem 8rem !important;
}

.ts-img-pad {
  padding: 1rem;
}

.success {
  padding: 0.5rem;
  background: #e5f0e0;
  color: #81c14b;
  border-radius: 20px;
}

.failed {
  padding: 0.5rem;
  background: rgba(221, 121, 121, 0.12);
  color: #dd7979;
  border-radius: 20px;
}

.max-width-ts {
  max-width: 18% !important;
}

.margin-add-fud {
  margin: 1rem;
}

.add-width {
  max-width: 800px;
}

.ad-model-header {
  background-color: #f6f6f6;
}

.ad-model-title {
  color: black;
  font-weight: 800;
  margin-bottom: 0.5rem;
}

.add-footer {
  justify-content: center !important;
}

.add-btn {
  width: 90% !important;
}

.margin-add-fud-bg:hover {
  background-color: #81c14b;
  border-radius: 5px;
  border: 1px solid #81c14b;
  color: #ffffff;
  transition: all 0.5s ease;
  cursor: pointer;
}

.model-height {
  max-height: 500px !important;
}

.profile-menu-new ul li a {
  border: 1px solid #e5f0e0;
  background: white;
  padding: 0.5rem 2rem !important;
}

.ad-search-bar {
  width: 180%;
}

.ad-filters {
  display: contents;
}

.ad-manager-filter {
  padding: 1rem;
  background: #dedede;
  border-radius: 10px;
}

.table-link > span > a {
  color: #0d6efd;
  text-decoration: underline;
}

.dropedownForFilter.show {
  display: block;
  width: revert;
}

input[type="checkbox"] {
  accent-color: #81c14b;
}

.customcheckboxForFilter
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: #81c14b;
  color: #eff0ef !important;
}

.formToolForAnime {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  background: whitesmoke;
  -webkit-animation-iteration-count: 1, 0;
          animation-iteration-count: 1, 0;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  transition: 0.4s ease-in-out;
}

.formToolForAnime:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}

.formToolForPreview {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: 1, 0;
          animation-iteration-count: 1, 0;
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  transition: 0.4s ease-in-out;
}

.formToolForAnime svg {
  cursor: pointer;
}

.bounce:hover {
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  }

  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  /* 100% {
    transform: translateY(0);
  } */
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  }

  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  /* 100% {
    transform: translateY(0);
  } */
}

/* -------------------------------- */

.navs-tab-list {
  background: #ffffff !important;
  border-radius: 6px 6px 0px 0px !important;
  /* font-family: 'Montserrat' !important; */
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  white-space: nowrap;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px 30px; */
  text-align: center !important;
  color: #5e5873 !important;
}

.navs-tab-lists {
  color: #4d4d4d !important;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
}

.navs-tab-lists.active {
  color: #81c14b !important;
  background-color: #81c14b33 !important;
  border: 2px solid #81c14b !important;
}

.btnForRefresh {
  background-color: #e5f0e0;
  border-radius: 5px;
  color: #4d4d4d;
}

.colorForBadgePrimary.badge {
  color: #81c14b;
  background-color: rgba(129, 193, 75, 0.12);
  border-radius: 17px;
}

.colorForBadgedanger.badge {
  color: #dd7979;
  background-color: rgba(221, 121, 121, 0.12);
  border-radius: 17px;
}

.ButtondisableForbutton:disabled {
  color: #ffffffa2;
  background-color: #4d4d4d93;
  border-color: whitesmoke;
}

.widthFordropemenu {
  will-change: transform;
  top: 0px;
  left: -50% !important;
  -webkit-transform: translate3d(-46px, 38px, 0px);
          transform: translate3d(-46px, 38px, 0px);
  width: -webkit-max-content !important;
  width: max-content !important;
}

.rowForrevert.row {
  --bs-gutter-x: revert !important;
}

.sizingForTextsmall {
  font-size: 0.6rem;
}

.sizingForTextvsmall {
  font-size: 0.5rem;
}

.sizingForTextheading {
  font-size: 0.6rem;
}

.postionFormic {
  position: absolute;
  top: 2.8rem;
  left: 3.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ffffff;
}

.postionFortag {
  position: absolute;
  background-color: #f9a321;
  width: 14%;
  text-align: center;
  border-radius: 30px 0px 0px 30px;
  top: 4.5rem;
  right: 0.3rem;
}

.customeForprogressbar {
  height: 0.28rem !important;
  /* position: relative; */
  width: 100% !important;
  /* left: -0.5rem; */
}
.rowForreverts {
  background-color: rgb(249, 251, 247);
}
.customeForprogressbar .progress-bar {
  background-color: #28a745 !important;
  height: 0.5rem;
}

.colorForbottom {
  background-color: rgba(0, 0, 0, 0.493);
  position: absolute;
  top: 85%;
  width: 100%;
  left: 0%;
}

.colorStoriesForbottom {
  background-color: rgba(0, 0, 0, 0.493);
  position: absolute;
  top: 95%;
  width: 100%;
  left: 0%;
}

.colorStoriesForbottomsmall {
  background-color: rgba(0, 0, 0, 0.493);
  position: absolute;
  top: 75%;
  width: 100%;
  left: 0%;
}

.whyAdForPosition {
  position: relative;
  top: 85%;
}

.whyAdForShotzPosition {
  position: relative;
  top: 98%;
}

.whyAdForShotzPositionsmall {
  position: relative;
  top: 88%;
}

.hbotQl {
  white-space: revert !important;
}

.PaymentmodalForStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background: #f6f6f6;
  border-bottom: #f6f6f6 1px solid;
}

.amount label {
  color: #4d4d4d;
  font-weight: 500;
}

.ConfirmatioForstyle {
  margin-top: 3%;
}

.ConfirmatioForstyle h5 {
  color: #4d4d4d;
  padding: 2% 0%;
  margin-top: 4%;
}

.ConfirmatioForstyle h3 {
  padding: 2% 0%;
}

.modalForPayHead {
  color: #4d4d4d;
  font-weight: 500;
}

.payForIcon img {
  width: 15%;
  aspect-ratio: 3/2;
  object-fit: contain;
  margin-right: 5%;
  /* mix-blend-mode: color-burn; */
}

.onMouseHovePayment:hover {
  background-color: #e5f0e0;
}

.currencyicon {
  position: absolute;
  left: 19px;
  bottom: 26px;
}

.Amounticon {
  position: absolute;
  left: 1.99%;
  right: 10.01%;
  top: 46%;
  bottom: 20%;
}

.ScrollForTab {
  overflow-y: auto;
  height: 500px;
}

.marginForheader {
  margin-right: 5% !important;
  /* display: ; */
}

.postitionForcount {
  position: absolute;
  right: 15px;
  top: 1px;
  border-radius: 100%;
  background-color: red;
  color: white;
  padding: 1px 5px;
  font-size: x-small;
}

.postionForOnlinestatus {
  position: absolute;
  right: 155px;
  top: 15px;
}

.dashboardForstyle {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  grid-gap: 2;
  gap: 2;
  /* width: min(100% - 10px, 1840px); */
  /* margin-inline: auto; */
}

.paddingForform {
  padding: 0rem 25rem;
}

.rotate-text {
  -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
  /* "rl" stands for right-to-left */
  text-align: center;
  /* to center the heading within the div */
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  position: absolute;
  top: 30%;
}

.mobile-frame {
  position: relative;
  width: 320px;
  height: 588px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.mobileAd-card {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  padding: 20px;
  overflow: auto;
}

.monitor-frame {
  position: relative;
  width: 600px;
  height: 550px;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
}

.monitorAd-card {
  position: absolute;
  top: 90px;
  left: 20px;
  right: 20px;
  /* bottom: 20px; */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  overflow: auto;
}

.mobile-frame-carousel {
  position: relative;
  width: 320px;
  height: 480px;
  border: 2px solid #ddd;
  border-radius: 16px;
  overflow: hidden;
}

.mobile-frame-video {
  position: relative;
  width: 320px;
  height: 480px;
  border: 2px solid #ddd;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.video-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 48px;
  color: #fff;
  opacity: 0.8;
  cursor: pointer;
}

.play-button:hover {
  opacity: 1;
}

.mobile-frame-full-video {
  position: relative;
  width: 320px;
  height: 480px;
  border: 2px solid #ddd;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-container-full-screen {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-thumbnail-full-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.story-bar {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.3);
}

.story-progress {
  height: 100%;
  width: 50%;
  background-color: #81c14b;
  -webkit-animation: story-progress 10s linear infinite;
          animation: story-progress 10s linear infinite;
}

@-webkit-keyframes story-progress {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes story-progress {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

/* MEDIA */

@media (max-width: 575px) {
  .paddingForform {
    padding: 0rem;
  }

  .sidebar-bg {
    display: none;
  }

  .sizingForTextsmall {
    font-size: 0.5rem;
  }

  .sizingForTextvsmall {
    font-size: 0.4rem;
  }

  .sizingForTextheading {
    font-size: 0.5rem;
  }

  .postionFormic {
    position: absolute;
    top: 2.7rem;
    left: 3.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ffffff;
  }

  .adForCol-1.col-lg-1 {
    flex: 0 0 auto;
    width: 4.33333%;
  }

  .adForCol-2.col-lg-11 {
    flex: 0 0 auto;
    width: 95.66667%;
  }
}

@media (max-width: 375px) {
  .postionFormic {
    position: absolute;
    top: 2.6rem;
    left: 3.3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ffffff;
  }

  .postionFortag {
    position: absolute;
    background-color: #f9a321;
    width: 20%;
    text-align: center;
    border-radius: 30px 0px 0px 30px;
    top: 2.8rem;
    right: 0.3rem;
    font-size: 0.3rem;
  }

  .ad-buttons-blk {
    display: flex;
    justify-content: center;
  }

  .customeForprogressbar {
    height: 0.25rem !important;
    position: relative;
    width: 18rem !important;
    left: -10px;
  }

  .responsiveForTag.ad-count-btn {
    width: 50px !important;
  }

  .adForCol-1.col-lg-1 {
    flex: 0 0 auto;
    width: 4.33333%;
  }

  .adForCol-2.col-lg-11 {
    flex: 0 0 auto;
    width: 95.66667%;
  }
}
/* -------------------------mobile view css------------------------ */
/* @media (max-width: 350px) {
  .header-section-One {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden; 
  }
} */
/* navtext-item */
/* Adjust the breakpoint value as needed */

@media (max-width: 768px) {
  .fixed-header {
    /* Keep the header fixed and adjust its width */
    position: fixed;
    width: 100%;
    max-width: 100%; /* Use full width for screens <= 768px */
    left: 0;
  }
}
@media (max-width: 768px) {
  .navtext-item {
    margin-left: 22px;
  }
}

/* Adjust the layout for mobile view */
/* Adjust the layout for mobile view */
/* @media (max-width: 768px) {
  .nav-item {
    width: 100%;
  }
} */

@media (max-width: 768px) {
  .nav-item {
    display: grid;
    /* grid-template-columns: repeat(2, 1fr);  */
    grid-gap: 0.2rem;
    gap: 0.2rem; /* Add gap between items */
  }
  .nav-link {
    line-height: 1.2;
    white-space: nowrap;
  }
}
@media (max-width: 768px) {
  .nav-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Display two items per row */
    grid-gap: 0.1rem;
    gap: 0.1rem; /* Add gap between items */
  }
  .nav-link {
    line-height: 1.2;
    white-space: nowrap;
  }
}
.nav-link {
  font-size: 14px; /* Adjust as needed */
  line-height: 1.5; /* Adjust as needed */
}

/* ===================list and grid css=================== */
@media (max-width: 768px) {
  .nav-flex {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .nav-flex > div {
    margin-bottom: 1rem;
  }
}
@media (max-width: 752px) {
  .small-main-text {
    margin-left: 113px !important;
    margin-top: -124px !important;
  }
}

/* =====================mobile css========================= */
/* Adjustments for mobile view */
@media (max-width: 767px) {
  .main-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }

  .filter-section {
    margin-bottom: 15px;
    margin-left: -0.1rem;
  }

  .filter-content {
    flex-direction: column;
  }

  .toggle-filter {
    flex-direction: column;
    align-items: flex-start;
    margin-top: -2rem;
  }

  .search-input {
    margin-top: -2rem;
    /* margin-left: -0.5rem; */
  }
  /* Add this CSS class to your stylesheet */
  /* .custom-checkbox {
  
      color:  white;
    } */

  .date-and-export {
    flex-direction: column;
    align-items: flex-start;
    margin-top: -1rem;
    margin-right: -2.9rem;
  }
}
@media (max-width: 912px) {
  .runningdrop {
    margin-left: -2rem !important;
  }
}
@media (max-width: 912px) {
  .runningdroplist {
    margin-left: 6rem !important;
  }
}
@media (min-width: 1200px) {
  .posttext-pre {
    margin-left: 3px !important;
  }
}

@media (min-width: 1366px) {
  .AdDurationForStyle {
    margin-top: -41px !important;
  }
}
@media (min-width: 318px) and (max-width: 900px) {
  .radio-text {
    margin-top: -29px !important;
  }
}
@media (min-width: 280px) and (max-width: 1366px) {
  .start-text {
    margin-top: -25px;
    margin-left: 44%;
    color: red;
  }
}
@media (min-width: 280px) and (max-width: 1366px) {
  .start-texts {
    margin-top: 4px;
    margin-left: 24%;
    color: red;
  }
}
@media (max-width: 1080px) {
  .lead-form {
    position: absolute !important;
    left: 32rem !important;
  }
}

.ScrollFordiv {
  overflow: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 80px);
}

.background {
  padding: 0 25px 25px;
  position: relative;
  width: 100%;
}

.background::after {
  content: '';
  /* background: #81c14b; */
  /* background: linear-gradient(to right, #2e5f06, #81C14B); */
  background: rgb(129, 193, 75);
  background: linear-gradient(11deg, rgba(129, 193, 75, 1) 0%, rgba(46, 95, 6, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#60a9ff', endColorstr='#4394f4', GradientType=0);
  height: 350px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  border-radius: 10px 10px 40% 40%;
}

.searchForIcon span.input-group-text {
  background-color: transparent;
  border: 0px;
}

.panel {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 25px;
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.panel-content {
  min-height: 35vh;
}

.heading {
  position: relative;
  font-family: 'Poppins';
  padding-top: 30px;
  padding-bottom: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
}

.main-panel {
  position: relative;
  z-index: 10;
}

.ad-button {
  font-family: 'Poppins';
  background: none;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  border: none;
  color: #999999;
}

.tab-button-active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 15px;
  grid-gap: 5px;
  gap: 5px;
  border-radius: 5px;
  color: #4D4D4D;
  border: none;
  background: #E5F0E0;
}

.tab-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 15px;
  grid-gap: 5px;
  gap: 5px;
  border-radius: 5px;
  color: #4D4D4D;
  border: none;
  background: #fff;
}
li.SettingForSidestyle {
    background: rgba(129, 193, 75, 0.2);
    cursor: pointer;
    color: #81C14B !important;
    border-left: 5px solid #81C14B;
}
.SettingForSidestyle1 {
    background-color: white ;
  /* Add a smooth transition for better user experience */
}


.SettingForSidestyle1.active {
    background: rgba(129, 193, 75, 0.2);
    cursor: pointer;
    color: #81C14B !important;
    border-left: 5px solid #81C14B;
  }

  .SettingForSidestyle1 li.active {
    background-color: #e0e0e0; /* You can change the background color as needed */
}

.SettingForSidestyle1:hover {
    background-color: rgba(129, 193, 75, 0.2) ; /* Change to your desired hover color */
    cursor: pointer; /* Change the cursor on hover */
   
    color: #81C14B !important;
    border-left: 5px solid #81C14B;
}


.clgForstyle {
    color: #4D4D4D;
    line-height: 17px;
}

.redefineClass {
    border-bottom: revert !important;
    padding: revert !important;
}



.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: transparent;
    border-left: none;
}

.SessionForBar {
    padding: 10px 20px;
    background: #F6F6F6;
    border-radius: 5px;
    color: #4D4D4D;
    margin-top: 10px;
}


.inputgrouppass{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 97%;
}

/*===================== start_Privacy_setting_css ========================*/
.SettingPrivacy_Main_section {
    margin-bottom: 207px;
    padding: 20px;
}

.SettingPrivacy_Card_Body {
    padding: 20px !important;
    background: #F6F6F6 !important;
    border-radius: 5px !important;
    margin-top: 20px;
}

.SettingPrivacy_Card_Body h3 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.SettingPrivacy_Content_Section {
    padding-bottom: 10px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 20px !important;
}

.SettingPrivacy_Content_Section:last-child {
    padding-bottom: 0 !important;
    border-bottom: none !important;
    margin-bottom: 0 !important;
}

.SettingPrivacy_SubContent_Section {
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
}

.SettingPrivacy_SubContent_Section h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}

.SettingPrivacy_SubContent_Section p {
    color: #808080;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Media_content_section {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 20px;
}

.Media_content_section h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
}

.Media_content_section .High-Quality {
    object-fit: cover;
    border-radius: 5px;
    width: 100%;
    height: 160px;
    border: 3px solid #FFFFFF;
    box-shadow: 0px 0px 0px 3px #81C14B;
    border-radius: 5px;
}

.Media_content_section .Medium-Quality {
    object-fit: cover;
    -webkit-filter: blur(0.25px);
            filter: blur(0.25px);
    border-radius: 5px;
    width: 100%;
    height: 160px;
}

.Media_content_section .Low-Quality {
    object-fit: cover;
    -webkit-filter: blur(0.5px);
            filter: blur(0.5px);
    border-radius: 5px;
    width: 100%;
    height: 160px;
}

.ReportProblem_label h3 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-top: 12px;
}

.Report_Input_section {
    background: #F6F6F6;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-top: 8px;
}

.Report_text_Validation {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.Report_Desclimer_section h3 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.Report_Desclimer_section span {
    color: #81C14B;
    font-weight: 600;
    font-size: 16px;
}

/*===================== end_Privacy_setting_css ========================*/

/*===================== start_language_settings_css =====================*/
.SettingLanguage_Card_Body {
    padding: 20px !important;
    background: #F6F6F6 !important;
    border-radius: 5px !important;
    margin-top: 20px;
}

.SettingLanguage_Card_Body p {
    color: #808080;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.SettingLanguage_Card_Body h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4D4D4D;
}

button.Language_DropSelect_section.btn.btn-outline-primary {
    border: 1px solid #81C14B;
    border-radius: 4px;
}

.language_menu.dropdown-menu.show {
    padding: 0px !important;
    margin: 0px !important;
    margin-top: 2px !important;
    font-size: 1rem !important;
    color: #212529 !important;
    text-align: left !important;
    list-style: none !important;
    background-color: #ffffff !important;
    background-clip: padding-box !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.25rem !important;
    max-height: 268px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

/*===================== end_language_settings_css =====================*/
/*===================== start_Deleted_setting_css =====================*/
.Delete_Account_ContentSection h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin-top: 10px;
}

.Delete_Account_ContentSection span {
    color: #FF9922;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;

}

.Delete_Account_Content_Section_blk h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    margin-top: 10px;
}

.Delete_Account_Content_Section_blk span {
    color: #FF9922;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
}

.SettingAccount_Content_Section {
    /* padding-bottom: 10px !important; */
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 20px !important;
}

.SettingAccount_SubContent_Section {
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
}

.SettingAccount_SubContent_Section h4 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
}

.SettingAccount_DeleteContent_Section {
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
}

.SettingAccount_DeleteContent_Section {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 16px;
}

.Delete_Account_profileSection {
    display: flex !important;
    align-items: center !important;

}

.Delete_Account_profileSection img {
    object-fit: cover;
    height: 71px;

}

.Delete_Account_profileSection h3 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
}

.Delete_Account_profileSection p {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

/*============= new ============*/

.Delete_Account_DetailSection h3 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.Delete_Account_DetailSection span {
    color: #0066FF;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.Delete_Account_DetailSection h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
}

.delete_Account_inputSection h3 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 20px;
}

.setting_Group_blk_section {
    border-bottom: 1px solid #ddd;
}

.setting_Group_blk_section:last-child {
    border-bottom: none;
}

.Settings_card-title {
    padding: 20px;
}

.Settings_card-title h3 {
    text-transform: capitalize;
    color: #51595e;
    font-weight: 700;
}

.Settings_Icon_Interest {
    background: #81C14B;
    border-radius: 4px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Settings_Fillter_ConnectionTab_section.dropdown-menu.show {
    position: absolute !important;
    will-change: transform !important;
    top: 0px !important;
    left: 0px !important;
    -webkit-transform: translate3d(-54px, 39px, 0px) !important;
            transform: translate3d(-54px, 39px, 0px) !important;
    /* margin: 0px; */
    padding: 0 !important;
    margin-top: 2px !important;
    display: block;
    width: 150% !important;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

button.Settings_Hover_item.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

button.Settings_Hover_item.dropdown-item:hover,
button.Settings_Hover_item.dropdown-item:focus {
    background: #E6F3DB !important;
    color: #333333;
}

.Setteing_connection_Heading_section h3 {
    color: #4D4D4D;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.Setting_Deleted_btn {
    width: 18%;
}

.btn-ReportProblem {
    background: #F6F6F6;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    transition: all 0.5s ease;
}

.Legal_Policies_Blk_section {
    padding-bottom: 15px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 15px !important;
}

.Legal_Policies_Blk_section:last-child {
    padding-bottom: 0 !important;
    border-bottom: none !important;
    margin-bottom: 0 !important;
}

.Legal_Policies_Content_section {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
}

a.Legal_Policies_Link {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
}

.Legal_Policies_Heading h3 {
    color: #333333;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

button.Language_droup_Item.dropdown-item:hover {
    color: #000000;
    background-color: #E6F3DB !important;
}

.My_Connection_MoreBtn_blk.btn-group {
    margin-right: -23px !important;
}

/*================ Start_MediaQueries.css ==================*/
@media (max-width: 540px) {
    .Media_content_section .High-Quality {
        border-radius: 5px;
        width: 100%;
        height: 100px;
        border: 3px solid #FFFFFF;
        box-shadow: 0px 0px 0px 3px #81C14B;
        border-radius: 5px;
    }

    .Media_content_section .Medium-Quality {
        object-fit: cover;
        -webkit-filter: blur(0.25px);
                filter: blur(0.25px);
        border-radius: 5px;
        width: 100%;
        height: 100px;
    }

    .Media_content_section .Low-Quality {
        object-fit: cover;
        -webkit-filter: blur(0.5px);
                filter: blur(0.5px);
        border-radius: 5px;
        width: 100%;
        height: 100px;
    }

    .Media_content_section h4 {
        -webkit-font-feature-settings: 'tnum' on, 'lnum' on;
                font-feature-settings: 'tnum' on, 'lnum' on;
        color: #4D4D4D;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        margin-top: 10px;
    }

    .btn-ReportProblem {
        margin-left: 16px;
    }

    button.ReportSumit_btn.btn.btn-primary {
        width: 100%;
        margin-top: 10px;
    }

    .Delete_Account_DetailSection h3 {
        color: #4D4D4D;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
    }

    .Delete_Account_DetailSection span {
        color: #0066FF;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
    }

    .Delete_Account_DetailSection h4 {
        color: #4D4D4D;
        font-weight: 500;
        font-size: 14px;
    }

    a.AccountProfile-login.active {
        text-decoration: none !important;
        color: #81c14b !important;
        font-weight: 500;
        font-size: 12px;
    }

    .Delete_Account_InputSection {
        display: flex !important;
        justify-content: center !important;
        width: 100% !important;
    }

    .Delete_Account_InputBlock {
        width: 70% !important;
    }

    .Setting_Deleted_btn {
        width: 30%;
    }
}

.Report_Problem_submit {
    display: flex;
    justify-content: end;
}

.Delete_Account_InputSection {
    display: flex;
    justify-content: center;
    width: 100%;
}

.Delete_Account_InputBlock {
    width: 50%;
}

/*================ End_MediaQueries.css ==================*/



@media (min-width:280px) and (max-width: 575px) {
    .hide-setting-options{
        display: none !important;
    }
    
}

@media (min-width :576px){
    .hide-setting-options1{
   display: none;
}

.setposi{
    
}
}

.settfont{
    color: #4D4D4D;
font-family: Poppins;
font-size: 18px !important;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 27px */
}


.setposi{
    position: relative;
    top: 2px;
    right: -17px;
}


.backarbut{
    position: absolute;
    top: -41px;
    left: 4px;
}
.backarbut1{
    position: absolute;
    top: -40px;
    left: 7px;
}

.Categories_LeftPannel_slider1{
    height: -webkit-max-content;
    height: max-content;
    border-radius: 5px;
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    /* margin-bottom: 30px; */
    overflow-y: hidden;
}

.blockmodalset{
    position: relative;
    overflow: hidden;
    margin-left: -4px;
    margin-right: -4px; 
}

.butsec{
    position: relative;
    right: 6px;
}
/*================= start_Post_creation_css ==========================*/
.Creation_main_blk {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
    border-radius: 5px;
    /* padding: 30px; */
    padding: 0.75rem
}

/* @media (max-width:576px) {
    .Creation_main_blk {
        padding: 20px;

    }

} */
.Card_Main_blk_section {
    max-height: 320px;
}

.Creation_Header_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Creation_Header_section h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #4D4D4D;
}

.GoLive_section {
    color: #808080;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
}

.Creation_Header_section span {
    color: #808080;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
}

.Public_dropDown_menu.dropdown-menu.show {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute !important;
    will-change: transform !important;
    top: 0px !important;
    left: 0px !important;
    -webkit-transform: translate3d(-71px, 30px, 0px) !important;
            transform: translate3d(-71px, 30px, 0px) !important;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: none;
}

.Creation_footer_section {
    padding-left: 16px;
    padding-right: 16px;
}

/* @media (max-width:576px) {
    .Creation_footer_section {
        padding-left: 5px;

    }
} */

.Activity_btn_blk {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50% !important;
    border: none;
    color: #A6A6A6;
    background: #F6F6F6;
    font-weight: 600;
    font-size: 13px;
    /* letter-spacing: 0.15rem; */
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.Activity_btn_blk:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F6F6F6;
    border-radius: 50% !important;
    border: none;
    z-index: -2;
}

.Activity_btn_blk:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #81c14b;
    transition: all 0.3s;
    border-radius: 50% !important;
    border: none;
    z-index: -1;
}

.Activity_btn_blk.btn:hover {
    color: #fff;
}

.Activity_btn_blk.btn:hover:before {
    width: 100%;
}

/*===============End_Post_creation ==========================*/

/*===============Start_Post_creation_Footer_blk ==========================*/

.Activity_btn_Postblk {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 10rem;
    border: none;
    color: #A6A6A6;
    background: #F6F6F6;
    font-weight: 600;
    font-size: 13px;
    /* letter-spacing: 0.15rem; */
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.Activity_btn_Postblk:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F6F6F6;
    border-radius: 10rem;
    border: none;
    z-index: -2;
}

.Activity_btn_Postblk:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #81c14b;
    transition: all 0.3s;
    border-radius: 10rem;
    border: none;
    z-index: -1;
}

.Activity_btn_Postblk.btn:hover {
    color: #fff;
}

.Activity_btn_Postblk.btn:hover:before {
    width: 100%;
}

.NewActiviy_btn_PostBlk {
    border: none;
    font-weight: 600;
    font-size: 13px;
    color: #A6A6A6;
    background: #F6F6F6;
    padding: 8px;
    border-radius: 10rem;
    transition: all 500ms ease-out;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.NewActiviy_btn_PostBlk:hover {
    -webkit-filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.24));
            filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.24));
    background: #81C14B;
    color: #FFFFFF;
    /* box-shadow: 0 5px 15px rgba(92, 182, 104, 0.4); */
}

.NewEdit_btn_blk {
    display: flex;
    align-items: center;
    border: none;
    color: #A6A6A6;
    background: #F6F6F6;
    padding: 8px;
    border-radius: 50% !important;
    transition: all 500ms ease-out;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.NewEdit_btn_blk:hover {
    -webkit-filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.24));
            filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.24));
    background: #81C14B;
    color: #FFFFFF;
}

/*===============End_Post_creation_Footer_blk ==========================*/

.zoom {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 10rem;
    border: none;
    color: #A6A6A6;
    background: #F6F6F6;
    font-weight: 600;
    font-size: 13px;
    /* letter-spacing: 0.15rem; */
    position: relative;
    overflow: hidden;
    z-index: 1;
    /* padding: 50px; */
    /* background-color: green; */
    transition: all 0.3s;
    margin: 0 auto;
}

.zoom:hover {
    color: #fff;
    background-color: #81c14b;
    /* IE 9 */
    -webkit-transform: scale(1.10);
    /* Safari 3-8 */
    transform: scale(1.10);
}

/*====================== Post_cretion ============================*/

.Edit_btn_blk {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 50% !important;
    border: none;
    color: #A6A6A6;
    background: #F6F6F6;
    font-weight: 600;
    font-size: 13px;
    /* letter-spacing: 0.15rem; */
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
}


.post_creation_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px !important;
    border-radius: 50% !important;
    z-index: 1;
}

.post_creation_btn:active {
    background-color: #81c14b !important;
    color: #fff !important;
}

.Edit_btn_blk:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F6F6F6;
    border-radius: 10rem;
    border: none;
    z-index: -2;
}

.Edit_btn_blk:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #81c14b;
    transition: all 0.3s;
    border-radius: 10rem;
    border: none;
    z-index: -1;
}

.Edit_btn_blk.btn:hover {
    color: #fff;
}

.Edit_btn_blk:hover:before {
    width: 100%;
}

/*================ Start_Modal_Animation_css ===============*/

.creation_modal_blk {
    -webkit-animation: blowUpModal 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards !important;
            animation: blowUpModal 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards !important;
    height: 60vh !important;
}




@-webkit-keyframes blowUpContent {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

    99.9% {
        -webkit-transform: scale(2);
                transform: scale(2);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}




@keyframes blowUpContent {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

    99.9% {
        -webkit-transform: scale(2);
                transform: scale(2);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}

@-webkit-keyframes blowUpContentTwo {
    0% {
        -webkit-transform: scale(2);
                transform: scale(2);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}

@keyframes blowUpContentTwo {
    0% {
        -webkit-transform: scale(2);
                transform: scale(2);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes blowUpModal {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes blowUpModal {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@-webkit-keyframes blowUpModalTwo {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0;
    }
}

@keyframes blowUpModalTwo {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0;
    }
}

/*================ End_Modal_Animation_css ===============*/
button.TagsMain_btn_blk.btn.btn-secondary {
    border: none;
    color: #000;
}

button.TagsMain_btn_blk.btn.btn-secondary::after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    direction: rtl;
    z-index: -1;
    box-shadow:
        -7px -7px 20px 0px #fff9,
        -4px -4px 5px 0px #fff9,
        7px 7px 20px 0px #0002,
        4px 4px 5px 0px #0001;
    transition: all 0.3s ease;
}

button.TagsMain_btn_blk.btn.btn-secondary:hover {
    color: #000;

}

button.TagsMain_btn_blk.btn.btn-secondary::after {
    left: auto;
    right: 0;
    width: 100%;
}

button.TagsMain_btn_blk.btn.btn-secondary:active {
    top: 2px;
}





.btn-16 {
    border: none;
    color: #000;
}

.btn-16:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    direction: rtl;
    z-index: -1;
    box-shadow:
        -7px -7px 20px 0px #fff9,
        -4px -4px 5px 0px #fff9,
        7px 7px 20px 0px #0002,
        4px 4px 5px 0px #0001;
    transition: all 0.3s ease;
}

.btn-16:hover {
    color: #000;
}

.btn-16:hover:after {
    left: auto;
    right: 0;
    width: 100%;
}

.btn-16:active {
    top: 2px;
}

/* //==================start_Feeling_Activity ========================// */

.FellingActivity_main_blk {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Modal_False_blk {
    position: absolute;
    top: -31px;
    right: -15px;
    cursor: pointer;
}

input.form-control.Feeling-control {
    border-radius: 25px !important;
}

.Feeling_Main_blk_section {
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: rgba(129, 193, 75, 0.2);
    padding: 15px 10px;
    cursor: pointer;
}

.Feeling_Main_blk_Subsection {
    display: flex;
    align-items: center;
    border-radius: 5px;
    /* background: #F1F1F1; */
    padding: 15px 10px;
    cursor: pointer;
}

.Feeling_Main_blk_Subsection:hover {
    background: rgba(129, 193, 75, 0.2);
    cursor: pointer;
}

.Feeling_scroll_box {
    max-height: 350px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

/* //==================End_Feeling_Activity ========================// */
.Creation_scroll_box {
    max-height: 450px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

li.Activity_setting {
    background: rgba(129, 193, 75, 0.2);
    cursor: pointer;
    border-radius: 5px;
}

li.Sub_Activity:hover {
    background: rgba(129, 193, 75, 0.2);
    cursor: pointer;
    border-radius: 5px;
}

.UpdateActivity_blk_section {
    padding: 5px 10px;
    width: 17%;
    cursor: pointer;
    background: #F1F1F1;
    border-radius: 30px;
}

.UpdateActivity_blk_section h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #4D4D4D;
}

/* //==================start_Media_secction_blk ========================// */

.AddMedia_photo_section {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    padding: 10px;
}

.Addmedia_sub_section {
    background: #FFFFFF;
    background-clip: border-box;
    border: none;
    border-radius: 5px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
    cursor: pointer;
}
.AddMedia_photo_section_one {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    padding: 5px; /* Reduced padding */
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    overflow: hidden;
}

.Addmedia_sub_section_one {
    background: #FFFFFF;
    background-clip: border-box;
    border: none;
    border-radius: 5px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
    cursor: pointer;
    padding: 0; /* Removed padding to reduce the gap */
    text-align: center;
    overflow: hidden;
}

@media (max-width: 768px) {
    .AddMedia_photo_section_one {
        width: 100%;
        max-width: 100%;
    }

    .Addmedia_sub_section_one {
        height: 200px;
    }
}

.Media_Img_section_one {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0; /* Ensure no padding around the image */
    margin: 0; /* Ensure no margin around the image */
}

.Media_Img_section_one img {
    width: 100%; /* Make sure the image takes the full width of its container */
    height: 100%; /* Make sure the image takes the full height of its container */
    object-fit: cover;
    border-radius: 5px; /* Matches the card's border radius */
}

.Img_right_section_one {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}




.AddmediaOne_sub_section {
    height: 500px;
}

/* .Addmedia_sub_section:hover {
    background: #f1f1f1;
    cursor: pointer;
} */

.Media_Img_section {
    width: 100%;
    position: relative;
    height: 250px;
}

.Media_Img_section img {
    border-radius: 5px;
    height: 250px;
    width: 100%;
    object-fit: cover;
    border: 1px solid #fff;
}


.MediaOne_Img_section {
    width: 100%;
    position: relative;
    height: 500px;
}

.MediaOne_Img_section img {
    border-radius: 5px;
    height: 500px;
    width: 100%;
    object-fit: cover;
    border: 1px solid #fff;
}

/* //==================end_Media_secction_blk ========================// */


.Img_right_section {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;

}

.AddMore_left_section {
    position: absolute;
    left: 73px;
    top: 8px;
    cursor: pointer;
    background: #F6F6F6;
    color: #808080;
    font-weight: 400;
    font-size: 12px;
    border-radius: 5px;
    padding: 5px 10px;
}

.Edit_left_section {
    position: absolute;
    left: 8px;
    top: 8px;
    cursor: pointer;
    background: #F6F6F6;
    color: #808080;
    font-weight: 400;
    font-size: 12px;
    border-radius: 5px;
    padding: 5px 10px;
}

.Create_Post_Footer_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-left: 28px;
    margin-right: 28px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 9px;
}

.Create_post_btn {
    background: #ACDAD6;
    border-radius: 5px;
    border: none;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
}

/* .Create_post_btn:hover {
    background: #81C14B !important;
    color: #FFFFFF;
} */

.btn_active_blk {
    background: #81C14B !important;
    color: #FFFFFF;
}

/*======================== Start_Post_Privacy_Css =========================*/

.Public_Creation_blk {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 10px 20px;
    cursor: pointer;
}

.Post_Audience_blk {
    background: rgba(129, 193, 75, 0.2);
    border-radius: 5px;
    cursor: pointer;
}

.Post_Audience_Subblk:hover {
    background: rgba(129, 193, 75, 0.2);
    border-radius: 5px;
    cursor: pointer;
}

.Public_Icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #DDDDDD;
    border-radius: 20px;
}

/*======================== End_Post_Privacy_Css =========================*/

.tag_ppl_main_blk_section {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 10px;
}

.tag_ppl_main_blk_section:hover {
    background: #f1f1f1;
    border-radius: 5px;
    cursor: pointer;
}

.Tag_ppl_main_blk {
    display: flex;
    align-items: start;
}

.Tag_ppl_main_blk:hover {
    background: #f1f1f1;
    border-radius: 5px;
    cursor: pointer;
}

.Tag_ppl_main_blk h3 {
    color: #4D4D4D;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
}

.Tag_ppl_main_blk p {
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #808080;
}

.HashTag_ppl_main_blk {}

.HashTag_ppl_main_blk:hover {
    background: #f1f1f1;
    border-radius: 5px;
    cursor: pointer;
}

.HashTag_ppl_main_blk h3 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
}

.HashTag_ppl_main_blk p {
    margin-top: 3px;
    color: rgba(0, 0, 0, 0.3);
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;

}

.Check_in_location_blk {
    display: flex;
    align-items: center;
    padding: 10px;
}

.Check_in_location_blk:hover {
    background: #f1f1f1;
    border-radius: 5px;
    cursor: pointer;
}

.Check_in_location_blk h3 {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #4D4D4D;
}

.Map_icon_blk {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(126, 178, 98, 0.05);
    border-radius: 5px;
    width: 35px;
    height: 35px;
    position: relative;
}

.CreatePostselection {
    display: flex !important;
    align-items: center !important;
    border: 1px solid #F6F6F6 !important;
    font-size: 12px !important;
    color: #808080 !important;
}

.CreatePostselection:hover {
    background: rgba(129, 193, 75, 0.2) !important;
    border-radius: 15px !important;
    cursor: pointer !important;
}

button.AlertPost_DropSelect_section.btn.btn-outline-primary {
    background: #F6F6F6;
    border-radius: 5px;
    border: none;
    color: #4D4D4D;
}

.Alert_Post_span {
    font-size: 12px;
    line-height: 18px;
}

button.AlertPost_DropSelect_section.btn.btn-outline-primary:hover {
    color: #fff !important;
    border-radius: 5px !important;
    border: none !important;
}

.Alert_menu.dropdown-menu.show {
    position: absolute;
    will-change: transform;
    width: 100%;
    top: 0px;
    left: 0px;
    -webkit-transform: translate3d(0px, -72px, 0px);
            transform: translate3d(0px, -72px, 0px);
    padding: 0px !important;
    margin: 0px !important;
    margin-top: 2px !important;
    font-size: 12px;
    font-weight: 500;
    color: #212529 !important;
    text-align: left !important;
    list-style: none !important;
    background-color: #ffffff !important;
    background-clip: padding-box !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.25rem !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

button.Alert_item.dropdown-item {
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 600;
    color: #4D4D4D;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 12px;
}

button.Alert_item.dropdown-item:hover {
    color: #fff !important;
    background: #81c14b !important;
}

.Main_creation_footer-section {
    padding: 0.5rem 1rem
}

.Recommendation_input_box {
    background: #F6F6F6 !important;
    border-radius: 5px !important;
}

.Events_date_section {
    position: relative;
}

.Events_input_blk {
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 7px;
}

.Friend_except_main_blk {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 10px;
}

.Friend_except_main_blk:hover {
    background: #f1f1f1;
    border-radius: 5px;
    cursor: pointer;
}

.Friend_except_main_blk h3 {
    color: #4D4D4D;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
}

.Friend_except_main_blk p {
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #808080;
}

.Color_picker_actual_Blk {
    padding: 3px;
}


.ColoPicker_actual_state {
    display: flex;
    background: #F2F3F5;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 65px;
    cursor: pointer;
}

.Text_onChange_section_one {
    width: 30px;
    height: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 65px;
    cursor: pointer;
    transition: width 0.2s, height 0.2s; /* Smooth transitions for resizing */
  }
  
  /* Media queries to make it responsive on smaller screens */
  @media (max-width: 768px) {
    .Text_onChange_section_one {
      width: 26px;  /* Reduce width on smaller screens */
      height: 26px; /* Reduce height on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .Text_onChange_section_one {
      width: 24px;  /* Further reduce for very small screens */
      height: 24px;
    }
  }
  

.scrollbarnone::-webkit-scrollbar {
    display: none;
}


.Text_onChange_active_blk {
    border: 2px solid #16C31E;
    border-radius: 65px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 3px;
    cursor: pointer;
}

.Text_onChange_Unactive_blk {
    border: 2px solid transparent;
    padding: 3px;
    cursor: pointer;
    border-radius: 65px;

}

.Trhied_media_blk {
    position: relative;

}

.Trhied_media_blk img {
    -webkit-filter: brightness(.4);
            filter: brightness(.4);
}

.Update_plus_count {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
}

.Update_plus_count span {
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;

}

.Arrow_main_blk_section {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #e4e6eb;
    transition: 0.1s ease-in-out;
}

.Arrow_main_blk_section:hover {
    background: #d5d8df;

}

.Schedule_post_blk_section {
    background: #F6F6F6;
    border-radius: 5px;
    padding: 10px 20px;
}

.editorClassName.bg-light.border.Artical_blk.rdw-editor-main {
    min-height: 170px !important;
    padding: 5px !important;
    border: none !important;
    border-radius: 5px !important;
    /* background: #F6F6F6 !important; */
    margin-top: 390px !important;
    background: none !important;
    word-wrap: break-word;

}

.rdw-editor-toolbar.toolbar-class.Artical_tool_bar {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    border-radius: 5px !important;
}

.xyz {
    position: relative;


}

.Main_Heading_main_blk_center {
    display: flex;
    justify-content: center;
}

.dpj_here {
    position: absolute;
    top: 31px;
    width: 75% !important;
}

@media (max-width:992px) {
    .dpj_here {
        position: absolute;
        top: 80px;
        width: 100% !important;
    }
}

@media (max-width:576px) {
    .dpj_here {
        position: absolute;
        top: 100px;
        width: 100% !important;
    }
}

@media (max-width:375px) {
    .dpj_here {
        position: absolute;
        top: 120px;
        width: 100% !important;
    }
}

.Artical_main_Headin_input_blk {
    height: 52px;
    padding-top: 15px;
    padding-bottom: 0;
    overflow: auto;
    box-shadow: none;
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
    font-size: 42px;
    line-height: 40px;
    margin: 3.2rem 0;
    min-height: 4.8rem;
    overflow-y: hidden !important;
    resize: none;
    /* max-width: -webkit-fill-available; */
    margin: 47px 32px 4px 0;
    /* border: 2px solid #212529 !important; */
    border: none !important;
    background: none !important;
}

.Artical_main_Headin_input_blk:hover {
    border: 2px solid #212529 !important;
}


.Artical_main_Headin_input_blk::-webkit-input-placeholder {
    color: var(--color-text);
    font-size: 42px;
    line-height: 48px;
}


.Artical_main_Headin_input_blk::placeholder {
    color: var(--color-text);
    font-size: 42px;
    line-height: 48px;
}

textarea.Artical_main_Headin_input_blk.form-control:focus-visible {
    border: 2px solid #212529 !important;
}

.Main_Editor_artical_blk {
    margin-top: 91px;
    display: flex;
    justify-content: center;
    min-height: 100%;
}

.Artical_Addmedia_sub_section {
    background: #F6F6F6 !important;
    background-clip: border-box;
    border: none;
    border-radius: 5px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Artical_Addmedia_sub_section img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 5px !important;
}

.next_btn_blk {
    position: absolute;
    top: 12px;
    right: 94px;
}

button.artical_next_btn.btn.btn-primary {
    padding: 2px 22px !important;
    text-transform: capitalize;
    font-size: calc(11px + (12 - 11) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    border-radius: 10px !important;
    display: flex;
    align-items: center;
}

.Post_creation_text_limt {

    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    color: #C4C4C4;
}

.Suggest_tag_btn {
    cursor: pointer;
    color: #81c14b;
    font-size: 16px;
    font-weight: 500;

}


.Filter_Img_Blk {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.FilterImages {
    flex: 1 1 auto;
    aspect-ratio: 4/4;
    width: calc((100% / 3) - 10px);
    max-width: calc((100% / 3) - 10px);
    /* border: 1px solid gray; */
    border-radius: 5px;
    margin-bottom: 10px;

}

.FilterImages img {
    object-fit: cover !important;
    width: 100% !important;
    aspect-ratio: 4/4;
    border-radius: 5px;
}


.FilterImages>h3 {
    font-size: calc(100vh / 49);
    margin-top: 3px;
}

.filter_scroll_box {
    max-height: 420px !important;
    /* max-height: calc([Filter_Img_Blk] * 3  + 50px); */
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.Schedule_Post_Preview {
    position: absolute;
    top: -6px;
    right: 0px;
    z-index: 2;
    background-color: #81c14b;
    color: #ffff;
    border-radius: 10px;
    padding: 0px 5px;
    font-size: 11px;
}

.Active_blk_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50% !important;
    border: none;
    font-weight: 600;
    font-size: 13px;
}

textarea.border-0.inputFortext.inputForback.actualArea.form-control {
    font-size: x-large;
    line-height: 24px;
}

.Update_Tag_suggest_blk {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    grid-gap: 10px;
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.Update_Image {
    position: relative;
}

.Update_Image img {
    border-radius: 50%;
}

.delete_img {
    position: absolute;
    top: -2px;
    right: -4px;
    cursor: pointer;
    background: #E6E6E6;
    border: 1px solid #FFFFFF !important;
    border-radius: 100px;
    width: 18px;
    height: 18px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.Update_HashTag_blk {
    background: #F6F6F6;
    border-radius: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    grid-gap: 5px;
    gap: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #808080;
    cursor: pointer;
}

svg#PplTooltip:hover {
    fill: #fff;
}

svg#PplTooltip:focus {
    fill: #fff;
}

button.Activity_btn_blk.btn-icon.pr-0.btn.btn-secondary {
    height: 100% !important;
}

.emoji-picker.light.visible {
    position: absolute !important;
    inset: 0px auto auto 0px !important;
    z-index: 9999 !important;
    /* z-index: 2; */
    /* z-index: revert; */
    /* z-index: 9999; */
    /* z-index: auto; */
    -webkit-transform: translate(939px, 345px) !important;
            transform: translate(939px, 345px) !important;
    border: none !important;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.2) !important;
    border-radius: 5px !important;
}

.emoji-picker__content {
    padding: 0.5em !important;
    height: 13rem !important;
    overflow: hidden;
    position: relative;
}

.Emoji_Btn_section {
    display: flex;
    align-items: end;
}

.Emoji_Btn_section>button {
    border: none !important;
    /* margin: 5px !important; */
    margin-top: "24px";
    font-size: 20px !important;
    background: none !important;
}

.emoji-picker__tab.active {
    background: #81C14B !important;
    color: #FFFFFF !important;
}

.Post_Display_Crad {
    border-radius: 5px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px;
    position: relative;
    overflow: hidden;
    border: none;
}

.Post_Dispaly_Card_body {
    padding: 0 !important;
}

.PostPreView_User_Info h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #4D4D4D;
}

.UserProfile-Name:hover {
    cursor: pointer;
    text-decoration: underline;
}

.Profile_Location {
    -webkit-text-decoration: #808080 underline;
            text-decoration: #808080 underline;
    font-size: 14px;
    cursor: pointer;
}

.Profile_Location:hover {
    -webkit-text-decoration: #81C14B underline;
            text-decoration: #81C14B underline;
}

.PostPreView_User_Info h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(77, 77, 77, 0.5);
}

/*======================= Start_Social_Feed_Post_disPlay_css ===================*/
.SingleMedia_blk img {
    width: 100% !important;
    object-fit: cover !important;
    height: 278px !important;
}
.SingleMedia_blk_1 img {
    width: 100% !important;
    object-fit: cover !important;
    height: 400px !important;
}

.SingleMedia_Heading_blk h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

.SingleMedia_Content_blk h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
}

.Text_Card_blk h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
}

.SingleCaptionMedia_blk {
    position: relative;
}

.SingleCaptionMedia_blk .Caption_Img {
    width: 100% !important;
    object-fit: cover !important;
    height: 278px !important;
}
.SingleCaptionMedia_blk_1 img {
    width: 100% !important;
    object-fit: cover !important;
    height: 400px !important;
}



.DoubleCaptionMedia_blk {
    display: flex;
    position: relative;
}

.DoubleCaptionMedia_blk .Double_Img {
    width: 100% !important;
    object-fit: cover !important;
    height: 278px !important;
    position: relative;
}

.DoubleCaptionMedia_blk .Double1_Img {
    width: 100% !important;
    object-fit: cover !important;
    height: 278px !important;

}


.Caption_box_section {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
    height: 53px;
    width: 100% !important;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
}

.Caption_box_section h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-left: 20px !important;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
}

.RecommendationMedia_blk {
    position: relative;
}

.RecommendationMedia_blk .Caption_Img {
    width: 100% !important;
    object-fit: cover !important;
    height: 278px !important;
}


.Recommendation_box_section {
    display: flex;
    justify-content: space-between;
    background: #235363af;
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
    height: 53px;
    width: 100% !important;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0
}

.Recommendation_box_section h3 {
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.RecmndNo_Imgbox_section {
    display: flex;
    justify-content: space-between;
    background: #235363af;
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
    height: 53px;
    width: 100% !important;
    display: flex;
    align-items: center;
}

.RecmndNo_Imgbox_section h3 {
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.Single_video_blk {
    position: relative;
}

.Single_video_blk img {
    width: 100%;
    object-fit: cover;
    height: 278px !important;
}

.Single_Video_Postion {
    position: absolute;
    top: 50%;
    width: 100%;
}

.Poll_Progress_Bar {
    background: #E5F0E0;
    border-radius: 35px;
    height: 50px;
}

.Paid_PodcastImg_blk {
    position: relative;

}

.Paid_PodcastImg_blk .Paid_Img {
    object-fit: cover;
    width: 100%;
    height: 310px;
}

.Paid_podcast_Top_section {
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    /* padding: 15px; */
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    top: 0;
}

.Paid_podcast_bottom_section {
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 3px;
    bottom: 0;
}

.Paid_Content_inner h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

.Paid_Content_inner h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
}

.paid_bottom_content_inner {
    display: flex;
    align-items: start;

}

.paid_bottom_content_inner .Ep_thum_img {
    border-radius: 5px;
    width: 70px;
    height: 70px;
    object-fit: cover;
}

.paid_User_dscrpn {
    margin-left: 15px;
    margin-right: 10px;
}

.paid_User_dscrpn h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

.paid_User_dscrpn h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;
}

.Singlepaid_User_dscrpn {
    margin-right: 10px;
}

.Singlepaid_User_dscrpn h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

.Singlepaid_User_dscrpn h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;
}

.Paid_Ep_btn {
    width: 20%;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}

.Eplist-blk-btn {
    background: rgba(255, 255, 255, 0.3);
    /* border: 1px solid #c4c4c4; */
    border-radius: 14px;
    display: inline-block;
    padding: 3px 10px;
    /* margin: 0 10px 10px 0; */
    color: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}

.Paid_bottom_tag {
    position: absolute;
    bottom: 0;
    right: 0;
}
.paid{
    
    bottom: 0;
    right: 0;
}

.ep_mic_icon {
    width: 22px;
    height: 22px;

}

/* .positionForMainPodacastIcon {
    position: absolute;
    top: 50%;
    text-align: center;
    width: 100%;

} */
.SinglePodacastIcon {
    margin: 0;
    position: absolute;
    left: 0%;
    top: 42%;
    width: 100%;
    text-align: center;
}



.paidSeries_bottom_content_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.paidSeries_bottom_content_inner .EpSeries_thum_img {
    position: relative;
    border-radius: 5px;
    width: 67px;
    height: 70px;
    object-fit: cover;
}

.PaidSeries_count {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    position: absolute;
    width: 41.13px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 27px;
    top: 27px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;

}

.paidSeries_User_dscrpn {
    margin-left: 15px;
    margin-right: 10px;
}

.paidSeries_User_dscrpn h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

.paidSeries_User_dscrpn h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;
}

.SeriesPaid_podcast_Top_section {
    position: absolute;
    top: 0;
    left: 0;
}

.EpisodePc_User_dscrpn {
    margin-left: 15px;
}

.EpisodePc_User_dscrpn h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

.EpisodePc_User_dscrpn h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;
}

.SingleUpload_User_dscrpn {}

.SingleUpload_User_dscrpn h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

.SingleUpload_User_dscrpn h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;
}

.Events_Card_blk {
    position: relative;
}

.Events_Card_blk img {
    width: 100%;
    object-fit: cover;
    height: 278px !important;
}

.Event-blk-Activebtn {
    background: rgba(129, 193, 75, 0.1);
    border: 1px solid #81C14B;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    padding: 5px 10px;
    cursor: pointer;
}

.Event-blk-Activebtn h2 {
    font-weight: 500;
    font-size: 17px;
    line-height: 150%;
    color: #81C14B;
}

.Event-blk-Activebtn h3 {
    font-size: 13px;
    font-weight: 400;
    line-height: 150%;
    color: #81C14B;
}

.Event-blk-btn {
    background: #F6F6F6;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    padding: 5px 10px;
    cursor: pointer;
}

.Event-blk-btn:hover {
    background: rgba(129, 193, 75, 0.1);
    border: 1px solid #81C14B;
    border-radius: 5px;
}

.Event-blk-btn h2 {
    font-weight: 500;
    font-size: 17px;
    line-height: 150%;
    color: #81C14B;
}

.Event-blk-btn h3 {
    font-size: 13px;
    font-weight: 400;
    line-height: 150%;
    color: #808080;
}

.Mp_card_blk {
    position: relative;
}

.Mp_card_blk img {
    width: 100%;
    object-fit: cover;
    height: 278px;
    border-radius: 3px;
}

.Mp_Category_blk h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #4D4D4D;

}

.mp_main_location {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #808080;
}

.mp_main_location span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #808080;
    -webkit-text-decoration: #4D4D4D underline;
            text-decoration: #4D4D4D underline;
    cursor: pointer;
}

.mp_main_location span:hover {
    -webkit-text-decoration: #81C14B underline;
            text-decoration: #81C14B underline;
}

.Mp_Action_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F6F6F6;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Mp_Action_section h1 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

button.mp_filled_btn.btn.btn-primary {
    padding: 6px 12px;
    /* height: 34px; */
}

button.Events_Btn_blkfroAll.btn.btn-secondary {
    padding: 6px 12px !important;
}

.socialFeed_Dropdown_EventMenu.dropdown-menu.show {
    width: 100% !important;
    display: block !important;
    background: #FFFFFF !important;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2) !important;
    border-radius: 5px !important;
    border: none !important;
}

.Events_GoingCard_Anal h4 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #808080;
}

.btn-icon.square {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px; /* Optional: for rounded corners */
  }
  
  /* Default size for web */
  .btn-icon.square {
    width: 28px;
    height: 28px;
  }
  
  /* Size for tablets and mobile devices */
  @media (max-width: 768px) {
    .btn-icon.square {
      width: 25px; /* Adjust size for tablets and mobile devices */
      height: 25px;
    }
  }
  
  /* Size for very small devices (mobile) */
  @media (max-width: 480px) {
    .btn-icon.square {
      width: 24px; /* Adjust size for small mobile devices */
      height: 24px;
    }
  }
  




/*======================= End_Social_Feed_Post_disPlay_css ===================*/
.LoginFormMainCont {
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
}

.Login_Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;

}

.FormBottomSec {
    display: flex;
}

.LoginConnectWith {
    display: grid;
    place-items: center;
}

.FormInputBlock {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.LoginFormSection {
    width: 100%;
    text-align: center;
}


.LoginSignUpBackground{
    background-image: url(/static/media/loginbg.cbc44709.jpg) !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}








.Login-Scroll-Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    max-height: 500px;
}

.LoginOptionTab {
    padding: 12px 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #4d4d4d;
    border-bottom: 1px solid #e1e1e1;
}

.LoginOptionTab:nth-last-child() {
    border-bottom: none;
}

.Login_searchbar {
    padding: 10px;
}


.LoginSearchPannel {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 3px 10px;
    border: 1px solid #e1e1e1;
    background-color: rgb(245 245 245);
}


.CountryCodeSearchPannel {
    background-color: #f6f6f6;
}


.TransparentBorder {
    /* box-sizing: content-box; */
    border: 1px solid transparent;
    box-shadow: none;
    cursor: pointer;
}

.TransparentBorder:hover {
    /* border: 1px solid #81c14b; */
    background-color: rgba(230, 243, 219, 0.444);
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px; */
}

.suggestedUsernames {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
}

.suggestedUsernames>h6 {
    flex: 1 1 auto;
}

.suggestedUserNamesItem {
    padding: 6px 10px;
    border: 1px solid #E0E0E0;
    text-align: center;
    cursor: pointer;
}

.suggestedUserNamesItem:hover {
    border: 1px solid #81c14b;
}

.LanguageTableAlltabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.LanguageTableAlltabs>.LanguageTabsMore {
    flex: 1 1 auto;
    /* max-width: fit-content; */
    text-align: center;
    transition: 0.3s ease;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    cursor: pointer;
    border: 1px solid #dee2e6;
}

.LanguageTabsMore:hover {
    background-color: #81c14b;
    color: white;
}



/* date time picker customize */

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    padding-top: 27px !important;
}

.react-datepicker__header {
    background-color: #81c14b !important;
    color: white !important;
    border-radius: 5px 5px 0px 0px !important;
    border-bottom: none !important;
}

.react-datepicker__year-wrapper {
    justify-content: center !important;
}

.react-datepicker__year-text--selected {
    background-color: #81c14b !important;
}

.react-datepicker__month-text--keyboard-selected {
    background-color: #81c14b !important;
}

.react-datepicker__year-text--keyboard-selected {
    background-color: #81c14b !important;
}

.react-datepicker__day-name {
    color: #ffffff !important;
}

.react-datepicker__day--selected {
    background-color: #81c14b !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: #81c14b !important;
}

.react-datepicker {
    border: none !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;

}

.react-datepicker__navigation-icon::before {
    border-color: #ffffff !important;
}



/* new Interest Design  */


/* .InterestItem {
    flex: 1 1 auto;
    border: 1px solid gray;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    max-width: fit-content;
    border-radius: 5px;
    margin: 4px;
}

.InterestsItems{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
} */



.InterestsItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.InterestsItems>.InterestItem {
    flex: 1 1 auto;
    /* max-width: fit-content; */
    text-align: center;
    transition: 0.3s ease;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    grid-gap: 5px;
    gap: 5px;
    max-width: calc(100% / 3);
    border: 1px solid #dee2e6;
}

.InterestItem:hover {
    background-color: #81c14b;
    color: white;
    border: 1px solid #81c14b;
}

.InterestItem.active {
    background-color: #81c14b;
    color: white;
    border: 1px solid #81c14b;
}






/* color */

.Login4D_500 {
    color: #4d4d4d !important;
    font-weight: 500 !important;
}

.Login4D_400 {
    color: #4d4d4d !important;
    font-weight: 400 !important;
}

.LoginBd_400 {
    color: #BDBDBD !important;
    font-weight: 400 !important;
}

.LoginAlert400 {
    color: #EB5757 !important;
    font-weight: 400 !important;
}

.LoginAlert500 {
    color: #EB5757 !important;
    font-weight: 500 !important;
}


.LoginLanguageBtn {
    color: #0066FF;
    font-weight: 500;
}

.Login81_400 {
    color: #81c14b;
    font-weight: 400;
}

.LoginLanguageBtn:hover {
    color: #81c14b;
}

.Login000_400 {
    color: #000000;
    font-weight: 400;
}

.Login000_500 {
    color: #000000;
    font-weight: 500;
}

.Login80_400 {
    color: #808080;
    font-weight: 400;
}

.Login33_400 {
    color: #333333;
    font-weight: 500;
}

.LoginAlert_600 {
    color: #EB5757;
    font-weight: 600;
}

.Login2F_400 {
    color: #2F80ED;
    font-weight: 500;
}

.Login03_400 {
    color: #032D23;
    font-weight: 400;
}

.Login4f_400 {
    color: #4F4F4F;
    font-weight: 400;
}


/* universal class */


.cursor-p {
    cursor: pointer;
}


.UnderLineText {
    text-decoration: underline !important;
}

.BorderInputAlert {
    border-color: #FF0000 !important;
}

.LoginSkipButton {
    font-size: clamp(10px 14px 15px) !important;
}



/* new language modal styling   */



.LanguageModalAllLang {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    height: 100%;
    max-height: 70vh;
    overflow-y: auto;
}

.LanguageModalAllLang>div>div {
    color: #4d4d4d;
    font-weight: 500;
}

.LanguageModalAllLang>div>div:hover {
    color: #81c14b;
    text-decoration: underline;
    font-weight: 500;
}































.InterestListItems {
    display: grid;
    grid-template-columns: repeat(auto-fit, calc(100% / 2));
}



.LanguageOffCanvas {
    height: 50%;
}

@media (max-width:567px) {
    .LanguageOffCanvas {
        height: none;
    }

}

.LanguagesTabItems_2:hover {
    background-color: #81c14b33;
}

.ScrollBarDisplayNone::-webkit-scrollbar {
    display: none;
}


.outline81 {
    outline-color: #81c14b;
}


.theme-form label {
    margin-right: 4px !important;
}







/*===================== start_Footer_Css_Code =======================*/
.Ad_Footer_Section {
  background: #ffffff;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
  position: fixed;
  width: 100%;
  z-index: 3;
  bottom: 0;
  right: 0;
}

.Ad_Footer_InnerBlk {
  height: 100px;
  display: flex;
  justify-content: center;
  grid-gap: 80px;
  gap: 80px;
  align-items: center;
}

.Footer_content h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}

.Footer_content p {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}

button.mr-3.Footer_LoginButton_blk.btn.btn-outline-primary {
  margin: 0;
  padding: 4px 38px;
  border: 1px solid #81c14b;
  border-radius: 3px;
  color: #81c14b;
  font-weight: 500;
  font-size: 14px;
}

button.mr-3.Footer_LoginButton_blk.btn.btn-outline-primary {
  box-shadow: none;
}

button.ml-3.Footer_SgnpButton_blk.btn.btn-primary {
  margin: 0;
  padding: 4px 38px;
  background: #95cb67;
  border: 1px solid #81c14b;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}

button.ml-3.Footer_SgnpButton_blk.btn.btn-primary:focus {
  box-shadow: none;
}

.Footer_span {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
}

/*===================== end_Footer_Css_Code =======================*/

.Page_Body_section {
  /* padding-top: 100px; */
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.AdPost_Display_Crads {
  max-width: 547px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  border-radius: 5px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px;
  position: relative;
  overflow: hidden;
  border: none;
}

.AdManager_Card_User {
  display: flex;
  align-items: start;
}

.Ad_PreviewCard_blk {
  position: relative;
}

.Ad_PreviewCard_blk .AdPrev_Img {
  object-fit: cover;
  width: 100%;
  height: 278px;
}

.AdPreview_Card_bottom_section {
  width: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  bottom: 0;
}

.AdPreview_Card_content_inner {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.AdPreview_Card_content_inner img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.Card_Tag_content h3 {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  line-height: 21px;
}

.Card_Tag_content h4 {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  line-height: 21px;
}

.Card_Tag_content h3 {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  line-height: 21px;
}

.Card_Tag_content h4 {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  line-height: 21px;
}

button.Card_Know_btn.btn.btn-primary {
  background: #81c14b;
  border-radius: 4px;
  border: none;
  padding: 4px 14px;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
}

button.Card_Know_btn.btn.btn-primary:focus {
  box-shadow: none;
}

button.Card_Know_btn.btn.btn-primary:active:focus {
  background: #81c14b;
  box-shadow: none;
}

.SingleMedia_Content_blk h3 {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.Crad_Text-heading h3 {
  font-weight: 600;
  font-size: 16px;
  color: #333333;
}

button.Login_Btn_blk.btn.btn-primary {
  background: #95cb67;
  border: 1px solid #81c14b;
  border-radius: 3px;
  padding: 4px 28px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

button.Login_Btn_blk.btn.btn-primary:focus {
  box-shadow: none;
}

button.Login_Btn_blk.btn.btn-primary:active:focus {
  background: #95cb67;
  box-shadow: none;
  border-color: #95cb67;
}

/* =====================input.w-100.form-control ===========*/
/* Default styling for all views */
input.w-100.form-control {
  width: 100%;
  min-width: 320px;
  font-size: 12px;
  color: rgba(78, 79, 80, 0.6);
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 3px;
  background: #ffffff;
  /* padding-right: 15% !important; */
}

/* Media query for mobile views */
@media (max-width: 767px) {
  input.w-100.form-control {
    /* Adjust properties for mobile views */
    min-width: auto;
    font-size: 14px;
    padding-right: 10px !important;
  }
}

/* ==========================input.w-100.form-control  end ===============*/
input.w-100.form-control:focus {
  box-shadow: none;
  border-radius: 3px;
  border: 1px solid #81c14b;
}

button.mr-3.Footer_LoginButton_blk.btn.btn-outline-primary:active:focus {
  background: #81c14b;
  color: white;
  box-shadow: none;
}

button.mr-3.Footer_LoginButton_blk.btn.btn-outline-primary:hover {
  background: #81c14b;
  color: white;
}

.Input_Position_section {
  position: relative;
}

.InputBox_Icon {
  position: absolute;
  bottom: 28px;
  right: 15px;
}

.InputBoxs_Icon {
  position: absolute;
  bottom: 5px;
  right: 15px;
}

.positionForSerachSmile {
  position: absolute;
  bottom: 7px;
  right: 15px;
}

/*============== Universal _Button_For_Whole_Project ==================*/
button.NFT_Create_Btn.btn.btn-primary {
    border-radius: 23px;
    font-size: 14px;
    font-weight: 700;

}

button.Ntf_Secondary_btn.btn.btn-outline-secondary {
    color: , #000;
    color: var(--000000-primary, #000);
    border-radius: 23px;
    font-size: 12px;
    font-weight: 700;
    background: #FFF;
    background: var(--ffffff, #FFF);
    border: 1px solid #000;
}

button.Profile_Drop_Detail_btn.btn.btn-primary {
    border-radius: 23px;
    font-weight: 700;
    border-radius: 23px;
    font-size: 14px;
}

/*============== Universal _Button_For_Whole_Project ==================*/

.NFT_Tab_Btn_Blk {
    color: , #5D5D5B;
    color: var(--5-d-5-d-5-b, #5D5D5B);
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.NFT_Tab_ActiveBtn_Blk {
    color: , #81C14B;
    color: var(--7-a-52-f-4-primary, #81C14B);
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.Wallet_Btn_Blk {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #81C14B;
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.NFT_Profile_Blk {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    /* background: url(<path-to-image>), lightgray 50% / cover no-repeat; */
    background: no-repeat;
    background-repeat: no-repeat;
    border-radius: 50%;
    cursor: pointer;
}

.NFT_Profile_Blk img {
    object-fit: cover;
    border-radius: 50%;

}


/*====================== start_Explore_Blk_Css ========================*/
.ExploreNft_Heading h1 {
    font-size: 42px;
    font-weight: 800;

}

.ExploreNftTab_Section {
    margin-top: 15px;
}

/* .ExploreNft-Tab-block-box {
    padding: 0 20px 20px;
} */

.Tab_Block li {
    cursor: pointer;
    display: inline-block;
    padding: 10px 20px;
    stroke-width: 1px;
    border: 0.5px solid #CFDBD5;
    border-radius: 18px;
    margin: 0 15px 15px 0;
    display: inline-flex;
    align-items: center;
    color: , #000;
    color: var(--000000-primary, #000);
    font-size: 12px;
    font-weight: 700;
}

.ExploreNft_CardThisGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.ExploreNft_Cardbox_section {
    background: #FFF;
    background: var(--ffffff, #FFF);
    padding: 24px;
    border-radius: 16px;
    border: 1px solid #CFDBD5;
    box-shadow: 0 0 0 rgba(0, 0, 0, .1);
    position: relative;
}

.ExploreNft_Img_smple {
    position: relative;
    border-radius: 14px;
    background-repeat: no-repeat;
    margin-top: 18px;
}

.ExploreNft_Img_smple img {
    object-fit: cover;
    width: 100%;
    height: 254px;
    border-radius: 14px;
}

.ExploreNft_Heading_Blk h3 {
    margin-top: 18px;
    color: , #000;
    color: var(--000000-primary, #000);
    font-weight: 800;
    font-size: 18px;
}

.ExploreNft_SubHeading_Blk {
    position: relative;
    margin-top: 12px;
    display: flex;
    align-items: center;
}

.ExploreNft_SubHeading_Blk h4 {
    color: , #000;
    color: var(--000000-primary, #000);
    font-weight: 700;
    font-size: 14px;
}

.ExpoleNft_Bottom_Section {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

button.Explore_Card_Button.btn.btn-primary {
    border-radius: 23px;
    font-size: 14px;
    font-weight: 700;
}

.ExpoleNft_Bottom_Section h4 {
    color: , #81C14B;
    color: var(--7-a-52-f-4-primary, #81C14B);
    font-weight: 700;
    font-size: 14px;

}

/*====================== end_Explore_Blk_Css ========================*/

/*====================== end_Drop_Blk_Css ==========================*/
.Drop_Cover_MainSection {
    position: relative;
    width: 100%;
    height: 428px;
    border-radius: 16px;
    background: #ECF1F1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Drop_Position_Section {
    position: absolute;
    top: 40px;
    left: 55px;
}

.Drop_Position_Section span {
    font-weight: 100;
    color: , #5D5D5B;
    color: var(--5-d-5-d-5-b, #5D5D5B);
    font-size: 42px;
}

.Drop-custom-block {
    margin: 0 0 30px;
}

.DropCard_heading_section h2 {
    color: , #000;
    color: var(--000000-primary, #000);
    font-weight: 800;
    font-size: 28px;
}

.Drop_Profile_Center_Section {
    position: relative;
    text-align: center;
}

.Drop_Profile_Center_Section h1 {
    color: #FFF;
    color: var(--ffffff, #FFF);
    font-size: 42px;
    font-weight: 800;
    margin-top: 20px;
}

.Drop_Profile_Center_Section h2 {
    color: #FFF;
    color: var(--ffffff, #FFF);
    font-size: 20px;
    font-weight: 800;
    margin-top: 10px;
}










.Drop_Profile_Detail_Page {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: end;
    text-decoration: underline;
    text-overflow: unset;
    color: #000;
    font-size: medium;
    font-weight: 700;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}

.Drop_UserProfile_Detail_Cover_Page {
    display: flex;
    justify-content: center;
    -webkit-text-decoration: dotted;
            text-decoration: dotted;
    font-family: 'Courier New', Courier, monospace;
    font-size: 12px;
    font-weight: 500;
    color: #81C14B;
    -webkit-text-decoration: dashed;
            text-decoration: dashed;
    align-items: center;
    display: flexbox;
    align-items: center;
    flex-direction: row;
    flex-direction: column-reverse;
    font-weight: 400;
}

.Drop_UserCover_Detail_Page {
    display: grid;
    grid-template-rows: none;
    align-items: self-start;
    -webkit-text-decoration: dashed;
            text-decoration: dashed;
    font-size: 12px;
    font-weight: 800;
    text-overflow: unset;
    font-weight: 400;


}

.Drop_Cover_MainSection_Block_Section {
    descent-override: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    text-transform: lowercase;
    text-align: center;
    font-weight: calc(100%-42px);
    font-weight: 400;
    text-shadow: none;
}


/*====================== end_Drop_Blk_Css ==========================*/


.Mp_Form_ImageUploadInputs1>div {
    flex: 1 1 auto;
    max-width: 200px;

}


.MpChooseImage1 {
    border: 1px dashed #D1D0D3;
    border-radius: 20px;
    /* padding: 20px; */
    width: 100px;
    height: 150px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    object-fit: cover;
    object-position: center;
    justify-content: center;
}

.golivesetting-toggle-box12 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.nftfoot {

    background: rgba(235, 240, 240, 0.50);

    background: var(--ebf-0-f-0-soft-on-light, rgba(235, 240, 240, 0.50));
    width: 100%;
    
   
    flex-shrink: 0;
}

