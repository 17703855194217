.blinkab {
    animation: blinkAnimation 1s infinite;
    background-color: red;
  }
  .podliveblinkes{
    border-radius: 20px;
    position: absolute;
    left: 94px;
    top: 56px;
    font-size: 19px;
  }
  .podliveblink{
    border-radius: 20px;
    position: absolute;
    left: 16px;
    top: 9px;
    font-size: 19px;
    
    font-size: 0.75rem !important;

  }
  
  @keyframes blinkAnimation {
    0%, 50%, 100% {
      opacity: 1;
    }
    25%, 75% {
      opacity: 0;
    }
  }




  .heart-explosion1 {
    position: relative;
    
    
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    cursor: pointer;
  }
 
  
  
  .heart-emojii {
    position: absolute;
    font-size: 24px;
    
    animation: explode 1s ease-out infinite;
    animation-delay: calc(0.1s * var(--index));
    cursor: pointer;
  }
  .heart{
    position: absolute;
    right: 17%;
    bottom: 20px;
  }
  @keyframes releaseHeart {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }

  
  @keyframes explode {
    0% {
      transform: translateY() scale(0);
      opacity: 0;
    }
    100% {
      transform: translateY(-100px) scale(1);
      opacity: 1;
    }
  }

  .heart-animation-app1{
    position: relative;
    right: 30%;
  }

  @media (min-width: 576px) {

  .mobheaderstyles{
    padding: 20px;
    padding: .75rem !important;
  }
  .podliveblinkes{
    border-radius: 20px;
    position: absolute;
    left: 113px;
    top: 73px;
    font-size: 19px;
  }
}
  
  @media (max-width: 576px) {

  .mobheaderstyles{
    padding: 20px;
    padding: .75rem !important;
  }
  .podliveblinkes{
    border-radius: 20px;
    position: absolute;
    left: 113px;
    top: 73px;
    font-size: 19px;
  }
}
  

@media (max-width: 280px){

  .podliveblinkes{
    border-radius: 20px;
    position: absolute;
    left: 93px;
    top: 63px;
    font-size: 14px;
}
}
  
  @media (min-width: 280px) {

  .InputForcolor-createlive{

  }
  .mobheaderstyles{
    padding: 20px;
    padding: .75rem !important;
  }
  .podliveblinkes{
    border-radius: 20px;
    position: absolute;
    left: 97px;
    top: 65px;
    font-size: 14px;
  } 
  .podliveblinkess{
    border-radius: 20px;
    position: absolute;
    left: 97px;
    top: 65px;
    font-size: 14px !important;
  } 
  .podliveblink{
    border-radius: 20px;
    position: absolute;
    left: 16px;
    top: 9px;
    font-size: 19px;
    
    font-size: 0.75rem !important;
  }
  .comimgsss{
     
      padding-right: 0.5rem !important;
      padding-left: 1.0rem !important;
  
  }
}
.pxy-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

/* video */

/* .video-container {
  position: relative;
  width: 100%;
   /* 16:9 aspect ratio (adjust as needed) 
  overflow: hidden;
}

.video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video {
  width: 105%;
    height: 111%;
  object-fit: cover;
} */

/* @media (min-width: 1280px) {

  .video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; 
    overflow: hidden;
  }
  
  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
} */
/* @media (min-width: 280px) {
  .video-container {
    position: relative;
    width: 100%;
    
    overflow: hidden;
    object-fit: cover;
    object-position: center center;
  }
  
  .video-wrapper {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 230px;
  }
} */

.video-container1 {
  position: fixed;
  width: 100%;
  height: 100%;

  overflow: hidden;
}

.video {
  /* position: fixed; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 280px){
  .video-container1 {
    position: absolute;
    width: 100%;
    height: 100%;
  
    overflow: hidden;
  }
  .video {
    /* position: fixed; */
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .ShareOnlineUser {
    position: absolute;
    top: 2px;
    left: -12px;
  }
  
}
@media (min-width: 580px){
  .video-container1 {
    position: fixed;
    width: 100%;
    height: 100%;
  
    overflow: hidden;
  }
  .video {
    /* position: fixed; */
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  
}
@media (max-width: 280px){
  .whocan{
         position: relative;
         width: 140px;


}
.readylive{
  width: 150px;
}
}
@media (max-width: 280px){
  .whocan{
    position: relative;
    left: 0px;
    top: 2px;
    width: auto;
  }
}
@media (max-width: 1191px){
  .detailheart{
    position: absolute;
    right: 13% !important;
    top: 10px;
  }
  
}

.micsend{
  position: relative;
  left: 20px;
  
}

.reposect {
  position: relative; /* Create a positioning context */
}

.line {
  position: absolute; /* Position the line absolutely within the container */
  bottom: -10px;/* Align the line to the bottom of the container */
  left: 0; /* Align the line to the left edge of the container */
  width: 100%; /* Make the line span the entire width */
  height: 1px; /* Set the line height (thickness) */
  background: rgba(0, 0, 0, 0.10); /* Line color */
}



.eyees{
  top: -10px;
}

.goliveblink{
  top: 17px;
  left: 10px;
}

.detailheart{
  position: absolute;
  right: 10%;
  top: 10px;
}

@media (max-width: 1000px){
  .detailheart {
    position: absolute;
    right: 14%;
    top: 8px;
}
}

.custom-select {
   /* Remove the default dropdown arrow in some browsers */
  background-image: url('https://static.thenounproject.com/png/1123247-200.png'); /* Add your dropdown icon image path here */
 
  
  padding-right: 30px; /* Adjust this value to align the icon properly */
}

.canvas{
  text-align: left;
  /* position: relative; */
  /* left: 36px; */
  margin-left: 8px;
}

.canvas1{
  border-radius: 22px 22px 0px 0px;
background: #FFF;
box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.10);
height: auto;
}

.canvas li:hover {
  background-color: #E6F4F3;
  /* Add any other styling you want for the hover effect */
}

@media(max-width:280px){
.sendtext{
  padding-left: 10px;
}
}

.canbutton{
  position: relative;
  top: 16px;
}

.view{
 
  border-radius: 5px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 0px;
}
.view1{
 
  
    minWidth: '500px',
   
}

.eyesicon{

left: 54px;

}

.usernamees{
  margin: 0 0 1px 0px !important
}
.heartcount{
  position: relative;
  left: 24px;
    top: -6px;
}
.heart-button{
  position: relative;
  top: 19px;
}

.heartplacing{
  position: relative;
  right: 32%;
  top: -20%;
}

.py-11{
  padding-top: 9px;
  padding-bottom: 4px;
}
.py-112{
  padding-top: 9px;
  padding-bottom: 10px;
}

.sendicon {
  position: absolute;
  right: 2%;
  top: 32%;
}
@media (max-width: 417px) {
  .filter-text {
      display: none;
  }
}

.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 10px;
  color: #4d4d4d;
}

.custom-select__arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the arrow doesn't interfere with the select box */
  color: #6E6B7B; /* Color for the chevron-down icon */
  font-size: 16px; /* Adjust the font size as needed */
}

.canvas li {
  border-bottom: 1px solid #ccc; /* Add a border at the bottom of each li */
  padding: 8px 0; /* Adjust padding as needed */
  color:#E6F4F3 ;
}

.canvas li:last-child {
  border-bottom: none; /* Remove border from the last li */
}

.my-4{
  margin-top: 0.65rem !important;
  margin-bottom: 1rem !important;
}


.hrtcnt{
  position: relative;
  top: 17px;
}

.reort{
  align-items: center;
}

.spacing{
  margin-left: 0.70rem!important;
}


.emoj{
  position: absolute;
    right: 26px;
    top: 3px;
}

.mobile{
  position: relative;
  left: 9px;
}

.mobileheart{
  position: absolute;
  top: -52px;
  left: 8px;
}