.rowForrevert.row {
    --bs-gutter-x: revert !important;
}

.dropToggleWidth {
    width: 500px !important
}

.headerForpodcast {
    position: absolute;
    display: flex;
    justify-content: space-between;
    color: whitesmoke;
    width: 100%;
    z-index: 1;
}

.simple-linear {
    background-image: linear-gradient(180deg, black, transparent);
    z-index: 2;
}

.podcastForcard {
    border-radius: 5px !important;
    /* box-shadow: 1px 5px #88888842; */
    box-shadow: 0px 0px 10px #E0E2DE;
    margin: 2%;
}

.container-padding {
    padding: 0px 80px 0px 80px;
    display: flex;
    justify-content: center;
}

.scroll-for-podcast {
    overflow-y: scroll;
    /* height: 90vh; */
    height: calc(100% - 30px);
    margin: 10px 0px;
}

.moreoverFormargin {
    color: white;
}

.paidTagForpostition {
    position: absolute;
    /* bottom: 110px;
    left: 73%; */
    bottom: 0px;
    right: 0px;
}

.adForpodcast {
    position: absolute;
    z-index: 100;
}

.adbigTagForpodcast {
    position: absolute;
    display: flex;
    bottom: 0px;
    justify-content: space-between;
    align-items: center;
    margin-left: 2%;
    color: white;
    background-color: #5a6a64a4;
    width: 100%;
    padding: 4%;
    left: -2%;
}

.bottomright {
    position: absolute;
    bottom: 8px;
    right: 16px;
    font-size: 18px;
}

.bottomcenter {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
}

.createForHeadercolor {
    padding: 1%;
    background-color: rgba(129, 193, 75, 0.2);
}

.colorForSwitch .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: #81C14B !important;
}

.colorForSwitch .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: revert;
    border: 1px solid #81C14B;
}

.whocanListenForpodcast {
    padding-right: 31rem;
    background-color: '#F6F6F6';
}

.PodacstForInput.form-control:focus {
    border: 1px solid #81C14B !important;
    background-color: #F6F6F6;
}

.soundUploadForpodcast {
    border-radius: 5px;
    /* background-color: #f1f3f4; */
    padding: 1%;
}

.imgbackgroundForPostion {
    position: absolute;
    left: 6px;
}

.existingDrop.dropdown-menu.show {
    display: block;
    width: 150%;
    min-width: max-content;
}

.existingDropQa.dropdown-menu.show {
    display: block;
    width: 150%;
    min-width: max-content;
    left: -15px !important;
}

.imgbackgroundFor {
    position: relative;
    width: 60px;
    height: 60px;
    /* background-color: #D0B736; */
    animation-name: bck;
    /* animation: backwards; */
    animation-duration: 5s;
    animation-delay: 1s;
    border-radius: 100%;
    animation-iteration-count: infinite;
    animation-direction: reverse;
    left: 2.5%;
    top: 27.7%
}

.padding-music-footer {
    padding: 10rem 20rem;
}

.padding-music-footer-slider {
    padding: 0rem 8rem;
}

.logo-size {
    width: 8rem;
}

.shadow-play {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 50% !important;
}

.button-hovered {
    background-color: #D3D3D3;
    border-radius: 50%;
}

.innerscroll {
    display: flex;
    justify-content: space-between;
    overflow-y: hidden;
    min-height: 85%;
    width: 100%;
    padding: 50px 45px;
}

.offcanvas-body.rem-scroll {
    overflow-y: hidden !important;
}

.pl {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    width: 50%;
}

.qu {
    position: relative;
    margin: 0;
    width: calc(100vw / 3);
    max-width: 550px;
    max-height: calc(75vh);
    overflow-y: auto !important;
}

.qu::-webkit-scrollbar {
    display: none;
}

.top {
    padding-left: 0;
    padding-bottom: 18px;
    margin-left: 20px;
    margin-bottom: 0;
    border-bottom: 0.4px solid rgba(142, 142, 147, .2);
}

.listScroll {
    overflow-y: auto;
    height: calc(100% - 30px);
    margin: 10px 0;
}

.table-head {
    position: sticky;
    top: 0;
    background: #6c7068;
    padding: 5px;
    z-index: 5;
    border-radius: 2px;
}

.img-container {
    position: relative;
}

.img-options {
    position: absolute;
    top: 8px;
    right: 4px;
    z-index: 2;
}

.scroll-canvas {
    overflow-y: hidden;
}

.margin-for-animation {
    margin: 7px 0;
}

.btn-size-reduce {
    padding: 6px 6px;
}

@keyframes bck {
    0% {
        /* background-color: #f3cc0bea; */
        border: 4px solid #b6b091ea;
    }

    25% {
        /* background-color: #dbba17a9; */
        border: 7px solid #c5b770a9;
    }

    50% {
        border: 9px solid #dbba17a9;
    }

    75% {
        border: 11px solid #ad9b41a9;
    }

    100% {
        /* background-color: #ceb12065; */
        border: 14px solid #d8b81b65;
    }
}

.animeForHeader {
    animation: headeranima 0.4s ease-in-out;
}


@keyframes headeranima {
    from {
        transform: translateY(-200px);
    }

    to {
        transform: translateY(0px);
    }
}

.chevron-down {
    animation-name: bounce;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}


.podcastcenterForstyle {
    position: absolute;
    top: 40%;
    left: 38%;
    /* width: 100%; */
    text-align: center;
    /* font-size: 18px; */
}

#usercontainer {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
}

.usercircle {
    border-radius: 50%;
    background-color: #CEB42F;
    width: 100px;
    height: 100px;
    position: absolute;
    opacity: 0;
    animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);
}

@keyframes scaleIn {
    from {
        transform: scale(.5, .5);
        opacity: .5;
    }

    to {
        transform: scale(2.5, 2.5);
        opacity: 0;
    }
}

.useritem {
    z-index: 100;
    padding: 2px;
}

.useritem img {
    width: 35px;
}

.audioForlivepodcast {
    position: absolute;
    z-index: 1;
    color: white;
    width: -webkit-fill-available;
}

.audioForlivepodcastAudio {
    position: absolute;
    z-index: 1;
    color: white;
    width: -webkit-fill-available;
    bottom: 0px;
}

.Fullsizecontainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Fullsizecontainercard {
    position: relative;
    width: 100%;
    height: 100%;
}

.Fullsizecontainertop-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.Fullsizecontainerbottom-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.podcastMic {
    position: relative;
}

.podcastMicImg {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
}

.podcastMicpositionForWings {
    height: 215px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: beats 0.80s ease-in-out infinite;
}

@keyframes beats {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }

    50% {
        transform: translate(-50%, -50%) scale(1.5);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

.seriesPlayForIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.seriesPlayForIconList {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.floatingButtonWrap {
    display: block;
    position: fixed;
    bottom: 80px;
    right: 160px;
    z-index: 1;
}

.floatingButtonWrapFilter {
    display: block;
    position: fixed;
    bottom: 120px;
    right: 1px;
    z-index: 1;
}

.flex-mob-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.padding-for-player {
    padding: 0rem 6rem;
}

.MusicList:hover {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    background-color: #80c14b35 !important;
    padding: 0.5rem !important;
    cursor: pointer;
    transition: 0.3s;
}

.list-heading {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.play-highlight:hover {
    background-color: #DDDDDD;
    border-radius: 50%;
    padding: 2px;
}

.caption {
    text-align: left;
}

.btn-outline-secondary.border-clr {
    border-color: #dedede;
}

.rem-bg {
    background-color: #ffffff !important;
}

@media (max-width: 375px) {
    .whocanListenForpodcast {
        padding-right: 10.5rem;
    }

    .imgbackgroundFor {
        position: absolute;
        width: 60px;
        height: 60px;
        /* background-color: #D0B736; */
        animation-name: bck;
        /* animation: backwards; */
        animation-duration: 5s;
        animation-delay: 1s;
        border-radius: 100%;
        animation-iteration-count: infinite;
        animation-direction: reverse;
        left: 3.2%;
        top: 26.7%
    }

    .positionLikeForMobPodcast {
        position: absolute;
        right: 33px;
        top: -195px;
    }
}

@media (max-width:1880px) {
    .timecontrols {
        width: calc(100% - 470px);
    }

    .padding-music-footer {
        padding: 10rem 15rem;
    }
}

@media (max-width:1440px) {
    .timecontrols {
        width: calc(100% - 364px);
    }

    .padding-for-player {
        padding: 0rem 1rem;
    }
}

@media (max-width: 1200px) {
    .rem-tab-hide {
        display: none;
    }
}

@media (max-width:1024px) {
    .timecontrols {
        width: calc(100% - 255px);
    }

    .margin-tab {
        margin-top: 5px;
    }

    .padding-music-footer {
        padding: 2rem 0rem;
    }

    .padding-for-player {
        padding: 0rem;
    }

}

@media (max-width: 992px) {
    .floatingButtonWrap {
        display: block;
        position: fixed;
        bottom: 50px;
        right: 150px;
        z-index: 1;
    }

    .floatingButtonWrapFilter {
        display: block;
        position: fixed;
        bottom: 55px;
        right: 125px;
        z-index: 1;
    }

    .container-padding {
        padding: 0px;
    }

    .padding-music-footer {
        padding: 0px;
    }

    .padding-music-footer-slider {
        padding: 0px;
    }

    .innerscroll {
        flex-direction: column;
        padding: 0px 20px;
    }

    .qu {
        position: relative;
        margin: 0;
        width: 100% !important;
        max-width: 100% !important;
        max-height: 500px !important;
        overflow-y: auto !important;
    }

    .qu::-webkit-scrollbar {
        display: none;
    }

    .scroll-canvas {
        overflow-y: auto;
    }

}

@media (max-width: 768px) {
    .floatingButtonWrap {
        display: block;
        position: fixed;
        bottom: 76px;
        right: 50px;
      
        z-index: 1;
    }

    .floatingButtonWrapFilter {
        display: block;
        position: fixed;
        bottom: 165px;
        right: 125px;
        /* right: 25px; */
        z-index: 1;
    }

    .timecontrols {
        width: 100%;
    }

    .logo-size {
        width: 100px;
    }
}

@media (max-width: 734px) {
    .whocanListenForpodcast {
        padding-right: 12.8rem;
    }
}

@media (max-width: 412px) {
    .whocanListenForpodcast {
        padding-right: 10.5rem;
    }
}

@media (max-width: 425px) {
    .whocanListenForpodcast {
        padding-right: 10.5rem;
    }

    .floatingButtonWrap {
        display: block;
        position: fixed;
        bottom: 84px;
        right: 10px;
        z-index: 1;
    }

    .floatingButtonWrapFilter {
        display: block;
        position: fixed;
        bottom: 165px;
        right: -14px;
        z-index: 1;
    }
}


@media (max-width: 375px) {
    .whocanListenForpodcast {
        padding-right: 10.5rem;
    }

    .floatingButtonWrap {
        display: block;
        position: fixed;
        bottom: 125px;
        right: 10px;
        z-index: 1;
    }

    .floatingButtonWrapFilter {
        display: block;
        position: fixed;
        bottom: 165px;
        right: -14px;
        z-index: 1;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .padding-music-footer {
        padding: 9rem 6rem;
    }
}







.MusicList {
    display: flex;
    align-items: center;
}

.MusicList>div {
    flex: 1 1 200px;
}

.categories-scroll {
    overflow-y: scroll;
    max-height: 200px;
    overflow-x: hidden;
}

.who-can-listen-scroll {
    overflow-y: scroll;
    max-height: 150px;
    overflow-x: hidden;
}

.scrollForSharePodcast {
    overflow-y: scroll;
    max-height: 250px;
}

.dropdown-padding-form {
    padding: 9px 20px !important;
}

.custom-toggle {
    color: #555555;
    font-weight: normal;
    /* font-size: 16px; */
    font-size: '14px';
}

.podcast-test-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 6px;
    height: 38px;
    color: #4d4d4d;
    font-weight: 500;
    transition: 0.2s;
}

.podcast-test-button:hover {
    background: #81C14B;
    color: #fff !important;
}

.podcast-setting-preview-blk {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.podcast-setting-preview-blk h4 {
    color: #ffffff;
}

.obj-container.dropdown-menu.show {
    width: fit-content !important;
}

.pod-footer {
    display: grid;
    grid-template-columns: 40% 20% 40%;
}

.pod-footer>div {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.TimerAlignment {
    justify-self: flex-end;
}


.VolumeSliderCustomize {
    position: absolute;
    bottom: 2rem;
    left: 0;
    height: 10rem;
    border-radius: 30px;
    width: 2rem;
    padding: 10px 2px;
    background-color: whitesmoke;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

@media (min-width:280px) and (max-width: 768px)  {
    .podnav{
  
    display: contents;
    }
  }
@media (min-width:280px) and (max-width: 336px)  {
    .tooglespace{
  
        border-radius: 2px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        grid-gap: 10px;
        gap: 0px;
    }
  }
@media (min-width:280px) and (max-width: 576px)  {
    .podwholenav{
  
        box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
   
      
        overflow: hidden;
        margin-right: 11px;
        margin-left: 11px;
    }
  }
@media (min-width:280px) and (max-width: 311px)  {
    .podpad{
  
      padding: 0.5rem 0.35rem !important;
    }
  }
@media (min-width:280px) and (max-width: 350px)  {
    .newpod{
  
   margin-top: 28px !important;
    }
  }
@media (min-width:280px) and (max-width: 374px)  {
    .podcomment{
  
   display: none !important;
    }
  }
@media (max-width:280px)   {
    .threedot{
  
   right: 16px;
    }
  }

@media (min-width:280px) and (max-width: 334px)  {
    .mobwatch{
  
    position: relative;
    right: 10px;
    }
    .butmobwat{
        padding: 10px 17px !important;
    }
    .butmobwat1{
        padding: 10px 8px !important;
    }
  }
@media (min-width:280px) and (max-width: 1200px)  {
    .pod-nav{
  
      display: none;
    }
  }
@media (min-width:280px) and (max-width: 370px)  {
    .foterpodmob{
  
     width: 270px !important;
    }
  }
@media (min-width:280px) and (max-width: 350px)  {
    .sharemodalpod{
  
    position: relative;
    right: 14px;
    }
  }
@media (min-width:1201px) and (max-width: 1214px)  {
    .filcret{
  
        display: flex;
        position: relative;
        top: -36px;
    }
  }
@media (min-width:589px) and (max-width: 1200px)  {
    .mypodehead{
  
      
        position: relative;
      left: 2.5%;
    }
  }
@media (min-width:438px) and (max-width: 589px)  {
    .mypodehead{
  
      
        position: relative;
      left: 4.5%;
    }
  }
@media (min-width:361px) and (max-width: 577px)  {
    .filtericonn {
           
        top: 59px !important;
        left: -11px !important;

    }
  }
@media (min-width:280px) and (max-width: 360px)  {
    .filtericonn {
           
        top: 51px !important;
        left: -11px !important;

    }
  }
@media (min-width:577px) and (max-width: 767px)  {
    .filtericonn {
           
      left: 12px !important;
    }
  }
@media (min-width:280px) and (max-width: 437px)  {
    .mypodehead{
  
      
        position: relative;
      left: 8%;
    }
  
  }

  .MobForChat1 {
    position: absolute;
    z-index: 0;
    /* width: 100%; */
    /* max-height: 282px; */
    overflow-y: scroll;
    /* background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.3)); */
    webkit-mask-image: linear-gradient(356deg, white, transparent);
    /* padding-right: 1px; */
    margin-left: 0px;
  }

  @media (max-width: 992px){
.InputForcolor12 {
    background: rgba(249, 251, 247, 0.8);
    border: 1px solid rgba(126, 178, 98, 0.1);
    padding-right: 24%;
    position: absolute;
    bottom: 0;
    position: fixed;
    /* margin-left: -15px; */
}
  }

  .podheart{
    position: relative;
    top: -13px;
  }

  .podwebheart{
    position: relative;
    top: -8px;
  }

  .podsliveblink{
    border-radius: 20px;
    font-size: 16px;
  }

  .podjoined{
    margin-left: 10px;
  }

  .chtspod{
    left: 26px;
  }

  .golivesetting-toggle-box1{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    
  }
  .m-31{
    margin: 1rem 1rem 1rem 0rem !important;
  }

  .hetpod{
    position: relative;
    right: 5%;
  }

  .reportbu{
    padding: 10px 11px !important;
  }

  .pt-21{
    padding-top: 0.8rem !important;
  }

  .filtericonn{
    display: flex;
    /* flex-direction: row-reverse; */
    /* flex-wrap: wrap; */
    position: absolute;
    top: 97px;
    left: 95px;
  }

  .mr-31{
    margin-right: 2rem!important;
    top: -6px;
  }

  .addpodcast{
    position: absolute;
  }

  .foterpodmob{
    width: 350px;
  }
  .fotlike{
    width: max-content;
  }


  .toppoddroup{
    padding: 0.25rem 0.5rem !important;
  }