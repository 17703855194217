/*============== Universal _Button_For_Whole_Project ==================*/
button.NFT_Create_Btn.btn.btn-primary {
    border-radius: 23px;
    font-size: 14px;
    font-weight: 700;

}

button.Ntf_Secondary_btn.btn.btn-outline-secondary {
    color: var(--000000-primary, #000);
    border-radius: 23px;
    font-size: 12px;
    font-weight: 700;
    background: var(--ffffff, #FFF);
    border: 1px solid #000;
}

button.Profile_Drop_Detail_btn.btn.btn-primary {
    border-radius: 23px;
    font-weight: 700;
    border-radius: 23px;
    font-size: 14px;
}

/*============== Universal _Button_For_Whole_Project ==================*/

.NFT_Tab_Btn_Blk {
    color: var(--5-d-5-d-5-b, #5D5D5B);
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.NFT_Tab_ActiveBtn_Blk {
    color: var(--7-a-52-f-4-primary, #81C14B);
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.Wallet_Btn_Blk {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #81C14B;
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.NFT_Profile_Blk {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    /* background: url(<path-to-image>), lightgray 50% / cover no-repeat; */
    background: no-repeat;
    background-repeat: no-repeat;
    border-radius: 50%;
    cursor: pointer;
}

.NFT_Profile_Blk img {
    object-fit: cover;
    border-radius: 50%;

}


/*====================== start_Explore_Blk_Css ========================*/
.ExploreNft_Heading h1 {
    font-size: 42px;
    font-weight: 800;

}

.ExploreNftTab_Section {
    margin-top: 15px;
}

/* .ExploreNft-Tab-block-box {
    padding: 0 20px 20px;
} */

.Tab_Block li {
    cursor: pointer;
    display: inline-block;
    padding: 10px 20px;
    stroke-width: 1px;
    border: 0.5px solid #CFDBD5;
    border-radius: 18px;
    margin: 0 15px 15px 0;
    display: inline-flex;
    align-items: center;
    color: var(--000000-primary, #000);
    font-size: 12px;
    font-weight: 700;
}

.ExploreNft_CardThisGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.ExploreNft_Cardbox_section {
    background: var(--ffffff, #FFF);
    padding: 24px;
    border-radius: 16px;
    border: 1px solid #CFDBD5;
    box-shadow: 0 0 0 rgba(0, 0, 0, .1);
    position: relative;
}

.ExploreNft_Img_smple {
    position: relative;
    border-radius: 14px;
    background-repeat: no-repeat;
    margin-top: 18px;
}

.ExploreNft_Img_smple img {
    object-fit: cover;
    width: 100%;
    height: 254px;
    border-radius: 14px;
}

.ExploreNft_Heading_Blk h3 {
    margin-top: 18px;
    color: var(--000000-primary, #000);
    font-weight: 800;
    font-size: 18px;
}

.ExploreNft_SubHeading_Blk {
    position: relative;
    margin-top: 12px;
    display: flex;
    align-items: center;
}

.ExploreNft_SubHeading_Blk h4 {
    color: var(--000000-primary, #000);
    font-weight: 700;
    font-size: 14px;
}

.ExpoleNft_Bottom_Section {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

button.Explore_Card_Button.btn.btn-primary {
    border-radius: 23px;
    font-size: 14px;
    font-weight: 700;
}

.ExpoleNft_Bottom_Section h4 {
    color: var(--7-a-52-f-4-primary, #81C14B);
    font-weight: 700;
    font-size: 14px;

}

/*====================== end_Explore_Blk_Css ========================*/

/*====================== end_Drop_Blk_Css ==========================*/
.Drop_Cover_MainSection {
    position: relative;
    width: 100%;
    height: 428px;
    border-radius: 16px;
    background: #ECF1F1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Drop_Position_Section {
    position: absolute;
    top: 40px;
    left: 55px;
}

.Drop_Position_Section span {
    font-weight: 100;
    color: var(--5-d-5-d-5-b, #5D5D5B);
    font-size: 42px;
}

.Drop-custom-block {
    margin: 0 0 30px;
}

.DropCard_heading_section h2 {
    color: var(--000000-primary, #000);
    font-weight: 800;
    font-size: 28px;
}

.Drop_Profile_Center_Section {
    position: relative;
    text-align: center;
}

.Drop_Profile_Center_Section h1 {
    color: var(--ffffff, #FFF);
    font-size: 42px;
    font-weight: 800;
    margin-top: 20px;
}

.Drop_Profile_Center_Section h2 {
    color: var(--ffffff, #FFF);
    font-size: 20px;
    font-weight: 800;
    margin-top: 10px;
}










.Drop_Profile_Detail_Page {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: end;
    text-decoration: underline;
    text-overflow: unset;
    color: #000;
    font-size: medium;
    font-weight: 700;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}

.Drop_UserProfile_Detail_Cover_Page {
    display: flex;
    justify-content: center;
    text-decoration: dotted;
    font-family: 'Courier New', Courier, monospace;
    font-size: 12px;
    font-weight: 500;
    color: #81C14B;
    text-decoration: dashed;
    align-items: center;
    display: flexbox;
    align-items: center;
    flex-direction: row;
    flex-direction: column-reverse;
    font-weight: 400;
}

.Drop_UserCover_Detail_Page {
    display: grid;
    grid-template-rows: none;
    align-items: self-start;
    text-decoration: dashed;
    font-size: 12px;
    font-weight: 800;
    text-overflow: unset;
    font-weight: 400;


}

.Drop_Cover_MainSection_Block_Section {
    descent-override: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    text-transform: lowercase;
    text-align: center;
    font-weight: calc(100%-42px);
    font-weight: 400;
    text-shadow: none;
}


/*====================== end_Drop_Blk_Css ==========================*/


.Mp_Form_ImageUploadInputs1>div {
    flex: 1 1 auto;
    max-width: 200px;

}


.MpChooseImage1 {
    border: 1px dashed #D1D0D3;
    border-radius: 20px;
    /* padding: 20px; */
    width: 100px;
    height: 150px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    object-fit: cover;
    object-position: center;
    justify-content: center;
}

.golivesetting-toggle-box12 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.nftfoot {

    background: var(--ebf-0-f-0-soft-on-light, rgba(235, 240, 240, 0.50));
    width: 100%;
    
   
    flex-shrink: 0;
}