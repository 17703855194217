/*start_ Catalogues_card_section */
.CataloguesCard {
  /* width: 274px;
    height: 250px;
    background: rgba(129, 193, 75, 0.1);
    border: 1px solid #81C14B;
    border-radius: 5px;
    justify-content: center; */
  border: 1px solid #ddd;
  border-radius: 4px;
  /* padding: 4px; */
  display: block;
  position: relative;
  height: 250px;
  background: rgba(129, 193, 75, 0.1);
}

.cataloguesBodyCard {
  text-align: center;
  margin-top: 25%;
}

.CataloguesCards {
  box-sizing: border-box;
  position: absolute;
  border-radius: 5px;
  position: absolute;
}

.data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 20px;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
}

.catalogueItem {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}

/* end_ Catalogues_card_section */
/* start_Reviews_Tab */
.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 554px;
  width: 764px;
  height: 50px;
}

.Rowdata {
  justify-content: space-between;
  display: flex;
}

.replydata {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
}

.reviewsData {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
}

.ProfileName {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #4d4d4d;
}

.inputbox {
  box-sizing: border-box;
  background: rgba(246, 246, 246, 0.09);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 713px;
  height: 90px;
}

/* end_Reviews_Tab */

/* start_ReactSlick_css */
.group {
  /* width: 170px;
    height: 160px;
    left: 170px;
    top: 0px;
    border-radius: 5px; */
}

.ReviewsHeading h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
}

.ReviewImgSection img {
  width: 369px;
  height: 84px;
  border-radius: 12px;
}

.ReviewPost {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
}

.ReviewTitle h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: #4d4d4d;
}

.spaninfo {
  font-size: small;
  font-weight: 400;
  font-style: normal;
  color: #000000;
  font-size: 12px;
}

/*end_ ReactSlick_css */
/* start_CreateBiz#_css */
.CardTextHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #4d4d4d;
}

/* end_CreateBiz#_css */
/* start_BizPageSetting_css */
.BizPageSettingTab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(129, 193, 75, 0.2);
  height: 50px;
  align-items: center;
  padding: 20px 10px;
}

.nave-items-bizPage {
  align-items: center;
  /* background: rgba(129, 193, 75, 0.2); */
  /* border-radius: 5px !important; */
  color: #4d4d4d !important;
  font-weight: 300;
  /* padding: 10px 5px; */
}

/* .nave-items-bizPage:hover {
  align-items: center;
 
  color: #FFFFFF !important;


} */

.BizPageSettingTab :hover {
  background: rgba(129, 193, 75, 0.2);
}

.nave-items-bizPage span {
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  color: #4d4d4d;
}

.custom-card-head,
.biz-carddetail-blk h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 18px;
}

.PinPage {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.PinPage p {
  font-weight: 500;
  font-size: 16px;
}

.PinPage h5 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

.PinPage small {
  color: #808080;
  font-weight: 400;
  font-size: 16px;
}

.DelBizData h5 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

.DelBizData small {
  color: #808080;
  font-weight: 400;
  font-size: 16px;
}

.DelBizData button {
  margin-top: 10px;
}

.DelBizData button p {
  color: #ff6b00;
}

.biz-main-Setting-blk {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.biz-PeopleFollow-blk {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.bizPage_pageRole-blk:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.biz-PeopleFollow-blk:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.biz-PeopleFollow-blk-blk:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.biz-PeopleFollow-blk h4 {
  font-weight: 600;
  font-size: 16px;
  color: #4d4d4d;
  margin: 0 0 8px;
}

.biz-PeopleFollow-blk p {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.biz-BlockedFollow-blk p {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.biz-PeopleFollow-blk h5 {
  font-weight: 500;
  font-size: 14px;
  color: #4d4d4d;
  margin: 0 0 8px;
}

.biz-main-Setting-blk h4 {
  font-weight: 500;
  font-size: 18px;
  color: #4d4d4d;
  margin: 0 0 10px;
}

.biz-main-Setting-blk p {
  font-weight: 400;
  font-size: 15px;
  color: #4d4d4d;
  line-height: 1.6;
}

.Biz_Notification_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Biz_Notification_section h5 {
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
}

li.Biz_setting {
  background: rgba(129, 193, 75, 0.2);
  cursor: pointer;
  border-right: 5px solid #81c14b;
}

li.left_setting:hover {
  background: rgba(129, 193, 75, 0.2);
  cursor: pointer;
  border-right: 5px solid #81c14b;
}

.biz_Filter_btn {
  cursor: pointer;
  padding: 10px 25px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Biz_business_stng.w-50.show {
  transform: translate(537px, 66px) !important;
  padding: 0px !important;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Biz_business_stng.w-25.show {
  transform: translate(661px, 67px) !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

/* end_BizPageSetting_css */

/* ----------------------start_About_biz_Page------------------------ */
.Biz_Location_btn {
  position: absolute;
  top: 8px;
  right: 10px;
  background: #ffffff;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
}

.bizabout-location {
  position: relative;
  object-fit: cover;
}

.BizAbout_bottom_section {
  display: flex;
  align-items: start;
  margin-top: 10px;
}

.Biz_Icon {
  padding: 7px;
  background: rgba(126, 178, 98, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BizAbout_Icon {
  padding: 7px;
  background: rgba(126, 178, 98, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.BizAbout_bottom_section .BizAbout_Detail {
  width: calc(100% - 45px);
}

.BizAbout_bottom_section .BizAbout_Detail h5 {
  color: #4d4d4d;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BizAbout_bottom_section .BizAbout_Detail h6 {
  color: #4d4d4d;
  /* text-transform: capitalize; */
  line-height: 1.4;
  font-size: 16px;
  font-weight: 500;
}

.BizAbout_blk_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  margin-top: 10px;
}

.BizAbout_edit_Icon {
  background: #f6f6f6;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.BizAbout_blk_section .BizDetails {
  width: calc(100% - 45px);
}

.BizAbout_blk_section .BizDetails h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  color: #4d4d4d;
}

.BizAbout_blk_section .BizDetails h6 {
  color: #4d4d4d;
  /* text-transform: capitalize; */
  line-height: 1.4;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 500;
}

.bizAbout_cont_section ul li {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.bizAbout_cont_section ul li iframe {
  width: 100%;
  height: 150px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}

.bizAbout_Map_Modal iframe {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  margin-top: 10px;
}

a.BizLink {
  color: #0066ff;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
}

a.OverView_BizLink {
  color: #0066ff;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.BizAbout_Modal_section {
  display: flex;
  justify-content: space-between;
  background: #f6f6f6;
  border-radius: 10px 10px 0px 0px;
}

.BizAbout_Heading {
  border-bottom: 1px solid #f6f6f6;
  border-color: rgba(0, 0, 0, 0.08);
  width: 100%;
}

.BizAbout_Modal_section h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.BizAbout_Modal_section p {
  color: #81c14b;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  line-height: 24px;
}

.BizAbout_input_box {
  position: relative;
}

.BizAbout_inputForm {
  padding-right: 20%;
}

.AboutBiz_section p {
  color: #81c14b;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.BizAbout_Map_heading {
  background: #f6f6f6;
  display: flex;
  justify-content: center;
}

.BizAbout_Map_heading h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #4d4d4d;
}

.BizAbout_Map_section {
  display: flex;
  justify-content: space-between;
}

.BizAbout_Map_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.BizAbout_Map_section p {
  color: #ff5b58;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.BizAbout_Map_subSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BizAbout_Map_subSection h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #4d4d4d;
}

.BizAbout_Map_subSection span {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #81c14b;
  cursor: pointer;
}

.RemoveBiz_section {
  text-align: center;
}

.RemoveBiz_section h5 {
  color: #808080;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.RemoveBiz_section h6 {
  color: #808080;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.Biz_Catalogue_Section {
  background: rgba(129, 193, 75, 0.1);
  border: 1px solid #81c14b;
  border-radius: 5px;
  height: 274px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Biz_Catalogue_Section h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.Biz_Catalogue_Section h4 {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.Biz_catalogue_media_section {
  border-radius: 4px;
  display: block;
  position: relative;
}

.Biz_catalogue_Allmedia_section {
  display: block;
  position: relative;
  border: 5px solid #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
}

.Biz_catalogue_img {
  width: 100%;
  height: 274px;
  object-fit: cover;
  border-radius: 5px;
}

.Catalogue_Top_section {
  position: absolute;
  right: 0px;
  top: 8px;
  cursor: pointer;
}

.Catalogue_LeftTop_section {
  position: absolute;
  left: 8px;
  top: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Catalogue_LeftTop_section span {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}

.Catalogue_bottom_section {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px) !important;
  width: 100%;
  /* padding: 20px; */
  height: 77px;
  left: 0;
}

.Catalogue_bottom_section span {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

element.style {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(0px, 27px);
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Biz_Drop_Option.show {
  transform: translate(-167px, 17px) !important;
  padding: 0px;
}

.Catalogues_heading_section {
  display: flex;
  justify-content: space-between;
}

.Catalogues_heading_section h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #4d4d4d;
}

.Catalogue_buttom_Count {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 41.15%,
    rgba(0, 0, 0, 0.5) 100%
  );
  padding: 17px 10px 10px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.Catalogue_buttom_Count span {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

.Biz_Icon_btn {
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
}

.Biz_modal_heading {
  display: flex;
  justify-content: center;
}

.Biz_modal_heading h3 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.biz_label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
}

/* ----------------------end_About_biz_Page------------------------ */
/*-----------------------start_Biz_Review_page---------------------*/

.BizReview_section_box p {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
}

.BizReview_section_Subbox {
  display: flex;
  justify-content: space-between;
}

.BizReview_section_Subbox p {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
}

.BizReview_Detail h3 {
  color: #4d4d4d;
  font-variant-numeric: lining-nums tabular-nums;

  /* /H3 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* @media (max-width: 540px) {
  .BizReview_Detail h3 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 14px;
  }
} */

.BizReview_Detail_MoreQuestionSection {
  color: #81c14b;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

/* .BizReview_Detail b {
  font-weight: 700;
  font-size: 16px;
  color: #4D4D4D;
} */

.BizReview_Detail_Question b {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 18px */
}

.BizReview_Detail_Question h4 {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.BizReview_Center_Section {
  margin-left: 42px;
}

/* .BizReview_Center_Section p {
  color: #222222;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
} */

.BizReview_Center_Section span {
  /* color: #81C14B; */
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  cursor: pointer;
}

.Reply_Biz_img {
  display: block;
  position: relative;
}

.Biz_ReplyImg_section {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.Biz_Progress_Bar {
  background: #efefef;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  height: 18px;
}

a.BizReview_Link {
  color: #0066ff;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
}

a.BizReviewTab_Link {
  color: #0066ff;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  cursor: pointer;
}

/*-----------------------end_Biz_Review_page---------------------*/

/*-----------------------start_Biz_OverView_page---------------------*/
.Overview_Biz_img {
  display: block;
  position: relative;
}

.Biz_OverviewImg_section {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 5px;
}

.OverView_Photo_block {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
}

.Lates_reviews_section {
  background: rgba(0, 102, 255, 0.1);
  border-radius: 5px;
  padding: 10px;
}

.Review_section p {
  color: #000000;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
}

.Review_img_box {
  object-fit: cover;
  border-radius: 12px;
  height: 80px;
  width: 80px;
}

/*-----------------------end_Biz_OverView_page---------------------*/
/*---------------------- start_Biz_Creation_page ------------------*/
.Biz_suggested_section {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
  border-radius: 5px;
  padding: 20px;
}

.BizCreation_card_head {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.BizCreation_card_head h4 small {
  padding-left: 5px;
  color: #808080;
}

.card-headInfo {
  display: flex;
  align-items: flex-start;
}

.image_section {
  border-radius: 5px;
  width: 35px;
  height: 35px;
  background: rgba(126, 178, 98, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.FormGroupInfo {
  display: flex;
  justify-content: space-between;
}

.FormGroupInfo h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.FormGroupInfo p {
  color: #81c14b;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

/*---------------------- sta_Biz_Creation_page ------------------*/

.Member_Tab_heading_section {
  /* border-bottom: solid 1px #F0F0F0; */
  padding: 10px;
}

a.Member_link.nav-link.active {
  color: #81c14b;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  border-bottom: solid 1px #81c14b;
}

a.Member_link.nav-link {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.5);
}

.Biz-members-block {
  display: block;
}

.Biz-members-block {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 15px;
  margin: 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Biz-members-block img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 50%;
  margin-right: 10px;
}

.BizMember_bottom_count {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.BizMember_bottom_count span {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.Ask_Review_heading {
  background: #f6f6f6;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
}

.Ask_Review_heading h2 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.css-zun73v.Mui-checked,
.css-zun73v.MuiCheckbox-indeterminate {
  color: #81c14b !important;
}

.BizDelete_Heading {
  display: flex;
  justify-content: center;
  background: #f6f6f6;
}

.BizDelete_Heading h2 {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.BizUnblock_Heading {
  background: #f6f6f6;
  /* background: #fd7e14; */
  display: flex;
  justify-content: center;
}

.BizUnblock_Heading h2 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.BizSetting_Unblock_Heading h2 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.BizSetting_Unblock_Heading p {
  color: #999999;
  font-weight: 500;
  font-size: 14px;
}

.Grpup_resend {
  text-decoration: underline;
  color: #2f80ed;
  cursor: pointer;
}

.biz-Follow-blk h4 {
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
  margin: 0 0 8px;
}
/* .profile-menu ul li a:hover svg{
  stroke: #81C14B;
} */

/*============= start_Owned_Biz_Page_css =======================*/
.BizPage_Card_section {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.2);
  position: relative;
  text-align: center;
  overflow: hidden;
}

.BizPage_Card_section1 {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.2);
  position: relative;
  text-align: center;
  overflow: hidden;
}

.BizPage_Card_section
  .BizPage_option_menu
  .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal {
  transform: translate(-198px, 24px) !important;
  width: 233px;
}

.BizPage_Card_section1
  .BizPage_option_menu
  .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal {
  transform: translate(-198px, 24px) !important;
  width: 233px;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.CataLogues_option.show {
  transform: translate(-207px, 23px) !important;
  padding: 0px;
  width: 213px;
}

.drop-menu-gal ul li a.Biz-OPTION_menu {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
}

.BizPage_option_menu {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  cursor: pointer;
}

.BizPage_Card_section .group-img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.BizPage_Card_section1 .group-img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.BizPage-buttons-blk {
  display: flex;
  align-items: center;
  justify-content: center;
}

.BizBTN_section {
  display: block;
  /* background: #f6f6f6; */
  border: 1px solid #c4c4c4;
  /* color: #808080; */
  font-weight: 500;
  font-size: 14px;
  width: 50%;
  border-radius: 5px;
  padding: 8px 5px;
  /* padding: 10px 20px; */
  margin: 5px;
}

.BizBtn_Fill_section {
  display: block;

  font-weight: 500;
  font-size: 14px;
  width: 50%;
  border-radius: 5px;
  padding: 6px 12px;
  /* padding: 10px 20px; */
  margin: 5px;
}

/*============= end_Owned_Biz_Page_css =======================*/

.BizCreation_CanCel_modal {
  background: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
}

/* .BizCreation_CanCel_modal h3 {
  
  color: #4d4d4d;
  color: #4D4D4D;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
width: 136px;
height: 30px;
position: relative;
left: 158px;

} */

.BizCreation_Content_section p {
  color: #808080;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.BizCreation_Btn_section {
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  width: 34%;
  color: #000000;
  position: relative;
  right: -27px;
}

/* { in create bizpage when tou go in left side form and go on additional info and go and setup open model mdedia query
 } */

@media (max-width: 540px) {
  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 30%;
  }

  /* .BizCreation_CanCel_modal h3 {
  
    color: #4d4d4d;
    color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 166px;
  
  } */
}

/* @media (max-width: 440px) {

  .BizCreation_CanCel_modal h3 {
  
    color: #4d4d4d;
    color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 105px;
  
  }
} */
/* @media (max-width: 400px) {

  .BizCreation_CanCel_modal h3 {
  
    color: #4d4d4d;
    color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 85px;
  
  }
} */
/* @media (max-width: 350px){
  .sugeested{
    width: 102%;
  }
} */
/* @media (max-width: 350px) {

  .BizCreation_CanCel_modal h3 {
  
    color: #4d4d4d;
    color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 85px;
  
  }
} */
@media (max-width: 412px) {
  .sucessmodal {
    color: #4d4d4d;
    font-family: Poppins;
    font-size: 23px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .foll1 {
    position: relative;
    right: 1% !important;
  }

  .sugeested {
    position: relative;
    right: 1% !important;
  }
}

/* 
@media (min-width: 540px) {

  .BizCreation_CanCel_modal h3 {
  
    color: #4d4d4d;
    color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 169px;
  
  }
} */
/* {end here} */
.BizCreation_Btn_Fillsection {
  background: #acdad6;
  border: 1px solid #acdad6;
  position: relative;
  left: -33px;
  width: 110px;
  padding-left: 8px;
}

.Biz_Creation_heading_section {
  background: #ffffff;
  margin: 0px 0 30px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
  border-radius: 5px;
}

.Biz_Creation_heading_section h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 20px;
}

.Biz_Creation_Rightsection {
  padding: 12px;
}

.Biz_Creation_Rightsection h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
}

.BizInput_heading_section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.BizInput_heading_section b {
  font-weight: 600;
  font-size: 16px;
  color: #4d4d4d;
  cursor: pointer;
}

.Biz_form_input_field {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

.Biz_form_input_field1 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

.Biz_form_tym {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* margin-left: 10px; */
}

.Biz_Form_more_location {
  cursor: pointer;
  color: #c4c4c4;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.BizForm_action_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BizForm_action_section b {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
}

.BizForm_action_section p {
  cursor: pointer;
  color: #81c14b;
  font-weight: 500;
  font-size: 14px;
}

.BizForm_toggle_section {
  cursor: pointer;
  color: #81c14b;
  font-weight: 500;
  font-size: 14px;
}

.Biz_Creation_Main_blk {
  padding-bottom: 15px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  margin-bottom: 19px;
  margin-top: 19px;
}

.Biz_Creation_Main_blk:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.Biz_Creation_setUp_section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.Biz_Creation_setUp_section h5 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 15px;
  margin: 0 0 8px;
}

.Biz_Creation_setUp_section small {
  font-weight: 400;
  font-size: 14px;
  color: #808080;
}

.Biz_toggle_action_switch {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.Biz_Creation_heading_Rightsection {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.Biz_Creation_heading_Rightsection h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.Biz_Creation_heading_Rightsection p {
  color: #4d4d4d !important;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
}

.Biz_Creation_Summery_section h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  color: #4d4d4d;
}

.Biz_Creation_Summery_section p {
  color: #b5b5b5;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.bizCreation-user-pro-blk {
  text-align: center;
  margin-top: -90px;
}

.bizCreation-user-pro-blk img {
  border: 3px solid #ffffff;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 50%;
}

.bizCreation-user-pro-blk h5 {
  font-size: 16px;
  color: #262626;
  margin: 15px 0 0;
  font-weight: 600;
}

.bizCreation-user-pro-blk p {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0 0;
  text-decoration: underline;
}

.bizCreation-user-pro-blk p svg {
  margin-right: 3px;
}

button.Creation_preview_btn.btn.btn-primary {
  background: #81c14b !important;
  color: #fff !important;
  border-radius: 5px !important;
  border: 1px solid #81c14b;
}

button.Creation_preview_Cancelbtn {
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}

.Biz_Rating_SECTION {
  background: #f6f6f6;
  display: flex;
  justify-content: center;
}

.Biz_Assign_section_heder {
  background: #f6f6f6;
}

.Biz_Assign_section_heder h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.Biz_Rating_SECTION h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.ASK_Question_heading h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.Biz_Review_detail_section {
  display: flex;
  align-items: flex-start;
}
.Biz_Review_detail_section1 {
  display: flex;
  align-items: flex-start;
}

.Biz_Review_detail_section h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
}
.Biz_Review_detail_section1 h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
}

.BizPost_Offering_section {
  display: flex;
  align-items: center;
}

.BizPost_Offering_section h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  text-transform: capitalize;
}

.Biz_Review_detail_section p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  color: #808080;
  align-items: center;
}
.Biz_Review_detail_section1 p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* display: flex; */
  color: #808080;
  align-items: center;
}

.Biz_Review_detail_section b {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
}

.Biz_Rating_sab_section h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.Biz_Post_sOfferingLabel_section h3 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.Biz_Messagesetting_section h3 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.Biz_Rating_status h6 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
}

.about-profile .card_Biz_Review_title {
  padding: 15px;
}

.card_Biz_Review_title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
}

.card_Biz_Review_title h3,
.card_Biz_Review_title .h3 {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.BizPage_leftSide_Heading {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.BizPage_leftSide_Heading h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
}

.Biz_Ask_Question_Limit {
  cursor: pointer;
  color: #808080;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

/* ========================= start_Biz_Review_tab_section =============================*/
.Biz_ReviewTab_heading_section h3 {
  color: var(--Gray-1, #333);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Biz_ReviewPage_content_section p {
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
}

.CataLog_Upload_img_section {
  position: relative;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.CataLog_Upload_img_section img {
  object-fit: cover;
  border-radius: 5px;
  width: 100%;
  height: 250px;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  color: #c4c4c4 !important;
}

.CataLog_Upload_Icon_section {
  position: absolute;
  top: 8px;
  right: 16px;
  cursor: pointer;
}

.WataDo_limit_section {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.customAbout-card-head {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.customAbout-card-head h4 small {
  padding-left: 5px;
  color: #808080;
}

.customAbout-card-head h3 {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.bizAbout-Main-blk {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.bizAbout-Main-blk h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
  margin: 0 0 10px;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
}

.bizAbout-Main-blk p {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
}

.BizAddition_heading_section h3 {
  color: #4d4d4d;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.bizAdditional-Main-blk {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.bizAdditional-Main-blk h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
  margin: 0 0 10px;
}

.bizAdditional-Main-blk p {
  font-weight: 500;
  font-size: 14px;
  color: #808080;
}

.service-Aboutlist-blk span {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  display: inline-block;
  padding: 8px;
  margin: 0 10px 10px 0;
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
}

.service-Amenitieslist-blk span {
  background: #f6f6f6;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: inline-block;
  padding: 8px;
  margin: 0 10px 10px 0;
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.gallery-page-section > .Aboutcard-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e6f3db;
  padding: 20px;
}

.Aboutcard-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
}

.thedetector {
  color: #262626;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Aboutcard-title h4,
.Aboutcard-title .h4 {
  color: #4d4d4d;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Aboutcard-title {
  margin-bottom: 0.5rem;
}

.Biz_Aditional_SECTION {
  background: #f6f6f6;
  display: flex;
  justify-content: center;
}

.Biz_Aditional_SECTION h3 {
  color: #4d4d4d;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.Heading_Points_section {
  color: #81c14b;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.AboutBiz_Email_section h4 {
  color: #333333;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.AboutBiz_Email_section h5 {
  color: #333333;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.AboutBiz_Email_section span {
  font-weight: 400;
  font-size: 18px;
  color: #c4c4c4;
  line-height: 27px;
  text-decoration: underline;
  cursor: pointer;
}

.AboutBiz_EmailOTP_section h3 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.BizAbout_location_addmore {
  color: #81c14b !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  cursor: pointer !important;
}

/* ========================= end_Biz_Review_tab_section =============================*/

.biz-main-SettingTab-blk {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.biz-main-SettingTab-blk:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.biz-main-SettingTab-blk h4 {
  font-weight: 600;
  font-size: 16px;
  color: #4d4d4d;
  margin: 0 0 10px;
}

.biz-main-SettingTab-blk p {
  font-weight: 400;
  font-size: 14px;
  color: #808080;
  line-height: 1.6;
}

.BizSetting_msg_toggle {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
}

.BizGeneral_settingDetel_modal h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
}

.BizGeneral_settingDetel_OtpSection span {
  font-weight: 500;
  font-size: 16px;
  color: #c4c4c4;
  line-height: 25px;
}

.BizSeeting_BlockBtn {
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.2);
  color: rgba(255, 0, 0, 0.6);
}

.BizSetting_DropDown_item.dropdown-menu.show {
  top: 4px !important;
  width: 220px !important;
  position: absolute !important;
  left: -129px !important;
  background: #ffffff !important;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%) !important;
  border-radius: 7px !important;
}

.Bizmember_section_blk {
  color: #c4c4c4;
}

.Bizmember_section_blk span {
  margin: 3px 4px;
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  color: #4d4d4d;
}

.Bizfriend-section h6 {
  font-size: calc(11px + (13 - 11) * ((100vw - 320px) / (1920 - 320)));
  color: #4d4d4d;
  margin-left: 6px;
  font-weight: 500;
  margin-top: 5px;
}

.bizpage-block .people-likes.Bizfriend-section {
  padding: 0 15px 15px;
}

.group-content .people-likes.Bizfriend-section {
  justify-content: center;
  margin: 5px 0;
}

.people-likes.Bizfriend-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.TopBanner_profile_Picture img {
  height: 480px;
  width: 100%;
}

.TopBanner_profile_CoverPicture img {
  height: 555px;
  width: 100%;
}

.Feather_Icon_Profile {
  position: absolute;
  top: -27px;
  right: -2px;
  cursor: pointer;
}

.BizCover-top-block {
  min-height: 350px;
}

.BizCover-top-block {
  min-height: 350px;
}

@media (max-width: 575px) {
  .BizCover-top-block {
    min-height: 170px;
    justify-content: center;
  }

  .heading-title-blk h3 {
    text-align: center;
    font-size: 20px;
    margin: 0 0 5px;
  }

  .gd-content-area-top-blk {
    text-align: center;
  }

  .group-content-area-top-blk {
    text-align: center;
  }

  .gd-content-area-top-blk .people-likes.matual-friend-sec {
    justify-content: center;
  }

  .group-content-area-top-blk .people-likes.matual-friend-sec {
    justify-content: center;
  }

  .biz-details-top .BizCover-top-block {
    justify-content: flex-end;
  }
}

.Bizgd-top-btns {
  position: absolute;
  top: 325px;
  right: 9px;
  z-index: 2;
}

@media (max-width: 991.98px) {
  .biz-details-top .Bizgd-top-btns .grey-btn {
    font-size: 0;
    padding: 8px;
  }

  .biz-details-top .Bizgd-top-btns .grey-btn svg {
    margin: 0px;
  }

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    position: relative;
    left: -33px;
    width: 110px;
    padding-left: 8px;
  }

  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 34%;
    color: #000000;
    position: relative;
    right: -27px;
  }
}

.Biz_followlist-blk {
  display: flex;
  justify-content: flex-end;
}

.Biz_followlist-blk li {
  margin-left: 30px;
}

.Biz_followlist-blk li {
  margin-left: 30px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
}

.Biz_followlist-blk li span {
  display: block;
  line-height: 1;
  font-size: 12px;
  font-weight: 500;
  color: #808080;
}

.head-title-biz .Biz_followlist-blk li {
  margin-left: 7%;
}

.head-title-biz .Biz_followlist-blk li {
  margin: 0 2%;
}

@media (max-width: 991.98px) {
  .head-title-biz .Biz_followlist-blk li {
    margin: 0 2%;
  }

  .head-title-biz .Biz_followlist-blk {
    justify-content: center;
  }

  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 21%;
    color: #000000;
  }
}

.Biz_top_groupmember-blk span {
  margin: 3px 4px !important;
  font-size: 16px !important;
  display: inline-block !important;
  color: #4d4d4d !important;
  font-weight: 500 !important;
}

.BizAbout_profileEdit_Icon {
  position: absolute;
  top: 76px;
  right: 127px;
}

.biz-user-pro-blk {
  position: relative;
}

.CreatePost_Offer_section {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.CreatePost_Offer_section img {
  object-fit: cover;
  height: 250px;
  width: 100%;
  border-radius: 5px;
}

.Upload_Creation_img_section {
  position: relative;
}

.UploadImg_Position_section {
  position: absolute;
  top: 8px;
  right: 16px;
  cursor: pointer;
}

.BizPost_sOffering_OptionalSection h3 {
  color: #4d4d4d;
  font-weight: 700;
  font-size: 14px;
}

.Create_offering_post_btn {
  padding: 10px 15px;
}

.Upload_img_section h3 {
  color: #4d4d4d !important;
  font-weight: 700 !important;
  font-size: 15px !important;
}

.Upload_img_section h4 {
  color: #808080;
  font-weight: 500;
  font-size: 15px;
}

.BizReview_Profile img {
  object-fit: cover;
  border: 1px solid #ffffff;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
}

.bizPage_pageRole-blk {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.bizPage_pageRole-blk:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.bizPage_pageRole-blk h4 {
  font-weight: 600;
  font-size: 16px;
  color: #4d4d4d;
  margin: 0 0 4px;
}

.bizPage_pageRole-blk h5 {
  color: #81c14b;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  margin: 0 0 4px;
}

.bizPage_pageRole-blk p {
  color: #4f4f4f;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
}

.bizPage_pageRole-Modalblk {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.bizPage_pageRole-Modalblk:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.bizPage_pageRole-Modalblk h4 {
  font-weight: 600;
  font-size: 16px;
  color: #4d4d4d;
  margin: 0 0 4px;
}

.bizPage_pageRole-Modalblk p {
  color: #808080;
  font-weight: 500;
  font-size: 12.68px;
  line-height: 19px;
}

.BizReview_Center_SubSection {
  margin-left: 67px;
}

.BizReview_Center_SubSection p {
  color: #222222;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
}

.black12 {
  color: #000000;
}

@media (max-width: 540px) {
  .BizCreationbtn-solidUpload {
    border-radius: 5px;
    border: 1.00227px solid #c4c4c4;
    color: #ffffff;
    -webkit-transition: all 0.5s ease;
    /* transition: all 0.5s ease; */
    margin-left: 16px;
  }
}

.BizCreationbtn-solidUpload {
  border-radius: 5px;
  border: 1.00227px solid #c4c4c4;
  color: #ffffff;
  -webkit-transition: all 0.5s ease;
  /* transition: all 0.5s ease; */
}

img.AvatarGroupImg.bg-img {
  background: #ffffff;
  height: 26px;
  width: 26px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
}

.BizDetailTymList {
  display: grid;
  grid-template-areas: "Day time ";
}

.BizTymListDay {
  grid-area: "Day";
  /* text-transform: capitalize; */
  line-height: 1.4;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 500;
}

.TymBiz {
  grid-area: "time ";
  color: red;
  /* text-transform: capitalize; */
  line-height: 1.4;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 500;
}

.BizCatalogue_Main_Listblk {
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}

.BizCatalogue_Internal_blk {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.bizCat_Img_section img {
  height: 125px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

@media (max-width: 540px) {
  .bizCat_Img_section img {
    height: 125px;
    width: calc(100% - 45px);
    object-fit: cover;
    border-radius: 5px;
  }
}

@media (max-width: 912px) {
  .CardTextHeading {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #4d4d4d;
  }
}

@media (min-width: 280px) and (max-width: 571px) {
  .BizPage_Card_section {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 10%);
    position: relative;
    text-align: center;
    overflow: hidden;
    margin-right: 11px;
    margin-left: 11px;
  }
}

@media (min-width: 280px) and (max-width: 571px) {
  .BizPage_Card_section1 {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 10%);
    position: relative;
    text-align: center;
    overflow: hidden;
    margin-right: 22px;
    margin-left: 12px;
  }
}
@media (min-width: 280px) and (max-width: 575px) {
  .BizPage_Card_section112 {
    position: relative;

    margin-right: 13px;
    margin-left: 12px;
  }
}

@media (min-width: 280px) and (max-width: 420px) {
  .Biz-members-block {
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    padding: 15px;
    margin: 0 0 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .profile-menu .right-menu {
    margin-left: auto;
    position: relative;
  }

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    width: 105px;
    padding-left: 10px;
    position: relative;
    left: -62px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .ownedd {
    position: relative;
    right: 7%;
  }
  .foll1 {
    position: relative;
    right: 7%;
  }
  .sugeested {
    position: relative;
    right: 7% !important;
  }
}

@media (max-width: 1080px) {
  .profile-menu .right-menu {
    margin-left: auto;
    position: relative;
  }

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    width: 105px;
    padding-left: 6px;
    position: relative;
    left: -8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 100px;
    color: #000000;
    position: relative;
    right: -4px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

@media (min-width: 1080px) {
  .BizCreation_Btn_Fillsection {
    width: 110px;
    padding-left: 9px;
    position: relative;
    left: -3px;
    border-radius: 5px;
    background: #acdad6;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 110px;

    color: #000000;
    position: relative;
    right: -2px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    background: #f6f6f6;
  }

  .pinsvgs {
    position: absolute !important;
  }
}

.profile-menu .iw-14 :hover circle {
  color: #000000;
}

@media (max-width: 540px) {
  .Biz_form_input_field {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    width: 97%;
    margin-left: 7px;
  }

  .countryfield {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    height: calc(2.25rem + 2px);
    display: block;
    width: 97%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
  }

  .BizInput_heading_section b {
    font-weight: 600;
    font-size: 16px;
    color: #4d4d4d;
    cursor: pointer;
    margin-right: -1px;
    margin-left: 9px;
  }

  .h3,
  .h3 {
    font-family: var(--bs-poppins-font);
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin: 0;
    margin-left: 8px;
  }

  .sugess {
    left: 3px;
  }
  .foll1 {
    left: 3px;
  }
}

@media (max-width: 576px) {
  .profile-cover .btn-group {
    top: 15px;
    right: 15px;
    position: relative;
    position: absolute;
    margin-left: 24px;
  }

  .addadress {
    width: 94%;
    margin-left: 7px;
  }

  .BizForm_action_section p {
    cursor: pointer;
    color: #81c14b;
    font-weight: 500;
    font-size: 14px;
    margin-right: 7px;
  }

  .input-hints {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    color: #c4c4c4;
    text-align: right;
    margin: 8px 11px 0px;
  }

  .h3,
  .h3 {
    font-family: var(--bs-poppins-font);
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin: 0;
    margin-left: 8px;
  }

  .Biz_Form_more_location {
    cursor: pointer;
    color: #c4c4c4;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    margin-right: 18px;
  }

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    width: 105px;
    padding-left: 10px;
    position: relative;
    left: -40px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

@media (max-width: 340px) {
  .Biz_form_input_field {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    width: 94%;
    margin-left: 7px;
  }

  .countryfield {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    height: calc(2.25rem + 2px);
    display: block;
    width: 94%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
  }

  /* .BizCreation_CanCel_modal h3 {
  
    color: #4d4d4d;
    color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 65px;
  
  } */
  .BizInput_heading_section b {
    font-weight: 600;
    font-size: 16px;
    color: #4d4d4d;
    cursor: pointer;
    margin-right: -1px;
    margin-left: 9px;
  }

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    width: 105px;
    padding-left: 10px;
    position: relative;
    left: -45px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 100px;
    color: #000000;
    position: relative;
    right: -2px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

/* @media (max-width: 340px) {

      
  
  .BizCreation_CanCel_modal h3 {
  
    color: #4d4d4d;
    color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 65px;
  
  }
} */
@media (max-width: 320px) {
  .ownedd {
    position: relative;
    /* right: 13px !important; */
    left: -10px !important;
  }

  .ownpage {
    margin-left: -4px;
    margin-right: -1px;
  }

  .navbiz {
    left: 5px;
  }
  .sugeested {
    position: relative;
    left: -15px !important;
  }

  .widt {
    width: 125px;
    margin-left: -7px;
    margin-right: -20px !important;
  }
}

@media (max-width: 480px) {
  .profile-cover .btn-group {
    top: 15px;
    right: 15px;
    position: relative;
    position: absolute;
    margin-left: 24px;
  }

  .Biz_form_input_field {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    width: 94%;
    margin-left: 7px;
  }

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    width: 105px;
    padding-left: 10px;
    position: relative;
    left: -24px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 100px;
    color: #000000;
    position: relative;
    right: -2px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .sugeested {
    left: 2px;
  }
  .foll1 {
    left: 2px;
  }
}

@media (max-width: 280px) {
  .profile-cover .btn-group {
    top: 15px;
    right: 15px;
    position: relative;
    position: absolute;
    margin-left: 24px;
  }

  .Biz_form_input_field {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    width: 94%;
    margin-left: 7px;
  }

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    width: 105px;
    padding-left: 10px;
    position: relative;
    left: -7px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .BizCreation_Btn_section {
    background: #f6f6f6;
    border: 1px solid #c4c4c4;
    width: 100px;
    color: #000000;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .sentex {
    padding-left: 10px !important;
  }

  .owned {
    position: relative;
    right: 5px;
  }

  .allbiz {
    position: relative;
    right: 7px;
  }
}

.slideronoff {
  position: relative;
  top: 22px;
  right: -5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 27px;
  top: 3px;
}

.pinsvg1 {
  position: relative;
}

.droup2 {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal {
  transform: translate(74px, 41px) !important;
  padding: 0px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  opacity: 0.9;
}

@media (max-width: 945px) {
  .bizset {
    position: relative;
    margin: auto;
    top: -26px;
  }
}
@media (max-width: 375px) {
  .ownedd {
    left: 2px;
  }
}

@media (max-width: 317px) {
  .creationimg {
    position: relative;
    left: -9px;
  }
}

@media (max-width: 414px) {
  .sentex {
    padding-left: 18px;
  }
  .ownedd {
    left: 2px;
  }
}

@media (max-width: 360px) {
  .sugeested {
    position: relative;
    left: -5px;
  }

  .sentex {
    padding-left: 15px;
  }
  .foll1 {
    position: relative;
    right: 1%;
  }

  /* .BizCreation_CanCel_modal h3 {
    color: #4d4d4d;
    color: #4D4D4D;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 136px;
    height: 30px;
    position: relative;
    left: 89px;
} */

  .BizCreation_Btn_Fillsection {
    background: #acdad6;
    border: 1px solid #acdad6;
    width: 105px;
    padding-left: 10px;
    position: relative;
    left: -14px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

@media (max-width: 393px) {
  .sucessmodal {
    color: #4d4d4d;
    font-family: Poppins;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  /* .BizCreation_CanCel_modal h3 {
  color: #4d4d4d;
  color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 89px ;
} */
}

@media (max-width: 1145px) {
  .createdbiz {
    position: relative;
    top: -24px;
    width: 218px;
  }
}

@media (max-width: 997px) {
  .createdbiz {
    position: relative;
    top: -24px;
    width: 182px;
  }
}
@media (max-width: 820px) {
  .sugeested {
    position: relative;
    right: 5px;
  }
}

@media (max-width: 600px) {
  .sugess {
    /* margin-left: -7px; */
  }

  .foll1 {
    position: relative;
    right: 4%;
  }
  .widt {
    margin-left: -7px;
    position: relative;
    right: 2px;
  }
}

@media (max-width: 1146px) {
  .cretbiz {
    width: 163px;
  }
}

.sugestborder {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.2);
}

.inviteimg {
  position: relative;
  left: 138px;
}

.BizCreation_CanCel_modal button span {
  position: absolute !important;
  top: -2rem;
  color: #ddd;
  display: none;
}

.invitebutton {
  display: block;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
  background: #81c14b;
  width: 120px;
  gap: 10px;
  cursor: pointer;
}

@media (max-width: 831px) {
  .widt {
    width: 145px;
    margin-right: -11px;
  }
}

@media (max-width: 779px) {
  .widt {
    width: 145px;
    margin-right: -14px;
  }
}

@media (max-width: 360px) {
  .widt {
    width: 125px;
  }
}

@media (max-width: 360px) {
  .widt {
    width: 125px;
    margin-left: -7px;
  }
}

@media (max-width: 789px) {
  .foll {
    width: 131px;
    margin-right: -6px;
    margin-left: -6px;
  }
}

@media (min-width: 360px) and (max-width: 540px) {
  .foll {
    width: 107px;
    margin-left: -3px;
    margin-right: -4px;
  }

  .widt {
    width: 125px;
    margin-right: -14px;
  }
}

@media (min-width: 280px) and (max-width: 359px) {
  .foll {
    width: 106px;
    margin-left: -3px;
    margin-right: -4px;
  }

  .foll1 {
    position: relative;
    left: -10px;
  }
}

@media (max-width: 280px) {
  .foll {
    width: 106px;
    margin-left: -7px;
    margin-right: -7px;
  }

  .widt {
    margin-left: -7px;
    margin-right: -17px;
  }

  /* .BizCreation_CanCel_modal h3 {
  color: #4d4d4d;
  color: #4D4D4D;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 136px;
  height: 30px;
  position: relative;
  left: 47px ;
} */
}

.dropshow1 {
}

.Modal_False_blk1 {
  position: absolute;
  top: -33px;
  right: -1px;
  cursor: pointer;
}
.Modal_False_blk12 {
  position: absolute;
  top: -29px;
  right: -1px;
  cursor: pointer;
}

.sucessmodal {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.py-12 {
  padding-top: 0.38rem !important;
  padding-bottom: 0.25rem !important;
}

.slider1:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: #c4c4c4;
  transition: 0.4s;
}

@media (min-width: 540px) {
  .countryfield {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
    height: calc(2.25rem + 2px);
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
  }
}

.xcircle {
  cursor: pointer;
  position: absolute;
  right: 7px;
}

.cardinbiz {
  padding-right: 0px;
  margin-right: -2px;
}

.centered-heading {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.invitetext {
  margin-left: 13px !important;
}

.my-31 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

@media (min-width: 329px) and (max-width: 390px) {
  .sucessmodal {
    color: #4d4d4d;
    font-family: Poppins;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@media (min-width: 280px) and (max-width: 312px) {
  .setupmargain {
    margin-top: 2rem;
  }
}

@media (min-width: 280px) and (max-width: 328px) {
  .sucessmodal {
    color: #4d4d4d;
    font-family: Poppins;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@media (min-width: 280px) and (max-width: 767px) {
  .invitrinput {
    width: 60%;
  }

  .filterinvite {
    bottom: 41px;
  }

  .inviteallsocio {
    position: absolute;
    top: -33px;
  }

  .invitesocio {
    margin-top: -35px;
  }
}

@media (min-width: 280px) and (max-width: 390px) {
  .invitebutton {
    display: block;
    margin: 0 auto;
    text-align: center;
    border-radius: 5px;
    background: #81c14b;
    width: 85px;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
  }

  .modalmargin {
    margin-top: 2rem;
  }
}

.image-container1 {
  position: relative;
  display: inline-block;
}

.BizAbout_profileEdit_Icon1 {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 280px) and (max-width: 576px) {
  .bizhead {
    position: relative;
    left: 13px;
  }

  .seeallbiz {
    position: relative;
    right: 12px;
    top: 4px;
  }
}

.inviteheading {
  width: 139px;
}

@media (min-width: 280px) and (max-width: 360px) {
  .imginvite {
    width: 60px !important;
    height: 60px !important;
    object-fit: cover;
    border: 2px solid #ffffff;

    border-radius: 50%;
    margin-right: 10px;
  }
}

@media (min-width: 280px) and (max-width: 412px) {
  .modalmargin {
    margin-top: 2rem;
  }
}

@media (max-width: 280px) {
  .bizpageheading {
    font-size: 17px !important;
  }
}
@media (max-width: 600px) {
  .ownedd {
    position: relative;
    right: 6px;
  }
}
@media (max-width: 353px) {
  .bizset {
    top: -40px !important;
  }
}
@media (min-width: 280px) and (max-width: 576px) {
  .bizdetailprofile {
    position: relative;
    box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);

    overflow: hidden;
    margin-right: 11px;
    margin-left: 11px;
  }
}

@media (min-width: 355px) and (max-width: 548px) {
  .bizset {
    top: -20px;
  }
}
@media (min-width: 280px) and (max-width: 352px) {
  .bizset {
    top: -63px !important;
  }
}

.viewphoto {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.flex-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
  right: 9px;
  top: -26px;
}

.cretedbizpage {
  text-transform: capitalize;
  color: #647589;
  font-weight: normal;
  font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.svgcreted {
  background: rgba(129, 193, 75, 0.2);
  border-radius: 5px;
  padding: 5px 16px;
}

@media (min-width: 992px) {
  .svgcreted {
    display: none;
  }
}
@media (min-width: 767px) {
  .setnavwindow {
    display: none;
  }
}
/* @media (min-width: 992px) {
  .entmagvifo {
    position: relative;
    top: -15px;
  }
} */

@media (max-width: 768px) {
  .img-fluid1 {
    max-width: 100%;
  }
}

.bizphotodroup {
  width: max-content !important;
}

.catalogbutton {
  display: flex;
  height: max-content;
}

.bizmaptop {
  font-size: 16px;
  display: flex;

  justify-content: center;
  margin: 3px 0 0;
  font-weight: 500;
  color: #81c14b;
  text-decoration: underline;
}

.bizmappara {
  display: flex;
  justify-content: center;
}

.bizenq {
  color: var(--Gray-1, #333);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.lscbizdetail {
  color: #4d4d4d;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.matual-friend-sec h6 {
  font-size: calc(11px + (13 - 11) * ((100vw - 320px) / (1920 - 320)));
  color: #647589;
  margin-left: 6px;
  font-weight: 500;
  margin-top: 0px !important;
}

.padavtar {
  margin: 5px 4px 10px !important;
}

.newcat {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6f3db;
  padding: 20px;
  justify-content: space-between;
}

@media (min-width: 280px) and (max-width: 442px) {
  .newcat {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e6f3db;
    padding: 20px;
    justify-content: space-between;
    flex-direction: column;
  }

  .newcatlog {
    color: #81c14b !important;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: Poppins;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin-left: 1px !important;
    position: relative;
    top: 8px;
  }
}

.newcatlog {
  color: #81c14b !important;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin-left: 1px !important;
}

@media (min-width: 280px) and (max-width: 430px) {
  .repfound {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

@media (min-width: 280px) and (max-width: 580px) {
  .catnone {
    display: none !important;
  }

  .bizpagepicmodal {
    max-width: 96% !important;
  }
  .bizpagepicmodal1 {
    max-width: 96% !important;
  }
}
@media (min-width: 280px) and (max-width: 768px) {
  .Review_section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .ml-311 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 1025px) {
  .Review_section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .ml-311 {
    margin-left: 1rem !important;
  }
}
@media (min-width: 768px) and (max-width: 860px) {
  .repfound {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
@media (min-width: 581px) and (max-width: 1215px) {
  .bizpagepicmodal {
    max-width: 84% !important;
  }
}
@media (min-width: 581px) and (max-width: 1000px) {
  .bizpagepicmodal1 {
    max-width: 60% !important;
  }
}
@media (min-width: 280px) and (max-width: 767px) {
  .seetingnav {
    display: none;
  }
}

.bizpagepicmodal {
  max-width: 40%;
}
.bizpagepicmodal1 {
  max-width: 40%;
}

@media (min-width: 280px) and (max-width: 767px) {
  .hide-setting-options21 {
    display: none !important;
  }
}

.biz-Follow-blk {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

@media (min-width: 280px) and (max-width: 434px) {
  .biz-Follow-blk1 {
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }

  .biz-Follow-blk h4 {
    font-weight: 500;
    font-size: 16px;
    color: #4d4d4d;
    margin: 0 0 6px !important;
  }
  .biz-Follow-blk1 h4 {
    font-weight: 500;
    font-size: 16px;
    color: #4d4d4d;
    margin: 0 0 6px !important;
  }
  .admintesxt {
    position: relative;
    top: -14px;
    left: 68px;
  }

  .blockmar {
    margin: -9px;
  }
  .remmob {
    position: relative;
    top: 7px;
    left: 67px;
  }

  .blockhead {
    display: flex;
    /* align-content: stretch; */
    /* flex-wrap: wrap; */
    flex-direction: column;
    /* justify-content: space-around; */
  }
}

@media (min-width: 768px) {
  .hide-setting-options12 {
    display: none;
  }
}
@media (min-width: 432px) {
  .BizReview_section_box1 {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 435px) {
  .biz-Follow-blk {
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 435px) {
  .biz-Follow-blk1 {
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .blockhead {
    display: flex;
    /* align-content: stretch; */
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: space-around;
  }
}

.BizReview_section_box {
  display: flex;
  justify-content: space-between;
}

.askqu {
  display: flex;
  justify-content: space-between;
}

.checkbox {
  position: relative;
  top: -7px;
}

.modal-scroll-container {
  max-height: 566px; /* Set your desired max height */
  overflow-y: auto;
}

.selected-tab1 {
  background: rgba(129, 193, 75, 0.2);
  cursor: pointer;
  border-right: 5px solid #81c14b;
} /* Change this to your desired color */

@media (min-width: 280px) and (max-width: 650px) {
  .aboutside {
    display: none;
  }
}
@media (min-width: 280px) and (max-width: 767px) {
  .modal-scroll-container {
    max-height: 500px; /* Set your desired max height */
    overflow-y: auto;
  }
}

.sndcolor {
  border-radius: 50px;
  background: #81c14b;
}


/* .sendicon1 {

  position: absolute;
  right: 42px;
  margin-top: 6px;
  cursor: pointer;
} */

.sendiconbiz{
  position: absolute;
  right: 44px;
  margin-top: 6px;
  cursor: pointer;
}

@media (min-width: 280px) and (max-width: 575px) {
  .modalbiz {
    margin-top: 30px;
  }
}
@media (min-width: 280px) and (max-width: 768px) {
  .bizratingrev {
    display: flex;
    align-items: center;
  }
}
@media (min-width: 280px) and (max-width: 420px) {
  .followbut {
    position: relative;
    left: 80px;
  }
}
@media (min-width: 280px) and (max-width: 650px) {
  .qamda {
    display: none;
  }
}
@media (min-width: 280px) and (max-width: 1300px) {
  .bizavtar {
    display: none;
  }
}
@media (min-width: 280px) and (max-width: 576px) {
  .headbiz {
    position: relative;
    left: 9px;
  }
}
@media (min-width: 280px) and (max-width: 340px) {
  .Biz_Review_detail_section1 h4 {
    color: #4d4d4d;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    text-transform: capitalize;
  }
}
@media (min-width: 280px) and (max-width: 372px) {
  .bizeditrat {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
@media (min-width: 280px) and (max-width: 500px) {
  .revimap1 {
    display: none;
  }
}
@media (min-width:280px) and (max-width: 540px) {
  .upload2{
    display: none;
 
  }
}

.bizeditrat {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 651px) {
  .aboutside1 {
    display: none;
  }
}

@media (min-width: 651px) {
  .plus1 {
    display: none;
  }
}
@media (min-width: 826px) {
  .bizratingrev {
    display: flex;
    align-items: center;
  }
}

.invitemodal {
  margin-top: 1.5rem;
  padding-top: 1rem;
}

.profile-menu a.active {
  background: #81c14b;
  color: white;
}

.addpc {
  border-radius: 5px;
  border: 1px solid rgba(129, 193, 75, 0.7);
  background: rgba(129, 193, 75, 0.1);
  padding: 4px;
}



.profile-menu1 {
  /* background-color: #ffffff; */
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.02);
  /* box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.02); */
  padding: 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}


.profile-menu1 a.active {
  background: #81c14b;
  color: white;
}
.setupmargain{
  padding-top: 15px;
}


.Assign_Sub_Section1{
  position: relative;
  margin-left: 16px;
  margin-top: 18px;
  /* margin-right: 23px; */
  padding-right: 26px;
}

.custom-select-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-select {
  width: 100%;
  padding: 8px 30px 8px 10px; /* Adjust padding to make space for the arrow */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff; /* Default background color */
  color: #333; /* Default text color */
  appearance: none; /* Remove default arrow */
  font-size: 16px;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s; /* Smooth transition */
}

.custom-select.selected {
  background-color: #81c14b; /* Green background when an option is selected */
  color: #fff; /* White text color for contrast */
}

.custom-select:focus {
  border-color: #81c14b; /* Border color on focus */
  outline: none; /* Remove default focus outline */
}

.custom-select-container::after {
  content: '▼'; /* Unicode character for arrow */
  font-size: 12px;
  color: #333; /* Default arrow color */
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust position as needed */
  transform: translateY(-50%);
  pointer-events: none; /* Make sure arrow doesn’t block click events */
}

.custom-select.selected + .custom-select-container::after {
  color: #fff; /* Change arrow color when an option is selected */
}


