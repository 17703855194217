/* ================= Market Place header section ============== */

.MrktPlaceSearch {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #F6F6F6;
    border-radius: 5px;
    /* margin-left: 10px; */
    gap: 8px;
    padding: 13px 20px;
}

.MrktPlaceSearch>input {
    border: none;
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    color: "black";
    background-color: transparent;
}

.MrktPlaceSearch>input::placeholder {
    color: rgba(77, 77, 77, 0.5);
    font-size: 12px;
    font-weight: 400;
}

.MrktPlaceHeaderLocation {
    padding: 12px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EAECEC;
    border-radius: 5px;
    gap: 10px;
}

.MrktPlaceHeaderLocation>input {
    border: none;
    height: 100%;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    color: "black";
    background-color: transparent;
}

.MrktCated {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    cursor: pointer;

}

.Mp_Deskto_HeaderTabs {
    display: flex;
}

.MarketPlaceHeaderCate {
    height: 6rem;
    width: 100%;
    display: flex;
    align-items: center;
}

.MrktPlaceCateCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.MrktCated>h3 {
    color: #4D4D4D;
    font-weight: 500;
}

.MrktCated>h6 {
    color: #4D4D4D;
    font-weight: 400;
}

.Mp_LocationSearchInput {
    width: 15rem;
}

.Mp_FormHeader_SideTwo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.Mp_HeaderSearchAndLocation {
    display: flex;
}

.Mp_HeaderSearchAndLocation_Mobile {
    display: none;
}



/* ================= market Place header end ===================== */


/* ====================== left sort By ============================ */


.accordion-button:not(.collapsed) {
    color: revert;
    background-color: #e6f3db;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}

.accordion-button:not(.collapsed) {
    color: revert;
    background-color: #e6f3db;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}


.MrktPlaceRadio {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.MrktPlaceCheckbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.MrktPlaceRadio>input {
    width: 1rem;
    height: 1rem;
}

.MrktPlaceRadio>h5 {
    color: "#808080"
}

.MpUploadHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.MrktClearAllBtn {
    color: #808080;
    cursor: pointer;
    font-weight: 400;
}

/* ================ left sort by ends ============================ */

/* =================left filter ============================= */

.MarketFilterPadding {
    padding: 1rem 1.25rem;
}

.MarketFilterPaddingLeft {
    padding-left: 1.25rem;
}


.Mp_FilterAccordionItem_btn>button.accordion-button {
    padding: 0.25rem 0.25rem;
}

.MpFilterValueInput>input {
    font-weight: 500;
    color: "#4D4D4D";
}

.MpFilterValueInput_first {
    width: 60%;
    border: none;
    border-radius: 0;
}

.MpFilterValueInput_Two {
    width: 40%;
    border: none;
}

.MpFilterValueInput>input::placeholder {
    font-weight: 500;
    color: "#4D4D4D";
}

.MpFilterValueInput_Two>option {
    width: 100%;
    border-bottom: 1px solid #808080;
}

.MpFilterValueInput_Price_First {
    width: 50%;
    border: none;
    border-radius: 0;
}

.MpFilterValueInput_Price_Doller {
    width: 10%;
}

.MpFilterValueInput_Price_two {
    width: 40%;
    border: none;
}




.Mp_FilterRangebtwn_value {
    font-weight: "500";
    font-size: 16px;
    color: #4d4d4d;
}

.Mp_FilterRangebtwn_value::placeholder {
    font-weight: "500";
    color: #4d4d4d;
}


.Mp_CategoryAccordion.accordion-body {
    padding-left: 1.25rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

/* .Mp_Filter_Tab {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

} */

/* .Mp_Filter_Tabs_Top {
    display: grid;
    column-gap: 1.5rem;
    grid-template-columns: repeat(4, minmax(auto, min-content));
} */

.Mp_Filter_Tabs_Top {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.Mp_Filter_Tabs_Top>div {
    flex: 1 1 55px;
}

.Mp_Tab_navItems {
    padding: 15px 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.Mp_FilterAccordionItem_btn>.accordion-button::after {
    top: 12px !important;
    /* or any other color that suits your design */
}


/* ============================ left Filter ends ======================= */


/* ===================== market place center cards ====================== */

.MpCardsMainCardContainer {
    padding: .5rem;
}

.marketplaceCardSec.card {
    margin: 0px 0px 12px !important;

}
.marketplaceCardSec2 {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    cursor: pointer;
    padding: 0.5rem;
    box-sizing: content-box;
    height:400px;
    margin-bottom: 8px;
}

.marketplaceCardSec {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    cursor: pointer;
    padding: 0.5rem;
    box-sizing: content-box;
}

.marketplaceCardSec .Mp_Card_img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 5px 5px 5px 5px;
}


.marketplaceCardSec2 .Mp_Card_img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 5px 5px 5px 5px;
}
.MarketPlaceCard_RightEye {
    position: absolute;
    display: flex;
    bottom: 8px;
    left: 8px;
    background: rgba(0, 0, 0, 0.15);
    padding: 4px;
    border-radius: 5px;
    backdrop-filter: blur(7px);
}

.MarketPlaceCard_RightEye_Suggested {
    position: absolute;
    display: flex;
    top: 20px;
    right: 12px;
    background: rgba(0, 0, 0, 0.15);
    padding: 4px;
    border-radius: 5px;
    backdrop-filter: blur(7px);
}

.MarketPlaceCard_RightEye p {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;

}

.MarketPlaceCard_RightEye_Suggested p {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;

}

.Mp_ProductCard_Analytics {
    padding: 6px 10px;
    background-color: rgba(0, 0, 0, 0.26);
    color: white;
    border-radius: 5px;
}

.MarketPlace_MrktIconLeft {
    position: absolute;
    top: 20px;
    left: 16px;
    display: flex;
    background: rgba(0, 0, 0, 0.15);
    padding: 8px;
    border-radius: 5px;
    backdrop-filter: blur(7px);
}

.MarketPlace_MrktIconLeft_SingleProductPage {
    position: absolute;
    top: 15px;
    /* left: 25px; */
    right: 25px;
    display: flex;
    background: rgba(0, 0, 0, 0.15);
    padding: 8px;
    border-radius: 5px;
    backdrop-filter: blur(7px);
}

.MarketPlace_MrktIconLeft_SingleProductPage1 {
    position: absolute;
    top: 25px;
    left: 25px;
    /* right: 25px; */
    display: flex;
    background: rgba(0, 0, 0, 0.15);
    padding: 8px;
    border-radius: 30px;
    backdrop-filter: blur(7px);
}


/* .MarketPlaceCard_RightEye p {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;

} */

.MpThreeDotMenu {
    position: absolute;
    right: 10px;
    top: 20px;
}

.MpMobileThreeDotIcon {
    display: none;
}

.Mp_ThreeDotIcon.btn-group>.btn {
    padding-right: 0px !important;
    padding-top: 8px !important;
}

.MpThreeDotMenu_singleProduct {
    position: absolute;
    right: 10px;
    top: 6px;
}

.MpMobileThreeDotIcon {
    display: none;
}

.MpThreeDotMenu_singleProduct.btn-group>.btn {
    padding-right: 0px !important;
    padding-top: 8px !important;
}





.MarketPlace_SubCate_Price {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MarketPlace_SubCate_Price h5 {
    color: #81c14b;
    font-weight: 400;
    font-size: 14px;
}

.MarketPlace_SubCate_Price p {
    color: #81c14b;
    font-size: 16px;
    font-weight: 600;
}

.MpDesktopEye {
    display: block;
}

.MpMobileEye {
    display: none;
}


/* .Mp_Card_Call_Msg_Btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
} */

.Mp_Card_Call_Msg_Btn {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
}

.Mp_Card_Call_Msg_Btn1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
}

/* 
.Mp_Card_Call_Msg_Btn>button {
    flex: 1 1 100px;
} */

.MarketPlaceCardContent {
    padding: 10px;
}




/* / */

.Mp_Card_Bottom_Sec h4 {
    color: #4D4D4D;
    font-weight: 600;
}

.Mp_Card_Bottom_Sec h3 {
    font-weight: 600;

}

.Mp_Card_Bottom_Sec .MarketPlace_SubCate_Price {
    display: flex;
    justify-content: space-between;
    padding-top: 13px;
}

.Mp_Card_Bottom_Sec .MarketPlace_SubCate_Price p {
    color: #4D4D4D;
    font-weight: 600;
}


.Mp_Card_Bottom_Sec .MarketPlace_SubCate_Price h5 {
    color: #16C31E;
    font-weight: 400;
}



.Mp_MsgBtn_svg {
    margin-right: 8px;
    width: 15px;
    height: 15px;
}

.Mp_MsgBtn_svg:hover {
    stroke: white;
}

.Mp_GridDesign_Display_None {
    display: none;
}

.Mp_ListViewCard_DisplayMange {
    display: flex;
}

.MpnoneDisplay {
    display: none;
}

/* ==============market place center cards end ======================= */


/* ============== Market place single product ========================= */

.SetHeartActive {
    color: red;
    fill: red;
}




/* .Mp-SingleProductMoreImages {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5px;
    row-gap: 5px;
} */

.MP_GridImageSizeDecieder img {
    aspect-ratio: 4/4;
    object-fit: cover;
    object-position: top;
}


/* For Safari iOS */
@supports (-webkit-touch-callout: none) {
    .MP_GridImageSizeDecieder {
        height: 0;
        padding-bottom: 100%;
        position: relative;
    }

    .MP_GridImageSizeDecieder img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
}




.Mp_GridContainers {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Mp_Single_CardDetails>h5 {
    color: #81c14b;
    font-weight: 600;
    margin-top: 10px;
}

.Mp_Single_CardDetails_H5 {
    color: #81c14b;
    font-weight: 600;
    margin-top: 10px;
}

.Mp_Single_CardDetails>h1 {
    color: #4D4D4D;
    font-weight: 600;
    margin-top: 10px;
}

.Mp_SetOrder {
    display: flex;
    width: 100%;
}

.Mp_setOrderLeft {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Mp_setOrderRight {
    flex: 0.2;
}

.Mp_setOrderLeft>h5 {
    font-weight: 600;
    color: #4D4D4D;
}

.Mp_setOrderLeft_Image {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.Mp_setOrderLeft_Image>img {
    width: 6.25rem;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
}

.Mp_setOrderLeft_ImageText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.25rem;
}

.Mp_setOrderLeft_ImageText1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.35rem;
}

.Mp_setOrderLeft_ImageText>h6 {
    color: #878992;
    font-weight: bold;
}

.Mp_setOrderLeft_ImageText>.Mp_singleVarient {
    font-weight: 600;
    color: "#4D4D4D"
}

.Mp_setOrderRight_Icon {
    padding: 7px;

}

.Mp_SinglePage_CardHeading {
    font-weight: 600;
    color: #4D4D4D;
}

.Mp_AllItems_Items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
}

.Mp_AllItems_Discription {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Mp_AllItems_Discription>li {
    color: #878992;
    font-weight: 400;
}

.MpSellerDetails_View {
    color: #81c14b;
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
}

.Mp_TextColorGray {
    color: "#878992";
    font-weight: "400";
}

.Mp_ColorPrimaryBold {
    font-weight: 600;
    color: "#81c14b";
}



/* like share cmment and view   */

/* .MuiButtonBase-root{
    color: #81c14b !important;
} */
/* button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    color: #81c14b !important;
    text-transform: capitalize !important;
}


span.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
    background-color: #81c14b !important;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    text-transform: capitalize !important;
} */

/* ======================== market place single page ========================== */

/* .css-1aquho2-MuiTabs-indicator {
    background-color: #81c14b !important;
} */



/* Market place sell modal */

.Mp_Modalheader {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 6px 0px;
}

.Mp_Modalheader>h3 {
    color: #4D4D4D;
    font-weight: 600;
}

/* .Mp_Modalheader>h5 {
    color: #ABACB3;
    font-weight: 400;
} */

.Mp_SellForm_Head>h4 {
    font-weight: 600;
    color: #4D4D4D;
}

.MpModalCategoryCard {
    max-height: 372px;
    overflow-y: auto;
}



/* =========== Form tabs ======== */
/* 
.MpFormTabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
}

.MpFormTabs>div {
    flex: 1 1 100px;
    border-radius: 5px;
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
} */

.MpformElementHeading {
    color: #878992;
    font-weight: 500;
}


.MpFormTabs {
    width: 100%;
    display: flex;
    gap: 20px
}


.Mp_Form_Tabs_ {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: inline-block;
    padding: 8px 15px;
    margin: 0 10px 10px 0;
    color: #4d4d4d;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.Mp_Form_Tabs_:hover {
    background: rgba(129, 193, 75, 0.15);
    border: 1px solid #81C14B;
}


.Mp_Form_ImageUploadInputs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.Mp_Form_ImageUploadInputs>div {
    flex: 1 1 auto;
    max-width: 100px;
}




.MpFullDropDown {
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    max-height: max-content;
}

.MpFormDropdownHeading {
    padding: 14px 25px;
}

.MpFullDropDown>div {
    position: absolute;
    left: 0;
    top: 44px;
    display: flex;
    padding: .5rem 0rem;
    padding-left: 2rem;
    gap: 12px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    z-index: 1000;
    background-color: white;
    box-shadow: 0px 20px 70px rgba(0, 0, 0, 0.1);
}

.MpFullDropDown>div>h5 {
    width: 100%;
    padding: .5rem 0rem;

}

.MpFormLocationInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    width: 100%;
    padding-right: 10px;
}


.Mp_LocationBoxOnInput {
    position: absolute;
    top: 46px;
    left: 0;
    width: 100%;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 20px 70px rgba(0, 0, 0, 0.1);
    background-color: white;
    z-index: 1;
}





.MpFormPriceInput>input {
    font-weight: 500;
    color: #4D4D4D;
}

.MpFormPriceInput>input::placeholder {
    font-weight: 500;
    color: #4D4D4D;
}


/* Discription box  */


.MpFormBottomEditable>span {
    font-size: 18px;
    object-fit: cover;
    object-position: center;
}

.MpChooseImage {
    border: 1px dashed #D1D0D3;
    border-radius: 20px;
    /* padding: 20px; */
    width: 100px;
    height: 100px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    object-fit: cover;
    object-position: center;
    justify-content: center;
}




/* form selller profile  */




.Mp_From_Seller {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Mp_From_Seller>img {
    width: 90px;
    height: 90px;
}


.Mp_Form_SellerCommunicaion>h5 {
    color: #878992;
    font-weight: 500;
}


.Mp_FormSeller_Btn {
    padding: 11px 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: white;

}

.MpSellerBtnActive {
    background: rgba(129, 193, 75, 0.15);
    border: 1px solid #81C14B;
}

.Mp_FormSeller_Btn:hover {
    background: rgba(129, 193, 75, 0.15);
    border: 1px solid #81C14B;
}

.Mp_FormPostNow_Btn {
    padding-left: 80px;
    padding-right: 80px;
}


.css-16r9ayz-MuiFormControlLabel-root {
    margin-bottom: 0px !important;
}








button.srch_btn_toggle.btn.btn-secondary {
    background: none !important;
    /* margin-top: -9px; */
    padding: 0;
}

.marketPlace_srch_dropMenu.dropdown-menu.show {
    display: block;
    width: 112%;
    will-change: transform;
    top: 0px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 10px 10px 84px rgba(0, 0, 0, 0.15);
    left: 0px;
    transform: translate3d(-37px, 48px, 0px) !important;
}


.Mp_MarketSvgICon {
    width: 12px;
    height: 12px;
    border-color: white;
}







/* ================ Universal class for marketplace Tabs ============= */
.Mp_TabsActive {
    background: rgba(129, 193, 75, 0.15);
    border: 1px solid #81C14B;
}

.Mp_TabsHover:hover {
    background: rgba(129, 193, 75, 0.15);
    border: 1px solid #81C14B;
}


/* color class  */

.Mp_Color_4D {
    color: #4D4D4D;
}

.Mp_FontWeight_6 {
    font-weight: 600;
}

.Mp_FontWeight_5 {
    font-weight: 500;
}

.Mp_Color4D_Font6 {
    font-weight: 600;
    color: #4D4D4D;
}

.Mp_Color4D_Font5 {
    font-weight: 500;
    color: #4D4D4D;
}

.Mp_Color80_Font4 {
    font-weight: 400;
    color: #4D4D4D;
}

.Mp_Color4D_Font6_Flex1 {
    font-weight: 600;
    color: #4D4D4D;
    flex: 1;
}

.Mp_Border0_Flex1 {
    border: none;
    flex: 1;
}

.Mp_Color81_Font5 {
    color: #81C14B;
    font-weight: 500;
}

.Mp_Color87_Font5 {
    color: #878992;
    font-weight: 500;
}

.Mp_Color16_Font6 {
    color: #161828;
    font-weight: 600;
}

.Mp_Color80_Font5 {
    color: #808080;
    font-weight: 600;
}


.zIndexImage {
    z-index: 1 !important;
}







/*  ================== Modal Location Design ================ */

.Mp_Loaction_Modal_Heading {
    display: flex !important;
    justify-content: space-between !important;

}

.Mp_Loaction_Modal_Heading h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

}

.Mp_Loaction_Modal_Heading p {
    color: #81C14B;
    /* font-weight: 600; */
    font-size: 16px;
    cursor: pointer;
    line-height: 24px;

}


.Mp_FormControlSec p {
    color: #81C14B;
    /* font-weight: 400;
    font-size: 16px; */
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.Mp_Form_Location_FormControl_Input {
    position: relative;
}


label.Mp_LocationSearchInputLabel {
    color: #4D4D4D !important;
    font-weight: 500 !important;
    font-size: 14;
}


.inputForm {
    padding-right: 20%;
    background-color: #F6F6F6;


}


.ImageCrossButton_Mp {
    border-radius: 100%;
    background-color: #FFFFFF;
    opacity: 0.7;
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
}

















/* arbnnb  */

.colorWhiteSlider {
    background-color: white !important;
}






/* market place modal  */
.MP_Modal_Adjustments {
    max-height: 400px;
}


ol.breadcrumb {
    background: transparent;
}





.PositionOfSmilyInReplyInput {
    position: absolute;
    right: 50px;
}





h3.MpEmojiBoxHeader.popover-header {
    background: #81c14b;
    color: white;
}



/* to responsive the cards */

.MpGridSystemAuto {
    display: grid;
    -ms-grid-columns: (minmax(200px, 1fr))[auto-fill];
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}



button.Mp_Card_Msgbtn.btn.btn-outline-success {
    padding: 4px 8px;
}

.Mp_Card_Msgbtn {
    width: 100%;
    padding: 10px;
}







/* Market place  three dot menu   */


.dropdown-menu.MpDropDownMenu.Market\@place_dropDown.show {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(135px, 40px) !important;
    width: fit-content;

}


.dropdown-menu.MpDropDownMenu.Market\@place_dropDown.show {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(135px, 40px) !important;
    width: fit-content;

}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.MarketPlaceSingleProductMenu.show {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(550px, 41px) !important;
    width: fit-content;
}



.Mp_Product_Status {
    padding: 5px 15px;
    background-color: #80c14b4e;
    color: #81c14b;
    border-radius: 30px;
    display: inline-block;
}

.Mp_Product_Status2 {
    padding: 5px 15px;
    background-color: #0f100f61;
    color: #fff;
    border-radius: 30px;
    display: inline-block;
}





.MpDropdownOptionMenu.dropdown-menu.show {
    width: fit-content !important;
    box-shadow: 2px 2px 8px rgb(0 0 0 / 10%) !important;
}


a.MpDropdownMenuItems.dropdown-item:hover {
    background: #e6f3db !important;
}

.MpDropdownMenuItems {
    display: flex;
    gap: 10px;
    font-size: 14px;
    border-bottom: 1px solid rgb(225, 225, 225);
}

.MpDropdownMenuItems:last-child {
    border-bottom: none;
}

.Mp_ThreeDotIcon {
    position: absolute;
    top: 10px;
    right: 10px;
}


.MpDropdownOptionMenu.dropdown-menu.show {
    transform: translate3d(-190px, 28px, 0px) !important;
}

.MpDropdownOptionMenu2.dropdown-menu.show {
    transform: translate3d(-132px, 39px, 0px) !important;
}




a.Mp_ViewTabs.nav-link.active {
    color: #81C14B;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    border-bottom: solid 1px #81C14B;
}

a.Mp_ViewTabs.nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 3rem;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: rgba(0, 0, 0, 0.5);
}


.mpCancelButton {
    border: 1px solid red;
    background-color: rgba(255, 0, 0, 0.19);
    color: red;
}






.MpDeleteButton {
    color: orange;
    border-color: orange;
}

.MpDeleteButton:hover {
    background: orange;
    color: white;
    border-color: orange;
}


button.MpDropdownMenuItems.dropdown-item:hover {
    color: #333;
    background: #e6f3db;
}


.Mptruncated {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


li.MpBreadCrumb_Items.breadcrumb-item {
    padding: 0 !important;
}

.MpSigleProductBookMark {
    position: absolute;
    right: 42px;
    top: 10px;
}
.MpSigleProductBookMark1 {
    position: absolute;
    right: 42px;
    top: 21px;
}








.Mp_Deskto_HeaderTabs {
    flex-wrap: wrap;
}



.Mp_Mobile_HeaderTabs {
    display: none;
}


.mpListViewImageWidth {
    min-width: 4vw;
    min-height: 4vh;
}


/* list View Card Design ================= */

.MpCardListView {
    /* max-height: 16vh; */
    display: grid;
    grid-template-columns: 11% 41% 15% 13% 18% 2%;
    grid-template-areas: "image name category price seller ThreeDotMenu";
    place-items: center;
    box-sizing: content-box;
    overflow-clip-margin: 10px;
}

.Mp_ListviewCardImage {
    grid-area: image;
    height:100px;
}

.Mp_ListViewCard_NameLoc {
    grid-area: name;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.MpCardListViewCategory {
    grid-area: category;
}

.MpCardListViewPrice {
    grid-area: price;
}

.MpCardListViewBtn_userdetail {
    grid-area: seller;
}

.MpCardListViewMenu {
    grid-area: ThreeDotMenu;
}

.MpListViewCardEye {
    position: absolute;
    bottom: 6px;
    right: 6px;
}



.MarketPlaceListView_MrktIconLeft {
    position: absolute;
    top: 8px;
    left: 6px;
    display: flex;
    background: rgba(0, 0, 0, 0.15);
    padding: 4px;
    border-radius: 5px;
    backdrop-filter: blur(7px);
}

.MpCardListViewButtons>div {
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid rgb(237, 237, 237);
}


.MarketPlaceFullCardContainer>button {
    padding: 2px 12px !important;
}

/* .MpHoverCard {
    display: flex;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 0 20px;
    transition: box-shadow 1s !important;

} */

button.Mp_ListGrid_Btn.ml-2.btn.btn-outline-success {
    padding: 9px 9px !important;
}


.MpHoverCard:hover {
    border: 1px solid #81C14B !important;
    background: rgba(129, 193, 75, 0.1) !important;
    border-radius: 6px;
}


.MpRevertRow.row {
    --bs-gutter-x: revert !important;
}











/* Modal category card ANimations ============================================= */

.Mp_CateList_d-none {
    animation: slideGone 0.3s ease;
    display: none;

}

.Mp_CateList_d-block {
    animation: SlideCome 0.3s ease;

}






@keyframes slideGone {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes SlideCome {
    from {
        opacity: 0;
    }

    to {

        opacity: 1;
    }
}


.MpFilterAndSortByMobile {
    display: none;
    position: relative;
}


/* vertical line */
.MpFilterAndSortByMobile::before {
    content: "";
    width: .5px;
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    transform: translate(-50%, 0%);
    background-color: #dee2e6;
}



/* ============================ sideBar Mobile Filter  ================== */
/* .MpFilterSortByMobile {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: white;
    overflow: auto;
    padding: 1rem;
    display: none;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 70vw;
    animation: SideBarfilterIn 0.3s ease-in-out;
} */





/* ========================== list View card media Query ====================== */

/* 
@media screen and (max-width:751px) {
    .MpCardListView {
        grid-template-columns: repeat(auto-fill, 1fr);
        grid-template-areas:
            "image image name name name ThreeDotMenu"
            "image image name name name .."
            "image image price price ..  .. "
            "image image .. .. seller seller "
            "image image category category seller seller";
        max-height: 35vh;
    }
}

@media screen and (max-width:425px) {
    .MpCardListView {
        grid-template-areas:
            "name name name price price ThreeDotMenu"
            "name name name price price .."
            "image image category category ..  .. "
            "image image category category seller seller "
            "image image category category seller seller";
    }
} */



ol.breadcrumb {
    z-index: 2 !important;
    position: relative !important;
}


.ManageOwnProductBtn {
    border: 1px solid orange;
    color: orange;
    background: #FFFFFF;
}

.ManageOwnProductBtn:hover {
    background-color: orange;
    border: 1px solid orange;
    color: white;
}

.marketradio {
    -webkit-appearance: radio !important;
    width: 18px !important;
    height: 24px;
}

.canvas3 li {

    padding: 3px 0;
    color: #E6F4F3;
}

.canvas3 {
    text-align: left;
    /* position: relative; */
    /* left: 36px; */
    margin-left: 8px;
}

.marketcn {
    position: relative;
    left: 4px;
}

@media (min-width:280px) and (max-width: 576px) {
    .markettop {

        position: relative;
        overflow: hidden;
        margin-right: 8px;
        margin-left: 8px;
    }
}

@media (min-width:280px) and (max-width: 336px) {
    .likempdiv {

        position: relative;
        right: 10px;

    }
}

@media (min-width:280px) and (max-width: 576px) {
    .mpshare {

        margin: 15px;
    }

    .cardmobile {

        display: none !important;
    }

    .comenshare {

        position: absolute;
        left: 98px;
    }

    .markettop1 {

        position: relative;

        margin-right: 8px;
        margin-left: 8px;
    }

    .Mp_FormPostNow_Btn {
        padding-left: 40px;
        padding-right: 40px;
    }

    .gapmp {
        border-radius: 10px;
        border: 1px solid #81C14B;
        background: #FFF;
    }

    .descpgap {
        margin-top: 26px !important;
    }

    .mpother{
        margin-top: 20px;
    }
}





.MpUploadHeading1 {
    display: flex;
    justify-content: flex-end;

}

@media (min-width:576px) {
    .cardmobileviewmp {

        display: none !important;
    }
}

.maploc {
    color: #808080;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.maplocc {
    color: #878992;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.maploccc {
    color: #212529;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.aboutmp {
    color: #161828;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.mpdescr {
    color: #4D4D4D;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.parmp {
    margin-top: -20px;
    margin-left: 13px;
    color: #878992;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
}

@media  (max-device-width: 480px) {
    .parmp {
      line-height: 1.2; /* Adjust line height specifically for iOS */
    }
  }

.mapicon{
    position: relative;
    top: -2px;
}

.totalpri{
    color: #000 !important;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.totalpri2{
    color: #81C14B;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}