.scrolledContent {
  overflow-y: auto;
  margin-top: 80px;
}

.ad-left {
  background-color: white;
  box-shadow: 0px 0px 10px rgb(129 193 75 / 10%);
}

.borderForADSelection {
  box-shadow: 0px 0px 5px 2px rgba(129, 193, 75, 0.94) !important;
  background: #e5f0e0 !important;
}

.color-grey {
  color: #a3a5a5;
}

.desh-icon-main {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  width: 300px;
  /* margin: auto; */
  margin-top: 15px;
  align-items: center;
  /* height: 100vh; */
}

/* CREATE AD */
.brand-main-new {
  width: unset;
  height: unset;
  background: unset;
}

.step-select-new {
  position: absolute;
}

.ad-count-btn {
  width: 44px;
  height: 44px;
  background: #81c14b;
  border-radius: 30px;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.ad-count-step {
  font-weight: 600;
  font-size: 16px;
  color: #81c14b;
}

.ad-count-heading {
  font-style: normal;
  font-weight: 500;
  color: #4d4d4d;
  font-weight: bold;
}

.ad-count {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.step-ad-heading {
  box-shadow: 0px 0px 10px rgb(129 193 75 / 10%);
  background-color: white;
  padding: 1rem 0rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.ad-type {
  padding: 1.5rem;
  background-color: white;
  box-shadow: 0px 0px 10px rgb(129 193 75 / 10%);
  margin: 0.5rem;
  align-items: center;
}

.btn-cancel {
  padding: 12px 3rem;
  background: #e0e0e0;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  color: black;
  font-size: 14px;
}

.btn-next:hover {
  color: white;
}

.btn-next {
  padding: 12px 3rem;
  background: #81c14b;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  color: white;
  font-size: 14px;
}

.ad-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}

.step-section {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
}

.radio_animated_lg:checked:before {
  padding: 7px;
}

.radio_animated_lg:checked:after {
  padding: 10px;
}

.radio_animated_lg:after {
  padding: 10px;
}

.ch-150 {
  height: 150px !important;
}

/* TYPES OF TYPE */
.ad-types-type {
  flex-direction: column;
  box-shadow: unset;
}

.ad-types-type-dec {
  display: contents;
}

.ad-types-type {
  background: #f6f6f6;
  border-radius: 10px;
}

.dec-types {
  padding: 0 5rem;
  text-align: center;
  color: black;
}

.ad-types-of-type-map {
  padding: 1rem;
  background-color: white;
  border-radius: 5px;
}

/* SINGLE IMAGE AD */
.navigate {
  padding-bottom: 1rem;
}

.p-heading {
  font-weight: bold;
  font-size: 16px !important;
  line-height: 21px;
  color: #4d4d4d;
  margin-bottom: 0.5rem;
  margin-right: 0.3rem;
  font-family: "Poppins", sans-serif;
}

.upload-img {
  width: 400px;
  height: 200px;
  background: #f6f6f6;
  border: 1px dashed #c4c4c4;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}
.upload-imgs {
  width: 400px;
  height: 200px;
  background: #f6f6f6;
  border: 1px dashed #81c14b;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}
.p-max-car {
  margin-top: 0.5rem;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #808080;
}

.btn-toggle {
  margin: 0 1rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
}

.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
  outline: none;
}

.btn-toggle:before,
.btn-toggle:after {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}

.btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}

.btn-toggle.active {
  transition: background-color 0.25s;
}

.btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
  background: #81c14b;
}

.btn-toggle.active:before {
  opacity: 0.5;
}

.btn-toggle.active:after {
  opacity: 1;
}

.btn-size {
  padding: 12px 25px !important;
}

.navigate-right {
  display: block;
  text-align: end;
}

.navigate-color {
  color: #81c14b;
  font-family: "Poppins", sans-serif;
}

/* CAROSEL AD */
.carosel-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ad-btn-new {
  background: transparent;
  border: 1px solid #81c14b;
  display: inline;
  font-size: 13px;
  border-radius: 5px;
  padding: 10px 30px;
  margin: 5px;
}

.full-video-ad-main {
  background-color: #f6f6f6;
}

.full-video-ad {
  width: 140px;
  display: block;
  margin: auto;
}

/* save-audience */
.save-audience {
  flex-direction: column;
}

.a-price-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
}

.a-price-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.a-price {
  font-weight: bold;
  font-size: 16px !important;
  line-height: 21px;
  color: #81c14b;
  margin-bottom: 0.5rem;
  margin-right: 0.3rem;
}

.total-amount {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.total-amount p {
  margin: 0.5rem 0;
}

.total-amount h2 {
  font-family: "Poppins", sans-serif !important;
  font-size: 2.5rem;
  margin: 0;
  color: #51595e;
}

/* PREVIEW */
.preview-main {
  background-color: unset;
  margin: unset;
  padding: 1rem 0rem;
}

.preview-left-end {
  background-color: white;
  box-shadow: 0px 0px 10px rgb(129 193 75 / 10%);
  padding: 1rem;
  border-radius: 5px;
}

.post-panel-new {
  background-color: white;
  overflow: hidden;
}

.preview-grp-post > img {
  width: 90px;
  height: 100px;
  border-radius: 1rem 1rem rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.preview-event > img {
  width: 150px;
  height: 150px;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.head-less-preview {
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #ebebeb;
  /* width: 75%; */
  border-radius: 5px;
  cursor: pointer;
}

.tag {
  padding: 1rem;
  font-size: inherit;
  font-weight: 500;
}

.Why-this-ad p {
  text-decoration: underline;
  color: darkgray;
}

.transparent-btn {
  padding: 5px 20px !important;
}

.ad-heading-upsd-title-input p {
  font-style: normal;
  font-weight: 400;
  /* font-size: 12px; */
  line-height: 18px;
  font-weight: bold;
  color: #4d4d4d;
  margin: 1.3rem 0rem;
}

/* LEAD GENREGATION */
.lead-greem {
  color: #81c14b;
  font-size: 14px;
}

.lead-switch {
  flex-direction: row;
  justify-content: space-between;
}

.lead-desh {
  display: contents;
}

.lead-add {
  color: #81c14b;
  font-size: 14px;
  font-weight: 600;
}

.preview-img > img {
  width: 135px;
  height: 135px;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.preview-fake-img {
  width: 135px;
  height: 135px;
  background-color: #c4c4c4;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.preview-img-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.preview-detail-small {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.preview-small {
  font-size: smaller;
}

.preview-grp-img > img {
  width: 165px;
  height: 135px;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.preview-heading {
  margin: 0.5rem 0;
  font-weight: 600;
}

.preview-know {
  text-decoration: underline;
  font-weight: 600;
}

.preview-main-border {
  padding: 0.5rem;
  box-shadow: 0rem 0rem 0.5rem 0rem #0000004a;
  width: 180px;
  height: 205px;
  border-radius: 0.5rem;
}

.fake-heading {
  background-color: #c4c4c4;
  height: 10px;
  /* width: 135px; */
  margin: auto;
}

.fake-p {
  height: 10px;
  background-color: #c4c4c4;
  width: 65%;
}

.fake-know {
  height: 10px;
  background-color: #c4c4c4;
  width: 15%;
}

.fake-know-main {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.preview-event-know-more {
  border-top: 1px solid #647589;
}

.preview-event-know-more > p {
  color: #647589;
  margin-bottom: 1rem;
}

.preview-more {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
}

/* SMALL PREVIEW SCREENS */
.small-bg {
  background-color: #ebebeb;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.small-p-heading {
  font-weight: bold;
  font-size: 10px !important;
  /* line-height: 21px; */
  color: #4d4d4d;
  /* margin-bottom: 0.5rem; */
  margin-right: 0.3rem;
}
.sponsored-text {
  font-size: 9px !important;
}
.small-tag {
  font-size: 9px;
  font-weight: 500;
  text-align: justify;
}

.small-recom-btn-cont-blk {
  display: none;
}

.small-Why-this-ad {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 1rem; */
}

.small-preview-img > img {
  width: 60px;
  height: 60px;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.small-preview-fake-img {
  width: 60px;
  height: 60px;
  background-color: #c4c4c4;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.small-preview-heading {
  font-size: 10px;
}

.small-preview-small {
  font-size: 6px;
}

.small-preview-main-border {
  padding: 0.5rem;
  box-shadow: 0rem 0rem 0.5rem 0rem #0000004a;
  width: 90px;
  height: 115px;
  border-radius: 0.5rem;
}

.small-preview-grp-img > img {
  width: 75px;
  height: 70px;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.small-preview-fake-img {
  /* width: 102px; */
  height: 60px;
  background-color: #c4c4c4;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.small-preview-grp-post {
  width: 40px;
  height: 40px;
  border-radius: 1rem 1rem rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.small-preview-event > img {
  width: 75px;
  height: 75px;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.smalls-preview-fake-img {
  width: 150px;
  height: 95px;
  background-color: #c4c4c4;
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0rem 1rem 1rem 0rem #c4c4c473;
}

.smalls-preview-main-border {
  padding: 0.5rem;
  box-shadow: 0rem 0rem 0.5rem 0rem #0000004a;
  width: 218px;
  height: 160px;
  border-radius: 0.5rem;
}

.small-bg-main {
  cursor: pointer;
  height: 55vh;
  background: #c4c4c4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.5rem;
  border: 2px solid #81c14b;
}
.small-bg-mains {
  cursor: pointer;
  /* height: 55vh; */
  background: #c4c4c4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.5rem;
  border: 2px solid #81c14b;
}
.small-bg-main > .post-panel-new {
  border: unset;
}

.small-padding-fake {
  padding: 6px;
}

.small-preview-more {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 600;
  font-size: 10px;
  text-transform: capitalize;
}

.head-preview {
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #ebebeb;
  /* width: 50%; */
  border-radius: 5px;
  cursor: pointer;
}

.preview-heading-head {
  padding: 0.5rem 0;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0rem 0rem 1rem 0rem #00000014;
}

.preview-logo {
  margin-top: 1px;
  background-color: white;
  padding: 0.8rem;
}

.bg-white {
  background-color: white;
}

.full-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.post-details-new {
  padding: 8px;
}

.mp-overword {
  position: relative;
  margin-top: -30px;
  background: #212529ad !important;
  padding: 5px 10px;
  color: white;
}

.AdHov:hover {
  background-color: #e5f0e0;
}

.AdDurationForStyle {
  background: #f6f6f6;
  border-radius: 5px;
}

.AdDurationForStyle h4 {
  background: #f6f6f6;
  font-weight: 500;
  color: #4d4d4d;
  border-radius: 5px;
  padding: 12px;
}

.total-paid-ad {
  padding: 2rem 1rem;
  /* background: rgba(126, 178, 98, 0.2); */
  margin-bottom: 2rem;
}

.total-paid-ad-after-selected {
  padding: 2rem 1rem;
  background: rgba(126, 178, 98, 0.2);
  margin-bottom: 2rem;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main-section-spss {
  display: flex;
}

.sidebar-main-spss {
  position: absolute;
  width: 350px;
  height: 879px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(129, 193, 75, 0.1);
  overflow: hidden;
}

.desh-icon-main-spss {
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin: auto;
  margin-top: 15px;
  align-items: center;
}

.desh-icon-spss {
  display: flex;
}

.desh-icon-spss i:nth-child(1) {
  color: #6e6b7b;
  padding-right: 10px;
}

.desh-icon-spss p:nth-child(2) {
  color: #4d4d4d;
  /* font-family: 'Poppins'; */
  /* font-family: 'Poppins', sans-serif; */
  font-size: 12px;
  font-weight: 400;
}

.desh-second-spss i:nth-child(1) {
  color: #808080;
}

.create-add-main-spss {
  position: absolute;
  width: 330px;
  height: 40px;
  margin-left: 10px;
  margin-top: 10px;
  align-items: center;
}

.create-add-main-second-spss {
  position: absolute;
  width: 330px;
  height: 40px;
  background: #e5f0e0;
  top: 125px;
  left: 10px;
  border-left: 2px solid #81c14b;
  align-items: center;
}

.create-add-main-third-spss {
  position: absolute;
  width: 330px;
  height: 40px;
  top: 85px;
  left: 10px;
  align-items: center;
}

.create-add-spss {
  display: flex;
  justify-content: space-between;
  width: 298px;
  margin: auto;
  align-items: center;
  margin-top: 12px;
}

.create-add-one-spss {
  display: flex;
  margin-left: 15px;
}

.create-add-one-spss i:nth-child(1) {
  color: #4d4d4d;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.create-add-one-spss p:nth-child(2) {
  color: #81c14b;
  /* font-family: 'Poppins'; */
  font-weight: 400;
  font-size: 12px;
  color: #4d4d4d;
  font-weight: bold;
}

.create-add-second-spss i:nth-child(1) {
  color: #808080;
}

.step-select-main-spss {
  width: 100%;
  height: 91px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
}

.step-select-spss {
  display: flex;
}

.step-select-child-spss {
  display: flex;
  align-items: center;
  margin-top: 10px;
  overflow: hidden;
}

.step-select-one-spss {
  box-sizing: border-box;
  position: absolute;
  width: 145px;
  height: 64px;
  left: 350px;
  margin-top: 14px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.step-select-child-spss button:nth-child(1) {
  position: absolute;
  width: 44px;
  height: 44px;
  left: 22px;
  background: #81c14b;
  border-radius: 30px;
  border: none;
  margin-top: 40px;
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.step-select-child-spss p:nth-child(2) {
  position: absolute;
  left: 76px;
  /* font-family: 'Poppins'; */
  font-weight: 600;
  font-size: 16px;
  color: #81c14b;
  margin-top: 40px;
}

.step-select-second-spss p:nth-child(1) {
  position: absolute;
  left: 526px;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 500;
  color: #4d4d4d;
  margin-top: 20px;
  font-weight: bold;
}

.step-select-second-spss p:nth-child(2) {
  position: absolute;
  left: 525px;
  /* font-family: 'Poppins'; */
  font-weight: 400;
  font-size: 12px;
  color: #808080;
  margin-top: 50px;
}

.brand-image-main-spss {
  position: absolute;
  width: 874px;
  height: 541px;
  left: 415px;
  top: 110px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
  border-radius: 5px;
}

.brand-aware-text-spss p:nth-child(1) {
  position: absolute;
  left: 20px;
  top: 20px;
  /* font-family: 'Poppins'; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  color: #81c14b;
}

.ad-heading-spss p:nth-child(1) {
  position: absolute;
  left: 20px;
  top: 58px;
  /* font-family: 'Poppins'; */
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #4d4d4d;
}

.ad-heading-second-spss p:nth-child(1) {
  position: absolute;
  left: 20px;
  top: 172px;
  /* font-family: 'Poppins'; */
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #4d4d4d;
}

.ad-heading-third-spss p:nth-child(1) {
  position: absolute;
  left: 20px;
  top: 272px;
  /* font-family: 'Poppins'; */
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #4d4d4d;
}

.ad-sale-spss {
  box-sizing: border-box;
  position: absolute;
  width: 402px;
  height: 45px;
  left: 20px;
  top: 89px;
  background: #ffffff;
  /* border: 1px solid #DEDEDE; */
  border-radius: 6px;
}

.ad-sale-spss p:nth-child(1) {
  position: absolute;
  left: 35.09px;
  top: 12px;
  /* font-family: 'Poppins'; */
  font-size: 14px;
  color: #4d4d4d;
  font-weight: bold;
}

.max-char-spss {
  position: absolute;
  left: 308px;
  top: 135px;
  /* font-family: 'Poppins'; */
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #808080;
}

.ad-sale-second-spss {
  box-sizing: border-box;
  position: absolute;
  width: 402px;
  height: 45px;
  left: 20px;
  top: 200px;
  background: #ffffff;
  /* border: 1px solid #DEDEDE; */
  border-radius: 6px;
}

.ad-sale-second-spss-input-new {
  box-sizing: border-box;
  /* position: absolute; */
  width: 100%;
  height: 45px;
  left: 20px;
  background: #ffffff;
  /* border: 1px solid #DEDEDE; */
  border-radius: 6px;
}

.ad-sale-second-spss p:nth-child(1) {
  position: absolute;
  left: 35.09px;
  top: 12px;
  /* font-family: 'Poppins'; */
  font-size: 14px;
  color: #4d4d4d;
  font-weight: bold;
}

.max-char-second-spss {
  position: absolute;
  left: 308px;
  top: 245px;
  /* font-family: 'Poppins'; */
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #808080;
}

.ad-sale-third-spss {
  box-sizing: border-box;
  position: absolute;
  width: 833px;
  height: 150px;
  left: 20px;
  top: 300px;
  background: #ffffff;
  /* border: 1px solid #DEDEDE; */
  border-radius: 6px;
}

.ad-sale-third-spss p:nth-child(1) {
  position: absolute;
  left: 35.09px;
  top: 12px;
  /* font-family: 'Poppins'; */
  font-size: 14px;
  color: #4d4d4d;
  font-weight: bold;
}

.max-char-third-spss {
  position: absolute;
  left: 725px;
  top: 450px;
  /* font-family: 'Poppins'; */
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #808080;
}

.upload-main-spss p:nth-child(1) {
  position: absolute;
  left: 450px;
  top: 58px;
  /* font-family: 'Poppins'; */
  font-size: 14px;
  color: #4d4d4d;
  font-weight: bold;
}

.upload-main-spss button:nth-child(2) {
  position: absolute;
  width: 401px;
  height: 196px;
  left: 450px;
  top: 88px;
  background: #f6f6f6;
  border: 1px dashed #c4c4c4;
  border-radius: 5px;
  font-size: 14px;
  /* font-family: 'Poppins'; */
  font-weight: bold;
}

.two-bumain-spss {
  display: flex;
}

.two-bumain-spss button:nth-child(1) {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 30px;
  gap: 5px;
  position: absolute;
  width: 111px;
  height: 43px;
  left: 585px;
  top: 480px;
  background: #f6f6f6;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
}

.two-bumain-spsss button:nth-child(1) {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 30px;
  gap: 5px;
  position: absolute;
  width: 111px;
  height: 43px;
  left: 720px;
  top: 480px;
  background: #c4c4c4;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}

/* .brand-image-main-spyy{
  margin-top: 70px;
} */
.brand-image-main-spss {
  margin-top: 70px;
}

.total-budget {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.total-budget-time {
  justify-content: space-around;
  align-items: baseline;
  background: #f6f6f6;
  height: 100px;
}

.total-budget-time p {
  font-size: 1rem;
}

.budget-btn {
  background: #81c14b;
  color: white;
  border: none;
  border-radius: 5px;
  width: 150px;
  height: 43px;
}

.budget-btn-main {
  display: flex;
  justify-content: end;
}

.in-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.main-left {
  border-bottom: 1px solid rgb(180, 180, 180);
}

.transparent-btn {
  background: transparent;
  color: #81c14b;
}

.new-recom-btn-cont-blk {
  margin: -70px 0 10px !important;
  position: absolute;
  width: 100%;
  background-color: #212529ad !important;
}

.bg-bullet {
  color: #c4c4c4;
}

.Publish-btn {
  width: 100% !important;
}

.preview-data {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.borderLeftForDasboard {
  border-left: 5px solid #81c14b;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.15) inset;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 20px -20px; */
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}

.paddingForStyle p {
  padding-bottom: 0.5rem;
}

/* MEDIA QUERY START HERE */
@media (max-width: 575px) {
  .ad-left {
    display: none;
  }

  .pad-zero {
    padding: 0%;
  }

  .ad-types-of-type-map {
    margin: 1rem 0;
  }

  .input-img {
    display: flex;
    flex-direction: column;
  }

  .upload-img {
    width: 237px;
  }

  .ad-types-of-type-map {
    padding: 1rem 0;
  }
}
.account-text {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 15px;
}
.account-texts {
  color: #81c14b;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}
.Account-text-section {
  border: 1px solid #f6f6f6;
  padding: 10px;
  background-color: #f5fff7;
  border-radius: 5px;
}
.Account-text-sections {
  border: 1px solid #f6f6f6;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
}
.trash-icon {
  position: relative;
  color: red;
  cursor: pointer;
}
.image-text {
  margin-top: -15px;
}
.term-text {
  color: #1066ad;
  font-size: 14px;
  font-weight: 600;
  margin-top: 12px;
  text-decoration: underline;
}
.view-text {
  color: #48985c;
  font-size: 14px;
  font-weight: 600;
}
.custom-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.custom-message p {
  color: #717171;
  font-size: 14px;
  font-weight: 400;
}
.header-section-request {
  padding: 47px;
}
.modal-cross-icon {
  position: relative;
}
.cross-icon-section {
  position: absolute;
  right: 11px;
}
/* .nav-pills .nav-link.active, */
.nav-pills .show > .nav-link {
  color: #81c14b;
  background-color: #ffffff;
}
.nav-pills .nav-link {
  background: none;
  border: transparent;
  border-radius: 0.25rem;
  color: #000000;
  font-size: 16px;
  font-weight: 300;
}
.css-13xfq8m-MuiTabPanel-root {
  padding: 21px !important;
}
.hover-text:hover {
  background-color: rgb(129 193 75 / 20%);
}
