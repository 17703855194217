.Events_TimeLine_Section {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.2);
  border-radius: 5px;
}
.bizpagefordropdownmenus {
  transform: translate3d(-126px, 27px, 10px) !important;
  font-size: 12px;
}
.create-Grp-section {
  text-align: center;
  margin: 20px 0 0;
  text-align: right;
}
.send-btn-text p {
  color: #4d4d4d;
  font-size: 15px;
  font-weight: 400;
}
.UncontrolledButtonDropdowntext {
  z-index: 0 !important;
}
.Event_Remember_section h4 {
  color: #63bf4c;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
}
/* .slick-slide .slick-active .slick-current{
width: 100%;
} */
.Event_Remember_section p {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.Group_Top_Cover_Buttom_LeftSection {
  position: absolute;
  /* bottom: 70px; */
  top: 7rem;
  left: 26px;
  font-size: 18px;
}

.Events_Remember_Heading h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 20px;
}

.Events_Remember_Heading p {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.Events_Top_section {
  position: relative;
  /* margin: -12px !important; */
}

.Events_scheduling_Card {
  position: absolute;
  bottom: 33px;
  left: 22px;
  background: rgba(22, 195, 30, 0.2);
}

.Group_Top_Cover_Buttom_CenterSection {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
}

.event-cover {
  height: 350px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.2);
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.2);
  position: relative;
}

.event-cover .event-box {
  position: absolute;
  left: 40px;
  top: 50%;
  /* -webkit-transform: translateY(-50%); */
  transform: translateY(-50%);
  /* padding: 15px; */
  width: 300px;
  height: 200px;
}

.event-box .event-content {
  text-align: center;
}

.event-box {
  /* background: rgba(22, 195, 30, 0.2); */
  border-radius: 5px;
}

.event-conten p {
  color: #ffffff;
  font-weight: 500;
  font-size: 21px;
}

.event_Card_section {
  background: rgba(22, 195, 30, 0.2) !important;
  border-radius: 5px !important;
  width: 300px;
  /* height: 200px; */
  box-sizing: border-box;
}
@media (max-width: 500px) {
  .event_Card_section {
    background: rgba(
      22,
      195,
      30,
      0
    ) !important; /* Set transparent background for smaller screens */
  }
}
/* @media (min-width: 1280px) and (max-height: 800px) {
  .event_Card_section {
    background: rgba(
      22,
      195,
      30,
      0
    ) !important; 
  }
} */

.Events_Card_Top_section h4 {
  color: #ffffff;
  font-weight: 500;
  font-size: 21px;
}

.Events_Card_Top_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event_Location_section h4 {
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  text-decoration-line: underline;
}

.Event_Bottom_section p {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.Boday_section {
  /* display: flex;
    justify-self: stretch; */
}

.events-slide-box {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
  border-radius: 5px;
  /* border: 1px solid #C4C4C4; */
  position: relative;
}

.events-slide-box .event_Tag_section {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 2;
}

.event_Tag_section {
  background: #ff9922;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0px 0px 20px;
  padding: 5px 15px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

.event_Body_Box {
  position: relative;
}

.event_Body_Box p {
  background: #f6f6f6;
  border-radius: 80px;
  width: 30px;
  height: 30px;
  position: absolute;
  padding: 5px;
  top: 8px;
  right: 9px;
  cursor: pointer;
}

.event_Body_Box span {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.event_Body_Box small {
  color: #81c14b;
  font-weight: 400;
  font-size: 12px;
}

.event_Body_Box h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

.fdsgfs span {
  cursor: pointer;
}

/* .heading-podcast-blk p {
    color: #0066FF;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
} */

.events-custom-block {
  margin: 0 -5px 25px !important;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Events-dropdown.show {
  transform: translate(-161px, 23px) !important;
  padding: 0px !important;
}

.EventsDetail_tab_Section {
  margin: 1px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
  border-radius: 5px;
}

/* start_events_Top_banner_section  */
.Events_modal_header_section {
  background: #f6f6f6;
}

.Events_modal_header_section h2 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.Event_location_search-svg {
  position: absolute;
  left: 8px;
  top: 11px;
}

.Event_inputForm {
  padding-left: 27px;
  padding-right: 20px;
}

/* end_events_Top_banner_section  */

/* Start_events_Upcoming_section */
.Filter_Modal_section {
  display: flex !important;
  justify-content: space-between !important;
}

.Filter_Modal_section h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #4d4d4d;
}

.Filter_Modal_section p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #81c14b;
}

.Events_Main_section {
  border-bottom: 1px solid #f6f6f6;
  width: 100%;
}

.Events_Filter_Modal_Option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Events_Filter_Modal_Option h4 {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.Events_srch_block {
  /* width: 600px; */
  /* max-width: 100%; */
}

.EventsCustom-card-head {
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* .Events_srch_block .input-Events_search-blk {
  width: 200px;
} */

.input-Events_search-blk {
  position: relative;
}

.input-Events_search-blk .search-svg {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 12px;
  left: 10px;
  color: #4d4d4d;
}

.Events_Invite_btn {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.search-block-podcast .input-Events_search-blk {
  margin: 3px !important;
}

.search-block-podcast .input-Events_search-blk {
  margin: 0 15px 0 0;
}

.Events_srch_block {
  /* width: 600px;
    max-width: 100%; */
}

.Events_srch_block .input-Events_search-blk {
  margin: 1px 15px 0 0;
}

.input-Events_search-blk {
  position: relative;
  margin-bottom: 15px;
}

.input-Events_search-blk .search-svg {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 12px;
  left: 10px;
  color: #4d4d4d;
  cursor: pointer;
}

.input-Events_search-blk .form-control {
  background: #f6f6f6;
  border-radius: 5px;
  padding: 8px 10px 8px 35px;
}

.group-search-assign-filter .Events_srch_block {
  width: auto;
}

/* end_events_Upcoming_section */

/* start_ Hosted_tab_section */
.MyEvents_box_section {
  background-color: #ffffff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  /* overflow: hidden; */
  position: relative;
}

.MyEvents_box_section .MyEvent_tAG {
  position: absolute;
  right: 0px;
  top: 10px;
  /* z-index: 2; */
}

.MyEvents_box_section .MyEvent-img {
  object-fit: cover;
  height: 180px;
  width: 100%;
  border-radius: 5px 5px 0px 0px;
}

.MyEvents_box_slider_section {
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 10%);
  /* overflow: hidden; */
  position: relative;
}

.MyEvents_box_slider_section .MyEvent_tAG {
  position: absolute;
  right: 0px;
  top: 10px;
  /* z-index: 2; */
}

.MyEvents_box_slider_section .MyEvent-img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border-radius: 5px 5px 0px 0px;
}

.MyEvent_BodySection {
  padding: 1rem 1rem;
  flex: 1 1 auto;
  -webkit-box-flex: 1;
  /* padding: 8px; */
}

.MyEvent_BodySection h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
}

.MyEvent_First_BodySection {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.MyEvent_First_BodySection small {
  color: #81c14b;
  font-weight: 600;
  font-size: 12px;
}

.MyEvents_more_btn {
  background: #f6f6f6;
  border-radius: 80px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.MyEvent-bottom-Bodysection p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #808080;
  padding-top: 10px;
}

.MyEvents_Btn_Types {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 41px;
  gap: 10px;
  background: #81c14b;
  border-radius: 5px;
  cursor: pointer;
}

.MyEvents_Btn_Types p {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}

.MyEvents_Invitaion_Btn_types {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 41px;
  gap: 10px;
  background: #c4c4c4;
  border-radius: 5px;
  cursor: pointer;
}

/* .MyEvents_Invitaion_Btn_types p {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
} */
.border-select {
  border: 1px solid gray;
}

a.my_events_Navlink.nav-link.active {
  color: #ffffff !important;
  background-color: #81c14b !important;
  padding: 10px !important;
}

a.my_events_Navlink.nav-link {
  padding: 10px !important;
}

.My_eventsMember_section h4 {
  line-height: 30px;
}

.My-Member_Header_section {
  background: #f6f6f6;
}

.My-Member_Header_section h2 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 20px;
}

.MyEvents-memberslist-block {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 5px;
  margin: 0 -20px 7px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.MyEvents-memberslist-block img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border: 2px solid #ffffff;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  border-radius: 50%;
  margin-right: 10px;
}

.MyEvents-memberslist-block {
  display: block;
}

/* End_ Hosted_tab_section */

/*-------start_ EventsCategories ----------*/
.EventsCategories_calender {
  background: rgba(129, 193, 75, 0.1);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.Trending_Heading_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
}

.EventsCategories_All_events h5 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

li.leftSection:hover {
  cursor: pointer;
  /* color: #81C14B !important; */
}

li.Events_categories_leftSection {
  background: rgba(129, 193, 75, 0.2);
  cursor: pointer;
  color: #81c14b !important;
  border-right: 5px solid #81c14b;
}

.Categories_Left_block {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
  border-radius: 5px;
}

.Categories_LeftScroll_block {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
  border-radius: 5px;
  height: 810px;
  overflow-y: scroll !important;
}
.Categories_LeftScroll_blocks {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
  border-radius: 5px;
  height: 324px;

  overflow-y: scroll !important;
}
.Total_count_Events_Categories h5 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
}

.Categories_LeftPannel_slider {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  border-radius: 5px;
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  margin-bottom: 30px;
  overflow-y: hidden;
}
.Categories_LeftPannel_sliders {
  margin-left: 6px !important;
}

#Categories-Scroll-1::-webkit-scrollbar {
  width: 5px;
}

#Categories-Scroll-1::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#Categories-Scroll-1::-webkit-scrollbar-thumb {
  background: rgba(129, 193, 75, 0.2);
}

#Categories-Scroll-1::-webkit-scrollbar-thumb:hover {
  background: #81c14b;
}

.Categories_subHeading_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Categories_subHeading_section h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
}

.Categories_subHeading_section p {
  color: #0066ff;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

.ForWard_Img_categories {
  position: relative;
}

.right_imge {
  position: absolute;
  left: 51px;
  bottom: 51px;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.MyeventsCategories_Option.show {
  width: auto;
  /* -webkit-transform: translate(49px, 10px) !important; */
  /* transform: translate(49px, 40px) !important; */
  transform: translate(117px, 202px) !important;
  padding: 0 !important;
}

.Categories_iNTERESTED {
  text-transform: capitalize;
  font-size: calc(11px + (12 - 11) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
}

.events-cover.profile-cover-new {
  border-radius: 5px !important;
  position: relative !important;
  box-shadow: none !important;
}

.btn-EventUpload {
  color: #ffffff;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.css-bf9wz-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  background-color: #81c14b !important;
}

.css-13zgp8s.Mui-selected {
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  color: rgb(255, 255, 255) !important;
  background-color: #81c14b !important;
}

.Events_list_heading p {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
}

/*-------end_ EventsCategories ----------*/

/*-------------start_Events_listView_section --------------*/
.Events_ListView_section {
  background: #ffffff;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.events_Invitaion_Card_section {
  background: #f6f6f6;
  border-radius: 5px;
  padding: 10px 15px;
}

.Invitation_Img_section {
  border-radius: 5px;
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.Invitaion_btn_types {
}

.Events_PeopleThisGrid {
  display: grid;
  -ms-grid-columns: (minmax(200px, 1fr)) [auto-fill];
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.Invitaion_Card_section {
  display: flex;
  align-items: flex-start;
}

.Invitaion_Card_section h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}

.Invitaion_Card_section p {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.Biz-heading_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Biz-heading_section h2 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

/*-------------end_Events_listView_section --------------*/

@media (min-width: 360px) and (max-height: 740px) {
  .avt-img-section {
    position: absolute;
    left: -1px !important;
    width: 28px !important;
    top: -7px !important;
  }
  .mobile-text-edit {
    position: absolute;
    right: -19rem;
  }
  .Events_Cards_Top_sections {
    position: absolute;
    left: 10rem;
    height: 42px;
    bottom: 62px;
  }
  .profile-date {
    position: absolute;
    top: 2px;
    left: 37px;
  }
  .rem-mob-view-texts {
    display: none;
  }
  .EventsDetail_tab_Sections {
    height: 62px;
  }
  .btn-add-text {
    margin-top: -21px;
  }
}
@media (min-width: 600px) and (max-width: 712px) {
  .Events_srch_block {
    position: relative;
    right: 30px;
  }
  .EventsDetail_tab_Sections {
    height: 58px;
  }
}
@media (min-width: 280px) and (max-height: 500px) {
  .web-texts {
    display: none;
  }
  .web-text {
    position: relative;
    font-size: 10px;
    margin-top: 32px;
    text-wrap: nowrap;
    margin-left: 5px;
  }
  .map-web-text {
    position: absolute;
    bottom: -1px;
    margin-left: -5px;
    width: 15px;
  }
  .date-second {
    font-size: 9px;
  }
}

@media (max-width: 412px) {
  .web-texts {
    display: none;
  }
  .web-text {
    position: relative;
    font-size: 10px;
    margin-top: 32px;
    text-wrap: nowrap;
    margin-left: 5px;
  }
  .map-web-text {
    position: absolute;
    bottom: -1px;
    margin-left: -5px;
    width: 15px;
  }

  .date-second {
    font-size: 9px;
  }
  /* .BizBTN_sections {
    font-size: 10px;
  } */
}

@media (max-width: 430px) {
  /* .BizBTN_sections {
    font-size: 10px;
  }
  .star-img {
    width: 13px;
  } */
}
@media (max-width: 480px) {
  .web-texts {
    display: none;
  }
  .web-text {
    position: relative;
    font-size: 10px;
    margin-top: 29px;
    text-wrap: nowrap;
    margin-left: 5px;
  }
  .map-web-text {
    position: absolute;
    bottom: -1px;
    margin-left: -5px;
    width: 15px;
  }
  .date-second {
    font-size: 9px;
  }
  .EventsDetail_tab_Sections {
    height: 58px;
  }
}
@media only screen and (max-width: 384px) {
  .date-second {
    font-size: 9px;
  }
}

@media only screen and (max-width: 384px) {
  .date-second {
    font-size: 9px;
  }
}
@media (min-width: 800px) {
  .vghfthftgh {
    /* width: 150px; */
  }
}
@media (min-width: 375px) and (max-height: 667px) {
  /* .BizBTN_sections {
    padding-left: 0px;
  } */
  .avt-img-section {
    position: absolute;
    left: -15px !important;
    width: 28px !important;
    top: -7px !important;
  }
  .avt-img-sections {
    position: absolute !important;
    left: 5px !important;
    width: 28px !important;
    top: -7px !important;
  }
}
@media (min-width: 375px) and (max-height: 812px) {
  /* .BizBTN_sections {
    padding-left: 0px;
  } */
  .avt-img-section {
    position: absolute;
    left: -9px !important;
    width: 28px !important;
    top: -7px !important;
  }
  .avt-img-sections {
    position: absolute !important;
    left: 5px !important;
    width: 28px !important;
    top: -7px !important;
  }
}
@media (min-width: 430px) and (max-height: 932px) {
  .input-Events_search-blks {
    width: 175px;
    position: absolute;
    left: 59%;
    margin-top: -74px;
  }
}

/* @media (max-width: 400px) {
  .event-card-text {
    margin-left: -11px !important;
    font-size: 10px !important;
  }
} */
@media (min-width: 384px) {
  .cal-text {
    position: absolute;
    top: -5rem;
  }
}

@media (min-width: 600px) {
  .cal-text {
    margin-top: 39px;
  }
  /* .mobile-text-edit {
    position: absolute;
    right: -19rem;
  }
  .Events_Cards_Top_sections {
    position: absolute;
    left: 20rem;
    height: 42px;
    bottom: 62px;
  }
  .profile-date{
    position: absolute;
    top: 2px;
    left: 37px;
  } */
}
@media (max-width: 390px) {
  .BizPage-buttons-blk {
    font-size: 10px !important;
  }
  /* .star-img {
    width: 13px;
  }

  .BizBTN_sections {
    font-size: 10px;
  } */
}
@media (min-width: 768px) and (max-height: 1024px) {
  .left_bar {
    /* width: 150px; */
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: -18px;
  }
  /* .interest-text {
    display: none !important;
  } */
}
@media (max-width: 1024px) {
  .not-interested-button {
    max-width: 83px;
  }
  .interest-text {
    margin-left: -13px;
  }
}

@media (min-width: 820px) and (max-height: 1180px) {
  .left_bar {
    /* width: 150px; */
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: -18px;
  }
  /* .interest-text {
    display: none !important;
  } */
}
@media (min-width: 1024px) and (max-height: 1024px) {
  .left_bar {
    /* width: 150px; */
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: -18px;
  }
  /* .interest-text {
    display: none !important;
  } */
  .interest-texts {
    display: none !important;
  }
  /* .star-img {
    width: 13px;
  } */
}
@media (min-width: 1000px) and (max-height: 1920px) {
  .event-hidden {
    display: none !important;
  }
}
@media (min-width: 640px) and (max-height: 360px) {
  .search-blk {
    margin-right: 55px !important;
  }
  .event_Card_section {
    position: absolute;
    top: -101px;
    height: 153px;
  }
  .input-Events_search-blk {
    width: 156px;
  }
  .Event_Bottom_section {
    display: none;
  }
  .event_Card_section {
    background: rgba(22, 195, 30, 0) !important;
  }
}

@media (max-width: 820px) {
  .rem-mob-view-text {
    display: none;
  }
  .create-btn-text {
    margin-top: -2rem;
  }
}
@media (max-width: 320px) {
  .group-search-assign-filter {
    margin-right: 19px;
    /* margin-top: -5rem; */
  }
  .EventsDetail_tab_Sections {
    height: 48px;
  }
  .Events_srch_block .input-Events_search-blk {
    margin: 0px 0px 0 0;
  }
  .avt-img-section {
    position: absolute;
    left: -1px !important;
    width: 28px !important;
    top: -7px !important;
  }
}

@media (min-width: 1366px) and (max-height: 768px) {
  .input-card-event-text {
    /* margin-left: 7%; */
  }
}

/* @media (min-width: 800px) {
  .event_drop {
    display: none;
  }
} */

@media (max-width: 712px) {
  .input-card-event-text {
    position: absolute;
    left: -25px;
    margin-top: -7px;
  }
}

@media (max-width: 800px) {
  .mobile-slider {
    width: 700px;
  }
  .nav-item {
    display: grid;
    /* grid-template-columns: repeat(2, 1fr); */
    grid-gap: 0.2rem;
    gap: 0.2rem;
  }
}
@media (max-width: 820px) {
  .mobile-slider {
    width: 700px;
  }
  .nav-item {
    display: grid;
    /* grid-template-columns: repeat(2, 1fr); */
    grid-gap: 0.2rem;
    gap: 0.2rem;
  }
}
@media (max-width: 950px) {
  .mobile-slider {
    width: 700px;
  }
  .nav-item {
    display: grid;
    /* grid-template-columns: repeat(2, 1fr); */
    grid-gap: 0.2rem;
    gap: 0.2rem;
  }
}

@media (max-width: 600px) {
  .event_Card_section {
    position: absolute;
    top: -101px;
    height: 153px;
  }
  .input-card-event-text {
    position: absolute;
    left: -25px;
    margin-top: -7px;
  }
  /* .input-search-text {
    width: 172px;
  } */
}
/* Common styles for both groups */
.event-date-time-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Responsive styles for smaller screens */
@media (min-width: 320px) and (max-width: 640px) {
  .event-date-time-container {
    flex-direction: column !important;
    align-items: stretch !important;
  }
  .two-text {
    display: flex;
    justify-content: center;
    margin-top: -16px;
  }
  .event-date-time-group {
    margin-bottom: 10px; /* Adjust spacing between groups */
  }
}
/* CSS code */
@media (max-width: 560px) {
  .hide-on-search {
    display: none !important;
  }
}
@media (max-width: 320px) {
  .star-img {
    margin-left: -4px;
  }
  .btn-add-text {
    margin-top: -33px;
    margin-left: -9px;
  }
  .MyEvents_box_section {
    margin-right: -5px !important;
  }
}
@media (max-width: 400px) {
  .btn-add-text {
    margin-top: -26px!important;
  }
}
