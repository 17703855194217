.RightquotationIconAlert {
    position: absolute;
    left: 95%;
    bottom: 12%;
}

@media (max-width: 575px) {
    .RightquotationIconAlert {
        position: absolute;
        left: 87%;
        bottom: 12%;
    }

    .LeftIconforthrought {
        position: absolute;
        left: 5px;
        top: 9px;
    }

    .RightIconforthrought {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
}

.flexicon {
    position: absolute;
    left: 90%;
}

.progress-bar-warning {
    font-size: .857rem;
    background-color: #E5F0FF;
    color: black;
    border-radius: 35px;
}

.progressColor {
    background-color: #FFCC00;
}

.progress-bar {
    /* background-color: #FFCC00 !important; */
    border-radius: 35px !important;
    color: black !important;
    display: flex !important;
    justify-content: space-between;
    flex-direction: row !important;
}

.poll-radio-blk {
    padding: 1px 20px !important;
    background: #E5F0E0;
    border-radius: 35px;
    margin-top: 10px;
    display: flex;
}

.poll-radio-blk label {
    margin: 10px !important;
}

.radio_animated {
    position: relative;
    margin: 5px 1rem 0 0;
    cursor: pointer;
}

.radio_animated:before {
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75), -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    content: "";
    position: absolute;
    top: 5px;
    left: 0.125rem;
    z-index: 1;
    width: 0.55rem;
    height: 0.55rem;
    background: #81C14B;
    border-radius: 50%;
}

.radio_animated:after {
    content: "";
    position: absolute;
    top: 3px;
    /* left: 0.875rem; */
    margin-top: 9px;
    width: 1.05rem;
    height: 1.05rem;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}

.radio_animated:checked:before {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    /* margin-left: 16px; */
    margin-top: 4px;
}

.radio_animated:checked:after {
    border: 1px solid #81C14B;
}

.somecheccount {
    position: absolute;
    right: 22px;

}

.progress-bar-warning .input-radio {
    background-color: red;
}


#popover-basic.fade.show.popover.bs-popover-top {
    inset: auto auto -80px -24px !important;
    border: 1px solid #d2d3d0 !important;
    position: relative;
    overflow: hidden;
}

.popOverHeaderMainPost {
    background-color: #f8faf6;
    height: auto;

}

.main-post-mb-0 {
    margin-bottom: -2px !important;
    margin-top: -2px !important;
    color: rgb(99, 99, 99) !important;
}

.alert-border {
    position: absolute;
    display: block;
    top: -50%;
    left: -50%;
    z-index: -1;
    display: block;
    height: 200%;
    width: 200%;
    /* transform: rotate(-20deg); */
    overflow: hidden;
    background: linear-gradient(to right, #f7f9f5 20%, #f7f9f5 40%, #e0f1cf 50%, #d4e4c5 55%, #f7f9f5 70%, #f7f9f5 100%);
    background-size: 200% auto;
    animation: shine 3s linear infinite;
}

div.post-drop-down {
    width: 204px !important;
    font-size: 12px;
    transform: translate(-180px, 27px) !important;
}

.ih-16 {
    height: 14px !important;
}

.setting-dropdown ul {
    padding: 0px !important;
}

.share-via-alert {
    margin-left: -110px
}

/*Begin shimmer code*/

@keyframes shine {
    to {
        background-position: 200% center;
    }
}
@media (min-width: 280px) and (max-width:715px){
    .Mobile-text{
        font-size: 12px !important;
        margin-left: 14px
    }
    .Mobile-text-btn{
        color: white;
        font-size: 13px;
        font-weight: 600;
        position: relative;
        right: -6px!important;
    }
    .Mobile-text-btn-section{
        position: relative;
        right: 0px!important;
    }
}
