/* .create-biz-btn .btn:hover {
    background-color: #28a745;
  } */

/* .biz-cration-preview .btn.btn-primary {
  background: #c4c4c4 !important;
  border: 1px solid #c4c4c4;
  color: #fff !important;
} */
 .Biz_form_input_field1{
  
  height: 102px;
}

.conta {


  flex-shrink: 0;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.10);
  margin-left: 9px;
  margin-right: 9px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.40);
}

@media (max-width: 480px) {

  .conta {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.40);
    margin-left: 9px;
    margin-right: 9px;
  }
}
@media (min-width: 280px) and (max-width: 370px){

  .closeinput {
    position: relative;
    left: 0px !important;
}


}
@media (min-width: 280px) and (max-width: 370px){

  .droup{
    left: 100px !important;
  }


}
@media (min-width: 280px) and (max-width: 529px){



.radies {
  
  right: 4px !important;
}
}

@media (max-width: 380px) {

  .conta {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.10);
    margin-left: 9px;
    margin-right: 9px;
    margin-top: 2rem;
  }
  .closeinput{
    position: relative;
    left:5px ;
  }
}

.Bizcrea {
  position: relative;
  left: 23px;
  top: 6px;
  font-size: 20px;
}

.pys-1{
  position: relative;
    left: 47px;
    
}

.gl{
  position: relative;
  top: 33px;
}
.ml-31{
  position: relative;
  top: -35px;
  left: 123px;
}
.headd{
  position: relative;
  left: 34px;
}
.loca{

}

.closeinput{
  position: relative;
 
}

@media (max-width: 1100px){
  .radies{
    position: relative;
    right: 28px;
   
  }
}

.settinges:hover{
  color: blue;
}
.downarrow{
  position: relative;
  right: -85%;
  top: -30px;
}
.downarrow:hover{
  cursor: pointer;
}

.logess{
  position: relative;
  top: -20px;
}

.savebizpage{
  background-color:#acdad6 !important;
  transition: background-color 0.3s, color 0.3s;
}

.BizCreation_Btn_Fillsection:hover {
  background-color: #81C14B !important; /* Change to the desired hover background color */
  color: white; /* Change to the desired hover text color */
}


.borderbiz{
  border-radius: 5px;
background: #FFF;
box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.20);
}

/* Change the color to black on hover */
.your-link-class:hover .your-svg-class {
  stroke: black;
}


@media (max-width: 758px){
  .gd-content-area-top-blk .people-likes.matual-friend-sec {
    margin: 5px -40px 12px;
}
}

@media (max-width: 800px){
.popov{
  width: 73px;
}
.popmember{
  position:relative ;
  right: 12px;
}
}


.settingsvg:hover{
  color: #81c14b;
}

.previewmember{
  padding: 15px 9px !important;
}

.bizsummary{
  padding: 0.55rem 1rem !important;
}

.settingicon ul li a:hover svg{
  stroke: #81c14b !important;
}

.assignimg{

border: 2px solid #FFF;
background:  lightgray 50% / cover no-repeat;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.weektext{
  color: var(--415047, #000);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
}

.open-text {
  color: green;
}

