
.connection-profile-new {
    width: 60px !important;
    height: 60px !important;
}

.media-new {
    justify-content: center;
    align-items: center;
}

.media-body-new {
    justify-content: space-around;
    flex-direction: column;
}

.popover__message {
    text-align: center;
}

.conn-btn {
    border: none;
}

.mp-head-blk-new {
    align-items: center;
    padding-bottom: 2rem;
}

.mp-head-blk-new>h2 {
    font-size: 1.8rem;
    font-weight: 600;
}

.mp-releted-pro-blk-new {
    position: unset !important;
    align-items: center;
}

.gallery-img-sublink-new>.dropbtn-gal>svg {
    transform: rotate(90deg);
    background: none;
    border: none;
}

.gallery-img-sublink-new {
    background: none;
    border: none;
}

/* card hover css start */
.connection-card {
    background: #FFFFFF !important;
    border: 1px solid #CCCCCC !important;
    border-radius: 10px !important;
    color: #666666 !important;
}

.popover___wrapper {
    position: relative;
    /* margin-top: 1rem; */
    display: inline-block;
}

.popover____content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50px;
    top: -300px;
    transform: translate(0, 10px);
    /* background-color: #FFFFFF; */
    /* padding: 1.5rem; */
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: 300px;
}

.popover____content:before {
    position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -20px;
    width: 300px;
    /* border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #FFFFFF transparent; */
    transition-duration: 0.3s;
    transition-property: transform;
}

.popover___wrapper:hover .popover____content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.8s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}


/* card hover css end */

.name-style {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1.2em !important;
    color: #222222 !important;
}

.user-name-style {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    color: #999999 !important;
}

.contries-scroll {
    max-height: 500px;
    scroll-behavior: smooth;
    /* overflow-y: scroll; */
}
/* =======================add css scroll==================== */
.contries-scroll {
    overflow: hidden;
}
.contries-scroll:hover {
    overflow-y: scroll;
}

.menu-hover-style:hover {
    background: #E5F0E0 !important;
    color: #4D4D4D !important;
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
}

.css-bf9wz-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    background-color: #81c14b !important;
}

.connections_PeopleThisGrid {
    display: grid;
    -ms-grid-columns: (max(30rem, 1fr))[auto-fill] !important;
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr)) !important;
}

.css-13zgp8s.Mui-selected {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    color: rgb(255, 255, 255) !important;
    background-color: #81c14b !important;
}

.css-1a5icme.Mui-checked {
    color: #81c14b !important;
}

.connection-search {
    padding: 3px 10px 3px 15px !important;
    font-size: 1.1em;
    color: #666666;
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    background: #EEEEEE;
    border-radius: 5px;
    border: 'none';
    display: flex !important;
    align-items: center !important;
    outline: none;

}

.css-13zgp8s.Mui-selected:hover {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    background-color: #81c14b !important;
}

.professional-style {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1em !important;
    color: #AAAAAA !important;
}

.green-text-style {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 1em !important;
    color: #81C14B !important;
}

.css-55hfh7-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #81C14B !important;
}

.conention-tab-text {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
}

.dark-heading-style {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1.8em !important;
    color: #4D4D4D !important;
}

.view-more-style {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    color: #999999 !important;
}

.connection-sub-heading {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1.1rem !important;
    color: #666666 !important;
}

.connection-orange-text {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    color: #F1AB59 !important;
}

.connection-red-text {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    color: #F95252 !important;
}

.gray-text-dark {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1em !important;
    color: #666666 !important;
}

.Connection-dropdown {
    width: 250px !important;
    justify-content: center;
    align-items: flex-start;
    background: #FFFFFF;
    box-shadow: 0px 24px 40px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    color: #666666;
}

.connection-dropdown-menu {
    justify-content: center;
    align-items: flex-start;
    background: #FFFFFF;
    box-shadow: 0px 24px 40px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    color: #666666;
}

.connection-cutomize-dropdown-toggle {
    margin: 0px !important;
    padding: 0px !important;
    border: none !important;
    background-color: transparent !important;
    display: inline-flex !important;
    display: -webkit-inline-box !important;
}

.connection-blue-text {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12 !important;
    color: #2F80ED !important;
}

.connection-search-style {
    padding: 3px 10px 3px 15px !important;
    font-size: 1.1em;
    color: #666666;
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    width: 300px;
    background: #EEEEEE;
    border-radius: 5px;
    border: 'none';
    display: flex !important;
    align-items: center !important;
    outline: none;
}

.coonection-input-style {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
}

.list-grid-toggle {
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
}

.grid-toggle-customize-css {
    background-color: #FFFFFF !important;
    color: #81C14B !important;
}

.green-text-dark {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #81C14B;
}


.bg-light-dropdown-toggle {
    padding: 1px !important;
    background: transparent !important;
    border: none !important;
    outline: none !important;
    color: #4D4D4D !important;
}

.light-red-button {
    background: #FFE5E5;
    border-radius: 6px;
    color: #FF0000CC;
    border: none !important;
    outline: none !important;
}

.light-orrange-button {
    background: rgba(241, 171, 89, 0.1);
    border-radius: 6px;
    color: #F1AB59;
    border: none !important;
    outline: none !important;
}

.light-secondary-button {
    justify-content: center;
    align-items: center;
    background: #C4C4C4;
    border-radius: 6px;
    color: #FFFFFF;
    outline: none !important;
    border: none !important;
}

.light-green-button {
    background: rgba(129, 193, 75, 0.2);
    border-radius: 5px;
    border: none !important;
    color: #4d4d4d;
    outline: none;
}

.report-option-text {
    /* font-family: 'Montserrat', sans-serif !important; */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #808080;
}

.modal-scroll {
    min-height: 450px;
    height: 450px;
    overflow-y: scroll;
    margin-bottom: 10px;
}

.modal_scrollconnections .modal-scroll {
    max-height: 350px;
    /* height: 350px; */
    overflow-y: scroll;
    margin-bottom: 10px;
    padding: 0px 5px;
}

.modal-scroll::-webkit-scrollbar {
    display: none;
}

.box-shadow-style {
    display: flex;
    border-radius: 10px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 0 20px;
    transition: box-shadow 1s !important;
}

.box-selected {
    display: flex;
    border-radius: 6px;
    border: 1px solid #81C14B !important;
    margin: 0 0 20px;
    background: rgba(129, 193, 75, 0.1);
    border-radius: 6px;
    position: relative;
    z-index: 1;
}

.box-selected .option-disable {
    position: relative;
    z-index: -1;
}

.box-shadow-style:hover {
    border: 1px solid #81C14B !important;
    background: rgba(129, 193, 75, 0.1) !important;
    border-radius: 6px;
}

.connection_bg {
    background-color: #FFFFFF !important;
}

.btn-transparent {
    display: flex;
    align-items: center;
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal-new {
    transform: translate(530px, 35px) !important;
    width: unset !important;
}

.green-text {
    color: #81C14B !important;
    font-weight: 500 !important;
    font-size: 1em !important;
}

.btn-connection-save-btn {
    background: #EEFBE4;
    border-radius: 5px;
}

.mp-releted-pro-blk-new-new {
    height: 64px;
    font-weight: 600;
}

.conn-menu-dropdown {
    transform: translate(600px, 45px) !important;
}

.conn-menu-dropdown ul {
    display: flex;
    flex-direction: column;
}

.conn-menu-dropdown ul li a {
    background: unset;
    margin: 0;
    padding: 5px 15px;
}

.dropdown-content-new {
    visibility: hidden;
    opacity: 0;
    display: none;
    position: absolute;
    background: white;
    width: 200px;
}

.dropdown-content-new>a {
    background: unset !important;
}

.dropdown:hover .dropdown-content-new {
    visibility: visible;
    opacity: 1;
    z-index: 99;
    display: block;
}

.dropdown:hover .dropdown-content-new>a {
    color: black;
}

.cus-cur-pointer {
    cursor: pointer;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal-new-follower {
    transform: translate(270px, 35px) !important;
    width: unset !important;
}

.sm-size-align-start {
    display: flex;
    margin-right: 19px !important;
    justify-content: end !important;
}

.rem-web-view {
    display: none !important;
}

/* nav css */

.nav-flex-container {
    display: flex !important;
    align-items: center;
    justify-content: space-between !important;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: 6px;
}
.nav-flex-containers {
    display: flex !important;
    align-items: center;
    justify-content: space-between !important;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: 1px;
}
.sub-nav-flex {
    display: flex !important;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.country-drop-menu {
    width: 400px !important;
}

.remove-margin {
    margin: 0rem 0.5rem;
}

/* .remove-btn-padding{
    padding: 0rem 0rem;
    margin: 0 0.5rem;
} */

/* Connection Card Hover css */


/* ----------------- Screen resize media Query ----------------- */

@media screen and (max-width: 992px) {
    .size-sm-mt-4 {
        margin-top: 10px !important;
        align-items: start !important;
    }

    .rem-mob-view {
        display: none !important;
    }

    .flex-mob-view {
        justify-content: space-between !important;
    }

    .rem-web-view {
        display: flex !important;
    }

    .sub-nav-flex {
        padding: 0.5rem 0rem;
    }
}

@media screen and (max-width: 912px) {
    .remove-margin {
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .add-margin {
        margin: 0rem 0.5rem;
    }
}

@media screen and (max-width: 576px) {
    .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal-new {
        transform: translate(220px, 54px) !important
    }

    .connections_PeopleThisGrid {
        display: grid;
        -ms-grid-columns: (max(20, 1fr))[auto-fill] !important;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)) !important;
    }

    .connection-profile-new {
        width: 25px !important;
        height: 25px !important;
    }

    .rem-mob-view {
        display: none !important;
    }

    .size-xs-mt-4 {
        margin-top: 10px !important;
        align-items: start !important;
    }

    .sm-size-align-start {
        display: flex !important;
        justify-content: start !important;
        margin-left: 19px !important;
    }

    .rem-web-view {
        display: flex !important;
    }

    .country-drop-menu {
        width: 300px !important;
    }

}

@media screen and (min-width:439px) {
    .remove-btn-padding {
        padding: 0rem 0rem;
        margin-left: 0.5rem;
    }
}

@media screen and (max-width: 354px) {
    .add-btn-padding {
        margin-top: 0.5rem;
    }
}

@media screen and (max-width : 320px) {
    .rem-mob-view-small{
        display: none !important;
    }

    .margin-small{
        margin-top: 5px;
    }
}
