.swal-overlay {
    background-color: rgba(0,0,0,0.0) !important;
    box-shadow: 10px 20px 36 #888888 !important;
    
}

.swal-overlay--show-modal .swal-modal {
    box-shadow: 5px 10px 40px 12px #dce2dc !important;
    border: 1px solid #caccca !important;
    /* padding-bottom: 24px !important; */
}
/* .swal-title {
    color: rgba(0,0,0,.65);
    font-weight: 600;
    text-transform: none;
    position: relative;
    display: block;
    padding: 13px 16px !important;
    font-size: 27px;
    line-height: normal;
    text-align: center;
    margin-bottom: 0;
} */