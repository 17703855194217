/*  ========================= media query ================ */




@media (max-width : 1070px) {
    .Mp_LocationSearchInput {
        width: 100%;
    }
}

@media (max-width:1500px) {
    .MpFilterValueInput_first {
        width: 55%;
    }

    .MpFilterValueInput_Two {
        width: 45%;
    }

    .MpFilterValueInput_Price_First {
        width: 45%;
    }

    .MpFilterValueInput_Price_two {
        width: 45%
    }


}

/* to manange the cards of the market place on all screen  */

@media (max-width:1851px) {
    .MpGridSystemAuto {
        display: grid;
        -ms-grid-columns: (minmax(120px, 1fr))[auto-fill];
        grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
    }
}

@media (max-width:1300px) {
    .MpGridSystemAuto {
        display: grid;
        -ms-grid-columns: (minmax(120px, 1fr))[auto-fill];
        grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
    }
}


/* Media Query for the responsiveness Of Header  */

@media screen and (max-width: 1154px) and (min-width:567px) {
    .Mp_Deskto_HeaderTabs {
        display: none;
    }

    .MrktCated {
        min-width: 164px;
    }

    .Mp_Mobile_HeaderTabs {
        display: flex;
    }
}

/* Media Query For the Responsiveness of Header on 800px */
@media screen and (max-width: 767px) {
    .MpFormTabs {
        flex-wrap: wrap;
        min-width: 100%;
    }

    .MpFormTabs>span {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Mp_MarketPlaceHeader_Location {
        width: 100%;
    }

    .Mp_LocationSearchInput {
        width: 100%;
    }

    .MrktPlaceHeaderLocation {
        width: 100%;
    }

    .Mp_FormHeader_SideTwo {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 10px;
    }

    .Mp_FormHeaderLocationBar.btn-group {
        width: 100% !important;
    }


}



/* Media Query For the  Header and SingleProduct Page design */
@media screen and (max-width: 576px) {
    .MpGrid_Image_BigScreen {
        display: none;
    }

    .Mp_SingleProductPage_Details {
        margin-top: 15px;
    }

    .Mp_SinglePageHeading_Name {
        font-size: 16px;
    }

    .MpFormTabs {
        flex-wrap: wrap;
        min-width: 100%;
    }

    .MpFormTabs>span {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Mp_Deskto_HeaderTabs {
        display: none;
    }

    .MrktCated {
        min-width: 164px;
    }

    .Mp_Mobile_HeaderTabs {
        display: flex;
    }

}

@media (max-width:992px) {
    .MpGrid_Image_BigScreen {
        display: none;
    }
}



/* media query for the card design on mobile  */

@media (max-width: 768px) {
    .MpGridSystemAuto {
        display: grid;
        -ms-grid-columns: (minmax(100px, 1fr))[auto-fill];
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    }

    .Mp_Card_Bottom_Sec>h3 {
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .MarketPlace_SubCate_Price>h5 {
        font-size: 9px;
    }

    .MarketPlace_SubCate_Price>p {
        font-size: 10px;
    }

    .Mp_Card_Call_Msg_Btn {
        display: none;
    }
    /* .Mp_Card_Call_Msg_Btn1 {
        display: none;
    } */

    .MarketPlace_End_section {
        display: none;
    }

    .MpMobileMarketSvgICon {
        width: 9px;
        height: 9px;
        border-color: white;
    }

    /* .Mp_ThreeDotIcon {
        display: none;
    } */

    .MarketPlace_MrktIconLeft {
        position: absolute;
        top: 14px;
        left: 12px;
        display: flex;
        background: rgba(0, 0, 0, 0.15);
        padding: 6px;
        border-radius: 5px;
        -webkit-backdrop-filter: blur(7px);
        backdrop-filter: blur(7px);
    }

    .MpMobileThreeDotIcon {
        display: block;
        position: absolute;
        top: -1px;
        right: 4px;
    }

    .MpMobileThreeDotIcon .btn-group>.btn {
        padding-right: 0 !important;
        padding-top: 8px !important;
    }

    button.MpDropdownMenuItems.dropdown-item {
        font-size: 10px;
    }

    .MpDropdownOptionMenu.dropdown-menu.show {
        width: fit-content !important;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1) !important;
    }

    .MpDropdownOptionMenu.dropdown-menu.show {
        transform: translate3d(-124px, 28px, 0) !important;
    }

    .MpDropdownOptionMenu2.dropdown-menu.show {
        transform: translate3d(-124px, 28px, 0) !important;
    }

    .MarketPlaceCard_RightEye {
        position: absolute;
        display: flex;
        bottom: 8px;
        left: 8px;
        background: rgba(0, 0, 0, 0.15);
        padding: 2px;
        border-radius: 5px;
        -webkit-backdrop-filter: blur(7px);
        backdrop-filter: blur(7px);
    }

    .MarketPlaceCard_RightEye p {
        color: #fff;
        font-weight: 500;
        font-size: 9px;
    }

    .MpDesktopEye {
        display: none;
    }

    .MpMobileEye {
        display: block;
    }

    .MpnoneDisplay {
        display: grid;
    }

    .Mp_ListViewCard_DisplayMange {
        display: none;
    }

    /* media query for the mobile view of Header */
    .Mp_Mobile_HeaderTabs {
        display: none;
    }

    button.MpMobileViewButton_Manage.btn.btn-outline-primary {
        padding: 2px 5px !important;
        display: none;
    }

    button.MpMobileViewButton_sell.btn.btn-outline-primary {
        padding: 5px 10px !important;
        /* border: none; */
        font-size: 12px !important;
    }

    .button.MpMobileViewButton_sell.btn.btn-outline-primary:hover {
        background-color: transparent !important;
        color: #81C14B !important;
    }

    .MrktPlaceSearch {
        padding: 13px 10px;
    }

    .marketPlace_srch_dropMenu.dropdown-menu.show {
        transform: translate3d(-20px, 48px, 0px) !important;
    }

    .MpCardsMainCardContainer {
        padding: .5rem;
    }

    .marketplaceCardSec {
        padding: 0rem;
    }

    .marketplaceCardSec {
        border: none !important;
    }

    .marketplaceCardSec2 {
        padding: 0rem;
        height: 350px;
    }

    .marketplaceCardSec2 {
        border: none !important;
    }




    /* list View and Grid view hide */
    .listViewAndGridViewHide {
        display: none;
    }
}



/* media query for the filter and sortby ==== */

@media screen and (max-width:1199px) {
    /* .MpFilterSortByMobile {
        display: block;
        width: 40%;
    } */

    .MpFilterSortBy_Desktop {
        display: none;
    }

    .MpFilterAndSortByMobile {
        display: flex;
    }
}




@media (max-width:992px) {
    .MarketPlace_MrktIconLeft_SingleProductPage {
        top: 25px;
    }
    .MarketPlace_MrktIconLeft_SingleProductPage1 {
        top: 25px;
    }

    .mpListViewImageWidth {
        min-width: 7vw;
        min-height: 7vh;
    }

    .MpCardListView {
        /* max-height: 16vh; */
        display: grid;
        grid-template-columns: 16% 36% 15% 13% 18% 2%;
        grid-template-areas: "image name category price seller ThreeDotMenu";
        place-items: center;
        box-sizing: content-box;
        overflow-clip-margin: 10px;
    }

}