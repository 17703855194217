.hashTagPlaceholder {
    border: 1px solid rgb(148, 148, 148) !important;
    border-radius: 4px !important;
}

.hashTagHead {
    font-weight: 600;
}

.emojiSearch {
    width: 100% !important;
    border: 1px solid rgb(206, 206, 206);
    padding: 4px;
    margin-top: 6px !important;
    margin-bottom: 22px !important;
    border-radius: 4px !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    /* color: #03ca0d !important; */
    color: #81C14B !important;
    /* font-weight: 600; */
    font-weight: 400;
    font-size: 16px;
    /* background-color: #ffffff;
    /* border-color: #dee2e6 #dee2e6 #ffffff; * comment this line deepak and i am inform rashid sir and sourav sir  */
}

/* .locationHeads{
    display: flex;
} */


.locationHeadsRight {
    color: #81c14b;
    font-size: 12px !important;
    font-weight: 600;
}

.remove {
    color: #81c14b;
    font-size: 12px !important;
    font-weight: 600;
}

.locationImg {
    margin-top: 0px !important;
    color: #03ca0d;
    font-size: 12px !important;
    font-weight: 600;
}

.locationDetails {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.locationPosition {
    position: relative !important;
    top: 0% !important;
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
    left: 13px !important;
}

.toggle-sec .toggle-btn .knobs span {
    left: 4px;
    /* background-color: #81c14b !important; */
    z-index: 1;
}

.toggle-sec .toggle-btn .checkbox:checked+.knobs span {
    left: 42px;
    /* background-color: #bfbebe !important; */
    color: black !important;
}