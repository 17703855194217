.shotz-top-heading {
  /* font-family: 'Montserrat', sans-serif !important; */
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 1.2rem !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

.modal-container-for-shortz {
  background-color: rgba(255, 0, 0, 0.859);
  display: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.shotz-search-style {
  padding: 8px 10px 8px 15px !important;
  font-size: 15px;
  color: #666666;
  /* font-family: 'Montserrat', sans-serif !important; */
  font-style: normal !important;
  font-weight: 500 !important;
  width: 350px;
  background: #eeeeee;
  border-radius: 5px;
  border: "none";
  display: flex !important;
  align-items: center !important;
  outline: none;
}

.shotz-text {
  /* font-family: 'Montserrat', sans-serif !important; */
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1rem !important;
  color: #ffffff !important;
}

.close-icon {
  position: absolute;
  right: -75px;
  color: #ffffff !important;
  cursor: pointer;
}

.shotz-menu-hover-style:hover {
  background: #e5f0e0 !important;
  color: #4d4d4d !important;
  /* font-family: 'Montserrat', sans-serif !important; */
  font-style: normal !important;
  cursor: pointer !important;
  font-weight: 500 !important;
}

.selected-shotz-style {
  border: 2px solid #81c14b;
  border-radius: 5px !important;
}

.videoWrapper {
  width: 100% !important;
  max-height: 100vh !important;
  min-height: 100vh !important;
  object-fit: cover !important;
  background-size: cover !important;
}

.Media_header {
  background: rgba(246, 237, 237, 0.5);
}

.light-green-button {
  background: #ffe5e5;
  border-radius: 6px;
  color: #caffcecc;
  border: none !important;
  outline: none !important;
}

.user-media .user-img img {
  width: 55px;
  height: 55px;
  border-radius: 10px;
  object-fit: cover;
}

.shotzs-img {
  background-size: cover !important;
  border: none !important;
  outline: none !important;
  height: 100% !important;
}

.shotz-box-selected {
  background: #ffffff;
  border: 3px solid #81c14b !important;
  padding: 2px !important;
  border-radius: 5px;
}

.shotz-box {
  border: 3px solid #ffffff !important;
  padding: 2px !important;
  border-radius: 5px;
}

.img-gradient {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.54) 0%,
    rgba(0, 0, 0, 0) 35.42%,
    rgba(0, 0, 0, 0) 61.98%,
    rgba(0, 0, 0, 0.72) 100%,
    rgba(0, 0, 0, 0.72) 100%
  ) !important;
}

.comment-bttn-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  margin-bottom: 30px !;
  background-color: red;
  color: white;
  text-align: center;
}

.grid-toggle-customize-css {
  background-color: #dfe3dd !important;
  color: #4d4d4d !important;
}

.img-container {
  position: relative !important;
}

.shotz_center {
  top: 30%;
  /* width: 100%; */
  height: 30px !important;
  width: 30px !important;
  position: absolute !important;
  display: flex;
  right: 30%;
  justify-content: center;
  align-items: center;
}

.archive-btn {
  stroke: #ffffff !important;
  fill: #ffffff !important;
  color: #ffffff !important;
}

.lable_style {
  font-size: 13px !important;
}

.img-shotz-container {
  position: relative;
  text-align: center;
  color: white;
}

.img-text-centered {
  position: absolute;
  text-align: center;
  left: 100px;
  top: 75px;
}

.align-right-ab {
  position: absolute !important;
  right: 8px !important;
  top: 15px;
  color: #4d4d4d;
}

.center-shotz-cont {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
}

.center-shotz-cont img {
  width: 70px;
}

.shotz-border {
  border-bottom: 30px solid rgba(35, 24, 24, 0.822);
}

.shotz-conatiner-vertical-Scroll {
  scroll-behavior: smooth !important;
  height: 100vh !important;
  overflow-y: scroll !important;
  scroll-snap-type: y mandatory !important;
}

.explore-btn:hover svg {
  stroke: #ffffff !important;
  fill: #ffffff !important;
  color: #ffffff !important;
}

.scroll-share-com {
  overflow: scroll;
  scroll-behavior: smooth;
  height: 350px;
}

/* shotz video css */
.stotz-video-style {
  height: 920px !important;
  object-fit: fill !important;
}

.carousel-container {
  height: fit-content;
  scroll-behavior: smooth;
  object-fit: cover !important;
}

/* shotz card hover css */

.shotz-card-hover {
  display: grid;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
}

.shotz-card-hover > * {
  grid-area: 1/1;
  border-radius: 5px;
  transition: 0.4s;
}

.shotz-card-hover figcaption {
  display: grid;
  align-items: end;
  /* font-family: sans-serif; */
  font-size: 2.3rem;
  font-weight: bold;
  color: #0000;
  border-radius: 5px;
  background: var(--c, #0009);
  clip-path: inset(0 var(--_i, 100%) 0 0);
  --webkit-mask: linear-gradient(#000 0 0), linear-gradient(#000 0 0);
  --webkit-mask-composite: xor;
  --webkit-mask-clip: text, padding-box;
}

video::-webkit-media-controls-start-playback-button {
  display: none;
}

.shotz-card-hover:hover figcaption {
  --_i: 0%;
}

.shotz-card-hover:hover img {
  transform: scale(1.2);
}

.bottom_alignMent-Footer {
  position: absolute;
  bottom: 10px;
  /* width: 370px; */
}

.scrollSuggestion {
  height: 300px;
  scroll-behavior: smooth;
  overflow-y: auto;
}

/* nav css */

.nav-flex-container {
  display: flex !important;
  align-items: center;
  justify-content: space-between !important;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.sub-nav-flex {
  display: flex !important;
  align-items: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* shotz share modal css */

.shotz-share-modal {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 5px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.shotz-share-modal img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border: 2px solid #ffffff;
  border-radius: 50%;
  margin-right: 10px;
}

.shotz-share-modal {
  display: block;
}

.top-shotz-cont {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  left: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}

/* video css */

@supports not (-webkit-mask-clip: text) {
  .shotz-card-hover figcaption {
    --webkit-mask: none;
    color: #fff;
  }
}

.PositionThiscenter {
  position: absolute;
  top: 18px;
  left: 26px;
  font-size: 18px;
}

.PositionThistopright {
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 18px;
}

.VideoFitforcover {
  height: calc(100vh - 56px);
  object-fit: cover;
}

.ghcvewcwevc {
  transform: translate(1px, 20px) !important;
}

.VideoFitzfocus.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #ffffff;
  background-color: #e74e2500 !important;
  border-color: #565e64 !important;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.VideoFitzfocus.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #ffffff;
  background-color: #e74e2500 !important;
  border-color: #565e64 !important;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.obj-container.dropdown-menu.show {
  width: fit-content !important;
}

.myModalTransparent .modal-content {
  background: transparent;
  border: none;
}

.comment-col {
  transition: all 0.3s;
  transition-timing-function: ease-out;
}

.comment-col-close {
  transition: all 0.3s;
  transition-timing-function: ease-in;
}

.simple-linear.open {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), transparent);
  z-index: 2;
  border-radius: 10px;
  transition: all 0.5s;
  transition-timing-function: ease-out;
}

.simple-linear.close {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), transparent);
  z-index: 2;
  border-radius: 1rem 1rem 0 0;
  transition: all 0.5s;
  transition-timing-function: ease-out;
}

.bottom-linear.open {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);
  z-index: 2;
  border-radius: 0 0 0 1rem;
  transition: all 0.5s;
  transition-timing-function: ease-out;
}

.bottom-linear.close {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);
  z-index: 2;
  border-radius: 0 0 1rem 1rem;
  transition: all 0.5s;
  transition-timing-function: ease-out;
}

.gradient-overlay {
  position: relative;
  display: inline-block;
}

.gradient-overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 19.79%,
      rgba(0, 0, 0, 0) 85.42%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    linear-gradient(360deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 49.78%);
}

.scrollForShareShotz {
  overflow-y: scroll;
  max-height: calc(100vh - 300px);
}

.ShotzRightSlider {
  vertical-align: bottom;
  position: absolute;
  bottom: 0px;
  right: -80px;
  z-index: 100;
}

.hide-on-web {
  display: none;
}

.modal.fade.show {
  backdrop-filter: brightness(1.1); /* Adjust the brightness value as needed */
}

.bottom-gallery-cont.padEdit {
  padding: 100px 20px 20px 20px;
  margin-bottom: 6px;
}

.my-card {
  transition: transform 0.5s ease-in-out;
  transform: translateX(0);
}

.my-card.open {
  transform: translateX(0);
}

.my-card.closed {
  transform: translateX(-100%);
}

.video-container {
  /* height: 400px;
  overflow-y: scroll;
  scroll-snap-type: y mandatory; */
  scroll-snap-type: y mandatory;
  flex-basis: 50%;
  scroll-snap-stop: always;
  overflow-y: scroll;
}

.video-item {
  /* scroll-snap-align: start;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; */

  scroll-snap-align: start;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.comment-closer {
  cursor: pointer;
  color: #fff !important;
  padding: 30px 3px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px 0 0 10px;
  position: absolute;
  right: 12px;
  top: 45%;
}

.cmnt-close-btn {
  position: relative;
}

.cmnt-close-btn ::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

button.padding-for-shotz.btn.btn-primary {
  padding: 7px 14px !important;
  height: fit-content !important;
}
.scroll-container {
  overflow-y: scroll; /* Enable vertical scrolling */
  overflow-x: hidden;
  white-space: nowrap;
  scroll-snap-type: y mandatory; /* Enable snapping to each child element */
  scroll-behavior: smooth; /* Smooth scrolling behavior */
}
.scroll-container::-webkit-scrollbar-track {
  background-color: #ffff0000;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #ffff0000;
}
.scroll-container .modal-fade {
}
.scroll-item {
  scroll-snap-align: start; /* Snap each item to the top */
}
.vcontainer {
  overflow: auto;
  padding-right: 100px;
  padding-left: 15px;
}

@media (max-width: 992px) {
  .hide-on-web {
    display: block;
  }
  .close-icons {
    position: absolute !important;
    right: 4px !important;
    z-index: 5 !important;
    top: 6px !important;
  }
}

@media (max-width: 768px) {
  .ShotzRightSlider {
    vertical-align: bottom;
    position: absolute;
    bottom: 0px;
    right: 1rem;
  }
  .vcontainer {
    padding-right: 12px;
    padding-left: 12px;
  }
  .close-icons {
    position: absolute !important;
    right: 4px !important;
    z-index: 5 !important;
    top: 6px !important;
  }
}

.SortsUpDownButton {
  position: fixed;
  right: -75px;
  z-index: 100;
  height: 93vh;
  top: 0;
}

/* .SortsUpDownButton {
  position: fixed;
  right: -75px;
  z-index: 100;
  height: 94vh;
  top: 0;
} */

.animateClose {
  animation: closeComment 0.3s ease !important;
  width: 0px !important;
}

@keyframes closeComment {
  from {
    width: 100%;
  }

  to {
    width: 0px;
  }
}
.shutter-container {
  overflow: hidden;
  height: auto;
  max-height: 0;
  transition: max-height 0.3s ease-in-out; /* Initial duration */
}

.shutter-container.slow {
  transition-duration: 2.5s; /* Slower duration when 'slow' class is added */
}

.shutter-content {
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
}
.comment-closer {
  display: block;
  opacity: 1;
  transition: opacity 0.5s ease-in-out, transform 0s ease-in-out; /* 0s transition for transform */
  transform: translateX(0);
}

.comment-closer.close {
  display: block;
  opacity: 1;
  transform: translateX(-100%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* Apply the transition only for close */
}


/* For mobile devices (e.g., screens with a maximum width of 767px) */
@media (max-width: 767px) {
  .manage-text {
    /* height: 30px; */
    width: 140px;
  }
  .manage-texts {
    /* height: 30px; */
    width: 137px;
  }
  /* margin-left: -7px; */
}

.Archive-text-btn{
   margin-left: -5px;
}

@media (min-width: 1024px)  {
  .close-icons {
    position: absolute !important;
    right: -14rem !important;
    z-index: 5 !important;
    top: 6px !important;
  }
 
}
/* Define the custom margin-top for screens with a resolution of 1024x1366 */
@media screen and (max-width: 1200px) {
  .select-text-buttons {
    margin-top: 12px;
  }
}

