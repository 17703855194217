.marginForSlider{
    margin-top: 0px !important;
}
.slider1{
    margin-top: 14px !important;
}
.marginForRange{
    color: rgb(77, 77, 77);
    font-weight: 500;
    font-size: 13px;
    margin-top: -22px !important;
}

  
 

