.FilterThisButton {
  padding: 10px 15px;
  color: #808080;
  /* background: rgba(129, 193, 75, 0.2); */
  border-radius: 5px;
  display: block;
  transition: 0.5s;
}
.connection-input-style {
  white-space: pre-wrap;
}

.bottomleft {
  position: absolute;
  bottom: 0px;
  font-size: 18px;
  color: #ffffff;
  background: rgba(2, 2, 2, 0.135);
  width: 100%;
  display: flex;
  border-radius: 10px;
}

.bottomleft.rem-round {
  position: absolute;
  bottom: 0px;
  font-size: 18px;
  color: #ffffff;
  background: rgba(2, 2, 2, 0.135);
  width: 100%;
  display: flex;
  border-radius: 0 0 10px 10px;
}

.PeopleThisGrid {
  display: grid;
  -ms-grid-columns: (minmax(200px, 1fr)) [auto-fill];
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.TopTitleThis {
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
}

.topleft {
  position: absolute;
  top: 14px;
  font-size: 15px;
  left: 12px;
}

.roundedThisBorder {
  border-radius: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.RecommendationThisawesome {
  background: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  padding: 11px 6px;
}

.numformattercolorPostion {
  position: absolute;
  bottom: 0;
  /* left: 2px; */
  font-size: 18px;
  width: 100%;
}

.backGroundThisColor {
  background: rgba(255, 255, 255, 0.9);
}

.topright {
  position: absolute;
  top: 14px;
  right: 16px;
  font-size: 18px;
}

.CountLeft {
  position: absolute;
  top: 15px;
  font-size: 15px;
  left: 76px;
  display: flex;
  opacity: 1.4;
}

.EventThisbutton {
  position: absolute;
  width: 100%;
  bottom: 14%;
  border-radius: 0 0 10px 10px;
}

.EventThisbuttonTitle {
  position: absolute;
  width: 100%;
  bottom: 17%;
  border-radius: 0 0 10px 10px;
}

.CricleThisFill {
  position: absolute;
  left: 50px;
  top: 70px;
}

.TagThisTopVideo {
  position: absolute;
  top: 36%;
  left: 39%;
  text-align: center;
}

.NumThisPeople {
  position: absolute;
  left: -1px;
  top: 70%;
  font-weight: 500;
  font-size: small;
  width: 100%;
  text-align: center;
  font-weight: 500;
  color: #000000;
  /* background-color: rgba(255, 255, 255, 0.456); */
}

.CategoryThisText {
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
}

.PaidThisColor {
  position: absolute;
  transform: rotate(0deg);
  width: 218px;
  height: 75px;
  right: -119px;
  bottom: 24px;
}

.EventThisTextFree {
  position: absolute;
  width: 100%;
  top: 30%;
  left: 10px;
  border-radius: 0 0 10px 10px;
}

.light-green-People {
  background: #81c14b;
  border-radius: 6px;
  color: #f8f9fa;
  border: none !important;
  outline: none !important;
}

.EventThisTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #4d4d4d;
}

.FreeThisText {
  border: 1px solid #ffffff;
  border-radius: 14px;
  color: #ffffff;
}

.Eventright {
  position: absolute;
  top: 8px;
  right: -2px;
  font-size: 16px;
}

.Eventleft {
  position: absolute;
  top: 1px;
  font-size: 18px;
  color: #ffffff;
  background: rgba(2, 2, 2, 0.135);
  width: 100%;
  display: flex;
}

.EventbodyThis {
  position: relative;
}

.CardbodyThis {
  position: relative;
}

.UncontrolledButtonDropdownthis {
  position: absolute;
  left: 269px;
  top: 30px;
}

.CountriesThisFontColor {
  font-weight: 400;
  font-size: 14px;
  color: #81c14b;
  padding-left: 1%;
}

.CounTriesTextColor {
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
  gap: 10px;
  margin: 1%;
}

.CountriyThisFilter {
  margin-right: 10rem;
}

.TrendingPeoplethistext {
  font-weight: 600;
  font-size: 13px;
  color: #4d4d4d;
}

.FollwerThisFontsize {
  font-weight: 400;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.FollwerThisFontsize {
  font-family: "Montserrat", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #81c14b !important;
}

.RecentThisText {
  color: rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  padding-top: 3%;
}

.backgroundColorThiswhite img {
  opacity: 0.5;
}

.PeopleThisGrid {
  display: grid;
  -ms-grid-columns: (minmax(200px, 1fr)) [auto-fit];
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  /* grid-gap: 1px; */
}

.padingThisblock {
  padding-left: 0px;
}

.TrendingPeoplethistexttwo {
  padding-left: 2%;
  padding-top: 1%;
  font-weight: 500 !important;
  font-size: 15px !important;
  color: #222222 !important;
}

.TrendingTagsBorders {
  background: rgba(129, 193, 75, 0.05);
  border-top: 2px solid rgba(129, 193, 75, 0.5);
  border-radius: 0px 0px 20px 20px;
  width: 55px;
  height: 59px;
}

.PeopleFollowersThis {
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 0.01em;
  font-weight: 600;
  font-size: 13px;
}

.PilesThistags {
  font-weight: 500;
  font-size: 12px;
  color: #81c14b;
  /* padding: 9px;
  padding-top: 12px; */
}

.MsgThisIcon .active svg,
.MsgThisIcon :hover svg {
  stroke: #f6f7f8;
}

.SeemoreThisTrendiingPeople {
  background: rgba(129, 193, 75, 0.05);
  border-radius: 10px;
  width: 232px;
  color: rgba(129, 193, 75, 0.7);
  height: 44px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  padding-top: 12px;
}

.GridButtonView {
  color: #ffffff;
  background-color: #81c14b;
}

.Thisiscaptiontext {
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 77%;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  color: #000000;
  height: 40px;
}

.TagThisButtonVideo {
  position: absolute;
  top: 42%;
  left: 38%;
  text-align: center;
}

.TagThisTopButtonVideo {
  position: absolute;
  top: 49%;
  left: 38%;
  text-align: center;
}

.ThisssscaptionVideo {
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 74%;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  color: #000000;
  height: 40px;
}

.ThisiscaptionVideo {
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 76%;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  color: #000000;
  height: 40px;
}

.TagTrendingTitleThisbuttonVideo {
  /* background-color: #e9e9e9; */
  position: absolute;
  width: 100%;
  bottom: 0%;
  border-radius: 0 0 10px 10px;
}

.TrendThisPlayButton {
  position: absolute;
  left: 46px;
  top: -5px;
}

.MicThisPosgition {
  position: absolute;
  left: 43px;
  top: -8.5px;
}

.TrendThisTopP {
  position: absolute;
  top: 6px;
}

.MediaThisVideo {
  position: absolute;
  top: 10%;
  left: 2%;
  text-align: center;
}

.Goliveleft {
  position: absolute;
  top: 41px;
  font-size: 8px;
  left: 30px;
}

.MicThisImg {
  position: absolute;
  top: 58px;
  left: 48px;
}

.BizPageCategory {
  font-weight: 500;
  font-size: 12px;
  color: #4d4d4d;
  letter-spacing: 0.01em;
}

.GridThreatThisText1 {
  position: absolute;
  top: 130px;
  right: 8px;
  background: #ff822e;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.divcolorThis {
  position: absolute;
  bottom: 8px;
  background: #ffffff;
  opacity: 0.5;
}

.GridThreatThisText2 {
  position: absolute;
  top: 130px;
  right: 8px;
  background: #ffd600;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.GridThreatThisText3 {
  position: absolute;
  top: 130px;
  right: 8px;
  background: #ff822e;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.EntertainmentThisText {
  background: rgba(19, 18, 18, 0.2);
  border: 1px solid #ffffff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 11px !important;
  text-align: center;
  width: 110px;
  height: 27.33px;
  padding-top: 5px;
}

button.btn.btn-primary.ColorThisButtonpadding.btn-xs {
  padding: revert;
  text-transform: capitalize;
  font-size: calc(11px + (12 - 11) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
}

.ColorThisButtonpadding svg :hover {
  color: white !important;
}

.xxyyzz:hover {
  color: #fff !important;
  background-color: #81c14b !important;
  border-color: #81c14b !important;
  stroke: #ba1c1c;
  fill: #ba1c1c;
}

.msg-btn {
  stroke: #ffffff !important;
  fill: #ffffff !important;
  color: #ffffff !important;
}

.MusicThisIcon {
  position: absolute;
  /* align-items: center; */
  text-align: center;
  left: 62px;
  top: 82px;
}

.msgThisiconfont {
  font-weight: 600;
}

.css-ryrseu-MuiRating-root {
  /* display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox; */
  display: inline-flex;
  position: relative;
  /* font-size: 1.5rem; */
  color: #faaf00;
  /* cursor: pointer; */
  text-align: left;
  -webkit-tap-highlight-color: transparent;
  /* pointer-events: auto !important; */
}

.RatingThisWidth {
  font-size: 2rem !important;
  padding-top: 7px;
}

.Video_Treanding_card {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
  padding-top: calc(var(--bs-gutter-x) / 2);
  padding-bottom: calc(var(--bs-gutter-x) / 2);
}

.Potrate_treanding_img {
  position: relative;
}

.BizpageThisimg {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px 5px 0px 0px;
}

.Potrate_treanding_img img {
  object-fit: cover;
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.Treanding_caption_section {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: rgb(13, 13, 13);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MicThisNum {
  display: flex;
  position: absolute;
  top: 7%;
  left: 5%;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.TreandingThisGame {
  align-items: flex-start;
  padding: 12px 20px;
  background: rgba(0, 0, 0, 0.6);
  /* height: 65px; */
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.TreandingThisGames {
  align-items: flex-start;
  padding: 12px 20px;
  /* background: rgba(0, 0, 0, 0.6); */
  /* height: 65px; */
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.TreandingThisGamess {
  align-items: flex-start;
  padding: 6px 20px;
  background: rgba(0, 0, 0, 0.6);
  /* height: 65px; */
  border-radius: 10px 10px 0px 0px;
  color: #ffffff;
  position: absolute;
  top: 10px;
  width: 100%;
  height: 4.2rem;
}
.TreandingThisGamesss {
  align-items: flex-start;
  padding: 6px 20px;
  background: rgba(0, 0, 0, 0.6);
  /* height: 65px; */
  border-radius: 10px 10px 0px 0px;
  color: #ffffff;
  position: absolute;
  top: 6.5rem;
  width: 400px; /* You can adjust this value as needed */
  height: 4.3rem;
}

.TreandingThisEvent {
  align-items: flex-start;
  padding: 5px 20px;
  background: rgba(0, 0, 0, 0.6);
  /* height: 71.11px; */
  color: #ffffff;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.TreandingRecommendations {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  height: 50.11px;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  padding: 5px;
  width: 100%;
  gap: 1px;
}

.short-recommendation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  height: 50px;
  color: #ffffff;
  margin-top: "5px";
  /* position: absolute; */
  /* bottom: 0; */
  padding: 5px;
  width: 100%;
  gap: 1px;
}

.ModalThisSize .modal-content {
  width: 71% !important;
}

.PaidThisImg {
  position: absolute;
  top: -52%;
  right: 0%;
}

.APPLYThisWidth {
  width: 24%;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 1px !important;
  pointer-events: none;
  border-style: solid;
  border-width: 0px !important;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.ChooseThisTrendingSong {
  background: #f7f7f7;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  font-weight: 600;
}

.Treanding_bottom_section {
  width: 100%;
  height: 64.56px;
  background: #f7f5f3;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* .diagonalthisgradient {
  background-color: #362f2f !important;
  width: 100% !important;
  height: 46% !important;
  border-radius: 5px !important;
} */

.diagonalthisgradient img {
  opacity: 0.2;
  /* background: radial-gradient(circle at center, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)); */
}

.Treanding_icon_section_forCpunt {
  background: rgba(0, 0, 0, 0.25);
  /* padding: 8px 11px; */
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(5%);
  margin-right: 6px;
  cursor: pointer;
}

.Treanding_icon_liked {
  background: #81c14b;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(5%);
  margin-right: 8px;
  cursor: pointer;
}

.Settings_tab_top_data {
  margin-right: 85px !important;
}

/* .polluThisserimg {
  transform: translateY(5px);
} */

.scrollbartopandtrend {
  height: 304px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.ArticleShowdo {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.TabthisMediaQurey {
  width: 100%;
}

.circularCorner {
  border-radius: 4px 4px 4px 4px;
}

.image-with-gradient {
  position: relative;
  display: inline-block;
}

.image-with-gradient:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 19.79%,
      rgba(0, 0, 0, 0) 85.42%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    linear-gradient(360deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 49.78%);
  border-radius: 4px;
}

.gallery-img-sublink-this-bizpage-shotz {
  position: absolute;
  right: -10px !important;
  z-index: 2 !important;
}

.bizpagefordropdownmenu.tops-dropdown {
  transform: translate3d(-130px, 60px, 10px) !important;
}

.on-hover-bg:hover {
  background-color: #80c14b35 !important;
  cursor: pointer;
  transition: 0.3s all;
}

.on-hover-bg-border:hover {
  background-color: #aaf07235 !important;
  border-color: #81c14b !important;
  cursor: pointer;
  transition: 0.5s all;
}

.tabs-top-trending {
  background-color: #e6f3db;
  border-radius: 5px;
  /* width: 211px; */
}

.slick-slide.slick-active.auto-wid {
  width: fit-content;
}

.right-triangle {
  width: 0;
  height: 0;
  border-right: 50px solid transparent;
  border-left: 1px solid #81c14b;
  border-top: 50px solid #81c14b;
}

.rotated-text {
  transform: rotate(315deg);
}

.trending-number-location {
  position: "absolute";
  top: "8px";
  left: "4px";
  z-index: "5";
  font-size: "12px";
}

.trending-triangle {
  position: "absolute";
  top: "0px";
  z-index: "4";
  left: "0";
}

.width-for-dropdown {
  width: fit-content !important;
  z-index: 10;
}

.date-time-fs {
  font-size: calc(
    11px + (14 - 13) * ((100vw - 320px) / (1920 - 320))
  ) !important;
}

.BizPageCategory.match {
  font-weight: 600;
  font-size: 12px;
  color: #4d4d4d;
  letter-spacing: 0.01em;
}

.BizPageCategory.count {
  font-weight: 600;
  font-size: 13px;
  color: #4d4d4d;
  letter-spacing: 0.01em;
}

.poll-text {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: rgba(77, 77, 77, 1);
}

.user-profile:hover {
  text-decoration: underline;
  cursor: pointer;
}

.text-muted.light-for-date {
  color: rgba(0, 0, 0, 0.5) !important;
}

.bizpagefordropdownmenu.dropdownLocation {
  transform: translate3d(-180px, 43px, 10px) !important;
}

.bizpagefordropdownmenu.dropdownLocation.dropdown-for-user-specific {
  transform: translate3d(-160px, 43px, 10px) !important;
}

.video {
  object-fit: cover;
}

.marquee-wrapper {
  width: 100%;
  padding: 0px 20px;
}

.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.for-scroll {
  max-height: "150px";
  overflow-y: "auto";
}

@media only screen and (max-width: 375px) {
  .TabthisMediaQurey {
    width: max-content;
  }
}

@media screen and (max-width: 1200px) {
  .rem-beyond-web {
    display: none !important;
  }
}
.rounded-top-right {
  border-top-right-radius: 10px; /* Adjust the value to control the roundness */
}
/* ==========================text add css======================== */
.text {
  display: flex; /* Use Flexbox to control layout */
  font-size: 13px;
}

.gray-text {
  color: #4d4d4d;
}
.post {
  font-size: 13px;
}
/* ===============================375 and 667======================== */
@media (min-width: 375px) and (min-height: 667px) {
  .filterbtn {
    margin-top: -26px !important;
    padding-left: 10px !important;
  }
}
/* ===============================360 and 740======================== */
@media (min-width: 360px) and (min-height: 740px) {
  .filterbtn {
    margin-top: -26px !important;
    padding-left: 10px !important;
  }
}
@media (min-width: 360px) and (min-height: 740px) {
  .svgtext {
    margin-top: -2.7px !important;
  }
}

/* ===============================820 and 1180======================== */
@media (min-width: 820px) and (min-height: 1180px) {
  /* .filterbtn {
    margin-top: -5px !important;
    padding-left: 10px !important;
  } */
  .toptext {
    text-wrap: nowrap;
  }
  .ArticleShowdos {
    display: block !important;
    /* margin-top: -5.8rem !important; */
  }
}

/* ===============================970 and 564======================== */
@media screen and (min-width: 970px) and (min-height: 564px) {
  .topepisode {
    margin-left: 11rem !important;
  }
  .profiles {
    height: 5rem !important;
  }
}

/* ===============================412 and 892======================== */
@media (min-width: 412px) and (min-height: 892px) {
  /* .Recom {
    margin-left: -2rem !important;
  }
  .trand {
    margin-right: 9rem !important;
  } */
  .morehorizontal {
    display: flex !important;
    justify-content: end !important;
  }
  .toptexts {
    margin-left: -9rem !important;
  }
}
/* ===============================375 and 667======================== */
@media (min-width: 375px) and (min-height: 667px) {
  .toptexts {
    margin-left: -1rem !important;
  }
  .topepisode {
    margin-left: 10.5rem !important;
  }
  .country-section {
    top: 50px !important;
    z-index: 100 !important;
    left: -12rem !important;
  }
  .country-sections {
    top: 4rem !important;
    z-index: 100 !important;
    left: -16rem !important;
  }
}
/* ===============================360and 740======================== */
@media (min-width: 360px) and (min-height: 640px) {
  .country-section {
    top: 4rem !important;
    z-index: 100 !important;
    left: -14rem !important;
  }
}
@media (max-width: 375px) {
  .button-text {
    display: none;
  }
  .audio-img {
    font-size: 10px;
  }
  .filterbt {
    width: 20px !important;
    margin-top: -2rem !important;
  }
  .filtericon {
    margin-left: -8px !important;
  }
}
@media (max-width: 414px) {
  .button-text {
    display: none;
  }
  .filterbt {
    width: 20px !important;
    /* margin-top: -4rem !important; */
  }
  .filtericon {
    margin-left: -8px !important;
  }
  .textpoll {
    height: 8rem !important;
  }
  .topepisode {
    margin-left: 10.5rem !important;
  }
  .country-section {
    z-index: 100 !important;
    top: 6rem !important;
    left: -6rem !important;
  }
  .Countrynametext {
    margin-left: -6rem !important;
    margin-top: 2rem !important;
    /* position: fixed !important; */
  }
  .textdrop {
    margin-top: 2rem !important;
    /* position: fixed !important; */
    left: 1rem !important;
  }
}
@media (max-width: 414px) {
  .button-text {
    display: none;
  }
  .filterbt {
    width: 20px !important;
    margin-top: -3rem !important;
  }
  .filtericon {
    margin-left: -8px !important;
  }
  .textpoll {
    height: 8rem !important;
  }
  .topepisode {
    margin-left: 10.5rem !important;
  }
  .country-section {
    z-index: 100 !important;
    top: 6rem !important;
    left: -6rem !important;
  }
  /* .Countrynametexts {
    margin-left: -9rem !important;
    margin-top: 2rem !important;
    position: fixed !important;
  } */
  .textdrops {
    margin-top: 2rem !important;
    /* position: fixed !important; */
    left: 1rem !important;
  }
}
/* ===============================820and 1180======================== */
@media (min-width: 820px) and (min-height: 1180px) {
  /* .country-section {
    top: 4rem !important;
    z-index: 100 !important;
    left: -7rem !important;
  } */
  .textpoll {
    height: 8rem !important;
  }
  .users {
    text-wrap: wrap !important;
  }
}

/* ===============================768 and 1124======================== */
@media (min-width: 768px) and (min-height: 1024px) {
  .textpoll {
    height: 10rem !important;
  }
}

/* ===============================1280and 800======================== */
@media (min-width: 768px) and (min-height: 1024px) {
  .textpoll {
    height: 10rem !important;
  }
}
/* ===============================540 and 720======================== */
@media (min-width: 540px) and (min-height: 720px) {
  .textAstronomy {
    /* width: 100% !important; */
    /* padding-top: 2rem !important; */
  }
  /* .filterbt {
    margin-top: -3rem !important;
  } */
}
/* ===============================912 and 1368======================== */
@media (min-width: 912px) and (min-height: 1368px) {
  .msgbtn {
    margin-left: 4rem !important;
  }
}
/* ===============================768 and 1024======================== */
@media (min-width: 768px) and (min-height: 1024px) {
  .textAstronomy {
    height: auto !important;
    width: auto !important;
  }
  /* .podcastlist {
    margin-top: -103px !important;
  } */
  /* .light-for-date {
    display: none !important;
  } */
}
/* @media (min-width: 414px) and (min-height: 896px) {
  .btnmsgs {
    margin-left: 8.5rem !important;
    width: 16rem !important;
  }
} */
@media (min-width: 768px) and (min-height: 1024px) {
  .msgbtn {
    margin-left: 0.5rem !important;
  }
  .chevron {
    margin-left: -1.5rem !important;
  }
  /* .pollutext {
    height: 6.5rem !important;
  } */
}
/* ===============================1366 and 768======================== */
@media (min-width: 1366px) and (min-height: 768px) {
  .msgbtn {
    margin-left: 1.5rem !important;
  }
}
/* ===============================360 and 640======================== */
@media (min-width: 360px) and (min-height: 640px) {
  /* .country-sections {
    top: 4rem !important;
    z-index: 100 !important;
    left: -6rem !important;
  } */
  .msgbtn {
    margin-left: 2rem !important;
  }
}
/* ===============================1024 and 1366======================== */
@media (min-width: 1024px) and (min-height: 1366px) {
  /* .btnmsg {
    margin-top: 20px !important;
    margin-left: 0rem !important;
    width: 7rem !important;
  } */
  .moredown {
    margin-left: -3rem !important;
  }
  .paid-card {
    margin-top: -3px !important;
  }
}
/* Hide text for screens with a maximum width of 768px (adjust the width as needed) */
/* @media (max-width: 768px) {
  .ArticleShowdos {
    display: none;
  }
  .light-for-date {
    display: none;
  }
} */
/* @media (max-width: 768px) and (min-height: 1024px) {
  .ArticleShowdos {
    display: block;
  }
  .light-for-date {
    display: none;
  }
} */
/* @media (max-width: 540px) and (min-height: 720px) {
  .ArticleShowdos {
    display: block;
    margin-top: -6rem !important;
  }
  .light-for-date {
    display: none;
  }
} */
/* @media (max-width: 375px) and (min-height: 812px) {
  .ArticleShowdos {
    display: none;
  }
  .light-for-date {
    display: none;
  }
} */
/* @media (max-width: 768px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
} */
/* @media (max-width: 360px) and (min-height: 564px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
} */
/* @media (max-width: 414px) and (min-height: 896px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }

} */
/* @media (max-width: 390px) and (min-height: 844px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: none !important;
  }
} */
/* @media (max-width: 393px) and (min-height: 851px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: none !important;
  }
} */
/* =================================add media query 360 and 740================== */
/* @media (max-width: 360px) and (min-height: 740px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: none !important;
  }
} */
/* =================================add media query 1024 and 600================== */
/* @media (max-width: 1024px) and (min-height: 600px) {
  .morehorizontaldata {
  margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: block !important;
  }
  .light-for-date {
    text-wrap: nowrap !important;
  }
} */
/* =================================add media query 1024 and 600================== */
/* @media (max-width: 540px) and (min-height: 720px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: block !important;
  }
  .light-for-date {
    text-wrap: nowrap !important;
  }
} */
/* =================================add media query 412 and 914================== */
/* @media (max-width: 412px) and (min-height: 914px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: none !important;
  }
} */
/* =================================add media query 540 and 564================== */
/* @media (max-width: 540px) and (min-height: 564px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: none !important;
    margin-top: -5.8rem !important;
  }
  .textAstronomy {
    width: 100% !important;
  } */
/* .msgbtn {
    margin-left: 8.5rem !important;
    width: 16rem !important;
  } */
/* .light-for-date {
    display: none !important;
  }
  .usedtextin {
    margin-top: -5.8rem !important;
  }
} */
/* =================================add media query 375 and 667================== */
/* @media (max-width: 375px) and (min-height: 664px) {
  .morehorizontaldata {
    margin-top: -11rem !important;
    margin-right: -1.5rem !important;
  }
  .sporttext {
    margin-right: -2rem !important;
    margin-top: 1rem !important;
  }
  .ArticleShowdos {
    display: none !important;
    margin-top: -5.8rem !important;
  } */
/* .msgbtn {
    margin-left: 3.5rem !important;
     width: 16rem !important;
  }
} */

/* CSS stylesheet or equivalent */
/* @media (max-width: 768px) {
  .hide-on-small-screen {
    display: none;
  }
}
@media (max-width: 360px) {
  .hide-on-small-screens {
    display: none;
  }
} */

/* Default style for screens other than 820px */
.small-screen {
  display: none;
}
/* ===============280px and 653px ============== */
/* Media query for screens with a minimum width of 280px or greater */
@media screen and (min-width: 280px) and (max-width: 280px) {
  .media_card {
    width: 15rem;
  }
  .custom-text {
    font-size: 10px !important;
    color: #666;
  }
  .hide-on-small-screens {
    display: none;
  }
  .textpolls {
    text-wrap: nowrap !important;
  }
  .small-text {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 768px) {
  .textpolls {
    text-wrap: nowrap !important;
  }
  .hide-on-small-screens {
    display: none;
  }
  .small-text {
    display: none;
  }
}
@media (max-width: 360px) {
  .usertextlist {
    display: none !important;
    text-wrap: wrap !important;
  }
}
/* @media (min-width: 360px) {
  .usertextlist {
    display: block !important;
    text-wrap: wrap !important;
  }
} */
@media (max-width: 768px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .podcastlist {
    margin-top: -86px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 820px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .podcastlist {
    margin-top: -86px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 1200px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .podcastlist {
    margin-top: -86px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 1200px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .audio-text {
    /* margin-top: -22px;
    margin-right: 13rem; */
    position: absolute;
    margin-left: -36px;
  }
}
@media (max-width: 1200px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .btn-audio {
    margin-right: 6rem;
  }
}

@media (max-width: 1075px) and (max-width: 768px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .rem-mob-views {
    display: none !important;
  }
  .podcastlist {
    /* margin-top: -62px; */
    margin-left: 49%;
    display: flex !important;
    align-items: center;
  }
}

@media (max-width: 360px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .morehorizontaldata {
    margin-top: -62px;
    margin-left: 49%;
    display: flex !important;
    align-items: center;
  }
}
@media (max-width: 375px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .morehorizontaldata {
    margin-top: -65px !important;
    margin-left: 49%;
    display: flex !important;
    align-items: center;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .hide-podcast {
    display: none !important;
  }
}
/* @media (min-width: 375px) and (max-width: 812px) {

  .textpodcasts {
    margin-top: -80px !important;
  }
} */
@media (min-width: 768px) and (max-width: 1024px) {
  .small-text {
    display: none;
  }
}
/* @media (max-width:834) {
  .Treanding_bottom_sections {
   position: absolute;
   bottom: 0px!important;
  }
} */
/* Apply styles for screens with a width between 834px and 984px */
/* @media screen and (min-width: 834px) and (max-width: 984px) {
  .textprofile {
    font-size: 10px;
  }
} */
/* @media screen and (min-width: 768px) and (max-width: 968px) {
  .textprofile {
    font-size: 10px;
  }
} */
@media (min-width: 768px) and (max-width: 1200px) and (min-height: 768px) and (max-height: 1200px) {
  .hide-text {
    display: none !important;
  }
}
