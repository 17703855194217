.all-hashtags {
    display: flex;
    flex-direction: row;
}

.hashtag-icob>img {
    width: 20px;
}

.css-1xr9krm.Mui-selected {
    background-color: #81C14B;
}

.hashtag-stat h4 {
    font-size: 1rem;
    line-height: 18px;
    color: #4D4D4D;
    font-weight: 500;
    cursor: pointer;
    margin: 0 0 4px;
}

.hashtag-stat h4:hover {
    text-decoration: underline !important;
    color: #81C14B !important;
}

.hashtag-stat h5 {
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
    color: #808080;
    font-weight: 400;
    line-height: 18px;
}

.more-tags-inn {
    align-items: center;
    text-transform: capitalize;
    margin-top: 20px;
    color: #81C14B;
    font-weight: 500;
    background-color: rgba(237, 247, 251, 0.25);
    padding: 8px 15px;
    border-radius: 5px;
}

.group-custom-block-hashtag {
    background: white;
    padding: 1rem;
}

.heading-podcast-blk-new {
    flex-direction: column;
    align-items: unset !important;
}

.group-btn-new {
    width: 100% !important;
}

.heading-podcast-blk-new p {
    color: #4D4D4D;
}

.Socialgallery img {
    /* border-radius: 5px; */
    /* width: 100%; */

}

img.img-fluid.Grallery-frstImg {
    border-radius: 5px 0 0 0 !important;
}

img.img-fluid.Grallery-ScndImg {
    border-radius: 0 5px 0 0 !important;
}

img.img-fluid.Grallery-ThirdImg {
    border-radius: 0 0 0 5px !important;
}

img.img-fluid.Grallery-FourthImg {
    border-radius: 0 0 5px 0 !important;
}

.SocialgallerySubChild {
    /* margin-top: 1px; */
    position: relative;
}

.SocialgallerySubChild img {
    opacity: 0.6;
}

.SocialgallerySubChildCount {
    /* color: yellowgreen; */
    position: absolute;
    top: 50%;
    left: 50%;
    /* width: 100%; */
    text-align: center;
    font-size: 18px;
    color: black;
    transform: translate(-50%, -50%);
}

.content-center-slider.content-center {
    max-height: 2200px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.content-center-slider.content-center::-webkit-scrollbar {
    display: none;
}

.headerFormain {
    position: relative;
    color: #4D4D4D;
}

.headerFormain .activeForIcon {
    position: absolute;
    top: -2px;
    right: 130px;
    font-size: 18px;
}

.form-check-input:checked {
    background-color: #81C14B;
    border-color: #81C14B;
}

.card {
    border: none;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
}


.shareForButton {
    background: rgba(126, 178, 98, 0.1);
    border-radius: 5px;
    padding: 8px 8px;
    color: #81C14B;
}


.SeeAllEventButton>h5 {
    color: #fff;
    cursor: pointer;
    font-weight: 600;
}

.SeeAllEventButton>h5:hover {
    text-decoration: underline;
    font-weight: 600;
}


.CommonHeadingSeg>h2 {
    color: #4D4D4D;
    font-weight: 500;
    line-height: 21px;
    /* font-family: 'Montserrat'; */
}

.CommonHeadingSeg>h5 {
    color: #808080;
    font-weight: 400;
    line-height: 21px;
    /* font-family: 'Montserrat'; */
}

.profileForCount>h3 {
    color: #000000 !important;
    line-height: 18px;
    font-weight: 500;
    /* font-family: 'Montserrat'; */
}

.profileForCount>h5 {
    color: #808080 !important;
    line-height: 18px;
    font-weight: 400;
    /* font-family: 'Montserrat'; */
}


.theBirthdayCardTitle {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.theBirthdayCardTitle>* {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: clamp(16px, 2vw, 24px);
}

.Events_Img_CardBlk {
    width: 100% !important;
    object-fit: cover !important;
    border-radius: 5px 5px 0px 0px !important;
}

.profileCard_box {
    background: #FFFFFF !important;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1) !important;
    border-radius: 5px !important;
}

.Group_suggestion_card {
    padding: 15px;
    background: #FFFFFF;
    border-radius: 5px;
}

.Crad_Blk_section {
    border-radius: 5px;
    position: relative;
    text-align: center;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.Img_Blk_section {
    position: relative;
    margin: 0 0 30px;
    display: block;
}

.Crad_Blk_section img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
}

.adminprofile-blk {
    position: absolute;
    bottom: -23px;
    left: 33px;
    transform: translate(-50%);
}

.Card_Content_blk {
    position: absolute;
    bottom: -23px;
    left: 50%;
    transform: translate(-50%);
    text-align: start;
}

.adminprofile-blk img {
    background: #fff;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
}

.Card_Content_blk h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 6px;
}

button.Biz_PageMore_btn.btn.btn-outline-primary {
    padding: 0;
    width: 20px;
    /* border-radius: 100%; */
    height: 20px;
    border: 0;
}

button.Biz_PageMore_btn.btn.btn-outline-primary:hover {
    background: none;
}

button.Biz_PageMore_btn.btn.btn-outline-primary {
    background-color: #FFFFFF !important;
    background: none !important;
}

.Events_buttom_section {
    align-items: center;
    position: absolute;
    padding: 15px;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100% !important;
}

.Events_buttom_section h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #FFFFFF;
}

:root {
    --updateStoryGradient: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))
}

.Events_bottom_rght_blk {
    position: absolute;
    bottom: 8px;
    right: 8px;
}

.Icon_Blk_section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    width: 34px;
    height: 34px;
}

.event-box .event-content:after {
    position: absolute !important;
    top: 0 !important;
}

.Group_Join_btn_blk {
    padding: 6px 12px;
}

.Suggestion_Grp_Img {
    width: 100%;
    object-fit: cover;
    height: 130px;
    border-radius: 5px 5px 0 0;
}

.Grpupmember_Main_blk span {
    /* margin: 3px 4px; */
    font-size: 13px;
    display: inline-block;
    color: #4d4d4d;
    /* font-weight: 600; */
}

.groupBottom-custom-block {
    margin: 0 0 10px !important;
}

.Events_button_section {
    font-size: 18px;
    /* Default font size */
}

@media screen and (max-width: 768px) {
    .Events_button_section {
        font-size: 16px;
        /* Font size for mobile devices */
    }
}

@media screen and (max-width: 480px) {
    .Events_button_section {
        font-size: 14px;
        /* Font size for smaller mobile devices */
    }
}

.eventTextByText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.seeall-btn a:hover {
    color: #81C14B !important;
    text-decoration: underline !important;
}

.time-hours-blk a:hover {
    text-decoration: underline !important;
    color: #81C14B !important;
}

.FollowRequest_Accept_btn {
    padding: 6px 24px !important;
}

.FollowRequest_Reject_btn {
    padding: 6px 24px !important;
    border: 1px solid #c4c4c4 !important;
}

.GroupSSliderCustomizes>.slick-prev:before {
    content: "\2039" !important;
    color: white !important;
    background: rgba(85, 85, 85, 0.5);
    border-radius: 0px 10px 10px 0px;
    padding: 5px 2px;
    padding-bottom: 16px !important;

    /* chevron left icon */
}

.GroupSSliderCustomizes>.slick-next:before {
    content: "\203A" !important;
    color: white !important;
    background: rgba(126, 178, 98, 0.8);
    padding: 5px 2px;
    padding-bottom: 16px !important;
    background: rgba(85, 85, 85, 0.5);
    border-radius: 10px 0px 0px 00px;
}

.GroupSSliderCustomizes>.slick-prev {
    left: -2px !important;
}

.GroupSSliderCustomizes>.slick-next {
    right: -2px !important;
}

.Biz_Img_Box img {
    border-radius: 5px;
}

.LikeBizUser h4 {
    margin-bottom: 0 !important;
}