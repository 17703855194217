.new-create-grp-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grp-create-btn {
  width: 100% !important;
}

.grp-create-p {
  text-align: center;
}

.new-create-grp-img {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.new-create-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grp-create-bg {
  width: 350px;
  margin: auto;
}

.grp-privacy {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.grp-privacy-h {
  font-weight: 600;
}

.grp-privacy-p {
  color: #647589;
}

.biz-user-pro-blk-new h5 {
  font-size: 16px;
  color: #262626;
  margin: 15px 0 0;
  font-weight: 600;
}

.grp-preview {
  border-bottom: 1px solid #0000001f;
  padding: 0.5rem 0rem;
}

.GropMenu_Tab_Section {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.2);
  border-radius: 5px;
}

.GroupDetail_tab_Section {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
  border-radius: 5px;
}

.Member_Super_Admin_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 15.787px;
}

.Member_Super_Admin_section .Super_profile {
  border-radius: 4.93342px;
  border: 0.986685px solid #f0f0f0;
  display: flex;
  align-items: center;
}

.profile_super_Member h5 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 15.787px;
}

.profile_super_Member p {
  color: #808080;
  font-weight: 400;
  font-size: 13.8136px;
}

.Super_profile h5 {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  left: 120.54px;
}

.Admin_Profile_section {
  background: #ffffff;
  border: 0.986685px solid #f0f0f0;
  border-radius: 4.93342px;
}

.Admin_Profile_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 15.787px;
}

.Admin_Profile_section p {
  color: #808080;
  font-weight: 400;
  font-size: 13.8136px;
}

.Admin_Profile_section small {
  color: #808080;
  font-weight: 400;
  font-size: 13.8136px;
}

.Admin_Profile_section .Admin_Profile_Detail {
  position: absolute;
  left: 100.54px;
}

.Activity_Profile_section {
  background: #ffffff;
  border: 0.986685px solid #f0f0f0;
  border-radius: 4.93342px;
  box-sizing: border-box;
}

.Activity_Profile_section .Activity_Profile_Detail {
  position: absolute;
  left: 82.54px;
}

.admin_Title_Name h4 {
  font-weight: 500;
  font-size: 15.787px;
  color: #4d4d4d;
}

.About_Section {
  background: #ffffff;
  box-shadow: 0px 0px 17.8888px rgba(126, 178, 98, 0.1);
  border-radius: 8.94442px;
}

.About-card-block {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
  border-radius: 5px;
  margin-bottom: 20px;
}

.About_Img-Section {
  background: #f6f6f6;
  border-radius: 40px;
  padding: 10px;
}

.About_edit_section {
  background: #f6f6f6;
  border-radius: 40px;
  padding: 10px;
}

.About_Main_section {
  height: 76px;
}

.About_Main_section .about_sub_section {
  position: absolute;
  left: 90px;
}

.about_sub_section h5 {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 20px;
}

.about_sub_section p {
  color: #b5b5b5;
  font-size: 18px;
  font-weight: 400;
}

.custom-card-head h4 {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 32px;
}

.About_Gallery_Section {
  background: #ffffff;
  box-shadow: 0px 0px 7.38296px rgba(126, 178, 98, 0.1);
  border-radius: 3.69148px;
  margin-bottom: 20px;
}

.Img_Card {
  width: 100%;
}

.imghight {
  height: 180px;
}

.top_Grllery_Section {
  margin-top: 36px;
}

.Suggestion_box_Section {
  border-radius: 5.12796px;
}

.Suggestion_box_Section img {
  border-radius: 5px 5px 0px 0px;
}

.join_btn {
  background: #81c14b;
  border-radius: 5.12796px;
  width: 266.65px;
  height: 42.51px;
}

.join_btn p {
  color: #ffffff;
  font-weight: 500;
  font-size: 14.3583px;
}

.Suggestion_Heading h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18.4607px;
}

.Suggestion_Heading span {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 14.3583px;
}

.Suggestion_Heading p {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.Trending_Heading_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
}

.Trending_Heading_section p {
  color: #81c14b;
  font-weight: 500;
  font-size: 12.9724px;
  cursor: pointer;
}

.trending_section .Tranding_data {
  position: absolute;
  left: 90px;
}

.trending_section {
  height: 70px;
}

.Tranding_data h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
}

.Tranding_data p {
  color: #808080;
  font-weight: 400;
  font-size: 16px;
}

.biz-about-cont span {
  color: #81c14b;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 5px;
}

.Setting_Data_Section {
  box-shadow: 0px 0px 10px rgba(129, 193, 75, 0.2);
  border-radius: 5px;
}

.setting_section ul li {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.setting_section span {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 18px;
}

.setting-card-head {
  padding: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.setting-card-head h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 18px;
}

.setting-card-head p {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
}

.Setting_Section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.Setting_Section p {
  color: #81c14b;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.Setting_Section h5 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

.Setting_Section small {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

/* input box  */
.GroupInfo_Input {
  border-radius: 5px;
  width: 300px;
  height: 41px;
}

.Create_GroupInfo_Input {
  border-radius: 5px;
  /* width: 100%; */
}

.GroupInfo_Input.form-control:focus {
  border: 1px solid #81c14b !important;
}

.Create_GroupInfo_Input.form-control:focus {
  border: 1px solid #81c14b !important;
}

input[type="Group"] {
  /* width: 300px; */
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  /* height: 41px; */
  border-radius: 5px;
}

input[type="Report"] {
  width: 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  height: 41px;
  border: none;
  background: #f6f6f6;
  border-radius: 3.23343px;
}

input[type="file"] {
  width: 300px;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  height: 41px;
  border-radius: 5px;
}

input[type="textarea"] {
  border-radius: 5px;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  height: 141px;
}

input[type="Group"]:focus {
  border: 1px solid #81c14b;
}

input[type="Creategroup"]:focus {
  border: 1px solid #81c14b;
}

.UploadBtn {
  color: #808080;
}

::placeholder {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 14px;
}

.Check_Label_Section p {
  color: #808080;
  font-weight: 400;
  font-size: 12px;
  padding-top: 5px;
}

.Check_Label_Section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

.Private_Label_Section p {
  color: #808080;
  font-weight: 400;
  font-size: 12px;
  padding-top: 5px;
}

.Private_Label_Section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

.GroupName_Label_Section p {
  color: #808080;
  font-weight: 400;
  font-size: 12px;
}

.GroupName_Label_Section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
}

.public_Check.form-check-input:checked {
  background-color: #81c14b !important;
  border-color: #81c14b !important;
}

.Private_Check.form-check-input:checked {
  background-color: #81c14b !important;
  border-color: #81c14b !important;
}

.Check.form-check-input:checked {
  background-color: #81c14b !important;
  border-color: #81c14b !important;
}

.Assign_Check.form-check-input:checked {
  background-color: #81c14b !important;
  border-color: #81c14b !important;
}

.public_Check.form-check-input {
  position: absolute !important;
  margin-top: 1px !important;
  margin-left: 1px !important;
}

.Check_Label_Section {
  margin-left: 40px;
}

.Private_Check.form-check-input {
  position: absolute !important;
  margin-top: 1px !important;
  margin-left: -19px !important;
}

.Check.form-check-input {
  position: absolute !important;
  margin-top: -3px !important;
  margin-left: -25px !important;
}

.Assign_Check.form-check-input {
  position: absolute !important;
  margin-top: 17px !important;
  margin-left: 4px !important;
}

.Assign_Sub_Section {
  position: relative;
  margin-left: 43px;
  margin-top: 18px;
}

.Private_Label_Section {
  margin-left: 21px;
}

.Group_Cancle_btn {
  background: #efefef;
  border-color: #c4c4c4;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  width: 130px;
  height: 45px;
}

.Group_Cancle_btn p {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.Group_Update_btn {
  background: #81c14b;
  border-radius: 5px;
  width: 130px;
  height: 45px;
}

.Group_Update_btn p {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

.validation {
  display: flex;
  justify-content: flex-end;
  color: #808080;
  font-weight: 400;
  font-size: 12px;
}

.Forgot_Section {
  display: flex;
  justify-content: flex-end;
  color: #81c14b;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.Descriptionvalidation {
  display: flex;
  justify-content: flex-end;
  color: #808080 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}

.Blocked_Member_Heading h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 20px;
}

.Blocked_Main_Section {
  background: #ffffff;
  border-radius: 5px;
}

.border_section {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.Unblock_btn {
  background: #ff822e;
  border: 1px solid #ff822e;
  border-radius: 5px;
  padding: 0px 0px;
  width: 86px;
  height: 37px;
}

.Unblock_btn p {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

/* @media (max-width:375px) {
    .Unblock_btn {
        background: #FF822E;
        border: 1px solid #FF822E;
        border-radius: 5px;
        width: 50px;
        padding: 0px !important;
        margin: 0px !important;
    }

    .Unblock_btn p {
        color: #FFFFFF;
        font-weight: 400;
        font-size: 10px;
    }
} */

.PaginationCount
  .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #81c14b !important;
  color: #ffffff !important;
}

@media (max-width: 375px) {
  .PaginationCount
    .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #81c14b !important;
    padding: 0px !important;
    margin: 0px !important;
  }
}

.modal_Heading_BlockUser {
  background: #f6f6f6;
  height: 70px;
}

.modal_Heading_BlockUser h4 {
  color: #4d4d4d;
  font-weight: 600;
  font-size: 24px;
}

.UnBlock_Cancle_btn {
  width: 125px;
  background: #efefef;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 41px;
}

.UnBlock_Cancle_btn p {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
}

.UnBlock_ok_btn {
  background: #81c14b;
  border-radius: 5px;
  width: 125px;
  height: 41px;
}

.UnBlock_ok_btn P {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.BlockProfile_section h6 {
  color: #808080;
  font-weight: 500;
  font-size: 15px;
}

.BlockProfile_section p {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.BlockSubProfile_section p {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.Join_Accept_btn {
  background: #81c14b;
  border-radius: 5.49479px;
  height: 44px;
  width: 90px;
}

.Join_Accept_btn p {
  color: #ffffff !important;
  font-weight: 400 !important;
  font-size: 15.3854px !important;
}

.Join_Decline_btn {
  background: #f6f6f6;
  border-radius: 5.49479px;
  height: 44px;
  width: 90px;
}

.Join_Decline_btn p {
  color: #808080;
  font-weight: 400;
  font-size: 15.3854px;
}

.Join_Confirm_btn {
}

.General_Cancel_btn {
  background: #efefef;
  width: 125px;
  height: 41px;
}

.General_Cancel_btn p {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14px;
}

@media (max-width: 375px) {
  .Join_Accept_btn {
    background: #81c14b;
    border-radius: 5.49479px;
    width: 55px;
    padding: 0px !important;
    margin: 0px !important;
  }

  .Join_Accept_btn p {
    color: #ffffff !important;
    font-weight: 400;
    font-size: 11px !important;
  }
}

@media (max-width: 375px) {
  .Join_Decline_btn {
    width: 55px;
    background: #f6f6f6;
    border-radius: 5.49479px;
    padding: 0px !important;
    margin: 0px !important;
    margin-left: 9px !important;
  }

  .Join_Decline_btn p {
    color: #808080;
    font-weight: 400;
    font-size: 11px;
  }
}

.Join_Profile_section {
  background: #ffffff;
  border: 0.986685px solid #f0f0f0;
  border-radius: 4.93342px;
}

.Join_Profile_section .Join_Profile_Detail {
  position: absolute !important;
  left: 86px !important;
}

.Join_Profile_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 17.5833px;
}

.Join_Profile_section p {
  color: #808080;
  font-weight: 400;
  font-size: 13.8136px;
}

@media (max-width: 375px) {
  .Join_Profile_section h4 {
    color: #4d4d4d;
    font-weight: 500;
    font-size: 13.5833px;
  }
}

@media (max-width: 375px) {
  .Join_Profile_section p {
    color: #808080;
    font-weight: 400;
    font-size: 10.8136px;
  }
}

.Join_Profile_section .Join_Profile_Detail {
  position: absolute;
  left: 100.54px;
}

@media (max-width: 375px) {
  .PostMedia_Gallery_section {
    width: 100% !important;
  }
}

.Block_UnblockAll_btn {
  background: #81c14b;
  border-radius: 5px;
  width: 117px;
  height: 41px;
}

.Block_UnblockAll_btn p {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

/* start_Member_section */
/* .group-main-block {
    display: block;
} */

/* .group-main-block {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    padding: 15px;
    margin: 0 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
} */

/* .group-main-block .gropImg {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border: 2px solid #FFFFFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 50%;
    margin-right: 10px;
} */

.Admin_Remove_btn {
  background: #ffffff;
  border-radius: 4.93342px;
  border: 0.986685px solid #ff822e;
  width: 86px;
  height: 36px;
  display: inline-block;
  padding: 0px 0px;
}

.Admin_Decline_btn {
  width: 86px;
  height: 36px;
  display: inline-block;
  background: #f6f6f6;
  padding: 0px 0px;
}

.Admin_Decline_btn p {
  color: #808080;
  font-weight: 400;
  font-size: 15.3854px;
}

.Admin_Remove_btn p {
  color: #ff822e;
  font-weight: 400;
  font-size: 13.8136px;
}

.Admin_Modify_btn {
  display: inline-block;
  background: #81c14b;
  border-radius: 4.93342px;
  width: 86px;
  height: 36px;
  padding: 0px;
}

.Admin_Modify_btn p {
  color: #ffffff;
  font-weight: 400;
  font-size: 13.8136px;
}

.Admin-btns-blk {
  margin: 12px 0 0;
}

.Block-btns-blk {
  margin: 12px 0 0;
}

@media (max-width: 375px) {
  .Block-btns-blk {
    display: flex !important;
    justify-content: center;
  }
}

@media (max-width: 375px) {
  .Admin-btns-blk {
    display: flex !important;
    justify-content: flex-end !important;
  }
}

@media (max-width: 375px) {
  .Group_Btns_section {
    display: flex;
    justify-content: center;
  }
}

.People_Cancel_btn {
  background: #f6f6f6;
  width: 107px;
  height: 44px;
}

.People_Cancel_btn p {
  color: #808080;
  font-weight: 400;
  font-size: 16px;
}

.People_Assign_btn {
  background: #81c14b;
  border-radius: 5px;
  width: 107px;
  height: 44px;
}

.People_Assign_btn p {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}

.Assign_Sub_Section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 14.4914px;
}

.Assign_Sub_Section p {
  color: #808080;
  font-weight: 400;
  font-size: 12.68px;
}

.Accordin_Body_Section {
  color: #808080;
  font-weight: 400;
  font-size: 12.68px;
}

.Success_Heading_Section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 23.3504px;
}

.Success_Ok_btn {
  background: #81c14b;
  border-radius: 3.89639px;
  width: 92px;
  height: 44px;
}

.Success_Ok_btn p {
  font-weight: 400;
  font-size: 16.6882px;
  color: #ffffff;
}

.CreateGroup_Btn {
  border: 1px solid #81c14b;
  border-radius: 5px;
  background: #ffffff;
}

.CreateGroup_Btn p {
  color: #81c14b;
  font-weight: 400;
  font-size: 14px;
}

.Btn_CreateGroup {
  height: 37px;
  padding: 0px;
  margin: 0px;
  width: 137px;
}

.Delete_Modal_Heading_section {
  background: rgba(255, 0, 0, 0.5);
  display: flex;
  justify-content: center;
}

.Delete_Modal_Heading_section h4 {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
}

/* end_Member_section */

/* Joined_group_Section */
.Join_Actifiy_btn {
  background: #81c14b;
  border-radius: 5px;
  width: 110px;
  height: 41px;
}

.Unfollow_Actifiy_btn {
  width: 110px;
  height: 41px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}

/* Joined_group_Section */

.Grop_Form_Label p {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 16px;
}

.CreateGroup_btn {
  background: #acdad6;
  border-radius: 5.01137px;
  width: 130px;
  height: 45px;
}

.CreateGroup_btn p {
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
}

.CancleGroupForm_btn {
  background: #f6f6f6;
  border: 1.00227px solid #c4c4c4;
  border-radius: 5.01137px;
  width: 130px;
  height: 45px;
}

.CancleGroupForm_btn p {
  color: #808080;
  font-weight: 400;
  font-size: 15px;
}

.Scandal-clr-btn {
  background: #acdad6;

  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
  border: 1.00227px solid #acdad6;
}

.Group_Memver_p p {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 16px;
}

.Group_Title_Section h4 {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 20.9179px;
}

.Group_Title_Section p {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 16px;
}

.Icon_Box_Section {
  background: #f6f6f6;
  box-shadow: 0px 0px 10.5338px rgba(126, 178, 98, 0.1);
  border-radius: 5.26688px;
  width: 31.69px;
  height: 31.69px;
}

.Create_Group_section h4 {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 18px;
}

.Create_Group_section p {
  color: #b5b5b5;
  font-weight: 400;
  font-size: 16px;
}

.Create_Group_Heading_Section {
  background: rgba(255, 0, 0, 0.5);
}

.Create_Group_Body_Section h4 {
  color: #808080;
  font-weight: 500;
  font-size: 16px;
}

.Creation_Cnfm_btn {
  background: #81c14b;
  border-radius: 4.71043px;
  width: 117px;
  height: 38px;
  color: #ffffff;
  font-weight: 500;
  font-size: 13.1892px;
  padding: 0px 0px;
}

.Creation_Cncle_btn {
  background: #efefef;
  border: 0.942085px solid #c4c4c4;
  border-radius: 4.71043px;
  width: 117px;
  height: 38px;
  color: #4d4d4d;
  font-weight: 500;
  font-size: 13.1892px;
  padding: 0px 0px;
}

.Button_section {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.Invitation_Heading_Section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 21.2161px;
}

.Invitaion_User_Profile h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 16px;
}

.Invitaion_User_Profile p {
  color: #808080;
  font-weight: 400;
  font-size: 15px;
}

.Invitaion_List_Heading h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 17px;
}

.Invite_SocioMate_Heading h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
}

.Invitation_Send_btn {
  background: #81c14b;
  border-radius: 3.7274px;
  width: 96.91px;
  height: 35.91px;
  padding: 0px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}

.Creation_Heading_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 15.213px;
}

.Creation_Heading_section p {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
}

.Invite_person_btn {
  background: #c4c4c4;
  border-radius: 2.36513px;
  width: 70.18px;
  height: 25.22px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  padding: 0px;
}

.Invite_person_btn.btn-secondary:hover {
  color: #ffffff !important;
  background-color: #c4c4c4 !important;
  border-color: #c4c4c4 !important;
}

.Group_suggested_Box {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
  border-radius: 5px;
}

.Group_suggested_Box .group-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.Group_suggested_Box .Group_circle {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  cursor: pointer;
}

.SuggestedGroup_Heading {
  text-align: center;
}

.SuggestedGroup_Heading h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18px;
}

.Suggest_Join_Button {
  background: #81c14b;
  width: 260px;
  height: 41px;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  padding: 0px;
}

.Invite_Join_Button {
  width: 125px;
  height: 41px;
  background: #81c14b;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  padding: 0px;
}

.Invite_Decline_Button {
  width: 125px;
  height: 41px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  color: #808080;
  font-size: 14px;
  line-height: 21px;
  padding: 0px;
}

.Invite_Join_Button .btn-secondary:hover {
  color: #ffffff !important;
  background-color: #81c14b !important;
  border-color: #81c14b !important;
}

.Suggest_Join_Button .btn-secondary:hover {
  color: #ffffff !important;
  background-color: #81c14b !important;
  border-color: #81c14b !important;
}

.Suggestmember-blk {
  color: #4d4d4d;
}

.Suggestmember-blk span {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
}

.Suggestmember-blk-text p {
  color: #808080;
  font-weight: 400;
  font-size: 14px;
}

.heading-podcast-blk h3 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 20px;
}

.heading-podcast-blk p {
  color: #808080;
  font-weight: 400;
  font-size: 16px;
}

.group-slide-box .gallery_menu_sublink {
  position: absolute;
  top: 120px;
  right: 10px;
  cursor: pointer;
  z-index: 2;
}

.group-slide-box
  .gallery_menu_sublink
  .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal {
  transform: translate(-136px, 24px) !important;
}

.Report_modal_Heading_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 20.1318px;
}

.modal_Header {
  background: #ffffff;
}

.Modal_Body_Section p {
  color: rgba(77, 77, 77, 0.5);
  font-weight: 500;
  font-size: 15px;
  text-align: start;
}

.Report_Section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 15.4697px;
}

.Report_Input_section {
  border-bottom: 0.646685px solid #ebebeb;
  width: 100%;
}

.Report_summit_btn {
  background: #81c14b;
  border-radius: 3.23343px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14.4697px;
  padding: 0px;
  width: 84.07px;
  height: 34.93px;
}

.Report_cancel_btn {
  background: #f6f6f6;
  border: 0.63px solid #c4c4c4;
  border-radius: 3.23343px;
  width: 84.07px;
  height: 34.93px;
  color: #808080;
  font-weight: 500;
  font-size: 14.4697px;
  padding: 0px;
}

.Group_Top_Cover_Section {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
  border-radius: 5px;
  margin-left: 4px;
  margin-right: 5px;
}

.detail-buttons-blk {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-buttons-blk p {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  font-size: 20px;
}

.Group_Detail_Invite_btn {
  background: #81c14b;
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
  width: 99px;
  height: 41px;
}

.Group_Join_btn {
  background: #81c14b;
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 41px;
}

.Group_Detail_Invite_btn .btn.btn-secondary {
  color: #81c14b !important;
  background-color: #81c14b !important;
}

.Group_Detail_paragraph_section p {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 14.1083px;
}

.Group-user-pro-blk-new h5 {
  color: #262626;
  font-weight: 500;
  font-size: 35px;
}

.Group_Avatar_section h6 {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 16px;
}

.Join_Request_Profile h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 18.1614px;
}

.Join_Request_Profile p {
  color: #808080;
  font-weight: 400;
  font-size: 14.2697px;
}

.Join_Request_Tym h4 {
  color: #81c14b;
  font-weight: 400;
  font-size: 15.5669px;
}

.approved_Post_button {
  width: 370.03px;
  height: 40.13px;
}

.group-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Post_content_section p {
  color: #808080;
  font-weight: 400;
  font-size: 14.2697px;
}

.Post_hasTag_section span {
  color: #81c14b;
  font-weight: 400;
  font-size: 18.7241px;
}

.Post_hasTag_section p {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 16.2697px;
}

.post_heading_section h4 {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 23.4051px;
}

.post_Request.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.w-25.show {
  transform: translate(400px, 42px) !important;
  padding: 0px !important;
  width: 300px !important;
}

.Post_Card_header {
  padding: 0.75rem 1.25rem !important;
  margin-bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.profile-cover.profile-cover-new {
  border-radius: 5px !important;
  position: relative !important;
  box-shadow: none !important;
}

label.GroupName_Label_Section {
  margin: auto !important;
}

.Group_Dropdown_menu.dropdown-menu.show {
  transform: translate3d(-65px, 45px, 0) !important;
  will-change: transform !important;
  top: 0px !important;
  left: 0px !important;
  position: absolute !important;
}

.About_Dropdown_menu.dropdown-menu.show {
  transform: translate3d(-65px, 45px, 0) !important;
  will-change: transform !important;
  top: 0px !important;
  left: 0px !important;
  position: absolute !important;
  height: 250px;
}

a.Group_DrppDown_Item.dropdown-item {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 15.8136px;
}

a.Group_DrppDown_Item.dropdown-item:active {
  background-color: #81c14b !important;
  color: #ffffff !important;
}

.Group_Pending_Screen_Section {
  background: #ffffff;
  width: 100%;
  height: 350px;
  position: relative;
}

.abccdrwe {
  position: absolute;
  top: 25%;

  width: 100%;
  text-align: center;
  font-size: 18px;
}

.search-blk {
  position: relative !important;
  /* margin-bottom: 15px !important; */
  left: 0px !important;
}

.search-blk .search-svg {
  width: 17px !important;
  height: 17px !important;
  position: absolute !important;
  top: 12px !important;
  left: 10px !important;
  color: #4d4d4d !important;
}

.search-blk .form-control {
  background: #f6f6f6 !important;
  border-radius: 5px !important;
  padding: 8px 10px 8px 35px !important;
}

.ForgetInput {
  width: 45px;
  border: 1px solid #bdbdbd;
  border-radius: 6px;
}
