/* for sports background */
.profile-slide-box{
    border-radius: 5px;
background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.24) 100%); 
}

.hidden-file .choose-file input {
    opacity: 0;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
}

.btn-grp-new {
    position: unset !important;
    right: unset !important;
    bottom: unset !important;
}

.profile-cover-new {
    height: unset !important;
}

.Event-box {
    background: #FFFFFF;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: relative;
    /* width: 299px; */
    /* height: 368px; */

}

.Event-box .Event-img-sublink {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
}


.Event-box .event-img {
    object-fit: cover;
    height: 151px;
    width: 100%;
    border-radius: 5px 5px 0px 0px;
}

.Event-FirstSection {
    padding: 5px;
}

.Event-SecndSection {
    display: flex;
    justify-content: space-between;
}

.Event-SecndSection small {
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #81C14B;

}

.stng_btn {
    background: #F6F6F6;
    border-radius: 80px;
    width: 24px;
    height: 24px;
    cursor: pointer;


}

.Event-SecndSection img {
    position: absolute;
    left: 91%;
    bottom: 51%;
}

.Event-bottom-section p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #808080;

    padding-top: 10px;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.events_Option.show {
    width: auto;
    transform: translate(49px, 10px) !important;
    padding: 0px !important;
}

.Event-bottom-section img {
    width: 12px;
    height: 12px;
}

.Events-bottom-paragraph {
    display: flex;

}

.Events-bottom-paragraph p {
    color: #808080;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
}

.Event_Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 41px;
    gap: 10px;
    background: #F0F0F0;
    border-radius: 5px;
    margin-top: 31px;
    cursor: pointer;
}

.Event_Button p {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 14px;
}

.Events_SendBtn {
    background: #F0F0F0;
}

.Going_Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 41px;
    gap: 10px;
    background: #81C14B;
    border-radius: 5px;
    margin-top: 31px;
    cursor: pointer;

}

.Invitation_Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 41px;
    gap: 10px;
    background: #C4C4C4;
    border-radius: 5px;
    margin-top: 31px;
    cursor: pointer;
}

.Invitation_Button p {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
}

.Going_Button p {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;

}

.Event-FirstSection h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #4D4D4D;
}

.InvitationInst_Button {
    background: #C4C4C4;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 41px;
    width: 112px;
}

.InvitationInst_Button p {
    font-weight: 600;
    font-size: 13px;
    color: #FFFFFF;
}

.InterestedButton {
    background: #C4C4C4;
    border-radius: 5px;
    height: 41px;
    width: 110px;
    align-items: center;

}

.InterestedButton span {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 12px;

}

.Interest_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    background: #C4C4C4;
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    width: 135px;
    height: 41px;

}

.MoreDropdownOption {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    background: rgba(129, 193, 75, 0.1);
    border-radius: 5px;
    height: 37px;
}

.MoreDropdownOption h6 {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 5px;
}

.DropDownMoreOption {
    background: #E5F0E0 !important;
    border-radius: 5px;
    border: none;
}

.DropDownMoreOption:hover {
    background: #81C14B !important;
    border-radius: 5px;
    border: none;
    color: #FFFFFF !important;
}

.DropDownMoreOption:active {
    background: #81C14B !important;
    border-radius: 5px;
    border: none;
    color: #FFFFFF !important;
}

.DropDownMoreOption h6 {
    background: #E5F0E0 !important;
    border-radius: 5px;
}

.profile-menu ul li.active svg,
.profile-menu ul li:hover svg {
    stroke: #FFFFFF !important;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.ancher-text {
    color: revert !important;
    border-radius: 0px !important;
    background-color: #ffffff !important;
}

.menu-item-text {
    color: #000000 !important;
    border-radius: 0px !important;
    background-color: #ffffff !important;
}

.menu-item-text:hover {
    background-color: #81C14B !important;
    border-radius: 0px !important;
    color: #FFFFFF !important;
}

.menu-item-text:active {
    background-color: #81C14B !important;
    border-radius: 0px !important;
}


.group-card-box {
    background: #FFFFFF;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: relative;


}

.group-card-box .Group-Img-sublink {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
}



.group-card-box .group-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
}

.Group-Contect_Text {
    padding: 12px;

}

.headingContectText {
    display: flex;
    justify-content: center;
}

.headingContectText h4 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 16px
}

.GroupButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #F6F6F6;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    width: 152px;
    height: 41px;
    margin-top: 15px;
    margin-left: 38px;
    cursor: pointer;
}

.Join_btn button {
    background: #F6F6F6;
}

.BookMark_Card_Box {
    background: #F6F6F6;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 29px;
    height: 28px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

.BookMark_Card_Section {
    border-radius: 5px 5px 5px 5px;
}

.BookMark_Card_Section img {
    border-radius: 5px 5px 0px 0px;
    height: 230px
}

.BookMark-card-Subdata {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    bottom: 19%;
    height: 77px;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    padding: 10px;
}

.BookMark-card-Subdata p {
    color: #81C14B;
}

.BookMark-card-Subdata span {
    color: #C4C4C4;
    font-weight: 400;
    font-size: 12px;
}

.BookMark-card-Subdata h4 {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
}

.BookMark_Button_section p {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 16px;
}

.BookMarkText_Card_Section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.BookMarkText_Card_box p {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;

}

.TextCenter_section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    background: linear-gradient(0deg, #81C14B, #81C14B);
    border-radius: 5px 5px 0px 0px;
    padding: 10px;

}

.MarketPlace_Card_Section {
    border-radius: 5px;
    /* width: 300px; */

}

.MarketPlace_Card_Section .MarketPlaceImg {
    object-fit: cover;
    border-radius: 5px 5px 0px 0px;

}

.MarketPlace_Card_LeftPosition {
    position: absolute;
    top: 8px;
    left: 16px;
    display: flex;
}

.MarketPlace_Card_LeftPosition p {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
}

.MarketPlace_Card_RightPosition {
    position: absolute;
    top: 8px;
    right: 16px;
}

/* .MarketPlace_Card_BottomLeftPosition {
    position: absolute;
    bottom: 123px;
    left: 16px;
} */

.BottomLeftPosition {
    background: #00A855;
    border-radius: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;

}

.BottomLeftPosition p {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;
}

.PenddingLeftPosition {
    background: #FF9900;
    border-radius: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
}

.PenddingLeftPosition p {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;
}

.SoludLeftPosition {
    background: #808080;
    border-radius: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
}

.SoludLeftPosition p {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;
}

.market-slide-box .MarketPlace_Card_BottomRightPosition {
    position: absolute;
    top: 116px;
    right: 10px;

}

/* .MarketPlace_Bottom_Section { */
    /* margin-top: 5px; */

/* } */

.MarketPlace_Bottom_Section h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 16px;
}

.MarketPlace_Bottom_Section h3 {
    font-weight: 600;
    font-size: 16px;

}

.MarketPlace_Bottom_Section .MarketPlace_Sub_Section {
    display: flex;
    justify-content: space-between;
    padding-top: 13px;
}

.MarketPlace_Bottom_Section .MarketPlace_Sub_Section p {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 16px;
}

.MarketPlace_Sub_Section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MarketPlace_Sub_Section h5 {
    color: #16C31E;
    font-weight: 400;
    font-size: 14px;
}

.MarketPlace_Sub_Section p {
    color: #4D4D4D;
    font-size: 16px;
    font-weight: 600;
}

.MarketPlace_Bottom_Section .MarketPlace_Sub_Section h5 {
    color: #16C31E;
    font-weight: 400;
    font-size: 14px;
}

.MarketPlace_End_section p {
    color: #808080;
    font-size: 14px;
    font-weight: 400;
}

/* .MarketPlace_Bottom_Section .MarketPlace_End_section {
    /* padding-top: 6px; */


.OwnedBizPage_section {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.OwnedBizPage_section .createBizImg {
    object-fit: cover;
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    height: 100px;

}

.OwnedBizPage_section .MenuBar_BizPage {
    position: absolute;
    right: 22px;
    top: 8px;
}

.Biz-Profile img {
    border: 2px solid #FFFFFF;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
}

.Biz-Profile {
    position: absolute;
    transform: translate(-50%);
    bottom: 224px;
    left: 50%;
}

.Biz-content {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.Biz-content p {
    color: #808080;
    font-weight: 400;
    font-size: 14px;
}

.BizGroupContent {
    display: flex;
    justify-content: space-between;
}


.BizEmailcontnet {
    text-align: center;
}

.ButtonClass p {
    color: #808080;
    font-weight: 500;
    font-size: 14px;
}

.Followedbtn p {
    color: #81C14B;
    font-weight: 500;
    font-size: 14px;
}

/* start_Atrical-section */
.Artical_Section {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.Artical_Section .ArticalImg {
    object-fit: cover;
    border-radius: 5px 5px 0px 0px;
    width: 100%;
}

.Artical_Top_Right {
    position: absolute;
    top: 8px;
    right: 16px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
    display: flex;
    flex-direction: row;

}

.Artical_Heading_Section h4 {
    font-weight: 500;
    font-size: 18px;
    color: #4D4D4D;
}

.Artical_subHeading_Section p {
    color: #808080;
    font-weight: 400;
    font-size: 14px;
}

.Artical_Main_section .Artical_Title_Section span {
    color: #808080;
    font-weight: 400;
    font-size: 14px;
}

/* Atrical-section */
/* start_Recommendation_section */
.Recommendation_section {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.Recommendation_section .RecommendationImg {
    border-radius: 5px 5px 0px 0px;
    object-fit: cover;
    width: 100%;
}

.Recommendation_Body_Section {
    background: #235363;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
}

.Recommendation_Body_Section p {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
}

.Recommendation_SubHeading p {
    color: #4D4D4D;
    font-size: 17px;
    line-height: 24px;
}

.Recommendation_Location p {
    color: #808080;
    font-weight: 400;
    font-size: 14px;
}

.Recommendation_Bottom_section .Recommendation_btn h4 {
    color: #999999;
    font-weight: 500;
    font-size: 15px;
}

.Recommendation_Bottom_section .Recommendation_btn .RecommendationImg {
    width: 20px;
    height: 20px;
    color: #999999;
}

.Recommendation_RightPosition {
    position: absolute;
    top: 8px;
    right: 16px;
    background: rgba(246, 246, 246, 0.7);
    border-radius: 80px;
}

.Text_section {
    background: #FFFFFF;
    border: 1px solid #81C14B !important;
}

.Mordreate_Text_Section {
    background: #FFE8D3;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.Severe_Text_Section {
    background: rgba(255, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.Mild_Text_Section {
    background: rgba(255, 230, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.TextLeft_Top {
    position: absolute;
    top: 8px;
    left: 16px;
}

.TextLeft_Top p {
    color: #333333;
    font-weight: 600;
    font-size: 20px;
}

.Mordreate_TextRight_Top {
    position: absolute;
    top: 8px;
    right: 16px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}

.Mlid_TextRight_Top {
    position: absolute;
    top: 8px;
    right: 16px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 20px;

}

.Severe_TextRight_Top {
    position: absolute;
    top: 8px;
    right: 16px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 20px;

}

.Text_TextRight_Top {
    position: absolute;
    top: 8px;
    right: 16px;
    background: #F6F6F6;
    border-radius: 20px;

}

.Text_Center_part p {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 14px;

    margin-left: 16px;
    margin-right: 16px;
    margin-top: 8px;

}

.Text_part p {
    color: #333333;
    font-weight: 400;
    text-decoration-line: underline;
    font-size: 14px;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 8px;


}


.Mordreate_SubText_part p {
    color: #FF8B02;
    font-weight: 400;
    font-size: 12px;
    margin-left: 16px;
    margin-top: 16px;
}

.Mild_SubText_part p {
    color: #FFB802;
    font-weight: 400;
    font-size: 12px;
    margin-left: 16px;
    margin-top: 16px;
}

.Severe_SubText_part p {
    color: #FF0202;
    font-weight: 400;
    font-size: 12px;
    margin-left: 16px;
    margin-top: 16px;
}

.Poll_RowFirtSide_section {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(129, 193, 75, 0.1);
    border-radius: 5px;
}

.PollMoreMenu {
    background: #EFEFEF;
    border-radius: 40px;
    padding: 10px;
    cursor: pointer;
}

.Poll_Progress_Bar {
    background: #D4E7E0;
    border-radius: 35px;
    height: 35px;

}

.Poll_Progress_Bar .Progress_Events {
    background: #FFD600;
    border-radius: 35px;
    width: 174px;

}

.Poll_Progress_Bar .Progress_Events p {
    color: #48985C;
    font-weight: 500;
    font-size: 16px;
    left: 22px;
    top: 7px;
    position: relative;
}

.Poll_Progress_Bar .Progress_Rating p {
    color: #48985C;
    font-weight: 500;
    font-size: 14px;
    top: 8px;
    right: 22px;
    position: relative;
}

.Progress_Bar_Poll {
    background: #D4E7E0;
    border-radius: 35px;
    height: 35px;
    display: flex !important;
    justify-content: space-between !important;
}

.Progress_Bar_Poll .progress-bar {
    background-color: #FFD600;
    border-radius: 35px;
}

.Progress_Bar_Poll .progress-bar label {
    color: #48985C;
    left: 22px;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    top: 4px;
}

.Progress_Bar {
    background: #D4E7E0;
    border-radius: 35px;
    height: 35px;
}

.Progress_Rating {
    position: absolute;
    right: 45px;
    color: #48985C;
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;

}

/*------------------------- start_Running_Poll------------------------ */
.Completed_Bar_Poll {
    background: #D4E7E0;
    border-radius: 35px;
    height: 35px;
    display: flex !important;
    justify-content: space-between !important;
}

.Completed_Bar_Poll .progress-bar {
    background-color: #C4C4C4;
    border-radius: 35px;
}

.Completed_Bar_Poll .progress-bar label {
    color: #48985C;
    left: 22px;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    top: 4px;
}

.Completed_Analytics {
    display: flex;
    justify-content: space-between;
}

.Completed_Analytics h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #4D4D4D;
}

.Completed_Analytics p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #808080;
}

/*------------------------- end_Running_Poll------------------------ */
/*------------------------- start_Schedule_Poll------------------------ */
.Schedule_Bar_Poll {
    background: #D4E7E0;
    border-radius: 35px;
    height: 35px;
    display: flex !important;
    justify-content: space-between !important;
}

.Schedule_Bar_Poll .progress-bar {
    background-color: #D4E7E0;
    border-radius: 35px;
}

.Schedule_Bar_Poll .progress-bar label {
    color: #48985C;
    left: 22px;
    font-weight: 500;
    font-size: 16px;
    position: relative;
    top: 4px;
}

.Scheduled_Analytics {
    display: flex;
    justify-content: space-between;
}

.Scheduled_Analytics h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #4D4D4D;
}

.Scheduled_Analytics p {
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #808080;
}

/*------------------------- end_Schedule_Poll------------------------ */
.Poll_Btn_section {
    background: #F6F6F6;
    height: 48px;
}

.Poll_Btn_section .Btn_section .flex_btn p {
    background: #DDDDDD;
    border-radius: 20px;
    width: 25px;
    height: 25px;
}

.Poll_Btn_section .Btn_section .btn_flex p {
    background: #DDDDDD;
    border-radius: 20px;
    width: 25px;
    height: 25px;

}

.Poll_Btn_section .Btn_section .btn_flex img {
    position: absolute;
    padding: 5px;
}

.Poll_Btn_section .Btn_section .flex_btn img {
    position: absolute;
    padding-left: 6px;
    padding-top: 5px;
}

.Poll_Analytics {
    display: flex;
    justify-content: space-between;
}

.Poll_Analytics h4 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 18px;
}

.Poll_Analytics p {
    color: #81C14B;
    font-weight: 500;
    font-size: 18px;
}

.Poll_Analytics .Poll_AnalyticsData {
    background: #F6F6F6;
    border-radius: 5px;
    height: 100px;
    width: 224px;
}

.Poll_Analytics .Poll_AnalyticsData h4 {
    color: #16C31E;
    font-weight: 500;
    font-size: 21px;
}

.Analytics_Poll {
    background: #F6F6F6;
    border-radius: 5px;
}

.Analytics_Poll h4 {
    color: #16C31E;
    font-weight: 500;
    font-size: 21px;
}

.Analytics_Poll .Analytics_Flex_box p {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 16px;
}

.Analytics_Poll .Analytics_Flex_box span {
    color: #0066FF;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
}

.Bottom_AllType_Tab_Section p {
    color: #81C14B;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;

}

.Bottom_AllType_Tab_Section span {
    font-weight: 500;
    font-size: 18px;
    color: #4D4D4D;
    cursor: pointer;
}

.Poll_Analaytics:active {
    color: #81C14B;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

a.Poll_link.nav-link {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

a.Poll_link.nav-link.active {
    color: #81C14B;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    border-bottom: solid 1px #81C14B;
}

.Gender_Progress_Bar {
    background: #EFF7F9;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}

.Gender_Progress_Bar .progress-bar {
    background-color: #3C803D !important;
    border-radius: 20px;
}

.PollGenderPreivew h4 {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 16px;
}

.PollGenderPreivew h5 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 16px;
}

.ViewModal_Heading_Section h4 {
    font-weight: 500;
    font-size: 18px;
    color: #81C14B;

}

/* start user profile */
/* end user profile */
.UserProfile_cover {
    height: 160px;
    border-radius: 5px 5px 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.UserProfile_cove .img-fluid.bg-img {
    height: 160px;
}

::-webkit-scrollbar{
    width: 3px;
}::-webkit-scrollbar-track{
    background-color: #e2fae1;
}::-webkit-scrollbar-thumb{
    background-color: #8abe46;
}

.DropDown_item.dropdown-menu.show {
    top: 4px !important;
    width: auto !important; /* Change width to auto to adjust based on content */
    min-width: 220px !important; /* Set a minimum width to ensure dropdown is not too small */
    max-width: 350px !important; /* Set a maximum width to prevent it from being too wide */
    position: absolute !important;
    left: 11px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
    border-radius: 7px !important;
    max-height: 210px;
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; 
}

.PodCast_DropDown_item.dropdown-menu.show {
    top: 4px !important;
    width: 220px !important;
    position: absolute !important;
    left: -184px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
    border-radius: 7px !important;
}

.DropDown_Share_item.dropdown-menu.show {
    top: 4px !important;
    width: 220px !important;
    position: absolute !important;
    left: -126px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
    border-radius: 7px !important;
}

.DropDown_Group_item.dropdown-menu.show {
    top: 4px !important;
    width: 220px !important;
    position: absolute !important;
    background: #FFFFFF !important;
    left: -9px !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;
    border-radius: 7px !important;
}

.MenuBar_BizPage .dropdown-menu .dropdown-menu-right .custom-dropdowns .drop-menu-gal {
    transform: translate(-267px, 150px) !important;
}

.MenuBar_BizPage .dropdown-menu.show {
    display: block;
    width: 200px !important;
}

.Profile_Info h4 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 16px;
}

.Profile_Info .Post_Count h4 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 14px;


}

.Profile_Info .Post_Count span {
    font-weight: 500;
    font-size: 14px;
    color: #808080;
}

.Profile_Info .Post_Count .Follower_count h4 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 14px;
}

.Profile_Info .Post_Count .Follower_count span {
    font-weight: 500;
    font-size: 14px;
    color: #808080;

}

.Like_Profile_user {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 2px;
    margin-left: 2px;
    margin-right: -1px;
}

.Btn_Typ .View_Btn {
    background: #EFEFEF;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
}

.Btn_Typ .View_Btn span {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 14px;
}

.ViewTab_Section a {
    color: #81C14B !important;
}

.ViewTab_Section .viewTab_NavItem {
    padding: 10px !important;
}

.ViewTab_Section .viewTab_NavItem span {
    font-weight: 400;
    font-size: 18px;
}

/* .Like_search {} */

.Like_search .Srch_btn {
    position: absolute;
    left: 22px;
    top: 10px;
    width: 16px;
    height: 16px;
    color: #4D4D4D;
}

.Like_search .input-controler {
    background: #F6F6F6;
    border-radius: 5px;
    border: 1px solid #F6F6F6;
    height: 40px;

}

.Like_Heading_Section h3 {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 16px;
}

.Heading_Invite_Section h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 20px;
}

.Heading_Invite_Section span {
    color: #81C14B;
    font-weight: 500;
    font-size: 16px;
}

.SubHeading_Invite_Section h4 {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 16px;
    padding-top: 10px;
}

.SubHeading_Invite_Section p {
    color: #81C14B;
    font-weight: 400;
    font-size: 16px;
    padding-top: 10px;
}

.Invite_Filter_btn {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px 20px;
    justify-content: center;
}

.Uncontrolled_btn :hover {
    /* background-color: #81C14B !important; */
    color: #81C14B !important;
}

/* .Invite_Filter_btn :hover {
    color: #81C14B;
    background-color: #00A855 !important;
} */

.Invite_Filter_btn p {
    color: #4B4B4B;
    font-weight: 400;
    font-size: 14px;
}

.Btn_Typ .CheckBox_btn {
    color: #81C14B !important;
}

.modalBody {
    overflow-y: auto;
    scroll-behavior: smooth;
    max-height: 10% !important;
}

.Join_Card_Section {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.Join_Card_Section img {
    border-radius: 5px 5px 0px 0px;
    height: 150px
}

.Join_Card_Position_Section {
    position: absolute;
    top: 8px;
    right: 22px;
    /* border-radius: 80px; */
    /* background: rgba(246, 246, 246, 0.24); */
    /* width: 24px;
    height: 24px; */
}

/* STAR_pODcAST_ */
.PodCast_section {
    border-radius: 5px;
}

.TopPodcast_Section img {
    border-radius: 5px 5px 5px 5px;

}

.TopPodcat_Left_right {
    position: absolute;
    top: 13px;
    right: 1px;
    background: #F9A321;
    border-radius: 30px 0px 0px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 4px 0px 7px;
}

.TopPodcat_Left_right h4 {
    color: #000000;
    font-size: 14px;
    font-weight: 500;

}

.TopPodcat_Left_Side {
    position: absolute;
    top: 8px;
    left: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.TopPodcat_Left_Side p {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
}

.TopPodcat_bottom_left {
    position: absolute;
    bottom: 8px;
    left: 12px;
}

.TopPodcat_bottom_left h4 {
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.TopPodcast_bottom_right {
    position: absolute;
    bottom: 0px;
    right: 0px;

}

.PodCast_Title h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    font-size: 16px;


}

.PodCast_Title p {
    color: #81C14B;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}

.Title_Podcast h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    font-size: 16px;
}

.Musics_Card {
    background: #FFFFFF;
    border-radius: 5px;
    width: 260px;
    height: 305px;
    box-sizing: border-box;
    left: 44px;
    top: 37px;
}

.Musics_Card img {
    border-radius: 5px 5px 5px 5px;
}



.Media_name h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    font-size: 16px;
}

.Media_name h5 {
    color: #979797;
    font-weight: 400;
    font-size: 14px;
}

.Media_name p {
    color: #979797;
    font-weight: 400;
    font-size: 14px;
}

.Delete_Media_modal_section {
    background: #ff0000;
    /* margin-left: 0; */
    /* margin-right: 1rem; */
}

.Delete_Media_modal_section h4 {
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
}

.modalHeading_Section h4 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 20px;
}

.modalHeading_Section p {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 14px;
}

.Shotz_Cancle_btn {
    background: #EFEFEF;
    border-color: #C4C4C4;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    width: 125px;
    height: 41px;

}

.Shotz_Cancle_btn p {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 14px;
}

.Shotz_HideOk_btn {
    background: #81C14B;
    border-radius: 5px;
    width: 125px;
    height: 41px;
}

.Shotz_HideOk_btn p {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.media_option.show {
    transform: translate(90px, 41px) !important;
    padding: 0px !important;
    width: auto !important;
}

/* END_pODcAST_ */
/* end_Recommendation_section */
/* .Event-box .Event-FirstSection .Event-SecndSection .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal {
    transform: translate(-136px, 24px) !important;
} */
/* .dropdown-menu .dropdown-menu-right .custom-dropdown .drop-menu-gal{
    transform: translate(-136px, 24px) !important;

} */


/* end_my_events_css */

.profile-search-blk {
    position: relative !important;
    width: 300px;


}

.profile-search-blk .search-svg {
    width: 17px;
    height: 17px;
    position: absolute;
    top: 12px;
    left: 10px;
    color: #4D4D4D;
}

.profile-search-blk .form-control {
    background: #F6F6F6;
    border-radius: 5px;
    padding: 8px 10px 8px 35px;
}

.Media_Preview .modal-content {
    background: transparent !important;

}

.media-body h4 {
    font-family: "Montserrat", sans-serif;
    color: #51595e;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 5px;
}

.PaginationCounts .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #81C14B !important;
    color: #FFFFFF !important;
}

.Profile-slide-box.card {
    margin: -4px -4px 25px !important;
}

.market-slide-box.card {
    margin: 0px 0px 25px !important;
}

.market-slide-box {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
    border-radius: 5px;
    /* border: 1px solid #C4C4C4; */
    position: relative;
}

.Profile-slide-box {
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgb(126 178 98 / 10%);
    border-radius: 5px;
    /* border: 1px solid #C4C4C4; */
    position: relative;
}

.Profile-slide-box .gallery-img-sublink {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
}

.market-slide-box .gallery-img-sublink {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
}

.market-slide-box .MarketPlace_Card_LeftPosition {
    position: absolute;
    top: 8px;
    left: 16px;
    display: flex;

}

.market-slide-box .MarketPlace_Card_BottomLeftPosition {
    position: absolute;
    top: 120px;
    left: 10px;

}


.Profile-slide-box .gallery-img-sublink .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal {
    transform: translate(-136px, 24px) !important;
}

.market-slide-box .gallery-img-sublink .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal {
    transform: translate(-136px, 24px) !important;
}

.Profile-slide-box .group-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
}

.market-slide-box .group-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
}

.MarketPlace-content {
    padding: 10px;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.recommendation.show {
    transform: translate(-169px, 41px) !important;
    padding: 0px;
    width: 177px !important;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Text.show {
    transform: translate(-169px, 0px) !important;
    padding: 0px;
}

/*Start_ABout_section_css */
.profile-about .about-content li .icon {
    background: rgba(126, 178, 98, 0.05);
    /* background: #0066FF; */
    border-radius: 5px;
    width: 35px;
    height: 35px;
    /* justify-content: center;
    margin-right: 15px;
    align-items: center;
    -webkit-box-pack: center; */
    position: relative;

}

.Icon_section {
    position: absolute;
    left: 7px;
    top: 6px;
}

.profile-about .about-content li .Profession_detail h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 16px;
}

.profile-about .about-content li .Education_details {
    width: calc(100% - 45px);
}

.profile-about .about-content li .Education_details h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 16px;
}

.profile-about .about-content li .Education_details h6 {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
}

.profile-about .about-content li .Education_details small {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 12px;
    text-transform: capitalize;
}

.Profession_Modal_section {
    display: flex !important;
    justify-content: space-between !important;
    padding: 1rem !important;
}

/* .taps-style {
    border-bottom: 3px solid #F6F6F6 !important;
} */

.taps-hover-style:hover {
    border-bottom: 3px solid #81C14B !important;
    border-width: 300px;
    align-content: center;
}

.taps-hover-style:active {
    border-bottom: 3px solid #81C14B !important;
    border-width: 300px;
    align-content: center;
}


.Profession_Modal_section h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

}

.Profession_Modal_section p {
    color: #81C14B;
    /* font-weight: 600; */
    font-size: 16px;
    cursor: pointer;
    line-height: 24px;

}

.Interest_Modal_section span {
    color: #81C14B;
    font-weight: 400;
    font-size: 16px;
}

.Interest_Modal_section h4 {
    color: #333333;
    font-weight: 400;
    font-size: 18px;
}


/* .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #81C14B !important;
} */
span.MuiRadio-root.MuiRadio-colorPrimary.MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.PrivateSwitchBase-root.Mui-checked.Radio_btn_check.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    color: #81C14B !important;
    margin-right: -10px !important;
}

/* .css-1a5icme.Mui-checked .Radio_btn_check {
    color: #81C14B !important;
    margin-right: -10px !important;
} */

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    margin-right: -10px !important;

}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #81C14B !important;
}

.Profession_Modal_Option {
    display: flex;
    justify-content: space-between;
    align-items: center;


}



.Profession_Modal_Option h4 {
    font-weight: 400;
    font-size: 16px;
    color: #4D4D4D;

}

/* .about-content.About_section {
    padding: 20px !important;
} */

.about-content.Modal_About_section {
    padding: 0px !important;

}

.card-title.About_section {
    padding: 15px !important;
}

.Basic_Info_modal {
    background: rgba(126, 178, 98, 0.2);
    border-radius: 5px;
    width: 35px;
    height: 35px;
    position: relative;
}

.hobbies_Info_modal {
    background: #81C14B;
    border-radius: 4px;
    width: 35px;
    height: 35px;
    position: relative;
}

.hobbies_Info_modal p {
    position: absolute;
    left: 7px;
    top: 7px;
}

.Info_Modal_img {
    position: absolute;
    left: 7px;
    top: 8px;

}

.Basic_Info h4 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    font-size: 14px;
}

.Basic_Info h6 {
    color: #647589;
    /* text-transform: capitalize; */
    line-height: 1.4;
    font-size: 14px;
    margin-top: 5px;

}

.Basic_Info p {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 12px;
}

/* .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #81C14B !important;
} */
span.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.Mui-checked.Check_Input_section.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    color: #81C14B !important;
}

.span.MuiSlider-colorPrimary.MuiSlider-sizeMedium.MuiSlider-root.slider_section_Musics.css-exkjcx {
    color: #81C14B !important;
}

label.Bio_text {
    color: #4D4D4D !important;
    font-weight: 500 !important;
    font-size: 15px;
}

.form_control_section p {
    color: #81C14B;
    /* font-weight: 400;
    font-size: 16px; */
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.form_control_section_input_box {
    position: relative;
}

.input_box_section {
    position: absolute;
    right: 8px;
    top: 12px;
    font-weight: 400;
    font-size: 14px;
    color: #C4C4C4;

}

.Map_box_section {
    position: absolute;
    right: 8px;
    top: 13px;
}

.Add_box_section {
    position: absolute;
    right: 8px;
    top: 11px;
}

.PlusCircle_border {
    position: absolute;
    right: 12px;
    top: 11px;
    border: 1px solid rgba(87, 17, 17, 0.1);
}

.Input_cross_section {
    position: absolute;
    right: 8px;
    top: 12px;
    cursor: pointer;
}

.Verfiy_input_section {
    position: absolute;
    right: 35px;
    top: 9px;
    cursor: pointer;
}

.inputForm {
    padding-right: 20%;


}

.Eduction_inputForm {
    padding-right: 11%;
    border-right: 1px solid rgba(24, 97, 9, 0.1);

}

.Input_Verify_section {
    background: rgba(196, 196, 196, 0.1);
    border-radius: 8px;
    width: 345px;
    height: 273px;
    margin-left: 70px;
}

.Otp_section {
    width: 39px;
    height: 39px;
    border-radius: 5px;
}

.input_receive_section h5 {
    color: #5F5F5F;
    font-weight: 400;
    font-size: 13px;
}

.input_receive_section h4 {
    color: #000000;
    font-weight: 400;
    font-size: 14px;
}

.hobbies-blk li {
    display: inline-block;
    padding: 10px 20px;
    background: rgba(126, 178, 98, 0.2);
    border: 0.5px solid rgba(129, 193, 75, 0.2);
    border-radius: 4px;
    font-size: 12px;
    color: #4D4D4D;
    font-weight: 500;
    margin: 0 15px 15px 0;
    display: inline-flex;
    align-items: center;
}

.hobbies-blk li p {
    margin-right: 5px;
}

.Interestes-blk li {
    display: inline-block;
    padding: 10px 20px;
    background: #81C14B;
    border: 0.5px solid rgba(129, 193, 75, 0.2);
    border-radius: 5px;
    font-size: 12px;
    color: #FFFFFF;
    font-weight: 500;
    margin: 0 15px 15px 0;
    display: inline-flex;
    align-items: center;
}

.Interestes-blk li p {
    margin-right: 5px;

}

.sport_Img_section {
    color: #000000;
    background-color: #0066FF;
}

.Profession_Modal_Option img {
    object-fit: cover;
    border-radius: 4px;

}

.Input_Add_Profession {
    width: 80%;
}

.Recently__Card_Media {
    background: #FFFFFF;
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
}

.Recently__Card_buttom_section {
    text-align: center;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.center_btn_action.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    background-color: #81C14B !important;
}

span.MuiSlider-colorPrimary.MuiSlider-sizeMedium.MuiSlider-root.slider_section_Musics.css-187mznn-MuiSlider-root {

    color: #81C14B !important;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.PodCast_play_list.w-50.show {
    transform: translate(185px, 512px) !important;
    padding: 0px;
}

.Recently_Player_List {
    display: flex;
    align-items: center;
    position: relative;
}

.Play_Btn_Section {
    position: absolute;
    left: 4%;
    top: 13%;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.25);

}

.Recently_Player_List .video_image {
    object-fit: cover;
    border-radius: 5px;
}

.Recently_Player_List_botton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    position: absolute;
    width: 150px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 5px;
    cursor: pointer;
    color: #81C14B;
    font-weight: 400;
    font-size: 14px;
}

.PodCast_Media_section {
    display: flex;
    position: relative;
}

.PodCast_Media_section .Podcast_media {
    object-fit: cover;
    border-radius: 5px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) !important;
}

img.img-fluid.Podcast_media {
    border-radius: 5px;
    object-fit: cover;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) !important;

}

.PodCast_Media_section {
    position: relative;
}

.Media_Mic {
    position: absolute;
    top: 52px;
    left: 48px;
}

.Media_Paid {
    position: absolute;
    bottom: 8px;
    left: 88px;
}

.Media_Paid img {
    border-radius: 5px 5px 5px 5px;
}

img.img-fluid.paid_media {
    border-radius: 5px;
}

.Podcast_Icon_section {
    position: relative;
    display: flex;
    width: 25px;
    height: 25px;
    background: #DDDDDD;
    border-radius: 20px;
}

.Icon_section {
    position: absolute;
    left: 8px;
    top: 8px;
}

.PodCast_button_section {
    background: #DDDDDD;
    border-radius: 20px;
    position: absolute;
    width: 25px;
    height: 25px;
}

.Podcast_Media_Card_Box {
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    position: relative;

}

.Podcast_Btn_section {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    width: 105px;
    height: 40px;
    color: #979797;
    font-weight: 400;
    font-size: 14px;

}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.podcast_option.show {
    transform: translate(455px, 27px) !important;
    padding: 0px;
    width: auto !important;

}


.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.podcastDetails_Option.show {
    transform: translate(512px, 41px) !important;
    padding: 0px;
    width: auto !important;
}

.Podcast_action_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #81C14B;
    border-radius: 5px;
    color: #81C14B;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
}

.Podcast_Explore_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px;
    cursor: pointer;
    width: 90px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #81C14B;
    border-radius: 5px;
    color: #81C14B;
    font-weight: 400;
    font-size: 14px;

}

.analitics-custom-blk {
    background: #F6F6F6;
    box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 10%);
    border-radius: 5px;
    padding: 15px;
    margin: 0 0 25px;

}

.podcast-analitics-blk li {
    display: flex;
    justify-content: space-between;
}

.podcast-analitics-blk li {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.podcast-analitics-blk li b {
    font-weight: 600;
    color: #2F81FC;
    text-decoration-line: underline;
}

.Podcast_Details_box {
    border: 1px solid rgba(196, 196, 196, 0.5);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 23px 10px 10px;
}

img.img-fluid.Podcast_Details_media {
    object-fit: cover;
    border-radius: 5px;
}

.Podcast_Details_Mic {
    position: absolute;
    top: 42px;
    left: 54px;
}

.episode-img-blk {
    position: relative;
}

.afsdfdxbbrt {
    position: absolute;
    top: 8px;
    left: 8px;
    cursor: pointer;
}

.Follower-memberslist-block {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    padding: 5px;
    /* margin: -15px -9px 25px; */
    margin: 0 -9px 7px;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.Follower-memberslist-block img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border: 2px solid #FFFFFF;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    border-radius: 50%;
    margin-right: 10px;
}

.Like_Profile_user img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    margin-right: 10px;
}

.Follower-memberslist-block {
    display: block;
}

.Remove_Modal_section p {
    color: #4D4D4D;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

}

.Choose_Modal_section {
    display: flex;
    justify-content: space-around;
}

.Choose_Modal_section h4 {
    cursor: pointer;
}

img.img-fluid.Choose_Img.card-img {
    cursor: pointer !important;
}

.Select_Modal_Header {
    margin-left: 168px;

}

button.Option_going_btn.dropdown-item:hover {
    color: #FFFFFF !important;
    background-color: #81C14B;
}

.Going_Menu.dropdown-menu.show {
    display: block;
    width: 137%;
    z-index: 99;
    color: #808080;
}

.Completed_Poll {
    display: flex;
    align-items: center;
}

.Completed_Poll h5 {
    font-weight: 600;
    font-size: 20px;
    color: #808080;
    line-height: 30px;
}

.Completed_Poll span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    opacity: 0.6;
}

.Poll-about-cont ul li {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.Running_POll {
    display: flex;
    align-items: center;
}

.Running_POll h5 {
    font-weight: 600;
    font-size: 20px;
    color: #81C14B;
    line-height: 30px;
}

.Running_POll span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    opacity: 0.6;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Poll_option.show {
    width: auto;
    transform: translate(409px, 39px) !important;
    padding: 0px !important;
}

.AboutModal_Main_section {
    border-bottom: 1px solid #F6F6F6;
    width: 100%;

}

.Modal_scroll_box {
    max-height: 450px !important;
    overflow-y: scroll !important;
}

/* #Scroll-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#Scroll-1::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

#Scroll-1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    
    background: rgba(129, 193, 75);

} */

span.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.Mui-checked.Check_Input_section.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    margin-right: -10px;
    color: #81C14B;
}

.Contact_Icon {
    background: rgba(126, 178, 98, 0.05);
    border-radius: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #81C14B !important;
    margin-right: -10px !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #81C14B !important;
    margin-right: -10px !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    margin-right: -10px !important;

}

.Media_Modal_send_Icon {
    background: #81C14B;
    border-radius: 50px;
    padding: 5px 3px 5px 7px;
    position: absolute;
    top: 7px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.Reply_Comment_section {
    padding: 34px;
    margin-top: -55px;

}

.my-0.py-0.rounded.shadow-lg.bg-body.rounded.dropdown-menu.show {
    width: 250% !important;
    display: block;
    border: none;
    border-radius: 5px;
}

.Media_filter_btn {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    border-radius: 5px;
    padding: 9px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4B4B4B;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.media_Filter.show {
    transform: translate(765px, 68px) !important;
    padding: 0px !important;
    /* width: 25% !important; */
    width: auto;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Artical_Filter.show {
    transform: translate(879px, 68px) !important;
    padding: 0px !important;
    /* width: 25% !important; */
    width: auto;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.shotz_Filter.show {
    transform: translate(1072px, 67px) !important;
    padding: 0px !important;
    /* width: 25% !important; */
    width: auto;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Biz_Filter {
    transform: translate(1232px, 638px) !important;
    padding: 0px !important;
    /* width: 25% !important; */
    width: auto;
}

.Slider_Filter {
    transform: rotate(90deg);
    stroke-width: 3px;
}


@media only screen and (min-width: 2200px) {
    .dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.Biz_Filter.show {
        transform: translate(1528px, 638px) !important;
        padding: 0px !important;
        /* width: 25% !important; */
        width: auto;
    }
}

.dropdown-menu.dropdown-menu-right.custom-dropdowns.drop-menu-gal.BizCard_option.show {
    width: auto;
    padding: 0px !important;
    transform: translate(-185px, 21px) !important;
}

.dropdown-menu.dropdown-menu-right.custom-dropdown.drop-menu-gal.InviteModal_filter.show {
    transform: translate(-96px, 44px) !important;
    padding: 0% !important;
}

button.Option_going_btn.dropdown-item {
    color: #808080 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
}

li.nav-item {
    margin-bottom: 1px !important;
}

.PodCast_Icon_Section {
    background: #DDDDDD;
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
    display: flex;


}

.Span_text {
    color: #808080;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.Podcast_media_blk {
    width: 120px;
    height: 120px;
    object-fit: cover;
    position: relative;
    border-radius: 5px;

}

.Profile_tab_more {
    background: rgba(129, 193, 75, 0.2);
    border-radius: 5px;
    padding: 8px 10px;
    gap: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

a.Podcast_link_heading {
    color: rgba(0, 0, 0, 0.8) !important;


}

a.Podcast_link_heading h3 {
    font-weight: 500 !important;
    font-size: 20px !important;
}

.Choose_Photo_section {
    border-radius: 8px 11px 8px 8px;
    position: relative;
}

.Choose_check_top_right {
    position: absolute;
    top: 8px;
    right: 16px;
}

/*End_ABout_section_css */

/* Add this CSS class to center the pagination */
.center-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Adjust the margin-top to control the spacing */
  }
 
  /* Define the border style for the hover effect */
  /* .post-card {
    transition: background-color 0.3s ease;
  } */
/*   
  .post-card:hover {
    border: 1px solid  lightgreen;
  } */

  .about-profile-box-blk{
    border-radius: 5px;
background: #FFF;
box-shadow: 0px 0px 10px 0px rgba(126, 178, 98, 0.10);
  }

  /* //for cross button */


  