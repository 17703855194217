.positionForbgColorRecco {
    background: #235363af;
    position: absolute;
    bottom: 53px;
    padding: 7px;
}
/* .positionForbgColorReccos {
    background: #235363af;
    position: absolute;
    bottom: 4.7rem;
    padding: 7px;
    left: 0px;
} */

.progress-bar-warning {
    background-color: #E5F0E0;
}

.positionForheadOne {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    width: -webkit-fill-available;
    top: 0;
    padding: 2%;
    color: whitesmoke;
}

.positionForheadTwo {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    top: 163px;
    padding: 2%;
    color: whitesmoke;
    width: -webkit-fill-available;
}

/* .positionForheadThree {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    padding: 2%;
    bottom: 114px;
    width: 100% !important;
    padding: 7px;
    color: whitesmoke;
} */

.imgForShadow {
    opacity: 0.5;
}

.mediaForshade {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    font-size: 18px;
    color: black;
    padding: 4px 8px;
    text-align: center;
}

.mediaForVshade {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    text-align: center;
}

.mediaForVBshade {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    text-align: center;
}


.inputForMedia input {
    display: none;
    content: Add;
}

label.labelForMedia {
    margin-bottom: 0;
    cursor: pointer;
    color: #808080;
}

label.labelForMediaM {
    margin-bottom: 0;
    cursor: pointer;
    color: #808080;
    position: absolute;
    margin: 2%;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px 0px;
}

.imgForgrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.imgForstyle {
    margin: 0.5rem 0.5rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px 0px;
}

.pd-banaer-img {
    background-size: contain;
    background-repeat: no-repeat;
}

.sizeForpro.progress {
    height: 0.4rem;
    background-color: #ACDAD6;
    color: #368C4E;
    margin-right: 15px;
    
}

.sizeForpro.progress-bar {
    background-color: #368C4E;
}

.progressFortimeLine .progress-bar {
    background-color: #368C4E;
    border-radius: 35px !important;
    color: black !important;
    display: flex !important;
    justify-content: space-between;
    flex-direction: row !important;
}

.positionForMainPodacastIcon {
    margin: 0;
    position: absolute;
    top: 30%;
    width: 100%;
    text-align: center;
}

.positionForWings {
    height: 140px;
    position: absolute;
    top: 28%;
    width: 100%;
    text-align: center;
    animation: beat .50s infinite alternate;
    transform-origin: center;
}

@keyframes beat {
    to {
        transform: scale(1.4);
    }
}

.colorForEverything {
    color: #808080;
}

.positionForSerachSmile {
    position: absolute;
    bottom: 7px;
    right: 15px;
}

.backgroundForallIcons {
    width: 30px;
    height: 30px;
    background: #DDDDDD;
    border-radius: 20px;
}

.backgroundGForallIcons {
    width: 30px;
    height: 30px;
    background: #81C14B;
    border-radius: 20px;
}

.cardAnimate {
    display: none;
    transition: opacity 1s ease-out;
    opacity: 0;
}

.postCardForSize {
    width: 100%;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
}

.Danimated {
    background-repeat: no-repeat;
    background-position: left top;
    /* padding-top: 95px; */
    margin-bottom: 60px;
    -webkit-animation-duration: 8s;
    animation-duration: 10s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeOuts {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fadeOuts {
    -webkit-animation-name: fadeOuts;
    animation-name: fadeOuts;
}

.removed-item {
    animation: removed-item-animation 1s cubic-bezier(0.55, -0.04, 0.91, 0.94) forwards;
    transform-origin: 0% 100%;
}

@keyframes removed-item-animation {
    0% {
        opacity: 1;
        transform: rotateZ(0);
    }

    100% {
        opacity: 0;
        transform: translateY(600px) rotateZ(90deg);
        /* display: none !important; */
    }

    101% {
        display: none;
    }
}

.onhovForPostselection {
    display: flex;
    align-items: center;
    border: 1px solid #F6F6F6;
    font-size: 12px;
    color: #808080;
}

.onhovForPostselection a {

    color: #808080;
}

.onhovForPostselection:hover {
    background-color: #F6F6F6;
}

.positionForCrossbutton {
    position: absolute;
    right: 1rem;
    margin: 2%;
    color: #b0cf66;
}

.inputFortext {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding-top: 14px;
    color: rgba(77, 77, 77, 0.5);
    background: #F6F6F6;
    resize: none;
}

.inputForback.form-control:focus {
    color: #212529;
    background-color: #F6F6F6;
    z-index: 1;
    border-color: #81C14B;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.positionForplaceholder::placeholder {
    position: absolute;
    /* top: 45%; */
    left: 35%;
    color: #6c757d;
    font-size: 16px;
}

.positionForArticleplaceholder::placeholder {
    position: absolute;
    top: 45%;
    left: 25%;
    color: #4F4F4F;
    font-size: 16px;
}

.positionForReccplaceholder::placeholder {
    color: #81C14B;
    font-size: 12px;
    margin-left: 15%;
}

.shadowForcircle {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 15px -3px, rgba(0, 0, 0, 0.25) 0px 4px 6px -2px;
    border-radius: 50%;
}

.btn-post {
    color: #ffffff;
    background-color: #ACDAD6;
    border-color: #ACDAD6;
}

.btn-post:hover {
    color: #ffffff;
    background-color: #81C14B;
    border-color: #81C14B;
}

.public-DraftStyleDefault-ltr {
    direction: ltr;
    text-align: left;
    /* border: 1px solid #F1F1F1; */
}

.rdw-left-aligned-block>div {
    display: revert !important;
}

.Podcast-section {
    position: relative;

}

.Podcast-section .PodcastForBanner {
    object-fit: cover;
    /* width: 100%; */
    height: 300px;

}

.positionForheadTwo {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    top: 217px;
    padding: 9px;
    color: #FFFFFF;
}

.positionForheadThree {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    top: 219px;
    /* bottom: 115px; */
    padding: 9px;
    color: #FFFFFF;
}


.ql-editor {
    box-sizing: border-box;
    line-height: 1.42;
    height: 100%;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
    /* margin-top: 397px; */
    border-top: 1px solid #ccc;
    min-height: 157px;
}

.positionForHead {
    position: absolute;
    top: 58px;
    width: 100%;
}

.positionForArticleSplaceholder {
    height: 52px;
    /* border: ; */
    padding-top: 2%;
    padding-bottom: 0;
    overflow: auto;
    box-shadow: none;
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
    font-size: 42px;
    line-height: 40px;
    margin: 3.2rem 0;
    min-height: 4.8rem;
    overflow-y: hidden !important;
    resize: none;
    max-width: -webkit-fill-available;
    margin: 47px 32px 4px 0;
}

.header-text-style {
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    color: #808080;
}

.positionForArticleSplaceholder::placeholder {
    color: var(--color-text);
    font-size: 42px;
    line-height: 48px;
}

.kitopenFor {
    padding: 0.25rem;
    animation-name: spin;
    animation-duration: 0.3s;
    animation-timing-function: linear;
}

.positionForArticleSplaceholderS {
    height: 52px;
    padding-top: 2%;
    padding-bottom: 0;
    overflow: auto;
    box-shadow: none;
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
    font-size: 42px;
    line-height: 40px;
    margin: 3.2rem 0;
    min-height: 4.8rem;
    overflow-y: hidden !important;
    resize: none;
    /* max-width: -webkit-fill-available; */
    margin: 47px 32px 4px 0;
}

.positionForArticleSplaceholderS::placeholder {
    color: var(--color-text);
    font-size: 42px;
    line-height: 48px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(90deg);
    }
}

.HovForkit {
    border: 1px solid #dee2e6;
    padding: 0.25rem;
}

.HovForkit:hover {
    background-color: #dee2e6;
}

.animForkit {
    display: block;
    /* animation-delay: 1.5s; */
    animation-name: moveInleft;
    animation-duration: 0.3s;
}

.positionForImgremove {
    position: absolute;
    top: 135px;
    right: 45px;
}

.positionForImgremoveS {
    position: absolute;
    top: 195px;
    right: 40px;
}

.kitForinputbox {
    display: inline-block;
    background-color: transparent;
    color: white;
    cursor: pointer;
    width: 100%;
}


@keyframes moveInleft {
    0% {
        opacity: 0;
        transform: translateX(-15px);
    }

    /* 80% {
        transform: translateX(10px);
    } */

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

.rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    min-height: 500px;
}

.StickerForslider {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 200px;
}

.StickerForslider::-webkit-scrollbar {
    display: none;
}

.reactionFortab {
    position: absolute;
    border-radius: 30px 30px 30px 30px;
    left: 25px;
    bottom: 116px;
}

.reactionFortab button {
    margin: 1px;
    padding: revert;
}

.mediaForGridContainer {
    display: flex;
}

.mediaForGridContainers {
    display: flex;
}

.mediaForGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mediaForGridContainer img {
    border: 1px solid white;
}

.mediaForGridContainers .borderForgrid {
    border: 1px solid white;
}

@media (max-width: 420px) {
    .positionForheadTwo {
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        top: 211px;
        padding: 2%;
        color: whitesmoke;
    }

    .positionForbgColorRecco {
        background: #235363af;
        position: absolute;
        bottom: 70px;
        padding: 7px;
    }

    .positionForheadThree {
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        top: 207px;
        /* bottom: 115px; */
        padding: 9px;
        color: #FFFFFF;
    }

    .mediaForshade {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 165%);
        font-size: 16px;
        font-weight: bold;
        color: black;
        padding: 4px 8px;
        text-align: center;
    }

    .sizeForText {
        font-size: 10px;
    }

    .positionForplaceholder::placeholder {
        position: absolute;
        top: 45%;
        left: 20%;
        color: white;
        font-size: 16px;
    }

    .mediaForVshade img {
        width: 40px;
    }

    .mediaForVshade {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 70%);
        text-align: center;
    }

}



@media (max-width: 360px) {
    .positionForheadTwo {
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        top: 200px;
        padding: 2%;
        color: whitesmoke;
    }

    .positionForheadThree {
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        top: 213px;
        padding: 2%;
        color: whitesmoke;
        width: -webkit-fill-available;
    }

    .positionForplaceholder::placeholder {
        position: absolute;
        top: 45%;
        left: 10%;
        color: white;
        font-size: 16px;
    }

    .positionForWings {
        height: 140px;
        position: absolute;
        top: 21%;
        width: 100%;
        text-align: center;
        animation: beat .50s infinite alternate;
        transform-origin: center;
    }

    .mediaForshade {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%, 175%);
        font-size: 12px;
        font-weight: bold;
        color: black;
        padding: 4px 8px;
        text-align: center;
    }

    .mediaForVshade img {
        width: 35px;
    }

    .mediaForVshade {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 90%);
        text-align: center;
    }
}