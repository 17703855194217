.golivemainForbackground {
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.webcamForstyle {
    width: "100%";
    height: "100%";
    position: "absolute";
    left: "50%";
    margin-left: "-50%";
    object-fit: "cover";
    object-position: "center";
}

.mobheaderstyle {
    position: absolute;
    color: #FFFFFF;
    font-family: 'Montserrat';
    z-index: 1;
}

.scrollForGolive {
    overflow: auto;
    overflow-x: hidden;
    max-height: 70vh;
}

.ShareOnlineUser {
    position: absolute;
    top: -8px;
    left: -12px;
}

.HeaderactiveForExplorePage {
    top: 1px;
    position: absolute;
    left: 37px;
}

.HeaderactiveForGoLiveIcon {
    top: 3px;
    position: absolute;
    left: 51px;
}

.HeaderactiveForGoLiveIconMedia {
    top: -3px;
    position: absolute;
    left: 37px;
}

.onHoverForshow {
    opacity: 0%;
}

.onHoverForshow:hover {
    opacity: 0.9;
}

.postitionForgoLivedrop {
    max-width: max-content;
    inset: 0px auto auto 305px !important;
}

.gl-setting-toggle-cont h5 {
    color: #808080;
}

.gl-setting-toggle-cont h4 {
    color: #4D4D4D;
}

.goliveFordrop.dropdown-menu {
    min-width: fit-content;
}

.goLiveDropDownMenuItems>.dropdown-item {
    font-size: 0.7rem !important;
}

.postitionStopForButton {
    margin-right: 11%;
}

.wglpausecircleForposition {
    position: relative;
    right: 516px;
}

.CommentForColor {
    background: rgba(0, 0, 0, 0.062) !important;
    color: #FFFFFF;
}

.InputForcolor {
    background: rgba(249, 251, 247, 0.4);
    border: 1px solid rgba(126, 178, 98, 0.1);
    border-radius: 5px;
    padding-right: 5%;
}

.iconForcolor {
    transform: rotate(45deg);
    transition: all 0.25s ease-in-out;
}

.commentInputForposition {
    position: absolute;
    right: 20px;
    bottom: 23px;
}
.smile{
    position: absolute;
    right: 20px;
}

.disableForButton .btn-primary:disabled,
.btn-primary.disabled {
    color: #81C14B;
    /* background-color: #0d6efd; */
    background: rgba(129, 193, 75, 0.2);
    border-color: #81C14B;
}

.positionTimerForMob {
    background-color: rgba(255, 255, 255, 0.411);
    font-size: x-large;
}

.goliveForheader {
    position: absolute;
    width: 100vw;
    top: 0px;
    color: #B8C6DB;
    font-weight: 500;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), transparent);
    -webkit-text-stroke-color: #FFFFFF;
}

.goliveForcampostition {
    position: absolute;
    width: 100vw;
    bottom: 0px;
    color: #B8C6DB;
    font-weight: 500;
    padding-top: 20px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
    -webkit-text-stroke-color: #FFFFFF;
}

.scrollForGoliveFilter {
    max-height: 200px; /* Adjust the max height as needed */
    overflow-y: auto;
  }
  .scrollForGoliveFilters {
    max-height: 385px;
    overflow-y: auto;
  }
  .scrollForGoliveFilters .dropdown-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .scrollForGoliveFilter .dropdown-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.MobForChat {
    position: absolute;
    z-index: 0;
    /* width: 100%; */
    max-height: 282px;
    overflow-y: scroll;
    /* background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.3)); */
    -webkit-mask-image: linear-gradient(356deg, white, transparent);
    padding-right: 1px;
    margin-left: -26px;
}

.gradient-blur {
    position: fixed;
    z-index: 5;
    inset: auto 0 0 0;
    height: 11%;
    pointer-events: none;
    bottom: calc(100% - 433px);
}

.gradient-blur>div,
.gradient-blur::before,
.gradient-blur::after {
    position: absolute;
    inset: 0;
}

.gradient-blur::before {
    content: "";
    z-index: 0;
    backdrop-filter: blur(0px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 12.5%,
            rgba(0, 0, 0, 1) 25%,
            rgba(0, 0, 0, 0) 37.5%);
}


.gradient-blur>div:nth-of-type(1) {
    z-index: 4;
    backdrop-filter: blur(1px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 37.5%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 1) 62.5%,
            rgba(0, 0, 0, 0) 75%);
}

.gradient-blur>div:nth-of-type(2) {
    z-index: 4;
    backdrop-filter: blur(0.9px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 37.5%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 1) 62.5%,
            rgba(0, 0, 0, 0) 75%);
}

.gradient-blur>div:nth-of-type(3) {
    z-index: 4;
    backdrop-filter: blur(0.5px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 37.5%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 1) 62.5%,
            rgba(0, 0, 0, 0) 75%);
}

.gradient-blur::after {
    content: "";
    z-index: 8;
    /* backdrop-filter: blur(32px); */
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 87.5%,
            rgba(0, 0, 0, 1) 100%);
}


.EditedChanges {
    position: fixed !important;
    z-index: 0;
    width: 100%;
    /* max-height: 222px; */
    overflow-y: scroll;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.3));

}

.mobPinnedChat {
    position: fixed;
    bottom: 40px;
}


.MobForChat::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.chatForpopup {
    /* background: rgba(233, 226, 226, 0.4); */
    padding: 0px 15px 0px 15px;
}

.sizeForEyesvg {
    font-family: 'Montserrat';
}

.positionCategoryForMob {
    font-family: 'Montserrat';
}
.form-control-test-Fs14_Fw_400{
    height:46px;
}


.smile{
    position:absolute;
     right:20px;
}
.wglpausecircleForposition {
    position: relative;
    right: 63px;
    top: -26px;
}
.heart-explosion {
    position: relative;
    
    
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    cursor: pointer;
  }
 
  
  
  .heart-emoji {
    position: absolute;
    font-size: 24px;
    
    animation: explode 1s ease-out infinite;
    animation-delay: calc(0.1s * var(--index));
    cursor: pointer;
  }
  .heart{
    position: absolute;
    right: 36%;
    bottom: 20px;
  }
  @keyframes releaseHeart {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }

  
  @keyframes explode {
    0% {
      transform: translateY() scale(0);
      opacity: 0;
    }
    100% {
      transform: translateY(-100px) scale(1);
      opacity: 1;
    }
  }


@media (max-width:1280px) {

    .wglpausecircleForposition {
        position: relative;
        right: 63px;
        top: -26px;
    }
    

}

@media (max-width:1000px) {
    .golive-comment-block {
        display: none;
    }
}


@media (max-width: 768px) {
    .wglpausecircleForposition {
        position: absolute;
        right: 14px;
        top: -192px;
    }

    .postitionStopForButton.wglpausecircle {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        bottom: 45px;
    }


    .commentInputForposition {
        position: absolute;
        right: 27px;
        bottom: 6px;
    }

    .positionTimerForMob {
        position: absolute;
        left: 9px;
        background-color: transparent;
        font-size: x-large;
        font-weight: 500;
        top: -480px;
    }

    .positionCategoryForMob {
        position: absolute;
        top: 84px;
        font-size: smaller;
    }

    .positionWatchingForMob {
        position: absolute;
        top: 100px;
        left: 1px;
    }

    .positionForEndbutton {
        position: absolute;
        top: -465px;
        right: 28px;
    }

}

@media (max-width: 567px) {

    .positionIconForMob {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .positionTimerForMob {
        position: absolute;
        top: -600px;
        right: 20px;
        background-color: transparent;
        font-size: x-large;
        font-weight: 500;
    }

    .positionCategoryForMob {
        position: absolute;
        top: 46px;
        font-size: smaller;
    }

    .positionWatchingForMob {
        position: absolute;
        top: 60px;
        left: 1px;
    }

    .positionMicForMob {
        position: absolute;
        right: 35px;
        top: -145px;
    }

    .positionSendForMob {
        position: absolute;
        right: 28px;
        top: -95px;
    }

    .positionMoreviewForMob {
        position: absolute;
        right: 33px;
        top: -50px;
    }

    .rotateIconMoreview {
        transform: rotate(90deg);
        transition: all 0.25s ease-in-out;
    }

    .postitionStopForButton.wglpausecircle {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        /* bottom: 50px; */

    }

    .inputboxForposition {
        display: block;
        position: relative;

    }

    .commentInputForposition {
        position: absolute;
        bottom: 5px;
    }

    .iconForcolor {
        transform: rotate(45deg);
        transition: all 0.25s ease-in-out;
        margin-left: 10px;
    }

    .InputForcolor {
        background: rgba(249, 251, 247, 0.4);
        border: 1px solid rgba(126, 178, 98, 0.1);
        padding-right: 24%;
        position: absolute;
        bottom: 0;
    }

    .wglpausecircleForposition {
        position: absolute;
        right: 416px !important;
    }

    .postitionStopForButton.wglpausecircle {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        bottom: 45px;
    }

}


@media (max-width: 425px) {
    .wglpausecircleForposition {
        position: relative;
        left: -15px;
        top: -192px;
    }

    .postitionStopForButton.wglpausecircle {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        bottom: 45px;
        display: none;
    }

    .positionForEndbutton {
        position: absolute;
        top: -535px;
        right: 28px;
    }

    .positionTimerForMob {
        position: absolute;
        left: 9px;
        background-color: transparent;
        font-size: larger;
        font-weight: 500;
        top: -500px;
    }
    .postitionForleftsidebar>div {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        top: 60px;
    }

}


@media (min-width: 300px){
    
    .postitionForleftsidebar>div {
        /* display: flex;
        flex-direction: column; */
     

        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        top: 31px;

    }
   
}
@media (min-width: 600px){
    
    .postitionForleftsidebar>div {
        /* display: flex;
        flex-direction: column; */
     

        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        top: 94px;

    }
}
@media (min-width: 400px){
    
    .postitionForleftsidebar>div {
        /* display: flex;
        flex-direction: column; */
     

        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        top: 70px;

    }
}

@media (max-width: 992px) {
    .wglpausecircleForposition {
        position: relative;
        left: -16px;
    }

    .postitionForleftsidebar {
        position: fixed;
        bottom: 0px;
        /* transform: translate(309px, -137px); */
        transform: translateY(calc(100% - 150%));
        right: 10px;
        height: 30vh;
        z-index: 1;

    }

    /* { for thr hewart animation } */

    .heart-explosion {
        position: relative;
        
        
        display: flex;

        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        cursor: pointer;
      }
      .heart-explosion:hover{
        cursor: pointer;
      }
      
      .heart-emoji {
        position: absolute;
        font-size: 24px;
        animation: explode 1s ease-out infinite;
        animation-delay: calc(0.1s * var(--index));
        cursor: pointer;
      }
      @keyframes releaseHeart {
        0%, 100% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
      }
  
      
      @keyframes explode {
        0% {
          transform: translateY() scale(0);
          opacity: 0;
        }
        100% {
          transform: translateY(-100px) scale(1);
          opacity: 1;
        }
      }
/* 
    .postitionForleftsidebar>div {
       
     

        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        top: 45px;

    } */

    .timecontrols {
        padding: revert;
        bottom: 0;
    }

    /* .postitionForleftsidebar>div:nth-child(1) {
        width: calc(100% / 15%);
        margin-top: calc(100% / 5);
    }

    .postitionForleftsidebar>div:nth-child(3) {
        width: calc(100% / 15%);
        margin-top: calc(100% / 5);
    }

    .postitionForleftsidebar>div:nth-child(4) {
        width: calc(100% / 15%);
        margin-top: calc(100% / 5);
    }

    .postitionForleftsidebar>div:nth-child(5) {
        width: calc(100% / 15%);
        margin-top: calc(100% / 5);
    } */

    .postitionStopForButton.wglpausecircle {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        bottom: 45px;
    }

    .postitionStopForButton {
        display: none;
    }

    .positionCategoryForMob {
        position: absolute;
        top: 45px;
        font-size: smaller;
    }

    .positionTimerForMob {
        position: fixed;
        left: 9px;
        background-color: transparent;
        font-size: large;
        font-weight: 500;
        top: 53px;
    }

    .positionForEndbutton {
        position: fixed;
        top: 10px;
        left: 14px;
        width: 60px;
    }

    .positionWatchingForMob {
        position: absolute;
        top: 85px;
        z-index: 1;
        left: -4px;
    }

    .positionIconForMob {
        position: absolute;
        top: 63px;
        left: 84px;
    }

    .sizeForEyesvg svg {
        width: 28px;
        height: 15px;
    }

    .InputForcolor {
        background: rgba(249, 251, 247, 0.8);
        border: 1px solid rgba(126, 178, 98, 0.1);
        padding-right: 24%;
        /* position: absolute; */
        bottom: 0;
        position: fixed;

        margin-left: -15px;
    }

    .InputForcolor1 {
        background: rgba(249, 251, 247, 0.40);
        border: 1px solid rgba(126, 178, 98, 0.1);
        padding-right: 24%;
        /* position: absolute; */
        bottom: 0;
        position: fixed;

        margin-left: -30px;
        opacity: 0.70;
    }
    .inputgolive {
        background: #c4c4c4;
        border: 1px solid rgba(126, 178, 98, 0.1);
        padding-right: -67%;
        /* position: absolute; */
        bottom: 0;
        position: fixed;
        margin-left: -16px;
    }

    .commentInputForposition {
        position: fixed;
        right: 14px;
        bottom: 7px;
    }
    .commentInputForposition1 {
        position: fixed;
        right: 20px;
        bottom: 7px;
    }

}



@media (max-width: 480px){
.show1 {
    left: -1px;
}
.dropshow{

}
}

@media (max-width: 360px){
.show1 {
    left: -1px;
}
.dropshow{
 left: -28px !important;
}
.checkbox{
    top: -8px;
   }

}







/* animation for the comment box  */

@keyframes commentOpen {
    from {
        height: 100%;
    }

    to {
        height: 100vh;
    }
}


.Fs14_Fw_400 {
    font-size: 14px !important;
    font-weight: 400 !important;
}


.goLiveRedBtn {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}
@media (max-width: 320px){
.ShareOnlineUser {
    position: absolute;
    top: 2px;
    left: -12px;
}
.playcircle{
    margin-right: -1px !important;
    padding-left: 3px;
}
.playci{
    margin-left: 5px;
}
.sendtext{
    padding-left: 13px;
}
.golivered{
    width: 90px;
    padding-left: 8px;
}
.textvideo{
    left: -16px ;
    padding-right: 2px;
}
.dropshow{
    left: -46px !important;
   }

   .drouparrow1 {
    position: absolute;
    right: 12px !important;
}
.priv{
    width: 175px;
   }
.golivehead{
    margin-top: 1.5rem;
}
}

@media (max-width: 331px){
    .readylive {
        width: 150px;
    }
}

@media (max-width: 375px){
   .checkbox{
    top:-8px;

   }
}
@media (max-width: 728px){
    .goliveheader{
        position: relative;
    left: 14px;
    width: 87%;

    }
}
@media (max-width: 768px){
    .goliveheader{
        position: relative;
        left: 2px;
    width: 99% !important;

    }
    .serchfiltergolive{
        margin-left: 6rem;
      
    }
}
@media (max-width: 466px){
    .goliveheader{
        position: relative;
        left: 9px;
        width: 96% !important;
    }
    .show1{
        left: 1px;
    }
  
}
@media (max-width: 412px){
    .goliveheader{
        position: relative;
        left: 9px;
        width: 94% !important;
    }
    .padbutton{
        padding: 8px 4px;
       }
    .priploi{
        left: -10px;
        width: 241px;
    }
    .dropshow{
        left: -13px !important;
       }
  .checkbox{
        top:-8px;
    
       }
}


@media (max-width: 766px){
    .goliovehead{
        
       position: absolute;
       top: 10px;
    }
}
@media (max-width: 390px){
    .goliovehead{
        
      width: 96% !important;
    }
    .dropshow{
        left: -20px !important;
       }
    .checkbox{
        top:-8px;
    
       }
}
@media (max-width: 384px){
    .dropshow{
        left: -23px !important;
       }
    
}
/* @media (max-width: 280px){
    .goliovehead{
        
      width: 96% !important;
    }
    
    .textpadd{
        padding-left: 2px;
    }
} */
@media (max-width: 766px){
    .serchfiltergolive{
        margin-left: 11rem;
      
    }
}
@media (max-width: 393px){
    .checkbox{
        top:-8px;
    
       }
}
@media (max-width: 342px){
    .serchfiltergolive{
        margin-left: 5rem;
      
    }
}
@media (max-width: 500px){
    .serchfiltergolive{
        margin-left: 4rem;
        margin-right: -5px;
      
    }
    .icon2{
        margin-left: -7px;
    }
}
@media (max-width: 314px){
    .serchfiltergolive{
        margin-left: 3.5rem;
        margin-right: -13px;
    }
   
}
/* @media (max-width: 625px){
    .serchfiltergolive{
        position: relative;
        left: 10%;
      
    }
}
@media (max-width: 625px){
    .serchfiltergolive{
        position: relative;
        left: 10%;
      
    }
} */


@media (max-width: 280px) {
    .srechgolive{
        margin-left: -4px;
    }
    .golivered{
        padding: 9px 8px;
    }
    .goliovehead{
        position: absolute;
        top: 13px;
        left: -1px !important;
    }
    .filter{
        margin-left: -5px !important;
        
    margin-right: -6px !important;
    }
    .serchfiltergolive {
        margin-left: 3.15rem;
        margin-right: -13px;
    }
    .livgif{
        height: 24px;
    }
    .textpadd{
        padding-left: 4px;
    }

    .show1{
        left: -58px !important;
    }
    .down2{
        margin-right: -14px;
        right: -4px;
       }
        
       .down{
       right: -17px;
       }
        
       .goliovehead{
        
        width: 96% !important;
      }
      
      .textpadd{
          padding-left: 2px;
      }
      .dropshow{
        left: -1px !important;
       }
       .priv{
        width: 175px;
       }
       .checkbox{
        top: -8px;
       }
    }
    

@media (max-width: 350px) {
    .goliveheader {
        position: relative;
        left: 2px;
        width: 98% !important;
        top: 7px;
        margin-top: 23px;
    }
    .padbutton{
        padding: 9px 8px;
    }
}
@media (max-width: 322px) {

    .seeall {
        width: 101px;
        position: relative;
        left: 32px;
    }
    .down2{
        margin-right: -14px;
        right: -4px;
       }
}
@media (max-width: 358px) {

   .padbutton{
    padding: 8px 4px;
   }
}

.goliovehead{
    position: absolute;
    top: 13px;
    left: 3px;
}



.custom-select {
    position: relative;
    display: inline-block;
  }
  
  .custom-select select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 10px;
    color: #4d4d4d;
  }
  
  .custom-select__arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none; /* Ensure the arrow doesn't interfere with the select box */
    color: #6E6B7B; /* Color for the chevron-down icon */
    font-size: 16px; /* Adjust the font size as needed */
  }
  

  /* @media (max-width: 280px) {
    .golivered.padbutton {
      
      padding: 6px 8px; 
      font-size: 14px; 
    }

    .drouparrow1{
        position: absolute;
        right: 12px !important;
      }
      .textvideo{
        left: -14px;
        padding-right: 2px;
    }
   
  
  } */

  @media (max-width: 333px) {

    .searrch{
        padding: 10px 10px 10px 18px !important;
    }

  }
  @media (max-width: 542px) {

    .textvideo{
        left: -10px;
        padding-right: 2px;
    }
    .show1{
        left: -15px;
    }
    .show3{
        left: -10px;
    }
  
   
  }
  @media (max-width: 315px) {

    .filter{
        padding: 8.5px 5px !important;
    }
  }
  @media (max-width: 393px) {

    .golivebutton{
        position: absolute;
        right: 13px;
    
    }
    .priploi{
        left: -11px;
    width: 242px;
    }
    .dropshow{
        left: -32px !important;
       }
    
  }

  .drouparrow{
    position: absolute;
    right: 22px;
  }
  .drouparrow1{
    position: absolute;
    right: 22px;
  }



.golivebutton{
    position: absolute;
    right: 13px;

}

.smileemoji{
    position: absolute;
    right: 31px;
}





