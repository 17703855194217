/*================= start_Post_creation_css ==========================*/
.Creation_main_blk {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
    border-radius: 5px;
    /* padding: 30px; */
    padding: 0.75rem
}

/* @media (max-width:576px) {
    .Creation_main_blk {
        padding: 20px;

    }

} */
.Card_Main_blk_section {
    max-height: 320px;
}

.Creation_Header_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Creation_Header_section h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #4D4D4D;
}

.GoLive_section {
    color: #808080;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
}

.Creation_Header_section span {
    color: #808080;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
}

.Public_dropDown_menu.dropdown-menu.show {
    width: fit-content;
    position: absolute !important;
    will-change: transform !important;
    top: 0px !important;
    left: 0px !important;
    transform: translate3d(-71px, 30px, 0px) !important;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: none;
}

.Creation_footer_section {
    padding-left: 16px;
    padding-right: 16px;
}

/* @media (max-width:576px) {
    .Creation_footer_section {
        padding-left: 5px;

    }
} */

.Activity_btn_blk {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50% !important;
    border: none;
    color: #A6A6A6;
    background: #F6F6F6;
    font-weight: 600;
    font-size: 13px;
    /* letter-spacing: 0.15rem; */
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.Activity_btn_blk:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F6F6F6;
    border-radius: 50% !important;
    border: none;
    z-index: -2;
}

.Activity_btn_blk:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #81c14b;
    transition: all 0.3s;
    border-radius: 50% !important;
    border: none;
    z-index: -1;
}

.Activity_btn_blk.btn:hover {
    color: #fff;
}

.Activity_btn_blk.btn:hover:before {
    width: 100%;
}

/*===============End_Post_creation ==========================*/

/*===============Start_Post_creation_Footer_blk ==========================*/

.Activity_btn_Postblk {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 10rem;
    border: none;
    color: #A6A6A6;
    background: #F6F6F6;
    font-weight: 600;
    font-size: 13px;
    /* letter-spacing: 0.15rem; */
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.Activity_btn_Postblk:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F6F6F6;
    border-radius: 10rem;
    border: none;
    z-index: -2;
}

.Activity_btn_Postblk:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #81c14b;
    transition: all 0.3s;
    border-radius: 10rem;
    border: none;
    z-index: -1;
}

.Activity_btn_Postblk.btn:hover {
    color: #fff;
}

.Activity_btn_Postblk.btn:hover:before {
    width: 100%;
}

.NewActiviy_btn_PostBlk {
    border: none;
    font-weight: 600;
    font-size: 13px;
    color: #A6A6A6;
    background: #F6F6F6;
    padding: 8px;
    border-radius: 10rem;
    transition: all 500ms ease-out;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.NewActiviy_btn_PostBlk:hover {
    filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.24));
    background: #81C14B;
    color: #FFFFFF;
    /* box-shadow: 0 5px 15px rgba(92, 182, 104, 0.4); */
}

.NewEdit_btn_blk {
    display: flex;
    align-items: center;
    border: none;
    color: #A6A6A6;
    background: #F6F6F6;
    padding: 8px;
    border-radius: 50% !important;
    transition: all 500ms ease-out;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.NewEdit_btn_blk:hover {
    filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.24));
    background: #81C14B;
    color: #FFFFFF;
}

/*===============End_Post_creation_Footer_blk ==========================*/

.zoom {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 10rem;
    border: none;
    color: #A6A6A6;
    background: #F6F6F6;
    font-weight: 600;
    font-size: 13px;
    /* letter-spacing: 0.15rem; */
    position: relative;
    overflow: hidden;
    z-index: 1;
    /* padding: 50px; */
    /* background-color: green; */
    transition: all 0.3s;
    margin: 0 auto;
}

.zoom:hover {
    color: #fff;
    background-color: #81c14b;
    -ms-transform: scale(1.10);
    /* IE 9 */
    -webkit-transform: scale(1.10);
    /* Safari 3-8 */
    transform: scale(1.10);
}

/*====================== Post_cretion ============================*/

.Edit_btn_blk {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 50% !important;
    border: none;
    color: #A6A6A6;
    background: #F6F6F6;
    font-weight: 600;
    font-size: 13px;
    /* letter-spacing: 0.15rem; */
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
}


.post_creation_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px !important;
    border-radius: 50% !important;
    z-index: 1;
}

.post_creation_btn:active {
    background-color: #81c14b !important;
    color: #fff !important;
}

.Edit_btn_blk:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #F6F6F6;
    border-radius: 10rem;
    border: none;
    z-index: -2;
}

.Edit_btn_blk:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #81c14b;
    transition: all 0.3s;
    border-radius: 10rem;
    border: none;
    z-index: -1;
}

.Edit_btn_blk.btn:hover {
    color: #fff;
}

.Edit_btn_blk:hover:before {
    width: 100%;
}

/*================ Start_Modal_Animation_css ===============*/

.creation_modal_blk {
    animation: blowUpModal 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards !important;
    height: 60vh !important;
}




@keyframes blowUpContent {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    99.9% {
        transform: scale(2);
        opacity: 0;
    }

    100% {
        transform: scale(0);
    }
}

@keyframes blowUpContentTwo {
    0% {
        transform: scale(2);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes blowUpModal {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes blowUpModalTwo {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0);
        opacity: 0;
    }
}

/*================ End_Modal_Animation_css ===============*/
button.TagsMain_btn_blk.btn.btn-secondary {
    border: none;
    color: #000;
}

button.TagsMain_btn_blk.btn.btn-secondary::after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    direction: rtl;
    z-index: -1;
    box-shadow:
        -7px -7px 20px 0px #fff9,
        -4px -4px 5px 0px #fff9,
        7px 7px 20px 0px #0002,
        4px 4px 5px 0px #0001;
    transition: all 0.3s ease;
}

button.TagsMain_btn_blk.btn.btn-secondary:hover {
    color: #000;

}

button.TagsMain_btn_blk.btn.btn-secondary::after {
    left: auto;
    right: 0;
    width: 100%;
}

button.TagsMain_btn_blk.btn.btn-secondary:active {
    top: 2px;
}





.btn-16 {
    border: none;
    color: #000;
}

.btn-16:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    direction: rtl;
    z-index: -1;
    box-shadow:
        -7px -7px 20px 0px #fff9,
        -4px -4px 5px 0px #fff9,
        7px 7px 20px 0px #0002,
        4px 4px 5px 0px #0001;
    transition: all 0.3s ease;
}

.btn-16:hover {
    color: #000;
}

.btn-16:hover:after {
    left: auto;
    right: 0;
    width: 100%;
}

.btn-16:active {
    top: 2px;
}

/* //==================start_Feeling_Activity ========================// */

.FellingActivity_main_blk {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Modal_False_blk {
    position: absolute;
    top: -31px;
    right: -15px;
    cursor: pointer;
}

input.form-control.Feeling-control {
    border-radius: 25px !important;
}

.Feeling_Main_blk_section {
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: rgba(129, 193, 75, 0.2);
    padding: 15px 10px;
    cursor: pointer;
}

.Feeling_Main_blk_Subsection {
    display: flex;
    align-items: center;
    border-radius: 5px;
    /* background: #F1F1F1; */
    padding: 15px 10px;
    cursor: pointer;
}

.Feeling_Main_blk_Subsection:hover {
    background: rgba(129, 193, 75, 0.2);
    cursor: pointer;
}

.Feeling_scroll_box {
    max-height: 350px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

/* //==================End_Feeling_Activity ========================// */
.Creation_scroll_box {
    max-height: 450px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

li.Activity_setting {
    background: rgba(129, 193, 75, 0.2);
    cursor: pointer;
    border-radius: 5px;
}

li.Sub_Activity:hover {
    background: rgba(129, 193, 75, 0.2);
    cursor: pointer;
    border-radius: 5px;
}

.UpdateActivity_blk_section {
    padding: 5px 10px;
    width: 17%;
    cursor: pointer;
    background: #F1F1F1;
    border-radius: 30px;
}

.UpdateActivity_blk_section h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #4D4D4D;
}

/* //==================start_Media_secction_blk ========================// */

.AddMedia_photo_section {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    padding: 10px;
}

.Addmedia_sub_section {
    background: #FFFFFF;
    background-clip: border-box;
    border: none;
    border-radius: 5px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
    cursor: pointer;
}
.AddMedia_photo_section_one {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    padding: 5px; /* Reduced padding */
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    overflow: hidden;
}

.Addmedia_sub_section_one {
    background: #FFFFFF;
    background-clip: border-box;
    border: none;
    border-radius: 5px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
    cursor: pointer;
    padding: 0; /* Removed padding to reduce the gap */
    text-align: center;
    overflow: hidden;
}

@media (max-width: 768px) {
    .AddMedia_photo_section_one {
        width: 100%;
        max-width: 100%;
    }

    .Addmedia_sub_section_one {
        height: 200px;
    }
}

.Media_Img_section_one {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0; /* Ensure no padding around the image */
    margin: 0; /* Ensure no margin around the image */
}

.Media_Img_section_one img {
    width: 100%; /* Make sure the image takes the full width of its container */
    height: 100%; /* Make sure the image takes the full height of its container */
    object-fit: cover;
    border-radius: 5px; /* Matches the card's border radius */
}

.Img_right_section_one {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}




.AddmediaOne_sub_section {
    height: 500px;
}

/* .Addmedia_sub_section:hover {
    background: #f1f1f1;
    cursor: pointer;
} */

.Media_Img_section {
    width: 100%;
    position: relative;
    height: 250px;
}

.Media_Img_section img {
    border-radius: 5px;
    height: 250px;
    width: 100%;
    object-fit: cover;
    border: 1px solid #fff;
}


.MediaOne_Img_section {
    width: 100%;
    position: relative;
    height: 500px;
}

.MediaOne_Img_section img {
    border-radius: 5px;
    height: 500px;
    width: 100%;
    object-fit: cover;
    border: 1px solid #fff;
}

/* //==================end_Media_secction_blk ========================// */


.Img_right_section {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;

}

.AddMore_left_section {
    position: absolute;
    left: 73px;
    top: 8px;
    cursor: pointer;
    background: #F6F6F6;
    color: #808080;
    font-weight: 400;
    font-size: 12px;
    border-radius: 5px;
    padding: 5px 10px;
}

.Edit_left_section {
    position: absolute;
    left: 8px;
    top: 8px;
    cursor: pointer;
    background: #F6F6F6;
    color: #808080;
    font-weight: 400;
    font-size: 12px;
    border-radius: 5px;
    padding: 5px 10px;
}

.Create_Post_Footer_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-left: 28px;
    margin-right: 28px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 9px;
}

.Create_post_btn {
    background: #ACDAD6;
    border-radius: 5px;
    border: none;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
}

/* .Create_post_btn:hover {
    background: #81C14B !important;
    color: #FFFFFF;
} */

.btn_active_blk {
    background: #81C14B !important;
    color: #FFFFFF;
}

/*======================== Start_Post_Privacy_Css =========================*/

.Public_Creation_blk {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 10px 20px;
    cursor: pointer;
}

.Post_Audience_blk {
    background: rgba(129, 193, 75, 0.2);
    border-radius: 5px;
    cursor: pointer;
}

.Post_Audience_Subblk:hover {
    background: rgba(129, 193, 75, 0.2);
    border-radius: 5px;
    cursor: pointer;
}

.Public_Icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #DDDDDD;
    border-radius: 20px;
}

/*======================== End_Post_Privacy_Css =========================*/

.tag_ppl_main_blk_section {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 10px;
}

.tag_ppl_main_blk_section:hover {
    background: #f1f1f1;
    border-radius: 5px;
    cursor: pointer;
}

.Tag_ppl_main_blk {
    display: flex;
    align-items: start;
}

.Tag_ppl_main_blk:hover {
    background: #f1f1f1;
    border-radius: 5px;
    cursor: pointer;
}

.Tag_ppl_main_blk h3 {
    color: #4D4D4D;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
}

.Tag_ppl_main_blk p {
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #808080;
}

.HashTag_ppl_main_blk {}

.HashTag_ppl_main_blk:hover {
    background: #f1f1f1;
    border-radius: 5px;
    cursor: pointer;
}

.HashTag_ppl_main_blk h3 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
}

.HashTag_ppl_main_blk p {
    margin-top: 3px;
    color: rgba(0, 0, 0, 0.3);
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;

}

.Check_in_location_blk {
    display: flex;
    align-items: center;
    padding: 10px;
}

.Check_in_location_blk:hover {
    background: #f1f1f1;
    border-radius: 5px;
    cursor: pointer;
}

.Check_in_location_blk h3 {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #4D4D4D;
}

.Map_icon_blk {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(126, 178, 98, 0.05);
    border-radius: 5px;
    width: 35px;
    height: 35px;
    position: relative;
}

.CreatePostselection {
    display: flex !important;
    align-items: center !important;
    border: 1px solid #F6F6F6 !important;
    font-size: 12px !important;
    color: #808080 !important;
}

.CreatePostselection:hover {
    background: rgba(129, 193, 75, 0.2) !important;
    border-radius: 15px !important;
    cursor: pointer !important;
}

button.AlertPost_DropSelect_section.btn.btn-outline-primary {
    background: #F6F6F6;
    border-radius: 5px;
    border: none;
    color: #4D4D4D;
}

.Alert_Post_span {
    font-size: 12px;
    line-height: 18px;
}

button.AlertPost_DropSelect_section.btn.btn-outline-primary:hover {
    color: #fff !important;
    border-radius: 5px !important;
    border: none !important;
}

.Alert_menu.dropdown-menu.show {
    position: absolute;
    will-change: transform;
    width: 100%;
    top: 0px;
    left: 0px;
    transform: translate3d(0px, -72px, 0px);
    padding: 0px !important;
    margin: 0px !important;
    margin-top: 2px !important;
    font-size: 12px;
    font-weight: 500;
    color: #212529 !important;
    text-align: left !important;
    list-style: none !important;
    background-color: #ffffff !important;
    background-clip: padding-box !important;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.25rem !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

button.Alert_item.dropdown-item {
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 600;
    color: #4D4D4D;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 12px;
}

button.Alert_item.dropdown-item:hover {
    color: #fff !important;
    background: #81c14b !important;
}

.Main_creation_footer-section {
    padding: 0.5rem 1rem
}

.Recommendation_input_box {
    background: #F6F6F6 !important;
    border-radius: 5px !important;
}

.Events_date_section {
    position: relative;
}

.Events_input_blk {
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 7px;
}

.Friend_except_main_blk {
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 10px;
}

.Friend_except_main_blk:hover {
    background: #f1f1f1;
    border-radius: 5px;
    cursor: pointer;
}

.Friend_except_main_blk h3 {
    color: #4D4D4D;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
}

.Friend_except_main_blk p {
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #808080;
}

.Color_picker_actual_Blk {
    padding: 3px;
}


.ColoPicker_actual_state {
    display: flex;
    background: #F2F3F5;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 65px;
    cursor: pointer;
}

.Text_onChange_section_one {
    width: 30px;
    height: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 65px;
    cursor: pointer;
    transition: width 0.2s, height 0.2s; /* Smooth transitions for resizing */
  }
  
  /* Media queries to make it responsive on smaller screens */
  @media (max-width: 768px) {
    .Text_onChange_section_one {
      width: 26px;  /* Reduce width on smaller screens */
      height: 26px; /* Reduce height on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .Text_onChange_section_one {
      width: 24px;  /* Further reduce for very small screens */
      height: 24px;
    }
  }
  

.scrollbarnone::-webkit-scrollbar {
    display: none;
}


.Text_onChange_active_blk {
    border: 2px solid #16C31E;
    border-radius: 65px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 3px;
    cursor: pointer;
}

.Text_onChange_Unactive_blk {
    border: 2px solid transparent;
    padding: 3px;
    cursor: pointer;
    border-radius: 65px;

}

.Trhied_media_blk {
    position: relative;

}

.Trhied_media_blk img {
    filter: brightness(.4);
}

.Update_plus_count {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
}

.Update_plus_count span {
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;

}

.Arrow_main_blk_section {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #e4e6eb;
    transition: 0.1s ease-in-out;
}

.Arrow_main_blk_section:hover {
    background: #d5d8df;

}

.Schedule_post_blk_section {
    background: #F6F6F6;
    border-radius: 5px;
    padding: 10px 20px;
}

.editorClassName.bg-light.border.Artical_blk.rdw-editor-main {
    min-height: 170px !important;
    padding: 5px !important;
    border: none !important;
    border-radius: 5px !important;
    /* background: #F6F6F6 !important; */
    margin-top: 390px !important;
    background: none !important;
    word-wrap: break-word;

}

.rdw-editor-toolbar.toolbar-class.Artical_tool_bar {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    border-radius: 5px !important;
}

.xyz {
    position: relative;


}

.Main_Heading_main_blk_center {
    display: flex;
    justify-content: center;
}

.dpj_here {
    position: absolute;
    top: 31px;
    width: 75% !important;
}

@media (max-width:992px) {
    .dpj_here {
        position: absolute;
        top: 80px;
        width: 100% !important;
    }
}

@media (max-width:576px) {
    .dpj_here {
        position: absolute;
        top: 100px;
        width: 100% !important;
    }
}

@media (max-width:375px) {
    .dpj_here {
        position: absolute;
        top: 120px;
        width: 100% !important;
    }
}

.Artical_main_Headin_input_blk {
    height: 52px;
    padding-top: 15px;
    padding-bottom: 0;
    overflow: auto;
    box-shadow: none;
    font-weight: var(--font-weight-bold);
    color: var(--color-text);
    font-size: 42px;
    line-height: 40px;
    margin: 3.2rem 0;
    min-height: 4.8rem;
    overflow-y: hidden !important;
    resize: none;
    /* max-width: -webkit-fill-available; */
    margin: 47px 32px 4px 0;
    /* border: 2px solid #212529 !important; */
    border: none !important;
    background: none !important;
}

.Artical_main_Headin_input_blk:hover {
    border: 2px solid #212529 !important;
}


.Artical_main_Headin_input_blk::placeholder {
    color: var(--color-text);
    font-size: 42px;
    line-height: 48px;
}

textarea.Artical_main_Headin_input_blk.form-control:focus-visible {
    border: 2px solid #212529 !important;
}

.Main_Editor_artical_blk {
    margin-top: 91px;
    display: flex;
    justify-content: center;
    min-height: 100%;
}

.Artical_Addmedia_sub_section {
    background: #F6F6F6 !important;
    background-clip: border-box;
    border: none;
    border-radius: 5px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Artical_Addmedia_sub_section img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 5px !important;
}

.next_btn_blk {
    position: absolute;
    top: 12px;
    right: 94px;
}

button.artical_next_btn.btn.btn-primary {
    padding: 2px 22px !important;
    text-transform: capitalize;
    font-size: calc(11px + (12 - 11) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    border-radius: 10px !important;
    display: flex;
    align-items: center;
}

.Post_creation_text_limt {

    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    color: #C4C4C4;
}

.Suggest_tag_btn {
    cursor: pointer;
    color: #81c14b;
    font-size: 16px;
    font-weight: 500;

}


.Filter_Img_Blk {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.FilterImages {
    flex: 1 1 auto;
    aspect-ratio: 4/4;
    width: calc((100% / 3) - 10px);
    max-width: calc((100% / 3) - 10px);
    /* border: 1px solid gray; */
    border-radius: 5px;
    margin-bottom: 10px;

}

.FilterImages img {
    object-fit: cover !important;
    width: 100% !important;
    aspect-ratio: 4/4;
    border-radius: 5px;
}


.FilterImages>h3 {
    font-size: calc(100vh / 49);
    margin-top: 3px;
}

.filter_scroll_box {
    max-height: 420px !important;
    /* max-height: calc([Filter_Img_Blk] * 3  + 50px); */
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.Schedule_Post_Preview {
    position: absolute;
    top: -6px;
    right: 0px;
    z-index: 2;
    background-color: #81c14b;
    color: #ffff;
    border-radius: 10px;
    padding: 0px 5px;
    font-size: 11px;
}

.Active_blk_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50% !important;
    border: none;
    font-weight: 600;
    font-size: 13px;
}

textarea.border-0.inputFortext.inputForback.actualArea.form-control {
    font-size: x-large;
    line-height: 24px;
}

.Update_Tag_suggest_blk {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.Update_Image {
    position: relative;
}

.Update_Image img {
    border-radius: 50%;
}

.delete_img {
    position: absolute;
    top: -2px;
    right: -4px;
    cursor: pointer;
    background: #E6E6E6;
    border: 1px solid #FFFFFF !important;
    border-radius: 100px;
    width: 18px;
    height: 18px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.Update_HashTag_blk {
    background: #F6F6F6;
    border-radius: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #808080;
    cursor: pointer;
}

svg#PplTooltip:hover {
    fill: #fff;
}

svg#PplTooltip:focus {
    fill: #fff;
}

button.Activity_btn_blk.btn-icon.pr-0.btn.btn-secondary {
    height: 100% !important;
}

.emoji-picker.light.visible {
    position: absolute !important;
    inset: 0px auto auto 0px !important;
    z-index: 9999 !important;
    /* z-index: 2; */
    /* z-index: revert; */
    /* z-index: 9999; */
    /* z-index: auto; */
    transform: translate(939px, 345px) !important;
    border: none !important;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.2) !important;
    border-radius: 5px !important;
}

.emoji-picker__content {
    padding: 0.5em !important;
    height: 13rem !important;
    overflow: hidden;
    position: relative;
}

.Emoji_Btn_section {
    display: flex;
    align-items: end;
}

.Emoji_Btn_section>button {
    border: none !important;
    /* margin: 5px !important; */
    margin-top: "24px";
    font-size: 20px !important;
    background: none !important;
}

.emoji-picker__tab.active {
    background: #81C14B !important;
    color: #FFFFFF !important;
}

.Post_Display_Crad {
    border-radius: 5px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px;
    position: relative;
    overflow: hidden;
    border: none;
}

.Post_Dispaly_Card_body {
    padding: 0 !important;
}

.PostPreView_User_Info h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #4D4D4D;
}

.UserProfile-Name:hover {
    cursor: pointer;
    text-decoration: underline;
}

.Profile_Location {
    text-decoration: #808080 underline;
    font-size: 14px;
    cursor: pointer;
}

.Profile_Location:hover {
    text-decoration: #81C14B underline;
}

.PostPreView_User_Info h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(77, 77, 77, 0.5);
}

/*======================= Start_Social_Feed_Post_disPlay_css ===================*/
.SingleMedia_blk img {
    width: 100% !important;
    object-fit: cover !important;
    height: 278px !important;
}
.SingleMedia_blk_1 img {
    width: 100% !important;
    object-fit: cover !important;
    height: 400px !important;
}

.SingleMedia_Heading_blk h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

.SingleMedia_Content_blk h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
}

.Text_Card_blk h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
}

.SingleCaptionMedia_blk {
    position: relative;
}

.SingleCaptionMedia_blk .Caption_Img {
    width: 100% !important;
    object-fit: cover !important;
    height: 278px !important;
}
.SingleCaptionMedia_blk_1 img {
    width: 100% !important;
    object-fit: cover !important;
    height: 400px !important;
}



.DoubleCaptionMedia_blk {
    display: flex;
    position: relative;
}

.DoubleCaptionMedia_blk .Double_Img {
    width: 100% !important;
    object-fit: cover !important;
    height: 278px !important;
    position: relative;
}

.DoubleCaptionMedia_blk .Double1_Img {
    width: 100% !important;
    object-fit: cover !important;
    height: 278px !important;

}


.Caption_box_section {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
    height: 53px;
    width: 100% !important;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
}

.Caption_box_section h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    margin-left: 20px !important;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
}

.RecommendationMedia_blk {
    position: relative;
}

.RecommendationMedia_blk .Caption_Img {
    width: 100% !important;
    object-fit: cover !important;
    height: 278px !important;
}


.Recommendation_box_section {
    display: flex;
    justify-content: space-between;
    background: #235363af;
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
    height: 53px;
    width: 100% !important;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0
}

.Recommendation_box_section h3 {
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.RecmndNo_Imgbox_section {
    display: flex;
    justify-content: space-between;
    background: #235363af;
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
    height: 53px;
    width: 100% !important;
    display: flex;
    align-items: center;
}

.RecmndNo_Imgbox_section h3 {
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
}

.Single_video_blk {
    position: relative;
}

.Single_video_blk img {
    width: 100%;
    object-fit: cover;
    height: 278px !important;
}

.Single_Video_Postion {
    position: absolute;
    top: 50%;
    width: 100%;
}

.Poll_Progress_Bar {
    background: #E5F0E0;
    border-radius: 35px;
    height: 50px;
}

.Paid_PodcastImg_blk {
    position: relative;

}

.Paid_PodcastImg_blk .Paid_Img {
    object-fit: cover;
    width: 100%;
    height: 310px;
}

.Paid_podcast_Top_section {
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    /* padding: 15px; */
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    top: 0;
}

.Paid_podcast_bottom_section {
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 3px;
    bottom: 0;
}

.Paid_Content_inner h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

.Paid_Content_inner h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
}

.paid_bottom_content_inner {
    display: flex;
    align-items: start;

}

.paid_bottom_content_inner .Ep_thum_img {
    border-radius: 5px;
    width: 70px;
    height: 70px;
    object-fit: cover;
}

.paid_User_dscrpn {
    margin-left: 15px;
    margin-right: 10px;
}

.paid_User_dscrpn h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

.paid_User_dscrpn h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;
}

.Singlepaid_User_dscrpn {
    margin-right: 10px;
}

.Singlepaid_User_dscrpn h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

.Singlepaid_User_dscrpn h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;
}

.Paid_Ep_btn {
    width: 20%;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}

.Eplist-blk-btn {
    background: rgba(255, 255, 255, 0.3);
    /* border: 1px solid #c4c4c4; */
    border-radius: 14px;
    display: inline-block;
    padding: 3px 10px;
    /* margin: 0 10px 10px 0; */
    color: #FFFFFF;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}

.Paid_bottom_tag {
    position: absolute;
    bottom: 0;
    right: 0;
}
.paid{
    
    bottom: 0;
    right: 0;
}

.ep_mic_icon {
    width: 22px;
    height: 22px;

}

/* .positionForMainPodacastIcon {
    position: absolute;
    top: 50%;
    text-align: center;
    width: 100%;

} */
.SinglePodacastIcon {
    margin: 0;
    position: absolute;
    left: 0%;
    top: 42%;
    width: 100%;
    text-align: center;
}



.paidSeries_bottom_content_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.paidSeries_bottom_content_inner .EpSeries_thum_img {
    position: relative;
    border-radius: 5px;
    width: 67px;
    height: 70px;
    object-fit: cover;
}

.PaidSeries_count {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    position: absolute;
    width: 41.13px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 27px;
    top: 27px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;

}

.paidSeries_User_dscrpn {
    margin-left: 15px;
    margin-right: 10px;
}

.paidSeries_User_dscrpn h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

.paidSeries_User_dscrpn h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;
}

.SeriesPaid_podcast_Top_section {
    position: absolute;
    top: 0;
    left: 0;
}

.EpisodePc_User_dscrpn {
    margin-left: 15px;
}

.EpisodePc_User_dscrpn h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

.EpisodePc_User_dscrpn h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;
}

.SingleUpload_User_dscrpn {}

.SingleUpload_User_dscrpn h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

.SingleUpload_User_dscrpn h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #FFFFFF;
}

.Events_Card_blk {
    position: relative;
}

.Events_Card_blk img {
    width: 100%;
    object-fit: cover;
    height: 278px !important;
}

.Event-blk-Activebtn {
    background: rgba(129, 193, 75, 0.1);
    border: 1px solid #81C14B;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    padding: 5px 10px;
    cursor: pointer;
}

.Event-blk-Activebtn h2 {
    font-weight: 500;
    font-size: 17px;
    line-height: 150%;
    color: #81C14B;
}

.Event-blk-Activebtn h3 {
    font-size: 13px;
    font-weight: 400;
    line-height: 150%;
    color: #81C14B;
}

.Event-blk-btn {
    background: #F6F6F6;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: inline-block;
    text-align: center;
    padding: 5px 10px;
    cursor: pointer;
}

.Event-blk-btn:hover {
    background: rgba(129, 193, 75, 0.1);
    border: 1px solid #81C14B;
    border-radius: 5px;
}

.Event-blk-btn h2 {
    font-weight: 500;
    font-size: 17px;
    line-height: 150%;
    color: #81C14B;
}

.Event-blk-btn h3 {
    font-size: 13px;
    font-weight: 400;
    line-height: 150%;
    color: #808080;
}

.Mp_card_blk {
    position: relative;
}

.Mp_card_blk img {
    width: 100%;
    object-fit: cover;
    height: 278px;
    border-radius: 3px;
}

.Mp_Category_blk h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #4D4D4D;

}

.mp_main_location {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #808080;
}

.mp_main_location span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #808080;
    text-decoration: #4D4D4D underline;
    cursor: pointer;
}

.mp_main_location span:hover {
    text-decoration: #81C14B underline;
}

.Mp_Action_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F6F6F6;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.Mp_Action_section h1 {
    color: #4D4D4D;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

button.mp_filled_btn.btn.btn-primary {
    padding: 6px 12px;
    /* height: 34px; */
}

button.Events_Btn_blkfroAll.btn.btn-secondary {
    padding: 6px 12px !important;
}

.socialFeed_Dropdown_EventMenu.dropdown-menu.show {
    width: 100% !important;
    display: block !important;
    background: #FFFFFF !important;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2) !important;
    border-radius: 5px !important;
    border: none !important;
}

.Events_GoingCard_Anal h4 {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #808080;
}

.btn-icon.square {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px; /* Optional: for rounded corners */
  }
  
  /* Default size for web */
  .btn-icon.square {
    width: 28px;
    height: 28px;
  }
  
  /* Size for tablets and mobile devices */
  @media (max-width: 768px) {
    .btn-icon.square {
      width: 25px; /* Adjust size for tablets and mobile devices */
      height: 25px;
    }
  }
  
  /* Size for very small devices (mobile) */
  @media (max-width: 480px) {
    .btn-icon.square {
      width: 24px; /* Adjust size for small mobile devices */
      height: 24px;
    }
  }
  




/*======================= End_Social_Feed_Post_disPlay_css ===================*/