/*===================== start_Footer_Css_Code =======================*/
.Ad_Footer_Section {
  background: #ffffff;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
  position: fixed;
  width: 100%;
  z-index: 3;
  bottom: 0;
  right: 0;
}

.Ad_Footer_InnerBlk {
  height: 100px;
  display: flex;
  justify-content: center;
  gap: 80px;
  align-items: center;
}

.Footer_content h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}

.Footer_content p {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #666666;
}

button.mr-3.Footer_LoginButton_blk.btn.btn-outline-primary {
  margin: 0;
  padding: 4px 38px;
  border: 1px solid #81c14b;
  border-radius: 3px;
  color: #81c14b;
  font-weight: 500;
  font-size: 14px;
}

button.mr-3.Footer_LoginButton_blk.btn.btn-outline-primary {
  box-shadow: none;
}

button.ml-3.Footer_SgnpButton_blk.btn.btn-primary {
  margin: 0;
  padding: 4px 38px;
  background: #95cb67;
  border: 1px solid #81c14b;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}

button.ml-3.Footer_SgnpButton_blk.btn.btn-primary:focus {
  box-shadow: none;
}

.Footer_span {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
}

/*===================== end_Footer_Css_Code =======================*/

.Page_Body_section {
  /* padding-top: 100px; */
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

.AdPost_Display_Crads {
  max-width: 547px;
  height: fit-content;
  width: 100%;
  border-radius: 5px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px;
  position: relative;
  overflow: hidden;
  border: none;
}

.AdManager_Card_User {
  display: flex;
  align-items: start;
}

.Ad_PreviewCard_blk {
  position: relative;
}

.Ad_PreviewCard_blk .AdPrev_Img {
  object-fit: cover;
  width: 100%;
  height: 278px;
}

.AdPreview_Card_bottom_section {
  width: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  bottom: 0;
}

.AdPreview_Card_content_inner {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.AdPreview_Card_content_inner img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.Card_Tag_content h3 {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  line-height: 21px;
}

.Card_Tag_content h4 {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  line-height: 21px;
}

.Card_Tag_content h3 {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  line-height: 21px;
}

.Card_Tag_content h4 {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
  padding: 0;
  line-height: 21px;
}

button.Card_Know_btn.btn.btn-primary {
  background: #81c14b;
  border-radius: 4px;
  border: none;
  padding: 4px 14px;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
}

button.Card_Know_btn.btn.btn-primary:focus {
  box-shadow: none;
}

button.Card_Know_btn.btn.btn-primary:active:focus {
  background: #81c14b;
  box-shadow: none;
}

.SingleMedia_Content_blk h3 {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.Crad_Text-heading h3 {
  font-weight: 600;
  font-size: 16px;
  color: #333333;
}

button.Login_Btn_blk.btn.btn-primary {
  background: #95cb67;
  border: 1px solid #81c14b;
  border-radius: 3px;
  padding: 4px 28px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

button.Login_Btn_blk.btn.btn-primary:focus {
  box-shadow: none;
}

button.Login_Btn_blk.btn.btn-primary:active:focus {
  background: #95cb67;
  box-shadow: none;
  border-color: #95cb67;
}

/* =====================input.w-100.form-control ===========*/
/* Default styling for all views */
input.w-100.form-control {
  width: 100%;
  min-width: 320px;
  font-size: 12px;
  color: rgba(78, 79, 80, 0.6);
  display: flex;
  align-items: center;
  font-weight: 400;
  border-radius: 3px;
  background: #ffffff;
  /* padding-right: 15% !important; */
}

/* Media query for mobile views */
@media (max-width: 767px) {
  input.w-100.form-control {
    /* Adjust properties for mobile views */
    min-width: auto;
    font-size: 14px;
    padding-right: 10px !important;
  }
}

/* ==========================input.w-100.form-control  end ===============*/
input.w-100.form-control:focus {
  box-shadow: none;
  border-radius: 3px;
  border: 1px solid #81c14b;
}

button.mr-3.Footer_LoginButton_blk.btn.btn-outline-primary:active:focus {
  background: #81c14b;
  color: white;
  box-shadow: none;
}

button.mr-3.Footer_LoginButton_blk.btn.btn-outline-primary:hover {
  background: #81c14b;
  color: white;
}

.Input_Position_section {
  position: relative;
}

.InputBox_Icon {
  position: absolute;
  bottom: 28px;
  right: 15px;
}

.InputBoxs_Icon {
  position: absolute;
  bottom: 5px;
  right: 15px;
}

.positionForSerachSmile {
  position: absolute;
  bottom: 7px;
  right: 15px;
}
