.success-message {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    background-color: green;
    color: white;
    border-radius: 5px;
}
