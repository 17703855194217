.poll-wrapper {
  width: 100%;
}
.content-wrapper {
  width: 100%;
  position: absolute;
  bottom: 0px !important;
}
.bookmark-post-card-image {
  width: 100%;
  height: min-content;
}
.bookmark-content {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.bookmark-contentt {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.bookmark-content-caption {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  top: 14rem;
  font-size: 13px;
  padding: 10px;
  height: 118px;
  width: 100%;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
}
.bookmark-content-captionn {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  top: 13.1rem;
  font-size: 13px;
  padding: 10px;
  height: 131px;
  width: 100%;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
}
.bookmark-content-captions {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  top: 17.9rem;
  font-size: 13px;
  padding: 10px;
  height: 3rem;
  width: 100%;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
}
.bookmark-content-captionss {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  top: 18.2rem;
  font-size: 13px;
  padding: 10px;
  height: 3rem;
  width: 100%;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
}
.bookmark-Recommendations-content-captionss {
  background: #235363af;
  position: absolute;
  top: 19.5rem;
  padding: 7px;
  left: 0px;
}
.text-server {
  margin: -1.5rem !important;
}
.text-servers {
  margin-bottom: -6rem !important;
}
.SingleMedia_blk .bookmark-content .bookmark-content-tag {
  position: absolute;
  height: 20px;
}
.SingleMedia_blk .bookmark-content img {
  position: absolute;
  height: 50px !important;
  width: 50px !important;
  top: 18.23rem;
}
.singletext {
  margin: -1rem;
  margin-left: 0.1rem;
}
/* .ThatCard-bookmark {
    height: 70px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
    border-radius: 5px;

}

@media only screen and (max-width: 375px) {
    .ThatCard-bookmark {
        font-size: 14px;
    }

    .ThatCard-bookmark {
        font-size: 14px;
        margin-left: 88%;
    }

    .mqText {
        font-size: 12px !important;
    }
}

.Thatbookmarktext {
    width: 160px;
    height: 30px;
    left: 21px;
    top: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #4D4D4D;
    margin-left: 20px;
}

.Thatfilter-iconbookmark {
    width: 46px;
    height: 41px;
    right: 20px;
    top: 16px;
    background: rgba(129, 193, 75, 0.2);
    border-radius: 5px;
}
.thatbookmark_headerImg{
    left: -25px;
}
.thatheaderImgbookmark{
    left: -15px;
}



.Thatbookmark-icon {
    margin-left: 20px;
}

.Thatimage-Cardbookmark {
    position: absolute;
    padding: none;
    width: 700px;
    margin-left: 15%;
    
}

.ThatCard-Headerbookmark {
    background: #FFFFFF;
}

.ThatCard-Footerbookmark {
    background: #FFFFFF;
}

.ThattextHeading-bookmark {
    font-weight: 500;
    font-size: 20px;
    color: #4D4D4D;
    width: 455px;
    height: 30px;
}

.Thattext1-bookmarkparaghaph {
    font-weight: 400;
    font-size: 16px;
    color: #4D4D4D;
    margin-top: 10px;

}

.thatbookmark-span {
    color: #81C14B;
    margin-top: 10px;
}

.Thattext2-bookmarkparaghaph {
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    color: #808080;
}

.bookmarksearchbar {
    width: 300px;
    margin-right: 15px;
}

.Thatbookmark-time {
    color: #81C14B;
    width: 85px;
    height: 21px;
}

.thatbookmark-likebutton {
    width: 35px;
    border-radius: 20px;
}

.Thatposteds {
    margin: 5px;
    color: #4d4d4d;

}

.Thatposteds-Like {
    margin: 5px;
    color: #81C14B;

}



.thatbookmark-Commentbox {
    margin-top: 5%;
    box-sizing: border-box;
    height: 40px;
    background: #F9FBF7;
    border: 1px solid rgba(126, 178, 98, 0.1);
    border-radius: 5px;
}

.ThatTime-bookmark {
    height: 22px;
    left: 70px;
    top: 1182px;
    background: rgba(126, 178, 98, 0.1);
    border-radius: 5px;
    color: #81C14B;
}

.thatcommentboxicons {
    position: relative;
    top: -32px;
    right: -603px;
}

.Card2-Bookmark {
    width: 677px;
    left: 0px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(129, 193, 75, 0.2);
    border-radius: 5px;
}

.Thatbutton-Section {
    background: #F6F6F6;
    margin-left: -16px;
    width: 105%;
}

.thatbookmark-Icon {
    width: 34px;
    height: 34px;
    left: 646px;
    top: 873px;
    background: rgba(126, 178, 98, 0.1);
    border-radius: 5px;
    margin-left: 345px;
    padding: 5px;
}

.Thatbookmark-Images {
    width: 504px;
    height: 435px;
    left: 0px;
    top: 0px;
}

.Thatbookmark-Images2 {
    position: absolute;
    box-sizing: border-box;
    height: 147px;
    left: 504px;
    width: 171px;
    top: 81px;
    border: 1px solid #FFFFFF;
}

.Thatbookmark-Images3 {
    box-sizing: border-box;
    position: absolute;
    width: 169px;
    height: 149px;
    left: 505px;
    top: 225px;
    border: 1px solid #FFFFFF;
}

.Thatbookmark-Images4 {
    box-sizing: border-box;
    position: absolute;
    width: 171px;
    height: 151px;
    left: 504px;
    top: 370px;
    border: 1px solid #FFFFFF;
}

.Thatbookmark-Number {
    position: absolute;
    width: -57px;
    left: 580px;
    top: 431px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
}

.thatbookmark-wooden {
    width: 606px;
    height: 58px;
    left: 20px;
    font-size: 16px;
    line-height: 24px;
    color: #4D4D4D;
    margin-left: 18px;

}

.thatbookmark-woodensale {
    width: 672px;
    margin-left: -2%;
    height: 70px;
    background: #F6F6F6;
}

.thatbookmark-woodenicon {
    margin-left: 420px;
}

.Thatbookmark-play {
    position: absolute;
    left: 25%;
    right: 0%;
    top: 30%;
    bottom: 0%;
    opacity: 0.5;
}

.Thatbookmark-play2 {
    position: absolute;
    left: 83%;
    right: -34%;
    top: 16%;
    bottom: 22%;
    border-radius: 2px;

}

.Thatbookmark-play3 {
    position: absolute;
    left: 83%;
    right: -34%;
    top: 34%;
    bottom: 16%;
    border-radius: 2px;

}

.Thatbookmark-play4 {
    position: absolute;
    left: 82%;
    right: -34%;
    top: 52%;
    bottom: 22%;
    border-radius: 2px;
}

.Thatbookmark-Number2 {
    position: absolute;
    left: 572.12px;
    top: 470.5px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
}

.Thatbookmark-Audi {
    left: 7.27%;
    right: 84.39%;
    top: 30%;
    bottom: 28%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #4D4D4D;
    position: relative;
    margin-top: -35px;
}

.thatbookmark-AudiHeading {
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    height: 50px;
    background: rgba(0, 102, 255, 0.1);
    border-radius: 35px;
}

.Thatbookmark-Checkbox {
    box-sizing: border-box;
    left: 3.03%;
    right: 94.55%;
    top: 34%;
    bottom: 34%;
    background: #FFFFFF;
    border-radius: 100%;
}

.ProgressThatColor .progress-bar {
    background: #e5f0ff !important;
    color: #0066FF1A;
}

.Thatbookmark-line {
    width: 106%;
    margin-left: -3%;
}

.BookmarkThatpadding {
    padding-right: 10%;
    background-color: #F9FBF7;
}

.thatcommenticon {
    margin-top: -40px;
}

.thatdropdown-menu {
    min-width: 15rem;
} */
.BookmarkThisMainCard {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(126, 178, 98, 0.1);
  border-radius: 5px;
}

.thatflitericon {
  background: rgba(129, 193, 75, 0.2);
  border-radius: 5px;
  padding: 9px;
  width: 46px;
  height: 38px;
  background: rgba(129, 193, 75, 0.2);
  border-radius: 5px;
}

.BookMarkThisDropDown {
  position: absolute;
  will-change: transform;
  top: 12px !important;
  /* left: -px !important; */
  transform: translate3d(-159px, 46px, 0px) !important;
  width: 218px !important;
  font-size: 12px !important;
}

.BookMarkThisFilter {
  position: absolute;
  will-change: transform;
  top: 12px !important;
  /* left: -px !important; */
  transform: translate3d(-159px, 46px, 0px) !important;
  width: 218px !important;
  /* font-size: 12px !important; */
}

.BookmarkSearchbIcon {
  position: absolute;
  /* left: 134px; */
  top: 127px;
  right: 422px;
}

.BookMarkSvgIcon {
  /* width: 15px; */
  height: 15px;
}

/* new css to customize the cards  */

.PeopleThisGrid {
  display: grid;
  -ms-grid-columns: (minmax(200px, 1fr)) [auto-fill];
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.Video_Treanding_card_overrided {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
  /* border-radius: 10px; */
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
  padding-top: calc(var(--bs-gutter-x) / 2);
  padding-bottom: calc(var(--bs-gutter-x) / 2);
}

/* classes to override the design  */

.BookmarkBorderRadius {
  border-radius: 5px !important;
}

.BookmarkBorderRadius_BothBottom {
  border-radius: 0px 0px 5px 5px !important;
}

.BookmarkBorderRadius_BothTop {
  border-radius: 5px 5px 0px 0px !important;
}

.PaddinNone {
  padding: 0 !important;
}

.BookmarkThisSerachSmile {
  position: absolute;
  bottom: 23px;
  right: 24px;
}

.BookmarkVideoLinearGradient {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}

.BookmarkRight8px {
  right: 8px !important;
}

.BookMarkCardShadow {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px !important;
}

.bookmarkMediaCount {
  position: absolute;
  top: 40%;
  /* transform: translateY(-500%); */
  width: 100%;
  margin: auto;
  font-size: 18px;
  color: black;
  text-align: center;
}

.textDisplayForBookmark {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
  /* height: 53px; */
  padding: 5px;
  width: 100% !important;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
}

.textArrangementForBookmark {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
}

.captionBoxForBookmark {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
  min-height: 53px;
  width: 100% !important;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
}
.captionBoxForBookmarks {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
  min-height: 53px;
  width: 100% !important;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
}
.captionTextForBookmark {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  /* margin-left: 20px !important; */
  padding: 5px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
}

.captionTextForBookmark {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  /* margin-left: 20px !important; */
  padding: 5px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
}

.text-nowrap {
  white-space: nowrap;
}

/* go liver */

.height100vh {
  min-height: 100vh;
  max-height: 100vh;
}

.GoLiveOverLapContents {
  display: grid;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  grid-template-areas:
    "header header header comment"
    "header header header comment"
    "footer footer footer comment"
    "footer footer footer comment";
  width: 100%;
}

.Zindex0 {
  z-index: 0 !important;
}

.goLiveHeader_is {
  grid-area: header;
  z-index: 1;
}

.goLiveFooter_is {
  grid-area: footer;
  z-index: 1;
}

.goLiveSidebar_is {
  grid-area: comment;
  background-color: #00000063;
  z-index: 1;
}

.goliveProfileImg {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.goliveHr {
  height: 1px;
  width: 100%;
  background: white;
}

.GridViewListView_Conatainer {
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

button.BookmarkFilterButton.btn.btn-primary {
  background: #e6f3db !important;
  color: #808080 !important;
  border-color: #e6f3db !important;
}

button.BookmarkFilterButton_Active.btn.btn-primary {
  background: #e6f3db !important;
  color: #808080 !important;
  border-color: #e6f3db !important;
}

.BookmarkFilterContentDropdown {
  max-height: 35vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.bookmarKDropDownMenu.dropdown-menu.show {
  display: block;
  width: 112%;
  will-change: transform;
  top: 0px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  left: 0px;
  transform: translate3d(-218px, 48px, 0px) !important;
}

.HoverE6_81:hover {
  background-color: #e6f3db !important;
  color: #81c14b !important;
}
/* Example media queries for responsive design */
/* Mobile */
@media (max-width: 768px) {
  .Card {
    max-width: 100%;
    margin: 0 auto;
  }

  /* Adjust the layout and font sizes for mobile */
  /* .d-flex {
    flex-direction: row;
    align-items: center;
  } */

  .UserProfile-Name {
    text-align: center;
  }

  /* Adjust image and text sizes for mobile */
  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  /* h4 {
    font-size: 16px;
  } */

  /* ... Add more styles as needed for mobile */
}

/* Tablet and Larger Screens */
@media (min-width: 768px) {
  .Card {
    max-width: 50%;
    margin: 0 auto;
  }

  /* Adjust the layout and font sizes for tablet and larger screens */
  /* ... Add styles as needed for larger screens */
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.blinking-form {
  animation: blink 1s infinite;
}
.image-container {
  position: relative;
  width: 90px;
  margin-left: 10px;
  padding: 2px;
}
/*
.rounded-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
}

.rounded-circle:nth-child(1) {
  left: 0;
  top: 0;
}

.rounded-circle:nth-child(2) {
  left: 15px;
  top: 0px;
}

.rounded-circle:nth-child(3) {
  left: 30px;
  top: 0px;
} */
/* ============================text===================== */
.usdtext {
  text-wrap: nowrap;
  padding-top: 10px;
}
.alertText {
  font-size: 16px;
}
.smalls {
  margin-right: 22px;
}

@media (max-width: 768px) {
  /* Adjust card layout for smaller screens */
  .your-card-component {
    max-width: 500px;
  }

  /* Adjust font sizes and padding */
  .text-nowrap {
    font-size: 14px;
  }
  .UserProfile-Name {
    font-size: 16px;
  }
  /* ... Other styles ... */
}

@media (min-width: 768px) and (max-width: 1023px) {
  .your-card-component {
    max-width: 100%;
    margin: 0 auto;
  }
}

/* Desktop styles (1024px and above) */
@media (min-width: 1024px) {
  .your-card-component {
    max-width: 100%;
    margin: 0 auto;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 991px) {
  .your-card-component {
    max-width: 100%;
    margin: 0 auto;
  }
}

/* Example media query for mobile screens */
@media (max-width: 768px) {
  .your-card-component {
    /* Update styles for mobile view */
    /* For example, adjust padding, margins, font sizes, etc. */
    /* text-wrap: nowrap; */
    margin: 0px;
    padding: 0px;
  }
}

@media (max-width: 360px) and (max-height: 915) {
  .Allbtn {
    margin-right: -0.8rem !important;
  }
  .TextWork {
    margin-left: -0.8rem !important ;
  }
  .pera {
    margin-top: -1rem !important;
  }
  .totaltext {
    margin-left: 0.5rem;
  }
  .episodetext {
    bottom: 9.3rem !important;
  }
}
/* Media query for mobile screens */
@media (max-width: 768px) {
  .card {
    /* max-width: 500px;  */

    .cardtext {
      /* max-width: 550px; */
      text-wrap: wrap;
      margin: 0px;
      padding: 0px;
      flex-direction: column;
      /* align-items: flex-start; */
      width: 370px;
    }
  }

  /* Adjust font sizes and margins for mobile */
  .card-content h4 {
    font-size: 18px;
  }

  .card .card-text {
    font-size: 12px;
    bottom: 2.6rem;
  }

  .tags {
    font-size: 10px;
  }

  /* Reduce padding for overlay on mobile */
  .overlay {
    padding: 5px;
  }
}

/* ==================card media============ */
@media (max-width: 768px) {
  /* .cards {
    max-width: 500px;
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
     width: 370px; 
  } */

  .cards .card-text {
    font-size: 12px;
    bottom: 2.6rem;
  }
}
@media (max-width: 768px) {
  .textCard {
    /* max-width: 500px;  */
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
  }

  .InternationalText {
    /* padding: 6px; */
    margin-top: -10px;
  }
  .mobiletext {
    font-size: 10px;
  }
  .cards .card-text {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .textCards {
    max-width: 500px; /* Full width for mobile */
    margin: 0px;
    padding: 0px;
    bottom: 9.3rem !important;
    height: 120px;
  }
  @media (max-width: 1629px) {
    .textCards {
      max-width: 500px; /* Full width for mobile */
      margin: 0px;
      padding: 0px;
      bottom: 9.3rem !important;
      height: 120px;
    }
  }
  .pollcardx {
    margin: -7px;
  }
  /* .playicon {
    position: absolute !important;
    top: 9rem !important;
    left: 35% !important;
  } */
  .blinkicon {
    position: absolute !important;
    top: 7rem !important;
    left: 26% !important;
  }
  .searchbookmark {
    width: 9.3rem !important;
  }
  .mintext {
    display: flex;
    justify-content: start;
  }
  .mintexts {
    margin-top: 3rem !important;
  }
  .cards .card-text {
    font-size: 12px;
  }
}

.cardtexts {
  /* Add styles for your card here */
  /* Example styles for the card container */
  border: 1px solid #ccc;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 400px; /* Adjust the maximum width as needed */
}

.cardtexts img {
  /* Add styles for the image here */
  max-width: 100%; /* Ensure the image doesn't exceed its container */
}

.cardtexts .content {
  /* Add styles for the content section here */
  margin-top: 10px; /* Add some space between the image and content */
}

.cardtexts .read-more {
  /* Add styles for the "Read more" text here */
  margin-top: 10px; /* Adjust the margin-top as needed to control spacing */
  color: #007bff; /* Change the color to your desired color */
  cursor: pointer; /* Add a pointer cursor to indicate it's clickable */
}

.cardtexts .read-more:hover {
  text-decoration: underline; /* Underline the "Read more" text on hover if desired */
}
/* ======================================768 and 1024===================== */
@media (min-width: 768px) and (min-height: 1024px) {
  .usdtexts {
    margin-right: 0rem;
  }
  .textCard {
    color: white;
    bottom: 6.6rem !important;
  }
  .pollcardx {
    width: 440px !important;
    margin-left: 12px;
  }
  .textCards {
    /* max-width: 500px; */
    margin: 0px;
    padding: 0px;
    bottom: 7.3rem !important;
  }
}
/* =======================================834 and 1194======================== */
@media (min-width: 834px) and (min-height: 1194px) {
  .pollcardx {
    width: 530px !important;
    margin-left: -4px;
  }
}
/* ===============================1920 and 1180======================== */
@media (min-width: 1920px) and (min-height: 1180px) {
  .pollcardx {
    width: 620px !important;
    margin-left: -4px;
  }
  .textCards {
    max-width: 671px;
    margin: 0px;
    padding: 0px;
    bottom: 8.1rem !important;
  }
  .icontext {
    padding-left: 36rem !important;
  }
}
/* ===============================2560 and 1440======================== */
@media (min-width: 2560px) and (min-height: 1440px) {
  .pollcardx {
    width: 59rem !important;
    margin-left: -4px;
  }
  .textCards {
    max-width: 62rem;
    margin: 0px;
    padding: 0px;
    bottom: 8.1rem !important;
  }
  .icontext {
    padding-left: 19rem !important;
  }
  .textCard {
    bottom: 5.6rem !important;
  }
}
/* ===============================375 and 667======================== */
@media (min-width: 375px) and (min-height: 667px) {
  .pollcardx {
    width: 22.6rem !important;
    margin-left: -4px;
  }
  .totaltext {
    margin-left: 0.5rem;
  }
  .Intertext {
    margin-left: -4px;
  }
  .TextWork {
    padding-left: 0.25px !important;
  }
  .messagebtn {
    margin-left: 5rem !important;
  }
}
/* ===============================414 and 886======================== */
@media (min-width: 414px) and (min-height: 889px) {
  .pollcardx {
    width: 25.2rem !important;
    margin-left: -4px;
  }
}
/* ===============================390 and 844======================== */
@media (min-width: 390px) and (min-height: 844px) {
  .pollcardx {
    width: 23.7rem !important;
    margin-left: -4px;
  }
}
/* ===============================367 and 740======================== */
@media (min-width: 360px) and (min-height: 740px) {
  .totaltext {
    margin-left: 0.5rem;
  }
  .Intertext {
    margin-left: -4px;
  }
  .TextWork {
    padding-left: 0.25px !important;
  }
  .messagebtn {
    margin-left: 2rem !important;
  }
}
/* ===============================412 and 915======================== */
@media (min-width: 412px) and (min-height: 915px) {
  .pollcardx {
    width: 24.9rem !important;
    margin-left: -4px;
  }
}
/* ======================================820 and 1180===================== */
@media (min-width: 768px) and (min-height: 1024px) {
  .usdtexts {
    margin-right: 0rem;
  }
  .textCard {
    color: white;
    bottom: 6.6rem !important;
  }
  .pollcardx {
    width: 550px !important;
    margin-left: -15px;
  }
  .textCards {
    /* max-width: 36rem; */
    margin: 0px;
    padding: 0px;
    bottom: 7.6rem !important;
  }
  .messagebtn {
    margin-left: 19rem !important;
  }
  .icontext {
    padding-left: 13rem !important;
  }
}
/* ===============================768 and 1024======================== */
@media (min-width: 768px) and (min-height: 1024px) {
  .textCard {
    /* max-width: 34rem; */
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 6.3rem !important;
  }
  .blkimg {
    width: 100% !important;
  }
  .icontext {
    padding-left: 16rem !important;
  }
  .pollcardx {
    width: 500px !important;
    margin-left: 4px;
  }
}
@media (min-width: 912px) and (min-height: 1368px) {
  .textCard {
    /* max-width: 49rem; */
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 6.5rem !important;
  }
  .textCards {
    /* max-width: 42rem !important; */
    margin: 0px;
    padding: 0px;
    bottom: 7.6rem !important;
  }
  .blkimg {
    width: 100% !important;
  }
  .icontext {
    padding-left: 35rem !important;
  }
  .pollcardx {
    width: 40rem !important;
    margin-left: -11px;
  }
}
/* ==========================540 and 720================== */
@media (min-width: 540px) and (min-height: 720px) {
  .textCard {
    /* max-width: 42rem; */
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 6.3rem !important;
  }
  .textCards {
    /* max-width: 42rem !important; */
    margin: 0px;
    padding: 0px;
    bottom: 7.5rem !important;
  }
  .blkimg {
    width: 100% !important;
  }
  .icontext {
    padding-left: 18rem !important;
  }
  .pollcardx {
    width: 32rem !important;
    margin-left: -4px;
  }
  .blinkicon {
    position: absolute !important;
    top: 7rem !important;
    left: 34% !important;
  }
  .messagebtn {
    margin-left: 16rem !important;
  }
}
/* ==========================1124 and 600================== */
@media (min-width: 1124px) and (min-height: 600px) {
  .messagebtn {
    margin-left: 16rem !important;
  }
  .icontext {
    padding-left: 16rem !important;
  }
}
/* ==========================1280 and 800================== */
@media (min-width: 1280px) and (min-height: 800px) {
  .textCard {
    /* max-width: 42rem; */
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 7.9rem !important;
  }
  .textCards {
    /* max-width: 42rem !important; */
    margin: 0px;
    padding: 0px;
    /* bottom: 9.8rem !important; */
  }
  .blkimg {
    width: 100% !important;
  }
  .icontext {
    padding-left: 21rem !important;
  }
  .pollcardx {
    width: 30rem !important;
    margin-left: -16px;
  }
  .blinkicon {
    position: absolute !important;
    top: 7rem !important;
    left: 34% !important;
  }
  .messagebtn {
    margin-left: 16rem !important;
  }
}
/* ==========================1366 and 768================== */
@media (min-width: 1366px) and (min-height: 768px) {
  .textCard {
    /* max-width: 42rem; */
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 6.9rem !important;
  }
  .pollcardx {
    width: 33rem !important;
    margin-left: -16px;
  }
}
/* ==========================1920 and 1080================== */
@media (min-width: 1920px) and (min-height: 1080px) {
  .textCards {
    /* max-width: 42rem !important; */
    margin: 0px;
    padding: 0px;
    /* bottom: 8.1rem !important; */
  }
  .pollcardx {
    width: 41rem !important;
    margin-left: -16px;
  }
  .icontext {
    padding-left: 37rem !important;
  }
  .blinkicons {
    position: absolute !important;
  }
}
/* ==========================2560 and 1440================== */
@media (min-width: 2560px) and (min-height: 1440px) {
  .textCard {
    max-width: 62rem;
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 5.6rem !important;
  }
  .messagebtn {
    margin-left: 33rem !important;
  }
  .icontext {
    padding-left: 18rem !important;
  }
  .pollcardx {
    width: 61rem !important;
    margin-left: -16px;
  }
  .pollcards {
    width: 40rem !important;
  }
  .textCards {
    max-width: 62rem !important;
    margin: 0px;
    padding: 0px;
    bottom: 7.8rem !important;
  }
  .textCad {
    bottom: 3.5rem !important;
  }
  .textCads {
    bottom: 4.5rem !important;
  }
  .textCadss {
    bottom: 2.5rem !important;
  }
  .blinkicons {
    position: absolute !important;
    top: 50% !important;
    left: 52% !important;
  }
  .bookmark-Recommendations-content-captionss {
    background: rgba(35, 83, 99, 0.6862745098039216) !important;
    position: absolute !important;
    top: 18rem !important;
    padding: 7px !important;
    left: 0 !important;
  }
}
/* ==========================2560 and 1440================== */
@media (min-width: 1078px) and (min-height: 527px) {
  .icontext {
    padding-left: 24rem !important;
  }
}
/* ==========================375 and 667================== */
@media (min-width: 375px) and (min-height: 667px) {
  /* .North {
    margin-left: -10rem !important;
  }
  .Norths {
    margin-left: -10rem !important;
  } */
  .Options {
    margin-right: 8rem !important;
  }
}
@media (min-width: 820px) and (min-height: 1180px) {
  .textCard {
    max-width: 42rem;
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 6.5rem !important;
  }
  .icontext {
    padding-left: 13rem !important;
  }
}
@media (min-width: 912px) and (min-height: 1368px) {
  .pollcardx {
    width: 61rem !important;
    margin-left: -16px;
  }
  .icontext {
    padding-left: 34rem !important;
  }
}
@media (min-width: 1024px) and (min-height: 600px) {
  .icontext {
    padding-left: 27rem !important;
  }
}
@media (min-width: 1280px) and (min-height: 800px) {
  .icontext {
    padding-left: 23rem !important;
  }
}
@media (min-width: 1078px) and (min-height: 575px) {
  .icontext {
    margin-left: 11rem !important;
  }
}
@media (min-width: 375px) and (min-height: 667px) {
  .icontext {
    margin-left: 7rem !important;
  }
}
@media (min-width: 1366px) and (min-height: 768px) {
  .TextWork {
    margin-left: 0.1rem !important;
  }
}
@media (min-width: 1024px) and (min-height: 1366px) {
  /* .pollcardx {
    width: 45rem !important;
  } */
}
@media (min-width: 2560px) and (min-height: 1440px) {
  /* .pollcardx {
    width: 60rem !important;
  } */
}
@media (min-width: 1024px) and (min-height: 1366px) {
  .textCard {
    max-width: 62rem;
    text-wrap: nowrap;
    margin: 0px;
    padding: 0px;
    bottom: 6.5rem !important;
  }
}
/* .textCards {
  bottom: 7.6rem !important;
} */
@media (min-width: 483px) and (min-height: 564px) {
  .content-wrappe {
    bottom: 6.5rem !important;
  }
}
@media (min-width: 360px) {
  .content-wrappe {
    bottom: 6.5rem !important;
  }
}
@media (min-width: 360px) and (min-height: 564px) {
  .episodetext {
    bottom: 7.6rem !important;
  }
  .textCard {
    bottom: 6.7rem !important;
  }
}
/* @media (min-width: 721px) and (min-height: 564px) {
  .episodetexts {
    bottom: 7.6rem !important;
  } */
/* .textCard {
    bottom: 6.2rem !important;
  } */
/* } */
@media (min-width: 540px) and (min-height: 720px) {
  .episodetexts {
    bottom: 7.3rem !important;
  }
  .textCard {
    bottom: 6.2rem !important;
  }
}
@media (min-width: 820px) and (min-height: 1180px) {
  .episodetexts {
    bottom: 7.8rem !important;
  }
  .textCard {
    bottom: 6.8rem !important;
  }
}
@media (min-width: 1075px) and (min-height: 564px) {
  .iconfafa {
    margin-left: 12rem !important;
  }
}
@media (min-width: 912px) and (min-height: 1368px) {
  .iconfafa {
    margin-left: 22rem !important;
  }
}
@media (min-width: 1920px) and (min-height: 1080px) {
  .iconfafa {
    margin-left: 22rem !important;
  }
}
/* @media screen and (max-width: 1024px) {
  .iconfafa {
    display: block !important;
  }
}
@media only screen and (max-width: 480px) {
  .iconfafa {
    display: none !important;
  }
} */
@media only screen and (max-width: 600px) {
  .iconfafa {
    margin-left: 8rem !important;
  }
}

@media only screen and (min-width: 768px) {
  .iconfafa {
    margin-left: 12rem !important;
  }
}

@media only screen and (min-width: 992px) {
  .iconfafa {
    margin-left: 29rem !important;
  }
}

@media only screen and (min-width: 1075px) {
  .iconfafa {
    margin-left: 19rem !important;
  }
}
/* @media only screen and (min-width: 360px) {
  .internationtext {
    margin-left: -5px;
  }
} */
