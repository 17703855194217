.LoginFormMainCont {
    width: 100%;
    height: 100vh;
    display: grid;
    place-items: center;
}

.Login_Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;

}

.FormBottomSec {
    display: flex;
}

.LoginConnectWith {
    display: grid;
    place-items: center;
}

.FormInputBlock {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.LoginFormSection {
    width: 100%;
    text-align: center;
}


.LoginSignUpBackground{
    background-image: url(../Story/Images/loginbg.jpg) !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}








.Login-Scroll-Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    max-height: 500px;
}

.LoginOptionTab {
    padding: 12px 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #4d4d4d;
    border-bottom: 1px solid #e1e1e1;
}

.LoginOptionTab:nth-last-child() {
    border-bottom: none;
}

.Login_searchbar {
    padding: 10px;
}


.LoginSearchPannel {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 3px 10px;
    border: 1px solid #e1e1e1;
    background-color: rgb(245 245 245);
}


.CountryCodeSearchPannel {
    background-color: #f6f6f6;
}


.TransparentBorder {
    /* box-sizing: content-box; */
    border: 1px solid transparent;
    box-shadow: none;
    cursor: pointer;
}

.TransparentBorder:hover {
    /* border: 1px solid #81c14b; */
    background-color: rgba(230, 243, 219, 0.444);
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px; */
}

.suggestedUsernames {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.suggestedUsernames>h6 {
    flex: 1 1 auto;
}

.suggestedUserNamesItem {
    padding: 6px 10px;
    border: 1px solid #E0E0E0;
    text-align: center;
    cursor: pointer;
}

.suggestedUserNamesItem:hover {
    border: 1px solid #81c14b;
}

.LanguageTableAlltabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.LanguageTableAlltabs>.LanguageTabsMore {
    flex: 1 1 auto;
    /* max-width: fit-content; */
    text-align: center;
    transition: 0.3s ease;
    max-height: fit-content;
    cursor: pointer;
    border: 1px solid #dee2e6;
}

.LanguageTabsMore:hover {
    background-color: #81c14b;
    color: white;
}



/* date time picker customize */

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    padding-top: 27px !important;
}

.react-datepicker__header {
    background-color: #81c14b !important;
    color: white !important;
    border-radius: 5px 5px 0px 0px !important;
    border-bottom: none !important;
}

.react-datepicker__year-wrapper {
    justify-content: center !important;
}

.react-datepicker__year-text--selected {
    background-color: #81c14b !important;
}

.react-datepicker__month-text--keyboard-selected {
    background-color: #81c14b !important;
}

.react-datepicker__year-text--keyboard-selected {
    background-color: #81c14b !important;
}

.react-datepicker__day-name {
    color: #ffffff !important;
}

.react-datepicker__day--selected {
    background-color: #81c14b !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: #81c14b !important;
}

.react-datepicker {
    border: none !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;

}

.react-datepicker__navigation-icon::before {
    border-color: #ffffff !important;
}



/* new Interest Design  */


/* .InterestItem {
    flex: 1 1 auto;
    border: 1px solid gray;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    max-width: fit-content;
    border-radius: 5px;
    margin: 4px;
}

.InterestsItems{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
} */



.InterestsItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.InterestsItems>.InterestItem {
    flex: 1 1 auto;
    /* max-width: fit-content; */
    text-align: center;
    transition: 0.3s ease;
    max-height: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 5px;
    max-width: calc(100% / 3);
    border: 1px solid #dee2e6;
}

.InterestItem:hover {
    background-color: #81c14b;
    color: white;
    border: 1px solid #81c14b;
}

.InterestItem.active {
    background-color: #81c14b;
    color: white;
    border: 1px solid #81c14b;
}






/* color */

.Login4D_500 {
    color: #4d4d4d !important;
    font-weight: 500 !important;
}

.Login4D_400 {
    color: #4d4d4d !important;
    font-weight: 400 !important;
}

.LoginBd_400 {
    color: #BDBDBD !important;
    font-weight: 400 !important;
}

.LoginAlert400 {
    color: #EB5757 !important;
    font-weight: 400 !important;
}

.LoginAlert500 {
    color: #EB5757 !important;
    font-weight: 500 !important;
}


.LoginLanguageBtn {
    color: #0066FF;
    font-weight: 500;
}

.Login81_400 {
    color: #81c14b;
    font-weight: 400;
}

.LoginLanguageBtn:hover {
    color: #81c14b;
}

.Login000_400 {
    color: #000000;
    font-weight: 400;
}

.Login000_500 {
    color: #000000;
    font-weight: 500;
}

.Login80_400 {
    color: #808080;
    font-weight: 400;
}

.Login33_400 {
    color: #333333;
    font-weight: 500;
}

.LoginAlert_600 {
    color: #EB5757;
    font-weight: 600;
}

.Login2F_400 {
    color: #2F80ED;
    font-weight: 500;
}

.Login03_400 {
    color: #032D23;
    font-weight: 400;
}

.Login4f_400 {
    color: #4F4F4F;
    font-weight: 400;
}


/* universal class */


.cursor-p {
    cursor: pointer;
}


.UnderLineText {
    text-decoration: underline !important;
}

.BorderInputAlert {
    border-color: #FF0000 !important;
}

.LoginSkipButton {
    font-size: clamp(10px 14px 15px) !important;
}



/* new language modal styling   */



.LanguageModalAllLang {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    height: 100%;
    max-height: 70vh;
    overflow-y: auto;
}

.LanguageModalAllLang>div>div {
    color: #4d4d4d;
    font-weight: 500;
}

.LanguageModalAllLang>div>div:hover {
    color: #81c14b;
    text-decoration: underline;
    font-weight: 500;
}































.InterestListItems {
    display: grid;
    grid-template-columns: repeat(auto-fit, calc(100% / 2));
}



.LanguageOffCanvas {
    height: 50%;
}

@media (max-width:567px) {
    .LanguageOffCanvas {
        height: none;
    }

}

.LanguagesTabItems_2:hover {
    background-color: #81c14b33;
}

.ScrollBarDisplayNone::-webkit-scrollbar {
    display: none;
}


.outline81 {
    outline-color: #81c14b;
}


.theme-form label {
    margin-right: 4px !important;
}






