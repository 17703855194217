.story-preview-page {
  width: 400px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    199.09deg,
    #81c14b 0%,
    #155b46 69.23%,
    #083737 100%
  );
  border-radius: 10px;
  font-size: 15px;
  text-decoration: underline;
  align-self: center;
  align-self: center;
  font-style: italic;
  color: #ffffff;
}

.story_sidebar {
  /* width: 24%; */
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(129, 193, 75, 0.2);
  /* position: fixed; */
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* overflow: auto; */
  scrollbar-width: none;
  -ms-overflow-style: none;

} 

.story_side ::-webkit-scrollbar{
  display: none;
}

/* .StoryPostButtonForUniversal {
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
} */

.story_content {
  /* margin-left: 24%; */
  /* width: 75%; */
  align-items: center !important;
  justify-content: center !important;
  align-content: center !important;
  height: 100% !important;
  border-radius: 5px;
  backdrop-filter: blur(6px) !important;
}

.story_inner_content {
  background: #ffffff;
  /* margin: 15px; */
  /* padding: 10px; */
  border-radius: 10px;
  align-self: center;
}

.change-background-scroll {
  height: calc(100vh / 3);
  max-height: calc(100vh / 3.5);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  /* gap: 4px; */
}

.change-background-scroll_filter {
  height: calc(100vh / 3);
  max-height: calc(100vh / 3.5);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 18px;
  gap: 12px;
}

.change-background-scroll > div {
  flex: 1 1 auto;
  aspect-ratio: 4/4;
  width: calc(100% / 7);
  max-width: calc(100% / 7);
  height: fit-content;
  padding: 0 !important;
  /* margin: 0px 0px !important; */
}

.change_background_scroll_filter > div {
  flex: 1 1 auto;
  aspect-ratio: 4/4;
  width: calc(100% / 4);
  max-width: calc(100% / 4);
  /* padding: 0 !important; */
  /* margin: 0px 0px !important; */
}

.BackgroundFilterImg {
  transition: all 0.1s ease-in-out;
  aspect-ratio: 4/4 !important;
}

.BackgroundFilterImg:hover {
  box-shadow: 10px 10px 30px -5px rgba(0, 0, 0, 0.4);
}

.storyTextRotationBtn {
  /* padding: 5px !important; */
  border-radius: 100% !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  height: 20px;
  margin-bottom: -10px;
  margin-right: -10px;
  z-index: 3;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .change-background-scroll > div {
    width: calc(100% / 4);
    max-width: calc(100% / 4);
  }

  .change_background_scroll_filter > div {
    flex: 1 1 auto;
    aspect-ratio: 4/4;
    width: calc(100% / 3);
    max-width: calc(100% / 3);
    /* padding: 0 !important; */
    /* margin: 0px 0px !important; */
  }
}

@media (max-width: 900px) {
  .change-background-scroll > div {
    width: calc(100% / 4);
    max-width: calc(100% / 3);
  }

  .change_background_scroll_filter > div {
    flex: 1 1 auto;
    aspect-ratio: 4/4;
    width: calc(100% / 4);
    max-width: calc(100% / 4);
    /* padding: 0 !important; */
    /* margin: 0px 0px !important; */
  }
}

.scroll-selecter {
  height: 200px;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

.story_sidebar a {
  display: block;
  padding: 16px;
  text-decoration: none;
}

.story_sidebar a.active {
  background-color: #04aa6d;
  color: white;
}

.story_sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

.backgroun_style {
  background-size: cover !important;
  border: 3px solid #ffffff !important;
  border-radius: 5px !important;
}

.backgroun_style:hover {
  background-size: cover !important;
  border: 3px solid #81c14b !important;
  border-radius: 5px !important;
}

.story-switch-tab {
  display: flex;
  justify-content: end;
  flex-direction: row;
  inline-size: max-content;
}

.fab_btn_success {
  color: #ffffff !important;
  stroke: #ffffff !important;
  fill: #ffffff !important;
  background-color: #81c14b !important;
}

.Create_story_container {
  position: relative;
}

/*--------------- View Story Modal css ----------------- */
.modal-container .modal-content {
  background: transparent !important;
  border: none;
}

.story_profress_bar progress {
  height: 5px !important;
}

.progress-custome-color {
  height: 10px !important;
  background-color: #81c14b !important;
  border-radius: 5px !important;
}

.text-area-box {
  background: #ffffff;
  border: 2px solid #c4c4c4;
  border-radius: 5px;
  line-break: auto;
  white-space: pre-wrap;
}

.img-top-head {
  position: absolute;
  top: 10px;
  color: #ffffff;
  border-radius: 15px !important;
  display: flex;
  justify-content: space-between;
}

.Story_Creation_Preview_section {
  /* background: linear-gradient(199.09deg, #81C14B 0%, #155B46 69.23%, #083737 100%); */
  border-radius: 10px !important;
  align-items: center;
  line-break: auto !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden !important;
  color: #ffffff !important;
  aspect-ratio: 9/16;
  height: calc(100vh / 1.2);
  /* height: 750px;
  width: 500px; */
}

.border_style {
  padding: 5px !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%2381C14BFF' stroke-width='3' stroke-dasharray='50%2c 14' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  border-radius: 5px;
}

.border_style_disable {
  padding: 5px !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%234D4D4DFF' stroke-width='3' stroke-dasharray='40%2c 14' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  border-radius: 5px;
}

.circle-border-style {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%2381C14BFF' stroke-width='3' stroke-dasharray='50%25%2c 13%25' stroke-dashoffset='86' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 100px;
}

.img-view-container {
  border-radius: 10px !important;
  position: relative !important;
  background-color: #c4c4c4 !important;
  align-content: center !important;
  flex-direction: column !important;
  justify-content: center !important;
  display: flex !important;
  /* height: 780px !important; */
  background-size: cover !important;
  /* min-height: 600px; */
}

.img-edit-container {
  border-radius: 5px !important;
  display: flex;
  position: relative !important;
  background-color: #c4c4c4 !important;
  justify-content: center;
  align-content: center !important;
  align-items: center;
  height: 750px;
  overflow: hidden;
  max-height: 100%;
  max-width: 100%;
}

/* 
.img-edit-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 33%;
  border-right: 1px solid white;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  Set the background color of the mask
  z-index: 1;
  Set the z-index to be higher than the image to position it on top of the image
}

.img-edit-container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 33%;
  border-left: 1px solid white;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  Set the background color of the mask
  z-index: 1;
  Set the z-index to be higher than the image to position it on top of the image
} */

.img-text-style {
  position: absolute;
  text-align: center;
  font-size: 18px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.35) !important;
  border-radius: 5px;
  padding: 10px !important;
  background-color: transparent;
  line-break: anywhere !important;
}

.text-container {
  position: absolute;
  top: 0;
  width: 93%;
  margin-left: 14px;

  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.text-Contianer-Mobile {
  position: absolute;
  top: 0;
  width: fit-content;

  height: fit-content;
  text-align: center;
  font-size: 18px;
  vertical-align: middle;
  z-index: 1;
}

.ParenMobiletextContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TextMobileView {
  width: fit-content;
  color: white;
  z-index: 3;
}

.img-text-container {
  position: absolute;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  overflow: hidden !important;
  font-size: 30px;
}

.text-area-box-img {
  background-color: transparent !important;
  outline: none !important;
  max-height: 98% !important;
  width: fit-content !important;
  max-width: 100% !important;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.35) !important;
  border-radius: 5px;
  padding: 10px !important;
  /* word-wrap: calc(); */
  overflow-wrap: break-word;
  font-weight: 500;

  /* float: left; */
  /* overflow: hidden; */
}

.text-area-box-pre {
  background-color: transparent !important;
  outline: none !important;
  font-size: 30px;
  max-height: 100% !important;
  width: fit-content !important;
  max-width: 100% !important;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.35) !important;
  border-radius: 5px;
  padding: 10px !important;
  /* word-wrap: calc(); */
  overflow-wrap: break-word !important;
  font-weight: 500;
  overflow: hidden;
}

.input-color-container {
  position: relative;
  overflow: hidden;
  width: 35px;
  height: 35px;
  border: solid 2px #ddd;
  border-radius: 35px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.input-color {
  position: absolute;
  right: -8px;
  top: -8px;
  width: 56px;
  height: 56px;
  border: none;
}

/* .StoryMobileColorPicker {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;

} */

.StoryMobileColorPicker {
  appearance: none;
  /* hide the default appearance */
  width: 30px;
  /* set the width of the input */
  height: 30px;
  /* set the height of the input */
  border-radius: 50%;
  /* make the input circular */
  border: none;
  /* remove the border */
  outline: none;
  /* remove the default focus outline */
  cursor: pointer;
  /* change the cursor to a pointer on hover */
}

/* set the background color to the selected color */
.StoryMobileColorPicker::-webkit-color-swatch-wrapper {
  padding: 0;
}

.StoryMobileColorPicker::-webkit-color-swatch {
  border-radius: 50%;
}

.StoryMobileColorPicker::-moz-color-swatch {
  border-radius: 50%;
}

/*---------------- Upload Image ----------------*/

.upload-field-customized {
  width: 50px;
  position: relative;
  cursor: pointer;
}

.upload-field-customized input[type="file"] {
  position: absolute;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  left: 0px;
  top: 0px;
  z-index: 10;
}

.story-time-progress {
  position: absolute !important;
  height: 10px;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  z-index: 1;
  background-color: transparent !important;
  top: 0 !important;
}

.added-story {
  border-radius: 10px;
  background-size: cover !important;
  object-fit: fill !important;
}

.added-story-container {
  position: relative;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.progress {
  display: -ms-flexbox;
  height: 10px;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 5px;
}

.add-icon-story {
  background-size: cover !important;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.upload-field-customized span {
  text-align: center;
  width: 100%;
}

.upload-field-customized span img {
  margin-top: 10px;
  opacity: 0.2;
}

.story-gif-align {
  position: relative;
}

.gif-align {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 18px;
}

.bg-img-story {
  width: 100%;
  height: auto;
}

.story-bg-transparent {
  background-color: transparent !important;
  border-radius: 20px !important;
}

.progress-bar {
  border-radius: revert !important;
}

.gif-align {
  position: absolute;
  align-items: center;
  z-index: 1;
}

.StoryAspectRatioAdjustment {
  aspect-ratio: 9/16 !important;
  height: calc(100vh / 1.2) !important;
}

.MinHeightOfImage {
  min-height: calc(100vh / 1.2) !important;
  max-height: calc(100vh / 1.2) !important;
}

.StorylikeAndShare {
  position: absolute;
  bottom: 3%;
  left: 5%;
}

.IconsStory {
  /* transition: all 0.3s ease-in-out; */
  position: absolute;
  bottom: 3%;
  right: 5%;
  display: flex;
  width: calc(100vw / 25);
  height: calc(100vh / 34);
  width: 50%;
  /* overflow: hidden; */
  justify-content: flex-end;
  align-items: centers;
  cursor: pointer;
  gap: 0.2rem;
}

.IconsStory:nth-child(n) {
  flex: 1 1 auto;
  /* transition: 0.3s ease-in-out; */
  width: calc(100% / 6);
  /* transform: translate(0); */
  /* animation: slideEmoji 1.2s ease-in-out; */
}

.IconsStory > img:hover {
  transform: scale(1.1);
  z-index: 3;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
}

/* css for adjustmentbox */
.adjustment-container {
  margin-top: 20px;
}

.adjustment-container .adjustment-title {
  cursor: pointer;
}

.adjustment-title {
  position: absolute;
  right: 20px;
  left: 20px;
  height: 35px;
  border-radius: 5px;
  background-color: #f5f3f3;
}
.adjustment-title p {
  position: relative;
  top: 30%;
  left: 10px;
}
.reset{
  position: relative;
  top: 6%;
  left: 71%;
}


.adjustment-title img {
  position: absolute;
  top: 12px;
  right: 10px;
}

.adjustment-container .card {
  margin-top: 55px;
  padding: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 0px #00000040;
  height: 300px; /* Set a fixed height for the scrollable box */
  overflow: auto;
  position: relative;
  top: 50px;
  right: 7px;
  left: 3px;
}

.adjustment-container .card-body {
  display: flex;
  flex-direction: column;
}

.adjustment-container .card-body > div {
  margin-bottom: 10px;
}

.adjustment-container label {
  display: block;
  margin-bottom: 5px;
}

.adjustment-container input[type="range"] {
  width: 85%;
}

/* Slider styles */
.adjustment-container input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  background-color: #4d4d4d;
  outline: none;
  border-radius: 5px;
}

.adjustment-container input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 2px solid #88ee88;
  color: rgb(144, 238, 144);
  border-radius: 50%;
  cursor: pointer;
}

.adjustment-container input[type="range"]::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 2px solid lightgreen;
  color: rgb(144, 238, 144);
  border-radius: 50%;
  cursor: pointer;
}

/* mouse hover on story slider */

/* Add hover effect to the Story_Main_Card_blk */
/* .Story_Main_Card_blk:hover {
  background-color: green;
  border-top: 7px solid rgb(81, 180, 81);
   */
/* Add any other styles you want for the hover effect */
/* } */

/* Add hover effect to the StoryUnRead_Main_Card_blk */
/* .StoryUnRead_Main_Card_blk:hover {
  background-color: green; */
/* Add any other styles you want for the hover effect */
/* border-top: 7px solid rgb(81, 180, 81);
} */

/* Add hover effect to the CreateStory_Main_Card_blk */
/* .CreateStory_Main_Card_blk:hover {
  background-color: green; */
/* Add any other styles you want for the hover effect */
/* border-top: 7px solid rgb(81, 180, 81);
} */

/* --------------------- screen resize media Query ------------------- */

@media only screen and (max-width: 1450px) and (min-width: 400px) {
  .Story_Creation_Preview_section {
    /* background: linear-gradient(199.09deg, #81C14B 0%, #155B46 69.23%, #083737 100%); */
    border-radius: 10px !important;
    align-items: center;
    line-break: auto !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    color: #ffffff;
    overflow: hidden !important;
    /* height: 430px;
    width: 350px; */
  }

  .img-view-container {
    border-radius: 10px !important;
    position: relative !important;
    background-color: #c4c4c4 !important;
    align-content: center !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
    background-size: cover !important;
    /* height: 430px; */
  }

  .img-edit-container {
    border-radius: 5px !important;
    position: relative !important;
    background-color: #c4c4c4 !important;
    justify-content: center;
    align-content: center !important;
    align-items: center;
    overflow: hidden;
    height: auto;
  }
}

@media screen and (max-width: 1000px) and (min-width: 785px) {
  /* .story_sidebar {
    width: 30%;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(129, 193, 75, 0.2);
    position: fixed;
    height: 100%;
    overflow: auto;
  } */

  .story_content {
    /* margin-left: 30%; */
    align-items: center !important;
    justify-content: center !important;
    align-content: center !important;
    height: 100% !important;
    border-radius: 5px;
    backdrop-filter: blur(6px) !important;
  }
}

@media screen and (max-width: 600px) {
  .story_sidebar {
    /* width: 100%; */
    height: auto;
    float: left;
    position: relative;
  }

  .story_content {
    width: 100% !important;
    height: 400px;
    align-items: center !important;
    justify-content: center !important;
    align-content: center !important;
    height: auto !important;
    border-radius: 5px;
    margin-left: 0;
  }

  .Story_Creation_Preview_section {
    height: auto;
    overflow: hidden !important;
    width: 300px;
  }

  .img-view-container {
    height: auto;
    width: 300px;
  }

  .img-edit-container {
    height: auto;
    width: 300px;
    overflow: hidden;
  }

  .MinHeightOfImage {
    /* min-height: calc(100vh /1.1) !important; */
    max-height: calc(100vh / 1.1) !important;
  }

  .StoryAspectRatioAdjustment {
    aspect-ratio: 9/16 !important;
    height: calc(100vh / 1.1) !important;
  }

  .SwitchOnMobileView {
    position: absolute;
    top: calc(100vh / 13);
    right: calc(100vw / 11);
    z-index: 4;
  }
}

@media (min-width: 1400px) {
  .imageMaxWidth {
    max-width: 34vw !important;
  }
}

@media (min-width: 1700px) {
  .imageMaxWidth {
    max-width: 28vw !important;
  }
}

.Mx_h_100 {
  max-height: 100%;
}

.font500 {
  font-weight: 500 !important;
}

.font400 {
  font-weight: 400 !important;
}

.FullStoryPriview {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
}

.FullStoryPriview:nth-child(1) {
  width: calc(100vw / 4);
}

.FullStoryPriview:nth-child(2) {
  width: calc(100vw / 3);
}

/* report Button  */

.StoryThreeDotButtonMenu {
  position: absolute;
  will-change: transform;
  top: -34px !important;
  /* left: -59px !important; */
  transform: translate3d(-128px, 68px, 10px) !important;
  width: fit-content;
  font-size: 12px !important;
}

.storyEditBtnDesignViewPage {
  padding: 0.5rem 0.7rem !important;
  border-radius: 50%;
  background-color: white !important;
  /* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); */
  color: #4d4d4d;
}

.storyEditBtnDesignViewPage:hover {
  background-color: #81c14b !important;
  color: #fff !important;
}

.storyEditBtnDesignViewPageactive {
  padding: 0.5rem 0.7rem !important;
  border-radius: 50%;
  /* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); */
  background-color: #81c14b !important;
  color: #fff !important;
}

/* slick slider modify  */

.StoryBtnSlickSlider > .slick-prev {
  left: -100px !important;
}

.StoryBtnSlickSlider > .slick-next {
  right: -100px !important;
}

.zIndex2 {
  z-index: 2 !important;
}

/* 

/* Story Privew box New Design  */

.StoryPriviewbox {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  /* height: 100vh; */
}

.us-none {
  user-select: none;
}

.StoryPreviewContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.StoryPreviewBox {
  background-repeat: no-repeat !important;
  aspect-ratio: 9 / 16;
  /* width: calc(100vw / 4); */
  border-radius: 10px;
  /* background-color: #04AA6D; */
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
  /* height: calc(100vh / 1.2); */
}

.StoryPreviewBox > img {
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}

.StoryImageUploadPreview {
  /* background-repeat: no-repeat !important; */
  aspect-ratio: 9 / 16;
  /* width: calc(100vw / 4); */
  border-radius: 10px;
  /* background-color: #04AA6D; */
  /* height: 100%; */
  /* background-image: url("https://wallpapershome.com/images/pages/ico_h/24116.jpg"); */
  /* background-size: cover !important; */
  /* background-position: center !important; */
  /* height: calc(100vh / 1.2); */
}

.StoryImageEditContainer {
  height: 80vh;
  object-fit: contain;
}

.StoryImageUploadPreview {
  z-index: 1;
  border: 1px solid white;
}

.ImageBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  /* adjust the color of the backdrop as desired */
  z-index: 1;
  /* set the z-index to -1 to place the backdrop behind the image and overlay */
}

.Frame {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid white;
  aspect-ratio: 9/16;
  /* height: 100%; */
  width: 100%;
  object-fit: contain;
  z-index: 1;
  /* box-shadow: 0px 0px 3000px rgba(0, 0, 0, 0.75); */
}

/* Mobile View Story Creationn  */

.StoryCreationCrossBtn {
  position: absolute;
  /* left: calc(100% / 20); */
  left: 0;
  top: calc(100% / 26);
}

/* .FilterAndTextBtn {
  position: absolute;
  right: 8%;
  top: calc(100% / 25);
} */

.StoryMobileBottomOptions {
  position: absolute;
  bottom: calc(100% / 26);
  left: 0;
}

.FiltersMobileView {
  position: absolute;
  bottom: calc(100% / 8);
  left: 0;
  display: flex;
  align-items: center;
  overflow-y: scroll;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.StoryMobiletextFieldUpperFilter {
  position: absolute;
  bottom: calc(100% / 4);
  left: 0;
  display: flex;
  align-items: center;
  overflow-y: scroll;
  transition: 0.3s ease-in-out;
  width: 100%;
}

.FiltersMobileView::-webkit-scrollbar {
  display: none;
}

.FiltersMobileView > img {
  width: calc(100% / 10);
  aspect-ratio: 4 / 4;
}

.MobileStoryView {
  display: none;
  /* background-size: contain !important; */
  background-repeat: no-repeat !important;
  background-position: center !important;
}

@media (max-width: 567px) {
  .FiltersMobileView > img {
    width: calc(100% / 5);
    aspect-ratio: 4 / 4;
  }
}

@media (max-width: 1024px) {
  .DeskTopViewStoryCreation {
    display: none;
  }

  .MobileStoryView {
    display: flex;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
}

/* story view Modal  */

.StoryViewHoverColor:hover {
  background-color: #e6f3db;
  border-radius: 5px;
}

.StoryAspectRatioFix {
  aspect-ratio: 9/16 !important;
  max-height: calc(100vh / 1.2) !important;
}

.StoryImagePreviewImage {
  aspect-ratio: 9/16 !important;
  height: calc(100vh / 1.7);
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.img-preview {
  overflow: hidden;
}

/* new story view modal  */

.storyViewPageMainContainer {
  display: flex;
}

.StorySidePannel {
  width: calc(100vw / 3);
}

.StoryViewPannel {
  width: calc(100vw / 2);
}

.StorySidebarCircleIcon {
  padding: 0.5rem 0.7rem;
  border-radius: 50%;
  border: 1px solid #4d4d4d;
  background-color: white;
}

.StoryProgressbar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

.StoryProgressbar > div {
  width: calc(100% / 5.1);
}

.StoryMainDesign {
  width: calc(100% / 2);
}

.mainStoryImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.StoryTextContainerAdjustment {
  /* position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%); */
  transform: translateY(-70%);
}

:root {
  --storyGradient: linear-gradient(
    to top,
    rgba(129, 193, 75, 0.5),
    rgba(129, 193, 75, 0.5)
  );
  --updateStoryGradient: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
}

.StoryMainSliderComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .ptofileImage{
  box-shadow: 0px 4px 4px 0px #00000040;

} */

.CreateStory_Main_Card_blk {
  border-radius: 9px;
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  min-width: 50%;
  height: 185px;
  object-fit: cover;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.Story_Main_Card_blk {
  border-radius: 9px;
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  height: 185px;
  object-fit: cover;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.unRead_story_section {
  border: 2px solid rgba(129, 193, 75, 0.4);
  border-radius: 5px;
}

.StoryUnRead_Main_Card_blk {
  border: 2px solid #81c14b;
  border-radius: 9px;
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 100%;
  height: 185px;
  object-fit: cover;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.Create_Story {
  position: absolute;
  top: 33%;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.Live_pills {
  padding: 3px 8px;
  position: absolute;
  top: 10px;
  left: 8px;
  background: #fd1b1b;
  border-radius: 5px;
  color: #e6f4f3;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
}

.Live_Right_blk {
  position: absolute;
  color: #e6f4f3;
  font-weight: 500;
  font-size: 10px;
  top: 12px;
  right: 8px;
}

.story-buttomcontent {
  position: absolute;
  bottom: 8px;
  left: 8px;
  text-align: start;
}

.story-buttomcontent h3 {
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
}

.story-buttomcontent h4 {
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  margin-top: 5px;
}

.story_buttom_rght_blk {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.story_TopLeft_blk {
  position: absolute;
  width: 100%;
  top: 0px;
}

.story_Progress_Bar {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 5px 5px 0px 0px;
  height: 4px;
}

.progress-bar {
  /* background: #81c14b; */
  background-color: #106931;
}

.Add_Story_text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  /* margin-top: 8px; */
  padding-top: 10px;
}

.StoryViewImageSlider {
  width: 100%;
  min-height: 80vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}

/* 
.StroyImagePrivewSlider {
  aspect-ratio: 9/16;
  min-height: calc(100vh / 1.2);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;

  background-size: contain;
  background-color: #4d4d4d;
  background-position: center;

} */

.StroyImagePrivewSlider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  background-size: contain;
  background-color: #4d4d4d;
  background-position: center;
}

@media (max-width: 767px) {
  .StroyImagePrivewSlider {
    aspect-ratio: 9/16 !important;
    min-height: auto;
    min-width: calc(100vw * (9 / 16));
    max-height: calc(100vh * (16 / 9));
    /* min-height: calc(100vw * 9/16); */
  }

  .StoryViewImageSlider {
    width: 100%;
    min-height: 100vh;
    /* max-height: 100vh; */
    /* background-color: red; */
  }
}

@media (max-width: 520px) {
  .StroyImagePrivewSlider {
    aspect-ratio: 9/16 !important;
    min-height: auto;
    min-width: calc(100vw * (14 / 16));
    max-height: calc(100vh * (16 / 9));
    /* min-height: calc(100vw * 9/16); */
  }

  .StoryViewImageSlider {
    width: 100%;
    min-height: 100vh;
    /* max-height: 100vh; */
    /* background-color: red; */
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .StroyImagePrivewSlider {
    aspect-ratio: 9 /16 !important;
    min-width: 100%;
  }
}

@media (min-width: 1025px) {
  .StroyImagePrivewSlider {
    aspect-ratio: 9 /16 !important;
    min-height: calc(100vh / 1.2);
  }
}

/* When screen size descrease, when it reached 1024px after that header will not visible */

.StoryPreviewImageSrc {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
}

/* .reply-form {
 position: absolute;
 bottom: 0;
 left: 0;
 margin-bottom: -80px;
} */

.storyReactionBtn {
  width: 2rem;
}

@media (max-width: 767px) {
  .RemoveInMobileStory {
    display: none;
  }

  /* 
  .SwitchOnMobileView {
    display: none;
  } */

  .storyReactionBtn {
    width: 1.5rem !important;
  }

  .StoryViewImageSlider {
    align-items: flex-start;
    padding-top: 15px;
  }
}

.ReplyInputbox {
  width: 100%;
  z-index: 9999;
}

/* reply box media query  */

/* New story Veiw design */

.NewSoryViewDesign {
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.StoryWholeContainer_ {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-color: transparent;
  display: flex;
  flex-wrap: nowrap;
  
  align-items: center;
  justify-content: center;
  /* display: grid; */
  /* grid-template-areas: "sidebar sidebar main main main main main main main main"; */
}

.StorySideBar_ {
  /* width: clamp(450px, 24vw, 26vw); */
  overflow-y: auto;
  height: 100vh;
  background-color: #ffffff;
  /* grid-area: sidebar; */
}

.StoryUserDesign_ {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.StoryViewArea_ {
  /* width: 100%; */
  background-color: transparent;
  height: 100vh;
  /* max-width: calc(100vw - clamp(450px, 24vw, 26vw)); */
  /* grid-area: main; */
}

.StorySideBarHead_ {
  /* width: 100%; */
  height: clamp(40px, 50px, 60px);
  border-bottom: 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.StoryDesignUserSelfData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
}

.StoryUserData_ {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 10px 0px;
}

.StoryUsetTextData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.6rem;
}

.StoryDataUserImage {
  width: clamp(30px, 10vw, 45px);
  height: clamp(30px, 10vw, 45px);
  border-radius: 5px;
  padding: 4px;
  color: #ccc;
  box-sizing: content-box;
}

.StoryImageEditbuttons_ {
  padding: 6px 8px;
  border-radius: 50%;
  
}

.Story_ActiveEdit_Btn {
  padding: 6px 8px;
  border-radius: 50%;
}

.StoryImageEditbuttons_AddText {
  padding: 8px 8px;
  border-radius: 50%;
  border: 1px solid #4d4d4d;
  
}

.EditStoryBtnImage {
  width: 16px;
  height: 16px;
}

.EditStoryBtnImage:hover {
  fill: white !important;
}

.StoryProgressbar {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  gap: 0.5rem;
  height: 80px;
  align-items: center;
}

.story_Progress_Bar_main {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 5px 5px 5px 5px;
  height: 7px;
}

.story_Progress_Bar_main > .progress-bar {
  background: white !important;
}

/* .Story_Preview_ {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
} */

.previewedImage_,
.ImagePreviewStory_ {
  border-radius: 10px;
}

.ImagePreviewStory_ .previewedImage_ {
  min-width: 100%;
  object-fit: contain;
  object-position: center;
}

.ImagePreviewContainer {
  min-width: 100%;
  overflow: hidden;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

/* .ImagePreviewContainer>div {
  width: 100%;
  object-fit: contain;

} */

.ImagePreviewContianerSub {
  max-width: 100%;
  position: relative;
}

.ImagePreviewStory_ {
  max-width: 100%;
  max-height: 83vh !important;
  object-fit: cover !important;
}

.OnStoryUserImg_ {
  width: clamp(40px, 40px, 65px);
  height: clamp(40px, 40px, 65px);
  border-radius: 100%;
  border: 2px solid white;
}

.StoryUserInfo_ {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), transparent);
  border-radius: 10px 10px 0px 0px;
}

.OnStoryUserInfo {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.OnStoryUserInfo > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
}

.OnStorySettings {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.OnStorySettings_ {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.8rem;
}

.StoryReplybox_ {
  height: 2rem;
}

.StoryReactionbtn {
  height: 2rem;
  width: 2rem;
}

.StoryReplyEmojiIcons {
  width: 1.5vw;
}

.StoryReplySendBtn {
  width: 2rem;
  height: 1.9rem;
  position: relative;
  background: #4d4d4d;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .StorySliderCustomize>.slick-prev:before,
.StorySliderCustomize>.slick-next:before {
  font-family: "Font Awesome 5 Free";
  font-size: 30px;
  line-height: 1;
  opacity: .75;
  color: #fff;
} */

.StorySliderCustomize > .slick-prev:before {
  content: "\2039" !important;
  color: white !important;
  /* chevron left icon */
}

.StorySliderCustomize > .slick-next:before {
  content: "\203A" !important;
  color: white !important;

  /* chevron right icon */
}

.StorySliderCustomize > .slick-prev {
  left: -50px !important;
  transform: scale(2.2);
}

.StorySliderCustomize > .slick-next {
  right: -50px !important;
  transform: scale(2.2);
}

.green-button {
  /* background-color: #81c14b; */
}
.StoryBottonLikeViewAdjust_ {
  /* position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%); */
  transform: translateY(-60%);
}

.StoryCrossButton_ {
  position: absolute;
  right: 0;
  z-index: 2;
}

.StoryCrossButton_Mobile {
  display: none;
}

@media (min-width: 280px) and (max-width: 800px){
  .StoryReplyEmojiIcons {
    width: 3.5vw;
  }
  
} 

@media (max-width: 960px) {
  .StoryCrossButton_Mobile {
    display: block;
    position: absolute;
    right: 0;
    top: 2px;
    z-index: 2;
  }

  .StoryCrossButton_ {
    display: none;
  }
}

/* unversal  */
.storyBoderColor {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.storyBoderColor4D {
  border-color: #4d4d4d !important;
}

.Story4D_600 {
  color: #4d4d4d !important;
  font-weight: 500 !important;
}

.Story4D_500 {
  font-weight: 400 !important;
  color: #4d4d4d !important;
}

.Story81_600 {
  font-weight: 600 !important;
  color: #81c14b;
}

.Fw_600 {
  font-weight: 600 !important;
}

.Fw_500 {
  font-weight: 400 !important;
}

.Fw_400 {
  font-weight: 400 !important;
}

.Hover81:hover {
  background-color: #81c14b !important;
  color: white;
}

.HoverE6:hover {
  background-color: #e6f3db !important;
}

.BorderRadius5px {
  border-radius: 5px;
}
/* profile image on story page */
.profileImage {
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  height: 60px;
  width: 60px;
 

}

.StatusBorderDashedProfile {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%2381C14BFF' stroke-width='5' stroke-dasharray='10%25%2c15%25' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  border-radius: 5px;
}

.StatusBorderDashed {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%2381C14BFF' stroke-width='5' stroke-dasharray='50%25%2c15%25' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  border-radius: 5px;
}

.StatusBorderDashedGray {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23C4C4C4FF' stroke-width='5' stroke-dasharray='30%25%2c15%25' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e") !important;
  border-radius: 5px;
}

.UserActiveStory {
  background-color: #e6f3db !important;
}

.StorySliderCustomizes > .slick-prev:before {
  content: "\2039" !important;
  color: white !important;
  background: rgba(85, 85, 85, 0.5);
  padding: 5px 2px;
  border-radius: 0px 5px 5px 0px;
  padding-bottom: 16px !important;

  /* chevron left icon */
}

.StorySliderCustomizes > .slick-next:before {
  content: "\203A" !important;
  color: white !important;
  background: rgba(85, 85, 85, 0.5);
  padding: 5px 2px;
  border-radius: 5px 0px 0px 5px;
  padding-bottom: 16px !important;
}

.StorySliderCustomizes > .slick-prev {
  left: -4px !important;
}

.StorySliderCustomizes > .slick-next {
  right: -4px !important;
}

.GroupSSliderCustomizes > .slick-prev:before {
  content: "\2039" !important;
  color: white !important;
  background: rgba(85, 85, 85, 0.5);
  border-radius: 0px 10px 10px 0px;
  padding: 5px 2px;
  padding-bottom: 16px !important;

  /* chevron left icon */
}

.GroupSSliderCustomizes > .slick-next:before {
  content: "\203A" !important;
  color: white !important;
  background: rgba(126, 178, 98, 0.8);
  padding: 5px 2px;
  padding-bottom: 16px !important;
  background: rgba(85, 85, 85, 0.5);
  border-radius: 10px 0px 0px 10px;
}

.GroupSSliderCustomizes > .slick-prev {
  left: -10px !important;
}

.GroupSSliderCustomizes > .slick-next {
  right: -10px !important;
}

.BirthdaySliderCustomizes > .slick-prev:before {
  content: "\2039" !important;
  color: white !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0px 10px 10px 0px;
  padding: 5px 2px;
  padding-bottom: 16px !important;

  /* chevron left icon */
}

.BirthdaySliderCustomizes > .slick-next:before {
  content: "\203A" !important;
  color: white !important;
  background: rgba(126, 178, 98, 0.8);
  padding: 5px 2px;
  padding-bottom: 16px !important;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px 0px 0px 10px;
}

.BirthdaySliderCustomizes > .slick-prev {
  left: -4px !important;
}

.BirthdaySliderCustomizes > .slick-next {
  right: -4px !important;
}

.StoryCreationTextAreaMain {
  max-height: 10rem;
  outline-color: #81c14b;
  color: #4d4d4d;
  font-weight: 400;
  border-color: #c4c4c4;
  border-width: 0.5px;
}

.StoryCreationTextAreaMain::placeholder {
  color: #c4c4c4;
  font-weight: 400;
}

/* image edit container flow   */

/* image select and crop section  */

.ImageEditCropSectionHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.CropTheStoryImage_Text {
  justify-self: center;
}

.StoryImageEditContainer_ {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.StoryImageEditContainer_Sub {
  height: 85vh;
  width: 90%;
  background-color: #c4c4c4;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ImageEditContainer_main {
  max-width: 100%;
  /* width: 100%; */
  max-height: 100%;
  min-height: 100%;
  display: grid;
  place-items: center;
  border-radius: 10px;
  position: absolute;
  top: 3px;
  bottom: 30px;
}



.StoryImageUploadIconContainer {
  max-width: 100%;
  /* width: 100%; */
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ImageEditContainer_mainAfterCrop {
  max-width: 100%;
  /* width: 100%; */
  max-height: 80%;
  min-height: 80%;
  display: grid;
  object-fit: cover;
  place-items: center;
  border-radius: 10px;
}

.OnEditImagePrivew_AfterCrop {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  min-height: 100%;
  height: auto;
  object-fit: cover;
  /* border-radius: 10px; */
  overflow: hidden;
  /* position: absolute;
  top:10px;
  bottom:10px; */
}

.OnEditImagePrivew_ {
  max-height: 100%;
  max-width: 90%;
  width: auto;
  min-height: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  overflow: hidden;
}

.DropDownLanguageBox {
  position: absolute;
  top: 2.4rem;
  left: 0.2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 20rem;
  padding: 6px;
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 10;
  align-items: flex-start;
}

.DropDownLanguageBox > div {
  padding: 5px 10px;
}

button.StoryFontDropDownToggle.btn.btn-secondary {
  border: none !important;
  background: white !important;
  padding: 0 !important;
}

.StoryFontDropDownMenu {
  width: 16rem !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
}

.AddTextWindowStoryEdit {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.InputTextStory_ {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  resize: none;
  overflow: hidden;
  min-height: 40px;
  height: fit-content;
  min-width: 92%;
  outline: none;
  
}

.InputTextStory_::placeholder {
  color: white;
  font-size: 22px;
}

.CroppedImageGradientStory::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
}

/* text customizer styline  */

.TheGeneratedtext {
  width: fit-content;
  height: fit-content;
  padding: 10px;
  position: relative;
  border: 1px solid transparent;
}

.TheGeneratedtext:hover {
  border: 1px dashed gray;
}

.TextCrossBtn_story {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
}

.cursor-p {
  cursor: pointer;
}

.TextRotationBtn_story {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
}

.TextResizableBtn_Story {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%) rotate(-30deg);
}

.TextRotationBoxSide {
  position: absolute;
  padding: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.StoryFontDropDownMenu.dropdown-menu.show {
  z-index: 3 !important;
}

.StoryImageAfterEdit {
  width: auto;
  max-height: 100%;
  height: 85vh;
}

/* .addedtext{
  font-size: 30px;
} */

.selectedTextBorder {
  border: 1px solid white;
}

.StoryCrossBtn {
  position: absolute;
  left: 0;
  top: 0;
 
 
 
}

.StoryTextCustomize_ {
  position: absolute;
  left: 0;
  right: 0;
  transform: translate(-50%, -50%);
  padding: 4px;
}

/* when screen size descrease below the 1025px then header will bw disappear */
/* @media screen and (max-width: 1025px){
  header{
    visibility: hidden;
  }
} */

/* mobile screen story module */

 /* .StoryCreationCrossBtn {
  position: absolute;
  top: 60px;
 
}

.StoryMobileBottomOptions {
  position: absolute;
  bottom: 65px;
  
}  */

@media (max-width: 1025px) {
  .header{
    display: none;
  }
}

.addrealtion{
  color: var(--button-color, #81C14B);
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 28.5px */
cursor: pointer;
}
